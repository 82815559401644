import styled from 'styled-components';
import { Button } from 'devextreme-react/button';

export const Container = styled.div`
  align-self: center;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;

  label {
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    img {
      height: ${({ rounded }) => (rounded ? '90px' : '50px')};
      width: ${({ rounded }) => (rounded ? '90px' : '120px')};
      border-radius: ${({ rounded }) => (rounded ? '50%' : '8px')};
      border: 1px solid ${({ theme }) => theme.colors.grayscale.gray80};
      background: ${({ theme }) => theme.colors.grayscale.gray90};
      object-fit: contain;
    }

    input#image {
      display: none;
    }
  }
`;

export const DeleteButton = styled(Button)`
  align-self: center;
  border: none;
  opacity: ${({ showDeleteButton }) => (showDeleteButton ? 0.8 : 0)};
  transition: opacity 0.3s;
  margin: 0 10px;

  &:hover {
    opacity: 1;
  }
`;
