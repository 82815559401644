import { Button, Popup } from 'devextreme-react';
import React from 'react';

function PopupConfirm({ message, visible, onConfirm, onCancel }) {
  return (
    <Popup
      visible={visible}
      dragEnabled={false}
      showTitle={false}
      showCloseButton={false}
      height="auto"
      width="auto"
    >
      <h3>{message}</h3>
      <br />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
        <Button text="Ok" onClick={onConfirm} type="success" />
        <Button text="Cancel" onClick={onCancel} />
      </div>
    </Popup>
  );
}

export default PopupConfirm;
