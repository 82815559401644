import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Button,
  CheckBox,
  LoadPanel,
  Popup,
  TextArea,
  TextBox,
} from 'devextreme-react';
import TagBox from 'devextreme-react/tag-box';
// import { ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { func } from 'prop-types';
import API from '../../../../../services/API';

import { FieldRow, FieldSet, GroupContainer, WrapperButtons } from './styles';
import Validator, {
  PatternRule,
  RequiredRule,
} from 'devextreme-react/validator';

const PopupTraining = forwardRef(({ onClose, refetch }, ref) => {
  const [isSaving, setIsSaving] = useState(false);
  const [trainingDraft, setTrainingDraft] = useState(null);
  const [hospitalGroup, setHospitalGroup] = useState(null);
  const [visiblePopup, setVisiblePopUp] = useState(false);
  const [currentTraining, setPopupData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  useImperativeHandle(
    ref,
    () => ({
      popupControl: () => setVisiblePopUp((prev) => !prev),
      setPopupData: (data) => setPopupData(data),
    }),
    []
  );

  useEffect(() => {
    if (currentTraining?.data) {
      setTrainingDraft({ ...currentTraining?.data });
    } else {
      setTrainingDraft(null);
    }
  }, [currentTraining]);

  useEffect(() => {
    let isSubscribed = true;
    API.sendRequest('HospitalGroup', 'get').then(({ data }) => {
      if (isSubscribed) {
        setHospitalGroup(data);
      }
    });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (!trainingDraft?.TrainingDesc) {
      notify('You need to choose a training name', 'error', 5000);
      return;
    }
    if (!trainingDraft?.Url && trainingDraft?.IsPortal) {
      notify('You need to choose a Url', 'error', 5000);
      return;
    }
    if (
      JSON.stringify(currentTraining.data) === JSON.stringify(trainingDraft)
    ) {
      onClose();
      return;
    }

    setIsSaving(true);
    try {
      const dataToSend = trainingDraft;

      if (!dataToSend?.HospitalGroupId) {
        delete dataToSend?.HospitalGroupId;
      }

      if (!trainingDraft?.IsGlobal && !trainingDraft?.HospitalGroups?.length) {
        setIsSaving(false);
        return notify(
          'You need to choose at least one Okta Tile',
          'error',
          5000
        );
      }

      const res = await API.sendRequest(
        'Training/Save',
        'post',
        trainingDraft?.IsGlobal
          ? { ...dataToSend, HospitalGroups: [] }
          : dataToSend
      );
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      if (
        res.status === 200 &&
        res.data === 'This training name already exists'
      ) {
        setErrorMessage('nameExists');
        throw new Error(res.data);
      }

      setTrainingDraft(null);
      setIsSaving(false);
      refetch();
      notify('Training saved successfully', 'success', 5000);
      onClose();
    } catch (e) {
      setIsSaving(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const handleClosePopup = () => {
    setErrorMessage('');
    onClose();
  };

  return (
    visiblePopup && (
      <>
        <LoadPanel
          visible={isSaving}
          message={isSaving ? 'Saving...' : 'Loading...'}
        />
        <Popup
          title={`${currentTraining?.type === 'NEW' ? 'New' : 'Edit'} Training`}
          visible={visiblePopup}
          onHiding={handleClosePopup}
          // onHidden={onClose}
          width={550}
          height="auto"
          dragEnabled={false}
          showCloseButton
        >
          <form onSubmit={handleSaveChanges} style={{ height: 'fit-content' }}>
            <GroupContainer>
              <FieldRow>
                <FieldSet $width="49%">
                  <label className="label">Training Name:</label>
                  <TextBox
                    style={{
                      border:
                        errorMessage && errorMessage === 'nameExists'
                          ? '1px solid red'
                          : '',
                    }}
                    width="380px"
                    maxLength={30}
                    value={trainingDraft?.TrainingDesc}
                    onValueChanged={(e) =>
                      setTrainingDraft((prevState) => ({
                        ...prevState,
                        TrainingDesc: e.value,
                      }))
                    }
                  >
                    <Validator>
                      <RequiredRule message="Training Name is required" />
                    </Validator>
                  </TextBox>
                </FieldSet>
              </FieldRow>

              <FieldRow>
                <FieldSet $width="49%">
                  <label className="label">Is Global?</label>
                  <CheckBox
                    value={trainingDraft?.IsGlobal || false}
                    onValueChanged={(e) =>
                      setTrainingDraft((prevState) => ({
                        ...prevState,
                        IsGlobal: e.value,
                        HospitalGroups: [],
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>
              {!trainingDraft?.IsGlobal && (
                <FieldRow>
                  <FieldSet $width="49%">
                    <label className="label">Okta Tiles:</label>
                    <TagBox
                      width="380px"
                      style={{ maxHeight: '100px', overflowY: 'auto' }}
                      dataSource={hospitalGroup}
                      valueExpr="HospitalGroupId"
                      displayExpr="HospitalGroupName"
                      searchEnabled
                      searchExpr="HospitalGroupName"
                      value={trainingDraft?.HospitalGroups?.map(
                        (item) => item.HospitalGroupId
                      )}
                      onValueChanged={(e) =>
                        setTrainingDraft((prevState) => ({
                          ...prevState,
                          HospitalGroups: e.value?.map((hospitalGroupId) => ({
                            HospitalGroupId: hospitalGroupId,
                          })),
                        }))
                      }
                      showClearButton
                    />
                  </FieldSet>
                </FieldRow>
              )}
              <FieldRow>
                <FieldSet $width="49%">
                  <label className="label">Url:</label>
                  <TextBox
                    width="380px"
                    value={trainingDraft?.Url}
                    onValueChanged={(e) =>
                      setTrainingDraft((prevState) => ({
                        ...prevState,
                        Url: e.value,
                      }))
                    }
                  >
                    <Validator>
                      {trainingDraft?.IsPortal && (
                        <RequiredRule message="Training Url is required" />
                      )}

                      <PatternRule
                        message="Url do not match to the pattern. Must have the following format: https://www.example.com"
                        pattern={
                          /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/
                        }
                      />
                    </Validator>
                  </TextBox>
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="49%">
                  <label className="label">Is Portal?</label>
                  <CheckBox
                    value={trainingDraft?.IsPortal || false}
                    onValueChanged={(e) =>
                      setTrainingDraft((prevState) => ({
                        ...prevState,
                        IsPortal: e.value,
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="49%">
                  <label className="label">Notes:</label>

                  <TextArea
                    width="380px"
                    height="150px"
                    value={trainingDraft?.Notes}
                    onValueChanged={(e) =>
                      setTrainingDraft((prevState) => ({
                        ...prevState,
                        Notes: e.value,
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>

              <FieldRow height="30px">
                <FieldSet $width="49%">
                  <label className="label">Active?</label>
                  <CheckBox
                    value={trainingDraft?.Active || false}
                    onValueChanged={(e) =>
                      setTrainingDraft((prevState) => ({
                        ...prevState,
                        Active: e.value,
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>
              <WrapperButtons>
                <Button
                  text="OK"
                  type="success"
                  useSubmitBehavior
                  width={100}
                />
                <Button
                  text="Cancel"
                  type="normal"
                  onClick={onClose}
                  width={100}
                />
              </WrapperButtons>
            </GroupContainer>
          </form>
        </Popup>
      </>
    )
  );
});

export default PopupTraining;

PopupTraining.propTypes = {
  onClose: func.isRequired,
  // currentTraining: object,
  refetch: func.isRequired,
};

PopupTraining.defaultProps = {
  // currentTraining: {},
};
