import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { format } from 'date-fns';
import Avatar from '../contacts/Avatar';
import API from '../../services/API';
import clone from '../../helpers/clone';

import {
  PopoverCustom,
  BoxAvatar,
  BoxSubject,
  Subject,
  DateTitle,
} from './styles';

const ListCustomMulti = React.forwardRef((props, ref) => {
  const { classes, source, handleRemove, currentDate } = props;

  let tasksrest = JSON.parse(source.value);
  tasksrest = Array.isArray(tasksrest) ? [...tasksrest] : [tasksrest];

  tasksrest = tasksrest.filter(t => !t.Locked);

  if (tasksrest.length === 0) {
    return;
  }

  const now = new Date();
  const day = parseInt(source.column.dataField.replace(/\D+/g, ''));
  const isPast =
    (now.getDate() > day &&
      now.getMonth() + 1 === currentDate.getMonth() + 1 &&
      now.getFullYear() === currentDate.getFullYear()) ||
    (now.getMonth() + 1 > currentDate.getMonth() + 1 &&
      now.getFullYear() === currentDate.getFullYear()) ||
    now.getFullYear() > currentDate.getFullYear();

  tasksrest.forEach((task) => {
    task.Dl = !(!task.Od && isPast) && task.Sb !== 'LOA';
  });

  return (
    <div ref={ref} style={{ width: 380 }}>
      <List dense className={classes.listRoot}>
        {tasksrest.map((item) => {
          const dateInicial = format(
            new Date(`${item.Sd ? item.Sd : item.StartDate}`),
            'MMM dd, hh:mm a'
          );
          const dateFinal = format(
            new Date(`${item.Ed ? item.Ed : item.EndDate}`),
            'MMM dd, hh:mm a'
          );

          return (
            <ListItem key={item.Id}>
              <ListItemAvatar>
                <BoxAvatar>
                  <Avatar
                    contact={source.data}
                    h={25}
                    w={25}
                    style={{ marginLeft: 0, marginRight: 0 }}
                  />
                  <span>{source.data.FullName}</span>
                </BoxAvatar>
              </ListItemAvatar>
              <ListItemText>
                <BoxSubject>
                  <Subject>{item.Sb}</Subject>

                  <DateTitle>{`${dateInicial} ${dateFinal}`}</DateTitle>
                </BoxSubject>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemove(item.Id)}
                  disabled={!item.Id || !item.Dl}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
});

export default function SimplePopover({
  anchorEl,
  setAnchorEl,
  classes,
  data,
  contacts,
  setContacts,
  currentDate,
}) {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveItem = (id) => {
    API.sendRequest(`ContactSchedule/${id}`, 'DELETE', id);

    const item = {};

    item.day = data.column.dataField ? data.column.dataField : '';
    item.contact = data.data;
    // formatSelectedCell end

    const oldContactId = item.contact.ContactId;
    const oldDay = data.column.dataField ? data.column.dataField : '';

    // Clone to avoid multiples renders
    const contactsCopy = clone(contacts);

    const oldContactIndex = contacts.findIndex(
      (contact) => contact.ContactId === oldContactId
    );

    const itemsOld = JSON.parse(contactsCopy[oldContactIndex][oldDay]) || [];

    const oldArray =
      itemsOld.length > 0
        ? JSON.stringify(itemsOld.filter((itemOld) => itemOld.Id !== id))
        : null;

    contactsCopy[oldContactIndex][oldDay] = oldArray;

    setContacts(contactsCopy);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <PopoverCustom
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <ListCustomMulti
          classes={classes}
          source={data}
          handleRemove={handleRemoveItem}
          currentDate={currentDate}
        />
      </PopoverCustom>
    </div>
  );
}
