import styled from 'styled-components';

export const WrapperContact = styled.div`
  display: flex;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.colors.white};
`;

export const WrapperDataContact = styled.div`
  display: flex;
  flex: 1;
  margin-left: 6px;
  width: 100%;
  flex-direction: ${({ columnDirection }) =>
    columnDirection ? 'column' : 'row'};
  margin-left: 5px;
  ${(props) =>
    props.columnDirection
      ? 'align-items: flex-start'
      : 'justify-content: flex-start'};
`;

export const Left = styled.div`
  min-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  textOverflow: ellipsis;
  whiteSpace: nowrap;
`;

export const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;