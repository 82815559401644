// import { baseUrl } from './currentBaseUrl';
const baseUrl = process.env.REACT_APP_API_URL;

const config = {
  development: {
    isDev: baseUrl.includes('dev'),
    apiUrl: `${baseUrl}/api`,
    imagesUrl: `${baseUrl}/api`,
    picUrl: `${baseUrl}/api/Images/User/`,
    googleAPIKey: 'AIzaSyBXDOJ6PmIBLjBR2aOP_9ZXGY971BSrRkI',
    sentryDsn:
      'https://cb7820478d324937ad5be47e3c49a38a@o405076.ingest.sentry.io/5270193',
    menuScheduleId: 'a8216bf1-682a-4d81-8275-db37a9ae0cf1',
    TREmail: 'truserdev@njsharingnetwork.org',
  },
  production: {
    isDev: baseUrl.includes('dev'),
    apiUrl: `${baseUrl}/api`,
    imagesUrl: `${baseUrl}/api`,
    picUrl: `${baseUrl}/api/Images/User/`,
    googleAPIKey: 'AIzaSyBXDOJ6PmIBLjBR2aOP_9ZXGY971BSrRkI',
    sentryDsn:
      'https://cb7820478d324937ad5be47e3c49a38a@o405076.ingest.sentry.io/5270193',
    menuScheduleId: 'a8216bf1-682a-4d81-8275-db37a9ae0cf1',
    TREmail: 'NJSNACT@njsharingnetwork.org',
  },
};

if (!config[process.env.NODE_ENV]) {
  throw new Error('Env not configured');
}

export default config[process.env.NODE_ENV];
