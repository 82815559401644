import AppBar from '@material-ui/core/AppBar';
import Popover from '@material-ui/core/Popover';
// import Toolbar from '@material-ui/core/Toolbar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { TRHeaderLogo } from '../../../assets/login/TRHeaderLogo';
import React, { useEffect, useState } from 'react';
import config from '../../../config';
import getLoggedUser from '../../../helpers/userFunctions';
// import useDevice from '../../hooks/useDevice';
import useAuth from '../../../hooks/useAuth';
import API from '../../../services/API';
import Avatar from '../../contacts/Avatar';
import Profile from '../../Profile';
import DesktopMenu from '../DesktopMenu';
import {
  ProfileButton,
  WrapperGeneral,
  WrapperHelp,
  WrapperLogo,
  WrapperMenu,
  WrapperProfile,
  WrapperRight,
  Toolbar,
} from './styles';

const AppBarDrawer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null);
  // const device = useDevice();
  const auth = useAuth();
  useEffect(() => {
    // Load Phone Types
    async function init() {
      const userId = getLoggedUser();

      if (userId === 'Not logged') {
        setUser({ Username: userId, ADUser: false });
        return;
      }

      const { data } = await API.sendRequest(
        `User/${userId}`,
        'get',
        {},
        false
      );
      setUser(data);
    }

    init();
  }, [auth]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {auth && (
        <AppBar color={config.isDev ? 'secondary' : 'primary'}>
          <Toolbar>
            <WrapperGeneral>
              <>
                <WrapperLogo>
                  <TRHeaderLogo />
                  <span>Team Relay</span>
                </WrapperLogo>
                <WrapperMenu>
                  <DesktopMenu />
                </WrapperMenu>
              </>

              <WrapperRight>
                <WrapperHelp>
                  <a href="/#/help">
                    <i className="fa fa-question-circle" />
                  </a>
                </WrapperHelp>
                <WrapperProfile>
                  {user && user.StatusPhotoId > 0 && user.Username ? (
                    <Avatar
                      onClick={handlePopoverOpen}
                      contact={user}
                      h={36}
                      w={36}
                    />
                  ) : (
                    <ProfileButton onClick={handlePopoverOpen}>
                      <AccountCircleIcon fontSize="large" />
                    </ProfileButton>
                  )}
                </WrapperProfile>
              </WrapperRight>
            </WrapperGeneral>
          </Toolbar>
        </AppBar>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {user && (
          <Profile user={user} handlePopoverClose={handlePopoverClose} />
        )}
      </Popover>
    </>
  );
};

export default AppBarDrawer;
