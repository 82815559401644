import React, { useState } from 'react';
import config from '../../../config';
import { PinMap } from './styles';
import PopupCard from '../Popup';

export default function Marker(props) {
  const { contact /*, map*/ } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const mainStyle = {
    position: 'relative',
    left: '-23px',
    top: '-65px',
    width: '46px',
    height: '65px',
    transformOrigin: 'right bottom',
    border: '1px solid transparent',
    cursor: 'pointer',
  };

  const markerStyle = {
    backgroundColor: `${
      contact.Color
        ? contact.Color.toUpperCase().substr(0, 4) === '#FFF'
          ? '#C0C0C0'
          : contact.Color
        : '#C0C0C0'
    }`,
    backgroundImage: contact.Username
      ? `url('${config.picUrl + contact.Username}.jpg')`
      : '',
    lineHeight: '34px',
    height: '34px',
    width: '34px',
    display: 'block',
    borderRadius: '50%',
    marginLeft: '6px',
    marginTop: '6px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    textAlign: 'center',
    color: '#fff',
    fontSize: '20px',
    cursor: 'pointer',
  };

  // const onClick = (e) => {
  // }

  const toggleDefault = (e) => {
    setAnchorEl(null);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={mainStyle}
      onClick={toggleDefault}
      onMouseEnter={toggleDefault}
      onMouseLeave={handleClose}
    >
      <div style={markerStyle}>
        <div>{contact.Username ? '' : contact.Initials}</div>
        <PinMap
          key={'pin_' + contact.ContactId}
          backColor={contact.Online ? 'green' : '#337ab7'}
        />
      </div>
      {anchorEl && (
        <div
          style={{
            marginLeft: '50px',
            marginTop: '-10px',
            backgroundColor: '#fff !important',
          }}
        >
          <PopupCard contact={contact} />
        </div>
      )}
    </div>
  );
}
