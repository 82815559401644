import {
  Button,
  DateBox,
  LoadPanel,
  Popup,
  SelectBox,
  Validator,
} from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { array, bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import API from 'services/API';
import useAllADUserEmrAccess from '../../../../../services/requests/useAllADUserEmrAccess';
import { FieldRow, FieldSet, GroupContainer, WrapperButtons } from './styles';

function PopupUserTraining({
  visible,
  onClose,
  refetch,
  currentUserTraining,
  trainingId,
  currentUserId,
  trainings,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [userTrainingDraft, setUserTrainingDraft] = useState(null);
  const { data: usersList } = useAllADUserEmrAccess();

  useEffect(() => {
    if (currentUserTraining?.data) {
      setUserTrainingDraft({ ...currentUserTraining?.data });
    } else {
      setUserTrainingDraft({
        UserId: currentUserId || null,
        TrainingId: trainingId || null,
        // ScheduledDate: '',
        CompletedDate: '',
        HospitalGroupId: '',
      });
    }
  }, [currentUserTraining, trainingId, currentUserId]);

  const HospitalGroups =
    trainings?.find(
      (training) => training.TrainingId === userTrainingDraft?.TrainingId
    )?.HospitalGroups || [];

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const trainingActive = trainings.find(
      (training) => training.TrainingId === userTrainingDraft.TrainingId
    ).Active;

    if (!trainingActive) {
      notify('Non-active training cannot be assigned to users.', 'error', 5000);
      return;
    }

    if (!userTrainingDraft?.UserId) {
      notify('You need to select a user', 'error', 5000);
      return;
    }
    if (!userTrainingDraft?.ScheduledDate) {
      notify('You need to choose a Scheduled Date', 'error', 5000);
      return;
    }
    if (
      JSON.stringify(currentUserTraining.data) ===
      JSON.stringify(userTrainingDraft)
    ) {
      onClose();
      return;
    }

    setIsSaving(true);
    try {
      const dataToSend = userTrainingDraft;

      if (!HospitalGroups.length) {
        delete dataToSend?.HospitalGroupId;
      }

      if (!dataToSend?.CompletedDate) {
        delete dataToSend?.CompletedDate;
      }
      if (!dataToSend?.UserTrainingId) {
        delete dataToSend?.UserTrainingId;
      }

      const res = await API.sendRequest(
        'UserTraining/Save',
        'post',
        dataToSend
      );
      if (res.data.ErrorMsg) {
        notify(res.data.ErrorMsg, 'error', 5000);
        setIsSaving(false);
        return;
      }

      setIsSaving(false);
      refetch();
      notify('User Training saved successfully', 'success', 5000);
      setUserTrainingDraft(null);
      onClose();
    } catch (e) {
      setIsSaving(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  return (
    <>
      <LoadPanel
        visible={isSaving}
        message={isSaving ? 'Saving...' : 'Loading...'}
      />
      <Popup
        title={`${
          currentUserTraining?.type === 'NEW' ? 'New' : 'Edit'
        } Staff Training`}
        visible={visible}
        onHiding={onClose}
        width={550}
        height="auto"
        dragEnabled={false}
        showCloseButton
      >
        <GroupContainer onSubmit={handleSaveChanges}>
          <FieldRow>
            <FieldSet $width="49%">
              <label className="label">Staff:</label>
              <SelectBox
                width="380px"
                dataSource={usersList}
                valueExpr="UserId"
                displayExpr="DisplayName"
                searchEnabled
                readOnly={
                  currentUserTraining?.type === 'EDIT' || !!currentUserId
                }
                searchExpr="DisplayName"
                value={userTrainingDraft?.UserId}
                onValueChanged={(e) =>
                  setUserTrainingDraft((prevState) => ({
                    ...prevState,
                    UserId: e.value,
                  }))
                }
                showClearButton
              >
                <Validator>
                  <RequiredRule message="Staff is required" />
                </Validator>
              </SelectBox>
            </FieldSet>
          </FieldRow>
          <FieldRow>
            <FieldSet $width="49%">
              <label className="label">Training:</label>
              <SelectBox
                width="380px"
                dataSource={trainings}
                valueExpr="TrainingId"
                displayExpr="TrainingDesc"
                searchEnabled
                readOnly={currentUserTraining?.type === 'EDIT' || !!trainingId}
                searchExpr="TrainingDesc"
                value={userTrainingDraft?.TrainingId}
                onValueChanged={(e) =>
                  setUserTrainingDraft((prevState) => ({
                    ...prevState,
                    TrainingId: e.value,
                    HospitalGroupId: null,
                  }))
                }
                showClearButton
              >
                <Validator>
                  <RequiredRule message="Training is required" />
                </Validator>
              </SelectBox>
            </FieldSet>
          </FieldRow>
          {!!HospitalGroups.length && (
            <FieldRow>
              <FieldSet $width="49%">
                <label className="label">Okta Tile:</label>
                <SelectBox
                  width="380px"
                  dataSource={HospitalGroups}
                  visible={!!HospitalGroups.length}
                  valueExpr="HospitalGroupId"
                  displayExpr="HospitalGroupName"
                  searchEnabled
                  readOnly={currentUserTraining?.type === 'EDIT'}
                  searchExpr="HospitalGroupName"
                  value={userTrainingDraft?.HospitalGroupId}
                  onValueChanged={(e) =>
                    setUserTrainingDraft((prevState) => ({
                      ...prevState,
                      HospitalGroupId: e.value,
                    }))
                  }
                  showClearButton
                >
                  <Validator>
                    {!!HospitalGroups?.length && (
                      <RequiredRule message="Okta Tile is required" />
                    )}
                  </Validator>
                </SelectBox>
              </FieldSet>
            </FieldRow>
          )}
          <FieldRow>
            <FieldSet $width="49%">
              <label className="label">Scheduled Date:</label>
              <DateBox
                width="380px"
                dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
                value={userTrainingDraft?.ScheduledDate}
                placeholder="00/00/0000"
                onValueChanged={(e) =>
                  setUserTrainingDraft((prevState) => ({
                    ...prevState,
                    ScheduledDate: e.value,
                  }))
                }
                min={currentUserTraining?.type === 'NEW' && new Date()}
              >
                <Validator>
                  <RequiredRule message="Scheduled Date is required" />
                </Validator>
              </DateBox>
            </FieldSet>
          </FieldRow>
          <FieldRow>
            <FieldSet $width="49%">
              <label className="label">Completed Date:</label>
              <DateBox
                width="380px"
                dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
                value={userTrainingDraft?.CompletedDate}
                placeholder="00/00/0000"
                onValueChanged={(e) =>
                  setUserTrainingDraft((prevState) => ({
                    ...prevState,
                    CompletedDate: e.value,
                  }))
                }
                dateOutOfRangeMessage="Completed date cannot be less than Schedule date"
                min={new Date(userTrainingDraft?.ScheduledDate)}
                max={new Date()}
              />
            </FieldSet>
          </FieldRow>
          <WrapperButtons>
            <Button
              text="OK"
              type="success"
              useSubmitBehavior
              disabled={
                userTrainingDraft?.CompletedDate
                  ? new Date(userTrainingDraft?.CompletedDate) <
                    new Date(userTrainingDraft?.ScheduledDate)
                  : false
              }
            />
            <Button text="Cancel" type="normal" onClick={onClose} />
          </WrapperButtons>
        </GroupContainer>

        {/* {popupButtons.map((options, index) => (
          <ToolbarItem
            key={index}
            widget="dxButton"
            location="after"
            toolbar="bottom"
            useSubmitBehavior
            options={{ ...options }}
          />
        ))} */}
      </Popup>
    </>
  );
}

export default PopupUserTraining;

PopupUserTraining.propTypes = {
  visible: bool,
  onClose: func.isRequired,
  currentUserTraining: object,
  refetch: func.isRequired,
  trainingId: string,
  currentUserId: string,
  trainings: array,
};

PopupUserTraining.defaultProps = {
  visible: false,
  currentUserTraining: {},
  trainingId: '',
  currentUserId: '',
  trainings: [],
};
