import React, { useEffect, useRef, useState } from 'react';

import {
  Column,
  DataGrid,
  Editing,
  Pager,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid';

import { Button } from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import API from '../../services/API';

// import ProgressStatus from '../../components/ProgressStatus';

import { BreadCrumbRender, LoadPermissions } from '../../global';
import usePermissions from '../../hooks/usePermissions';
import useStyles from './styles';

import HistoryPopup from '../../components/popups/HistoryPopup';
import getChangeHistory from '../../utils/getChangeHistory';
import DeletePrivateContactPopup from './components/DeletePrivateContactPopup';
import EditPrivateContactPopup from './components/EditPrivateContactPopup';
import MembersPopup from './components/MembersPopup';
import { formatPhoneNumber } from 'utils/format';

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const PrivateContacts = () => {
  const classes = useStyles();

  const [contacts, setContacts] = useState(null);
  const [phoneTypes, setPhoneTypes] = useState(null);

  const [currentContact, setCurrentContact] = useState(null);
  const [contactChangeHistory, setContactChangeHistory] = useState(null);
  const [contactDeleteVisible, setContactDeleteVisible] = useState(false);
  const [contactEditVisible, setContactEditVisible] = useState(false);
  const [membersPopupVisible, setMembersPopupVisible] = useState(false);
  // const [isLoadingSync, setIsLoadingSync] = useState(false);

  const [historyPopupVisible, setHistoryPopupVisible] = useState(false);

  const dataGridRef = useRef();

  function loadContactData(isSubscribed) {
    loadData('PrivateContact').then((contactsList) => {
      if (isSubscribed) {
        setContacts(contactsList);
      }
    });
  }

  useEffect(() => {
    let isSubscribed = true;

    loadContactData(isSubscribed);

    loadData('PhoneType').then((phoneTypesList) => {
      if (isSubscribed) {
        setPhoneTypes(phoneTypesList);
      }
    });

    return () => {
      isSubscribed = false;
    };

    // eslint-disable-next-line
  }, []);

  const loadData = async (uri) => {
    const res = await API.sendRequest(uri, 'GET');

    return res.data;
  };

  const handleEditContact = async (data) => {
    loadData(`PrivateContact/${data.PrivateContactId}`).then(
      (contactComplete) => {
        setCurrentContact(contactComplete);
        setContactEditVisible(true);
      }
    );
  };

  const handleShowChangeHistory = async (data) => {
    await getChangeHistory('PrivateContact', data.PrivateContactId).then(
      (ret) => {
        if (ret) {
          setContactChangeHistory(ret);
          setHistoryPopupVisible(true);
        }
      }
    );
  };

  const deleteClick = (data) => {
    setCurrentContact(data);
    setContactDeleteVisible(true);
  };

  const addNewContact = () => {
    setCurrentContact({
      PrivateContact: {
        Prefix: '',
        DisplayName: '',
        FirstName: '',
        LastName: '',
        Initials: '',
        Company: '',
        Department: '',
        JobTitle: '',
        Description: '',
        Active: true,
        Email: '',
      },
      Phones: [],
    });

    setContactEditVisible(true);
  };

  // const handleSyncContacts = async () => {
  //   setIsLoadingSync(true);
  //   try {
  //     const res = await API.sendRequest('PrivateContact/Sync', 'POST');
  //     if (res.status !== 200) {
  //       throw new Error(res.message);
  //     }
  //     notify(`Hidden Contacts synced successfully`, 'success', 5000);
  //     loadContactData(true);
  //     setIsLoadingSync(false);
  //   } catch (error) {
  //     setIsLoadingSync(false);
  //     notify(`Error: ${error.message}`, 'error', 5000);
  //   }
  // };

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  const onToolbarPreparing = (e) => {
    if (ACL.AddNew) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: addNewContact,
        },
      });
    }
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'breadCrumb',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          height: 36,
          icon: 'file',
          text: 'Members',
          hint: 'Private contacts members',
          onClick: () => {
            setMembersPopupVisible(true);
          },
        },
      }
    );
    // e.toolbarOptions.items.unshift({
    //   location: 'after',
    //   widget: 'dxButton',
    //   options: {
    //     icon: isLoadingSync ? 'clock' : 'refresh',
    //     disabled: isLoadingSync,
    //     hint: 'Sync Private Contacts',
    //     onClick: handleSyncContacts,
    //   },
    // });
  };

  const getActionsButtons = ({ data }) => (
    <>
      {(ACL?.Update || ACL?.Admin) && (
        <>
          {ACL?.Admin && (
            <Button
              hint="Show Change History"
              icon="fas fa-history"
              onClick={() => handleShowChangeHistory(data)}
              stylingMode="text"
              type="default"
            />
          )}
          {ACL?.Update && (
            <Button
              hint="Edit Contact"
              icon="fas fa-address-card"
              onClick={() => handleEditContact(data)}
              stylingMode="text"
              type="default"
            />
          )}
        </>
      )}
      {ACL?.Delete && (
        <Button
          hint="Delete Contact"
          icon="trash"
          onClick={() => deleteClick(data)}
          stylingMode="text"
          type="default"
        />
      )}
    </>
  );

  const updateContactGrid = async () => {
    loadData('PrivateContact').then((contactsList) => {
      setContacts(contactsList);
    });
  };

  const updateContacts = (PrivateContactId) => {
    setContacts((state) =>
      state.filter((contact) => contact.PrivateContactId !== PrivateContactId)
    );
  };
  console.log()
  return (
    <div className={classes.divGridContainer}>
      {historyPopupVisible && (
        <HistoryPopup
          historyType="json"
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={contactChangeHistory}
        />
      )}
      {currentContact && contactEditVisible && (
        <EditPrivateContactPopup
          // areas={areas}
          phoneTypes={phoneTypes}
          // categories={categories}
          isVisible={contactEditVisible}
          setIsVisible={setContactEditVisible}
          updateContactGrid={updateContactGrid}
          currentContact={currentContact}
        />
      )}
      {currentContact && contactDeleteVisible && (
        <DeletePrivateContactPopup
          visible={contactDeleteVisible}
          contact={currentContact}
          onVisibleChange={setContactDeleteVisible}
          updateContacts={updateContacts}
        />
      )}
      <MembersPopup
        isVisible={membersPopupVisible}
        setIsVisible={setMembersPopupVisible}
      />
      {/* <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={isLoadingSync}
        message="Synchronizing Hidden Contacts..."
        closeOnOutsideClick={false}
      /> */}
      {
        contacts && (
          <DataGrid
            id="gridContainer"
            dataSource={contacts}
            keyExpr="PrivateContactId"
            allowColumnReordering
            showBorders
            rowAlternationEnabled
            showRowLines
            focusedRowEnabled
            onRowUpdating={onRowUpdating}
            headerFilter={{ visible: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            ref={dataGridRef}
            onToolbarPreparing={onToolbarPreparing}
          >
            <SearchPanel visible width={240} placeholder="Search..." />

            <Paging defaultPageSize={15} />
            <Pager
              showPageSizeSelector
              allowedPageSizes={[15, 30, 50, 100, 200, 400, 600]}
              showInfo
            />
            <Editing useIcons mode="popup" allowUpdating />

            <Column dataField="Prefix" width={100} />
            <Column dataField="FirstName" />
            <Column dataField="LastName" />
            <Column dataField="JobTitle" />

            <Column dataField="Email" />
            <Column dataField="PhoneNumber" mask="(000) 000-0000" width='auto' cellRender={(data) => data.value && formatPhoneNumber(data.value)} />

            <Column dataField="Active" width={100} />
            <Column
              caption="Actions"
              type="buttons"
              cellRender={getActionsButtons}
              width={135}
            />
            <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
          </DataGrid>
        )
        //  : (
        //   <ProgressStatus />
        // )
      }
    </div>
  );
};

export default PrivateContacts;
