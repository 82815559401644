import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 28px;
  padding-left: 8px;
`;

export const TopControls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0 8px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100% - 80px);
  height: fit-content;
  background-color: white;
  border: 1px solid #cccccc60;
  gap: 12px;
  padding: 0 12px 12px;
`;

export const WrapperField = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 148px);
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #ddd;
`;

export const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 12px;
`;
