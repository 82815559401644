import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import ADUser from '../../pages/ADUser';
import ADUserFromAD from '../../pages/ADUserFromAD';
import AppointmentType from '../../pages/AppointmentType';
import Area from '../../pages/Area';
import City from '../../pages/City';
import ContactGroup from '../../pages/ContactGroup';
import Country from '../../pages/Country';
import County from '../../pages/County';
import Department from '../../pages/Department';
import Device from '../../pages/Device';
import DisabledUsers from '../../pages/DisabledUsers';
import EMRAccess from '../../pages/EMRAccess';
import { EmrAccessProvider } from '../../pages/EMRAccess/contexts/EmrAccessContext';
import Event from '../../pages/Event';
// import Contact from '../../pages/Contact';
import ACTAccessTermination from 'pages/ACTAccessTermination';
import ACTExpirationSearch from 'pages/ACTExpirationSearch';
import AccountDeletion from 'pages/AccountDeletion';
import ExportStaff from 'pages/ExportStaff';
import LogErrors from 'pages/LogErrors';
import ActiveCases from '../../pages/ActiveCases';
import DistributionList from '../../pages/DistributionList';
import EMRCredentialsLoading from '../../pages/EMRCredentialsLoading';
import EMRSetup from '../../pages/EMRSetup';
import ExternalContacts from '../../pages/ExternalContacts';
import ExternalUser from '../../pages/ExternalUser';
import FileExplorer from '../../pages/FileExplorer';
import Help from '../../pages/Help';
import HelpPicture from '../../pages/HelpPicture';
import HelpText from '../../pages/HelpText';
import HelpTextGroup from '../../pages/HelpTextGroup';
import HelpTopic from '../../pages/HelpTopic';
import HierarchyPage from '../../pages/HierarchyPage';
import Holiday from '../../pages/Holiday';
import Hospital from '../../pages/Hospital';
import HospitalGroup from '../../pages/HospitalGroup';
import ImpersonateLogin from '../../pages/ImpersonateLogin';
import InterestType from '../../pages/InterestType';
import LaboratoryReportV2 from '../../pages/Laboratory-v2';
import LaboratoryReportV1 from '../../pages/Laboratory/Report';
import Login from '../../pages/Login';
import MapContacts from '../../pages/MapContacts';
import Menu from '../../pages/Menu';
import OktaApplications from '../../pages/OktaApplications';
import { OktaAppProvider } from '../../pages/OktaApplications/context/OktaAppContext';
import Performance from '../../pages/Performance';
import PhoneType from '../../pages/PhoneType';
import PrivateContacts from '../../pages/PrivateContacts';
import QueryContact from '../../pages/QueryContacts';
import Report from '../../pages/Report';
import Role from '../../pages/Role';
import Schedule from '../../pages/Schedule';
import StaffOn from '../../pages/StaffOn';
import StaffTeams from '../../pages/StaffTeams';
import State from '../../pages/State';
import Template from '../../pages/Template';
import TimeZone from '../../pages/TimeZone';
import Training from '../../pages/Training';
import User from '../../pages/User';
import UserType from '../../pages/UserType';
import API from '../../services/API';
import PrivateRoute from './PrivateRoute';

const Routes = () => {
  const [areasWithSchedules, setAreasWithSchedules] = useState([]);

  useEffect(() => {
    async function getAreasWithSchedule() {
      const { data } = await API.sendRequest(`Menu/ScheduleList`, 'get');
      setAreasWithSchedules(data);
    }
    getAreasWithSchedule();
  }, []);

  return (
    <>
      <PrivateRoute exact path="/" component={ExternalContacts} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/impersonate-login" component={ImpersonateLogin} />
      <Route exact path="/account-deletion" component={AccountDeletion} />
      <Route
        exact
        path="/setEMRCredentials"
        component={EMRCredentialsLoading}
      />
      {/* <PrivateRoute exact path="/contacts" component={Contact} /> */}
      <PrivateRoute exact path="/contacts" component={ExternalContacts} />
      <PrivateRoute
        exact
        path="/private-contacts"
        component={PrivateContacts}
      />
      <PrivateRoute
        exact
        path="/distribution-list"
        component={DistributionList}
      />
      {/* <PrivateRoute exact path="/contacts2" component={ContactPage} /> */}

      <PrivateRoute exact path="/menus" component={Menu} />

      <PrivateRoute exact path="/helptopics" component={HelpTopic} />
      <PrivateRoute exact path="/helptextgroups" component={HelpTextGroup} />
      <PrivateRoute exact path="/helptexts" component={HelpText} />
      <PrivateRoute exact path="/helppictures" component={HelpPicture} />
      <PrivateRoute exact path="/ad-hierarchy" component={HierarchyPage} />
      <PrivateRoute exact path="/staff-on" component={StaffOn} />
      <PrivateRoute exact path="/contacts-query" component={QueryContact} />
      <PrivateRoute exact path="/active-cases" component={ActiveCases} />
      <PrivateRoute exact path="/file-explorer" component={FileExplorer} />

      {/* Schedules */}
      {areasWithSchedules.length > 0 &&
        areasWithSchedules.map(
          (area) =>
            area.AreaId && (
              <PrivateRoute
                exact
                key={area.MenuId}
                path={`/${area.Url}`}
                component={Schedule}
                area={area.AreaId}
                areaData={area}
              />
            )
        )}

      <PrivateRoute
        exact
        path="/schedule-lab-v1"
        component={LaboratoryReportV1}
        area={4}
        mobile
      />

      <PrivateRoute
        exact
        path="/schedule-lab-v2"
        component={LaboratoryReportV2}
        area={4}
        mobile
      />

      <PrivateRoute exact path="/ADUsers" component={ADUser} />
      <PrivateRoute exact path="/export-staff" component={ExportStaff} />
      <PrivateRoute exact path="/travelers" component={ADUser} />
      <PrivateRoute exact path="/onlegalhold" component={ADUser} />
      <PrivateRoute exact path="/onleave" component={ADUser} />
      <PrivateRoute exact path="/others" component={ADUser} />
      <PrivateRoute exact path="/disabled-users" component={DisabledUsers} />
      <PrivateRoute exact path="/ADUsersFromAD" component={ADUserFromAD} />
      <PrivateRoute exact path="/events" component={Event} />
      <PrivateRoute
        exact
        path="/appointmenttypes"
        component={AppointmentType}
      />
      <PrivateRoute exact path="/countries" component={Country} />
      <PrivateRoute exact path="/counties" component={County} />
      <PrivateRoute exact path="/cities" component={City} />
      <PrivateRoute exact path="/states" component={State} />
      <PrivateRoute exact path="/contactgroups" component={ContactGroup} />
      <PrivateRoute exact path="/template" component={Template} />
      <PrivateRoute exact path="/act-setup" component={EMRSetup} />
      <PrivateRoute exact path="/hospitalGroups" component={HospitalGroup} />
      <PrivateRoute exact path="/hospitals" component={Hospital} />
      <PrivateRoute exact path="/training" component={Training} />
      <PrivateRoute exact path="/holidays" component={Holiday} />
      <PrivateRoute exact path="/phonetypes" component={PhoneType} />
      <PrivateRoute exact path="/timezones" component={TimeZone} />
      <PrivateRoute exact path="/roles" component={Role} />
      <PrivateRoute exact path="/areas" component={Area} />
      <PrivateRoute exact path="/depts" component={Department} />
      <PrivateRoute exact path="/devices" component={Device} />
      <PrivateRoute exact path="/users" component={User} />
      <PrivateRoute exact path="/usertypes" component={UserType} />
      <PrivateRoute exact path="/externalusers" component={ExternalUser} />
      <PrivateRoute exact path="/interesttypes" component={InterestType} />
      <PrivateRoute exact path="/map" component={MapContacts} />
      <PrivateRoute exact path="/help" component={Help} />
      <PrivateRoute exact path="/reports" component={Report} />
      <PrivateRoute exact path="/performance" component={Performance} />
      <PrivateRoute exact path="/teams" component={StaffTeams} />
      <PrivateRoute
        exact
        path="/act-expiration"
        component={ACTExpirationSearch}
      />
      <PrivateRoute exact path="/log-errors" component={LogErrors} />
      <OktaAppProvider>
        <PrivateRoute
          exact
          path="/okta-applications"
          component={OktaApplications}
        />
      </OktaAppProvider>
      <EmrAccessProvider>
        <Route exact path="/act-request" component={EMRAccess} />
      </EmrAccessProvider>
      <Route
        exact
        path="/act-access-termination"
        component={ACTAccessTermination}
      />
    </>
  );
};

export default Routes;
