import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 34px);
  width: 100%;

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 12px;
  }

  .dropItem {
    width: 100%;
  }
`;

export const WrapperScheduleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;

  gap: 8px;
  width: 100%;
`;

export const WrapperContent = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  gap: 12px;
  align-items: center;

  user-select: none;

  label {
    padding-left: 2px;
    font-weight: 500;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 84px;
  gap: 8px;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const WrapperControls = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 12px;

  height: 34px;
`;
