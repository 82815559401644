import { object } from 'prop-types';
import React from 'react';
import useEmrRequestStatusList from '../../../services/requests/useEmrRequestStatusList';
import { StatusContainer } from './styles';

const StatusCellRender = ({ data }) => {
  const { data: requestStatusDataList } = useEmrRequestStatusList();

  return (
    <StatusContainer
      $iconColor={
        requestStatusDataList?.find(
          (status) => status.EMRRequestStatusId === data.value
        )?.EMRRequestStatusColor
      }
    >
      <i
        className={
          requestStatusDataList?.find(
            (status) => status.EMRRequestStatusId === data.value
          )?.EMRRequestStatusIcon
        }
      />
      <span>
        {`${
          requestStatusDataList?.find(
            (status) => status.EMRRequestStatusId === data.value
          )?.EMRRequestStatusName
        }`}
      </span>
    </StatusContainer>
  );
};

StatusCellRender.propTypes = {
  data: object.isRequired,
};

export default StatusCellRender;
