import styled from 'styled-components';
import { Grid, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const NJGrid = styled(Grid)`
  height: ${parseInt(window.innerHeight * 0.8) - 100}px;
`;

export default makeStyles(theme => ({
  align: {
    alignContent: 'right'
  },
  padding: {
    padding: '2px'
  },
  button: {
    margin: '5px',
    width: '120px'
  }
}))