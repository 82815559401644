import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  height: 100%;
`;

export const Label = styled.span`
  font-size: 16px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
`;

export const OptionsItem = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RadioLabel = styled.div`
  &:last-child {
    margin-top: 10px;
  }

  span {
    font-size: 16px;
  }
`;
