import React, { useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { bool, func, object } from 'prop-types';

import { Container, LabelContainer, Text } from './styles';
import API from '../../../../../services/API';

function DeleteMenuPopup({ visible, setVisible, currentArea }) {
  const [deleting, setDeleting] = useState(false);

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        handleDeleteMenu();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setVisible(false);
      },
    },
  ];

  const handleDeleteMenu = async () => {
    setDeleting(true);
    try {
      const areaToRemoveMenu = {
        AreaId: currentArea.AreaId,
        AreaDesc: currentArea.AreaDesc,
        DistributionList: currentArea.DistributionList,
        SummaryBefore: currentArea.SummaryBefore,
        UseInSchedule: false,
        ScheduleMenuId: null,
      };

      const resArea = await API.sendRequest('Area', 'post', areaToRemoveMenu);

      if (resArea.status !== 200) {
        throw new Error(resArea.message);
      }
      const resMenu = await API.sendRequest(
        `Menu/${currentArea.ScheduleMenuId}`,
        'delete'
      );
      if (resMenu.status !== 200) {
        throw new Error(resArea.message);
      }

      notify(
        'Menu removed with success. The application will reload.',
        'success',
        2000
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (e) {
      setDeleting(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  return (
    <Popup
      visible={visible}
      onHidden={() => setVisible(false)}
      width={420}
      height={250}
      showCloseButton={false}
      title="Warning"
      disabled={deleting}
    >
      <Container>
        <LabelContainer>
          <Text bold>
            This action will delete the {currentArea.AreaDesc} schedule menu.
          </Text>
          <Text>
            After clicking on the DELETE button the application will restart to
            apply the changes.
          </Text>
          <Text bold>Are you sure?</Text>
        </LabelContainer>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

export default DeleteMenuPopup;

DeleteMenuPopup.propTypes = {
  visible: bool.isRequired,
  setVisible: func.isRequired,
  currentArea: object.isRequired,
};

DeleteMenuPopup.defaultProps = {
  visible: false,
  setVisible: () => {},
  currentArea: {},
};
