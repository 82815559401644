import React, { useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { object, bool, func } from 'prop-types';
import notify from 'devextreme/ui/notify';

import { Container, LabelContainer, Text } from './styles';
import API from '../../../../services/API';

function AppointmentTypeDeletePopup({
  currentAppType,
  visible,
  setVisible,
  updateGrid,
}) {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await API.sendRequest(
        `AppointmentType/${currentAppType.AppointmentTypeId}`,
        'delete'
      );

      if (res.status !== 200) {
        throw new Error(res.message);
      }
      updateGrid();
      setDeleting(false);
      setVisible(false);
      notify('Appointment type removed.', 'success', 2000);
    } catch (e) {
      setDeleting(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        handleDelete();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setVisible(false);
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      onHiding={() => setVisible(false)}
      width={500}
      height={250}
      showCloseButton={false}
      title="Appointment Type - Delete"
      disabled={deleting}
    >
      <Container>
        <LabelContainer>
          <Text bold>
            {currentAppType.AppointmentTypeDesc !== ''
              ? `The [ ${currentAppType.AppointmentTypeDesc} ] appointment will be deleted. This action cannot be undone.`
              : 'The appointment type will be deleted. This action cannot be undone.'}
          </Text>
          <Text>Are you sure?</Text>
        </LabelContainer>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

AppointmentTypeDeletePopup.propTypes = {
  currentAppType: object.isRequired,
  visible: bool.isRequired,
  setVisible: func.isRequired,
  updateGrid: func.isRequired,
};

export default AppointmentTypeDeletePopup;
