import React, { useState, useEffect } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import TileView from 'devextreme-react/tile-view';
import notify from 'devextreme/ui/notify';
import { LoadPanel } from 'devextreme-react/load-panel';
import { bool, func, object } from 'prop-types';

import { faList } from '../../../../../helpers/faList';
import API from '../../../../../services/API';
import config from '../../../../../config';

import {
  Container,
  TileContainer,
  IconItem,
  Text,
  LabelContainer,
} from './styles';

function MenuPopup({ visible, setVisible, currentArea }) {
  const [iconSelected, setIconSelected] = useState(null);
  const [isIconEditing, setIsIconEditing] = useState(false);
  const [currentAreaMenu, setCurrentAreaMenu] = useState(null);
  const [panelMessage, setPanelMessage] = useState('Loading...');
  const [panelVisible, setPanelVisible] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (currentArea.ScheduleMenuId) {
        setIsIconEditing(true);
        const { data } = await API.sendRequest(
          `Menu/${currentArea.ScheduleMenuId}`,
          'get'
        );
        setCurrentAreaMenu(data);
        setIconSelected({ Name: data.Icon });
      }
    };
    init();
  }, [currentArea]);

  const texts = {
    success: isIconEditing ? 'Icon changed' : 'Menu created',
    saveButton: isIconEditing ? 'Update' : 'Create',
    warning: isIconEditing ? 'UPDATE' : 'CREATE',
  };

  const buttonsOptions = [
    {
      text: texts.saveButton,
      type: 'success',
      onClick: () => {
        handleCreateMenu();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setVisible(false);
      },
    },
  ];

  const handleSelectionChanged = (e) => {
    setIconSelected({ Name: e.itemData.Name });
  };

  const handleCreateMenu = async () => {
    if (!iconSelected) {
      notify('Please, select an icon first.', 'error', 3000);
      return;
    }

    setPanelMessage('Saving...');
    setPanelVisible(true);

    const childrenList = (await API.sendRequest('Menu/ChildrenOnly/1', 'get'))
      .data;

    const lastMenuOrder = childrenList.reduce((acc, cur) => {
      if (cur.ParentMenuId === config.menuScheduleId) {
        return acc > cur.MenuOrder ? acc : cur.MenuOrder;
      }
      return acc;
    });

    let areaName = currentArea.AreaDesc;
    const menuToSave = {
      MenuId: isIconEditing ? currentAreaMenu.MenuId : null,
      MenuCaption: isIconEditing ? currentAreaMenu.MenuCaption : areaName,
      MenuOrder: isIconEditing ? currentAreaMenu.MenuOrder : lastMenuOrder + 1,
      MenuFullCaption: isIconEditing
        ? currentAreaMenu.MenuFullCaption
        : areaName,
      ParentMenuId: config.menuScheduleId,
      Url: isIconEditing
        ? currentAreaMenu.Url
        : `schedule-${areaName
            .replaceAll(' ', '')
            .replaceAll('&', 'and')
            .toLowerCase()}`,
      Icon: iconSelected.Name,
      MenuRootId: 1,
      Active: true,
    };

    try {
      const resMenu = await API.sendRequest('Menu', 'post', menuToSave);
      if (resMenu.status !== 200) {
        throw new Error(resMenu.message);
      }
      if (!isIconEditing) {
        const areaToSave = {
          ...currentArea,
          ScheduleMenuId: resMenu.data.MenuId,
        };

        const resArea = await API.sendRequest('Area', 'post', areaToSave);
        if (resArea.status !== 200) {
          throw new Error(resArea.message);
        }
      }

      notify(
        `${texts.success} with success. The application will reload.`,
        'success',
        2000
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (e) {
      setPanelVisible(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const iconTileRender = ({ data }) => {
    return (
      <TileContainer>
        <IconItem className={data && data.Name} />
      </TileContainer>
    );
  };

  return (
    <>
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={panelVisible}
        message={panelMessage}
        closeOnOutsideClick={false}
      />
      <Popup
        visible={visible}
        onHidden={() => setVisible(false)}
        width={600}
        height={500}
        showCloseButton={false}
        title="Icon selection"
      >
        <Container>
          <LabelContainer>
            <Text>{`Select an icon that best describes the ${currentArea.AreaDesc} area.`}</Text>
          </LabelContainer>

          <TileView
            dataSource={faList}
            itemComponent={iconTileRender}
            baseItemHeight={40}
            baseItemWidth={40}
            itemMargin={10}
            showScrollbar
            direction="vertical"
            height={170}
            selectedItem={iconSelected && { Name: iconSelected.Name }}
            onItemClick={handleSelectionChanged}
            hoverStateEnabled={false}
          />
          <LabelContainer>
            <Text bold>Selected Icon</Text>
            <IconItem className={iconSelected && iconSelected.Name} />
          </LabelContainer>
          <LabelContainer>
            <Text>
              WARNING: After clicking on the {texts.warning} button the
              application will restart to apply the changes.
            </Text>
          </LabelContainer>
        </Container>
        {buttonsOptions.map((buttonOpt, index) => (
          <ToolbarItem
            key={index}
            options={{ stylingMode: 'contained', ...buttonOpt }}
            widget="dxButton"
            toolbar="bottom"
            location="after"
          />
        ))}
      </Popup>
    </>
  );
}

export default MenuPopup;

MenuPopup.propTypes = {
  visible: bool.isRequired,
  setVisible: func.isRequired,
  currentArea: object.isRequired,
};

MenuPopup.defaultProps = {
  visible: false,
  setVisible: () => {},
  currentArea: {},
};
