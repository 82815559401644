import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);

  .diagram {
    width: 100%;
    height: 100%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #fff;
  width: 100%;
`;

export const ListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 90%;
  padding: 10px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  gap: 10px;
`;

export const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
  padding: 20px;

  .userName {
    font-size: 1rem;
    font-weight: 500;
  }

  .userOffice {
    font-size: 0.8rem;
    font-weight: 400;
    color: #aaa;
  }
`;

export const UserDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;
