/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-one-expression-per-line */
import notify from 'devextreme/ui/notify';
import { any, bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import API from '../../../services/API';
import { emrSendRequests } from '../../../services/requests/emrRequestsPost';
import { RequestButton, SeeEmailButton, StatusContainer } from './styles';

const RequestMethodCell = ({
  data,
  ACL,
  emailViewRef,
  sendEmailPopupRef,
  isExpirationScreen,
  isTerminationScreen,
  forceRefetch,
  docuSignTemplateList,
}) => {
  const queryClient = useQueryClient();
  const [requestUpdating, setRequestUpdating] = useState(false);
  const [documentIsDownloading, setDocumentIsDownloading] = useState(false);
  const { data: requestData } = data;
  const {
    mutate: sendEMRRequests,
    isLoading: sendEMRRequestsIsLoading,
    isSuccess: sendEMRRequestsIsSuccess,
    isError: sendEMRRequestsIsError,
    error: sendEMRRequestsErrorData,
  } = useMutation((requests) => emrSendRequests(requests), {
    onSuccess: async ({ data: requests }) => {
      let userIdsToInvalidate = [];
      if (Array.isArray(requests)) {
        requests.forEach((request) => {
          userIdsToInvalidate.push(request.UserId);
        });
        userIdsToInvalidate = [...new Set([...userIdsToInvalidate])]; // remove duplicates
        userIdsToInvalidate.forEach(async (userId) => {
          await queryClient.invalidateQueries(['adUserEmrAccessList', userId]);
        });
      } else {
        await queryClient.invalidateQueries([
          'adUserEmrAccessList',
          requests?.UserId,
        ]);
      }

      forceRefetch();
      setRequestUpdating(false);
    },
  });

  function invalidateAdUserEmrQuery() {
    queryClient.invalidateQueries(['adUserEmrAccessList', requestData.UserId]);
  }

  useEffect(() => {
    invalidateAdUserEmrQuery();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    if (!sendEMRRequestsIsLoading && sendEMRRequestsIsError) {
      notify(
        sendEMRRequestsErrorData
          ? `Error sending requests: ${sendEMRRequestsErrorData}`
          : 'Error sending requests',
        'error',
        4000
      );
      if (isSubscribed) {
        setRequestUpdating(false);
      }
    }

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, [
    sendEMRRequestsIsLoading,
    sendEMRRequestsIsSuccess,
    sendEMRRequestsIsError,
  ]);

  const handleSendRequest = () => {
    setRequestUpdating(true);
    const requestsToSend = [requestData];
    sendEMRRequests(requestsToSend);

    // queryClient.invalidateQueries(['adUserEmrAccessList', requestData.UserId]);
    // queryClient.invalidateQueries(['allStaffUsers']);
  };

  const handleDownloadDocument = async () => {
    setDocumentIsDownloading(true);
    const res = await API.sendRequest(
      `ADUserEMRAccess/EnvelopeDocuments/${requestData.EnvelopeId}`,
      'GET',
      null,
      true
    );

    if (res?.data) {
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    }
    setDocumentIsDownloading(false);
  };

  const getRequestMethodDetail = () => {
    let templateName = '';
    if (requestData.EMRRequestMethodId === 1) {
      templateName = docuSignTemplateList?.find(
        (template) =>
          template.DocuSignTemplateId === requestData.DocuSignTemplateId
      )?.DocuSignTemplateDesc;
    }

    if (isTerminationScreen && requestData.EMRRequestMethodId === 3) {
      // Termination Statuses
      return (
        <SeeEmailButton
          title="See Email Details"
          onClick={() => emailViewRef?.current?.changeVisibility(data)}
        >
          {!!requestData?.UnReadEmailCount && (
            <div className="number">{requestData?.UnReadEmailCount}</div>
          )}
          {!!requestData?.UnReadEmailCount ? (
            <i className="fa fa-envelope" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-envelope-open" aria-hidden="true"></i>
          )}
          <span>See Email Details</span>
        </SeeEmailButton>
      );
    }

    switch (requestData.EMRRequestMethodId) {
      case 2:
        return (
          <div>
            {'Website: ['}
            <a
              href={requestData.EMRRequestUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {requestData.EMRRequestUrl}
            </a>
            {']'}
          </div>
        );
      case 4:
        return requestData.EMRRequestStatusId > 0
          ? `Phone call ${
              requestData.EMRPhone ? ` - ${requestData.EMRPhone}` : ''
            }`
          : `Call to ${requestData.EMRPhone}`;
      case 3:
        if (isExpirationScreen) {
          if (requestData.EMRRequestStatusId === 4) {
            return (
              <SeeEmailButton
                title="Edit renew credentials email before sending to hospital"
                onClick={() =>
                  sendEmailPopupRef?.current?.changeVisibility(data)
                }
              >
                <i className="fa fa-at" aria-hidden="true"></i>
                <span>
                  Edit renew credentials email before sending to hospital
                </span>
              </SeeEmailButton>
            );
          } else {
            return (
              <SeeEmailButton
                title="See Email Details"
                onClick={() => emailViewRef?.current?.changeVisibility(data)}
              >
                {!!requestData?.UnReadEmailCount && (
                  <div className="number">{requestData?.UnReadEmailCount}</div>
                )}
                {!!requestData?.UnReadEmailCount ? (
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-envelope-open" aria-hidden="true"></i>
                )}
                <span>See Email Details</span>
              </SeeEmailButton>
            );
          }
        }
        return requestData.EMRRequestStatusId > 0 ? (
          <SeeEmailButton
            title="See Email Details"
            onClick={() => emailViewRef?.current?.changeVisibility(data)}
          >
            {!!requestData?.UnReadEmailCount && (
              <div className="number">{requestData?.UnReadEmailCount}</div>
            )}
            {!!requestData?.UnReadEmailCount ? (
              <i className="fa fa-envelope" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-envelope-open" aria-hidden="true"></i>
            )}
            <span>See Email Details</span>
          </SeeEmailButton>
        ) : requestUpdating ? (
          'Wait please...'
        ) : ACL?.Update || ACL?.Admin ? (
          <SeeEmailButton
            title={`Submit request e-mail to ${requestData.EMREmail?.replaceAll(
              ',',
              ', '
            )}`}
            onClick={() => sendEmailPopupRef?.current?.changeVisibility(data)}
          >
            <i className="fa fa-at" aria-hidden="true"></i>
            <span>Edit email before sending to hospital</span>
          </SeeEmailButton>
        ) : (
          'Waiting for email submission'
        );
      case 1:
        return docuSignTemplateList ? (
          templateName ? (
            requestData.EMRRequestStatusId > 0 &&
            requestData.EMRRequestStatusId < 3 ? (
              `DocuSignTemplate: [${templateName}]`
            ) : requestData.EMRRequestStatusId === 3 ||
              requestData.EMRRequestStatusId === 4 ? (
              documentIsDownloading ? (
                'Please wait...'
              ) : (
                <RequestButton
                  icon="fas fa-file-arrow-down"
                  type="default"
                  stylingMode="text"
                  text="Download DocuSign"
                  height={28}
                  onClick={handleDownloadDocument}
                  focusStateEnabled={false}
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                />
              )
            ) : requestUpdating ? (
              'Wait please...'
            ) : (
              <RequestButton
                icon="fas fa-file-signature"
                type="default"
                stylingMode="text"
                text="Submit DocuSign"
                height={28}
                onClick={handleSendRequest}
                focusStateEnabled={false}
                hoverStateEnabled={false}
                activeStateEnabled={false}
              />
            )
          ) : (
            'Template not found'
          )
        ) : (
          'Loading...'
        );

      default:
        return 'Undefined';
    }
  };

  return (
    <StatusContainer>
      <span>{getRequestMethodDetail()}</span>
    </StatusContainer>
  );
};

RequestMethodCell.propTypes = {
  data: object.isRequired,
  emailViewRef: { current: { changeVisibility: func, setData: func } },
  sendEmailPopupRef: { current: { changeVisibility: func, setData: func } },
  isExpirationScreen: bool,
  ACL: object,
};

RequestMethodCell.defaultProps = {
  emailViewRef: { current: { changeVisibility: () => {}, setData: () => {} } },
  sendEmailPopupRef: {
    current: { changeVisibility: () => {}, setData: () => {} },
  },
  isExpirationScreen: false,
  forceRefetch: () => {},
  ACL: {},
};

export default RequestMethodCell;

RequestMethodCell.propTypes = {
  data: object.isRequired,
  emailViewRef: any,
  sendEmailPopupRef: any,
  forceRefetch: func,
};
