import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  agendaFormContainer: {
    display: 'flex',
    flex: 1,
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
}));
