import { makeStyles } from '@material-ui/core';
import { CheckBox } from 'devextreme-react';
import styled from 'styled-components';

export const CheckBoxSmall = styled(CheckBox)`
  .dx-checkbox-icon {
    scale: 0.78;
  }
`;

export const WrapperBulkButton = styled.div`
  margin-left: 620px;
  padding-bottom: 8px;
`;

export default makeStyles(() => ({
  divGridContainer: {
    margin: 'auto',
    width: '98%',
    height: '85vh',
    textAlign: 'center',
  },
  cellRenderTd: {
    padding: '0 !important',
    width: '100%',
  },
}));
