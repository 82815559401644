import React from 'react';
import Popup from 'devextreme-react/popup';
import ScheduleForm from '../ScheduleForm';

export default function SchedulePopup(props) {
  const {
    saveAppointments,
    setPopupVisible,
    popupVisible,
    selectedDate,
    typeChanged,
    appointmentTypes,
    contacts,
  } = props;

  const getWidth = () => {
    return parseInt(window.innerWidth * 0.5);
  };

  const getHeight = () => {
    return parseInt(window.innerHeight * 0.8);
  };

  return (
    <Popup
      onHiding={() => {
        setPopupVisible(false);
      }}
      visible={popupVisible}
      dragEnabled={true}
      closeOnOutsideClick={true}
      showTitle={true}
      title="Schedule"
      width={getWidth}
      height={getHeight}
    >
      <ScheduleForm
        setPopupVisible={setPopupVisible}
        selectedDate={selectedDate}
        typeChanged={typeChanged}
        contacts={contacts}
        appointmentTypes={appointmentTypes}
        saveAppointments={saveAppointments}
      />
    </Popup>
  );
}
