import { Template } from 'devextreme-react/core/template';
import { Column, DataGrid, Paging } from 'devextreme-react/data-grid';
import React, { useState } from 'react';
import HistoryPopup from '../../components/popups/HistoryPopup';
import {
  BreadCrumbRender,
  LoadPermissions,
  onToolbarBreadCrumb,
} from '../../global';
import usePermissions from '../../hooks/usePermissions';
import useGetHospitals from '../../services/requests/useGetHospitals';
import getChangeHistory from '../../utils/getChangeHistory';
import useStyles from '../styles/styles';
import HospitalEditPopup from './components/HospitalEditPopup';

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const Hospital = () => {
  const [currentHospital, setCurrentHospital] = useState(null);
  // const [addressVisible, setAddressVisible] = useState(false);
  const [hospitalEditVisible, setHospitalEditVisible] = useState(false);
  const { data: hospitals, refetch: refetchHospitals } = useGetHospitals();
  const [hospitalChangeHistory, setHospitalChangeHistory] = useState(null);
  const [historyPopupVisible, setHistoryPopupVisible] = useState(false);

  // const openAddressPopup = (e) => {
  //   if (e.row.data) {
  //     setCurrentHospital(e.row.data);
  //   }
  //   setAddressVisible(true);
  //   e.event.preventDefault();
  // };

  const handleEmrAccessClick = (e) => {
    setCurrentHospital(e.row.data);
    setHospitalEditVisible(true);
  };

  const handleCloseEditPopup = () => {
    refetchHospitals();
    setHospitalEditVisible(false);
    setCurrentHospital(null);
  };

  const classes = useStyles();

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }
  /** ****************************** */

  const handleShowChangeHistory = async (e) => {
    await getChangeHistory('Hospital', e.row.data.HospitalId).then((ret) => {
      if (ret) {
        setHospitalChangeHistory(ret);
        setHistoryPopupVisible(true);
      }
    });
  };

  return (
    <div className={classes.divGridContainer}>
      {/* <AddressPopup
        visible={addressVisible}
        address={currentHospital}
        owner={currentHospital && { FullName: currentHospital.HospitalName }}
        onVisibleChange={(visible) => setAddressVisible(visible)}
      /> */}
      {historyPopupVisible && (
        <HistoryPopup
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={hospitalChangeHistory}
        />
      )}
      {currentHospital && (
        <HospitalEditPopup
          currentHospital={currentHospital}
          visible={hospitalEditVisible}
          onClose={handleCloseEditPopup}
        />
      )}
      <DataGrid
        elementAttr={{
          id: 'gridContainer',
        }}
        dataSource={hospitals}
        keyExpr="HospitalId"
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        onRowUpdating={onRowUpdating}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />

        <Column dataField="HospitalGroupName" caption="Okta Tile" width={220} />
        <Column dataField="HospitalCode" caption="Code" width={90} />
        <Column dataField="HospitalName" caption="Hospital" />
        <Column dataField="Line1" caption="Address" />
        <Column dataField="City" width={120} />
        <Column dataField="State" width={40} />
        <Column dataField="ZipCode" width={80} />
        <Column dataField="PhoneNumber" width={110} />
        <Column
          caption="Actions"
          type="buttons"
          width={100}
          buttons={[
            {
              hint: 'Edit',
              icon: 'fas fa-pen',
              onClick: handleEmrAccessClick,
              visible: ACL?.Admin || ACL?.Update,
            },
            // {
            //   hint: 'Full Address',
            //   icon: 'fas fa-location-dot',
            //   onClick: openAddressPopup,
            // },
            {
              hint: 'Show Change History',
              icon: 'fa fa-history',
              onClick: handleShowChangeHistory,
              visible: ACL?.Admin,
            },
          ]}
        />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default Hospital;
