import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box, { Item } from 'devextreme-react/box';
import API from '../../../services/API';
import Message from '../../message/Message';
import { DateBox } from 'devextreme-react';

const ScheduleClonePopup = (props) => {
  const { visible, onVisibleChange, sourceYear, sourceMonth, area } = props;
  const [error, setError] = useState('');
  const [targetDate, setTargetDate] = useState(getNextMonth());

  function getNextMonth() {
    let dt = new Date();
    dt.setMonth(dt.getMonth() + 1);
    return dt;
  }

  const getMonthName = (date) => {
    return date.toLocaleDateString('en-us', { month: 'long' });
  };

  const onMonthChanged = (e) => {
    setTargetDate(new Date(e.value));
  };

  const handleClone = async () => {
    const contactId = 'null';
    const result = await API.sendRequest(
      `ContactSchedule/Clone/${area}/${sourceYear}/${sourceMonth}/${targetDate.getFullYear()}/${
        targetDate.getMonth() + 1
      }/${contactId}`,
      'POST'
    );

    if (result.status !== 200) {
      setError(result.message);
    } else {
      onVisibleChange(false);
    }
  };

  const handleMessageClose = () => {
    setError('');
  };

  return (
    <>
      <Message
        message={error}
        variant="error"
        open={!!error}
        handleClose={handleMessageClose}
      />
      <Dialog
        open={visible}
        onClose={() => onVisibleChange(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {'This process will clone the schedules from [' +
            getMonthName(new Date(sourceYear, sourceMonth - 1, 1)) +
            ' ' +
            sourceYear +
            '] to:'}
        </DialogTitle>
        <DialogContent>
          <Box direction={'row'} width={'100%'}>
            <Item ratio={1}>
              Target month
              <DateBox
                id={'dtTargetDate'}
                defaultValue={targetDate}
                type={'date'}
                format={'date'}
                width={'200px'}
                displayFormat={'monthAndYear'}
                // maxZoomLevel={'year'}
                // minZoomLevel={'century'}
                calendarOptions={{
                  maxZoomLevel: 'year',
                  minZoomLevel: 'century',
                }}
                onValueChanged={onMonthChanged}
              />
            </Item>
            <Item ratio={2}>
              Any appointment at the target month will be deleted. Only dates
              present in the source month will be copied to target month. For
              example: January 1st will be copied to February 1st. January 29,
              30, and 31st will not be copied to February. Are you sure?
            </Item>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClone} color="primary">
            Clone
          </Button>
          <Button onClick={() => onVisibleChange(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduleClonePopup;
