import styled from 'styled-components';

export const WrapperContact = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => props.cursor};
`;

export const WrapperDataContact = styled.div`
  flex: 1;
  margin-left: 6px;
`;

export const Paragraph = styled.p`
  font-weight: ${(props) => (props.bold ? 'bold' : 'regular')};
`;
