import styled from 'styled-components';

export const ContentPopup = styled.div`
  width: 100%;
  height: calc(100% - 42px);
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
`;

export const WrapperEmail = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #d6d6d6;
`;

export const WrapperHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  gap: 8px;
  border-radius: 12px;
  :hover {
    background-color: #dddddd50;
    cursor: pointer;
  }
`;

export const WrapperImage = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 75px;
    height: 75px;
  }
`;

export const WrapperInfos = styled.div`
  width: calc(100% - 68px);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;

  p {
    color: #717171;
  }
`;

export const Content = styled.div`
  width: fit-content;
  width: 800px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  /* background-color: gray; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 12px;
`;

export const WrapperAttachments = styled.div`
  border: 1px solid #dddddd;
  border-radius: 10px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;

  gap: 8px;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .list {
    display: flex;
    gap: 8px;
  }
`;

export const Attachments = styled.div`
 .doc {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    svg {
      width: 40px;
      height: 40px;
    }
  }
  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
  }
  width: 110px;
  height: 110px;
  padding: 8px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    line-clamp: 2;
    cursor: pointer;
    max-width: 98%;
    text-align: center;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* Número máximo de linhas */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Adiciona reticências (...) quando o texto for truncado */
    word-wrap: break-word; /* Permite que palavras sejam quebradas */
    white-space: normal; /* Força a quebra de palavras */
  }
  position: relative;

  .trash {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4px;
    top: 4px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    transition: 0.5s;
    border: none;

    :hover {
      cursor: pointer;
      background-color: #a1a1a140;
    }
    .trashImg {
      width: 13px !important;
      height: 14px !important;
      opacity: 0.6;
      :hover {
        opacity: 1;
      }
    }
  }
`;

export const WrapperEmailContent = styled.div``;
