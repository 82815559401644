import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  gap: 5px;
`;

export const Text = styled.span`
  font-weight: ${({ bold }) => (bold ? 'bold' : '')};
  font-size: 16;
  text-align: center;
`;
