import { useQuery } from 'react-query';
import API from '../API';

async function getAllHospitalGroups() {
  const { data } = await API.sendRequest('HospitalGroup', 'GET');

  return data.map((hospitalGroup) => ({
    ...hospitalGroup,
    AutoSend: false,
    EMRPhone: hospitalGroup?.EMRPhone?.replace(/[^0-9]/g, '')?.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '($1) $2-$3'
    ),
  }));
}

export default function useAllHospitalGroupsList(enableRefetch) {
  return useQuery('allHospitalGroups', getAllHospitalGroups, {
    // refetchInterval: enableRefetch ? 1 * 60 * 1000 : false, // 5 minutes
    refetchOnWindowFocus: false,
  });
}

async function getHospitalGroupById(hospitalGroupId) {
  const { data } = await API.sendRequest(
    `HospitalGroup/${hospitalGroupId}`,
    'GET'
  );
  return data;
}

export function useGetHospitalGroupById(hospitalGroupId) {
  return useQuery(
    'allHospitalGroups',
    () => getHospitalGroupById(hospitalGroupId),
    {
      refetchOnWindowFocus: false,
    }
  );
}
