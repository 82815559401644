import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import { Button, Column } from 'devextreme-react/data-grid';
import { TitleGrid } from '../../styles';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { useOktaApp } from '../../context/OktaAppContext';
import { object } from 'prop-types';
import API from '../../../../services/API';

function AppDetailGrid({ data }) {
  const {
    popupAddAppMemberRef,
    //  popupAddMembersRef,
    popupDeleteRef,
    ACL,
  } = useOktaApp();
  const [oktaMembers, setOktaMembers] = useState([]);

  async function fetchOktaMembers() {
    const { data: oktaMembersData } = await API.sendRequest(
      `OktaApp/ListMembersByApplication/${data?.OktaAppId}`,
      'GET'
    );
    setOktaMembers(oktaMembersData);
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && data?.OktaAppId && oktaMembers.length === 0) {
      fetchOktaMembers();
    }
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        style={{
          paddingBottom: '8px',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <TitleGrid>Members</TitleGrid>
        {(ACL?.Update || ACL?.Admin) && (
          <>
            <IconButton
              aria-label="plus"
              size="small"
              onClick={() =>
                popupAddAppMemberRef?.current?.setPopupData({
                  visible: true,
                  data,
                  type: 'APP',
                  refetch: fetchOktaMembers,
                })
              }
            >
              <AddBoxOutlinedIcon />
            </IconButton>
            {/* <IconButton
              aria-label="plus"
              size="small"
              onClick={() =>
                popupAddMembersRef?.current?.setPopupData({
                  visible: true,
                  data: {
                    ...data,
                    CurrentMembers: oktaMembers,
                  },
                  refetch: fetchOktaMembers,
                })
              }
            >
              <AddBoxOutlinedIcon />
            </IconButton> */}
          </>
        )}
      </div>
      {useMemo(
        () => (
          <DataGrid
            focusedRowEnabled
            keyExpr="UserId"
            dataSource={oktaMembers}
            showBorders
            rowAlternationEnabled
          >
            <Column dataField="DisplayName" caption="Name" />
            <Column dataField="JobTitle" caption="Job Title" />
            <Column dataField="Department" />
            <Column dataField="Email" caption="Email" />
            {(ACL?.Update || ACL?.Admin) && (
              <Column type="buttons" caption="Actions">
                <Button
                  icon="edit"
                  onClick={(e) =>
                    popupAddAppMemberRef?.current?.setPopupData({
                      visible: true,
                      data: { ...e?.row?.data, ...data },
                      type: 'EDIT',
                      refetch: fetchOktaMembers,
                    })
                  }
                />
                <Button
                  icon="trash"
                  onClick={(e) =>
                    popupDeleteRef?.current?.setPopupData({
                      visible: true,
                      data: {
                        UserId: e?.row?.data?.UserId,
                        OktaAppId: data?.OktaAppId,
                        OktaApplicationId: data?.OktaApplicationId,
                        Type: 'MEMBER',
                        Message: `Do you want to delete member [${e?.row?.data?.DisplayName}] from application [${data.OktaAppLabel}]?`,
                        refetch: fetchOktaMembers,
                      },
                    })
                  }
                />
              </Column>
            )}
          </DataGrid>
        ),
        // eslint-disable-next-line
        [oktaMembers]
      )}
    </>
  );
}

export default AppDetailGrid;

AppDetailGrid.propTypes = {
  data: object.isRequired,
};
