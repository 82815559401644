import { format, parseISO } from 'date-fns';
import { Template } from 'devextreme-react/core/template';
import {
  Column,
  DataGrid,
  SearchPanel,
  Selection,
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import React, { useEffect, useRef, useState } from 'react';
import API from '../../services/API';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import usePermissions from '../../hooks/usePermissions';
import { handleExportGridToExcel } from '../../utils/exportScheduleHistory';
import useStyles from '../styles/styles';
import RestoreConfirmationPopup from './components/RestoreConfirmationPopup';

const DisabledUsers = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [userSchedule, setUserSchedule] = useState(null);
  const [currentUser, setCurrentUser] = useState('');
  const [restoreUser, setRestoreUser] = useState(null);
  const [restoreConfirmPopupVisible, setRestoreConfirmPopupVisible] = useState(
    false
  );

  const scheduleGridRef = useRef(null);

  const fetchRecycleList = async (isSubscribed) => {
    setLoading(true);
    const { data } = await API.sendRequest(`ADUser/RecycleList`, 'get');
    if (isSubscribed) {
      setDeletedUsers(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    fetchRecycleList(isSubscribed);

    return () => {
      isSubscribed = false;
    };
  }, []);

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /*********************************/

  const handleShowUserSchedule = async (e) => {
    setLoading(true);
    setCurrentUser(e.row.data.Username);
    const res = await API.sendRequest(
      `ContactSchedule/ScheduleFromActiveOrRecycle/${e.row.data.UserId}`
    );
    setUserSchedule(
      res.data.map((item) => {
        return {
          ...item,
          StartDate: format(
            parseISO(item.StartDate),
            item.AllDay || item.DayOff ? 'MM-dd-yyyy' : 'MM-dd-yyy HH:mm'
          ),
          EndDate: format(
            parseISO(item.EndDate),
            item.AllDay || item.DayOff ? 'MM-dd-yyyy' : 'MM-dd-yyy HH:mm'
          ),
          CreatedAt: format(parseISO(item.CreatedAt), 'MM-dd-yyy HH:mm'),
          ChangedAt: format(parseISO(item.ChangedAt), 'MM-dd-yyy HH:mm'),
        };
      })
    );
  };

  function handleRestoreAdUser({ row }) {
    if (row.data) {
      setRestoreUser(row.data);
      setRestoreConfirmPopupVisible(true);
    }
  }

  function onScheduleGridContentReady() {
    handleExportGridToExcel(scheduleGridRef.current.instance, currentUser);
    setLoading(false);
  }

  const buttonList = [
    {
      hint: 'Export schedule history to Excel',
      icon: 'fas fa-calendar-alt',
      onClick: handleShowUserSchedule,
    },
    {
      hint: 'Restore user',
      icon: 'fas fa-trash-restore',
      onClick: handleRestoreAdUser,
    },
  ];

  const toolbarInfoRender = (source, totalCount) => {
    return (
      <div className="toolBarInformation">
        <strong>{source}:</strong> {totalCount} records
      </div>
    );
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'breadCrumb',
      },
      {
        location: 'center',
        template: 'totalDeletedRecordsCount',
      }
    );
  };

  return (
    <>
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={loading}
        message="Loading"
        closeOnOutsideClick={false}
      />
      {restoreUser && (
        <RestoreConfirmationPopup
          userData={restoreUser}
          visible={restoreConfirmPopupVisible}
          handleClose={() => {
            setRestoreConfirmPopupVisible(false);
            setRestoreUser(null);
          }}
          updateRecycleList={() => fetchRecycleList(true)}
        />
      )}
      {userSchedule && (
        <DataGrid
          ref={scheduleGridRef}
          dataSource={userSchedule}
          onContentReady={onScheduleGridContentReady}
          visible={false}
        >
          <Column dataField="DisplayName" caption="Name" />
          <Column dataField="AppointmentTypeCode" caption="Appointment Code" />
          <Column
            dataField="AppointmentTypeDesc"
            caption="Appointment Description"
          />
          <Column dataField="StartDate" />
          <Column dataField="EndDate" />
          <Column dataField="AllDay" />
          <Column dataField="DayOff" />
          <Column dataField="CreatedAt" />
          <Column dataField="ChangedAt" />
        </DataGrid>
      )}
      <div className={classes.divGridContainer}>
        {deletedUsers.length > 0 && (
          <DataGrid
            dataSource={deletedUsers}
            onToolbarPreparing={onToolbarPreparing}
            showBorders={true}
            rowAlternationEnabled
          >
            <SearchPanel visible={true} />
            <Selection mode="single" />
            <Column dataField={'FirstName'} width={200} />
            <Column dataField={'LastName'} width={200} />
            <Column dataField={'Username'} caption={'User Name'} width={200} />
            <Column
              dataField={'Department'}
              caption={'Department'}
              width={200}
            />
            <Column dataField={'Description'} caption={'Description'} />
            <Column caption="Actions" type="buttons" buttons={buttonList} />
            <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
            <Template
              name="totalDeletedRecordsCount"
              render={() =>
                toolbarInfoRender('Disabled Users Count', deletedUsers.length)
              }
            />
          </DataGrid>
        )}
      </div>
    </>
  );
};

export default DisabledUsers;
