import React, { useState } from 'react';
import DataGrid, { Button, Column, Paging } from 'devextreme-react/data-grid';
import PopupNewMember from '../popups/PopupNewMember';
import { array, object, shape } from 'prop-types';
import { IconButton } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import PopupMemberDelete from '../popups/PopupMemberDelete';

import {
  Container,
  DatagridContainer,
  Section,
  SectionHeader,
  Title,
} from './styles';

function GroupListGrid({ data, ACL, distList }) {
  const [popupNewMemberVisible, setPopupNewMemberVisible] = useState(false);
  const [popupDeleteVisible, setPopupDeleteVisible] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);

  const distributionListData = data?.data?.DistributionLists;

  const distributionListToShow = distributionListData?.map(
    (distributionListPerson) => {
      const matchData = distList?.find(
        (item) => item?.DName === distributionListPerson.DistinguishedName
      );
      return { ...matchData };
    }
  );

  function handleOpenPopupDelete(data) {
    setCurrentGroup(data);
    setPopupDeleteVisible(true);
  }

  function handleClosePopupDelete() {
    setPopupDeleteVisible(false);
    setCurrentGroup(null);
  }

  return (
    <>
      {popupNewMemberVisible && (
        <PopupNewMember
          visible={popupNewMemberVisible}
          onClose={() => setPopupNewMemberVisible(false)}
          type="LIST"
          existentGroups={distributionListData}
          currentGroup={{}}
          currentMember={{
            DisplayName: data?.data?.DisplayName,
            Username: data?.data?.Username,
          }}
        />
      )}
      {popupDeleteVisible && (
        <PopupMemberDelete
          visible={popupDeleteVisible}
          onClose={() => handleClosePopupDelete()}
          groupName={currentGroup?.DistributionListName}
          groupDName={currentGroup?.DistinguishedName}
          memberData={{
            DisplayName: data?.data?.DisplayName,
            DistinguishedName: data?.data?.DistinguishedName,
          }}
        />
      )}
      <Container>
        <Section>
          <SectionHeader>
            <Title>Distribution Lists</Title>
            {(ACL?.Update || ACL?.Admin) && (
              <IconButton
                aria-label="plus"
                size="small"
                onClick={() => setPopupNewMemberVisible(true)}
              >
                <AddBoxOutlinedIcon />
              </IconButton>
            )}
          </SectionHeader>
          <DatagridContainer>
            <DataGrid
              height={300}
              dataSource={distributionListToShow}
              keyExpr="DName"
              showBorders
              selection={{
                mode: 'single',
              }}
              hoverStateEnabled
            >
              <Paging enabled={false} />
              <Column dataField="GroupCN" caption="Name" />
              <Column dataField="ManagerDisplay" caption="Owner" />

              {(ACL?.Delete || ACL?.Admin) && (
                <Column type="buttons" caption="Actions">
                  {(ACL?.Delete || ACL?.Admin) && (
                    <Button
                      icon="trash"
                      text="Delete"
                      onClick={(e) =>
                        handleOpenPopupDelete(
                          distributionListData?.find(
                            (item) =>
                              item.DistinguishedName === e?.row?.data.DName
                          )
                        )
                      }
                    />
                  )}
                </Column>
              )}
            </DataGrid>
          </DatagridContainer>
        </Section>
      </Container>
    </>
  );
}

GroupListGrid.propTypes = {
  data: shape({
    data: shape({}),
  }).isRequired,
  ACL: object.isRequired,
  distList: array,
};

GroupListGrid.defaultProps = {
  distList: [],
};

export default GroupListGrid;
