import styled from 'styled-components';

export const HistoryContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fafafa;

  table {
    max-width: 100%;
    font-size: 0.95rem;
    border-collapse: separate;
    border-spacing: 0 0.3rem;

    th,
    td {
      color: #000;
      padding: 0.4rem 1rem;
    }

    td.fieldName {
      font-weight: bold;
      padding-right: 1rem;
    }

    th {
      color: #000;
      text-transform: uppercase;
      text-align: left;
    }

    /* td {
      border-top: solid 0.0625rem #dddddd;
      border-bottom: solid 0.0625rem #dddddd;
      word-wrap: break-word;
      padding: 0.4rem 1rem;
    } */
  }
`;

export const TableRow = styled.tr`
  td {
    /* border-top: solid 0.0625rem #dddddd;
    border-bottom: solid 0.0625rem #dddddd; */
    max-width: 450px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #dddddd;
  }
  background: ${({ hasalteration }) => (hasalteration ? '#ff9587' : '#ffffff')};
  td:first-child {
    /* border-left: solid 0.0625rem #dddddd; */
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  td:last-child {
    /* border-right: solid 0.0625rem #dddddd; */
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;
