/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { func, bool, shape } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Popup } from 'devextreme-react/popup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TreeList, Selection, Column } from 'devextreme-react/tree-list';
import { Button } from 'devextreme-react';
import { LoadPanel } from 'devextreme-react/ui/load-panel';

import API from '../../../services/API';
import Message from '../../message/Message';
import useStyles from '../ContactInfoPopup/styles';

import {
  BoxLoading,
  BoxCheckbox,
  WrapperTreeList,
  WrapperButtons,
} from './styles';

const expandedRowKeys = [1];

const initialsColumns = [
  {
    key: 0,
    value: false,
    field: '',
  },
  {
    key: 1,
    value: false,
    field: 'Read Only',
  },
  {
    key: 2,
    value: false,
    field: 'Add New',
  },
  {
    key: 3,
    value: false,
    field: 'Update',
  },
  {
    key: 4,
    value: false,
    field: 'Delete',
  },
  {
    key: 5,
    value: false,
    field: 'Print',
  },
  {
    key: 6,
    value: false,
    field: 'Export',
  },
  {
    key: 7,
    value: false,
    field: 'Bulk',
  },
  {
    key: 8,
    value: false,
    field: 'Admin',
  },
];

const RolePermissionsPopup = (props) => {
  const { visible, setPermissionsVisible, role } = props;
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [columns, setColumns] = useState(initialsColumns);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    async function loadMenus() {
      setIsLoading(true);
      const { data } = await API.sendRequest(`RoleMenu/${role.RoleId}`, 'get');

      const columnsCheckeds = initialsColumns.map((col, index) => {
        if (index === 0) {
          return col;
        }
        const resultChecked = data.filter((menu) => menu[col.field] === false);
        if (resultChecked && resultChecked.length > 0) {
          col.value = false;
          return col;
        }
        col.value = true;
        return col;
      });

      setColumns(columnsCheckeds);

      setMenus(data);
      setIsLoading(false);
    }

    loadMenus();
  }, [role.RoleId, visible]);

  const classes = useStyles();

  const savePermissions = async () => {
    setIsLoadingSave(true);
    let saveMenus = menus.filter((menu) => {
      const ArrayMenu = Object.values(menu);
      const checkeds = ArrayMenu.slice(8, ArrayMenu.length - 1).filter(
        (item) => item
      );
      if (checkeds.length > 0) return true;
      return false;
    });

    saveMenus = saveMenus.map((save) => ({
      MenuId: save.MenuId,
      Read_Only: save['Read Only'],
      Add_New: save['Add New'],
      Update: save.Update,
      Delete: save.Delete,
      Print: save.Print,
      Export: save.Export,
      Bulk: save.Bulk,
      Admin: save.Admin,
    }));

    const result = await API.sendRequest('RoleMenu/SavePermissions', 'post', {
      RoleId: role.RoleId,
      Menus: saveMenus,
    });

    if (result.status !== 200) {
      setError(result.message);
    } else {
      setPermissionsVisible(false);
    }
    setIsLoadingSave(false);
  };

  const handleMessageClose = () => {
    setError('');
  };

  const handleHeaderCheckbox = (key, value, field) => {
    const menusCheckAll = menus.map((menu) => {
      menu[field] = value;
      return menu;
    });

    setMenus(menusCheckAll);

    const columnsCheckeds = columns.map((col) => {
      if (col.field === field) {
        col.value = value;
      }
      return col;
    });

    setColumns(columnsCheckeds);
  };

  const headerRender = (value) => (
    <BoxCheckbox>
      <input
        id="lfname"
        name="fname"
        type="checkbox"
        checked={columns[value.columnIndex].value}
        onClick={(e) =>
          handleHeaderCheckbox(
            value.columnIndex,
            e.currentTarget.checked,
            value.column.dataField
          )
        }
      />
      <label htmlFor="lfname">
        {value.columnIndex === 7
          ? value.column.caption
          : value.column.dataField}
      </label>
    </BoxCheckbox>
  );

  const handleCellCheckbox = (key, value, field) => {
    let unchecked = null;
    let checked = null;
    let menusChecked = menus.map((menu) => {
      if (key === menu.MenuId) {
        menu[field] = value;
        checked = menu.ParentMenuId;
      }
      if (menu.ParentMenuId === key) {
        menu[field] = value;
      }
      if (value === false && key === menu.MenuId) {
        unchecked = menu.ParentMenuId;
      }
      if (value === true && key === menu.MenuId) {
        checked = menu.ParentMenuId;
      }

      return menu;
    });

    if (unchecked) {
      menusChecked = menusChecked.map((m) => {
        if (unchecked === m.MenuId) {
          m[field] = value;
        }
        return m;
      });
    }

    if (checked) {
      let checksChildrens = false;
      menusChecked = menusChecked.map((m) => {
        if (checked === m.ParentMenuId) {
          checksChildrens = m[field];
        }
        return m;
      });

      if (checksChildrens) {
        menusChecked = menusChecked.map((m) => {
          if (checked === m.MenuId && m.ParentMenuId === null) {
            m[field] = value;
          }
          return m;
        });
      }
    }

    const columnsCheckeds = columns.map((col, index) => {
      if (index === 0) {
        return col;
      }
      const resultChecked = menusChecked.filter(
        (menu) => menu[col.field] === false
      );
      if (resultChecked && resultChecked.length > 0) {
        col.value = false;
        return col;
      }
      col.value = true;
      return col;
    });

    setColumns(columnsCheckeds);
    setMenus(menusChecked);
  };

  const cellRender = (cell) => (
    <input
      type="checkbox"
      checked={cell.value}
      onClick={(e) => {
        handleCellCheckbox(
          cell.key,
          e.currentTarget.checked,
          cell.column.dataField
        );
      }}
    />
  );

  return (
    <>
      <Message
        message={error}
        variant="error"
        open={!!error}
        handleClose={handleMessageClose}
      />
      <Popup
        visible={visible}
        title={`${role && role.RoleDesc}`}
        onHidden={() => setPermissionsVisible(false)}
        width="95%"
        maxWidth="1500px"
        height="90%"
        showCloseButton={false}
      >
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={isLoadingSave}
          message="Saving..."
          closeOnOutsideClick={false}
        />
        <Typography className={classes.subtitle}>
          {role.RoleId && role.RoleId < 3 ? (
            <>SYSTEM ROLES CANNOT BE CHANGED</>
          ) : (
            <>Select the menu options</>
          )}
        </Typography>
        <WrapperTreeList>
          {isLoading ? (
            <BoxLoading>
              <CircularProgress />
            </BoxLoading>
          ) : (
            <div>
              <TreeList
                id="menulist"
                dataSource={menus}
                autoExpandAll
                allowColumnReordering={false}
                showBorders
                showRowLines
                defaultExpandedRowKeys={expandedRowKeys}
                keyExpr="MenuId"
                parentIdExpr="ParentMenuId"
                height="100%"
              >
                <Selection mode="single" />
                <Column
                  dataField="MenuCaption"
                  caption="Options"
                  width={180}
                  allowEditing={false}
                />
                <Column
                  dataField="Read Only"
                  headerCellRender={headerRender}
                  cellRender={cellRender}
                  allowSorting={false}
                />
                <Column
                  dataField="Add New"
                  headerCellRender={headerRender}
                  cellRender={cellRender}
                  allowSorting={false}
                />
                <Column
                  dataField="Update"
                  headerCellRender={headerRender}
                  cellRender={cellRender}
                  allowSorting={false}
                />
                <Column
                  dataField="Delete"
                  headerCellRender={headerRender}
                  cellRender={cellRender}
                  allowSorting={false}
                />
                <Column
                  dataField="Print"
                  headerCellRender={headerRender}
                  cellRender={cellRender}
                  allowSorting={false}
                />
                <Column
                  dataField="Export"
                  headerCellRender={headerRender}
                  cellRender={cellRender}
                  allowSorting={false}
                />
                <Column
                  dataField="Bulk"
                  caption="Bulk Operations"
                  headerCellRender={headerRender}
                  cellRender={cellRender}
                  allowSorting={false}
                />
                <Column
                  dataField="Admin"
                  headerCellRender={headerRender}
                  cellRender={cellRender}
                  allowSorting={false}
                />
              </TreeList>
            </div>
          )}
        </WrapperTreeList>
        <WrapperButtons>
          {role && role.RoleId > 2 && (
            <Button
              text="SAVE"
              type="default"
              stylingMode="contained"
              onClick={() => savePermissions()}
              style={{ marginRight: '10px' }}
            />
          )}
          <Button
            text="CANCEL"
            type="normal"
            stylingMode="outlined"
            onClick={() => setPermissionsVisible(false)}
          />
        </WrapperButtons>
      </Popup>
    </>
  );
};

RolePermissionsPopup.propTypes = {
  role: shape().isRequired,
  visible: bool.isRequired,
  setPermissionsVisible: func.isRequired,
};

export default RolePermissionsPopup;
