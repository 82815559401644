import React, { useEffect, useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { bool, func, array, string } from 'prop-types';
import { Column, DataGrid, MasterDetail } from 'devextreme-react/data-grid';
import { format, parseISO } from 'date-fns';
import { DataGridContainer } from './styles';
import XMLHistoryDetail from './components/XMLHistoryDetail';
import JSONHistory from './components/JSONHistory';
import { checkJsonType } from '../../../utils/checkJsonType';

function HistoryPopup({
  isVisible,
  setIsVisible,
  changeHistory,
  historyType,
  needConvertToJson,
}) {
  const [history, setHistory] = useState(null);

  useEffect(() => {
    let newHistory = null;
    if (changeHistory.length > 0) {
      newHistory = changeHistory.map((item, index) => {
        if (historyType === 'xml') {
          return {
            ...item,
            HistoryIndex: index,
            ChangedAt: format(parseISO(item.ChangeDate), 'MM/dd/yyyy HH:mm a'),
            XMLComplete: `<document>${
              item.XMLAfter && item.XMLAfter.replace('<row', '<after')
            }${
              item.XMLBefore && item.XMLBefore.replace('<row', '<before')
            }</document>`,
          };
        }
        if (needConvertToJson) {
          return {
            ...item,
            HistoryIndex: index,
            ChangedAt: format(parseISO(item.ChangeDate), 'MM/dd/yyyy HH:mm a'),
            JSONBefore: convertXMLToJson(item.XMLBefore),
            JSONAfter: convertXMLToJson(item.XMLAfter),
          };
        }
        return {
          ...item,
          HistoryIndex: index,
          ChangedAt: format(parseISO(item.ChangeDate), 'MM/dd/yyyy HH:mm a'),
          JSONBefore: JSON.parse(item.XMLBefore),
          JSONAfter: JSON.parse(item.XMLAfter),
        };
      });
    }
    setHistory(newHistory);
  }, [changeHistory]); //eslint-disable-line

  const convertXMLToJson = (xml) => {
    if (xml) {
      return JSON.parse(
        `[${xml
          .replaceAll('<row ', '{"')
          .replaceAll(' />', '}')
          .replaceAll('=', '":')
          .replaceAll('" ', '", "')
          .replaceAll('}{', '}/s{')
          .split('/s')}]`
      );
    }
    return null;
  };

  return (
    <Popup
      visible={isVisible}
      onHidden={() => setIsVisible(false)}
      width="85%"
      height="90%"
      title={
        changeHistory.length > 0
          ? `${changeHistory[0].TopicDesc} - Change History`
          : 'Change History'
      }
      closeOnOutsideClick
      showCloseButton={false}
    >
      <DataGridContainer>
        <DataGrid
          dataSource={history}
          keyExpr="HistoryIndex"
          height="100%"
          showBorders
          rowAlternationEnabled
          focusedRowEnabled
          showRowLines
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: true }}
        >
          <Column dataField="ActionDesc" caption="Action" />
          <Column dataField="ChangedAt" />
          <Column dataField="Username" />

          <MasterDetail
            enabled
            component={({ data }) =>
              checkJsonType(data.data.XMLAfter) ? (
                <JSONHistory data={data} />
              ) : (
                <XMLHistoryDetail data={data} />
              )
            }
          />
        </DataGrid>
      </DataGridContainer>
      <ToolbarItem
        options={{
          stylingMode: 'contained',
          text: 'Close',
          type: 'normal',
          onClick: () => {
            setIsVisible(false);
          },
        }}
        widget="dxButton"
        toolbar="bottom"
        location="after"
      />
    </Popup>
  );
}
HistoryPopup.propTypes = {
  isVisible: bool.isRequired,
  setIsVisible: func.isRequired,
  changeHistory: array.isRequired,
  historyType: string,
  needConvertToJson: bool,
};

HistoryPopup.defaultProps = {
  historyType: 'xml',
  needConvertToJson: true,
};
export default HistoryPopup;
