import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import {
  Column,
  DataGrid,
  Paging,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';
import usePermissions from '../../hooks/usePermissions';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import { Template } from 'devextreme-react/core/template';

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const HelpText = () => {
  const [texts, setTexts] = useState(null);
  const [menus, setMenus] = useState(null);
  const [groups, setGroups] = useState(null);
  const [menuRootId, setMenuRootId] = useState(1);
  const classes = useStyles();

  const rootMenuList = [
    {
      value: 1,
      text: 'Web Admin',
    },
    {
      value: 2,
      text: 'Mobile',
    },
  ];

  useEffect(() => {
    async function init() {
      setMenus(
        (await API.sendRequest(`Menu/ChildrenOnly/${menuRootId}`, 'get')).data
      );
      setGroups((await API.sendRequest(`HelpTextGroup`, 'get')).data);
    }

    const textsTmp = new CustomStore({
      key: 'HelpTextId',
      load: async () => {
        const { data } = await API.sendRequest(
          `HelpText/List/${menuRootId}`,
          'get'
        );
        return data;
      },
      insert: async (data) => {
        await API.sendRequest(`HelpText`, 'post', data);
      },
      update: async (id, data) => {
        await API.sendRequest(`HelpText`, 'post', data);
      },
      remove: async (id) => {
        await API.sendRequest(`HelpText/${id}`, 'delete');
      },
    });

    setTexts(textsTmp);

    init();
  }, [menuRootId]);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'breadCrumb',
      },
      {
        location: 'before',
        widget: 'dxSelectBox',
        options: {
          width: 200,
          items: rootMenuList,
          displayExpr: 'text',
          valueExpr: 'value',
          value: menuRootId,
          onValueChanged: menuRootChanged,
        },
      }
    );
  };

  const menuRootChanged = (e) => {
    setMenuRootId(e.value);
  };

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={texts}
        // keyExpr="HelpTextId"
        allowColumnReordering
        showBorders
        onRowUpdating={onRowUpdating}
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Paging enabled={false} />
        <Editing
          useIcons
          mode="form"
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        />

        <Column dataField="HelpTextGroupId" caption="Group">
          <Lookup
            dataSource={groups}
            displayExpr="GroupDesc"
            valueExpr="HelpTextGroupId"
          />
        </Column>
        <Column
          dataField="TextContent"
          caption="Topic"
          editorType="dxTextArea"
        />
        <Column dataField="TextOrder" caption="Order" />
        <Column dataField="StyleCss" />
        <Column dataField="Active" />
        <Column dataField="MenuId" caption="Menu">
          <Lookup
            dataSource={menus}
            displayExpr="MenuFullCaption"
            valueExpr="MenuId"
          />
        </Column>
        <Column dataField="AccessControl" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default HelpText;
