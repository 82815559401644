import { Form } from 'devextreme-react';
import { SimpleItem } from 'devextreme-react/form';
import { object } from 'prop-types';
import React from 'react';

const UserDetail = ({ userData }) => (
  <Form formData={userData} readOnly width="100%" colCount={2}>
    {Object.keys(userData).map((userProp) => (
      <SimpleItem key={userProp} dataField={userProp} />
    ))}
  </Form>
);

UserDetail.propTypes = {
  userData: object.isRequired,
};

export default UserDetail;
