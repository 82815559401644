import { useState, useEffect } from 'react';

const useDevice = () => {
  const [device, setDevice] = useState(null);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    onWindowResize();

    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  function onWindowResize() {
    setDevice(
      window.matchMedia('(max-width: 920px)').matches ? 'mobile' : 'desktop'
    );
  }

  return device;
};

export default useDevice;
