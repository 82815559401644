import { useQuery } from 'react-query';
import API from '../API';

async function getEmrRequestStatusList() {
  const { data } = await API.sendRequest('EMRRequestStatus', 'GET');
  return data;
}

export default function useEmrRequestStatusList() {
  return useQuery('emrRequestStatusList', getEmrRequestStatusList, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
