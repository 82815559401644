import React, { useRef, useState } from 'react';

import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import {
  DataGrid,
  Column,
  SearchPanel,
  Selection,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { func, bool } from 'prop-types';
import API from '../../../../services/API';
import { format, parseISO } from 'date-fns';
import { handleExportGridToExcel } from '../../../../utils/exportScheduleHistory';

import { ContentContainer, GridContainer } from '../styles';

export default function DeletedUsers({ visible, setVisible }) {
  const [loading, setLoading] = useState(false);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [userSchedule, setUserSchedule] = useState(null);
  const [currentUser, setCurrentUser] = useState('');

  const scheduleGridRef = useRef(null);

  const handleShowUserSchedule = async (e) => {
    setLoading(true);
    setCurrentUser(e.row.data.Username);
    const res = await API.sendRequest(
      `ContactSchedule/ScheduleFromActiveOrRecycle/${e.row.data.UserId}`
    );
    setUserSchedule(
      res.data.map((item) => {
        return {
          ...item,
          StartDate: format(
            parseISO(item.StartDate),
            item.AllDay || item.DayOff ? 'MM-dd-yyyy' : 'MM-dd-yyy HH:mm'
          ),
          EndDate: format(
            parseISO(item.EndDate),
            item.AllDay || item.DayOff ? 'MM-dd-yyyy' : 'MM-dd-yyy HH:mm'
          ),
          CreatedAt: format(parseISO(item.CreatedAt), 'MM-dd-yyy HH:mm'),
          ChangedAt: format(parseISO(item.ChangedAt), 'MM-dd-yyy HH:mm'),
        };
      })
    );
  };

  function onScheduleGridContentReady() {
    handleExportGridToExcel(scheduleGridRef.current.instance, currentUser);
    setLoading(false);
  }

  const bottomToolbarButtons = [
    {
      text: 'Close',
      type: 'normal',
      onClick: () => {
        setVisible(false);
      },
    },
  ];

  const buttonList = [
    {
      hint: 'Export schedule history to Excel',
      icon: 'fas fa-calendar-alt',
      onClick: handleShowUserSchedule,
    },
  ];

  const onPopupShowing = async () => {
    setLoading(true);
    const { data } = await API.sendRequest(`ADUser/RecycleList`, 'get');
    setDeletedUsers(data);
    setLoading(false);
  };

  const onHidden = () => {
    setVisible(false);
  };

  const toolbarInfoRender = (source, totalCount) => {
    return (
      <div className="toolBarInformation">
        <strong>{source}:</strong> {totalCount} records
      </div>
    );
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'totalDeletedRecordsCount',
    });
  };

  return (
    <Popup
      onShowing={onPopupShowing}
      onHidden={onHidden}
      visible={visible}
      title="Deleted Users"
      width="90%"
      height="80%"
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={loading}
        message="Loading"
        closeOnOutsideClick={false}
      />
      <ContentContainer>
        {userSchedule && (
          <DataGrid
            ref={scheduleGridRef}
            dataSource={userSchedule}
            onContentReady={onScheduleGridContentReady}
            visible={false}
          >
            <Column dataField="DisplayName" caption="Name" />
            <Column
              dataField="AppointmentTypeCode"
              caption="Appointment Code"
            />
            <Column
              dataField="AppointmentTypeDesc"
              caption="Appointment Description"
            />
            <Column dataField="StartDate" />
            <Column dataField="EndDate" />
            <Column dataField="AllDay" />
            <Column dataField="DayOff" />
            <Column dataField="CreatedAt" />
            <Column dataField="ChangedAt" />
          </DataGrid>
        )}
        <GridContainer>
          {deletedUsers.length > 0 && (
            <DataGrid
              dataSource={deletedUsers}
              onToolbarPreparing={onToolbarPreparing}
              height={'100%'}
              width={'100%'}
              showBorders={true}
              rowAlternationEnabled
            >
              <SearchPanel visible={true} />
              <Selection mode="single" />
              <Column dataField={'FirstName'} width={200} />
              <Column dataField={'LastName'} width={200} />
              <Column
                dataField={'Username'}
                caption={'User Name'}
                width={200}
              />
              <Column
                dataField={'Department'}
                caption={'Department'}
                width={200}
              />
              <Column dataField={'Description'} caption={'Description'} />
              <Column caption="Actions" type="buttons" buttons={buttonList} />
              <Template
                name="totalDeletedRecordsCount"
                render={() =>
                  toolbarInfoRender('Deleted Users Count', deletedUsers.length)
                }
              />
            </DataGrid>
          )}
        </GridContainer>
      </ContentContainer>
      {bottomToolbarButtons.map((buttonOptions, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOptions }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

DeletedUsers.propTypes = {
  visible: bool.isRequired,
  setVisible: func.isRequired,
};

DeletedUsers.defaultProps = {
  visible: false,
  setVisible: () => {},
};
