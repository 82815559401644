import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Player,
  ControlBar,
  VolumeMenuButton,
  BigPlayButton,
  LoadingSpinner,
} from 'video-react';

import API from '../../services/API';

import {
  Container,
  WrapperTitle,
  WrapperPlatform,
  WrapperItemsPlatform,
  WrapperModules,
  WrapperItems,
  WrapperItem,
  WrapperSubmodules,
  WrapperSubitem,
  WrapperBreadcrumbs,
  WrapperMedia,
  WrapperAccordion,
  WrapperVideo,
  WrapperTopics,
  Topic,
  WrapperPictures,
  WrapperContentAccordion,
  GroupDesc,
  TextContent,
} from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  breadcrumbs: {
    fontSize: theme.typography.pxToRem(30),
    textAlign: 'left',
  },
}));

function Help() {
  const [modules, setModules] = useState();
  const [submodules, setSubmodules] = useState('');
  const [contentHelp, setContentHelp] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [expanded, setExpanded] = useState(false);
  const playVideo = useRef(null);
  const [active, setActive] = useState({
    platform: null,
    module: null,
    submodule: null,
    topic: null,
  });
  const classes = useStyles();

  const rootMenuList = [
    {
      id: 1,
      name: 'Web Admin',
      icon: 'fa fa-tools',
    },
    {
      id: 2,
      name: 'Mobile',
      icon: 'fa fa-mobile-alt',
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function handlePlatform(menuid) {
    setActive({
      ...active,
      platform: menuid,
    });

    let response = await API.sendRequest(`menu/listbyuser/${menuid}`, 'get');
    response = response.data.filter(
      (item) => item.name.toLowerCase() !== 'help'
    );

    setModules(response);
  }

  function handleSubmodule(menuid) {
    setActive({
      ...active,
      module: menuid,
    });
    let submodule = modules.filter((item) => item.id === menuid);
    submodule = submodule[0].items;
    setSubmodules(submodule);
  }

  async function handleContentHelp(menuid) {
    setActive({
      ...active,
      submodule: menuid,
    });
    const response = await API.sendRequest(`menu/helpbymenu/${menuid}`, 'get');
    setContentHelp(response.data);
  }

  function handleActiveTopic(topicId, videoUrl) {
    setActive({
      ...active,
      topic: topicId,
    });
    setVideoUrl(videoUrl);
    playVideo.current.actions.play();
  }

  return (
    <Container>
      <WrapperTitle>
        <h3>Team Relay</h3>
        <h1>Welcome to Help Center</h1>
      </WrapperTitle>

      <WrapperPlatform>
        <WrapperItemsPlatform>
          {rootMenuList &&
            rootMenuList.map((item) => (
              <WrapperItem
                platform
                itemActive={active.platform === item.id}
                key={item.id}
                onClick={() => handlePlatform(item.id)}
              >
                <i
                  className={`${item.icon} fa-2x`}
                  aria-hidden="true"
                  style={{
                    color: active.platform === item.id ? '#FFF' : '#4F68C2',
                  }}
                />
                <span>{item.name}</span>
              </WrapperItem>
            ))}
        </WrapperItemsPlatform>
      </WrapperPlatform>

      <WrapperModules>
        <WrapperItems>
          {modules &&
            modules.map((item) => (
              <WrapperItem
                itemActive={active.module === item.id}
                key={item.id}
                onClick={() => handleSubmodule(item.id)}
              >
                <i
                  className={`${item.icon} fa-6x`}
                  aria-hidden="true"
                  style={{
                    color: active.module === item.id ? '#FFF' : '#4F68C2',
                  }}
                />
                <span>{item.name}</span>
              </WrapperItem>
            ))}
        </WrapperItems>
      </WrapperModules>

      {submodules !== '' && (
        <WrapperSubmodules>
          {submodules.map((item) => (
            <WrapperSubitem
              itemActive={active.submodule === item.id}
              key={item.id}
              onClick={() => handleContentHelp(item.id)}
            >
              <i
                className={`${item.icon} fa-3x`}
                aria-hidden="true"
                style={{
                  color: active.submodule === item.id ? '#FFF' : '#4F68C2',
                }}
              />
              <span>{item.name}</span>
            </WrapperSubitem>
          ))}
        </WrapperSubmodules>
      )}

      {contentHelp !== '' && contentHelp.HasHelp && (
        <>
          <WrapperBreadcrumbs>
            <Typography color="textPrimary" className={classes.breadcrumbs}>
              {contentHelp.BreadCrumb}
            </Typography>
          </WrapperBreadcrumbs>

          <WrapperMedia>
            <WrapperVideo>
              <Player
                id="playVideo"
                src={videoUrl}
                width="100%"
                height={500}
                fluid={false}
                ref={playVideo}
              >
                <LoadingSpinner />
                <BigPlayButton position="center" />
                <ControlBar autoHide={false}>
                  <VolumeMenuButton vertical />
                </ControlBar>
              </Player>
            </WrapperVideo>
            <WrapperTopics>
              <div>
                <h3>Topics</h3>
              </div>
              {contentHelp.Topics.map((item) => (
                <Topic
                  onClick={() =>
                    handleActiveTopic(item.HelpTopicId, item.VideoUrl)
                  }
                  active={active.topic === item.HelpTopicId}
                >
                  <p>{item.TopicDesc}</p>
                  <p>{item.Duration}</p>
                </Topic>
              ))}
            </WrapperTopics>
          </WrapperMedia>

          <WrapperAccordion>
            <div className={classes.root}>
              {contentHelp.Groups &&
                contentHelp.Groups.map((item) => (
                  <ExpansionPanel
                    expanded={expanded === item.HelpTextGroupId}
                    onChange={handleChange(item.HelpTextGroupId)}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${item.HelpTextGroupId}bh-content`}
                      id={`${item.HelpTextGroupId}bh-header`}
                    >
                      <GroupDesc className={classes.heading}>
                        {item.GroupDesc}
                      </GroupDesc>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <WrapperContentAccordion>
                        {item.Texts.map((item2) => (
                          <TextContent format={item2.StyleCss}>
                            {item2.TextContent}
                          </TextContent>
                        ))}
                      </WrapperContentAccordion>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
            </div>
          </WrapperAccordion>

          {contentHelp.Pictures && (
            <WrapperPictures>
              {contentHelp.Pictures.map((item) => (
                <img
                  src={item.PictureUrl}
                  alt={item.PictureDesc}
                  key={item.id}
                />
              ))}
            </WrapperPictures>
          )}
        </>
      )}
    </Container>
  );
}

export default Help;
