import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config';

export default class Common {
  static async checkToken() {
    //call refreshtoken for new ones
    const tokenExpired = this.isExpired(Cookies.get('token'));
    if (tokenExpired) {
      //try refresh token
      const refreshToken = Cookies.get('refreshtoken');
      const data = {
        Username: '.',
        RefreshToken: refreshToken,
      };

      const response = (await axios.post(`${config.apiUrl}/user/refresh`, data))
        .data;

      //if not receive user, there's something wrong
      if (!response.User) {
        Cookies.remove('token');
        Cookies.remove('refreshtoken');

        return false;
      }

      Cookies.set('token', response.User.Token);
      Cookies.set('refreshtoken', response.User.RefreshToken);
    }

    return true;
  }

  static isExpired(token) {
    if (!token) {
      return true;
    }

    const tokenSplit = token.split(' ');

    let tokens = [];
    if (tokenSplit.length > 1) {
      tokens = tokenSplit[1].split('.');
    } else {
      tokens = tokenSplit[0].split('.');
    }

    const tokenData = JSON.parse(
      Buffer.from(tokens[1], 'base64').toString('ascii')
    );

    if (!tokenData.exp) {
      return false;
    }

    // 1 minute to expire, already expired
    const todayTimestamp = Math.floor(Date.now() / 1000) + 60;
    if (tokenData.exp > todayTimestamp) {
      return false;
    }

    return true;
  }
}