import React from 'react';
import { array, bool, func } from 'prop-types';
import { Popup, ScrollView } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/date-box';

import { Holiday, Square, WrapperHoliday } from './styles';
import { format, parseISO } from 'date-fns';

function HolidaysPopup({ visible, holidaysData, closeFunction }) {
  const buttonsOptions = [
    {
      text: 'Close',
      type: 'normal',
      onClick: () => {
        closeFunction();
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      title="Holidays"
      showCloseButton={false}
      width={400}
      height={400}
    >
      <ScrollView>
        <WrapperHoliday>
          {holidaysData?.map((item) => (
            <Holiday key={item?.HolidayId}>
              <Square />
              <div className="texts">
                <span>{item?.HolidayDesc}</span>
                <b>{format(parseISO(item?.HolidayDate), 'MM/dd/yyyy')}</b>
              </div>
            </Holiday>
          ))}
        </WrapperHoliday>
      </ScrollView>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

HolidaysPopup.propTypes = {
  visible: bool,
  holidaysData: array,
  closeFunction: func,
};

HolidaysPopup.defaultProps = {
  visible: false,
  holidaysData: [],
  closeFunction: () => {},
};

export default HolidaysPopup;
