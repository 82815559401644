import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  /* background-color: red; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 14px;
`;

export const FormContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
`;

export const FieldSet = styled.fieldset`
  border: unset;
  background: unset;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  flex-wrap: wrap;

  align-items: ${({ align }) => align || 'flex-start'};
  gap: 8px;
  width: ${({ width }) => width || '100%'};

  label {
    width: 100px;
    margin-left: 4px;
    white-space: nowrap;
  }

  .flex {
    display: flex;
    gap: 8px;
  }
`;

export const SelectBoxItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const ItemIcon = styled.i`
  color: ${({ $iconColor }) => $iconColor || '#000000'};
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
`;

export const WrapperTagBox = styled.div`
  color: #000;
  flex: 1;

  #wrapperTag {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
