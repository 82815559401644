import styled from 'styled-components';
import ToolbarMUI from '@material-ui/core/Toolbar';

export const WrapperGeneral = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  //border: 2px solid yellow;
`;

export const Toolbar = styled(ToolbarMUI)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
`;

export const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.3rem;
  margin-right: 15px;
  // border: 2px solid white;
`;

export const WrapperSvg = styled.div`
  max-width: 50px;
  max-height: 50px;
`;

export const WrapperMenu = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //border: 2px solid white;
`;

export const WrapperRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const WrapperHelp = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #fff;
    opacity: 0.8;
    transition: opacity 0.4s;

    margin-left: 10px;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    p {
      font-size: 15px;
    }

    i {
      font-size: 20px;
      margin-left: 5px;
    }
  }
`;

export const WrapperProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileButton = styled.div`
  cursor: pointer;
`;
