import React, { useEffect, useState } from 'react';
import {
  Column,
  DataGrid,
  Paging,
  Editing,
  Selection,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import useStyles from '../styles/styles';
import API from '../../services/API';
import usePermissions from '../../hooks/usePermissions';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import AreaEditPopup from './Popups/AreaEditPopup';
import HistoryPopup from '../../components/popups/HistoryPopup';
import getChangeHistory from '../../utils/getChangeHistory';

// const areas = new CustomStore({
//   key: 'AreaId',
//   load: async () => {
//     const data = (await API.sendRequest(`Area`, 'get')).data;
//     return data;
//   },
//   insert: async (data) => {
//     await API.sendRequest(`Area`, 'post', data);
//   },
//   update: async (id, data) => {
//     await API.sendRequest(`Area`, 'post', data);
//   },
//   remove: async (id) => {
//     await API.sendRequest(`Area/${id}`, 'delete');
//   },
// });

const Area = () => {
  const classes = useStyles();
  const [areas, setAreas] = useState([]);
  const [editPopupVisible, setEditPopupVisible] = useState(false);
  const [currentArea, setCurrentArea] = useState(null);
  const [areaChangeHistory, setAreaChangeHistory] = useState(null);
  const [historyPopupVisible, setHistoryPopupVisible] = useState(false);

  useEffect(() => {
    async function init() {
      const { data } = await API.sendRequest(`Area`, 'get');
      setAreas(data);
    }
    init();
  }, []);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (!ACL.Admin) {
    return (
      <LoadPermissions>
        You don't have access to this page. For Administrators Only.
      </LoadPermissions>
    );
  }
  /** ****************************** */

  const handleEditArea = async (e) => {
    const { data } = await API.sendRequest(`Area/${e.row.data.AreaId}`);
    // console.log('data:', data);
    setCurrentArea(data);
    setEditPopupVisible(true);
  };

  const addNewArea = () => {
    setCurrentArea({
      AreaDesc: '',
      DistributionList: null,
      SummaryBefore: 0,
      UseInSchedule: false,
      ScheduleMenuId: null,
      UseDraft: false,
      UseTeams: false,
      HideAppointmentsToOtherAreas: false,
      AvailabilityPeriod: 1,
      ErrorMsg: null,
    });
    setEditPopupVisible(true);
  };

  const handleShowChangeHistory = async (e) => {
    await getChangeHistory('Area', e.row.data.AreaId).then((ret) => {
      if (ret) {
        setAreaChangeHistory(ret);
        setHistoryPopupVisible(true);
      }
    });
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'breadCrumb',
    });

    if (ACL.AddNew) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: addNewArea,
        },
      });
    }
  };

  const buttonList = [];
  if (ACL.Update) {
    buttonList.push(
      {
        hint: 'Show Change History',
        icon: 'fa fa-history',
        onClick: handleShowChangeHistory,
        visible: ACL?.Admin,
      },
      {
        hint: 'Edit Area',
        icon: 'edit',
        onClick: handleEditArea,
      }
    );
  }

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={areas}
        keyExpr="AreaId"
        // onRowUpdating={onRowUpdating}
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Paging enabled={false} />
        <Selection mode="single" />
        <Editing mode="popup" allowUpdating={ACL.Update} />
        <Column dataField="AreaDesc" caption="Area" />
        <Column dataField="UseInSchedule" />
        <Column dataField="UseDraft" />
        <Column dataField="UseTeams" />
        <Column dataField="DistributionList" />
        <Column dataField="SummaryBefore" />
        <Column caption="Actions" type="buttons" buttons={buttonList} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
      {currentArea && (
        <AreaEditPopup
          visible={editPopupVisible}
          setVisible={setEditPopupVisible}
          currentArea={currentArea}
        />
      )}
      {historyPopupVisible && (
        <HistoryPopup
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={areaChangeHistory}
        />
      )}
    </div>
  );
};

export default Area;
