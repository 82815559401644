import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
`;

export const DataGridContainer = styled.div`
  display: ${({ visible }) => !visible && 'none'};
  width: 100%;
  min-height: calc(100vh - 160px);
`;

export const TitleGrid = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: #161616;
`;
