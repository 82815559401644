import React, { useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { object, bool, func, string } from 'prop-types';
import notify from 'devextreme/ui/notify';
import API from '../../../../../services/API';
import queryClient from '../../../../../services/queryClient';

import { Container, LabelContainer, Text } from './styles';
import { LoadPanel } from 'devextreme-react';

function PopupMemberDelete({
  visible,
  onClose,
  memberData,
  groupName,
  groupDName,
}) {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await API.sendRequest('ADUser/RemoveGroupMember', 'post', {
        DistinguishedName: memberData?.DistinguishedName,
        GroupDName: groupDName,
      });

      if (res.status !== 200) {
        throw new Error(res.message);
      }

      queryClient.invalidateQueries('distList');
      queryClient.invalidateQueries('distListByUser');
      setDeleting(false);
      onClose();
      notify('Member Deleted', 'success', 2000);
    } catch (e) {
      setDeleting(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        handleDelete();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        onClose();
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      onHiding={onclose}
      width={500}
      height={250}
      showCloseButton={false}
      title="Delete Member"
      disabled={deleting}
    >
      <LoadPanel
        visible={deleting}
        message="Deleting..."
        shadingColor="rgba(255,255,255,0.4)"
        closeOnOutsideClick={false}
      />
      <Container>
        <LabelContainer>
          <Text bold>
            {memberData?.DisplayName
              ? `[${memberData?.DisplayName}] will be removed from [${groupName}].`
              : 'The member will be deleted.'}
          </Text>
        </LabelContainer>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

export default PopupMemberDelete;

PopupMemberDelete.propTypes = {
  visible: bool,
  onClose: func.isRequired,
  memberData: object,
  groupName: string.isRequired,
  groupDName: string.isRequired,
};

PopupMemberDelete.defaultProps = {
  visible: false,
  memberData: {},
};
