import { Button, LoadPanel, Popup, ScrollView } from 'devextreme-react';
import React, { useEffect, useMemo, useState } from 'react';

import notify from 'devextreme/ui/notify';
import API from 'services/API';
import ScheduleItem from './ScheduleItem';
import { Container, WrapperControls } from './styles';

function ScheduleExpirationPopup({ visible, onClose, data, refetch }) {
  const [expirationAlertList, setExpirationAlertList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  function setInitialData() {
    setIsLoading(true);
    const listToShowJSON = data?.ExpirationSchedules;
    let expirationList = [];

    try {
      const listToShow = JSON.parse(listToShowJSON);
      expirationList = listToShow;
    } catch (error) {
      expirationList = [];
    }

    setExpirationAlertList(expirationList);
    setIsEditing(false);
    setIsLoading(false);
  }

  useEffect(() => {
    setInitialData();
    // eslint-disable-next-line
  }, [visible, data]);

  async function handleSaveAlerts() {
    setIsLoading(true);
    const alertsToSave = expirationAlertList?.map((alert, index) => {
      if (String(alert?.ACTExpirationScheduleId).includes('TemporaryId')) {
        return {
          DisplayOrder: index + 1,
          TimeBefore: alert?.TimeBefore,
          TimeType: alert?.TimeType,
        };
      }
      return alert;
    });

    try {
      const res = await API.sendRequest('EMRSetup', 'post', {
        ...data,
        ExpirationSchedules: JSON.stringify(alertsToSave),
      });

      if (res?.data?.ErrorMsg) {
        notify(res.data?.ErrorMsg, 'error', 5000);
        setIsLoading(false);
        return;
      }
      refetch();
    } catch (error) {
      notify('Error to save alert', 'error', 5000);
    } finally {
      setIsLoading(false);
    }
  }
  const handleDragStart = (e, order) => {
    e.dataTransfer.setData('text/plain', order.toString());
  };

  const handleDragOver = (e, order) => {
    e.preventDefault();
    const draggedOrder = Number(e.dataTransfer.getData('text/plain'));
    if (order !== draggedOrder) {
      e.dataTransfer.dropEffect = 'move';
    }
  };

  const handleDrop = (e, targetOrder) => {
    e.preventDefault();
    const draggedOrder = Number(e.dataTransfer.getData('text/plain'));

    if (draggedOrder === targetOrder) {
      return;
    }
    const updatedItems = [...expirationAlertList];
    const draggedItemIndex = updatedItems.findIndex(
      (item) => item?.DisplayOrder === draggedOrder
    );
    const targetItemIndex = updatedItems.findIndex(
      (item) => item?.DisplayOrder === targetOrder
    );
    if (draggedItemIndex !== -1 && targetItemIndex !== -1) {
      const draggedItem = updatedItems.splice(draggedItemIndex, 1)[0];
      updatedItems.splice(targetItemIndex, 0, draggedItem);
      updatedItems.forEach((item, index) => {
        item.DisplayOrder = index + 1;
      });

      setExpirationAlertList(updatedItems);
    }
  };

  function getOrdinal(number) {
    switch (number % 100) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }

  return (
    <Popup
      maxWidth={1000}
      visible={visible}
      title="Alert Settings"
      onHiding={onClose}
    >
      {useMemo(
        () => (
          <Container>
            <LoadPanel
              shadingColor="rgba(255,255,255,0.4)"
              visible={isLoading && visible}
              message="Loading..."
            />
            <ScrollView>
              <div className="list">
                {!!expirationAlertList?.length ? (
                  expirationAlertList
                    ?.sort((a, b) => a.DisplayOrder - b.DisplayOrder)
                    ?.map((item, index) => (
                      <div
                        key={index}
                        className="dropItem"
                        draggable={isEditing}
                        onDragStart={(e) =>
                          handleDragStart(e, item.DisplayOrder)
                        }
                        onDragOver={(e) => handleDragOver(e, item.DisplayOrder)}
                        onDrop={(e) => handleDrop(e, item.DisplayOrder)}
                      >
                        <ScheduleItem
                          data={item}
                          isEditing={isEditing}
                          setAlertList={setExpirationAlertList}
                          title={getOrdinal(index + 1)}
                        />
                      </div>
                    ))
                ) : (
                  <h4>No Alerts</h4>
                )}
                <Button
                  icon="plus"
                  title="Add Alert"
                  onClick={() => {
                    setExpirationAlertList((prev) => [
                      ...(prev || []),
                      {
                        DisplayOrder: prev?.length + 1,
                        TimeBefore: 1,
                        TimeType: 1,
                        ACTExpirationScheduleId: `TemporaryId_${
                          Number(prev?.length || 0) + 1
                        }`,
                      },
                    ]);
                  }}
                  visible={isEditing}
                />
              </div>
            </ScrollView>
          </Container>
        ),
        // eslint-disable-next-line
        [expirationAlertList, isLoading, data, isEditing, visible]
      )}
      <WrapperControls>
        <Button
          text={!!expirationAlertList?.length ? 'Edit' : 'Add Alerts'}
          type="default"
          onClick={() => setIsEditing(true)}
          visible={!isEditing}
        />
        <Button
          text="Save"
          type="success"
          onClick={handleSaveAlerts}
          visible={isEditing}
        />
        <Button
          text="Cancel"
          onClick={() => {
            setInitialData();
          }}
          visible={isEditing}
        />
      </WrapperControls>
    </Popup>
  );
}

export default ScheduleExpirationPopup;
