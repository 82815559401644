// import { format } from 'date-fns';
import DataGrid, {
  Column,
  FilterRow,
  Lookup,
  MasterDetail,
  Selection,
  Sorting,
} from 'devextreme-react/data-grid';
import { useEmrAccess } from 'pages/EMRAccess/contexts/EmrAccessContext';
import { array, object } from 'prop-types';
import React from 'react';
import useAllAreasList from 'services/requests/useAllAreasList';
import useEmrRequestMethodList from 'services/requests/useEmrRequestMethodList';
import useEmrRequestStatusList from 'services/requests/useEmrRequestStatusList';
import RequestsDetail from '../RequestsDetail';

const EmployeesGrid = ({ gridData, ACL }) => {
  const { data: areasList } = useAllAreasList();
  const { data: requestStatusDataList } = useEmrRequestStatusList();
  const { data: requestMethodList } = useEmrRequestMethodList();

  const {
    showMissingCredentialsOnly,
    dataGridEmployeesRef,
    requestsRef,
  } = useEmrAccess();

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
    requestsRef.current = {
      Apps: [],
      Users: [],
    };
  }

  return (
    <DataGrid
      dataSource={gridData}
      keyExpr="UserId"
      noDataText="There are no Request(s) in this category"
      height="75vh"
      showBorders
      focusedRowEnabled
      onSelectionChanged={selectionChanged}
      ref={dataGridEmployeesRef}
    >
      <Selection mode="single" />
      <FilterRow visible applyFilter="auto" />
      <Sorting />
      <Selection mode="single" showCheckBoxesMode="always" />
      <Column dataField="DisplayName" caption="Name" />
      <Column dataField="AreaId" caption="Area">
        <Lookup
          dataSource={areasList}
          displayExpr="AreaDesc"
          valueExpr="AreaId"
        />
      </Column>
      <MasterDetail
        enabled={false}
        component={({ data }) => (
          <RequestsDetail
            data={data}
            ACL={ACL}
            type="USER"
            requestStatusDataList={requestStatusDataList}
            requestMethodList={requestMethodList}
          />
        )}
        autoExpandAll={showMissingCredentialsOnly}
        requestMethodList={requestMethodList}
        requestStatusDataList={requestStatusDataList}
      />
    </DataGrid>
  );
};

EmployeesGrid.propTypes = {
  gridData: array,
  ACL: object.isRequired,
};

EmployeesGrid.defaultProps = {
  gridData: [],
};

export default EmployeesGrid;
