import DataGrid, {
  Column,
  Pager,
  SearchPanel,
  Selection,
  Button,
} from 'devextreme-react/data-grid';
import { array, func } from 'prop-types';
import React, { useState } from 'react';

import Avatar from '../../components/contacts/Avatar';
import ContactPopup from '../../components/ContactPopup';
import { AvatarWrapper } from './styles';
import PopupConfirm from 'components/popups/PopupConfirm';
import API from 'services/API';
import notify from 'devextreme/ui/notify';
import { formatPhoneNumber } from 'utils/format';

function ContactsGrid({ dataSource, setRefetch }) {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [popupContact, setPopupContact] = useState({
    visible: false,
    data: {},
  });

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'Queries/Contacts',
    });
  };

  function avatarCellRender({ data }) {
    if (data) {
      return (
        <AvatarWrapper>
          <Avatar contact={data} h={30} w={30} />
        </AvatarWrapper>
      );
    }

    return <img height="50px" src="assets/blank.jpg" alt="" />;
  }

  function phoneCellRender({ data }) {
    const phonesObj = JSON.parse(data?.Phones);

    if (!phonesObj?.length) {
      return '';
    }

    return (
      (phonesObj?.find((phone) => phone?.Preferred)?.PhoneNumber ??
        formatPhoneNumber(phonesObj[0]?.PhoneNumber)) ||
      (phonesObj?.find((phone) => phone?.pf)?.pn ??
        formatPhoneNumber(phonesObj[0]?.pn))
    );
  }

  const handleOpenPopupContact = ({ data }) => {
    const contactsData = {
      ...data,
      Phones: JSON.parse(data.Phones),
    };
    setPopupContact({
      visible: true,
      data: contactsData,
    });
  };
  const handleClosePopupContact = () => {
    setPopupContact({
      visible: false,
      data: {},
    });
  };

  const handleDeleteContact = (e) => {
    setOpenDeletePopup(true);
    setContactToDelete(e.row.data);
  };

  const deleteContact = async () => {
    try {
      const res = await API.sendRequest(
        `Contact/${contactToDelete?.UserId}`,
        'DELETE'
      );

      if (res.status === 200) {
        setOpenDeletePopup(false);
        setContactToDelete(null);
        setRefetch(true);
        notify('Contact deleted successfully', 'success', 5000);
      }

      if (res.status === 500) {
        throw new Error('This contact is in use and cannot be deleted');
      }
    } catch (error) {
      setOpenDeletePopup(false);
      notify(error, 'error', 5000);
    }
  };

  return (
    <>
      {popupContact.data && popupContact.visible && (
        <ContactPopup
          visible={popupContact.visible}
          data={popupContact.data}
          onClose={handleClosePopupContact}
        />
      )}
      {openDeletePopup && (
        <PopupConfirm
          message="Are you sure you want to delete this contact?"
          visible={openDeletePopup}
          onConfirm={deleteContact}
          onCancel={() => setOpenDeletePopup(false)}
        />
      )}
      <DataGrid
        dataSource={dataSource}
        height="100%"
        showBorders
        showColumnLines
        showRowLines
        searchPanel={{ visible: true }}
        filterBuilder={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarPreparing}
        onRowClick={handleOpenPopupContact}
        hoverStateEnabled
        rowAlternationEnabled
      >
        <SearchPanel visible width={240} placeholder="Search..." />

        <Pager
          showPageSizeSelector
          allowedPageSizes={[15, 30, 50, 100, 200, 400, 600]}
          showInfo
        />
        <Selection mode="single" />
        <Column
          caption=""
          dataField="Username"
          width={50}
          allowSorting={false}
          cellRender={avatarCellRender}
        />
        <Column caption="Name" dataField="FullName" width="15%" />
        <Column dataField="ADUser" width="15%" />
        <Column dataField="JobTitle" width="20%" />
        <Column dataField="DepartmentDesc" width="20%" caption="Department" />
        <Column dataField="Email" width="15%" />
        <Column
          caption="Phone Number"
          cellRender={phoneCellRender}
          width="10%"
        />

        <Column type="buttons" caption="Actions">
          <Button
            icon="trash"
            onClick={handleDeleteContact}
            visible={(data) => !data.row.data.ADUser}
          />
        </Column>
      </DataGrid>
    </>
  );
}

ContactsGrid.propTypes = {
  // workingDay: number.isRequired,
  dataSource: array.isRequired,
  setRefetch: func,
};

ContactsGrid.defaultProps = {
  // workingDay: 0,
  setRefetch: () => {},
};

export default ContactsGrid;
