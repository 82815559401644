import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export default makeStyles((theme) => ({
  Header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    gap: '2px',
    flexWrap: 'wrap',
  },
  BreadCrumb: {},
  Space: {
    width: 2,
  },
  divGridContainer: {
    margin: 'auto',
    width: '98%',
    height: '85vh',
    textAlign: 'center',
  },
  divGridScheduleContainer: {
    margin: 'auto',
    width: '100%',
    height: '92%',
    textAlign: 'center',
  },
  cellRender: {
    height: '34px',
    lineHeight: '34px',
    padding: '0 5px',
  },
  cellRender2: {
    height: '17px',
    lineHeight: '17px',
    padding: '0 5px',
  },
  cellRenderTd: {
    padding: '0 !important',
  },
  fullName: {
    height: '34px',
    lineHeight: '34px',
  },
  fullNameMobile: {
    color: 'white',
    height: '33px',
    lineHeight: '33px',
  },
  itemBadge: {
    textAlign: 'center',
    float: 'left',
    marginRight: '12px',
    color: 'white',
    width: '18px',
    height: '18px',
    fontSize: '19.5px',
    borderRadius: '18px',
    marginTop: '2px',
    border: '0.5px solid #eee',
  },
  itemAvailability: {
    textAlign: 'center',
    float: 'left',
    marginRight: '4px',
    color: 'white',
    width: '4px',
    height: '18px',
    marginTop: '2px',
  },

  listRoot: {
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;

  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;

  margin: auto 2px;
  gap: 12px;
  padding: 8px;
  height: 48px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

export const LegendSquare = styled.div`
  min-height: 30px;
  min-width: 10px;
  background-color: ${({ bg }) => bg};
  border-radius: 3px;
`;

export const LegendLabel = styled.span`
  font-size: 0.9rem;
`;

export const LoadContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BgLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 8px;
  background-color: ${({ bg }) => bg};

  user-select: none;
  cursor: ${({ button }) => button && 'pointer'};
  :hover {
    opacity: ${({ button }) => button && 0.9};
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: baseline;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 4px;
  min-height: 48px;

  .dx-radiobutton-checked .dx-radiobutton-icon .dx-radiobutton-icon-dot {
    background: #3f51b5;
  }
`;
export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;

  margin: auto 1px;
  padding: 4px;
  min-height: 48px;
`;

export const UpdateLabel = styled.span`
  text-align: center;
  font-weight: 700;
  font-size: 14px;
`;

export const RadioLegend = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  span {
    font-size: 16px;
  }
  i {
    margin-right: 5px;
  }
`;

export const WarningLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: red;
`;
