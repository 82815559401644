import React from 'react';

import { ItemTotal, ItemTotalBold } from './styles';

const CellTotal = ({ source, handleCellClick, handleMenuClick }) => {
  if (source.data.Initials === '##') {
    return (
      <ItemTotalBold
        onClick={e => handleCellClick(e, source)}
        onContextMenu={e => handleMenuClick(e, source)}
      >
        {source.value}
      </ItemTotalBold>
    );
  } else {
    return (
      <ItemTotal
        onClick={e => handleCellClick(e, source)}
        onContextMenu={e => handleMenuClick(e, source)}
      >
        {source.value === '0' ? '' : source.value}
      </ItemTotal>
    );
  }
};

export default CellTotal;
