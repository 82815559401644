import { addCircle, trash } from 'assets';
import AttachmentIcon from 'assets/attachmentIcon';
import { getArchiveIcon } from 'assets/getArchiveIcon';
import config from 'config';
import { format, parseISO } from 'date-fns';
import { Button, LoadIndicator, LoadPanel } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import TextEditor from 'pages/EMRSetup/components/TextEditor';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import API from '../../../../../../services/API';
import {
  Attachments,
  Content,
  EmailTitle,
  ReplyContent,
  WrapperAttachments,
  WrapperEmail,
  WrapperHeader,
  WrapperImage,
  WrapperInfos,
  WrapperReply,
  WrapperReplyButton,
  WrapperReplyButtons,
  WrapperReplyContent,
} from './styles';

function EmailBox({
  currentData,
  emailData,
  handleOpenFile,
  isSelected,
  onHeaderClick,
  fetchEmailList,
  refetch,
}) {
  const pdfLoaderRef = useRef(null);
  const attachmentList = JSON?.parse(emailData?.AttachmentsNames) || [];
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
  const [showReplyDropdown, setShowReplyDropdown] = useState(false);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [htmlContent, setHtmlContent] = useState('');

  const emailEnv = config.isDev
    ? 'truserdev@njsharingnetwork.org'
    : 'NJSNACT@njsharingnetwork.org';

  async function fetchEmailData() {
    setIsLoading(true);
    try {
      if (!emailData?.IsRead) {
        await API.sendRequest(`ExchangeEmail/UpdateEmailRead`, 'post', {
          ...emailData,
          IsRead: true,
        });
        fetchEmailList(currentData?.ADUserEMRAccessId);
      }

      // const { data: response } = await API.sendRequest(
      //   `ExchangeEmail/GetExchangeFile/${emailData?.FileName.replace(
      //     '.eml',
      //     '.HTML'
      //   )}`,
      //   'get'
      // );

      // setEmailDetails(response);
      setIsLoading(false);
    } catch (error) {
      notify('Unable to load email', 'error', 5000);
    }
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && isSelected) {
      fetchEmailData();
    }
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, [isSelected]);

  async function handleOpenPDFFile(attachmentName) {
    let fileLink;
    if (
      attachmentName
        ?.toLowerCase()
        ?.includes(emailData?.ConversationId?.toLowerCase())
    ) {
      fileLink = attachmentName;
    } else {
      const extensionDotIndex = attachmentName?.lastIndexOf('.');
      const extension = attachmentName?.substring(extensionDotIndex);
      fileLink = `${attachmentName}_${emailData?.EmailId}_${emailData?.ConversationId}${extension}`;
    }

    const attachmentURL = `https://teamrelaystore.blob.core.windows.net/exchange/${fileLink}`;
    handleOpenFile(attachmentURL, false);
  }

  const handleOpenLocalFile = (file) => {
    handleOpenFile(file, true);
  };

  const handleAddFile = () => {
    pdfLoaderRef.current.click();
  };

  const handleFileUpload = (event) => {
    const { files } = event.target;

    const filesToSave = [...attachmentsList, ...files];
    const filesSize = filesToSave.reduce(
      (totalSize, file) => totalSize + file.size,
      0
    );

    if (filesSize > 9000000) {
      notify('Maximum file size is 9MB', 'error', 6000);
      return;
    }
    setAttachmentsList(filesToSave);
  };

  const handleSendReply = async () => {
    setIsLoadingSendEmail(true);
    const formData = new FormData();

    attachmentsList.forEach((file) => {
      formData.append('Attachment', file);
    });
    formData.append('FileName', emailData?.FileName);
    formData.append('ConversationId', emailData?.ConversationId);
    formData.append('EmailBody', htmlContent + '<hr/>');
    formData.append('ADUserEMRAccessId', currentData?.ADUserEMRAccessId);
    try {
      const res = await API.sendRequest(
        'ExchangeEmail/ReplyEmail',
        'POST',
        formData
      );
      if (res.status === 200) {
        fetchEmailList(currentData?.UserId);
        setIsLoadingSendEmail(false);
        setShowReplyDropdown(false);
        setHtmlContent('');
        setAttachmentsList([]);
        notify('Email sent', 'success', 3000);
        refetch();
      }
      setIsLoadingSendEmail(false);
    } catch (error) {
      setIsLoadingSendEmail(false);
      notify('Unable to send email', 'error', 5000);
      console.log(error);
    }
  };

  const handleCloseReplyDropdown = () => {
    setShowReplyDropdown(false);
    setHtmlContent('');
    setAttachmentsList([]);
  };

  const handleDownload = async ({ fileUrl, fileName }) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error while downloading file:', error);
    }
  };

  return (
    <WrapperEmail>
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={isLoadingSendEmail}
        message="Sending email..."
        closeOnOutsideClick={false}
      />
      <WrapperHeader
        onClick={() => {
          onHeaderClick();
          setShowReplyDropdown(false);
        }}
      >
        <WrapperImage>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
          >
            <g
              id="Grupo_2632"
              data-name="Grupo 2632"
              transform="translate(-265 -234)"
            >
              <circle
                id="Elipse_218"
                data-name="Elipse 218"
                cx="30"
                cy="30"
                r="30"
                transform="translate(265 234)"
                fill="#326a95"
              />
              <path
                id="hospital-solid"
                d="M10.294,2.574A2.574,2.574,0,0,1,12.868,0h8.578a2.574,2.574,0,0,1,2.574,2.574V27.451H19.73V23.162a2.574,2.574,0,1,0-5.147,0v4.289H10.294Zm6.434.858a.859.859,0,0,0-.858.858V5.576H14.583a.86.86,0,0,0-.858.858v.858a.86.86,0,0,0,.858.858H15.87V9.436a.86.86,0,0,0,.858.858h.858a.86.86,0,0,0,.858-.858V8.149H19.73a.86.86,0,0,0,.858-.858V6.434a.86.86,0,0,0-.858-.858H18.443V4.289a.859.859,0,0,0-.858-.858ZM8.578,5.147v22.3h-6A2.574,2.574,0,0,1,0,24.877V17.157H4.289a.858.858,0,1,0,0-1.716H0V12.01H4.289a.858.858,0,0,0,0-1.716H0V7.721A2.574,2.574,0,0,1,2.574,5.147Zm23.162,0a2.574,2.574,0,0,1,2.574,2.574v2.574H30.024a.858.858,0,1,0,0,1.716h4.289v3.431H30.024a.858.858,0,1,0,0,1.716h4.289v7.721a2.574,2.574,0,0,1-2.574,2.574h-6V5.147Z"
                transform="translate(277.843 250.275)"
                fill="#fff"
              />
            </g>
          </svg>
        </WrapperImage>
        <WrapperInfos>
          <div>
            <EmailTitle isRead={emailData?.IsRead}>
              {emailData?.IsRead ? (
                <i className="fa fa-envelope-open" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-envelope" aria-hidden="true"></i>
              )}{' '}
              {emailData?.Subject}{' '}
              {!!attachmentList?.length && <AttachmentIcon />}
            </EmailTitle>
            <p>{`From: ${emailData?.From}`}</p>
            <p>
              {'To: '}
              <span>{emailData?.To?.replaceAll(',', ', ')}</span>
            </p>
          </div>
          <div>
            {emailData && emailData?.From?.toLowerCase() === emailEnv ? (
              <i className="fa-solid fa-share" aria-hidden="true"></i>
            ) : (
              <i
                className="fa-solid fa-share fa-rotate-180"
                style={{ color: '#0ea427' }}
                aria-hidden="true"
              ></i>
            )}
            <p>{format(parseISO(emailData?.Date), 'M/d/yyyy h:mm a')}</p>
          </div>
        </WrapperInfos>
      </WrapperHeader>
      {isSelected && (
        <Content>
          {/* {emailData?.From.toLowerCase() !== config?.TREmail && ( */}
          <WrapperReply>
            <WrapperReplyButton>
              {!showReplyDropdown && (
                <Button
                  type="default"
                  icon="fas fa-reply"
                  text={
                    emailData?.From.toLowerCase() !== emailEnv
                      ? 'Reply'
                      : 'Edit and re-send'
                  }
                  onClick={() => setShowReplyDropdown(!showReplyDropdown)}
                />
              )}
            </WrapperReplyButton>
            <WrapperReplyContent hasHeight={showReplyDropdown}>
              <ReplyContent>
                <WrapperEmail>
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .txt, .xlsx, .png, .jpg"
                    multiple="multiple"
                    onChange={handleFileUpload}
                    ref={pdfLoaderRef}
                    style={{ display: 'none' }}
                  />
                  {/* <Content> */}
                  <WrapperAttachments>
                    <div className="title">
                      <AttachmentIcon />
                      <h4>Attachments</h4>
                    </div>
                    <div className="list">
                      {attachmentsList?.map((attachment, idx) => (
                        <Attachments key={idx} title={attachment}>
                          <button
                            type="button"
                            className="trash"
                            onClick={() =>
                              setAttachmentsList((prev) =>
                                prev.filter(
                                  (item) => item.name !== attachment.name
                                )
                              )
                            }
                          >
                            <img
                              src={trash}
                              alt="Delete Attachment"
                              className="trashImg"
                            />
                          </button>
                          {/* <img src={pdfImg} alt="Attachment" /> */}
                          <div
                            className="doc"
                            onClick={() => handleOpenLocalFile(attachment)}

                            // onClick={() => handleOpenPDFFile(attachment)}
                          >
                            {getArchiveIcon(attachment.name)}
                          </div>
                          <p onClick={() => handleOpenLocalFile(attachment)}>
                            {attachment.name}
                          </p>
                        </Attachments>
                      ))}
                      <Attachments onClick={handleAddFile} dashed>
                        <img src={addCircle} alt="" />
                        <p>Add Files</p>
                      </Attachments>
                    </div>
                  </WrapperAttachments>
                  {/* </Content> */}

                  <TextEditor
                    data={htmlContent}
                    setHtmlContent={setHtmlContent}
                    width="100%"
                  />
                </WrapperEmail>
                <WrapperReplyButtons>
                  <Button
                    text="Send"
                    type="success"
                    icon="fas fa-paper-plane"
                    onClick={handleSendReply}
                  />
                  <Button icon="trash" onClick={handleCloseReplyDropdown} />
                </WrapperReplyButtons>
              </ReplyContent>
              <hr />
            </WrapperReplyContent>
          </WrapperReply>
          {/* )} */}
          {attachmentList?.length ? (
            <WrapperAttachments>
              <div className="title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.464"
                  height="12.885"
                  viewBox="0 0 12.464 12.885"
                >
                  <path
                    id="attachment-01-svgrepo-com"
                    d="M14.205,7.634,8.674,13.166A3.221,3.221,0,0,1,4.118,8.61L9.65,3.079a2.147,2.147,0,1,1,3.037,3.037L7.372,11.43A1.074,1.074,0,0,1,5.853,9.912l4.664-4.664"
                    transform="translate(-2.449 -1.95)"
                    fill="none"
                    stroke="#326a95"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </svg>
                <h4>Attachments</h4>
              </div>
              <div className="list">
                {attachmentList?.map((attachment, idx) => (
                  <Attachments key={idx} title={attachment}>
                    <div
                      className="doc"
                      onClick={() => handleOpenPDFFile(attachment)}
                    >
                      {getArchiveIcon(attachment)}
                    </div>
                    <p onClick={() => handleOpenPDFFile(attachment)}>
                      {attachment}
                    </p>
                    {emailData?.From.toLowerCase() !== config?.TREmail && (
                      <button
                        onClick={() => {
                          handleDownload({
                            fileUrl: `https://teamrelaystore.blob.core.windows.net/exchange/${attachment}`,
                            fileName: attachment
                              ?.toLowerCase()
                              ?.replace(
                                currentData?.HospitalGroupId?.toLowerCase(),
                                ''
                              )
                              ?.replace(currentData?.UserId?.toLowerCase(), '')
                              ?.replace(
                                emailData?.ConversationId?.toLowerCase(),
                                ''
                              ),
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7.892"
                          height="8.769"
                          viewBox="0 0 7.892 8.769"
                        >
                          <g id="Download" transform="translate(-13.424)">
                            <path
                              id="way"
                              d="M20.594,7.308H14.146a.731.731,0,0,0,0,1.462h6.448a.731.731,0,0,0,0-1.462Zm-3.758-.8a.734.734,0,0,0,.473.213c.02,0,.04.006.061.006s.04,0,.06-.006A.734.734,0,0,0,17.9,6.5l2.311-2.318a.742.742,0,0,0-1.048-1.05L18.1,4.206V.731a.731.731,0,1,0-1.462,0V4.206l-1.067-1.07a.742.742,0,0,0-1.048,1.05Z"
                              transform="translate(0)"
                              fillRule="evenodd"
                            />
                          </g>
                        </svg>

                        <span>Download</span>
                      </button>
                    )}
                  </Attachments>
                ))}
              </div>
            </WrapperAttachments>
          ) : (
            ''
          )}
          {isLoading ? (
            <LoadIndicator />
          ) : emailData?.emailDetails ? (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: emailData.emailDetails,
              }}
            />
          ) : (
            <span>Unable to load email</span>
          )}
        </Content>
      )}
    </WrapperEmail>
  );
}

export default EmailBox;

EmailBox.propTypes = {
  emailData: object.isRequired,
  currentData: object.isRequired,
  isSelected: bool.isRequired,
  onHeaderClick: func.isRequired,
  handleOpenFile: func.isRequired,
  fetchEmailList: func.isRequired,
  refetch: func.isRequired,
};
