/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';

export default makeStyles(() => ({
  EmptyItem: {
    display: 'none',
  },
  DatesItem: {
    borderLeft: `1px solid #dddddd`,
  },
}));

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RadioOption = withStyles({
  root: {
    color: '#2d6da3',
    '&$checked': {
      color: '#2d6da3',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const StyledLabel = styled.label`
  justify-content: center;
`;

export const GroupSpan = styled.div`
  display: flex;
  justify-content: space-between;

  span + span {
    font-style: oblique;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
