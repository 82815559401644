import React, { useRef } from 'react';
import { Form, Popup } from 'devextreme-react';
import {
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { bool, func, object, string } from 'prop-types';

function EditStaffTeam({
  visible,
  handleClose,
  handleSave,
  currentTeam,
  area,
}) {
  const formRef = useRef(null);

  const buttonsOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        saveTeam();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        handleClose();
      },
    },
  ];

  async function saveTeam() {
    if (formRef.current.instance.validate().isValid) {
      handleSave(currentTeam);
    } else {
      notify(`Please check the highlighted fields.`, 'error', 2000);
    }
  }

  return (
    <Popup
      visible={visible}
      onHiding={() => handleClose()}
      width={600}
      height={300}
      showCloseButton={false}
      showTitle={false}
    >
      <Form id="formEditTeam" formData={currentTeam} ref={formRef}>
        <GroupItem
          caption={
            currentTeam && currentTeam.TeamName !== ''
              ? currentTeam.TeamName
              : 'New Team'
          }
          colCount={2}
        >
          <SimpleItem dataField="TeamName" editorType="dxTextBox">
            <Label text="Name" />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField="TeamOrder"
            editorType="dxNumberBox"
            editorOptions={{
              min: 1,
              showSpinButtons: true,
            }}
          >
            <Label text="Order" />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            editorType="dxTextBox"
            editorOptions={{
              readOnly: true,
              value: area,
            }}
          >
            <Label text="Area" />
          </SimpleItem>
          <SimpleItem dataField="Color" editorType="dxColorBox">
            <Label text="Color" />
          </SimpleItem>
        </GroupItem>
      </Form>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

EditStaffTeam.propTypes = {
  visible: bool,
  handleClose: func.isRequired,
  handleSave: func.isRequired,
  currentTeam: object.isRequired,
  area: string,
};

EditStaffTeam.defaultProps = {
  visible: false,
  area: '',
};

export default EditStaffTeam;
