import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box, { Item } from 'devextreme-react/box';
import API from '../../../services/API';
import Message from '../../message/Message';

const RoleDeletePopup = (props) => {
  const { visible, afterDelete, role } = props;
  const [error, setError] = useState('');

  const handleDelete = async () => {
    const result = await API.sendRequest(`Role/${role.RoleId}`, 'DELETE');

    if (result.status !== 200) {
      setError(result.message);
    } else {
      afterDelete(true);
    }
  };

  const handleMessageClose = () => {
    setError('');
  };

  return (
    <>
      <Message
        message={error}
        variant="error"
        open={!!error}
        handleClose={handleMessageClose}
      />
      <Dialog
        open={visible}
        onClose={() => afterDelete()}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {role && role.RoleDesc}
        </DialogTitle>
        <DialogContent>
          <Box direction={'row'} width={'100%'}>
            <Item ratio={1}>
              Deleting this Role will delete all Users and Permissions related
              to this Role.
              <br />
              Are you sure?
            </Item>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
          <Button onClick={() => afterDelete(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RoleDeletePopup;
