import { format, parseISO } from 'date-fns';
import { Accordion, Form, LoadPanel, Popup } from 'devextreme-react';
import { SimpleItem } from 'devextreme-react/form';
import TabPanel, { Item as Tab } from 'devextreme-react/tab-panel';
import { number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import API from '../../../services/API';
import { AccordionItemBody, AccordionItemHeader, Container } from './styles';

function accordionTitleRender(data) {
  return (
    <AccordionItemHeader>
      <span>
        {`${format(parseISO(data?.NoteDateTime), 'MM/dd/yyyy | HH:mm')}`}
      </span>
      <span>{`${data?.NoteType} by ${data?.NoteUser}`}</span>
    </AccordionItemHeader>
  );
}

function accordionItemRender(data) {
  return (
    <AccordionItemBody>
      <span>{data?.Note}</span>
    </AccordionItemBody>
  );
}

function CasePopup({ referralId, ...props }) {
  async function referralDetailFetch({ referralId }) {
    setLoading(true);
    const { data: activeCasesData } = await API.sendRequest(
      `ActiveCases/ListDetails/${referralId}`,
      'get'
    );
    setReferralDetail(
      Object.entries(activeCasesData)?.reduce(
        (obj, curr) => ({
          ...obj,
          data: { ...obj.data, [curr[0]]: curr[1]?.trim() },
          configuration: [
            ...obj.configuration,
            {
              dataField: curr[0],
              colSpan:
                curr[0] === 'Circumstances'
                  ? 3
                  : curr[0] === 'Reflexes' ||
                    curr[0] === 'Notes' ||
                    curr[0] === 'Medications' ||
                    curr[0] === 'PMHx'
                  ? 2
                  : 1,
              editorType:
                curr[0] === 'Circumstances' ||
                curr[0] === 'Notes' ||
                curr[0] === 'Medications' ||
                curr[0] === 'PMHx' ||
                curr[0] === 'Reflexes'
                  ? 'dxTextArea'
                  : 'dxTextBox',
              editorOptions: {
                readOnly: true,
                height:
                  curr[0] === 'Circumstances' ||
                  curr[0] === 'Notes' ||
                  curr[0] === 'Medications' ||
                  curr[0] === 'PMHx' ||
                  curr[0] === 'Reflexes'
                    ? 90
                    : undefined,
              },
            },
          ],
        }),
        { data: {}, configuration: [] }
      )
    );

    const { data: progressNotesData } = await API.sendRequest(
      `ActiveCases/ListProgressNotes/${referralId}`,
      'get'
    );
    setProgressNotes(progressNotesData);

    setLoading(false);
  }

  const [referralDetail, setReferralDetail] = useState([]);
  const [progressNotes, setProgressNotes] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    referralDetailFetch({ referralId });

    return () => {
      setReferralDetail([]);
    };
  }, [referralId]);

  if (loading) {
    return <LoadPanel visible />;
  }

  return (
    <Popup
      title={`${referralDetail.data.Name} - #${referralDetail.data.OPOID}`}
      width="98%"
      height="98%"
      {...props}
    >
      <Container>
        <TabPanel height="92%" width="100%">
          <Tab title="Data">
            <Container>
              <Form
                formData={referralDetail.data}
                colCount={4}
                labelLocation="top"
                readOnly
                scrollingEnabled
                width="100%"
              >
                {referralDetail?.configuration?.map((conf) => (
                  <SimpleItem key={conf?.dataField} {...conf} />
                ))}
              </Form>
            </Container>
          </Tab>
          <Tab title="Progress Notes">
            <Container>
              <Accordion
                dataSource={progressNotes?.reverse()}
                width="100%"
                itemTitleRender={accordionTitleRender}
                itemRender={accordionItemRender}
                collapsible
              />
            </Container>
          </Tab>
        </TabPanel>
      </Container>
    </Popup>
  );
}

CasePopup.propTypes = {
  referralId: number.isRequired,
};

export default CasePopup;
