import React, { useState } from 'react';
import { Tabs } from 'devextreme-react/tabs';

import { TabContainer, ContentContainer } from './styles';

// import Range from './Range';
import Value from './Value';
import ValueCategory from './ValueCategory';

function Performance() {
  const [selectedIndexTab, setSelectedIndexTab] = useState(0);

  const performanceTab = [
    // {
    //   id: 0,
    //   text: 'Range Colors',
    //   icon: 'fas fa-paint-roller',
    //   content: <Range />,
    // },
    {
      id: 1,
      text: 'Values Categories',
      icon: 'fas fa-tags',
      content: <ValueCategory />,
    },
    { id: 2, text: 'Values', icon: 'fas fa-list-ol', content: <Value /> },
  ];

  const handleItemClick = ({ itemIndex }) => {
    setSelectedIndexTab(itemIndex);
  };
  return (
    <>
      <TabContainer>
        <Tabs
          dataSource={performanceTab}
          showNavButtons={true}
          selectedIndex={selectedIndexTab}
          onItemClick={handleItemClick}
          width={'80%'}
        />
      </TabContainer>
      <ContentContainer>
        {performanceTab[selectedIndexTab].content}
      </ContentContainer>
    </>
  );
}

export default Performance;
