import { useQuery } from 'react-query';
import API from '../API';

async function getEMRSetup() {
  const { data } = await API.sendRequest('EMRSetup', 'GET');

  return data;
}

export default function useGetEMRSetup() {
  return useQuery('emrSetup', getEMRSetup, {
    refetchOnWindowFocus: false,
  });
}
