import { useQuery } from 'react-query';
import API from '../API';

async function allADUserListFromSQL() {
  // ListAllFromSQL
  const { data } = await API.sendRequest('ADUser/ListAllFromSQL', 'GET');

  return data;
}

export default function useGetADUserListFromSQL() {
  return useQuery('ADUserListFromSQL', allADUserListFromSQL, {
    refetchOnWindowFocus: false,
  });
}
