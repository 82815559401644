import { useQuery } from 'react-query';
import API from '../API';

async function getEMRContactList() {
  const { data } = await API.sendRequest('Contact/ListEMRContacts', 'GET');

  return data;
}

export default function useEmrContactList() {
  return useQuery('emrContactsList', getEMRContactList, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
}
