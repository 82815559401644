import React from 'react';
import styled from 'styled-components';
import { withStyles, makeStyles, fade } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const Header = styled.div`
  height: 60px;
  width: 100%;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
`;

export const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
`;

export const Text = styled.span`
  margin-left: 15px;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  text-transform: ${(props) => props.upper && 'uppercase'};
`;

export const BoxSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
// height: 40px;

export const BoxCenter = styled.div`
  height: 230px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxAssignment = styled.div`
  height: 230px;
  width: 100%;
  overflow: hidden;
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const ButtonCustom = styled(Button)`
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  border: ${(props) => (props.bg === '#FFF' ? '1px solid #707070' : 'none')};
  width: 70px;
  margin: 15px;

  &:hover {
    background-color: ${(props) =>
      props.bg === '#FFF' ? 'rgba(0, 0, 0, 0.08)' : '#c8c8c8'};
  }
`;

export const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    border: '1px solid #707070',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    margin: 15,
    zIndex: 1350,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    zIndex: 1351,
    // // height: 10,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // // transition: theme.transitions.create('width'),
    width: '100%',
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },
  buttonClose: {
    marginRight: 5,
  },
  iconClose: {
    color: '#FFF',
  },
  textList: {
    fontSize: 10,
    fontWeight: 500,
  },
}));

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: -50,
    // width: 300,
    // cursor: 'move',

    '& .MuiList-padding': {
      padding: 0,
    },
  },
})((props) => (
  <Menu
    elevation={8}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: '#EBEBEB',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);
