function getOs(userAgent) {
  return userAgent?.toLowerCase().indexOf('windows') > 0
    ? { OSId: 3, OSName: 'Windows' }
    : userAgent?.toLowerCase().indexOf('mac') > 0
    ? { OSId: 4, OSName: 'Mac' }
    : userAgent?.indexOf('iPhone') > 0 ||
      userAgent?.toLowerCase().indexOf('ios') > 0
    ? { OSId: 1, OSName: 'IOs' }
    : { OSId: 2, OSName: 'Android' };
}

function getBrowserName(userAgent) {
  return userAgent?.toLowerCase().indexOf('chrome') > 0
    ? 'Chrome'
    : userAgent?.toLowerCase().indexOf('safari') > 0
    ? 'Safari'
    : userAgent?.toLowerCase().indexOf('edg') > 0
    ? 'Edge'
    : userAgent?.toLowerCase().indexOf('opera') > 0
    ? 'Opera'
    : 'Firefox';
}

function getBrowserData() {
  const navUserAgent = navigator.userAgent;

  return {
    OS: getOs(navUserAgent),
    Browser: getBrowserName(navUserAgent),
  };
}

export default getBrowserData;
