import { Popup, ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { bool, func, object } from 'prop-types';
import React, { useState } from 'react';
import API from '../../../../../services/API';
import { Container, LabelContainer, Text } from './styles';

function PopupUserTrainingDelete({
  visible,
  onClose,
  refetch,
  currentUserTraining,
}) {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await API.sendRequest(
        `UserTraining/Delete/${currentUserTraining.UserTrainingId}`,
        'delete'
      );

      if (res.status !== 200) {
        throw new Error(res.message);
      }

      refetch();
      setDeleting(false);
      onClose();
      notify('User Training Deleted.', 'success', 2000);
    } catch (e) {
      setDeleting(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        handleDelete();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        onClose();
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      onHiding={onclose}
      width={500}
      height={250}
      showCloseButton={false}
      title="Training - Delete"
      disabled={deleting}
    >
      <Container>
        <LabelContainer>
          <Text bold>
            {currentUserTraining?.DisplayName
              ? `The [${currentUserTraining?.DisplayName}] will be deleted to this training [${currentUserTraining?.TrainingDesc}]. This action cannot be undone.`
              : `The User Training will be deleted. This action cannot be undone.`}
          </Text>
          <Text>Are you sure?</Text>
        </LabelContainer>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

export default PopupUserTrainingDelete;

PopupUserTrainingDelete.propTypes = {
  visible: bool,
  onClose: func.isRequired,
  currentUserTraining: object,
  refetch: func.isRequired,
};

PopupUserTrainingDelete.defaultProps = {
  visible: false,
  currentUserTraining: {},
};
