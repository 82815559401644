import React, { useEffect, useRef, useState } from 'react';

import {
  Column,
  DataGrid,
  Editing,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid';

import { Button } from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import API from '../../services/API';

import ProgressStatus from '../../components/ProgressStatus';

import ContactExportPopup from '../../components/popups/ContactExportPopup';
import HistoryPopup from '../../components/popups/HistoryPopup';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import usePermissions from '../../hooks/usePermissions';
import getChangeHistory from '../../utils/getChangeHistory';
import DeleteExternalContactPopup from './components/DeleteExternalContactPopup';
import EditExternalContactPopup from './components/EditExternalContactPopup';
import useStyles from './styles';

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const ExternalContacts = () => {
  const classes = useStyles();

  const [areas, setAreas] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [phoneTypes, setPhoneTypes] = useState(null);
  const [categories, setCategories] = useState(null);

  const [currentContact, setCurrentContact] = useState(null);
  const [contactChangeHistory, setContactChangeHistory] = useState(null);
  const [contactDeleteVisible, setContactDeleteVisible] = useState(false);
  const [contactEditVisible, setContactEditVisible] = useState(false);
  const [exportPopupVisible, setExportPopupVisible] = useState(false);
  const [historyPopupVisible, setHistoryPopupVisible] = useState(false);

  const dataGridRef = useRef();
  console.log(contacts);

  useEffect(() => {
    let isSubscribed = true;
    loadData('Contact/ListByActive/false').then((contactsList) => {
      if (isSubscribed) {
        setContacts(contactsList);
      }
    });

    loadData('Area').then((areasList) => {
      if (isSubscribed) {
        setAreas(areasList);
      }
    });

    loadData('PhoneType').then((phoneTypesList) => {
      if (isSubscribed) {
        setPhoneTypes(phoneTypesList);
      }
    });

    loadData('Category').then((categoriesList) => {
      if (isSubscribed) {
        setCategories(categoriesList);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  const loadData = async (uri) => {
    const res = await API.sendRequest(uri, 'GET');

    return res.data;
  };

  const handleEditContact = async (data) => {
    loadData(`Contact/ListComplete/${data.ContactId}`).then(
      (contactComplete) => {
        setCurrentContact(contactComplete);
        setContactEditVisible(true);
      }
    );
  };

  const handleShowChangeHistory = async (data) => {
    await getChangeHistory('Contact', data.ContactId).then((ret) => {
      if (ret) {
        setContactChangeHistory(ret);
        setHistoryPopupVisible(true);
      }
    });
  };

  const deleteClick = (data) => {
    setCurrentContact(data);
    setContactDeleteVisible(true);
  };

  const addNewContact = () => {
    setCurrentContact({
      Contact: {
        Prefix: '',
        DisplayName: '',
        FirstName: '',
        LastName: '',
        Initials: '',
        Company: '',
        AreaId: 0,
        DepartmentId: 0,
        Department: '',
        JobTitle: '',
        Description: '',
        Active: true,
        OU: '',
        Today: '',
        Username: '',
        Email: '',
        BadgeColor: '',
        PhoneNumbers: '',
        Categories: '',
      },
      Phones: [],
      Category: [],
    });

    setContactEditVisible(true);
  };

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  const onToolbarPreparing = (e) => {
    if (ACL.AddNew) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: addNewContact,
        },
      });
    }
    if (ACL.Export) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: 'breadCrumb',
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            height: 36,
            icon: 'file',
            text: 'Export Contacts',
            hint: 'Export contacts to PDF or Excel',
            onClick: () => {
              setExportPopupVisible(true);
            },
          },
        }
      );
    }
  };

  const getActionsButtons = ({ data }) => (
    <>
      {(ACL?.Update || ACL?.Admin) && (
        <>
          {ACL?.Admin && (
            <Button
              hint="Show Change History"
              icon="fas fa-history"
              onClick={() => handleShowChangeHistory(data)}
              stylingMode="text"
              type="default"
            />
          )}
          {ACL?.Update && (
            <Button
              hint="Edit Contact"
              icon="fas fa-address-card"
              onClick={() => handleEditContact(data)}
              stylingMode="text"
              type="default"
            />
          )}
        </>
      )}
      {ACL?.Delete && (
        <Button
          hint="Delete Contact"
          icon="trash"
          onClick={() => deleteClick(data)}
          stylingMode="text"
          type="default"
        />
      )}
    </>
  );

  const updateContactGrid = async () => {
    loadData('Contact/List').then((contactsList) => {
      setContacts(contactsList);
    });
  };

  const updateContacts = (contactId) => {
    setContacts((state) =>
      state.filter((contact) => contact.ContactId !== contactId)
    );
  };

  return (
    <div className={classes.divGridContainer}>
      {historyPopupVisible && (
        <HistoryPopup
          historyType="json"
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={contactChangeHistory}
        />
      )}
      {currentContact && contactEditVisible && (
        <EditExternalContactPopup
          areas={areas}
          phoneTypes={phoneTypes}
          categories={categories}
          isVisible={contactEditVisible}
          setIsVisible={setContactEditVisible}
          updateContactGrid={updateContactGrid}
          currentContact={currentContact}
        />
      )}
      {currentContact && contactDeleteVisible && (
        <DeleteExternalContactPopup
          visible={contactDeleteVisible}
          contact={currentContact}
          onVisibleChange={setContactDeleteVisible}
          updateContacts={updateContacts}
        />
      )}
      <ContactExportPopup
        isVisible={exportPopupVisible}
        setIsVisible={setExportPopupVisible}
      />
      {contacts && contacts.length > 0 ? (
        <DataGrid
          id="gridContainer"
          dataSource={contacts}
          keyExpr="ContactId"
          allowColumnReordering
          showBorders
          rowAlternationEnabled
          showRowLines
          focusedRowEnabled
          onRowUpdating={onRowUpdating}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: true }}
          ref={dataGridRef}
          onToolbarPreparing={onToolbarPreparing}
        >
          <SearchPanel visible width={240} placeholder="Search..." />

          <Paging defaultPageSize={15} />
          <Pager
            showPageSizeSelector
            allowedPageSizes={[15, 30, 50, 100, 200, 400, 600]}
            showInfo
          />
          <Editing useIcons mode="popup" allowUpdating />

          <Column dataField="Prefix" width={100} />
          <Column dataField="FirstName" />
          <Column dataField="LastName" />
          <Column dataField="AreaId" caption="Area">
            <Lookup
              dataSource={areas}
              displayExpr="AreaDesc"
              valueExpr="AreaId"
            />
          </Column>
          <Column dataField="Company" />
          <Column dataField="Email" />
          <Column
            dataField="PhoneNumbers"
            caption="Phone Numbers"
            minWidth={240}
            cellRender={(data) => {
              if (data.value) {
                const phoneNumbersArray = String(data.value).split(',');
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    {phoneNumbersArray.map((phoneNumber, index) => (
                      <div key={index}>{phoneNumber.trim()}</div>
                    ))}
                  </div>
                );
              }
            }}
            mask="(000) 000-0000"
          />
          <Column dataField="CategoriesDesc" caption="Categories" />
          <Column dataField="Active" width={100} />
          <Column
            caption="Actions"
            type="buttons"
            cellRender={getActionsButtons}
            width={135}
          />
          <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
        </DataGrid>
      ) : (
        <ProgressStatus />
      )}
    </div>
  );
};

export default ExternalContacts;
