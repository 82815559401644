import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactSwipeButton from 'react-swipe-button';

import Message from '../../components/message/Message';
import isAuth from '../../helpers/isAuth';
import useDevice from '../../hooks/useDevice';
import API from '../../services/API';

import Button from 'devextreme-react/button';
import Cookies from 'js-cookie';
import Validator from 'validatorjs';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { AzureLogo } from '../../assets/login/AzureLogo';
import { TRIconSquareLogo } from '../../assets/login/TRIconSquareLogo';
import getBrowserData from '../../utils/getBrowserData';
import {
  AppName,
  AzureName,
  AzureSpanWrapper,
  AzureWrapper,
  ButtonContainer,
  Container,
  FormContainer,
  FormFields,
  FormFooter,
  FormHeader,
  Logo,
  LogoWrapper,
  SideImage,
  Wrapper,
} from './styles';

const ImpersonateLogin = () => {
  const history = useHistory();
  const device = useDevice();
  const [unlockButton, setUnlockButton] = useState(false);
  const [values, setValues] = useState({
    username: '',
    password: '',
    showPassword: false,
  });
  const [error, setError] = useState('');
  const [errorFields, setErrorFields] = useState({});

  useEffect(() => {
    if (isAuth()) {
      history.push('/contacts');
    }
    // eslint-disable-next-line
  }, [device]);

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function loginWithoutOneSignalId(credentials) {
    const response = await API.sendRequest('User/Impersonate', 'post', {
      Username: credentials.username,
      Password: credentials.password,
    });

    if (response.status === 200) {
      Cookies.set('token', response.data.User.Token);
      Cookies.set('refreshtoken', response.data.User.RefreshToken);
      history.push(`/${response.data?.User?.InitialRoute}`);
    }
    return response;
  }

  async function loginWithOneSignalId(credentials, oneSignalId) {
    const browserData = getBrowserData();
    const response = await API.sendRequest(
      'user/auth_device',
      'post',
      {
        Username: credentials.username,
        Password: credentials.password,
        OneSignalId: oneSignalId,
        OSId: browserData?.OS?.OSId,
        Model: browserData?.Browser,
        Manufacturer: browserData?.OS?.OSName,
      },
      false
    );
    if (response.status === 200) {
      Cookies.set('token', response.data.User.Token);
      Cookies.set('refreshtoken', response.data.User.RefreshToken);
      history.push(`/${response.data.User?.InitialRoute}`);
    }

    return response;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const rules = {
      username: 'required',
      password: 'required',
    };

    const validation = new Validator(values, rules);

    if (validation.fails()) {
      setErrorFields(validation.errors.errors);
      return;
    }
    setErrorFields({});
    const oneSignalId = Cookies.get('oneSignalId');
    let response;

    if (oneSignalId && oneSignalId !== 'null') {
      response = await loginWithOneSignalId(values, oneSignalId);
    } else {
      response = await loginWithoutOneSignalId(values);
    }

    if (response.status === 500) {
      setError(response.message);
    }
  };

  const handleMessageClose = () => {
    setError('');
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <Container>
      <Message
        message={error}
        variant="error"
        open={!!error}
        handleClose={handleMessageClose}
      />
      <Wrapper>
        <SideImage>
          <Logo src="assets/NJShareLogo.png" />
        </SideImage>
        <FormContainer>
          <FormHeader>
            <LogoWrapper>
              <TRIconSquareLogo />
              <AppName>Team Relay</AppName>
            </LogoWrapper>
          </FormHeader>
          <form noValidate autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
            <FormFields>
              <FormControl fullWidth>
                <InputLabel error={!!errorFields.username}>Username</InputLabel>
                <Input
                  label="Username"
                  value={values.username}
                  error={!!errorFields.username}
                  onChange={handleChange('username')}
                  autoFocus
                />
                <FormHelperText error={!!errorFields.username}>
                  {errorFields.username}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel error={!!errorFields.password}>Password</InputLabel>
                <Input
                  label="Password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  error={!!errorFields.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error={!!errorFields.password}>
                  {errorFields.password}
                </FormHelperText>
              </FormControl>
            </FormFields>
            <ButtonContainer>
              {unlockButton ? (
                <Button
                  id="loginButton"
                  width={120}
                  text="Login"
                  type="default"
                  stylingMode="outlined"
                  useSubmitBehavior
                />
              ) : (
                <ReactSwipeButton
                  text="SWIPE TO UNLOCK"
                  color="#326A95"
                  onSuccess={() => setUnlockButton(true)}
                />
              )}
            </ButtonContainer>
          </form>
          <FormFooter>
            <AzureWrapper>
              <AzureLogo />
              <AzureSpanWrapper>
                <span className="powered-by">Powered by</span>
                <AzureName className="azure-name">Microsoft Azure</AzureName>
              </AzureSpanWrapper>
            </AzureWrapper>
          </FormFooter>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default ImpersonateLogin;
