import Cookies from 'js-cookie';
import isAuth from './isAuth';

function GetUserFromCookie(token) {
  if (!token) {
    return 'Not Logged';
  }

  const tokenSplit = token.split(' ');

  let tokens = [];
  if (tokenSplit.length > 1) {
    tokens = tokenSplit[1].split('.');
  } else {
    tokens = tokenSplit[0].split('.');
  }

  const tokenData = JSON.parse(
    Buffer.from(tokens[1], 'base64').toString('ascii')
  );

  return tokenData.unique_name;
}

export default function getLoggedUser() {
  if (!isAuth()) {
    return 'Not logged';
  }

  return GetUserFromCookie(Cookies.get('token'));
}
