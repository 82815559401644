import { LoadPanel, TagBox } from 'devextreme-react';
import DataGrid, { Column, ColumnChooser } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { bool, func } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import API from '../../../services/API';
import { FilterGroup, GridContainer } from './styles';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF as JsPDF } from 'jspdf';
import 'jspdf-autotable';

import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

function ContactExportPopup({ isVisible, setIsVisible }) {
  const [contactsExported, setContactsExported] = useState(null);
  const [contactsToShow, setContactsToShow] = useState(null);
  const [areas, setAreas] = useState(null);
  const [deptos, setDeptos] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedDeptos, setSelectedDeptos] = useState([]);

  const [contactTypeFilter, setContactTypeFilter] = useState(1);
  const [loading, setLoading] = useState(true);

  const contactExportGridRef = useRef(null);

  useEffect(() => {
    let isSubscribed = true;

    API.sendRequest(`Department`, 'get').then(({ status, data }) => {
      if (status === 200 && isSubscribed) {
        setDeptos(data);
      }
    });

    API.sendRequest(`Area`, 'get').then(({ status, data }) => {
      if (status === 200 && isSubscribed) {
        setAreas(data);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, [contactTypeFilter]);

  useEffect(() => {
    let contacts = contactsExported;

    if (selectedAreas?.length) {
      contacts = contacts.filter((contact) =>
        selectedAreas.includes(contact?.Area)
      );
    }
    if (selectedDeptos?.length) {
      contacts = contacts.filter((contact) =>
        selectedDeptos.includes(contact?.Department)
      );
    }

    setContactsToShow(contacts);
    // eslint-disable-next-line
  }, [selectedAreas?.length, selectedDeptos?.length, contactsExported]);

  useEffect(() => {
    setLoading(true);
    let isSubscribed = true;

    loadContactExports().then((contacts) => {
      if (isSubscribed) {
        setContactsExported(contacts);
        setLoading(false);
      }
    });

    return () => {
      isSubscribed = false;
      setLoading(false);
    };
    // eslint-disable-next-line
  }, [contactTypeFilter]);

  const radioOptions = [
    { value: '0', text: 'All Contacts', term: 'ALL' },
    { value: '1', text: `Staff Only`, term: 'STAFF' },
    { value: '2', text: `Travelers`, term: 'TRAV' },
    { value: '3', text: `Managers`, term: 'MNG' },
    { value: '4', text: `Interim Lab Directors`, term: 'DIR' },
  ];

  async function loadContactExports() {
    const res = await API.sendRequest(
      `Contact/Export/${radioOptions.find((item) => Number(item.value) === contactTypeFilter)
        ?.term
      }`,
      'get'
    );

    return res.data;
  }

  function handleExportGridToExcel(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      contactTypeFilter ? 'Staff Contacts' : 'All Contacts'
    );
    const dataGrid = contactExportGridRef.current.instance;

    exportDataGridToExcel({
      component: dataGrid,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'PhoneNumbers' && gridCell.value) {
            const content = gridCell.value
              .replaceAll('Business ', '')
              .replaceAll(',', ' \n');

            excelCell._value.model.value = content;
            excelCell._column.width = 40;
            excelCell.alignment = { wrapText: true };
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${contactTypeFilter ? 'StaffContacts' : 'AllContacts'}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  function handleExportGridToPdf(e) {
    const dataGrid = contactExportGridRef.current.instance;
    const doc = new JsPDF(
      dataGrid.getVisibleColumns().length > 8 ? 'landscape' : 'portrait'
    );

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
      autoTableOptions: {
        margin: { left: 1, right: 1, top: 5, bottom: 5 },
        rowPageBreak: 'avoid',
      },
      customizeCell: ({ gridCell, pdfCell }) => {
        pdfCell.styles = {
          fontSize: 8,
          cellPadding: 1.5,
          overflow: 'ellipsize',
        };

        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'PhoneNumbers' && gridCell.value) {
            const content = gridCell.value
              .replaceAll('Business ', '')
              .replaceAll(',', '\n');
            pdfCell.content = content;
          }
        }

        if (gridCell.column.dataField === 'PhoneNumbers') {
          pdfCell.styles = {
            fontSize: 8,
            cellPadding: 1.5,
            cellWidth: 40,
            cellMinWidth: 40,
          };
        }
        if (gridCell.column.dataField === 'Email') {
          pdfCell.styles = {
            fontSize: 7,
            cellPadding: 1.5,
            cellMinWidth: 40,
            overflow: 'ellipsize',
          };
        }
        if (
          gridCell.column.dataField === 'FirstName' ||
          gridCell.column.dataField === 'LastName'
        ) {
          pdfCell.styles = {
            ...pdfCell.styles,
            cellMinWidth: 21,
          };
        }
        if (
          gridCell.column.dataField === 'Prefix' ||
          gridCell.column.dataField === 'DisplayName' ||
          gridCell.column.dataField === 'Initials' ||
          gridCell.column.dataField === 'Groups'
        ) {
          pdfCell.styles = {
            ...pdfCell.styles,
            fontSize: 6,
            cellWidth: 15,
            cellMinWidth: 0,
          };
        }
        if (
          gridCell.column.dataField === 'Username' ||
          gridCell.column.dataField === 'Company' ||
          gridCell.column.dataField === 'Department' ||
          gridCell.column.dataField === 'JobTitle' ||
          gridCell.column.dataField === 'Description'
        ) {
          pdfCell.styles = {
            ...pdfCell.styles,
            fontSize: 7,
            cellWidth: 21,
            cellMinWidth: 0,
          };
        }
        if (gridCell.rowType === 'header') {
          pdfCell.styles = {
            fontSize: 9,
            fontStyle: 'bold',
            overflow: 'ellipsize',
          };
        }
      },
    }).then(() => {
      doc.save(`${contactTypeFilter ? 'StaffContacts' : 'AllContacts'}.pdf`);
    });
    e.cancel = true;
  }

  const handleRadioChanged = ({ value }) => {
    setContactTypeFilter(Number(value.value));
  };

  const handleSelectAreas = ({ value }) => {
    setSelectedAreas(value);
  };

  const handleSelectDeptos = ({ value }) => {
    setSelectedDeptos(value);
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxRadioGroup',
        options: {
          dataSource: radioOptions,
          defaultValue: radioOptions[contactTypeFilter],
          onValueChanged: handleRadioChanged,
          value: radioOptions[contactTypeFilter],
          layout: 'horizontal',
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'xlsxfile',
          text: 'Export to Excel',
          onClick: handleExportGridToExcel,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'exportpdf',
          text: 'Export to PDF',
          onClick: handleExportGridToPdf,
        },
      }
    );
  };

  return (
    <Popup
      visible={isVisible}
      title="Contacts Export"
      onHiding={() => setIsVisible(false)}
      closeOnOutsideClick
      width="90%"
    >
      <FilterGroup>
        <span>Filter by Areas:</span>
        <TagBox
          placeholder="Select Areas..."
          dataSource={areas}
          valueExpr="AreaDesc"
          displayExpr="AreaDesc"
          searchEnabled={true}
          value={selectedAreas}
          onValueChanged={handleSelectAreas}
          width={400}
          multiline={false}
          maxDisplayedTags={7}
        />
        <span>Filter by Departments:</span>
        <TagBox
          placeholder="Select Departments..."
          dataSource={deptos}
          valueExpr="DepartmentDesc"
          displayExpr="DepartmentDesc"
          searchEnabled={true}
          value={selectedDeptos}
          onValueChanged={handleSelectDeptos}
          width={400}
          multiline={false}
          maxDisplayedTags={7}
        />
      </FilterGroup>
      <GridContainer>
        <DataGrid
          ref={contactExportGridRef}
          showRowLines
          dataSource={contactsToShow}
          allowColumnReordering
          allowColumnResizing
          showBorders
          rowAlternationEnabled
          onToolbarPreparing={onToolbarPreparing}
          width="100%"
          height="90%"
          loadPanel={{ enabled: false }}
        // paging={{
        //   enabled: false,
        // }}
        >
          <ColumnChooser enabled mode="select" />
          <Column dataField='Prefix' />
          <Column dataField='DisplayName' />
          <Column dataField='FirstName' />
          <Column dataField='LastName' />
          <Column dataField='Initials' />
          <Column dataField='Company' />
          <Column dataField='Department' />
          <Column dataField='Area' />
          <Column dataField='JobTitle' />
          <Column dataField='Description' />
          <Column dataField='IsManager' />
          <Column dataField='Email' />
          <Column dataField='Staff' />
          <Column dataField='Username' />
          <Column dataField="PhoneNumbers" caption='Phone Numbers' minWidth={240} cellRender={(data) => {
            if (data.value) {
              const phoneNumbersArray = String(data.value).split(',');
              return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  {phoneNumbersArray.map((phoneNumber, index) => (
                    <div key={index}>{phoneNumber.trim()}</div>
                  ))}
                </div>
              );
            }
          }} />
          <Column dataField='Groups' />
        </DataGrid>
        <div className="count">Contacts: {contactsToShow?.length}</div>
      </GridContainer>
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={loading && isVisible}
        message="Loading..."
        closeOnOutsideClick={false}
      />
    </Popup >
  );
}

export default ContactExportPopup;

ContactExportPopup.propTypes = {
  isVisible: bool.isRequired,
  setIsVisible: func.isRequired,
};
