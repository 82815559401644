import { useQuery } from 'react-query';
import API from '../API';

async function getEmrRequestMethodList() {
  const { data } = await API.sendRequest('EMRRequestMethod', 'GET');

  return data;
}

export default function useEmrRequestMethodList() {
  return useQuery('emrRequestMethodList', getEmrRequestMethodList, {
    refetchOnWindowFocus: false,
  });
}
