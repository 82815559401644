import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
    border-radius: 8px;
    border: 0.5px solid #ccc;

    legend {
      padding: 0 5px;
    }
  }
`;

export const WrapperField = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  align-items: flex-end;
  justify-content: center;
`;
export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  margin-top: 25px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 12px;
`;
