import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const WrapperHoliday = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Holiday = styled.span`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .texts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 18px);
    gap: 12px;
  }
`;

export const Square = styled.span`
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: #90d052;
`;
