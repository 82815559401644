import { useQuery } from 'react-query';
import API from '../API';

async function getUserTrainingsById(trainingId) {
  const { data } = await API.sendRequest(
    `UserTraining/ListByTraining/${trainingId}`,
    'GET'
  );

  return data;
}

export default function useGetUserTrainingsById(trainingId) {
  return useQuery('userTraining', () => getUserTrainingsById(trainingId), {
    refetchOnWindowFocus: false,
  });
}

export async function saveUserTrainings(userTraining) {
  const data = await API.sendRequest(
    'UserTraining/SaveComplete',
    'POST',
    userTraining
  );
  // if (status !== 200) {
  //   throw new Error(`Error on api status ${status}`);
  // }
  // const checkError = data.find((item) => item.ErrorMsg);
  // if (checkError) {
  //   throw new Error(checkError.ErrorMsg);
  // }
  return data;
}
