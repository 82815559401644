import { LoadPanel, Popup, TagBox, TextBox } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { array, bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import API from '../../../../../services/API';
import queryClient from '../../../../../services/queryClient';
import { Content, FieldRow, FieldSet, GroupContainer } from './styles';

function PopupNewMember({
  existentMembers,
  existentGroups,
  currentGroup,
  currentMember,
  visible,
  onClose,
  type,
}) {
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [ADUserList, setADUserList] = useState([]);
  const [distributionList, setDistributionList] = useState([]);
  const [membersToSave, setMemberToSave] = useState([]);
  const [distListToSave, setDistListToSave] = useState([]);

  useEffect(() => {
    let isMounted = true;

    async function fetchADUsers() {
      if (isMounted) {
        setLoading(true);
        const { data: ADUserListFromSQL } = await API.sendRequest(
          `ADUser/ListAllFromSQL`,
          'GET'
        );
        const existent = existentMembers?.map((item) => item?.DisplayName);
        setADUserList(
          ADUserListFromSQL?.filter(
            (user) => !existent?.includes(user?.DisplayName)
          )
        );
        setLoading(false);
      }
    }
    async function fetchDistributionList() {
      if (isMounted) {
        setLoading(true);
        const { data: distList } = await API.sendRequest(
          'ADUser/GetDistLists',
          'GET'
        );
        const existent = existentGroups?.map(
          (item) => item?.DistributionListName
        );
        setDistributionList(
          distList
            ?.filter((user) => !existent?.includes(user?.GroupCN))
            ?.map((item) => ({ DName: item.DName, GroupCN: item?.GroupCN }))
        );
        setLoading(false);
      }
    }

    fetchADUsers();
    fetchDistributionList();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleSaveChanges = async () => {
    let validationError = '';
    let dataToSave = [];
    let successNotificationMessage = '';

    if (type === 'MEMBER') {
      if (!membersToSave.length) {
        validationError = 'You need to select at least one Member';
      } else {
        dataToSave = membersToSave;
        successNotificationMessage = 'Member(s) saved successfully';
      }
    } else if (type === 'LIST') {
      if (!distListToSave.length) {
        validationError = 'You need to select at least one Group';
      } else {
        dataToSave = distListToSave;
        successNotificationMessage = 'List(s) saved successfully';
      }
    }

    if (validationError) {
      notify(validationError, 'error', 5000);
      return;
    }

    setIsSaving(true);

    try {
      const errors = await Promise.all(
        dataToSave.map(async (data) => {
          try {
            const res = await API.sendRequest('ADUser/AddGroupMember', 'post', {
              Username: type === 'MEMBER' ? data : currentMember.Username,
              GroupDName: type === 'MEMBER' ? currentGroup.DName : data,
            });

            if (res.status !== 200) {
              return res;
            }
          } catch (e) {
            return e;
          }
        })
      ).then((results) => results.filter((result) => result));

      if (errors.length) {
        notify(
          `Error(s): ${errors?.map((item, idx) =>
            idx + 1 === errors.length
              ? item?.data?.ErrorMsg
              : ` ${item?.data?.ErrorMsg} `
          )} `,
          'error',
          5000
        );
        setIsSaving(false);
        return;
      }

      setIsSaving(false);
      queryClient.invalidateQueries('distList');
      queryClient.invalidateQueries('distListByUser');
      notify(successNotificationMessage, 'success', 5000);
      onClose();
    } catch (e) {
      setIsSaving(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const popupButtons = [
    {
      text: 'OK',
      type: 'success',
      onClick: handleSaveChanges,
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: onClose,
    },
  ];

  return (
    <Popup
      title={type === 'LIST' ? 'Add Group(s)' : 'Add Member(s)'}
      visible={visible}
      onHiding={() => {
        onClose();
        setMemberToSave([]);
        setDistListToSave([]);
      }}
      width={550}
      height={350}
      dragEnabled={false}
      showCloseButton
    >
      <LoadPanel
        visible={isSaving || isLoading}
        message={isSaving ? 'Saving...' : 'Loading...'}
        shadingColor="rgba(255,255,255,0.4)"
        closeOnOutsideClick={false}
      />
      <Content>
        <GroupContainer reverse={type === 'MEMBER'}>
          <FieldRow>
            <FieldSet $width="49%">
              <label className="label">
                {`Staff${type === 'MEMBER' ? '(s)' : ''}:`}
              </label>
              <TagBox
                width="380px"
                dataSource={ADUserList}
                valueExpr="Username"
                displayExpr="DisplayName"
                searchEnabled
                searchExpr={['DisplayName', 'Username']}
                hideSelectedItems
                onValueChanged={(e) => setMemberToSave(e.value)}
                visible={type === 'MEMBER'}
              />
              <TextBox
                width="380px"
                visible={type === 'LIST'}
                value={currentMember?.DisplayName}
                readOnly
              />
            </FieldSet>
          </FieldRow>
          <FieldRow>
            <FieldSet $width="49%">
              <label className="label">
                {`Group${type === 'LIST' ? '(s)' : ''}:`}
              </label>

              <TagBox
                width="380px"
                dataSource={distributionList}
                valueExpr="DName"
                displayExpr="GroupCN"
                searchEnabled
                searchExpr="GroupCN"
                hideSelectedItems
                onValueChanged={(e) => setDistListToSave(e.value)}
                visible={type === 'LIST'}
              />
              <TextBox
                width="380px"
                visible={type === 'MEMBER'}
                value={currentGroup?.GroupCN}
                readOnly
              />
            </FieldSet>
          </FieldRow>
        </GroupContainer>
      </Content>
      {popupButtons.map((options, index) => (
        <ToolbarItem
          key={index}
          widget="dxButton"
          location="after"
          toolbar="bottom"
          options={{ ...options }}
        />
      ))}
    </Popup>
  );
}

export default PopupNewMember;

PopupNewMember.propTypes = {
  currentGroup: object,
  currentMember: object,
  visible: bool,
  onClose: func.isRequired,
  existentMembers: array,
  existentGroups: array,
  type: string,
};

PopupNewMember.defaultProps = {
  currentGroup: {},
  currentMember: {},
  visible: false,
  existentMembers: [],
  existentGroups: [],
  type: '',
};
