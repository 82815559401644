import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, LoadPanel, Popup, TextBox, Validator } from 'devextreme-react';

import ImageSelector from '../../../../../components/ImageSelector';
import { FieldRow, FieldSet, GroupContainer, WrapperButtons } from './styles';
import { func } from 'prop-types';
import { RequiredRule } from 'devextreme-react/form';
import { saveOktaApp } from '../../../../../services/requests/useOktaApp';
import notify from 'devextreme/ui/notify';
import queryClient from '../../../../../services/queryClient';
import ImagesAPI from '../../../../../services/ImagesAPI';
import { PatternRule } from 'devextreme-react/data-grid';

const PopupApplication = forwardRef(({ onClose }, ref) => {
  const [applicationDraft, setApplicationDraft] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [imageToSend, setImageToSend] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const [data, setData] = useState(null);

  useImperativeHandle(
    ref,
    () => ({
      setPopupData: (data) => {
        setData(data);
      },
    }),
    []
  );

  const handleClose = () => {
    onClose();
    setImageToSend(null);
    setApplicationDraft(null);
    setImageChanged(false);
    setData(null);
  };

  useEffect(() => {
    if (data?.data) {
      setApplicationDraft({ ...data?.data });
    } else {
      setApplicationDraft({
        OktaAppName: '',
        OktaAppLabel: '',
        OktaAppUrl: '',
      });
    }
    // eslint-disable-next-line
  }, [data]);

  const handleImageChange = (img) => {
    setImageToSend(img);
    setImageChanged(true);
  };

  const handleSaveApp = useCallback(async () => {
    setIsSaving(true);
    let resApp;

    const hasDataChanged =
      data?.data?.OktaAppName !== applicationDraft?.OktaAppName ||
      data?.data?.OktaAppLabel !== applicationDraft?.OktaAppLabel ||
      data?.data?.OktaAppUrl !== applicationDraft?.OktaAppUrl;

    try {
      if (hasDataChanged) {
        resApp = await saveOktaApp(applicationDraft);
        if (resApp.status !== 200) {
          throw new Error(resApp.message);
        }
      }

      if (imageChanged) {
        const formData = new FormData();
        formData.append(
          'OktaApplicationId',
          hasDataChanged
            ? resApp?.data?.OktaApplicationId
            : applicationDraft?.OktaApplicationId
        );
        formData.append(
          'OktaAppId',
          hasDataChanged ? resApp?.data?.OktaAppId : applicationDraft?.OktaAppId
        );
        formData.append('File', imageToSend);

        const resImage = await ImagesAPI.sendRequest(
          'OktaApp/SaveApplicationLogo',
          'post',
          formData
        );

        if (resImage.status !== 200) {
          throw new Error(resImage?.message || resImage?.data?.ErrorMsg);
        }
      }
      notify('Okta Application saved successfully', 'success', 5000);
      queryClient.invalidateQueries(['oktaAppList']);
      handleClose();
    } catch (e) {
      setIsSaving(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
    // eslint-disable-next-line
  }, [applicationDraft, imageChanged, imageToSend]);

  return (
    data?.visible && (
      <>
        <LoadPanel
          visible={isSaving}
          message={isSaving ? 'Saving...' : 'Loading...'}
        />
        <Popup
          title="Application"
          visible={data?.visible}
          onHiding={handleClose}
          onHidden={handleClose}
          width={600}
          height={350}
          dragEnabled={false}
          showCloseButton
        >
          <FieldSet $width="49%">
            <label className="label">Logo:</label>
            <ImageSelector
              rounded={false}
              onFileSelected={handleImageChange}
              onFileDeleted={() => {}}
              previewImg={applicationDraft?.OktaAppLogo}
              hasLimit
              showDeleteButton={false}
            />
          </FieldSet>

          <form onSubmit={handleSaveApp}>
            <GroupContainer>
              <FieldRow>
                <FieldSet $width="49%">
                  <label className="label">Name:</label>
                  <TextBox
                    width="430px"
                    readOnly={!!applicationDraft?.OktaApplicationId}
                    value={applicationDraft?.OktaAppName}
                    onValueChanged={(e) =>
                      setApplicationDraft((prevState) => ({
                        ...prevState,
                        OktaAppName: e.value,
                      }))
                    }
                  >
                    <Validator>
                      <RequiredRule message="Application Name is required" />
                    </Validator>
                  </TextBox>
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="49%">
                  <label className="label">Label:</label>
                  <TextBox
                    width="430px"
                    value={applicationDraft?.OktaAppLabel}
                    onValueChanged={(e) =>
                      setApplicationDraft((prevState) => ({
                        ...prevState,
                        OktaAppLabel: e.value,
                      }))
                    }
                  >
                    <Validator>
                      <RequiredRule message="Application Label is required" />
                    </Validator>
                  </TextBox>
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="49%">
                  <label className="label">Url:</label>
                  <TextBox
                    width="430px"
                    value={applicationDraft?.OktaAppUrl}
                    onValueChanged={(e) =>
                      setApplicationDraft((prevState) => ({
                        ...prevState,
                        OktaAppUrl: e.value,
                      }))
                    }
                  >
                    <Validator>
                      <RequiredRule message="Url is required" />
                      <PatternRule
                        message="Url do not match to the pattern. Must have the following format: https://www.example.com"
                        pattern={
                          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
                        }
                      />
                    </Validator>
                  </TextBox>
                </FieldSet>
              </FieldRow>

              <WrapperButtons>
                <Button
                  text="OK"
                  type="success"
                  useSubmitBehavior
                  width={100}
                />
                <Button
                  text="Cancel"
                  type="normal"
                  onClick={handleClose}
                  width={100}
                />
              </WrapperButtons>
            </GroupContainer>
          </form>
        </Popup>
      </>
    )
  );
});

export default PopupApplication;

PopupApplication.propTypes = {
  onClose: func.isRequired,
};
