import React from 'react';
import { useDrag } from 'react-dnd';

import { Item, ItemEmpty } from './styles';

const CellContent = ({
  source,
  item,
  itemQty,
  selectedCells,
  handleCellClick,
  handleMenuClick,
  classes,
  appointmentTypesColors,
  cellId,
  index,
  tasks,
  supports,
}) => {

  const [{ opacity }, drag] = useDrag({
    item: {
      id: item ? item.Id : '00000000-0000-0000-0000-000000000000',
      cellId,
      type: 'CELL',
      source,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  if (item) {
    if (item.Sb === 'LOA') {
      item.Cl = 'repeating-linear-gradient(45deg, #DD6969, #DD6969 10px, #D95959 10px, #D95959 20px)' ?? item.Cl;
    }
    if (item.Cl) {
      item.Cl = item.Cl.trim();
    }

    if (appointmentTypesColors[item.Sb]) {
      item.Cl = appointmentTypesColors[item.Sb];
    }

    if (!item.Cl) {
      item.Cl = item?.Locked
        ? 'repeating-linear-gradient(45deg, #00000010, #00000010 10px, #ffffff20 10px, #ffffff20 20px)'
        : '#0a00f0';
    }

    if (index === 2) {
      item.Cl = '#0a00f0';
      const qtyItem = itemQty - 2;
      return (
        <Item
          bg={item.Cl}
          color={item.Cl}
          border={selectedCells.includes(cellId)}
          opacity={opacity}
          itemQty={itemQty}
          // onClick={(e) => handleCellClick(e, source)}
          onContextMenu={
            item?.Locked
              ? (e) => e.preventDefault()
              : (e) => handleMenuClick(e, source)
          }
        >
          {`${qtyItem} more`}
        </Item>
      );
    }

    return (
      <Item
        ref={item?.Sb === 'LOA' ? null : drag}
        bg={item.Cl}
        color={item.Cl}
        border={selectedCells.includes(cellId)}
        opacity={opacity}
        itemQty={itemQty}
        // onClick={(e) => e.preventDefault()}
        onContextMenu={
          item?.Locked || item.Sb === 'LOA'
            ? (e) => e.preventDefault()
            : (e) => handleMenuClick(e, source)
        }
      >
        {item.Sb}
      </Item>
    );
  }

  return (
    <ItemEmpty
      border={selectedCells.includes(cellId)}
      onContextMenu={(e) => handleMenuClick(e, source)}
    />
  );
};

export default CellContent;
