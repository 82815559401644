import { Button, DataGrid, SelectBox, TreeView } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import Diagram, { AutoLayout, Nodes, Toolbox } from 'devextreme-react/diagram';
import CustomStore from 'devextreme/data/custom_store';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Avatar from '../../components/contacts/Avatar';
import API from '../../services/API';
import {
  Container,
  HeaderContainer,
  ListContainer,
  ListItem,
  UserDataContainer,
  UserDetailContainer,
} from './styles';
import UserDetail from './UserDetail';

const viewOptions = [
  {
    value: 1,
    display: 'Diagram',
  },
  {
    value: 2,
    display: 'List',
  },
];

const HierarchyPage = () => {
  const [currentView, setCurrentView] = useState(2);
  const [currentUser, setCurrentUser] = useState(null);
  const [listData, setListData] = useState([]);
  const [dataGridSource, setDataGridSource] = useState([]);
  const adHierarchyGridRef = useRef();
  function listToTree(list) {
    const map = {};
    let node;
    const roots = [];
    let i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].Username] = i; // initialize the map
      list[i].items = []; // initialize the items
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.Manager) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.Manager]].items.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  const fetchListData = useCallback(async () => {
    const { data } = await API.sendRequest('ADUser/ListHierarchy', 'GET');
    setDataGridSource(data);
    setListData(listToTree(data));
  }, []);

  useEffect(() => {
    fetchListData();
  }, [fetchListData]);

  /** *******************************
   * Access Control
   ******************************** */
  // const ACL = usePermissions();
  // while (ACL === undefined) {
  //   return <LoadPermissions>Loading permissions</LoadPermissions>;
  // }
  // if (ACL.NoAccess) {
  //   return (
  //     <LoadPermissions>You do not have access to this page</LoadPermissions>
  //   );
  // }
  /** ****************************** */

  const dataSource = new CustomStore({
    key: 'UserId',
    load: async () => {
      const { data } = await API.sendRequest('ADUser/ListHierarchy', 'GET');
      return listToTree(data);
    },
  });

  const listItemRender = (data) => (
    <ListItem>
      <Avatar
        contact={{ ...data, Username: data.Username }}
        h={32}
        w={32}
        useTooltip={false}
      />
      <UserDetailContainer>
        <span className="userName">{data.DisplayName}</span>
        <span className="userOffice">{data.JobTitle}</span>
      </UserDetailContainer>
    </ListItem>
  );

  const handleItemClick = ({ itemData }) => {
    setCurrentUser(
      currentUser && currentUser.UserId === itemData.UserId ? null : itemData
    );
  };

  function handleExportGridToExcel(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('NJ Hierarchy');
    const dataGrid = adHierarchyGridRef.current.instance;

    exportDataGridToExcel({
      component: dataGrid,
      worksheet,
      keepColumnWidths: false,

      // customizeCell: ({ gridCell, excelCell }) => {
      //   if (gridCell.rowType === 'data') {
      //     if (gridCell.column.dataField === 'PhoneNumbers' && gridCell.value) {
      //       const content = gridCell.value
      //         .replaceAll('Business ', '')
      //         .replaceAll(',', ' \n');

      //       excelCell._value.model.value = content;
      //       excelCell._column.width = 40;
      //       excelCell.alignment = { wrapText: true };
      //     }
      //   }
      // },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `nj_hierarchy.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  return (
    <Container>
      <HeaderContainer>
        {/* {BreadCrumbRender(ACL)} */}
        <SelectBox
          items={viewOptions}
          value={currentView}
          onValueChanged={(e) => {
            setCurrentView(e.value);
          }}
          valueExpr="value"
          displayExpr="display"
        />
        <Button
          stylingMode="outlined"
          type="normal"
          icon="xlsxfile"
          onClick={handleExportGridToExcel}
        />
        <DataGrid
          ref={adHierarchyGridRef}
          dataSource={dataGridSource}
          visible={false}
        >
          <Column dataField="DisplayName" />
          <Column dataField="Username" />
          <Column dataField="NewUsername" />
          <Column dataField="Email" />
          <Column dataField="JobTitle" />
          <Column dataField="IsManager" />
          <Column dataField="Manager" />
          <Column dataField="orderImport" />
        </DataGrid>
      </HeaderContainer>
      {currentView === 1 ? (
        <Diagram className="diagram" readOnly>
          <Nodes
            dataSource={dataSource}
            keyExpr="UserId"
            textExpr="DisplayName"
            itemsExpr="items"
          >
            <AutoLayout orientation="horizontal" type="layered" />
          </Nodes>
          <Toolbox visibility="disabled" />
        </Diagram>
      ) : (
        <ListContainer>
          <TreeView
            items={listData}
            displayExpr="DisplayName"
            itemRender={listItemRender}
            onItemClick={handleItemClick}
            searchMode="contains"
            searchEnabled
            animationEnabled
            width="500px"
          />
          {currentUser && (
            <UserDataContainer>
              <UserDetail
                userData={{
                  DisplayName: currentUser.DisplayName,
                  JobTitle: currentUser.JobTitle,
                  Email: currentUser.Email,
                  Username: currentUser.Username,
                }}
              />
            </UserDataContainer>
          )}
        </ListContainer>
      )}
    </Container>
  );
};

export default HierarchyPage;
