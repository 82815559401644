import React, { useState } from 'react';
import useStyles from '../styles/styles';
import usePermissions from '../../hooks/usePermissions';
import { LoadPermissions } from '../../global';

import { Container, Content } from './styles';
import useGetDocuSignTemplate from '../../services/requests/useGetDocusignTemplate';
import { Button, DataGrid, LoadPanel } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import EditTemplatePopup from './components/EditTemplatePopup';
import API from '../../services/API';
import notify from 'devextreme/ui/notify';

function Template() {
  const [popupEditVisible, setPopupEditVisible] = useState(false);
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [templateData, setTemplateData] = useState('');
  const [title, setTitle] = useState('');
  const classes = useStyles();

  const {
    data: getDocuSignTemplate,
    refetch: forceRefetch,
    isLoading,
  } = useGetDocuSignTemplate();

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }
  /** ****************************** */

  const handleOpenEditPopup = (e) => {
    setTemplateData(e.row.data);
    setTitle(e.row.data.DocuSignTemplateDesc);
    setPopupEditVisible(true);
  };

  const handlePopupClose = () => {
    setPopupEditVisible(false);
  };

  async function handleSyncTemplates() {
    setIsLoadingSync(true);
    try {
      const res = await API.sendRequest('DocuSign/Template/Sync', 'GET');
      if (res.status !== 200) {
        throw new Error(res.message);
      }
      notify(`Templates synced successfully`, 'success', 5000);
      forceRefetch();
      setIsLoadingSync(false);
    } catch (error) {
      setIsLoadingSync(false);
      notify(`Error: ${error.message}`, 'error', 5000);
    }
  }

  return (
    <div className={classes.divGridContainer}>
      {popupEditVisible && (
        <EditTemplatePopup
          isVisible={popupEditVisible}
          setIsVisible={handlePopupClose}
          data={templateData}
          title={title}
          forceRefetch={forceRefetch}
          isExternalCall={false}
          handleChangeHospitalDraft={() => {}}
        />
      )}

      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <h3>{ACL && ACL.BreadCrumb}</h3>
          <Button
            icon={isLoadingSync ? 'clock' : 'refresh'}
            disabled={isLoadingSync}
            hint="Sync Templates"
            onClick={() => handleSyncTemplates()}
          />
        </div>
        {/* <LoadingIndicator enabled /> */}
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={isLoading || isLoadingSync}
          message={
            isLoading ? 'Loading templates...' : 'Synchronizing Templates...'
          }
          closeOnOutsideClick={false}
        />
        <Content>
          <DataGrid
            dataSource={getDocuSignTemplate}
            showBorders
            rowAlternationEnabled
            focusedRowEnabled
            keyExpr="DocuSignTemplateId"
            noDataText={isLoading ? 'Loading...' : 'No data'}
          >
            <Column caption="Template" dataField="DocuSignTemplateDesc" />
            <Column
              caption="Hospital Admin Name"
              dataField="HospitalEMRAdminName"
            />
            <Column
              caption="Hospital Admin Email"
              dataField="HospitalEMRAdminEmail"
            />
            <Column
              caption="Actions"
              type="buttons"
              width={100}
              buttons={[
                {
                  hint: 'Edit',
                  icon: 'fas fa-pen',
                  onClick: (e) => handleOpenEditPopup(e),
                  visible: ACL?.Admin || ACL?.Update,
                },
              ]}
            />
          </DataGrid>
        </Content>
      </Container>
    </div>
  );
}

export default Template;
