import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import { Column, DataGrid, Paging, Editing } from 'devextreme-react/data-grid';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

const groups = new CustomStore({
  key: 'HelpTextGroupId',
  load: async () => {
    const { data } = await API.sendRequest(`HelpTextGroup`, 'get');
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`HelpTextGroup`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`HelpTextGroup`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`HelpTextGroup/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const HelpTextGroup = () => {
  const [menus, setMenus] = useState(null); //eslint-disable-line
  const classes = useStyles();

  useEffect(() => {
    async function init() {
      setMenus((await API.sendRequest(`Menu/ChildrenOnly/1`, 'get')).data);
    }

    init(); // eslint-disable-next-line
  }, []);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={groups}
        // keyExpr="HelpTextGroupId"
        allowColumnReordering
        showBorders
        onRowUpdating={onRowUpdating}
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />
        <Editing
          useIcons
          mode="form"
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        />

        <Column dataField="GroupDesc" caption="Topic" />
        <Column dataField="GroupOrder" caption="Order" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default HelpTextGroup;
