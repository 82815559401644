import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Container = styled.div`
  padding: 20px;
`;
export const BoxTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const FullName = styled(Typography)`
  color: #0063a5;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
`;
export const JobTitle = styled(Typography)`
  color: #e0e0e0;
  font-size: 14px;
`;

export const BoxImagesTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const ImgIcon = styled.img`
  height: 28px;
  width: 28px;
  margin: 10px;
`;

export const BoxBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const BoxField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const IconField = styled.img`
  height: 28px;
  width: 28px;
  margin-right: 10px;
`;

export const BoxLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabelField = styled(Typography)`
  color: #8f8f8f;
  font-size: 14px;
`;

export const WrapperFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperSignout = styled.div`
  margin-top: 25px;
  border: 1px solid #0063a5;
  border-radius: 8px;
  cursor: pointer;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    padding: 5px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #0063a5;
    opacity: 0.8;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    p {
      font-size: 15px;
      font-weight: bold;
    }

    i {
      font-size: 20px;
      margin-left: 5px;
    }
  }
`;
