import { DataGrid, Button as DxButton, Lookup } from 'devextreme-react';
import {
  Button as ButtonGrid,
  Column,
  FilterRow,
} from 'devextreme-react/data-grid';
import RequestMethodCell from 'pages/EMRAccess/components/RequestMethodCell';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import notify from 'devextreme/ui/notify';
import DeleteConfirmationPopup from 'pages/EMRAccess/components/DeleteConfirmationPopup';
import StatusCellRender from 'pages/EMRAccess/components/StatusCellRender';
import API from 'services/API';
import { WrapperBulkButton } from './styles';

function TerminationDetail(
  {
    ACL,
    data,
    loading,
    requestMethodList,
    requestStatusDataList,
    editRequestPopupRef,
    emailViewRef,
    sendEmailPopupRef,
  },
  ref
) {
  const [bulkEmailUsers, setBulkEmailUsers] = useState([]);
  const [deletePopup, setDeletePopup] = useState({
    visible: false,
    emrAccessId: '',
    hospitalGroupName: '',
    question: '',
  });

  const [requestList, setRequestList] = useState({
    data: [],
    isLoading: false,
  });

  async function fetchRequestData() {
    setRequestList((prev) => ({ ...prev, isLoading: true }));

    try {
      const { data: requests } = await API.sendRequest(
        `ADUserEMRAccess/ListByUser/${data?.data?.UserId}/false/true`,
        'GET'
      );

      setRequestList({
        data: requests,
        isLoading: false,
      });
    } catch (error) {
      setRequestList((prev) => ({ ...prev, isLoading: false }));
      console.log(error);
    }
  }
  const autoRefetchGrid = true;

  useEffect(() => {
    fetchRequestData();
    // eslint-disable-next-line
  }, []);

  const activeAutoRefetch = useCallback(() => {
    if (autoRefetchGrid) {
      fetchRequestData();
    }
    // eslint-disable-next-line
  }, [autoRefetchGrid]);

  useEffect(() => {
    let isSubscribed = true;
    let intervalId;

    if (isSubscribed) {
      fetchRequestData();

      intervalId = setInterval(() => {
        activeAutoRefetch();
      }, 1 * 60 * 1000); // 1 minute
    }
    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [activeAutoRefetch]);

  useImperativeHandle(ref, () => ({
    clearUsers: () => setBulkEmailUsers([]),
  }));

  const handleDeleteRequest = (e) => {
    if (e?.row.data.EMRRequestStatusId) {
      setDeletePopup({
        visible: true,
        emrAccessId: e?.row.data.ADUserEMRAccessId,
        hospitalGroupName: e?.row.data.HospitalGroupName,
        question: 'Delete',
      });
    }
  };

  async function deleteRequest(requestId) {
    if (requestId) {
      const res = await API.sendRequest(
        `ADUserEMRAccess/TerminationDelete/${requestId}`,
        'POST'
      );

      if (res?.data?.ErrorMsg) {
        notify(res?.data?.ErrorMsg, 'error', 5000);
        return;
      }
      fetchRequestData();
      notify('Request canceled successfully', 'success', 5000);
    }
  }

  return (
    <>
      <WrapperBulkButton
        title={bulkEmailUsers.length < 2 ? 'Select at least 2 users' : ''}
      >
        <DxButton
          text={`Edit and send bulk email ${
            bulkEmailUsers.length > 0 ? `(${bulkEmailUsers.length})` : ''
          }`}
          disabled={bulkEmailUsers.length < 2}
          icon="email"
          visible={
            data?.EMRRequestMethodId === 3
            // &&
            // requestList?.data?.filter((item) => item.EMRRequestStatusId === 0)
            //   .length > 0
          }
          onClick={() =>
            sendEmailPopupRef?.current?.changeVisibility(
              {},
              bulkEmailUsers,
              'bulk'
            )
          }
        />
      </WrapperBulkButton>
      <DataGrid
        // ref={contactExportGridRef}
        dataSource={requestList?.data}
        // loadPanel={{ enabled: loading }}
        allowColumnReordering
        allowColumnResizing
        showRowLines
        showColumnHeaders
        showBorders
        rowAlternationEnabled
        filterPanel={{ visible: true }}
        noDataText={requestList?.isLoading ? 'Loading...' : 'No data'}
        width="100%"
        height="100%"
        focusedRowEnabled
        keyExpr="ADUserEMRAccessId"
      >
        <FilterRow visible applyFilter="auto" />
        <Column
          dataField="HospitalGroupName"
          caption="Okta Tile"
          width="200px"
        />
        <Column
          dataField="EMRRequestStatusId"
          caption="Status"
          type="text"
          cellComponent={StatusCellRender}
          minWidth={200}
        >
          <Lookup
            dataSource={requestStatusDataList}
            displayExpr="EMRRequestStatusName"
            valueExpr="EMRRequestStatusId"
          />
        </Column>
        <Column
          dataField="ExpiresOn"
          caption="Expires On"
          dataType="date"
          width={110}
          // cssClass={classes.cellRenderTd}
          // cellRender={({ data }) => {
          //   const yourDate = parseISO(data?.ExpiresOn);
          //   const currentDate = new Date();
          //   return (
          //     <div>
          //       <CellRenderContainer isBefore={isBefore(yourDate, currentDate)}>
          //         {format(yourDate, 'MM/dd/yyyy')}
          //       </CellRenderContainer>
          //     </div>
          //   );
          // }}
        />

        <Column
          dataField="EMRRequestMethodId"
          caption="Request Method"
          // cssClass={classes.cellRenderTd}
          cellComponent={({ data }) => {
            return data?.data?.EMRRequestStatusId === 6 ? (
              <span>
                {
                  requestMethodList?.find(
                    (item) =>
                      item.EMRRequestMethodId === data?.data?.EMRRequestMethodId
                  )?.EMRRequestMethodName
                }
              </span>
            ) : (
              <RequestMethodCell
                data={data}
                emailViewRef={emailViewRef}
                sendEmailPopupRef={sendEmailPopupRef}
                isTerminationScreen
              />
            );
          }}
          minWidth={200}
        >
          <Lookup
            dataSource={requestMethodList}
            valueExpr="EMRRequestMethodId"
            displayExpr="EMRRequestMethodName"
          />
        </Column>

        <Column
          dataField="ApprovalDate"
          caption="Approval Date"
          dataType="date"
          width={110}
        />

        <Column caption="Termination">
          <Column
            dataField="TerminationSubmitted"
            caption="Submitted"
            dataType="date"
            width={110}
          />
          <Column
            dataField="TerminationCompleted"
            caption="Completed"
            dataType="date"
            width={110}
          />
        </Column>

        <Column
          width={150}
          dataField="RequestedByUsername"
          caption="Requested By"
        />

        <Column fixed caption="Actions" type="buttons" width={100}>
          {(ACL?.Admin || ACL?.Update) && (
            <ButtonGrid
              icon="fas fa-edit"
              hint="Edit"
              onClick={(e) => {
                // eslint-disable-next-line no-unused-expressions
                editRequestPopupRef?.current?.changeVisibility({
                  ...e.row.data,
                });
              }}
              visible={!loading}
            />
          )}
          {(ACL?.Admin || ACL?.Delete) && (
            <ButtonGrid
              visible={(e) => [7, 8].includes(e?.row?.data?.EMRRequestStatusId)}
              icon="fas fa-trash"
              hint="Delete"
              onClick={handleDeleteRequest}
            />
          )}
        </Column>
      </DataGrid>
      {deletePopup.visible && deletePopup.emrAccessId && (
        <DeleteConfirmationPopup
          visible={deletePopup.visible}
          hospitalGroupName={deletePopup?.hospitalGroupName}
          onClose={() => {
            setDeletePopup({
              visible: false,
              emrAccessId: '',
              hospitalGroupName: '',
              question: '',
            });
          }}
          question={deletePopup?.question}
          deleteFunc={() => deleteRequest(deletePopup?.emrAccessId)}
        />
      )}
    </>
  );
}

export default forwardRef(TerminationDetail);
