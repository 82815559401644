import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import { number } from 'prop-types';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { DateBox } from 'devextreme-react';
import { Resource } from 'devextreme-react/scheduler';
import usePermissions from '../../hooks/usePermissions';
import { BreadCrumb, LoadPermissions } from '../../global';
import API from '../../services/API';
import { Container, NJScheduler, RowDateGarbage } from './styles';
// import clone from '../../helpers/clone';
import TooltipTemplate from './TooltipTemplate';
import SchedulePopup from './SchedulePopup';

// import Chat from '../../components/popups/Chat';

let typeChanged = [];
let selectedDate = null;
let tooltipClicked;

const getAppointmentTypes = async (area) => {
  const { data } = await API.sendRequest(`AppointmentType/${area}`, 'get');

  const result = data.map((item) => ({
    text: item.AppointmentTypeDesc,
    id: item.AppointmentTypeId,
    code: item.AppointmentTypeCode,
    color: item.Color,
  }));

  return result;
};

const getContacts = async (area) => {
  const { data } = await API.sendRequest(
    `ContactSchedule/ListContacts/${area}`,
    'get'
  );

  return data;
};

const getAgenda = async (area, currentDate) => {
  const { data } = await API.sendRequest(
    `ContactSchedule/ListAgendaView/${area}/${currentDate.getFullYear()}/${
      currentDate.getMonth() + 1
    }`,
    'get'
  );

  // data.map(appointment => {
  data.forEach((appointment) => {
    appointment.EndDate = appointment.StartDate;
  });

  return data;
};

function getInitialDate(area) {
  const itemStorage = `lab_report_v2_${area}`;

  const now = localStorage.getItem(itemStorage)
    ? new Date(JSON.parse(localStorage.getItem(itemStorage)))
    : new Date();

  localStorage.setItem(itemStorage, JSON.stringify(now));

  return now;
}

export default function Report(props) {
  const { area } = props;
  const [currentDate, setCurrentDate] = useState(null);
  const [agenda, setAgenda] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);

  const scheduler = useRef();

  // const dataChat = useSelector(({ chat }) => chat.chatData);
  // const dispatch = useDispatch();

  const onMonthChanged = async (e) => {
    const newDate = new Date(e.value);
    const itemStorage = `lab_report_v2_${area}`;
    localStorage.setItem(itemStorage, JSON.stringify(newDate));

    const age = await getAgenda(area, newDate);

    setCurrentDate(newDate);
    setAgenda(age);
  };

  // cria um array padrão para gravação do apontamento
  const createResultObjects = (date) => {
    selectedDate = date;
    typeChanged = [];

    // para cada appointmentType...
    for (let index = 0; index < appointmentTypes.length; index++) {
      const element = appointmentTypes[index];

      // ...verifica se existe contatos na agenda
      const result = agenda.filter(
        (item) =>
          item.StartDate.substring(0, 10) === date.substring(0, 10) &&
          item.AppointmentTypeId === element.id
      );

      // ...cria o objeto mesmo sem items
      const type = {
        StartDate: date.substring(0, 10),
        Subject: element.code,
        key: element.id,
        items: [],
      };

      // ...se existir, adiciona os items encontrados
      if (result && result.length > 0) {
        const items = result.map((item) => ({
          ...item,
          StartDate: date,
          Subject: element.code,
        }));

        type.items = items;
      }

      // ...adiciona o appointmentType no array
      typeChanged.push(type);
    }
  };

  // prepara o formulário ao dar duplo click na célula
  const handleAppointmentFormOpening = (data) => {
    // cancela o formulário atual
    data.cancel = true;

    // se o tooltip disparou a abertura do form, cancelar.
    if (tooltipClicked) {
      tooltipClicked = false;
      return;
    }

    const { form } = data;

    const selDate = form.option('formData').StartDate;

    // não abre o formulário se duplo clique em célula que não corresponde ao mês selecionado
    if (
      format(new Date(selDate), 'MMMM yyyy') !==
      format(currentDate, 'MMMM yyyy')
    ) {
      return;
    }

    createResultObjects(selDate);

    // exibe o popup customizado
    setPopupVisible(true);
  };

  const saveAppointments = async () => {
    let totalItems = [];

    for (let x = 0; x < typeChanged.length; x++) {
      var obj = typeChanged[x];

      // criar propriedade contacts
      obj.Contacts = obj.items;

      const response = await API.sendRequest(
        'ContactSchedule/SavePopup',
        'post',
        obj
      );

      // adicionar manualmente os apontamentos
      const { data } = response;

      if (data && data.length > 0) {
        // data.map((appointment) => {
        // eslint-disable-next-line
        data.forEach((appointment) => {
          const item = contacts.find(
            (item) => item.ContactId === appointment.ContactId
          );

          if (item) {
            appointment.CustomName = `${obj.Subject}: ${item.FirstName}`;
            appointment.Description = obj.Subject;
            appointment.FullName = item.FullName;
            appointment.Initials = item.Initials;
            appointment.Subject = obj.Subject;
            appointment.EndDate = appointment.StartDate;
          }
        });

        totalItems = totalItems.concat(data);
      }
    }

    // teste 3 ***********************************************
    const age = await getAgenda(area, getInitialDate(area));
    setAgenda(age);

    // teste 1 ***********************************************
    // var remover = agenda.filter(item => item.StartDate.substring(0, 10) === typeChanged[0].StartDate.substring(0, 10));

    // for (let d = 0; d < remover.length; d++) {
    //   while (inAction) { console.log("deleting", inAction) };
    //   inAction = true;
    //   scheduler.current.instance.deleteAppointment(remover[d]);
    // }

    // // //adicionar todos os apontamentos retornados
    // for (let a = 0; a < totalItems.length; a++) {
    //   while (inAction) { console.log("adding", inAction) };
    //   inAction = true;
    //   scheduler.current.instance.addAppointment(totalItems[a]);
    // }

    // var upd = agenda.filter(item =>
    //   item.StartDate.substring(0, 10) === typeChanged[0].StartDate.substring(0, 10) &&
    //   totalItems.filter(ret => ret.ContactId === item.ContactId).length > 0);

    // teste 2 ************************************************
    // console.log("items a atualizar", upd);

    // for (let u = 0; u < upd.length; u++) {
    //   var item = agenda.find(item =>
    //     item.StartDate.substring(0, 10) === typeChanged[0].StartDate.substring(0, 10) &&
    //     item.ContactId === upd[u].ContactId);

    //   if (item) {
    //     console.log("atualizando", item);
    //     item.ContactScheduleId = upd[u].ContactScheduleId
    //   }
    // }

    // var add = totalItems.filter(ret =>
    //   agenda.filter(item =>
    //     item.StartDate.substring(0, 10) === typeChanged[0].StartDate.substring(0, 10) &&
    //     item.ContactId === ret.ContactId).length === 0);

    // console.log("items a adicionar", add);

    // //adicionar todos os apontamentos retornados
    // for (let a = 0; a < add.length; a++) {
    //   console.log("adicionando", add[a]);
    //   scheduler.current.instance.addAppointment(add[a]);
    // }

    // setTimeout(() => {}, 3000);

    // var del = agenda.filter(item =>
    //   item.StartDate.substring(0, 10) === typeChanged[0].StartDate.substring(0, 10) &&
    //   totalItems.filter(ret => ret.ContactId === item.ContactId).length === 0);

    // console.log("items a excluir", del);

    // for (let d = 0; d < del.length; d++) {
    //   console.log("eliminando", del[d]);
    //   scheduler.current.instance.deleteAppointment(del[d]);
    // }

    setPopupVisible(false);
  };

  // cancela o double click no apontamento existente e abre o form
  const handleAppointmentDblClick = (sender) => {
    sender.cancel = true;

    const appointmentData = {
      StartDate: sender.appointmentData.StartDate,
    };

    sender.component.showAppointmentPopup(appointmentData, true);
  };

  // excluindo o apontamento
  const handleAppointmentDeleting = (data) => {
    scheduler.current.instance.deleteAppointment(data);
    API.sendRequest(
      `ContactSchedule/${data.ContactScheduleId}`,
      'DELETE',
      data.ContactScheduleId
    );
  };

  // cancelar abertura do form, ao clicar no tooltip
  const handleTooltipClick = (e) => {
    tooltipClicked = true;
  };

  // disparado quando acontece drag and drop de um apontamento
  const handleAppointmentUpdating = (sender) => {
    const appointment = {
      AreaId: area,
      ContactScheduleId: sender.newData.ContactScheduleId,
      StartDate: sender.newData.StartDate.substring(0, 10),
      AppointmentTypeId: sender.newData.AppointmentTypeId,
      ContactId: sender.newData.ContactId,
      Subject: appointmentTypes.find(
        (item) => item.id === sender.newData.AppointmentTypeId
      ).code,
    };

    API.sendRequest('ContactSchedule', 'post', appointment);
  };

  // renderizando o tooltip
  const handleAppointmentTooltipRender = (model) => (
    <TooltipTemplate
      data={model.appointmentData}
      handleRemove={handleAppointmentDeleting}
      handleTooltipClick={handleTooltipClick}
    />
  );

  useEffect(() => {
    async function init() {
      setCurrentDate(getInitialDate(area));

      setAppointmentTypes(await getAppointmentTypes(area));

      setContacts(await getContacts(area));

      setAgenda(await getAgenda(area, getInitialDate(area)));
    }

    init(); // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   async function init() {

  //     if (!popupVisible) {
  //       setAgenda(await getAgenda(area, getInitialDate(area)));
  //     }
  //   }

  //   init();
  // }, [popupVisible]);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don&apos;t have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  return (
    <DndProvider backend={Backend}>
      <Container>
        <RowDateGarbage>
          <BreadCrumb>
            <h3>{ACL.BreadCrumb}</h3>
          </BreadCrumb>
          <DateBox
            id="dtCurrentDate"
            defaultValue={currentDate}
            type="date"
            format="date"
            width="200px"
            displayFormat="monthAndYear"
            onValueChanged={onMonthChanged}
            calendarOptions={{
              maxZoomLevel: 'year',
              minZoomLevel: 'century',
            }}
          />
          {/* {ACL.Delete && <Garbage handleDrop={handleGarbageDrop} />} */}
        </RowDateGarbage>
        <NJScheduler
          ref={scheduler}
          dataSource={agenda}
          views={['month']}
          defaultCurrentView="month"
          currentDate={currentDate}
          startDayHour={9}
          textExpr="CustomName"
          startDateExpr="StartDate"
          endDateExpr="EndDate"
          descriptionExpr="Description"
          allDayExpr="Duration"
          onAppointmentDblClick={handleAppointmentDblClick}
          onAppointmentUpdating={handleAppointmentUpdating}
          onAppointmentFormOpening={handleAppointmentFormOpening}
          appointmentTooltipRender={handleAppointmentTooltipRender}
        >
          <Resource
            dataSource={appointmentTypes}
            fieldExpr="AppointmentTypeId"
            displayExpr="text"
            valueExpr="id"
            colorExpr="color"
            label="Appointment Type"
            useColorAsDefault
          />
          <Resource
            dataSource={contacts}
            fieldExpr="ContactId"
            displayExpr="FullName"
            valueExpr="ContactId"
            label="Contact"
          />
        </NJScheduler>
        <SchedulePopup
          saveAppointments={saveAppointments}
          setPopupVisible={setPopupVisible}
          popupVisible={popupVisible}
          selectedDate={selectedDate}
          typeChanged={typeChanged}
          contacts={contacts}
          appointmentTypes={appointmentTypes}
        />

        {/* {dataChat.length > 0 &&

          dataChat.map((item) => (
            <Chat
              key={item.personData.ContactId}
              active={item.personData.isActive}
              data={item.personData}
              position={item.personData.position}
              minimized={item.personData.minimized}
            />
          ))} */}
      </Container>
    </DndProvider>
  );
}

Report.propTypes = {
  area: number.isRequired,
};
