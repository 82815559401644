import { IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import { CheckBox } from 'devextreme-react/check-box';
import { Column, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import UsersPopup from '../UsersPopup';
import Message from '../message/Message';

import Avatar from '../contacts/Avatar';

import { LoadPanel } from 'devextreme-react';
import API from '../../services/API';
import {
  BoxAvatarName,
  BoxSubtitle,
  ButtonCustom,
  CellCheckbox,
  ColumnDivider,
  Container,
  Content,
  DataGridCustom,
  Name,
  Subtitle,
  WrapperButtons,
} from './styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RoleDetails({ data }) {
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [groups, setGroups] = useState([]);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [openSnack, setOpenSnack] = useState(null);
  const [errorSnack, setErrorSnack] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [loadingSaveGroups, setLoadingSaveGroups] = useState(false);
  const [error, setError] = useState('');

  const handleMessageClose = () => {
    setError('');
  };

  async function loadDataAreas() {
    await API.sendRequest(`Role/AreaList/${data.key}`, 'get').then((response) =>
      setAreas(response.data)
    );
  }

  async function loadDataGroups() {
    await API.sendRequest(
      `Role/GroupList/${data.key}`,
      'get'
    ).then((response) => setGroups(response.data));
  }

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      await Promise.all([
        await API.sendRequest(`UserRole/listusers/${data.key}`, 'get'),
        await API.sendRequest(`Role/AreaList/${data.key}`, 'get'),
        await API.sendRequest(`Role/GroupList/${data.key}`, 'get'),
      ])
        .then((response) => {
          if (isSubscribed) {
            setUsers(response[0].data);
            setAreas(response[1].data);
            setGroups(response[2].data);
          }
        })
        .catch((error) => (isSubscribed ? setError(error.toString()) : null));
    };

    fetchData();

    return () => (isSubscribed = false); //eslint-disable-next-line
  }, []);

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const onDeleteClick = async (item) => {
    setRemoving(true);
    const response = await API.sendRequest('UserRole', 'DELETE', {
      UserId: item.row.data.UserId,
      RoleId: data.key,
    });

    if (response) {
      const usersRest = users.filter((u) => u.UserId !== item.row.data.UserId);

      setUsers(usersRest);
    } else {
      setErrorSnack(true);
      setOpenSnack(true);
    }
    setRemoving(false);
  };

  function cellRender({ data }) {
    return (
      <BoxAvatarName>
        <Avatar
          contact={data}
          h={24}
          w={24}
          style={{ marginLeft: 0, marginRight: 0 }}
        />
        <Name>{data.FullName || data.Username}</Name>
      </BoxAvatarName>
    );
  }

  const handleCheckboxSchedule = (key, value) => {
    const areasSchedules = areas.map((area) => {
      if (area.AreaId === key.AreaId) {
        area.EditSchedule = value;
      }
      return area;
    });
    setAreas(areasSchedules);
  };

  const handleCheckboxContact = (key, value) => {
    const groupsContacts = groups.map((group) => {
      if (group.GroupId === key.GroupId) {
        // change AreaId for GroupId
        group.EditContact = value;
      }
      return group;
    });
    setGroups(groupsContacts);
  };

  function cellCheckboxSchedule(dataSchedule) {
    return (
      <CellCheckbox>
        <CheckBox
          activeStateEnabled
          value={dataSchedule.key.EditSchedule}
          onValueChanged={(e) =>
            handleCheckboxSchedule(dataSchedule.key, e.value)
          }
        />
      </CellCheckbox>
    );
  }

  function cellCheckboxContact(dataContact) {
    return (
      <CellCheckbox>
        <CheckBox
          value={dataContact.key.EditContact}
          onValueChanged={(e) =>
            handleCheckboxContact(dataContact.key, e.value)
          }
        />
      </CellCheckbox>
    );
  }

  async function handleSaveAreas() {
    setLoadingSave(true);
    try {
      await API.sendRequest('Role/AreaSave', 'post', {
        RoleId: data.key,
        Areas: [...areas],
      });
      setError('Area permissions saved');
    } catch (e) {
      setError(e.message);
    }
    setLoadingSave(false);
  }

  const handleUserOpen = (event) => {
    event.preventDefault();

    setUserAnchorEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setUserAnchorEl(null);
  };

  async function handleSaveGroups() {
    setLoadingSaveGroups(true);
    try {
      const groupsFormatted = groups.map((group) => ({
        GroupId: group.GroupId,
        EditContact: group.EditContact,
      }));
      await API.sendRequest('Role/GroupSave', 'post', {
        RoleId: data.key,
        Groups: [...groupsFormatted],
      });
      setError('Group permissions saved');
    } catch (e) {
      setError(e.message);
    }
    setLoadingSaveGroups(false);
  }

  return (
    <Container>
      <Message
        message={error}
        variant="success"
        open={!!error}
        handleClose={handleMessageClose}
      />
      <LoadPanel
        visible={removing}
        message="Removing..."
        shadingColor="rgba(255,255,255,0.4)"
        closeOnOutsideClick={false}
      />
      <Content>
        <ColumnDivider>
          <BoxSubtitle>
            <Subtitle>USERS IN THIS ROLE</Subtitle>
            <IconButton aria-label="plus" size="small" onClick={handleUserOpen}>
              <AddBoxOutlinedIcon />
            </IconButton>
          </BoxSubtitle>
          <DataGridCustom
            dataSource={users}
            showBorders
            showRowLines
            hoverStateEnabled
          >
            <Scrolling mode="virtual" />
            <HeaderFilter visible allowSearch />
            <Column caption="FullName" cellRender={cellRender} />
            <Column dataField="Username" />
            <Column
              caption="Remove"
              type="buttons"
              width={100}
              buttons={[
                {
                  hint: 'Remove',
                  icon: 'trash',
                  onClick: onDeleteClick,
                },
              ]}
            />
          </DataGridCustom>
        </ColumnDivider>

        <ColumnDivider>
          <BoxSubtitle>
            <Subtitle>AREA (For Adjust Schedule on Mobile)</Subtitle>
          </BoxSubtitle>
          <DataGridCustom dataSource={areas} showBorders showRowLines>
            <Column dataField="AreaDesc" />
            <Column
              width={100}
              caption="Edit Schedule"
              cellRender={cellCheckboxSchedule}
            />
          </DataGridCustom>
          <WrapperButtons>
            <ButtonCustom
              variant="outlined"
              color="default"
              onClick={handleSaveAreas}
            >
              {loadingSave ? <CircularProgress size={22} /> : 'SAVE'}
            </ButtonCustom>
            <ButtonCustom
              variant="outlined"
              color="secondary"
              onClick={loadDataAreas}
            >
              CANCEL
            </ButtonCustom>
          </WrapperButtons>
        </ColumnDivider>

        <ColumnDivider>
          <BoxSubtitle>
            <Subtitle>
              GROUP (For Edit Contacts belonging to these groups)
            </Subtitle>
          </BoxSubtitle>
          <DataGridCustom dataSource={groups} showBorders showRowLines>
            <Scrolling mode="virtual" />
            <Column dataField="GroupDesc" caption="Group Desc" />
            <Column
              width={100}
              caption="Edit Contact"
              cellRender={cellCheckboxContact}
            />
          </DataGridCustom>
          <WrapperButtons>
            <ButtonCustom
              variant="outlined"
              color="default"
              onClick={handleSaveGroups}
            >
              {loadingSaveGroups ? <CircularProgress size={22} /> : 'SAVE'}
            </ButtonCustom>
            <ButtonCustom
              variant="outlined"
              color="secondary"
              onClick={loadDataGroups}
            >
              CANCEL
            </ButtonCustom>
          </WrapperButtons>
        </ColumnDivider>
      </Content>

      {userAnchorEl && (
        <UsersPopup
          anchorEl={userAnchorEl}
          handleClose={handleUserClose}
          title="Select User"
          users={users}
          setUsers={setUsers}
          roleId={data.key}
          setErrorSnack={setErrorSnack}
          handleClickSnack={handleClickSnack}
        />
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        {errorSnack ? (
          <Alert onClose={handleCloseSnack} severity="error">
            Error during operation. Please try again!
          </Alert>
        ) : (
          <Alert onClose={handleCloseSnack} severity="success">
            User add success!
          </Alert>
        )}
      </Snackbar>
    </Container>
  );
}
