import styled from 'styled-components';
import loginImg from '../../assets/login/image.png';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 80vh;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 450px;
  width: 800px;
  background-color: #fff;
  box-shadow: 6px 6px rgba(0, 0, 0, 0.1);
`;

export const Logo = styled.img`
  height: 150px;
`;

export const SideImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 450px;
  background-image: url(${loginImg});
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  margin-left: 20px;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 90%;
`;

export const AppName = styled.div`
  color: #0569ba;
  /* font-weight: bold; */
  font-size: 1.6rem;
`;

export const ButtonContainer = styled.div`
  width: 90%;
  margin-top: 60px;
  #loginButton {
    border-width: 2px;
  }
  /* #loginButton {
    background-color: #0063a5;
    transition: 0.2s background-color;
  }

  #loginButton.dx-state-hover {
    background-color: #003558;
  } */
`;

export const FormFields = styled.div`
  width: 90%;
`;

export const FormFooter = styled.footer`
  display: flex;
  align-items: center;
  margin-top: 40px;
  width: 100%;
`;

export const AzureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 90%;
`;

export const AzureSpanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    color: #4d4d4d;
    font-size: 0.69rem;
  }
`;

export const AzureName = styled.div`
  color: #0177d1;
  font-size: 0.95rem;
`;
