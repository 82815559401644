import React from 'react';

export function TRHeaderLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="31.77"
      viewBox="0 0 997.672 792.405"
    >
      <g fill="#fff" fillRule="evenodd" data-name="4">
        <path
          d="M36749.531 17053.945q-175.219-176.81-350.422-353.615c-82.594-89.7-126.914-212.594-16.883-314.109 107.594-73.244 182.156-40.619 267.328 43.895 77.531 76.93 155.867 153.049 233.18 230.186 14.578 14.635 33.789 26.061 54.133 15.523 16.836-8.707 32.5-23.766 45.445-37.334l43-42.99a38.647 38.647 0 10-21.2-19.709c-18.8 17.143-50.484 52.094-68.937 65.141-12.141 8.568-17.3 9.438-29.031-.059-5.516-4.459-10.8-10.3-16.211-15.635-79.5-78.367-153.57-156.752-233.07-235.125-44.555-41.687-74.055-67.719-131.641-75.145-85.352-8.055-143.352 18.668-199.07 82.543-68.6 78.654-49.367 200.039 8.8 279.033 12.2 16.564 25.078 31.188 43.586 49.553q173.355 172.069 346.734 344.148 12.2 12.4 24.383 24.816c13.664 10.037 17.1 10.916 34.242 10.916 17.133-4.592 22.617-8.75 35.484-21.187l41.773-40.334a38.593 38.593 0 10-22.57-21.332q-13.992 14.013-28.016 28.029-8.062 8.068-16.133 16.121c-4.7 3.848-9.281 8.75-15.8 8.016-9.852-1.115-11.047-3.607-19.555-11.725q-4.791-4.813-9.549-9.621zm124.656-63.846a17.97 17.97 0 11-17.977 17.971 17.969 17.969 0 0117.978-17.97zm164.9-412.746a17.97 17.97 0 1117.969-17.965 17.965 17.965 0 01-17.97 17.966z"
          data-name="Caminho 1154"
          transform="translate(-36282.438 -16313.573)"
        />
        <path
          d="M38591.641 16720.355q-107.66 107.089-215.352 214.172c-6.906 6.873-22.344 24.994-31.07 24.092-7.023-.723-29.2-21.244-35.594-27.639-72.172-72.186-143.562-143.379-215.766-215.58l-.156-.453a38.614 38.614 0 10-24.07 23.982l1.984.246q114.152 113.247 228.313 226.492c13.2 13.5 21.539 23.393 41.9 24.08 27.063.924 46.273-24.219 63.656-41.607 60.648-60.643 124.18-120.762 183.836-182.309 32.32-33.328 55.719-58.982 81.891-98.139 66.547-99.582 19.758-249.391-81.266-298.2-48.039-22.973-98.391-30.158-151.648-15.959-49.586 13.211-82.75 36.521-119.867 70.119-18.508 16.762-35.656 33.992-55.414 53.75a38.732 38.732 0 1021.281 21.971l46.406-46.4c35.555-31.439 52.57-47.891 98.992-65.24 52.266-15.084 86.2-17.77 135.008 4 74.953 38.453 118.2 121.973 102.422 204.221-8 29.639-11.945 39-26.555 66-15.272 22.616-29.494 38.962-48.931 58.401zm-516.508-18.01a17.969 17.969 0 11-17.969-17.971 17.967 17.967 0 0117.969 17.972zm191.023-189.254a17.977 17.977 0 11-17.984-17.971 17.98 17.98 0 0117.984 17.972z"
          data-name="Caminho 1155"
          transform="translate(-37704.543 -16340.331)"
        />
      </g>
    </svg>
  );
}
