import {
  Button,
  LoadPanel,
  Popup,
  SelectBox,
  TextBox,
  Validator,
} from 'devextreme-react';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { CompareRule, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { array, func } from 'prop-types';
import {
  getUserApplicationById,
  saveOktaAppMember,
} from '../../../../../services/requests/useOktaApp';
import {
  FieldRow,
  FieldSet,
  GroupContainer,
  WrapperButtons,
  WrapperRow,
} from './styles';

const PopupAddAppMember = forwardRef(
  ({ onClose, oktaAppList, adUserList }, ref) => {
    const [applicationDraft, setApplicationDraft] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        setPopupData: (data) => {
          setData(data);
        },
      }),
      []
    );

    const handleClose = () => {
      onClose();
      setApplicationDraft(null);
      setData(null);
      setShowPassword(false);
      setShowRepeatPassword(false);
    };

    async function getUserAppData() {
      if (data?.data?.OktaAppId && data?.data?.OktaAppId) {
        const userAppData = await getUserApplicationById(
          data?.data?.OktaAppId,
          data?.data?.UserId
        );
        setApplicationDraft(userAppData?.data);
      }
    }

    useEffect(() => {
      if (data?.type === 'EDIT') {
        getUserAppData();
      } else if (data?.data) {
        setApplicationDraft({
          UserId: data?.data?.UserId || undefined,
          Username: data?.data?.Username || undefined,
          Password: data?.data?.Password || undefined,
          OktaAppId: data?.data?.OktaAppId || undefined,
          OktaApplicationId: data?.data?.OktaApplicationId || undefined,
        });
      }
      // eslint-disable-next-line
    }, [data]);

    const handleSaveApp = useCallback(
      async (e) => {
        e.preventDefault();
        setIsSaving(true);

        const dataToSend = {
          ...applicationDraft,
          OktaApplicationId:
            data?.type === 'EDIT'
              ? data?.data?.OktaApplicationId
              : applicationDraft.OktaApplicationId,
        };

        delete dataToSend.RepeatPassword;

        try {
          const response = await saveOktaAppMember(dataToSend);
          if (response.status !== 200) {
            throw new Error(response.message);
          }
          notify('Application user saved successfully', 'success', 5000);
          data.refetch();
          setIsSaving(false);
          handleClose();
        } catch (e) {
          setIsSaving(false);
          notify(`Error: ${e.message}`, 'error', 5000);
        }
      },
      // eslint-disable-next-line
      [applicationDraft]
    );

    return (
      data?.visible && (
        <>
          <LoadPanel
            visible={isSaving}
            message={isSaving ? 'Saving...' : 'Loading...'}
          />
          <Popup
            title="User Application"
            visible={data?.visible}
            onHiding={handleClose}
            onHidden={handleClose}
            width={600}
            height={408}
            dragEnabled={false}
            showCloseButton
          >
            <form onSubmit={handleSaveApp}>
              <GroupContainer>
                <WrapperRow
                  flexDirection={
                    data?.type === 'APP' ? 'column' : 'column-reverse'
                  }
                >
                  <FieldRow>
                    <FieldSet $width="49%">
                      <label className="label">Application:</label>
                      <SelectBox
                        width="430px"
                        dataSource={oktaAppList}
                        displayExpr="OktaAppLabel"
                        valueExpr="OktaAppId"
                        onValueChanged={(e) =>
                          setApplicationDraft((prevState) => ({
                            ...prevState,
                            OktaAppId: e.value,
                            OktaApplicationId: oktaAppList?.find(
                              (item) => item.OktaAppId === e.value
                            )?.OktaApplicationId,
                          }))
                        }
                        searchEnabled
                        searchExpr="OktaAppLabel"
                        value={applicationDraft?.OktaAppId}
                        readOnly={data?.type === 'APP' || data?.type === 'EDIT'}
                      >
                        <Validator>
                          <RequiredRule message="Application is required" />
                        </Validator>
                      </SelectBox>
                    </FieldSet>
                  </FieldRow>
                  <FieldRow>
                    <FieldSet $width="49%">
                      <label className="label">Employee:</label>
                      <SelectBox
                        width="430px"
                        dataSource={adUserList}
                        displayExpr="DisplayName"
                        valueExpr="UserId"
                        onValueChanged={(e) =>
                          setApplicationDraft((prevState) => ({
                            ...prevState,
                            UserId: e.value,
                            Username: adUserList?.find(
                              (item) => item.UserId === e.value
                            )?.Username,
                          }))
                        }
                        searchEnabled
                        searchExpr="DisplayName"
                        value={applicationDraft?.UserId}
                        readOnly={
                          data?.type === 'MEMBER' || data?.type === 'EDIT'
                        }
                      >
                        {' '}
                        <Validator>
                          <RequiredRule message="Employee is required" />
                        </Validator>
                      </SelectBox>
                    </FieldSet>
                  </FieldRow>
                </WrapperRow>
                <FieldRow>
                  <FieldSet $width="49%">
                    <label className="label">Username:</label>
                    <TextBox
                      width="430px"
                      value={applicationDraft?.Username}
                      onValueChanged={(e) =>
                        setApplicationDraft((prevState) => ({
                          ...prevState,
                          Username: e.value,
                        }))
                      }
                    >
                      <Validator>
                        <RequiredRule message="Username is required" />
                      </Validator>
                    </TextBox>
                  </FieldSet>
                </FieldRow>
                <FieldRow>
                  <FieldSet $width="49%">
                    <label className="label">Password:</label>
                    <TextBox
                      width="378px"
                      value={applicationDraft?.Password}
                      mode={showPassword ? 'text' : 'password'}
                      inputAttrs={{ autoComplete: 'off' }}
                      onValueChanged={(e) =>
                        setApplicationDraft((prevState) => ({
                          ...prevState,
                          Password: e.value,
                        }))
                      }
                    >
                      <Validator>
                        <RequiredRule message="Password is required" />
                      </Validator>
                    </TextBox>
                    <Button
                      icon={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
                      stylingMode="outlined"
                      onClick={() => {
                        setShowPassword((prevState) => !prevState);
                      }}
                    />
                  </FieldSet>
                </FieldRow>
                <FieldRow>
                  <FieldSet $width="49%">
                    <label className="label">Repeat Password:</label>
                    <TextBox
                      width="378px"
                      value={applicationDraft?.RepeatPassword}
                      mode={showRepeatPassword ? 'text' : 'password'}
                      inputAttrs={{ autoComplete: 'off' }}
                      onValueChanged={(e) =>
                        setApplicationDraft((prevState) => ({
                          ...prevState,
                          RepeatPassword: e.value,
                        }))
                      }
                    >
                      <Validator>
                        <RequiredRule message="Repeat Password is required" />
                        <CompareRule
                          message="Passwords do not match"
                          comparisonTarget={() => applicationDraft?.Password}
                        />
                      </Validator>
                    </TextBox>
                    <Button
                      icon={
                        showRepeatPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                      }
                      stylingMode="outlined"
                      onClick={() => {
                        setShowRepeatPassword((prevState) => !prevState);
                      }}
                    />
                  </FieldSet>
                </FieldRow>

                <WrapperButtons>
                  <Button
                    text="OK"
                    type="success"
                    useSubmitBehavior
                    width={100}
                  />
                  <Button
                    text="Cancel"
                    type="normal"
                    onClick={handleClose}
                    width={100}
                  />
                </WrapperButtons>
              </GroupContainer>
            </form>
          </Popup>
        </>
      )
    );
  }
);

export default PopupAddAppMember;

PopupAddAppMember.propTypes = {
  onClose: func.isRequired,
  oktaAppList: array,
  adUserList: array,
};

PopupAddAppMember.defaultProps = {
  oktaAppList: [],
  adUserList: [],
};
