import React from 'react';

export function TRIconSquareLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      data-name="3"
      viewBox="0 0 1000 1000"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.179"
          x2="0.821"
          y1="0.117"
          y2="0.883"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0c569d" />
          <stop offset="0.078" stopColor="#0766b7" />
          <stop offset="0.2" stopColor="#0177d1" />
          <stop offset="1" stopColor="#0c569d" />
        </linearGradient>
      </defs>
      <g>
        <rect
          width="1000"
          height="1000"
          fill="url(#linear-gradient)"
          data-name="Retângulo 336"
          rx="172"
        />
        <g data-name="Grupo 967" transform="translate(0 -18)">
          <g fill="#fff" fillRule="evenodd" data-name="Grupo 969">
            <path
              d="M36653.039 16901.006q-139.008-140.282-278.031-280.564c-65.531-71.176-100.7-168.684-13.4-249.228 85.383-58.115 144.531-32.23 212.105 34.825 61.516 61.041 123.668 121.436 185.016 182.637 11.574 11.613 26.816 20.68 42.957 12.318 13.355-6.908 25.781-18.857 36.059-29.623l34.109-34.113a30.6 30.6 0 10-16.836-15.629c-14.9 13.6-40.043 41.332-54.684 51.68-9.633 6.8-13.73 7.494-23.043-.043-4.371-3.543-8.562-8.172-12.859-12.406-63.066-62.184-121.844-124.375-184.926-186.558-35.348-33.075-58.754-53.731-104.437-59.622-67.723-6.39-113.742 14.812-157.945 65.492-54.441 62.406-39.176 158.719 6.977 221.395 9.672 13.145 19.895 24.748 34.574 39.316q137.561 136.523 275.117 273.055 9.674 9.85 19.344 19.7c10.836 7.965 13.566 8.658 27.168 8.658 13.6-3.643 17.945-6.943 28.16-16.812l33.137-32a30.663 30.663 0 10-17.9-16.922q-11.127 11.118-22.242 22.24-6.393 6.4-12.793 12.791c-3.727 3.051-7.363 6.939-12.535 6.355-7.824-.881-8.773-2.859-15.531-9.3-2.514-2.548-5.045-5.097-7.561-7.642zm98.906-50.658a14.258 14.258 0 11-14.266 14.258 14.25 14.25 0 0114.266-14.258zm130.844-327.484a14.256 14.256 0 1114.254-14.258 14.259 14.259 0 01-14.254 14.257z"
              data-name="Caminho 1154"
              transform="translate(-36186.402 -16082.293)"
            />
            <path
              d="M38473.254 16643.074l-170.875 169.93c-5.473 5.455-17.727 19.83-24.652 19.115-5.562-.574-23.16-16.855-28.238-21.932-57.266-57.273-113.906-113.762-171.187-171.043l-.133-.359a30.626 30.626 0 10-19.1 19.027l1.574.193q90.574 89.848 181.16 179.7c10.457 10.711 17.082 18.561 33.23 19.107 21.48.732 36.723-19.215 50.516-33.01 48.117-48.119 98.527-95.82 145.863-144.65 25.641-26.441 44.2-46.8 64.965-77.867 52.8-79.014 15.68-197.873-64.473-236.6-38.113-18.228-78.07-23.928-120.324-12.664-39.344 10.483-65.648 28.979-95.1 55.634-14.687 13.3-28.3 26.973-43.969 42.646a30.615 30.615 0 1016.895 17.434l36.816-36.814c28.207-24.941 41.7-38 78.535-51.762 41.469-11.968 68.387-14.1 107.121 3.169 59.465 30.511 93.781 96.778 81.262 162.04-6.348 23.516-9.48 30.936-21.07 52.357-12.105 17.959-23.39 30.923-38.816 46.349zm-409.824-14.291a14.256 14.256 0 11-14.25-14.258 14.253 14.253 0 0114.25 14.258zm151.563-150.16a14.26 14.26 0 11-14.258-14.258 14.258 14.258 0 0114.257 14.258z"
              data-name="Caminho 1155"
              transform="translate(-37671.469 -16114.963)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
