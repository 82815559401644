import { useQuery } from 'react-query';
import API from '../API';

async function getHospitals() {
  const { data } = await API.sendRequest('Hospital', 'GET');

  return data.map((hospital) => ({
    ...hospital,
    AutoSend: false,
  }));
}

export default function useGetHospitals() {
  return useQuery('hospitals', getHospitals, {
    refetchOnWindowFocus: false,
  });
}
