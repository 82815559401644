import React, { useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { bool, func, object } from 'prop-types';

import { Container, LabelContainer, Text } from './styles';

function ConfirmationPopup({
  visible,
  setVisible,
  setDraftStatus,
  draftData,
  setDraftData,
  saveDraftData,
}) {
  const [loading, setLoading] = useState(false);

  const buttonsOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        handleConfirm();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setDraftStatus(1);
        setVisible(false);
      },
    },
  ];

  const handleConfirm = () => {
    setLoading(true);
    try {
      let draftCopy = draftData;
      draftCopy.DraftStatusId = 2;
      saveDraftData(draftCopy);
      notify('Draft released with success.', 'success', 2000);
      setLoading(false);
      setVisible(false);
    } catch (e) {
      setLoading(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  return (
    <Popup
      visible={visible}
      onHidden={() => setVisible(false)}
      width={420}
      height={250}
      showTitle={true}
      title="Warning"
      showCloseButton={false}
      disabled={loading}
    >
      <Container>
        <LabelContainer>
          <Text>
            After this month&apos;s schedule is released, it will be visible on
            mobile devices and cannot be undone.
          </Text>
          <Text bold>Are you sure you want to proceed?</Text>
        </LabelContainer>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

export default ConfirmationPopup;

ConfirmationPopup.propTypes = {
  visible: bool.isRequired,
  setVisible: func.isRequired,
  setDraftStatus: func.isRequired,
  draftData: object,
  setDraftData: func.isRequired,
  saveDraftData: func.isRequired,
};

ConfirmationPopup.defaultProps = {
  draftData: {},
};
