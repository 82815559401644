import { LoadPanel, SelectBox } from 'devextreme-react';
import TabPanel, { Item as Tab } from 'devextreme-react/tab-panel';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react';
import API from '../../services/API';
import useStyles, {
  Header,
  HeaderButtons,
  Title,
  TitleWrapper,
} from './styles';
import WorkDayGrid from './WorkDayGrid';

function StaffOn() {
  async function fetchAreas() {
    const { data } = await API.sendRequest('Area', 'GET');
    setAreas(data);
  }

  async function fetchContactWCI({ areaId }) {
    setIsLoading(true);
    try {
      const { data: yesterdayData } = await API.sendRequest(
        `ContactWCI/ListWeb/${areaId}/0`
      );
      const { data: todayData } = await API.sendRequest(
        `ContactWCI/ListWeb/${areaId}/1`
      );
      const { data: tomorrowData } = await API.sendRequest(
        `ContactWCI/ListWeb/${areaId}/2`
      );

      setYesterdayList(yesterdayData);
      setTomorrowList(tomorrowData);
      setTodayList(todayData);
    } catch (e) {
      notify('Error loading Staff On data', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleAreaChanged(e) {
    setCurrentArea(e.value);
    fetchContactWCI({ areaId: e.value });
  }

  const classes = useStyles();
  const [areas, setAreas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [todayList, setTodayList] = useState([]);
  const [tomorrowList, setTomorrowList] = useState([]);
  const [yesterdayList, setYesterdayList] = useState([]);
  const [currentArea, setCurrentArea] = useState(0);

  useEffect(() => {
    fetchAreas();
    fetchContactWCI({ areaId: 0 });
  }, []);

  return (
    <div className={classes.divGridContainer}>
      <Header>
        <TitleWrapper>
          <Title>Queries/Staff On</Title>
        </TitleWrapper>
        <HeaderButtons>
          <SelectBox
            dataSource={areas}
            displayExpr="AreaDesc"
            valueExpr="AreaId"
            value={currentArea}
            onValueChanged={handleAreaChanged}
          />
        </HeaderButtons>
      </Header>

      <TabPanel
        hoverStateEnabled={false}
        activeStateEnabled={false}
        focusStateEnabled={false}
        height="100%"
      >
        <Tab title="Today" badge={!isLoading && `${todayList?.length}`}>
          <LoadPanel visible={isLoading} />
          <WorkDayGrid workingDay={1} dataSource={todayList} />
        </Tab>
        <Tab title="Yesterday" badge={!isLoading && `${yesterdayList?.length}`}>
          <LoadPanel visible={isLoading} />
          <WorkDayGrid workingDay={0} dataSource={yesterdayList} />
        </Tab>
        <Tab title="Tomorrow" badge={!isLoading && `${tomorrowList?.length}`}>
          <LoadPanel visible={isLoading} />
          <WorkDayGrid workingDay={2} dataSource={tomorrowList} />
        </Tab>
      </TabPanel>
    </div>
  );
}

export default StaffOn;
