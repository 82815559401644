import { DataGrid, Popup } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { bool, func } from 'prop-types';
import { Column } from 'devextreme-react/data-grid';
import API from '../../../../services/API';

// import { Container } from './styles';

function MembersPopup({ isVisible, setIsVisible }) {
  const [membersList, setMembersList] = useState();

  useEffect(() => {
    let isSubscribed = true;
    loadData('PrivateContact/ListMembers').then((membersList) => {
      if (isSubscribed) {
        setMembersList(membersList);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  const loadData = async (uri) => {
    const res = await API.sendRequest(uri, 'GET');

    return res.data;
  };
  return (
    <Popup
      visible={isVisible}
      title="Members"
      width={350}
      height={400}
      onHiding={() => setIsVisible(false)}
    >
      <Container>
        <DataGrid showBorders dragEnabled={false} dataSource={membersList}>
          <Column dataField="DisplayName" caption="Name" />
        </DataGrid>
      </Container>
    </Popup>
  );
}

export default MembersPopup;

MembersPopup.propTypes = {
  isVisible: bool.isRequired,
  setIsVisible: func.isRequired,
};
