import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import pickTextColorBasedOnBgColor from '../../helpers/pickTextColorBasedOnBgColor';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

import {
  Column,
  DataGrid,
  Paging,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';
import useStyles from '../styles/styles';
import HistoryPopup from '../../components/popups/HistoryPopup';
import getChangeHistory from '../../utils/getChangeHistory';
import notify from 'devextreme/ui/notify';

const Groups = new CustomStore({
  key: 'GroupId',
  load: async () => {
    try {
      const { data } = await API.sendRequest(`Group`, 'get');
      return data;
    } catch (e) {
      throw new Error(e.message || 'Error getting groups');
    }
  },
  insert: async (data) => {
    try {
      if (!data?.Color) {
        throw new Error('Color is required');
      }
      const result = await API.sendRequest(`Group`, 'post', data);
      if (result?.message) {
        throw new Error(
          result.message.includes('duplicate')
            ? 'The [Group Code] already exists for this area'
            : result.message
        );
      }
    } catch (e) {
      throw new Error(e.message || 'Error inserting group');
    }
  },
  update: async (id, data) => {
    try {
      if (!data?.Color) {
        throw new Error('Color is required');
      }
      const result = await API.sendRequest(`Group`, 'post', data);
      if (result?.message) {
        throw new Error(
          result.message.includes('duplicate')
            ? 'The [Group Code] already exists for this area'
            : result.message
        );
      }
    } catch (e) {
      throw new Error(e.message || 'Error updating group');
    }
  },
  remove: async (id) => {
    try {
      await API.sendRequest(`Group/${id}`, 'delete');
    } catch (e) {
      throw new Error(e.message || 'Error deleting group');
    }
  },
  errorHandler: (error) => {
    notify(error?.message || 'Error in operation', 'error');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const Group = () => {
  const [areas, setAreas] = useState(null);
  const [groupChangeHistory, setGroupChangeHistory] = useState(null);
  const [historyPopupVisible, setHistoryPopupVisible] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    async function init() {
      setAreas((await API.sendRequest(`Area/ScheduleOnly`, 'get')).data);
    }

    init();
  }, []);

  const colorCellRender = (data) => (
    <div
      className={classes.colorCellRender}
      style={{
        backgroundColor: data.value,
        color: pickTextColorBasedOnBgColor(data.value, '#fff', '#000'),
      }}
    >
      {data.value}
    </div>
  );

  const handleShowChangeHistory = async (e) => {
    await getChangeHistory('Group', e.row.data.GroupId).then((ret) => {
      if (ret) {
        setGroupChangeHistory(ret);
        setHistoryPopupVisible(true);
      }
    });
  };

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  const buttonsList = [];

  if (ACL.Admin) {
    buttonsList.push({
      hint: 'Show Change History',
      icon: 'fa fa-history',
      onClick: handleShowChangeHistory,
      visible: ACL?.Admin,
    });
  }

  if (ACL.Update) {
    buttonsList.push({ name: 'edit', visible: ACL?.Update });
  }
  if (ACL.Delete) {
    buttonsList.push({ name: 'delete', visible: ACL?.Delete });
  }

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={Groups}
        // keyExpr={'GroupId'}
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onRowUpdating={onRowUpdating}
        onToolbarPreparing={onToolbarBreadCrumb}
        focusedRowEnabled
      >
        <Paging enabled={false} />

        <Editing
          mode="form"
          useIcons
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        />
        <Column dataField="GroupCode" width={100} />
        <Column dataField="GroupName" />
        <Column
          width={100}
          dataField="Color"
          editorType="dxColorBox"
          cellRender={colorCellRender}
          cssClass={classes.colorCellRenderTd}
        />
        <Column dataField="AreaId" width={150} caption="Area">
          <Lookup
            dataSource={areas}
            displayExpr="AreaDesc"
            valueExpr="AreaId"
          />
        </Column>
        <Column dataField="ScheduleControlOrder" caption="Order" width={80} />
        <Column dataField="Active" width={80} />
        <Column caption="Actions" type="buttons" buttons={buttonsList} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
      {historyPopupVisible && (
        <HistoryPopup
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={groupChangeHistory}
        />
      )}
    </div>
  );
};

export default Group;
