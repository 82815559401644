import React, { useState, useEffect } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import { func, bool } from 'prop-types';
import API from '../../../../services/API';
import {
  DataGrid,
  Paging,
  Column,
  Selection,
} from 'devextreme-react/data-grid';
// import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';

import { ContentContainer, GridContainer } from '../styles';

export default function ImportFromAdPopup({
  popupImportVisible,
  setPopupImportVisible,
  updateAdUsersGrid,
}) {
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [importButtonDisabled, setImportButtonDisabled] = useState(true);
  const [notInSqlUsers, setNotInSqlUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const bottomToolbarButtons = [
    {
      text: 'Import',
      type: 'success',
      onClick: () => {
        handleImportOnClick();
      },
      disabled: importButtonDisabled,
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setPopupImportVisible(false);
      },
    },
  ];

  useEffect(() => {
    selectedRowsData.length === 0
      ? setImportButtonDisabled(true)
      : setImportButtonDisabled(false);
  }, [selectedRowsData]);

  const onPopupShowing = async () => {
    setLoading(true);
    const { data } = await API.sendRequest(`ADUser/ListNotInSQL`, 'get');
    setNotInSqlUsers(data);
    setLoading(false);
  };

  const handleImportOnClick = async () => {
    try {
      const selectedUsernames = selectedRowsData.map((data) => {
        return data.Username;
      });
      const response = await API.sendRequest(
        `ADUser/ImportFromAD`,
        'post',
        selectedUsernames
      );

      if (response.status === 200) {
        notify({ message: 'Import completed with success!' }, 'success', 1000);
        updateAdUsersGrid();
        setPopupImportVisible(false);
      } else {
        notify({ message: response.message }, 'error', 1000);
      }
    } catch (err) {
      notify({ message: err.message }, 'error', 1000);
    }
  };

  const handleSelectionChanged = (e) =>
    setSelectedRowsData(e.component.getSelectedRowsData);

  return (
    <Popup
      onHidden={() => {
        setPopupImportVisible(false);
      }}
      visible={popupImportVisible}
      title="Import from AD"
      width={'90%'}
      height={'80%'}
      onShowing={onPopupShowing}
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={loading}
        message="Loading"
        closeOnOutsideClick={false}
      />
      <ContentContainer>
        <GridContainer>
          {notInSqlUsers.length > 0 && (
            <DataGrid
              id={'gridContainer'}
              dataSource={notInSqlUsers}
              keyExpr={'Username'}
              allowColumnReordering={true}
              showBorders={true}
              rowAlternationEnabled={true}
              focusedRowEnabled={true}
              showRowLines={true}
              headerFilter={{ visible: true }}
              filterPanel={{ visible: true }}
              filterRow={{ visible: true }}
              onSelectionChanged={handleSelectionChanged}
              height={'100%'}
              width={'100%'}
            >
              <Selection mode="multiple" showCheckBoxesMode="always" />

              <Paging enabled={true} />

              <Column dataField={'DisplayName'} caption={'Display Name'} />
              <Column
                dataField={'Username'}
                caption={'User Name'}
                width={100}
              />
              <Column dataField={'OU'} caption={'OU'} />
              <Column dataField={'Description'} caption={'Description'} />
              <Column dataField={'Email'} caption={'Email'} />
            </DataGrid>
          )}
        </GridContainer>
      </ContentContainer>
      {bottomToolbarButtons.map((buttonOptions, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOptions }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

ImportFromAdPopup.propTypes = {
  popupImportVisible: bool,
  setPopupImportVisible: func,
  updateAdUsersGrid: func,
};

ImportFromAdPopup.defaultProps = {
  popupImportVisible: false,
  setPopupImportVisible: () => {},
  updateAdUsersGrid: () => {},
};
