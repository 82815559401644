import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../services/API';

export default function usePermissions(secondaryLocation) {
  const location = useLocation();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    async function selectPermissions(secondaryLocation) {
      let path = '';
      if (!secondaryLocation) {
        path = location.pathname;
        if (path === '/') {
          path = '/contacts';
        }
      } else {
        path = secondaryLocation;
      }

      const { data } = await API.sendRequest(
        `Menu/AccessControl${path}`,
        'get'
      );

      setPermissions(data);
    }
    selectPermissions(secondaryLocation);

    // eslint-disable-next-line
  }, []);

  return permissions[0];
}
