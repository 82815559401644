import usePermissions from '../../../hooks/usePermissions';
import { node } from 'prop-types';
import React, { createContext, useContext, useMemo, useRef } from 'react';

const OktaAppContext = createContext();

function OktaAppProvider({ children }) {
  const ACL = usePermissions();
  const popupApplicationRef = useRef({
    visible: false,
    data: null,
  });
  const popupDeleteRef = useRef({
    visible: false,
    data: null,
    refetch: () => {},
  });
  const popupAddMembersRef = useRef({
    visible: false,
    data: null,
  });
  const popupAddAppMemberRef = useRef({
    visible: false,
    data: null,
    refetch: () => {},
  });

  const oktaAppsContextValues = useMemo(
    () => ({
      popupDeleteRef,
      popupAddMembersRef,
      popupApplicationRef,
      popupAddAppMemberRef,
      ACL,
    }),
    [
      popupDeleteRef,
      popupAddMembersRef,
      popupApplicationRef,
      popupAddAppMemberRef,
      ACL,
    ]
  );

  return (
    <OktaAppContext.Provider value={oktaAppsContextValues}>
      {children}
    </OktaAppContext.Provider>
  );
}

OktaAppProvider.propTypes = {
  children: node.isRequired,
};

const useOktaApp = () => useContext(OktaAppContext);

export { OktaAppProvider, useOktaApp };
