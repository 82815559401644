import styled from 'styled-components';

export const ItemTotal = styled.div`
  font-size: 14px;
  height: 34px;
  line-height: 34px;
`;

export const ItemTotalBold = styled.div`
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  font-weight: bold;
  background-color: #66ff99;
`;

// height: 34px;
