import { format, parseISO } from 'date-fns';

/* eslint-disable no-param-reassign */
export const { format: formatPrice } = new Intl.NumberFormat('pt-br', {
  style: 'currency',
  currency: 'BRL',
});

export function formatCurrency(input, blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  let inputVal = input.value;

  // don't validate empty input
  if (inputVal === '') {
    return '';
  }

  // original length
  const originalLen = inputVal.length;

  // initial caret position
  let caretPos = input.selectionStart;

  // check for decimal
  if (inputVal.indexOf(',') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimalPos = inputVal.indexOf(',');

    // split number by decimal point
    let leftSide = inputVal.substring(0, decimalPos);
    let rightSide = inputVal.substring(decimalPos);

    // add commas to left side of number
    leftSide = formatNumber(leftSide);

    // validate right side
    rightSide = formatNumber(rightSide);

    // On blur make sure 2 numbers after decimal
    if (blur === 'blur') {
      rightSide += '00';
    }

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, 2);

    // join number by .
    inputVal = `R$ ${leftSide},${rightSide}`;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    inputVal = formatNumber(inputVal);
    inputVal = `R$ ${inputVal}`;

    // final formatting
    if (blur === 'blur') {
      inputVal += ',00';
    }
  }

  // send updated string to input
  input.value = inputVal;

  // put caret back in the right position
  const updatedLen = inputVal.length;
  caretPos = updatedLen - originalLen + caretPos;
  input.setSelectionRange(caretPos, caretPos);
  return input.value;
}

function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
export function formatCPF(n) {
  // eslint-disable-next-line no-useless-escape
  return n.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
}
export function formatCNPJ(n) {
  // eslint-disable-next-line no-useless-escape
  return n.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ` ${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

export function formatPhone(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
}

export const dateTimeFormatter = (value) => {
  if (/^[/D]/.test(value)) {
    return value;
  }
  try {
    return format(parseISO(value), 'dd/MM/yyyy - HH:mm');
  } catch {
    return value;
  }
};
