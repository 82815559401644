import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config';
import Common from './Common';

export default class API {
  static async sendRequest(
    uri,
    method,
    data,
    blobRes = false,
    throwErrors = false,
    customOptions = {}
  ) {
    let response;
    const options = {
      method,
      data,
      blobRes,
    };
    try {
      if (Cookies.get('token')) {
        if (!Common.checkToken()) {
          throw new Error('Your session has expired.');
        }

        if (options.blobRes) {
          options.responseType = 'blob';
        }

        options.headers = {
          authorization: `Bearer ${Cookies.get('token')}`,
        };
      }

      if (customOptions.headers) {
        options.headers = { ...options.headers, ...customOptions.headers };
      }

      response = await axios.request(`${config.apiUrl}/${uri}`, options);

      if (
        response.status !== 200 &&
        response.data.ErrorMsg !== undefined &&
        response.data.ErrorMsg !== null &&
        response.data.ErrorMsg !== ''
      ) {
        return {
          data: response ? response.data : {},
          message: response.data.ErrorMsg,
          status: response.status,
        };
      } else {
        if (
          response.data.ErrorMsg !== undefined &&
          response.data.ErrorMsg !== null &&
          response.data.ErrorMsg !== ''
        ) {
          if (response.status !== 200) {
            throw new Error(JSON.stringify(response.data.ErrorMsg));
          }
          response = response.data;
        } else {
          response = response.data;
        }
      }

      return { data: response, status: 200 };
    } catch (e) {
      let message = 'Error on API';

      if (!e.response) {
        return {
          data: {},
          message: e.message,
          status: 400,
        };
      }

      if (
        window.location.hash.includes('login') &&
        e.response?.status === 401
      ) {
        return {
          data: e.response ? e.response.data : {},
          message,
          status: 500,
        };
      }

      options.data = {
        StatusCode: e.response.status,
        ErrorMessage: e.response.data.ErrorMsg
          ? e.response.data.ErrorMsg
          : JSON.stringify(e.response.data),
        Method: e.response.config.method,
        PageRoute: window.location.href,
        CreatedDate: new Date(),
        RequestData: JSON.stringify(e.response),
        UserId: '00000000-0000-0000-0000-000000000000',
      };
      options.method = 'POST';
      const logErrors = await axios.request(
        `${config.apiUrl}/LogErrors/Create`,
        options
      );
      console.error(`Error on API, code:${logErrors?.data?.Id}`);
      if (e.response) {
        if (e.response.data) {
          if (e.response.data.errors) {
            message = Object.values(e.response.data.errors)[0];
          }
          if (e.response.data.message) {
            message = e.response.data.message;
          }
        }
      } else if (e.message) {
        message = e.message;
      }

      if (throwErrors) {
        throw new Error(message);
      }

      return {
        data: e.response ? e.response.data : {},
        message:
          e.response.status === 400 &&
          e.response.data.message === 'Username or password is incorrect'
            ? e.response.data.message
            : `Error on API, code:${logErrors?.data?.Id}`,
        status: 500,
      };
    }
  }
}
