import React from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import {
  Column,
  DataGrid,
  Paging,
  Editing,
  FormItem,
} from 'devextreme-react/data-grid';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

const deptos = new CustomStore({
  key: 'DepartmentId',
  load: async () => {
    const data = (await API.sendRequest(`Department`, 'get')).data;
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`Department`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`Department`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`Department/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const Department = () => {
  const classes = useStyles();

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (!ACL.Admin) {
    return (
      <LoadPermissions>
        You don't have access to this page. For Administrators Only.
      </LoadPermissions>
    );
  }
  /*********************************/

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id={'gridContainer'}
        dataSource={deptos}
        //keyExpr={'DepartmentId'}
        allowColumnReordering
        onRowUpdating={onRowUpdating}
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />
        <Editing useIcons mode={'form'} allowUpdating={ACL.Update} />

        <Column
          dataField={'DepartmentDesc'}
          caption={'Department'}
          allowEditing={false}
        />
        <Column dataField={'ExchangeDB'}>
          <FormItem helpText="Make sure this Exchange Database exists. Otherwise the mailbox cannot be created" />
        </Column>
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default Department;
