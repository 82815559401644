import React from 'react';
import { shape, func, string } from 'prop-types';
import { useDrag } from 'react-dnd';
import Avatar from '../../../../components/contacts/Avatar';
import { WrapperContact, WrapperDataContact, Paragraph } from './styles';

export default function ItemDrag({
  data,
  handleAvatarClick,
  row,
  cell,
  handleDelete,
  acl,
}) {
  let opacity0;
  let isDragging0;
  let drag0;
  const [{ opacity, isDragging }, drag] = useDrag({
    item: {
      id: data.ContactScheduleId,
      type: 'CELL',
      cell,
      data,
      row,
    },
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }
      return handleDelete(item);
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: !!monitor.isDragging(),
    }),
  });

  if (acl.Update) {
    opacity0 = opacity;
    drag0 = drag;
    isDragging0 = isDragging;
  }

  const getCursor = () => {
    let cursor = 'default';
    if (acl.Update) {
      cursor = isDragging0 ? 'grabbing' : 'grab';
    }
    return cursor;
  };

  return (
    <WrapperContact ref={drag0} opacity={opacity0} cursor={getCursor}>
      <div>
        <Avatar
          contact={data}
          h={30}
          w={30}
          style={{ marginLeft: 0, marginRight: 0 }}
          onClick={() => handleAvatarClick(data)}
        />
      </div>
      <WrapperDataContact>
        <Paragraph bold>{data.FirstName}</Paragraph>
        <Paragraph>{data.LastName}</Paragraph>
      </WrapperDataContact>
    </WrapperContact>
  );
}

ItemDrag.propTypes = {
  data: shape({
    ContactScheduleId: string,
    FirstName: string,
    LastName: string,
  }).isRequired,
  handleAvatarClick: func.isRequired,
  row: shape.isRequired,
  cell: string.isRequired,
  handleDelete: func.isRequired,
};
