import { Button, Popup } from 'devextreme-react';
import { func } from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Container, WrapperButtons } from './styles';

const PopupDelete = forwardRef(({ onClose, onConfirm }, ref) => {
  const [data, setData] = useState(null);

  useImperativeHandle(
    ref,
    () => ({
      setPopupData: (data) => {
        setData(data);
      },
    }),
    []
  );

  const handleClose = () => {
    setData(null);
    onClose();
  };

  return (
    <Popup
      title="Delete"
      visible={data?.visible}
      onHiding={handleClose}
      onHidden={handleClose}
      width={600}
      height="fit-content"
      dragEnabled={false}
      showCloseButton
    >
      <Container>
        <span>{data?.data?.Message}</span>
        <WrapperButtons>
          <Button
            text="OK"
            type="success"
            onClick={() => onConfirm(data)}
            width={100}
          />
          <Button
            text="Cancel"
            type="normal"
            onClick={handleClose}
            width={100}
          />
        </WrapperButtons>
      </Container>
    </Popup>
  );
});

export default PopupDelete;
PopupDelete.propTypes = {
  onClose: func.isRequired,
  onConfirm: func.isRequired,
};
