import React, { useEffect, useState } from 'react';
import { Button, List, LoadPanel, SelectBox, Toolbar } from 'devextreme-react';
import { ItemDragging } from 'devextreme-react/list';
import API from '../../services/API';
import usePermissions from '../../hooks/usePermissions';

import {
  Container,
  LoadPermissions,
  Header,
  ListsContainer,
  TeamsListContainer,
  TeamListLabel,
  TeamContainer,
  NoTeamContainer,
} from './styles';
import { Item } from 'devextreme-react/toolbar';
import EditStaffTeam from './EditStaffTeam';
import notify from 'devextreme/ui/notify';
import generateUUID from '../../helpers/uuid';
import ConfirmPopup from './ConfirmPopup';
import getLoggedUser from '../../helpers/userFunctions';
import pickTextColorBasedOnBgColor from '../../helpers/pickTextColorBasedOnBgColor';

function StaffTeams() {
  const [areas, setAreas] = useState([]);
  const [currentAreaId, setCurrentAreaId] = useState(null);
  const [currentAreaName, setCurrentAreaName] = useState('');
  const [usersWithoutTeam, setUsersWithoutTeam] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [usersByTeam, setUsersByTeam] = useState([]);
  const [usersByTeamOriginal, setUsersByTeamOriginal] = useState([]);
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [editTeamVisible, setEditTeamVisible] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [wait, setWait] = useState(false);
  const [usersChanges, setUsersChanges] = useState([]);
  const [teamsChanges, setTeamsChanges] = useState([]);
  const [teamsToDelete, setTeamsToDelete] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState({
    visible: false,
    message: '',
    confirmFunction: () => {},
    closeFunction: () => {},
  });
  const [loggedUser, setLoggedUser] = useState({
    userId: '',
    isFullAccess: false,
  });

  useEffect(() => {
    let isSubscribed = true;

    API.sendRequest('Area/ListByUseTeams', 'GET', false).then((res) => {
      if (isSubscribed && res.status === 200) {
        setAreas(res.data);
      }
    });

    API.sendRequest(`USerRole/listroles/${getLoggedUser()}`, 'GET', false).then(
      (res) => {
        if (isSubscribed) {
          if (res.status === 200) {
            const fullAccessIndex = res.data.findIndex(
              (role) => role.RoleId === 1
            );
            setLoggedUser({
              userId: getLoggedUser(),
              isFullAccess: fullAccessIndex > -1,
            });
          } else {
            setLoggedUser({ userId: getLoggedUser(), isFullAccess: false });
          }
        }
      }
    );

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    if (loggedUser.userId && !loggedUser.isFullAccess) {
      API.sendRequest(
        `ADUser/ListComplete/${loggedUser.userId}`,
        'GET',
        false
      ).then((res) => {
        if (isSubscribed && res.status === 200) {
          const userAreaId = res.data.User.AreaId;
          const userArea = areas.find((area) => area.AreaId === userAreaId);

          if (userArea) {
            setCurrentAreaId(userArea.AreaId);
            setCurrentAreaName(userArea.AreaDesc);
          }
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [loggedUser, areas]);

  useEffect(() => {
    if (
      usersByTeam !== usersByTeamOriginal ||
      teamsToDelete.length > 0 ||
      teamsChanges.length > 0
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [
    usersByTeam,
    usersByTeamOriginal,
    usersChanges,
    teamsChanges,
    teamsToDelete,
  ]);

  useEffect(() => {
    let isSubscribed = true;
    setWait(true);

    if (currentAreaId && isSubscribed) {
      setUsersChanges([]);
      setTeamsToDelete([]);
      setTeamsChanges([]);
      updateTeams();
      updateUsers();
    }

    setWait(false);
    return () => {
      isSubscribed = false;
    };
  }, [currentAreaId]); //eslint-disable-line

  useEffect(() => {
    if (teams) {
      const teamsWithUsers = teams
        .map((team) => {
          const teamGroup = {
            TeamName: team.TeamName,
            TeamId: team.TeamId,
            TeamOrder: team.TeamOrder,
            Color: team.Color,
            Users: allUsers
              .filter((user) => user.TeamId === team.TeamId)
              .sort(orderUsers),
          };
          return teamGroup;
        })
        .sort(orderTeams);
      setUsersByTeam(teamsWithUsers);
      setUsersByTeamOriginal(teamsWithUsers);
    }
  }, [allUsers]); // eslint-disable-line

  useEffect(() => {
    usersByTeam.forEach((team) => {
      team.Users.forEach((user) => {
        const userChangeIndex = usersChanges.findIndex(
          (u) => u.UserId === user.UserId
        );
        setUsersChanges((prevState) => {
          if (userChangeIndex > -1) {
            prevState[userChangeIndex] = {
              UserId: user.UserId,
              TeamId: team.TeamId,
              UserTeamOrder: team.Users.findIndex(
                (teamUser) => teamUser.UserId === user.UserId
              ),
            };
          } else {
            prevState.push({
              UserId: user.UserId,
              TeamId: team.TeamId,
              UserTeamOrder: team.Users.findIndex(
                (teamUser) => teamUser.UserId === user.UserId
              ),
            });
          }
          return [...prevState];
        });
      });
    });

    setUsersChanges((prevState) => {
      if (prevState) {
        prevState.forEach((userChange) => {
          usersByTeam.forEach((team) => {
            const userIndex = team.Users.findIndex(
              (tUser) => tUser.UserId === userChange.UserId
            );
            if (userIndex === -1 && userChange.TeamId === team.TeamId) {
              userChange = { ...userChange, TeamId: null, UserTeamOrder: 0 };
              const index = prevState.findIndex(
                (uc) => uc.UserId === userChange.UserId
              );
              if (index > -1) {
                prevState[index] = userChange;
              } else {
                prevState.push(userChange);
              }
            }
          });
        });
      }
      return [...prevState];
    });
  }, [usersByTeam]); // eslint-disable-line

  function orderUsers(userA, userB) {
    if (userA.UserTeamOrder < userB.UserTeamOrder) {
      return -1;
    }
    if (userA.UserTeamOrder > userB.UserTeamOrder) {
      return 1;
    }
    return 0;
  }

  function updateTeams() {
    API.sendRequest(`Team/ListByArea/${currentAreaId}`, 'GET', false).then(
      (res) => {
        setTeams(res.data);
      }
    );
  }

  function orderTeams(teamA, teamB) {
    if (teamA.TeamOrder < teamB.TeamOrder) {
      return -1;
    }
    if (teamA.TeamOrder > teamB.TeamOrder) {
      return 1;
    }
    return 0;
  }

  function updateUsers() {
    API.sendRequest(`ADUser/ListByArea/${currentAreaId}`, 'GET', false).then(
      (res) => {
        setAllUsers(res.data);
        setUsersWithoutTeam(
          res.data.filter((user) => user.TeamId === null || user.TeamId === '')
        );
      }
    );
  }

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();

  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  const buttonsOptions = [
    {
      text: 'Create New Team',
      icon: 'plus',
      disabled: !currentAreaId,
      onClick: () => {
        handleAddTeamClick();
      },
    },
    {
      text: 'Save Changes',
      icon: 'save',
      disabled: saveDisabled,
      onClick: () => {
        handleSaveChanges();
      },
    },
    {
      text: 'Cancel Changes',
      icon: 'clearformat',
      disabled: saveDisabled,
      onClick: () => {
        handleCancelClick();
      },
    },
  ];

  function handleCancelClick() {
    setWait(true);
    setUsersChanges([]);
    setTeamsToDelete([]);
    setTeamsChanges([]);
    updateTeams();
    updateUsers();
    setWait(false);
  }

  function handleAddTeamClick() {
    let lastOrder = 0;

    if (teams.length > 0) {
      lastOrder = Math.max.apply(
        Math,
        teams.map((team) => team.TeamOrder)
      );
    }

    setCurrentTeam({
      TeamId: generateUUID(),
      TeamName: '',
      AreaId: currentAreaId,
      Color: '#FFFFFF',
      TeamOrder: lastOrder + 1,
    });
    setEditTeamVisible(true);
  }

  function handleEditTeam(teamId) {
    const teamObj = teams.find((team) => team.TeamId === teamId);
    const teamToEdit = {
      ...teamObj,
    };
    setCurrentTeam(teamToEdit);
    setEditTeamVisible(true);
  }

  async function addTeam(team) {
    const sameNameTeam = teams.find((t) => t.TeamName === team.TeamName);

    if (sameNameTeam && sameNameTeam.TeamId !== team.TeamId) {
      notify(
        `A group with ${team.TeamName} name already exists. Please, choose another name`,
        'error',
        2000
      );
      return;
    }

    setTeams((prevState) => {
      const teamIndex = prevState.findIndex((t) => t.TeamId === team.TeamId);
      if (teamIndex > -1) {
        prevState[teamIndex] = team;
      } else {
        prevState.push(team);
      }
      return [...prevState];
    });

    setUsersByTeam((prevState) => {
      const teamIndex = prevState.findIndex((t) => t.TeamId === team.TeamId);
      if (teamIndex > -1) {
        prevState[teamIndex] = {
          ...prevState[teamIndex],
          TeamName: team.TeamName,
          TeamId: team.TeamId,
          Color: team.Color,
          TeamOrder: team.TeamOrder,
        };
      } else {
        const newTeam = {
          TeamName: team.TeamName,
          TeamId: team.TeamId,
          Color: team.Color,
          TeamOrder: team.TeamOrder,
          Users: [],
        };
        prevState.push(newTeam);
      }
      return [...prevState].sort(orderTeams);
    });

    setTeamsChanges((prevState) => {
      if (team.TeamId) {
        const changeIndex = prevState.findIndex(
          (t) => t.TeamId === team.TeamId
        );
        if (changeIndex > -1) {
          prevState[changeIndex] = { ...prevState[changeIndex], ...team };
        } else {
          prevState.push(team);
        }
      } else {
        const changeIndex = prevState.findIndex(
          (t) => t.TeamName === team.TeamName
        );
        if (changeIndex > -1) {
          prevState[changeIndex] = { ...prevState[changeIndex], ...team };
        } else {
          prevState.push(team);
        }
      }
      return [...prevState];
    });

    notify(team.TeamId ? 'Team changed' : 'Team created', 'success', 2000);
    setEditTeamVisible(false);
  }

  async function deleteTeam(users, teamId) {
    setWait(true);
    if (users.length > 0) {
      users.forEach((user) => {
        setUsersWithoutTeam((prevState) => {
          user.TeamId = null;
          user.UserTeamOrder = 0;
          prevState.unshift(user);
          return [...prevState];
        });
        setUsersChanges((prevState) => {
          const changeIndex = prevState.findIndex(
            (u) => u.UserId === user.UserId
          );
          if (changeIndex > -1) {
            if (teamId) {
              prevState[changeIndex] = {
                UserId: user.UserId,
                TeamId: null,
                UserTeamOrder: 0,
              };
            } else {
              prevState.splice(changeIndex, 1);
            }
          } else if (teamId) {
            prevState.push({
              UserId: user.UserId,
              TeamId: null,
              UserTeamOrder: 0,
            });
          }
          return [...prevState];
        });
      });
    }

    setUsersByTeam((prevState) => {
      const teamIndex = prevState.findIndex((team) => team.TeamId === teamId);
      if (teamIndex > -1) {
        prevState.splice(teamIndex, 1);
      }
      return [...prevState].sort(orderTeams);
    });

    if (teamId) {
      setTeamsToDelete((prevState) => {
        prevState.push({ TeamId: teamId });
        return [...prevState];
      });
    } else {
      setTeamsChanges((prevState) => {
        const teamIndex = prevState.findIndex((team) => team.TeamId === teamId);
        if (teamIndex > -1) {
          prevState.splice(teamIndex, 1);
        }
        return [...prevState];
      });
    }
    setWait(false);
  }

  function handleCloseConfirmPopup() {
    setConfirmPopup({
      visible: false,
      message: '',
      confirmFunction: () => {},
      closeFunction: () => {},
    });
  }

  function handleShowDeletePopup(teamId) {
    const teamToDelete = teams.find((team) => team.TeamId === teamId);
    const teamUsersList = usersByTeam.find((t) => t.TeamId === teamId).Users;

    if (teamUsersList.length > 0) {
      setConfirmPopup((prevState) => ({
        ...prevState,
        message: `This action will delete [${teamToDelete.TeamName}] group and all users in it will left without goup. Are you sure?`,
      }));
    } else {
      setConfirmPopup((prevState) => ({
        ...prevState,
        message: `This action will delete [${teamToDelete.TeamName}] group. Are you sure?`,
      }));
    }

    setConfirmPopup((prevState) => ({
      ...prevState,
      visible: true,
      confirmFunction: () => {
        deleteTeam(teamUsersList, teamToDelete.TeamId);
      },
      closeFunction: () => {
        handleCloseConfirmPopup();
      },
    }));
  }

  function handleCloseAddPopup() {
    setEditTeamVisible(false);
  }

  function confirmChangeAreaId(newAreaId) {
    setConfirmPopup((prevState) => ({
      ...prevState,
      message: 'All unsaved changes will be lost. Are you sure?',
      visible: true,
      confirmFunction: () => {
        setCurrentAreaId(newAreaId);
        setCurrentAreaName(
          areas.find((area) => area.AreaId === newAreaId).AreaDesc
        );
      },
      closeFunction: () => {
        handleCloseConfirmPopup();
      },
    }));
  }

  function handleAreaChanged(e) {
    if (e.event !== undefined) {
      if (
        usersByTeam !== usersByTeamOriginal ||
        teamsToDelete.length > 0 ||
        teamsChanges.length > 0
      ) {
        confirmChangeAreaId(e.value);
      } else {
        setCurrentAreaId(e.value);
        setCurrentAreaName(
          areas.find((area) => area.AreaId === e.value).AreaDesc
        );
      }
    }
  }

  function listItemRender(item) {
    return (
      <div id="list_item">
        <span style={{ fontWeight: 'normal', color: '#333333' }}>
          <b>{item.DisplayName}</b> -{item.JobTitle}
        </span>
      </div>
    );
  }

  function onDragStart(e) {
    if (e.fromData.startsWith('team.')) {
      const teamName = e.fromData.replace('team.', '');
      e.itemData = usersByTeam.find((team) => team.TeamName === teamName).Users[
        e.fromIndex
      ];
    } else {
      e.itemData = usersWithoutTeam[e.fromIndex];
    }
  }

  async function onAdd(e) {
    if (e.toData.startsWith('team.')) {
      const teamName = e.toData.replace('team.', '');
      setUsersByTeam((prevState) => {
        const teamIndex = prevState.findIndex(
          (team) => team.TeamName === teamName
        );
        const teamData = prevState.find((team) => team.TeamName === teamName);
        teamData.Users = [
          ...teamData.Users.slice(0, e.toIndex),
          e.itemData,
          ...teamData.Users.slice(e.toIndex),
        ];
        // let newState = prevState;
        prevState[teamIndex] = teamData;

        return [...prevState].sort(orderTeams);
      });
    } else {
      setUsersWithoutTeam((prevState) => [
        ...prevState.slice(0, e.toIndex),
        e.itemData,
        ...prevState.slice(e.toIndex),
      ]);
    }
  }

  function onRemove(e) {
    if (e.fromData.startsWith('team.')) {
      const teamName = e.fromData.replace('team.', '');
      setUsersByTeam((prevState) => {
        const teamIndex = prevState.findIndex(
          (team) => team.TeamName === teamName
        );
        const teamData = prevState.find((team) => team.TeamName === teamName);
        teamData.Users = [
          ...teamData.Users.slice(0, e.fromIndex),
          ...teamData.Users.slice(e.fromIndex + 1),
        ];
        const newState = prevState;
        newState[teamIndex] = teamData;

        return [...newState].sort(orderTeams);
      });
    } else {
      setUsersWithoutTeam((prevState) => [
        ...prevState.slice(0, e.fromIndex),
        ...prevState.slice(e.fromIndex + 1),
      ]);
    }
  }

  function onReorder(e) {
    onRemove(e);
    onAdd(e);
  }

  async function deleteTeamsOnApi() {
    if (teamsToDelete.length > 0) {
      const res = await API.sendRequest('Team', 'DELETE', teamsToDelete);
      if (res.status !== 200) {
        notify(`Error deleting teams: ${res.message}`, 'error', 3000);
      }
    }
  }

  async function saveTeamsChangesOnApi() {
    if (teamsChanges.length > 0) {
      teamsChanges.forEach(async (t) => {
        const res = await API.sendRequest('Team', 'POST', t);

        if (res.status !== 200) {
          notify(`Error saving team data: ${res.message}`, 'error', 3000);
        }
      });
    }
  }

  async function saveUsersChangesOnApi() {
    if (usersChanges.length > 0) {
      const res = await API.sendRequest(
        'ADUser/UpdateADUserTeams',
        'POST',
        usersChanges
      );
      if (res.status !== 200) {
        notify(`Error saving users: ${res.message}`, 'error', 3000);
      }
    }
  }

  async function handleSaveChanges() {
    setWait(true);

    // Remove teams tagged to remove
    await deleteTeamsOnApi();

    // Add new teams and update existing teams. When creating a new team, update the TeamId on users added to them
    await saveTeamsChangesOnApi();

    // Send Users changes
    await saveUsersChangesOnApi();

    setTeamsChanges([]);
    setUsersChanges([]);
    setTeamsToDelete([]);

    updateUsers();
    updateTeams();
    setWait(false);
    notify('Changes saved', 'success', 3000);
  }

  return (
    <>
      {confirmPopup.visible && (
        <ConfirmPopup
          visible={confirmPopup.visible}
          closeFunction={confirmPopup.closeFunction}
          confirmFunction={confirmPopup.confirmFunction}
          message={confirmPopup.message}
        />
      )}
      {editTeamVisible && (
        <EditStaffTeam
          visible={editTeamVisible}
          handleClose={handleCloseAddPopup}
          handleSave={addTeam}
          area={currentAreaName}
          currentTeam={currentTeam}
        />
      )}
      <LoadPanel
        visible={wait}
        shading
        shadingColor="rgba(255,255,255,0.5)"
        message=""
      />
      {areas.length > 0 &&
      loggedUser.userId &&
      !loggedUser.isFullAccess &&
      !currentAreaId ? (
        <LoadPermissions>Your Area do not use Teams</LoadPermissions>
      ) : (
        <Container>
          <Header>
            <h3>
              {ACL && ACL.BreadCrumb}
              {!loggedUser.isFullAccess && `/${currentAreaName}`}
            </h3>
            {loggedUser.isFullAccess && (
              <SelectBox
                placeholder="Select an Area"
                dataSource={areas}
                displayExpr="AreaDesc"
                valueExpr="AreaId"
                width={300}
                onValueChanged={handleAreaChanged}
                value={currentAreaId}
              />
            )}
          </Header>
          <Toolbar style={{ backgroundColor: '#F6F6F6' }}>
            {buttonsOptions.map((buttonOpt, index) => (
              <Item
                key={index}
                location="before"
                widget="dxButton"
                options={buttonOpt}
              />
            ))}
          </Toolbar>
          <ListsContainer>
            <TeamsListContainer>
              {usersByTeam.length > 0 ? (
                usersByTeam.map((team, index) => (
                  <TeamContainer key={index}>
                    <TeamListLabel
                      bgColor={team.Color}
                      textColor={pickTextColorBasedOnBgColor(
                        team.Color,
                        '#FFFFFF',
                        '#2d6da3'
                      )}
                    >
                      <span>{team.TeamName}</span>
                      <div>
                        <Button
                          icon="edit"
                          onClick={() => handleEditTeam(team.TeamId)}
                          hint="Edit team"
                          type="default"
                          stylingMode="contained"
                          width={36}
                          height={36}
                        />
                        <Button
                          icon="trash"
                          onClick={() => handleShowDeletePopup(team.TeamId)}
                          hint="Delete team"
                          type="default"
                          stylingMode="contained"
                          width={36}
                          height={36}
                        />
                      </div>
                    </TeamListLabel>
                    <List
                      dataSource={team.Users}
                      keyExpr="UserId"
                      repaintChangesOnly
                      itemRender={listItemRender}
                      height="auto"
                      width="100%"
                      searchMode="contains"
                      noDataText={`There is no User in ${team.TeamName}`}
                      pageLoadMode="scrollBottom"
                    >
                      <ItemDragging
                        allowReordering
                        group="teams"
                        data={`team.${team.TeamName}`}
                        onDragStart={onDragStart}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onReorder={onReorder}
                        handle="#list_item"
                      />
                    </List>
                  </TeamContainer>
                ))
              ) : (
                <div
                  style={{
                    height: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {currentAreaId
                      ? 'There are no teams created for this area'
                      : ''}
                  </span>
                </div>
              )}
            </TeamsListContainer>
            {usersWithoutTeam.length > 0 && (
              <NoTeamContainer>
                <TeamListLabel>
                  <span>No Team</span>
                </TeamListLabel>
                <List
                  dataSource={usersWithoutTeam}
                  keyExpr="UserId"
                  repaintChangesOnly
                  itemRender={listItemRender}
                  height="100%"
                  pageLoadMode="scrollBottom"
                  noDataText={
                    currentAreaId
                      ? 'There are no Users without Team for this Area'
                      : 'Select an Area'
                  }
                >
                  <ItemDragging
                    allowReordering={false}
                    group="teams"
                    data="usersWithoutTeam"
                    onDragStart={onDragStart}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onReorder={onReorder}
                    handle="#list_item"
                  />
                </List>
              </NoTeamContainer>
            )}
          </ListsContainer>
        </Container>
      )}
    </>
  );
}

export default StaffTeams;
