import React, { useState, useEffect, useRef } from 'react';
import { bool, func, object, array } from 'prop-types';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Form, {
  SimpleItem,
  Label,
  GroupItem,
  RequiredRule,
} from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from 'devextreme/ui/notify';
import API from '../../../../services/API';
import { format, parseISO } from 'date-fns';

function AppointmentTypeEditPopup({
  currentAppType,
  areas,
  updateGrid,
  visible,
  setVisible,
}) {
  const [allDay, setAllDay] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [loadPanelSettings, setLoadPanelSettings] = useState({
    message: 'Loading...',
    visible: true,
  });
  const [lastChangeLabel, setLastChangeLabel] = useState('');
  const formRef = useRef();

  const toggleLoadPanel = (message = 'Loading...', visible) => {
    setLoadPanelSettings({ message, visible });
  };

  const handleSave = async () => {
    if (formRef.current.instance.validate().isValid) {
      try {
        if ((!startTime && !allDay) || (!endTime && !allDay)) {
          throw new Error('Start time and end time can not be saved empty.');
        }
        if (!!startTime && !!endTime) {
          if (startTime === endTime) {
            throw new Error('Start time and end time can not be the same.');
          }
          currentAppType.StartTime = getTime(startTime);
          currentAppType.EndTime = getTime(endTime);
        }
        if (allDay) {
          currentAppType.StartTime = null;
          currentAppType.EndTime = null;
        }
        toggleLoadPanel('Saving...', true);
        const res = await API.sendRequest(
          'AppointmentType',
          'post',
          currentAppType
        );
        if (res.status !== 200) {
          throw new Error(res.data.ErrorMsg);
        }
        updateGrid();
        toggleLoadPanel('', false);
        setVisible(false);
        notify('Appointment type saved.', 'success', 5000);
      } catch (e) {
        toggleLoadPanel('', false);
        notify(`Error: ${e.message}`, 'error', 5000);
      }
    } else {
      toggleLoadPanel('', false);
      notify(`Please check the highlighted fields.`, 'error', 5000);
    }
  };

  const handleOnShowing = () => {
    setAllDay(currentAppType.AllDay);
    toggleLoadPanel('', false);
  };

  const handleAllDay = ({ value }) => {
    setAllDay(value);
    if (value) {
      setStartTime(null);
      setEndTime(null);
    } else {
      setStartTime(currentAppType.StartTime);
      setEndTime(currentAppType.EndTime);
    }
  };

  const handleStartTimeValueChanged = ({ value }) => {
    setStartTime(value);
  };

  const handleEndTimeValueChanged = ({ value }) => {
    setEndTime(value);
  };

  const getTime = (date) => {
    if (!date) return;

    if (date.length === 8 && date.includes(':')) {
      return date;
    }

    date = new Date(date); // .toLocaleString("en-US", {timeZone: "America/New_York"}));
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours}:${minutes}:${seconds}`;
  };

  const buttonsOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        handleSave();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setVisible(false);
      },
    },
  ];

  const hours = [
    { text: '12:00 AM', value: '00:00:00' },
    { text: '12:30 AM', value: '00:30:00' },
    { text: '1:00 AM', value: '01:00:00' },
    { text: '1:30 AM', value: '01:30:00' },
    { text: '2:00 AM', value: '02:00:00' },
    { text: '2:30 AM', value: '02:30:00' },
    { text: '3:00 AM', value: '03:00:00' },
    { text: '3:30 AM', value: '03:30:00' },
    { text: '4:00 AM', value: '04:00:00' },
    { text: '4:30 AM', value: '04:30:00' },
    { text: '5:00 AM', value: '05:00:00' },
    { text: '5:30 AM', value: '05:30:00' },
    { text: '6:00 AM', value: '06:00:00' },
    { text: '6:30 AM', value: '06:30:00' },
    { text: '7:00 AM', value: '07:00:00' },
    { text: '7:30 AM', value: '07:30:00' },
    { text: '8:00 AM', value: '08:00:00' },
    { text: '8:30 AM', value: '08:30:00' },
    { text: '9:00 AM', value: '09:00:00' },
    { text: '9:30 AM', value: '09:30:00' },
    { text: '10:00 AM', value: '10:00:00' },
    { text: '10:30 AM', value: '10:30:00' },
    { text: '11:00 AM', value: '11:00:00' },
    { text: '11:30 AM', value: '11:30:00' },
    { text: '12:00 PM', value: '12:00:00' },
    { text: '12:30 PM', value: '12:30:00' },
    { text: '1:00 PM', value: '13:00:00' },
    { text: '1:30 PM', value: '13:30:00' },
    { text: '2:00 PM', value: '14:00:00' },
    { text: '2:30 PM', value: '14:30:00' },
    { text: '3:00 PM', value: '15:00:00' },
    { text: '3:30 PM', value: '15:30:00' },
    { text: '4:00 PM', value: '16:00:00' },
    { text: '4:30 PM', value: '16:30:00' },
    { text: '5:00 PM', value: '17:00:00' },
    { text: '5:30 PM', value: '17:30:00' },
    { text: '6:00 PM', value: '18:00:00' },
    { text: '6:30 PM', value: '18:30:00' },
    { text: '7:00 PM', value: '19:00:00' },
    { text: '7:30 PM', value: '19:30:00' },
    { text: '8:00 PM', value: '20:00:00' },
    { text: '8:30 PM', value: '20:30:00' },
    { text: '9:00 PM', value: '21:00:00' },
    { text: '9:30 PM', value: '21:30:00' },
    { text: '10:00 PM', value: '22:00:00' },
    { text: '10:30 PM', value: '22:30:00' },
    { text: '11:00 PM', value: '23:00:00' },
    { text: '11:30 PM', value: '23:30:00' },
  ];

  useEffect(() => {
    setStartTime(currentAppType.StartTime);
    setEndTime(currentAppType.EndTime);

    if (currentAppType && currentAppType.AppointmentTypeId) {
      API.sendRequest(
        `Audit/LastChange/AppointmentType/${currentAppType.AppointmentTypeId}`,
        'GET'
      ).then((res) => {
        if (res && res.status === 200 && res.data) {
          setLastChangeLabel(
            `Last change on ${format(
              parseISO(res.data.ChangeDate),
              "MM/dd/yyyy 'at' HH:mm a"
            )} by ${res.data.Username}`
          );
        }
      });
    }
  }, [currentAppType]); //eslint-disable-line

  const showLastChangeLabel = () => <span>{lastChangeLabel}</span>;

  return (
    <Popup
      onShowing={handleOnShowing}
      visible={visible}
      onHiding={() => setVisible(false)}
      width={900}
      height={600}
      showCloseButton={false}
      title="Appointment Type - Edit"
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={loadPanelSettings.visible}
        message={loadPanelSettings.message}
        closeOnOutsideClick={false}
      />
      <Form id="formEditAppType" formData={currentAppType} ref={formRef}>
        <GroupItem caption="Identification" colCount={2}>
          <SimpleItem
            dataField="AppointmentTypeCode"
            editorType="dxTextBox"
            editorOptions={{ maxLength: 15 }}
          >
            <RequiredRule message="Code is required" />
            <Label text="Code" />
          </SimpleItem>
          <SimpleItem dataField="AppointmentTypeDesc" editorType="dxTextBox">
            <RequiredRule message="Description is required" />
            <Label text="Description" />
          </SimpleItem>
          <SimpleItem dataField="Color" editorType="dxColorBox">
            <RequiredRule message="Color is required" />
          </SimpleItem>
          <SimpleItem
            dataField="AreaId"
            editorType="dxSelectBox"
            editorOptions={{
              items: areas,
              displayExpr: 'AreaDesc',
              valueExpr: 'AreaId',
            }}
          >
            <Label text="Area" />
          </SimpleItem>
        </GroupItem>
        <GroupItem caption="Configuration" colCount={2}>
          <SimpleItem
            dataField="ShowInToday"
            editorType="dxCheckBox"
            colSpan={1}
          >
            <Label text="Show in [Home/Working]" />
          </SimpleItem>
          <SimpleItem dataField="Overdue" editorType="dxCheckBox" colSpan={1}>
            <Label text="Past Appt." />
          </SimpleItem>
          <SimpleItem
            dataField="AllDay"
            editorType="dxCheckBox"
            colSpan={2}
            editorOptions={{
              onValueChanged: (e) => {
                handleAllDay(e);
              },
            }}
          >
            <Label text="All day" />
          </SimpleItem>
          <SimpleItem
            dataField="StartTime"
            visible={!allDay}
            editorType="dxLookup"
            editorOptions={{
              items: hours,
              displayExpr: 'text',
              valueExpr: 'value',
              onValueChanged: handleStartTimeValueChanged,
            }}
          />
          <SimpleItem
            dataField="EndTime"
            visible={!allDay}
            editorType="dxLookup"
            editorOptions={{
              items: hours,
              displayExpr: 'text',
              valueExpr: 'value',
              onValueChanged: handleEndTimeValueChanged,
            }}
          />
          <SimpleItem dataField="DayOff" editorType="dxCheckBox">
            <Label text="Day off" />
          </SimpleItem>
          <SimpleItem dataField="Summary" editorType="dxCheckBox" />
        </GroupItem>
      </Form>
      <ToolbarItem
        toolbar="bottom"
        location="before"
        render={showLastChangeLabel}
      />
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

AppointmentTypeEditPopup.propTypes = {
  visible: bool.isRequired,
  setVisible: func.isRequired,
  currentAppType: object.isRequired,
  areas: array.isRequired,
};

export default AppointmentTypeEditPopup;
