import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const TileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const IconItem = styled.i`
  font-size: 20px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 50px;
  margin: 5px 0;

  padding: ${({ warning }) => warning && '8px'};
  border-radius: ${({ warning }) => warning && '8px'};
  background: ${({ warning }) => warning && '#DA6461'};
  color: ${({ warning }) => warning && '#FFF'};
`;

export const Text = styled.span`
  font-weight: ${({ bold }) => (bold ? 'bold' : '')};
  font-size: 14;
  text-align: center;
`;
