// import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const BoxCenter = styled.div`
  height: 230px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxAssignment = styled.div`
  height: 180px;
  width: 100%;
  overflow: hidden;
`;

export const useStyles = makeStyles((theme) => ({
  left: {
    float: 'left',
    width: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  right: {
    float: 'right',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));
