import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
`;

export const WrapperEmail = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #d6d6d6;
`;

export const WrapperHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  gap: 8px;
  border-radius: 12px;
  :hover {
    background-color: #dddddd50;
    cursor: pointer;
  }
`;

export const WrapperImage = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 75px;
    height: 75px;
  }
`;

export const WrapperInfos = styled.div`
  width: calc(100% - 68px);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;

  p {
    color: #717171;
  }
`;

export const Content = styled.div`
  width: fit-content;
  width: 800px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  /* background-color: gray; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 12px;
`;

export const WrapperAttachments = styled.div`
  border: 1px solid #dddddd;
  border-radius: 10px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;

  gap: 8px;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .list {
    display: flex;
    gap: 8px;
  }
`;

export const Attachments = styled.div`
  img {
    width: 40px;
    height: 40px;
  }
  padding: 8px;
  height: 100px;
  border: 1px ${({ dashed }) => (dashed ? 'dashed #eeeeee' : 'solid #dddddd')};
  border-radius: 6px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  /* justify-content: center; */

  p {
    max-width: 100px;
    word-break: break-all;
    text-align: center;
    line-clamp: 2;
  }
`;

export const WrapperEmailContent = styled.div``;
