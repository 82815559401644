import { DataGrid, Button as DxButton, Lookup } from 'devextreme-react';
import {
  Button as ButtonGrid,
  Column,
  FilterRow,
} from 'devextreme-react/data-grid';
import RequestMethodCell from 'pages/EMRAccess/components/RequestMethodCell';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { format, isBefore, parseISO } from 'date-fns';
import notify from 'devextreme/ui/notify';
import StatusCellRender from 'pages/EMRAccess/components/StatusCellRender';
import { CellRenderContainer } from '../styles';
import classes, { CheckBoxSmall, WrapperBulkButton } from './styles';

function ExpirationDetail(
  {
    ACL,
    data,
    loading,
    handleDeleteRequest,
    handleShowChangeHistory,
    requestMethodList,
    requestStatusDataList,
    editRequestPopupRef,
    emailViewRef,
    sendEmailPopupRef,
  },
  ref
) {
  const [bulkEmailUsers, setBulkEmailUsers] = useState([]);

  useImperativeHandle(ref, () => ({
    clearUsers: () => setBulkEmailUsers([]),
  }));

  return (
    <>
      <WrapperBulkButton
        title={bulkEmailUsers.length < 2 ? 'Select at least 2 users' : ''}
      >
        <DxButton
          text={`Edit and send bulk email ${
            bulkEmailUsers.length > 0 ? `(${bulkEmailUsers.length})` : ''
          }`}
          disabled={bulkEmailUsers.length < 2}
          icon="email"
          visible={
            data?.EMRRequestMethodId === 3
            // &&
            // requestList?.data?.filter((item) => item.EMRRequestStatusId === 0)
            //   .length > 0
          }
          onClick={() =>
            sendEmailPopupRef?.current?.changeVisibility(
              {},
              bulkEmailUsers,
              'bulk'
            )
          }
        />
      </WrapperBulkButton>
      <DataGrid
        // ref={contactExportGridRef}
        dataSource={data?.requestList}
        // loadPanel={{ enabled: loading }}
        allowColumnReordering
        allowColumnResizing
        showRowLines
        showColumnHeaders
        showBorders
        rowAlternationEnabled
        filterPanel={{ visible: true }}
        width="100%"
        height="100%"
        focusedRowEnabled
        keyExpr="ADUserEMRAccessId"
      >
        <FilterRow visible applyFilter="auto" />

        <Column dataField="DisplayName" caption="Name" width={200} />
        <Column
          dataField="HospitalGroupName"
          caption="Okta Tile"
          width="200px"
        />
        <Column
          dataField="ExpiresOn"
          caption="Expires On"
          dataType="date"
          width={110}
          cssClass={classes.cellRenderTd}
          cellRender={({ data }) => {
            const yourDate = parseISO(data?.ExpiresOn);
            const currentDate = new Date();
            return (
              <div>
                <CellRenderContainer isBefore={isBefore(yourDate, currentDate)}>
                  {format(yourDate, 'MM/dd/yyyy')}
                </CellRenderContainer>
              </div>
            );
          }}
        />
        <Column
          dataField="ApprovalDate"
          caption="Approval Date"
          dataType="date"
          width={110}
        />
        <Column
          visible={data?.EMRRequestMethodId === 3}
          width={70}
          cellRender={({ data }) =>
            data?.EMRRequestStatusId === 4 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <CheckBoxSmall
                  onValueChanged={(e) => {
                    if (e.value) {
                      setBulkEmailUsers((prev) => [...prev, data]);
                    } else {
                      setBulkEmailUsers((prev) =>
                        prev.filter(
                          (item) =>
                            data.ADUserEMRAccessId !== item.ADUserEMRAccessId
                        )
                      );
                    }
                  }}
                  defaultValue={bulkEmailUsers
                    ?.map((item) => item.ADUserEMRAccessId)
                    ?.includes(data?.ADUserEMRAccessId)}
                />
              </div>
            )
          }
        />
        <Column
          dataField="EMRRequestMethodId"
          caption="Request Method"
          alignment="left"
          // cssClass={classes.cellRenderTd}
          cellComponent={({ data }) => {
            return data?.data?.EMRRequestStatusId === 6 ? (
              <span>
                {
                  requestMethodList?.find(
                    (item) =>
                      item.EMRRequestMethodId === data?.data?.EMRRequestMethodId
                  )?.EMRRequestMethodName
                }
              </span>
            ) : (
              <RequestMethodCell
                data={data}
                emailViewRef={emailViewRef}
                sendEmailPopupRef={sendEmailPopupRef}
                isExpirationScreen
              />
            );
          }}
          minWidth={200}
        >
          <Lookup
            dataSource={requestMethodList}
            valueExpr="EMRRequestMethodId"
            displayExpr="EMRRequestMethodName"
          />
        </Column>

        <Column
          width={150}
          dataField="RequestedByUsername"
          caption="Requested By"
        />

        <Column
          dataField="EMRRequestStatusId"
          caption="Status"
          alignment="left"
          type="text"
          cellComponent={StatusCellRender}
          minWidth={200}
        >
          <Lookup
            dataSource={requestStatusDataList}
            displayExpr="EMRRequestStatusName"
            valueExpr="EMRRequestStatusId"
          />
        </Column>

        <Column fixed caption="Actions" type="buttons" width={100}>
          {(ACL?.Admin || ACL?.Update) && (
            <ButtonGrid
              icon="fas fa-edit"
              hint="Edit"
              onClick={(e) => {
                // eslint-disable-next-line no-unused-expressions
                editRequestPopupRef?.current?.changeVisibility({
                  ...e.row.data,
                });
              }}
              visible={!loading}
            />
          )}
          {ACL?.Admin && (
            <ButtonGrid
              icon="fas fa-history"
              hint="Show Change History"
              onClick={handleShowChangeHistory}
            />
          )}
          {(ACL?.Admin || ACL?.Delete) && (
            <ButtonGrid
              icon="fas fa-trash"
              hint="Delete"
              onClick={(e) => {
                if (
                  !e?.row?.data?.EMREmail &&
                  e?.row?.data?.EMRRequestMethodId === 3
                ) {
                  notify(
                    'This Okta Tile does not have contacts for each type of email, please configure the contacts on the "Okta Tile" screen!',
                    'warning',
                    8000
                  );
                  return;
                }
                handleDeleteRequest(e);
              }}
            />
          )}
        </Column>
      </DataGrid>
    </>
  );
}

export default forwardRef(ExpirationDetail);
