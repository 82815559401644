function returnTimesTamp() {
  const dateNow = new Date(); // today's date and time in ISO format
  const dateConverted = Date.parse(dateNow);
  return dateConverted;
}

function returnPhoto(pth) {
  const newPath = pth?.substring(0, 1);
  if (newPath === 'h') {
    return `${pth}?timestamp=${returnTimesTamp()}`;
  }
  return pth;
}

export default returnPhoto;
