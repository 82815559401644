import styled from 'styled-components';

export const WrapperEditor = styled.div`
  width: ${({ width }) => width};
  .ck-content {
    /* height: 50vh; */
  }

  .ck-source-editing-area {
    display: flex;
    height: 400px;
    justify-content: flex-start;

    textarea {
      overflow: auto;
    }
  }
`;
