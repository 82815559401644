import { Popup } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import { bool, func, string } from 'prop-types';
import React from 'react';

// import { Container } from './styles';

function ConfirmPopup({ visible, message, confirmFunction, closeFunction }) {
  const buttonsOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        confirmFunction();
        closeFunction();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        closeFunction();
      },
    },
  ];
  return (
    <Popup
      visible={visible}
      width={300}
      height={200}
      showTitle={false}
      closeOnOutsideClick={false}
    >
      <div style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>
        <span>{message}</span>
      </div>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

ConfirmPopup.propTypes = {
  visible: bool.isRequired,
  message: string.isRequired,
  confirmFunction: func.isRequired,
  closeFunction: func.isRequired,
};

export default ConfirmPopup;
