import { useQuery } from 'react-query';
import API from '../API';

async function getAllHospitalsGroupsWithEMREnabled() {
  const { data } = await API.sendRequest('HospitalGroup', 'GET');

  return data.map((hospital) => ({
    ...hospital,
    AutoSend: false,
  }));
}

export default function useAllHospitalsGroupsList() {
  return useQuery('allHospitals', getAllHospitalsGroupsWithEMREnabled, {
    refetchOnWindowFocus: false,
  });
}
