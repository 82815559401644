import styled from 'styled-components';
import loginImg from '../../assets/login/image.png';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Content = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  background-color: white;
  box-shadow: 6px 6px rgba(0, 0, 0, 0.1);

  /* border-radius: 8px; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 24px; */
`;

export const Step = styled.div`
  width: 350px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
  /* padding: 12px; */
`;

export const WrapperInput = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  gap: 12px;
  border-radius: 6px;
  padding: 24px;

  .inputWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
`;

export const TextLabel = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0;

  span {
    font-size: 1rem;
    text-align: left;
    color: #707070;
  }
`;

export const WrapperButton = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 8px;
`;

export const SideImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 10px;
  width: 450px;
  height: 450px;
  background-image: url(${loginImg});
  padding: 28px;

  h2 {
    font-size: 1.6rem;
    color: #505050;
    font-weight: bold;
  }
`;

export const Logo = styled.img`
  height: 60px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 90%;
`;

export const AppName = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  span {
    color: #0569ba;
    /* font-weight: bold; */
    font-size: 1.6rem;
  }

  label {
    text-align: left;
    padding-left: 5px;
    font-size: 0.7rem;
    font-weight: 300;
    color: #707070;
  }
`;

export const StepDetails = styled.div`
  width: 80%;
  height: fit-content;
  border: ${({ bdActive }) =>
    bdActive ? '2px solid #0569ba' : '2px solid #d0d0d0'};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ bgActive }) =>
    bgActive ? '#ffffff90' : 'transparent'};

  span {
    text-align: left;
    font-size: 0.8rem;
    font-weight: bold;
    color: #707070;
  }
`;

export const WarningInfo = styled.span`
  width: 80%;
  text-align: left;
  font-size: 0.7rem;
  border: 1px solid #e13f34;
  padding: 4px 10px;
  border-radius: 8px;
  background-color: #e47373;
  color: #ffffff;
`;
