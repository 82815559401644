import notify from 'devextreme/ui/notify';
import API from '../services/API';

const getChangeHistory = async (topicDesc, registryId) => {
  const res = await API.sendRequest(
    `Audit/ListDetails/${topicDesc}${registryId ? `/${registryId}` : ''}`,
    'GET',
    false
  );
  if (res.status !== 200 || res.data.length === 0) {
    notify('There are no history entries for this record', 'info', 3000);
  } else {
    return res.data;
  }
  return false;
};

export default getChangeHistory;
