import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  padding: 16px 32px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 28px;
  padding-left: 8px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 36px);
  background-color: white;
  border: 1px solid #cccccc60;
  padding: 0 12px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cccccc60;
  margin-bottom: 6px;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 100px);
  width: 100%;

  .count {
    text-align: left;
    padding: 8px 0;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
  width: 100%;
  background-color: white;
`;

export const CellRenderContainer = styled.div`
  margin: 0;
  background-color: ${(props) => (props.isBefore ? '#ed6f6f' : 'transparent')};
  text-align: center;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-left: 8px;
  height: 43px;
  min-width: fit-content;
  width: 100%;
`;

export default makeStyles(() => ({
  divGridContainer: {
    margin: 'auto',
    width: '98%',
    height: '85vh',
    textAlign: 'center',
  },
  cellRenderTd: {
    padding: '0 !important',
    width: '100%',
  },
}));
