import React from 'react';
import {
  string, shape, element, func,
} from 'prop-types';
import { useDrop } from 'react-dnd';
import { CustomTableCell } from './styles';

export default function ItemDrop({
  item, row, children, handleDrop, handleCanDrop, handleAssignmentOpen,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'CELL',
    canDrop: handleCanDrop,
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  return (
    <CustomTableCell
      ref={drop}
      holiday={item.columnName.includes('(')}
      isActive={isActive}
      onContextMenu={(e) => handleAssignmentOpen(e, item, row)}
    >
      {children}
    </CustomTableCell>
  );
}

ItemDrop.propTypes = {
  item: string.isRequired,
  row: shape.isRequired,
  children: element.isRequired,
  handleDrop: func.isRequired,
  handleCanDrop: func.isRequired,
  handleAssignmentOpen: func.isRequired,
};
