import Cookies from 'js-cookie';

function isExpired(token) {
  if (!token) {
    return true;
  }

  const tokenSplit = token.split(' ');

  let tokens = [];
  if (tokenSplit.length > 1) {
    tokens = tokenSplit[1].split('.');
  } else {
    tokens = tokenSplit[0].split('.');
  }

  const tokenData = JSON.parse(
    Buffer.from(tokens[1], 'base64').toString('ascii')
  );

  if (!tokenData.exp) {
    return false;
  }

  // 1 minute to expire, already expired
  const todayTimestamp = Math.floor(Date.now() / 1000) + 60;
  if (tokenData.exp > todayTimestamp) {
    return false;
  }

  return true;
}

export default function isAuth() {
  const tokenExpired = isExpired(Cookies.get('token'));
  if (tokenExpired) {
    Cookies.remove('token');
  }

  return !tokenExpired;
}
