import { /*React,*/ useEffect, useState } from 'react';
import isAuth from '../helpers/isAuth';

const useAuth = () => {
  const [auth, setAuth] = useState(isAuth());

  useEffect(() => {
    const verifyAuth = () => {
      const localAuth = isAuth();
      setAuth(localAuth);

      setTimeout(() => {
        verifyAuth();
      }, 1000);
    };

    verifyAuth();
  }, []);

  return auth;
};

export default useAuth;
