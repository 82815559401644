import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 85vh;
  margin: 16px auto;

  .dx-datagrid-header-panel {
    height: 47px;
    display: flex;
    align-items: center;
  }
  .dx-toolbar-items-container {
    height: 47px;
  }
  .dx-toolbar-item-content {
    font-size: 1.1rem;
    font-weight: 500;
  }
`;
