import { useQuery } from 'react-query';
import API from '../API';

async function getDistLists() {
  const { data } = await API.sendRequest('ADUser/GetDistLists', 'GET');
  if (data) {
    const dataFormatted = data?.map((item) => ({
      ...item,
      Members: JSON.parse(item.Members),
    }));
    return dataFormatted;
  }
  return data;
}

export default function useGetDistLists() {
  return useQuery('distList', getDistLists, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}
