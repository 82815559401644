import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { format } from 'date-fns';
import Avatar from '../../../components/contacts/Avatar';

import {
  Container,
  BoxAvatar,
  BoxSubject,
  Subject,
  DateTitle,
} from './styles';

export default function TooltipTemplate(props) {
  const { data, handleRemove, handleTooltipClick } = props;

  return (
    <Container onClick={handleTooltipClick}>
      <ListItem key={data.ContactId}>
        <ListItemAvatar>
          <BoxAvatar>
            <Avatar
              contact={data}
              h={48}
              w={48}
              style={{ marginLeft: 0, marginRight: 0 }}
            />
            <span>{data.FullName}</span>
          </BoxAvatar>
        </ListItemAvatar>
        <ListItemText>
          <BoxSubject>
            <Subject>{data.Subject}</Subject>
            <DateTitle>{format(new Date(data.StartDate), 'MMMM dd')}</DateTitle>
          </BoxSubject>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton aria-label="delete" onClick={(e) => handleRemove(data)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Container>
  );
}
