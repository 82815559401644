import React from 'react';
import Chip from '@material-ui/core/Chip';
import { shape, func, string, bool, number } from 'prop-types';
import Avatar from '../contacts/Avatar';
import { WrapperContact, WrapperDataContact, Left, Right } from './styles';

export default function CardPerson({
  data,
  handleCardClick,
  handleOnlineClick,
  columnDirection,
  height,
  width,
  avatarH,
  avatarW,
  bgColor,
}) {
  return (
    <WrapperContact
      onClick={() => handleCardClick()}
      height={height}
      width={width}
      bgColor={bgColor}
    >
      <div>
        <Avatar
          contact={data}
          h={avatarH}
          w={avatarW}
          style={{ marginLeft: 0, marginRight: 0 }}
        />
      </div>
      <WrapperDataContact columnDirection={columnDirection}>
        <Left><p><b>{data.FirstName}</b> {data.LastName}</p></Left>
        {data.AreaDesc !== 'Hospitals' && data.Online &&
          <Right>
            <Chip 
              label="online" 
              size="small"
              color="primary"
              style={{backgroundColor:'green'}} 
              onClick={handleOnlineClick} />
          </Right>
        }
      </WrapperDataContact>
    </WrapperContact>
  );
}

CardPerson.propTypes = {
  data: shape({
    FirstName: string,
    LastName: string,
  }).isRequired,
  handleCardClick: func,
  columnDirection: bool,
  height: number.isRequired,
  width: number.isRequired,
  avatarH: number.isRequired,
  avatarW: number.isRequired,
  bgColor: string,
};

CardPerson.defaultProps = {
  handleCardClick: () => {},
  columnDirection: false,
  bgColor: '#fff',
};
