import React, { useEffect, useState } from 'react';
import { LoadPermissions } from '../../global';
import usePermissions from '../../hooks/usePermissions';

import { Button, SelectBox, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/form';
import Tabs from 'devextreme-react/tabs';
import notify from 'devextreme/ui/notify';
import API from '../../services/API';
import useAllADUserEmrAccess from '../../services/requests/useAllADUserEmrAccess';
import useGetEMRSetup from '../../services/requests/useGetEMRSetup';
import EMRAccessRequest from './components/EMRAccessRequest';
import {
  Container,
  Content,
  ContentContainer,
  TabContainer,
  Title,
  TopControls,
  WrapperButtons,
  WrapperField,
} from './styles';
// import ExpirationTemplate from './components/ExpirationTemplate';
import RenewCredentials from './components/EMRRenewCredentials';
import NewTrainingTemplate from './components/NewTrainingTemplate';
import ScheduleExpirationPopup from './components/ScheduleExpirationPopup';
import TerminationTemplate from './components/TerminationTemplate';

function EMRSetup() {
  const [isLoading, setIsLoading] = useState(false);

  const { data: getAllUsers } = useAllADUserEmrAccess();
  const { data: getEMRSetup, refetch: refetchSetup } = useGetEMRSetup();
  const [selectedIndexTab, setSelectedIndexTab] = useState(0);
  const [emrSetupDraft, setEMRSetupDraft] = useState({
    ExpirationAlert: 7,
  });
  const [admins, setAdmins] = useState({
    AdminUserId1: '',
    AdminUserId2: '',
  });
  const [oldAdmins, setOldAdmins] = useState({
    AdminUserId1: '',
    AdminUserId2: '',
  });
  const [
    popupScheduleExpirationVisible,
    setPopupScheduleExpirationVisible,
  ] = useState(false);
  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed && getEMRSetup) {
      setEMRSetupDraft(getEMRSetup);

      let adminsAPI = [];

      if (getEMRSetup?.Admins) {
        adminsAPI = JSON.parse(getEMRSetup.Admins)?.sort(
          (a, b) => b?.Main - a?.Main
        );
      }

      const adminsToSave = {
        AdminUserId1: adminsAPI[0]?.UserId?.toLowerCase() || '',
        AdminUserId2: adminsAPI[1]?.UserId?.toLowerCase() || '',
      };

      setAdmins(adminsToSave);
      setOldAdmins(adminsToSave);
    }
    return () => {
      isSubscribed = false;
    };
  }, [getEMRSetup]);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }
  /** ****************************** */

  const ACTVariables = [
    '{Date}',
    '{DisplayName}',
    '{Email}',
    '{HospitalGroupName}',
    '{JobTitle}',
    '{PhoneNumbers}',
  ];

  const TrainingTemplateVariables = [
    '{Date}',
    '{DisplayName}',
    '{TrainingTable}',
  ];

  const handleSaveEMRSetup = async (e) => {
    e.preventDefault();
    if (!admins?.AdminUserId1 || !admins?.AdminUserId2) {
      notify('Please select the 2 admins!', 'error', 5000);
      return;
    }

    if (
      !ACTVariables.every((element) =>
        emrSetupDraft?.AccessRequestEmailBody?.includes(element)
      )
    ) {
      setIsLoading(false);
      notify(
        'Not find variables [{Date}, {DisplayName}, {Email}, {HospitalGroupName}, {JobTitle}, {PhoneNumbers}] in [New Credential Request Template].',
        'error',
        6000
      );
      return;
    }
    if (
      !ACTVariables.every((element) =>
        emrSetupDraft?.AccessRenewCredentialsEmailBody?.includes(element)
      )
    ) {
      setIsLoading(false);
      notify(
        'Not find variables [{Date}, {DisplayName}, {Email}, {HospitalGroupName}, {JobTitle}, {PhoneNumbers}] in [Renewal of Credential Request Template].',
        'error',
        6000
      );
      return;
    }
    if (
      !ACTVariables.every((element) =>
        emrSetupDraft?.TerminationEmail?.includes(element)
      )
    ) {
      setIsLoading(false);
      notify(
        'Not find variables [{Date}, {DisplayName}, {Email}, {HospitalGroupName}, {JobTitle}, {PhoneNumbers}] in [Termination Template].',
        'error',
        6000
      );
      return;
    }
    if (
      !TrainingTemplateVariables.every((element) =>
        emrSetupDraft?.NewTrainingEmailBody?.includes(element)
      )
    ) {
      setIsLoading(false);
      notify(
        'Not find variables [{Date}, {DisplayName}, {TrainingTable}] in [New Training Template].',
        'error',
        6000
      );
      return;
    }

    setIsLoading(true);
    try {
      const admin1Data = getAllUsers?.find(
        (item) => item.UserId === admins?.AdminUserId1
      );
      const admin2Data = getAllUsers?.find(
        (item) => item.UserId === admins?.AdminUserId2
      );

      const dataToSend = {
        ...emrSetupDraft,
        Admins: JSON.stringify([
          {
            UserId: admins?.AdminUserId1,
            Email: admin1Data?.Email,
            DisplayName: admin1Data?.DisplayName,
            Main: true,
          },
          {
            UserId: admins?.AdminUserId2,
            Email: admin2Data?.Email,
            DisplayName: admin2Data?.DisplayName,
            Main: false,
          },
        ]),
      };
      delete dataToSend.ErrorMsg;

      const res = await API.sendRequest('EMRSetup', 'post', dataToSend);
      if (res.status === 200) {
        refetchSetup();
        setIsLoading(false);
        notify('Template(s) saved successfully', 'success', 3000);
      }
      if (res.status !== 200) {
        setIsLoading(false);
        if (res.data) {
          throw new Error(res.data.ErrorMsg);
        }
        throw new Error(res.message);
      }
    } catch (e) {
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const tabs = [
    {
      id: 0,
      text: 'New Credential Request Template',
      content: (
        <EMRAccessRequest
          emrSetupDraft={emrSetupDraft}
          setEMRSetupDraft={setEMRSetupDraft}
          isLoading={isLoading}
        />
      ),
    },
    {
      id: 1,
      text: 'Renewal of Credential Request Template',
      content: (
        <RenewCredentials
          emrSetupDraft={emrSetupDraft}
          setEMRSetupDraft={setEMRSetupDraft}
          isLoading={isLoading}
        />
      ),
    },
    {
      id: 2,
      text: 'Termination Template',
      content: (
        <TerminationTemplate
          emrSetupDraft={emrSetupDraft}
          setEMRSetupDraft={setEMRSetupDraft}
          isLoading={isLoading}
        />
      ),
    },
    {
      id: 3,
      text: 'New Training Template',
      content: (
        <NewTrainingTemplate
          emrSetupDraft={emrSetupDraft}
          setEMRSetupDraft={setEMRSetupDraft}
          isLoading={isLoading}
        />
      ),
    },
  ];

  const handleItemClick = ({ itemIndex }) => {
    setSelectedIndexTab(itemIndex);
  };

  return (
    <>
      <ScheduleExpirationPopup
        visible={popupScheduleExpirationVisible}
        onClose={() => setPopupScheduleExpirationVisible(false)}
        data={getEMRSetup}
        refetch={refetchSetup}
      />
      <form style={{ width: '100%' }} onSubmit={handleSaveEMRSetup}>
        <Container>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Title>{ACL?.BreadCrumb}</Title>
            <Button
              text="Alert Settings"
              icon="fa fa-calendar"
              type="default"
              onClick={() => {
                setPopupScheduleExpirationVisible(true);
              }}
            />
          </div>
          <Content>
            <TopControls>
              <WrapperField>
                <label>Admin 1:</label>
                <SelectBox
                  searchEnabled
                  dataSource={getAllUsers?.filter(
                    (item) => item.UserId !== admins?.AdminUserId2
                  )}
                  value={admins?.AdminUserId1}
                  displayExpr="DisplayName"
                  valueExpr="UserId"
                  width={250}
                  onValueChanged={(e) => {
                    setAdmins((prevState) => ({
                      ...prevState,
                      AdminUserId1: e.value,
                    }));
                  }}
                >
                  <Validator>
                    <RequiredRule message="This field is required" />
                  </Validator>
                </SelectBox>
              </WrapperField>
              <WrapperField>
                <label>Admin 2:</label>
                <SelectBox
                  searchEnabled
                  dataSource={getAllUsers?.filter(
                    (item) => item.UserId !== admins?.AdminUserId1
                  )}
                  value={admins?.AdminUserId2}
                  displayExpr="DisplayName"
                  valueExpr="UserId"
                  width={250}
                  onValueChanged={(e) => {
                    setAdmins((prevState) => ({
                      ...prevState,
                      AdminUserId2: e.value,
                    }));
                  }}
                >
                  <Validator>
                    <RequiredRule message="This field is required" />
                  </Validator>
                </SelectBox>
              </WrapperField>
              {/* <WrapperField>
            <label>Email:</label>
            <TextBox
              readOnly
              id="Email"
              width={300}
              value={emrSetupDraft?.Email}
              onValueChanged={handleTextChange}
            />
          </WrapperField> */}
              {/*   <WrapperField>
              <label>Expiration Alert (Days):</label>
              <NumberBox
              id="ExpirationAlert"
              showSpinButtons
              width={70}
                min={1}
                value={emrSetupDraft?.ExpirationAlert}
                onValueChanged={handleTextChange}
                >
                <Validator>
                <RequiredRule message="This field is required" />
                </Validator>
                </NumberBox>
              </WrapperField> */}
              {/* <WrapperField>
            <label>Link Expiration (Hours):</label>
            <NumberBox
            id="LinkExpiration"
            showSpinButtons
            width={70}
            min={0}
            value={emrSetupDraft?.LinkExpiration}
              onValueChanged={handleTextChange}
              />
            </WrapperField> */}
            </TopControls>
            <TabContainer>
              <Tabs
                dataSource={tabs}
                selectedIndex={selectedIndexTab}
                onItemClick={handleItemClick}
                width="100%"
              />
              <ContentContainer>
                {tabs[selectedIndexTab].content}
              </ContentContainer>
              {/* <EMRAccessRequest
              emrSetupDraft={emrSetupDraft}
              setEMRSetupDraft={setEMRSetupDraft}
              isLoading={isLoading}
            /> */}
            </TabContainer>
            <WrapperButtons>
              <Button
                text="Save"
                className="button-save"
                type="success"
                stylingMode="contained"
                width={120}
                height={35}
                // onClick={handleSaveEMRSetup}
                useSubmitBehavior
                disabled={
                  emrSetupDraft?.NewTrainingEmailBody ===
                    getEMRSetup?.NewTrainingEmailBody &&
                  emrSetupDraft?.AccessRequestEmailBody ===
                    getEMRSetup?.AccessRequestEmailBody &&
                  emrSetupDraft?.AccessRenewCredentialsEmailBody ===
                    getEMRSetup?.AccessRenewCredentialsEmailBody &&
                  emrSetupDraft?.ExpirationAlert ===
                    getEMRSetup?.ExpirationAlert &&
                  admins?.AdminUserId1 === oldAdmins?.AdminUserId1 &&
                  admins?.AdminUserId2 === oldAdmins?.AdminUserId2 &&
                  emrSetupDraft?.TerminationEmail ===
                    getEMRSetup?.TerminationEmail
                }
              />
              <Button
                type="default"
                stylingMode="outlined"
                width={120}
                height={35}
                text="Cancel"
                onClick={() => {
                  setEMRSetupDraft(getEMRSetup);
                }}
              />
            </WrapperButtons>
          </Content>
        </Container>
      </form>
    </>
  );
}

export default EMRSetup;
