import { useQuery } from 'react-query';
import API from '../API';

async function getOktaApps() {
  const data = await API.sendRequest('OktaApp', 'GET');
  return data;
}

export async function getOktaAppsSyncApps() {
  const data = await API.sendRequest('OktaApp/SyncApplicationsOkta', 'GET');
  return data;
}

export function useGetOktaApps() {
  return useQuery('oktaAppList', () => getOktaApps(), {
    refetchOnWindowFocus: false,
  });
}

export async function saveOktaApp(oktaAppUser) {
  const data = await API.sendRequest('OktaApp', 'POST', oktaAppUser);
  return data;
}

export async function saveOktaAppMember(oktaAppUserData) {
  const data = await API.sendRequest(
    'OktaAppUser/Add',
    'POST',
    oktaAppUserData
  );
  return data;
}

export async function saveOktaAppUser(oktaAppUser) {
  const data = await API.sendRequest('OktaAppUser', 'POST', oktaAppUser);
  return data;
}

export async function deleteOktaApp(oktaAppId) {
  const data = await API.sendRequest(`OktaApp/${oktaAppId}`, 'DELETE');
  return data;
}

async function getOktaAppsByUser(userId) {
  const data = await API.sendRequest(`OktaApp/ListByUser/${userId}`, 'GET');
  return data;
}

export function useGetOktaAppsByUser(userId) {
  return useQuery('oktaAppList', () => getOktaAppsByUser(userId), {
    refetchOnWindowFocus: false,
  });
}

export async function getMembersByOktaApp(oktaAppId) {
  const data = await API.sendRequest(
    `OktaApp/ListMembersByApplication/${oktaAppId}`,
    'GET'
  );
  return data;
}

export function useGetMembersByOktaApp(oktaAppId) {
  return useQuery('oktaAppUserList', () => getMembersByOktaApp(oktaAppId), {
    refetchOnWindowFocus: false,
  });
}

export async function deleteOktaAppMember(
  oktaAppId,
  oktaApplicationId,
  memberId
) {
  const data = await API.sendRequest(
    `OktaAppUser/${oktaAppId}/${oktaApplicationId}/${memberId}`,
    'DELETE'
  );
  return data;
}

export async function getUserApplicationById(oktaAppId, userId) {
  const data = await API.sendRequest(
    `OktaAppUser/UserApplicationById/${oktaAppId}/${userId}`,
    'GET'
  );
  return data;
}

export function useUserApplicationById(oktaAppId) {
  return useQuery('oktaAppUserList', () => getMembersByOktaApp(oktaAppId), {
    refetchOnWindowFocus: false,
  });
}
