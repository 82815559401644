import Button from 'devextreme-react/button';
import React, { useRef } from 'react';
import { LoadPermissions } from '../../global';
import usePermissions from '../../hooks/usePermissions';
import { useADUserDeactivation } from '../../services/requests/useAllADUserEmrAccess';
import useStyles from '../styles/styles';
import {
  DataGridContainer,
  Header,
  HeaderButtons,
  Title,
  TitleWrapper,
} from './styles';

import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Lookup,
  MasterDetail,
  Selection,
  Sorting,
} from 'devextreme-react/data-grid';
import EmailView from 'pages/EMRAccess/components/EmailView';
import EmrAccessEditPopup from 'pages/EMRAccess/components/RequestsDetail/components/EmrAccessEditPopup';
import useAllAreasList from 'services/requests/useAllAreasList';
import useEmrRequestMethodList from 'services/requests/useEmrRequestMethodList';
import useEmrRequestStatusList from 'services/requests/useEmrRequestStatusList';
import TerminationDetail from './TerminationDetail';

const ACTAccessTermination = () => {
  const classes = useStyles();

  const {
    data: adUserEmrData,
    refetch: forceUpdateUsers,
    isFetching: isFetchingUsers,
  } = useADUserDeactivation();

  const { data: requestStatusDataList } = useEmrRequestStatusList();
  const { data: requestMethodList } = useEmrRequestMethodList();
  const { data: areasList } = useAllAreasList();

  const emailViewRef = useRef(null);
  const sendEmailPopupRef = useRef(null);
  const editRequestPopupRef = useRef(null);

  const isLoading = !!isFetchingUsers;

  const handleUpdate = async () => {
    forceUpdateUsers();
  };

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }

  /*********************************/

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  return (
    <div className={classes.divGridContainer}>
      <EmrAccessEditPopup
        forceRefetch={() => handleUpdate()}
        terminationPage={true}
        setAutoRefetchGrid={() => {}}
        ref={editRequestPopupRef}
      />
      <EmailView ref={emailViewRef} refetch={handleUpdate} />

      <Header>
        <TitleWrapper>
          <Title>{ACL?.BreadCrumb}</Title>
        </TitleWrapper>
        <HeaderButtons>
          <Button
            icon={isLoading ? 'clock' : 'refresh'}
            onClick={handleUpdate}
            hint="Refresh data"
            disabled={isLoading}
          />
        </HeaderButtons>
      </Header>
      <DataGridContainer visible>
        <DataGrid
          dataSource={adUserEmrData}
          keyExpr="UserId"
          noDataText={
            isLoading
              ? 'Loading...'
              : 'There are no Request(s) in this category'
          }
          height="75vh"
          showBorders
          focusedRowEnabled
          onSelectionChanged={selectionChanged}
        >
          <Selection mode="single" />
          <FilterRow visible applyFilter="auto" />
          <Sorting />
          <Selection mode="single" showCheckBoxesMode="always" />
          <Column dataField="DisplayName" caption="Name" />
          <Column dataField="AreaId" caption="Area">
            <Lookup
              dataSource={areasList}
              displayExpr="AreaDesc"
              valueExpr="AreaId"
            />
          </Column>
          <Column dataField="Status">
            <Lookup
              dataSource={[
                { Status: 'Active' },
                { Status: 'Inactive' },
                { Status: 'Disabled' },
              ]}
              displayExpr="Status"
              valueExpr="Status"
            />
          </Column>
          <MasterDetail
            enabled={false}
            component={({ data }) => (
              <TerminationDetail
                data={data}
                ACL={ACL}
                type="USER"
                loading={isLoading}
                requestStatusDataList={requestStatusDataList}
                requestMethodList={requestMethodList}
                editRequestPopupRef={editRequestPopupRef}
                emailViewRef={emailViewRef}
                sendEmailPopupRef={sendEmailPopupRef}
              />
            )}
            autoExpandAll={false}
            requestMethodList={requestMethodList}
            requestStatusDataList={requestStatusDataList}
          />
        </DataGrid>
      </DataGridContainer>
    </div>
  );
};

export default ACTAccessTermination;
