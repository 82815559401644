import React, { useState, useEffect } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from 'devextreme/ui/notify';
import { func, bool, string } from 'prop-types';

import API from '../../../../../services/API';

import {
  Container,
  Header,
  Body,
  Card,
  Fields,
  InputRadio,
  Label,
  TitleLabel,
  FieldLabel,
} from './styles';

export default function CompareDetails({
  detailsPopupVisible,
  setDetailsPopupVisible,
  UserId,
  UserName,
  onDataChanged,
}) {
  const exceptionList = [
    'UserId',
    'UserPrincipalName',
    'Name',
    'DepartmentId',
    'ExchangeDB',
    'IPPhone',
    'UseRank',
    'SDUserId',
    'AllowSwitch',
    'TeamId',
    'UserTeamOrder',
    'Categories',
    'AvailabilityPeriod',
    'ErrorMsg',
    'ChangedAt',
    'CreatedAt',
    'Synchronize',
    'IsManager',
    'Password',
    'StatusPhotoId',
    'AreaId',
    'Leave',
    'PhoneNumbers',
    'StaffId',
    'InitialMobileScreenId',
    'Active',
  ];

  const [compareUser, setCompareUser] = useState([]);
  const [filteredDataUser, setFilteredDataUser] = useState([]);
  const [userFields, setUserFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const bottomToolbarButtons = [
    {
      text: 'OK',
      type: 'success',
      hint: 'Click to save changes',
      onClick: () => {
        handleSaveClick();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setDetailsPopupVisible(false);
      },
    },
  ];

  useEffect(() => {
    if (compareUser) {
      const filtered = compareUser.filter((field) => {
        return !exceptionList.includes(field.Key);
      });
      setFilteredDataUser(filtered);
    } //eslint-disable-next-line
  }, [compareUser]);

  const onPopupShowing = async () => {
    setLoading(true);
    const res = await API.sendRequest(`ADUser/GetCompare/${UserId}`, 'get');
    if (res.message === 'No Data') {
      setLoading(false);
      notify(
        {
          message: 'This user has no data to compare. Please refresh the page.',
        },
        'error',
        5000
      );
      setDetailsPopupVisible(false);
    } else {
      setCompareUser(res.data);
    }
    setLoading(false);
  };

  const getFieldValue = (field) => {
    let object = document.querySelector(`input[name = '${field}']:checked`);
    if (object) {
      let result = object.value;
      if (field === 'Active') {
        return Boolean(result);
      } else {
        return result;
      }
    } else if (field === 'Pager') {
      return null;
    } else {
      throw new Error(
        `Please, choose one of the options in the ${field} field.`
      );
    }
  };

  const getOriginalFieldValue = (source, field) => {
    return compareUser
      .find((item) => item.Key === field)
      .Value.find((item) => item.Key === source).Value;
  };

  const getDepartmentId = () => {
    let deptName = getFieldValue('Department');
    let source = filteredDataUser
      .find((item) => item.Key === 'Department')
      .Value.find((item) => item.Value === deptName).Key;

    if (source) {
      return compareUser
        .find((item) => item.Key === 'DepartmentId')
        .Value.find((item) => item.Key === source).Value;
    }

    return null;
  };

  const getPhone = (value) => {
    return value.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
  };

  const renderFieldsData = (field, source) => {
    if (userFields.length === 0) {
      setUserFields([...userFields, field.Key]);
    } else {
      if (field.Key && !userFields.find((item) => item === field.Key)) {
        setUserFields([...userFields, field.Key]);
      }
    }
    return (
      <Label key={String(source.Key)}>
        <InputRadio
          type="radio"
          value={source.Value}
          disabled={field.Diff && source.Value ? false : true}
          name={field.Key}
          defaultChecked={
            (!field.Diff && source.Value) ||
            (source.Key === 'ad' &&
              field.Diff &&
              source.Value &&
              !field.Value[1].Value) ||
            (source.Key === 'db' &&
              field.Diff &&
              source.Value &&
              !field.Value[0].Value) ||
            (source.Key === 'db' &&
              !field.Diff &&
              !source.Value &&
              !field.Value[0].Value)
          }
        />
        {field.Key === 'Manager'
          ? source.Value.slice(
              source.Value.indexOf('CN='),
              source.Value.indexOf(',', source.Value.indexOf('CN='))
            ).replace('CN=', '')
          : field.PhoneTypeId === ''
          ? source.Value
          : getPhone(source.Value)}
      </Label>
    );
  };

  const onHidden = () => {
    setDetailsPopupVisible(false);
  };

  const handleSaveClick = async () => {
    try {
      let syncData = {};
      syncData['IsAdUser'] = true;

      let userData = {};
      userData['UserId'] = UserId;
      userData['IsManager'] = Boolean(getOriginalFieldValue('db', 'IsManager'));
      userData['Leave'] = Boolean(getOriginalFieldValue('db', 'Leave'));
      userData['Active'] = Boolean(getOriginalFieldValue('db', 'Active'));
      userData['Synchronize'] = true;
      userData['DepartmentId'] = Number(getDepartmentId());
      userData['AreaId'] = Number(getOriginalFieldValue('ad', 'AreaId'));
      userData['StatusPhotoId'] = Number(
        getOriginalFieldValue('ad', 'StatusPhotoId')
      );
      userData['OU'] = getOriginalFieldValue('ad', 'OU');
      userData['UserPrincipalName'] = getOriginalFieldValue(
        'ad',
        'UserPrincipalName'
      );
      userData['Name'] = getOriginalFieldValue('ad', 'Name');

      let phonesData = [];
      let order = 1;
      userFields.forEach((field) => {
        let item = filteredDataUser.find((user) => user.Key === field);
        if (item.PhoneTypeId === '') {
          userData[field] = getFieldValue(field);
        } else {
          phonesData.push({
            ContactId: UserId,
            ContactPhoneOrder: order,
            PhoneTypeId: item.PhoneTypeId,
            PhoneNumber: getPhone(getFieldValue(field)),
          });

          order++;
        }
      });

      syncData['User'] = userData;
      syncData['Phones'] = phonesData;
      syncData['Groups'] = [];

      await API.sendRequest('ADUser/SaveComplete', 'POST', syncData);

      notify(
        {
          message: 'Data saved!',
        },
        'success',
        3000
      );

      onDataChanged();
    } catch (err) {
      notify(
        {
          message: err.message,
        },
        'error',
        3000
      );
    }
  };

  return (
    <>
      <Popup
        onHidden={onHidden}
        visible={detailsPopupVisible}
        title={UserName}
        width={'70%'}
        height={'90%'}
        onShowing={onPopupShowing}
      >
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={loading}
          message="Loading"
          closeOnOutsideClick={false}
        />
        <Header>
          <TitleLabel>Active Directory</TitleLabel>
          <TitleLabel>Team Relay</TitleLabel>
        </Header>
        <ScrollView showScrollbar="always" scrollByContent height="98%">
          <Container>
            {filteredDataUser && filteredDataUser.length > 0 && (
              <Body>
                {filteredDataUser.map((field) => (
                  <Card diff={field.Diff} key={String(field.Key)}>
                    <FieldLabel>{field.Key}</FieldLabel>
                    <Fields>
                      {field.Value.filter(
                        (data) => data.Key === 'ad'
                      ).map((filteredData) =>
                        renderFieldsData(field, filteredData)
                      )}
                      {field.Value.filter(
                        (data) => data.Key === 'db'
                      ).map((filteredData) =>
                        renderFieldsData(field, filteredData)
                      )}
                    </Fields>
                  </Card>
                ))}
              </Body>
            )}
          </Container>
        </ScrollView>
        {bottomToolbarButtons.map((buttonOptions, index) => (
          <ToolbarItem
            key={index}
            options={{ stylingMode: 'contained', ...buttonOptions }}
            widget="dxButton"
            toolbar="bottom"
            location="after"
          />
        ))}
      </Popup>
    </>
  );
}

CompareDetails.propTypes = {
  detailsPopupVisible: bool,
  setDetailsPopupVisible: func,
  UserId: string,
  UserName: string,
};

CompareDetails.defaultProps = {
  detailsPopupVisible: false,
  setDetailsPopupVisible: () => {},
  UserId: '',
  UserName: '',
};
