import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import './styles.css';
import API from '../../../services/API';

const menus = new CustomStore({
  key: 'MenuId',
  load: async () => {
    const { data } = await API.sendRequest('Menu/ListByUser/1', 'get');
    return data;
  },
});

const DesktopMenu = (props) => {
  const history = useHistory();

  const itemClick = (e) => {
    if (e.itemData.url) {
      history.push({
        pathname: `/${e.itemData.url}`,
        state: { idRoute: e.itemData.id },
      });
      // window.location.hash = e.itemData.url;
    }
  };

  return (
    <div>
      <Menu
        dataSource={menus}
        displayExpr="name"
        showFirstSubmenuMode={{
          name: 'onHover',
          delay: { show: 0, hide: 500 },
        }}
        orientation="horizontal"
        submenuDirection="auto"
        hideSubmenuOnMouseLeave={false}
        onItemClick={itemClick}
        cssClass="desktop-menu"
      />
    </div>
  );
};

export default DesktopMenu;
