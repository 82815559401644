import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { array, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '../contacts/Avatar';
import API from '../../services/API';
import CustomCheckbox from '../Checkbox';
import {
  BoxAssignment,
  BoxCenter,
  BoxSearch,
  ButtonCustom,
  Header,
  StyledMenu,
  StyledMenuItem,
  SubHeader,
  Text,
  useStyles,
  WrapperButtons,
} from './styles';

export default function RolesPopup({
  anchorEl,
  handleClose,
  roles,
  setRoles,
  setErrorSnack,
  handleClickSnack,
  title,
  userId,
}) {
  const classes = useStyles();
  const [rolesApi, setRolesApi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [term, setTerm] = useState('');

  const [position, setPosition] = useState(0);

  useEffect(() => {
    async function loadRoles() {
      const { data } = await API.sendRequest('Role', 'get');

      const results = data.map((d) => {
        const existItem = roles.filter((it) => it.RoleId === d.RoleId);
        if (existItem && existItem.length > 0) {
          d.check = true;
        } else {
          d.check = false;
        }
        return d;
      });

      setRolesApi(results);
      setIsLoading(false);
    }

    loadRoles();
    // eslint-disable-next-line
  }, []);

  const handleChangeCheckbox = (event) => {
    const { id, checked } = event.target;

    setRolesApi((prevState) =>
      prevState.map((prev) => {
        if (prev.ContactId === id) {
          prev.check = checked;
        }
        return prev;
      })
    );
  };

  const handleClickCheckbox = (id, checked) => {
    setRolesApi((prevState) =>
      prevState.map((prev) => {
        if (prev.RoleId === id) {
          prev.check = !checked;
        }
        return prev;
      })
    );
  };

  const handleSave = async () => {
    setIsLoading(true);

    const rolesSave = rolesApi.filter((role) => role.check);
    const rolesSaveId = rolesSave.map((p) => ({ RoleId: p.RoleId }));

    // SAVE
    let data;
    if (rolesSaveId.length > 0) {
      const response = await API.sendRequest('UserRole/SaveRoles', 'post', {
        UserId: userId,
        Roles: [...rolesSaveId],
      });
      data = response.data;
    } else {
      const response = await API.sendRequest('UserRole/SaveRoles', 'post', {
        UserId: userId,
        Roles: [],
      });
      data = response.data;
    }

    if (data) {
      setRoles(rolesSave);

      setIsLoading(false);
      handleClose();
      handleClickSnack();
    } else {
      setIsLoading(false);
      setErrorSnack(true);
      handleClickSnack();
    }
  };

  const onStart = () => {
    setPosition(position + 1);
  };

  const onStop = () => {
    setPosition(position - 1);
  };

  const filterRoles = rolesApi.filter((role) =>
    role.RoleDesc.toLowerCase().includes(term.toLowerCase())
  );
  const dragHandlers = { onStart, onStop };

  return (
    <Draggable {...dragHandlers} handle="strong">
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <strong>
          <Header color="#D5D5D5">
            <SubHeader>
              <Text color="#000" weight={300} size="12px" />
              <Text color="#000" weight="bold" size="16px" upper>
                {title}
              </Text>
            </SubHeader>
            <IconButton
              className={classes.buttonClose}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" className={classes.iconClose} />
            </IconButton>
          </Header>
        </strong>
        <BoxSearch>
          {!isLoading && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                type="text"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                // inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => {
                  setTerm(e.target.value);
                }}
              />
            </div>
          )}
        </BoxSearch>
        <BoxAssignment>
          {!isLoading ? (
            <PerfectScrollbar>
              {filterRoles &&
                filterRoles.map((listItem) => (
                  <StyledMenuItem
                    key={listItem.RoleId}
                    onClick={() =>
                      handleClickCheckbox(listItem.RoleId, listItem.check)
                    }
                  >
                    <ListItemIcon>
                      <CustomCheckbox
                        id={listItem.RoleId.toString()}
                        value={listItem.check}
                        handleChange={handleChangeCheckbox}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={listItem.RoleDesc}
                      className={classes.textList}
                    />
                  </StyledMenuItem>
                ))}
            </PerfectScrollbar>
          ) : (
            <BoxCenter>
              <CircularProgress />
            </BoxCenter>
          )}
        </BoxAssignment>

        <WrapperButtons>
          <ButtonCustom variant="outlined" color="default" onClick={handleSave}>
            OK
          </ButtonCustom>
          <ButtonCustom
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </ButtonCustom>
        </WrapperButtons>
      </StyledMenu>
    </Draggable>
  );
}

RolesPopup.propTypes = {
  anchorEl: object.isRequired,
  handleClose: func.isRequired,
  setRoles: func.isRequired,
  setErrorSnack: func.isRequired,
  handleClickSnack: func.isRequired,
  roles: array,
  // roles: arrayOf.isRequired,
  title: string.isRequired,
  roleId: number,
  // roleId: number.isRequired,
};
