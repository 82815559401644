import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  colorCellRender: {
    height: '32px',
    padding: '0 5px',
    lineHeight: '32px',
  },
  colorCellRenderTd: {
    padding: '0 !important',
  },
  divGridContainer: {
    margin: 'auto',
    width: '95%',
    height: '95vh',
    textAlign: 'center',
  },
  Header: {
    display: 'flex',
  },
}));
