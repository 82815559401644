import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { DataGrid } from 'devextreme-react/data-grid';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;
export const Content = styled.div`
  display: flex;
`;

export const ColumnDivider = styled.div`
  width: 50%;
  padding-right: 15px;
`;

export const BoxAvatarName = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const Name = styled.span`
  margin-left: 10px;
`;

export const BoxSubtitle = styled.div`
  height: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #b4b4b4;
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonCustom = styled(Button)`
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  width: 70px;
  margin: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${(props) =>
      props.bg === '#FFF' ? 'rgba(0, 0, 0, 0.08)' : '#c8c8c8'};
  }
`;

export const CellCheckbox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DataGridCustom = styled(DataGrid)`
  height: 223px;
`;
