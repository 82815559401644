import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import {
  Column,
  DataGrid,
  Paging,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';
import usePermissions from '../../hooks/usePermissions';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import { Template } from 'devextreme-react/core/template';

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const HelpPicture = () => {
  const [pictures, setPictures] = useState(null);
  const [menus, setMenus] = useState(null);
  const [menuRootId, setMenuRootId] = useState(1);
  const classes = useStyles();

  const rootMenuList = [
    {
      value: 1,
      text: 'Web Admin',
    },
    {
      value: 2,
      text: 'Mobile',
    },
  ];

  useEffect(() => {
    async function init() {
      setMenus(
        (await API.sendRequest(`Menu/ChildrenOnly/${menuRootId}`, 'get')).data
      );
    }

    const picturesTmp = new CustomStore({
      key: 'HelpPictureId',
      load: async () => {
        const data = (
          await API.sendRequest(`HelpPicture/List/${menuRootId}`, 'get')
        ).data;
        return data;
      },
      insert: async (data) => {
        await API.sendRequest(`HelpPicture`, 'post', data);
      },
      update: async (id, data) => {
        await API.sendRequest(`HelpPicture`, 'post', data);
      },
      remove: async (id) => {
        await API.sendRequest(`HelpPicture/${id}`, 'delete');
      },
    });

    setPictures(picturesTmp);

    init();
  }, [menuRootId]);

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /*********************************/

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'breadCrumb',
      },
      {
        location: 'before',
        widget: 'dxSelectBox',
        options: {
          width: 200,
          items: rootMenuList,
          displayExpr: 'text',
          valueExpr: 'value',
          value: menuRootId,
          onValueChanged: menuRootChanged,
        },
      }
    );
  };

  const menuRootChanged = (e) => {
    setMenuRootId(e.value);
  };

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={pictures}
        //keyExpr="HelpPictureId"
        allowColumnReordering={true}
        showBorders={true}
        onRowUpdating={onRowUpdating}
        rowAlternationEnabled={true}
        focusedRowEnabled={true}
        showRowLines={true}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Paging enabled={false} />
        <Editing
          useIcons
          mode="form"
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        />

        <Column dataField="PictureDesc" caption="Description" />
        <Column dataField="PictureOrder" caption="Order" />
        <Column dataField="PictureUrl" />
        <Column dataField="Active" />
        <Column dataField="MenuId" caption="Menu">
          <Lookup
            dataSource={menus}
            displayExpr="MenuFullCaption"
            valueExpr="MenuId"
          />
        </Column>
        <Column dataField="AccessControl" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default HelpPicture;
