/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useMemo } from 'react';
// import Button from '@material-ui/core/Button';
import Button from 'devextreme-react/button';
import DropDownButton from 'devextreme-react/drop-down-button';
import RadioGroup from 'devextreme-react/radio-group';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  format,
  getDaysInMonth,
  differenceInMonths,
  parseISO,
  getDate,
} from 'date-fns';
import { DateBox } from 'devextreme-react/date-box';
import DataGrid, {
  Column,
  ColumnFixing,
  Grouping,
  GroupPanel,
  Paging,
  Scrolling,
} from 'devextreme-react/data-grid';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import getLoggedUser from '../../helpers/userFunctions';

import outlookImg from '../../assets/outlook2.png';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
// import { Center } from 'devextreme-react/map';

import usePermissions from '../../hooks/usePermissions';
import usePrevious from '../../hooks/usePrevious';
import Avatar from '../../components/contacts/Avatar';
import ScheduleClonePopup from '../../components/popups/ScheduleClonePopup';
import ContactAgendaPopup from '../../components/popups/ContactAgendaPopup';
import ConfirmationPopup from './components/popups/ConfirmationPopup';
import ProgressStatus from '../../components/ProgressStatus';
import ExchangePopup from '../../components/Exchange';
import Cell from './components/Cell';
import clone from '../../helpers/clone';
import API from '../../services/API';
import useStyles, {
  LegendContainer,
  LegendItem,
  LegendSquare,
  LoadContainer,
  LegendLabel,
  UpdateLabel,
  RadioLegend,
  RadioContainer,
  StatusContainer,
  WarningLabel,
  BgLabelWrapper,
} from './styles';
import pickTextColorBasedOnBgColor from '../../helpers/pickTextColorBasedOnBgColor';
import PopupCell from '../../components/PopupCell';
import { BreadCrumb, LoadPermissions } from '../../global';
import generateUUID from '../../helpers/uuid';
import { returnTwoDigits } from '../../helpers/twoDigits';
import PasteConfirmationPopup from './components/popups/PasteConfirmationPopup';
import getChangeHistory from '../../utils/getChangeHistory';
import HistoryPopup from '../../components/popups/HistoryPopup';
import { CheckBox, LoadPanel, SelectBox } from 'devextreme-react';
import HolidaysPopup from './components/popups/HolidaysPopup';

function getInitialDate(area) {
  const itemStorage = `schedule_${area}`;
  const now = localStorage.getItem(itemStorage)
    ? new Date(JSON.parse(localStorage.getItem(itemStorage)))
    : new Date();
  localStorage.setItem(itemStorage, JSON.stringify(now));
  return now;
}

const Schedule = (props) => {
  const { area, path, areaData } = props;
  const [currentDate, setCurrentDate] = useState(getInitialDate(area));
  const [holidaysMonth, setHolidaysMonth] = useState([]);
  const [holidayPopupData, setHolidayPopupData] = useState([]);
  const [prevDate, setPrevDate] = useState(getInitialDate(area));
  const [contacts, setContacts] = useState(null);
  const [currentContact, setCurrentContact] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const [selectedCellsContent, setSelectedCellsContent] = useState({});
  const [appointmentTypesColors, setAppointmentTypesColors] = useState({});
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [scheduleClonePopupVisible, setScheduleClonePopupVisible] =
    useState(false);
  const [agendaPopupVisible, setAgendaPopupVisible] = useState(false);
  const [holidayPopupVisible, setHolidayPopupVisible] = useState(false);
  const [exchangePopupVisible, setExchangePopupVisible] = useState(false);
  const [confirmationPopupVisible, setConfirmationPopupVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [availabilityList, setAvailabilityList] = useState([]);
  const [periodsList, setPeriodsList] = useState([]);
  const [anchorElCell, setAnchorElCell] = useState(null);
  const [dataPopup, setDataPopup] = useState(null);
  const [draftData, setDraftData] = useState(null);
  const [draftStatus, setDraftStatus] = useState(0);
  const [cellIsPast, setCellIsPast] = useState(false);
  const [taskQty, setTaskQty] = useState(0);
  const [monthDiff, setMonthDiff] = useState(0);
  const [appTypeOverdueCount, setAppTypeOverdueCount] = useState(0);
  const [copySource, setCopySource] = useState(null);
  const [pasteConfirmationPopup, setPasteConfirmationPopup] = useState({
    visible: false,
    originUser: null,
    destinyUser: null,
    pasteFunction: () => { },
    cancelFunction: () => { },
  });
  const [changeHistory, setChangeHistory] = useState(null);
  const [historyPopupVisible, setHistoryPopupVisible] = useState(null);
  const [allowOverrideAppointments, setAllowOverrideAppointments] =
    useState(true);
  const [loadingInvalidate, setLoadingInvalidate] = useState(false);


  const userLoggedId = getLoggedUser();

  const classes = useStyles();

  const previousDate = usePrevious(currentDate);

  const scheduleGridRef = useRef(null);

  const now = new Date();

  const saveAsOptions = [
    {
      id: 1,
      name: 'Excel',
      icon: 'xlsxfile',
      onClick: handleExportGridToExcel,
    },
    { id: 2, name: 'PDF', icon: 'exportpdf', onClick: handleExportGridToPdf },
  ];

  const visualizationOptions = [
    { id: 0, text: 'By Teams' },
    { id: 1, text: 'By Groups' },
  ];

  const radioOptions = [
    {
      id: 0,
      text: 'Not started',
      icon: 'far fa-file',
      visible: false,
    },
    { id: 1, text: 'Draft', icon: 'fas fa-edit', disabled: draftStatus === 2 },
    { id: 2, text: 'Released', icon: 'fas fa-mobile-alt' },
  ];

  useEffect(() => {
    async function init() {
      const pList = (await API.sendRequest('Period', 'get')).data;
      setPeriodsList(pList);

      await API.sendRequest(`AppointmentType/${area}`, 'get').then(
        ({ data }) => {
          if (data) {
            getAvailabilityItem(data, pList);
          }
        }
      );

      setAppointmentTypesColors(
        appointmentTypes.reduce(
          (acumulator, currentValue) => ({
            ...acumulator,
            [currentValue.AppointmentTypeCode]: currentValue.Color,
          }),
          {}
        )
      );
    }

    init();

    function getAvailabilityItem(data, pList) {
      data.forEach((item) => {
        if (item.DayOff) {
          const period = pList.find((obj) => obj.PeriodId === 4);
          item.AvailabilityColor = period.PeriodColor;
          return;
        }

        if (item.AllDay) {
          const period = pList.find((obj) => obj.PeriodId === 3);
          item.AvailabilityColor = period.PeriodColor;
          return;
        }

        if (item.StartTime) {
          const parseStart = parseInt(item.StartTime.replace(/\D+/g, ''));
          const period =
            parseStart >= 0 && parseStart < 120000
              ? pList.find((obj) => obj.PeriodId === 1)
              : pList.find((obj) => obj.PeriodId === 2);
          item.AvailabilityColor = period.PeriodColor;
          return;
        }
        const period = pList.find((obj) => obj.PeriodId === 0);
        item.AvailabilityColor = period.PeriodColor;
      });
      setAppointmentTypes(data);

      let appCount = 0;
      data.forEach((item) => {
        if (item.Overdue) {
          // eslint-disable-next-line no-plusplus
          appCount++;
        }
      });
      setAppTypeOverdueCount(appCount);
    }
  }, [area]); // eslint-disable-line

  useEffect(() => {
    setMonthDiff(
      differenceInMonths(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth(), 1)
      )
    );
    const getWeekDaysInMonth = () => {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();

      const nDays = getDaysInMonth(new Date(year, month));

      const fields = [];

      for (let i = 1; i <= nDays; i++) {
        fields.push(`${i.toString()}-${getDayName(new Date(year, month, i))}`);
      }

      return fields;
    };

    const handleGetHolidaysMonth = async () => {
      const holidays = await API.sendRequest(
        `Holiday/ListByMonth/${currentDate.getFullYear()}/${currentDate.getMonth() + 1
        }`
      );
      const holidaysDates = holidays?.data?.map((item) =>
        item.HolidayDate.slice(0, -9)
      );
      setHolidaysMonth(holidaysDates);
      setHolidayPopupData(holidays?.data);
    };

    async function init() {
      setIsLoading(true);

      handleGetHolidaysMonth();

      const yearMonth = `${currentDate.getFullYear()}-${returnTwoDigits(
        currentDate.getMonth() + 1
      )}`;
      const availability = (
        await API.sendRequest(`UserAvailability/${yearMonth}`, 'get')
      ).data;
      setAvailabilityList(availability);

      let schedule = null;
      if (areaData.UseTeams) {
        const resTeams = await API.sendRequest(
          `ContactSchedule/ListMonthTeam/${area}/${currentDate.getFullYear()}/${currentDate.getMonth() + 1
          }`,
          'get'
        );
        if (resTeams.status === 200) {
          schedule = resTeams.data;
        }
      } else {
        const resGroups = await API.sendRequest(
          `ContactSchedule/ListMonthWeb/${area}/${currentDate.getFullYear()}/${currentDate.getMonth() + 1
          }`,
          'get'
        );

        if (resGroups.status === 200) {
          schedule = resGroups.data;
        }
      }
      setContacts(schedule);
      const weekDaysInMonth = getWeekDaysInMonth();
      setDaysInMonth(weekDaysInMonth);

      const draft = (
        await API.sendRequest(
          `Draft/ByMonth/${area}/${currentDate.getFullYear()}/${currentDate.getMonth() + 1
          }`,
          'get'
        )
      ).data;

      if (draft && draft.UseDraft) {
        setTaskQty(taskCounter(weekDaysInMonth, schedule));
      }

      if (!draft) {
        const newDraft = {
          AreaId: area,
          Month: currentDate.getMonth() + 1,
          Year: currentDate.getFullYear(),
          DraftStatusId:
            monthDiff > -1
              ? taskCounter(weekDaysInMonth, schedule) > 0
                ? 1
                : 0
              : 2,
        };
        saveDraftData(newDraft);
      } else {
        setDraftData(draft);
        if (draft.UseDraft) {
          setDraftStatus(draft.DraftStatusId);
        }
      }
    }
    init();
  }, [currentDate, areaData]); //eslint-disable-line

  useEffect(() => {
    if (draftData && draftData.UseDraft) {
      setTaskQty(taskCounter(daysInMonth, contacts));
    }
  }, [contacts, daysInMonth]); //eslint-disable-line

  useEffect(() => {
    if (
      prevDate &&
      format(prevDate, 'yyyyMM') === format(currentDate, 'yyyyMM')
    ) {
      if (taskQty > 0) {
        if (draftData && draftData.UseDraft && draftData.DraftStatusId === 0) {
          const draftCopy = draftData;
          draftCopy.DraftStatusId = 1;
          saveDraftData(draftCopy);
        }
      }
    }

    if (
      !prevDate ||
      format(prevDate, 'yyyyMM') !== format(currentDate, 'yyyyMM')
    ) {
      setPrevDate(currentDate);
    }
  }, [taskQty]); //eslint-disable-line

  const taskCounter = (weekdays, schedule) => {
    let count = 0;
    weekdays.forEach((day) => {
      schedule.forEach((contact) => {
        if (contact[day]) {
          count++;
        }
      });
    });

    return count;
  };

  const saveDraftData = async (draftToSave) => {
    setDraftStatus(draftToSave.DraftStatusId);
    const { data } = await API.sendRequest(`Draft`, 'post', draftToSave);
    setDraftData(data);
  };

  const renderRadioGroupItem = (itemData) => (
    <>
      <RadioLegend>
        <span>
          <i className={itemData.icon} />
          {itemData.text}
        </span>
      </RadioLegend>
    </>
  );

  const handleDraftStatusChange = ({ value }) => {
    setDraftStatus(value?.id ?? 0);
    if (draftData) {
      if (value.id === 0 && !draftData?.DraftStatusId) {
        const draftCopy = draftData;
        draftCopy.DraftStatusId = value.id;
        saveDraftData(draftCopy);
        return;
      }

      setConfirmationPopupVisible(
        draftData.DraftStatusId !== 2 && value.id === 2
      );
    }
  };

  function handleGridContentReady() {
    setIsLoading(false);
  }

  const handleClickPopupCell = (event, source) => {
    setDataPopup(source);
    const sourceObject = JSON.parse(source.value);
    setAnchorElCell(sourceObject?.[0]?.Locked ? null : event.currentTarget);
  };

  const handleAvatarClick = (contact) => {
    setCurrentContact(contact);
    setAgendaPopupVisible(true);
  };

  const handleChangeVisualization = async (e) => {
    setIsLoading(true);
    let newVisualization = null;
    if (e.value === 0) {
      newVisualization = (
        await API.sendRequest(
          `ContactSchedule/ListMonthTeam/${area}/${currentDate.getFullYear()}/${currentDate.getMonth() + 1
          }`,
          'get'
        )
      ).data;
    }
    if (e.value === 1) {
      newVisualization = (
        await API.sendRequest(
          `ContactSchedule/ListMonthWeb/${area}/${currentDate.getFullYear()}/${currentDate.getMonth() + 1
          }`,
          'get'
        )
      ).data;
    }

    setContacts(newVisualization);
    setIsLoading(false);
  };

  const onMonthChanged = (e) => {
    const newDate = new Date(e.value);
    setPrevDate(previousDate);
    setCurrentDate(newDate);
    const itemStorage = `schedule_${area}`;
    localStorage.setItem(itemStorage, JSON.stringify(newDate));
  };

  const renderFullNameHeader = (data) => {
    const style = {
      height: '34px',
      lineHeight: '34px',
      fontWeight: 'bold',
    };

    return <div style={style}>{data.column.caption}</div>;
  };

  const renderDaysHeader = (data) => {
    const day = data.column.dataField ? data.column.dataField : '';
    const isWeekend = '-Sat-Sun'.includes(day.substr(day.length - 4));
    const isToday =
      now.getDate() === parseInt(day.replace(/\D+/g, '')) &&
      now.getMonth() + 1 === currentDate.getMonth() + 1 &&
      now.getFullYear() === currentDate.getFullYear();
    const dayNumber = Number(day.slice(0, -4));
    const holidaysNumbers = holidaysMonth?.map((item) => Number(item.slice(8)));
    const isHoliday = holidaysNumbers.includes(dayNumber);
    const bg = isToday
      ? '#9dcbff'
      : isWeekend
        ? '#eef797'
        : isHoliday
          ? '#90D052'
          : '#FFF';
    const style = {
      backgroundColor: bg,
      height: '34px',
      lineHeight: '34px',
      fontWeight: 'bold',
    };

    return <div style={style}>{data.column.caption}</div>;
  };

  const cellRenderAvatar = (source) => {
    if (source.data.isSummary) {
      const style = {
        backgroundColor: source.data.Color,
        color: pickTextColorBasedOnBgColor(source.data.Color, '#FFF', '#000'),
        marginLeft: 0,
        marginRight: 0,
      };
      return (
        <div className={classes.fullName} style={style}>
          {source.data.Initials}
        </div>
      );
    }
    return (
      <div>
        <Avatar
          contact={source.data}
          h={30}
          w={30}
          style={{ marginLeft: 0, marginRight: 0 }}
          onClick={() => handleAvatarClick(source.data)}
        />
      </div>
    );
  };

  const cellRenderReadOnly = (source) => {
    if (source.data) {
      let style = {
        backgroundColor: source.data.Color,
        color: pickTextColorBasedOnBgColor(source.data.Color, '#FFF', '#000'),
      };

      const fullName = source.data.FullName.replace('zTotal', 'Total');
      if (fullName === 'Total') {
        style = {
          backgroundColor: source.data.Color,
          color: pickTextColorBasedOnBgColor(source.data.Color, '#FFF', '#000'),
          fontWeight: 'bold',
        };
      }
      return (
        <div className={classes.fullName} style={style}>
          {fullName}
        </div>
      );
    }
    return <img height="34px" src="assets/blank.jpg" alt="" />;
  };

  const cellRender = (source, data) => (
    <Cell
      source={source}
      currentDate={currentDate}
      selectedCells={selectedCells}
      handleCellClick={handleCellClick}
      handleMenuClick={handleMenuClick}
      classes={classes}
      appointmentTypesColors={appointmentTypesColors}
      save={save}
      contacts={contacts}
      setContacts={setContacts}
      ACL={ACL}
      availabilityList={availabilityList}
      periodsList={periodsList}
      holidaysMonth={holidaysMonth}
    />
  );

  const getDayName = (date) =>
    date.toLocaleDateString('en-us', { weekday: 'short' });

  //* * Move into useEffect to prevent infinite re-renders.
  // const Week = () => {
  //   const year = currentDate.getFullYear();
  //   const month = currentDate.getMonth();

  //   // To get the number of days need pass month base 1
  //   const nDays = new Date(year, month + 1, 0).getDate();

  //   const fields = [];

  //   for (let i = 1; i <= nDays; i++) {
  //     fields.push(`${i.toString()}-${getDayName(new Date(year, month, i))}`);
  //   }

  //   return fields;
  // };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const formatSelectedCell = (source) => {
    let item = {};

    if (source.value) {
      const itemArray = JSON.parse(source.value);
      item = itemArray[0] || itemArray;
    }

    item.day = source.column.dataField ? source.column.dataField : '';
    item.contact = source.data;

    return item;
  };

  const handleMenuClick = (event, source) => {
    event.preventDefault();
    if (!ACL.AddNew && !ACL.Update) {
      return false;
    }

    if (source.data.isSummary) {
      return false;
    }

    setCellIsPast(
      (now.getDate() > parseInt(source.column.dataField.replace(/\D+/g, '')) &&
        now.getMonth() + 1 === currentDate.getMonth() + 1 &&
        now.getFullYear() === currentDate.getFullYear()) ||
      (now.getMonth() + 1 > currentDate.getMonth() + 1 &&
        now.getFullYear() === currentDate.getFullYear()) ||
      now.getFullYear() > currentDate.getFullYear()
    );

    setSelectedCell(formatSelectedCell(source));

    setMenuAnchorEl(event.currentTarget);
    return false;
  };

  const handleCellClick = (event, source) => {

    event.preventDefault();


    if (!ACL.AddNew && !ACL.Update) {
      return false;
    }

    if (source.data.isSummary) {
      setSelectedCells([]);
      return true;
    }

    if (!event.ctrlKey) {
      if (source.text !== '') {
        handleClickPopupCell(event, source);
      }

      setSelectedCells([]);
      return true;
    }

    const yearMonth = format(currentDate, 'yyyy-MM');
    const day = source.column.dataField ? source.column.dataField : '';
    const cellId = `${yearMonth}-${day}#${source.data.ContactId}`;

    let cloneSelectedCells = clone(selectedCells);
    if (selectedCells.includes(cellId)) {
      cloneSelectedCells = cloneSelectedCells.filter(
        (selectedCellId) => selectedCellId !== cellId
      );
    } else {
      cloneSelectedCells.push(cellId);
    }

    setSelectedCells(cloneSelectedCells);

    const cloneSelectedCellsContent = clone(selectedCellsContent);
    cloneSelectedCellsContent[cellId] = formatSelectedCell(source);
    setSelectedCellsContent(cloneSelectedCellsContent);

    return true;
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    handleMenuClose();
  };

  const save = (saveItem, day) => {
    const yearMonth = format(currentDate, 'yyyy-MM');

    saveItem.AreaId = area;

    // The Stored procedure will treat the time part
    if (!saveItem.ContactScheduleId) {
      saveItem.Id = generateUUID();
      saveItem.ContactScheduleId = saveItem.Id;
    }


    saveItem.StartDate = `${yearMonth}-${day.padStart(2, '0')}T00:00:00`;
    saveItem.EndDate = `${yearMonth}-${day.padStart(2, '0')}T23:59:59`;
    saveItem.AllDay = true;
    // The Stored procedure will treat the time part

    if (saveItem.contact && saveItem.contact.ContactId) {
      saveItem.ContactId = saveItem.contact.ContactId;
    }

    API.sendRequest('ContactSchedule', 'post', saveItem);

    return saveItem;
  };

  // const [supports, setSupports] = useState(null);

  useEffect(() => {
    async function searchSupport() {
      try {
        const { data } = await API.sendRequest(
          `ContactSchedule/ListContacts/${area}`,
          'get',
          {}
        );

        // setSupports(data);

        return data;
      } catch (e) {
        console.log(e);
      }
    }

    searchSupport(); // eslint-disable-next-line
  }, []);

  const handleMenuSave = (appointmentTypeCode, overdue, appointmentColor) => {
    handleMenuClose();

    let cellsToSave = [selectedCell];
    if (selectedCells.length) {
      cellsToSave = []; // eslint-disable-next-line
      for (const cellId of selectedCells) {
        cellsToSave.push(selectedCellsContent[cellId]);
      }
    }

    const contactsCopy = clone(contacts);

    // eslint-disable-next-line
    for (const cellToSave of cellsToSave) {
      const day = cellToSave.day.split('-')[0];
      const contactIndex = contacts.findIndex(
        (contact) => contact.ContactId === cellToSave.contact.ContactId
      );

      const existingValue = JSON.parse(
        contactsCopy[contactIndex][cellToSave.day] || '[]'
      );


      let saveItem = {};
      saveItem.Subject = appointmentTypeCode;
      saveItem.Sb = appointmentTypeCode;
      saveItem.Remarks = cellToSave.Rm || null;
      saveItem.ContactId = cellToSave.contact.ContactId;
      saveItem.ContactScheduleId =
        allowOverrideAppointments && cellToSave.Id
          ? cellToSave.Id.toLowerCase()
          : generateUUID();
      saveItem.Id = saveItem.ContactScheduleId;
      saveItem.Color = cellToSave.Cl || '#FFF';
      saveItem.Cl = appointmentColor || '#FFF';
      saveItem.Od = overdue;
      saveItem.Override = allowOverrideAppointments;
      saveItem = save(saveItem, day);

      if (!allowOverrideAppointments) {
        existingValue.push(saveItem);
      } else if (existingValue.some(item => item.Sb === 'LOA') && allowOverrideAppointments) {
        existingValue.push(saveItem);
      } else {
        if (existingValue.length > 1) {
          existingValue.splice(0, existingValue.length);
        }
        existingValue[0] = saveItem;
      }
      contactsCopy[contactIndex][cellToSave.day] = JSON.stringify(
        appointmentTypeCode === 'CLEAR' ? (existingValue.some(item => item.Sb === 'LOA') ? existingValue.filter(item => item.Sb === 'LOA') : []) : existingValue
      );
    }

    setContacts(contactsCopy);
    setSelectedCellsContent({});
    setSelectedCells([]);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  function handlePasteClick(destinyUser) {
    setPasteConfirmationPopup({
      visible: true,
      originUser: copySource,
      destinyUser,
      pasteFunction: () => {
        pasteSchedule(copySource, destinyUser);
      },
      cancelFunction: () => {
        setPasteConfirmationPopup((prevState) => ({
          ...prevState,
          visible: false,
        }));
        setCopySource(null);
      },
    });
  }

  function pasteSchedule(originUser, destinyUser) {
    API.sendRequest(
      `ContactSchedule/CopyMonth/${currentDate.getFullYear()}/${currentDate.getMonth() + 1
      }/${originUser.ContactId}/${destinyUser.ContactId}`,
      'POST'
    );
    const today = getDate(now);

    setContacts((contactState) => {
      const destinyIndex = contactState.findIndex(
        (c) => c.ContactId === destinyUser.ContactId
      );
      contactState[destinyIndex].MonthCopied = true;

      daysInMonth.forEach((day) => {
        if (monthDiff === 0) {
          const currentDay = Number(day.substring(0, day.indexOf('-')));
          if (currentDay >= today) {
            contactState[destinyIndex][day] = originUser[day];
          }
        } else {
          contactState[destinyIndex][day] = originUser[day];
        }
      });
      return contactState;
    });
    scheduleGridRef.current.instance.refresh(true);
    setCopySource(null);
    setPasteConfirmationPopup({
      visible: false,
      originUser: null,
      destinyUser: null,
      pasteFunction: () => { },
      cancelFunction: () => { },
    });
  }

  async function handleShowHistory(userId) {
    const registryId = `${userId}${currentDate.getFullYear()}${String(
      currentDate.getMonth() + 1
    ).padStart(2, '0')}`;
    await getChangeHistory('ScheduleMonth', registryId).then((ret) => {
      if (ret) {
        setChangeHistory(ret);
        setHistoryPopupVisible(true);
      }
    });
  }

  function copyMonthButtonsRender(row) {
    const userSelected = row.data;

    if (monthDiff >= 0 && !userSelected.isSummary) {
      let buttonIcon = 'paste';
      let onClickFunction = () => {
        handlePasteClick(userSelected);
      };
      let buttonType = 'success';
      let buttonHint = `Paste schedules from ${copySource ? copySource.FullName : ''
        }`;

      if (!copySource) {
        buttonIcon = 'copy';
        onClickFunction = () => {
          setCopySource(userSelected);
        };
        buttonType = 'default';
        buttonHint = `Copy schedules`;
      }

      if (copySource && copySource.ContactId === userSelected.ContactId) {
        buttonIcon = 'clear';
        onClickFunction = () => {
          setCopySource(null);
        };
        buttonType = 'danger';
        buttonHint = 'Cancel copy';
      }

      let daysScheduled = 0;
      daysInMonth.forEach((day) => {
        if (row.data[day]) {
          daysScheduled++;
        }
      });

      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2px' }}>
          {monthDiff >= 0 && (
            <Button
              disabled={buttonIcon === 'copy' && daysScheduled === 0}
              height={20}
              width={20}
              stylingMode="text"
              icon={buttonIcon}
              onClick={() => onClickFunction()}
              hoverStateEnabled={false}
              activeStateEnabled={false}
              focusStateEnabled={false}
              type={buttonType}
              hint={buttonHint}
            />
          )}
          {userSelected.MonthCopied && (
            <Button
              height={20}
              width={20}
              stylingMode="text"
              icon="fas fa-history"
              onClick={() => {
                handleShowHistory(userSelected.ContactId);
              }}
              disabled={!!copySource}
              hoverStateEnabled={false}
              activeStateEnabled={false}
              focusStateEnabled={false}
              type="default"
              hint={`${userSelected.FullName} schedules copy history`}
            />
          )}
        </div>
      );
    }
  }

  function handleExportGridToExcel(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Schedule');
    const dataGrid = scheduleGridRef.current.instance;

    exportDataGridToExcel({
      component: dataGrid,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (typeof gridCell.value === 'string') {
          if (gridCell.rowType === 'group') {
            excelCell.value = gridCell.value.substr(2, gridCell.value.length);
          }
          if (gridCell.value === 'zTotal') {
            excelCell.value = 'Total';
          }
        }

        if (gridCell.rowType === 'header') {
          excelCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb:
                gridCell.column.caption.toUpperCase().includes('SAT') ||
                  gridCell.column.caption.toUpperCase().includes('SUN')
                  ? 'EEF797'
                  : 'FFFFFF',
            },
          };
          return;
        }

        if (gridCell.rowType === 'data') {
          // Format the task cell content
          if (
            gridCell.column.dataField !== 'FullName' &&
            !gridCell.data.Groupname.toUpperCase().includes('SUMMARY')
          ) {
            if (!gridCell.value) {
              excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb:
                    gridCell.column.caption.toUpperCase().includes('SAT') ||
                      gridCell.column.caption.toUpperCase().includes('SUN')
                      ? 'EEF797'
                      : 'FFFFFF',
                },
              };
              return;
            }

            const content = JSON.parse(gridCell.value);
            excelCell._value.model.value = content?.map(
              (item) => ` ${item.Sb}`
            );

            if (content.length === 1) {
              excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: content[0]?.Cl?.replaceAll('#', '')?.toUpperCase(),
                },
              };

              excelCell.font = {
                color: {
                  argb: pickTextColorBasedOnBgColor(
                    content[0].Cl,
                    'FFFFFF',
                    '363333'
                  ),
                },
                bold: gridCell.data.FullName === 'zTotal',
              };
              return;
            }

            // Case 2 or more tasks, fills with a default color
            if (content.length > 1) {
              excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCCCCC' },
              };
              return;
            }
          }

          if (gridCell.column.dataField === 'FullName') {
            excelCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: gridCell?.data?.Color?.replaceAll('#', '')?.toUpperCase(),
              },
            };

            excelCell.font = {
              color: {
                argb: pickTextColorBasedOnBgColor(
                  gridCell.data.Color,
                  'FFFFFF',
                  '363333'
                ),
                bold: gridCell.data.FullName === 'zTotal',
              },
            };
            return;
          }

          excelCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb:
                gridCell.data.FullName === 'zTotal'
                  ? gridCell?.data?.Color?.replaceAll('#', '')?.toUpperCase()
                  : gridCell.column.caption.toUpperCase().includes('SAT') ||
                    gridCell.column.caption.toUpperCase().includes('SUN')
                    ? 'EEF797'
                    : 'FFFFFF',
            },
          };
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${path
            ?.replaceAll('/', '')
            ?.replaceAll('-', '_')}_${currentDate.getFullYear()}_${currentDate.getMonth() + 1
          }.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  function handleExportGridToPdf(e) {
    const doc = new jsPDF('landscape');
    const dataGrid = scheduleGridRef.current.instance;

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
      autoTableOptions: {
        margin: { left: 1, right: 1, top: 3, bottom: 3 },
      },
      customizeCell: ({ gridCell, pdfCell }) => {
        const defaultOptions = {
          valign: 'middle',
          cellPadding: 1,
        };

        const taskOptions = {
          cellWidth: 8.9,
          cellMinWidth: 8.9,
          fontSize: 5.3,
        };

        // Remove unecessary informations
        if (typeof gridCell.value === 'string') {
          if (gridCell.rowType === 'group') {
            pdfCell.content = gridCell.value.substr(2, gridCell.value.length);
          }

          if (gridCell.value === 'zTotal') {
            pdfCell.content = 'Total';
          }
        }

        if (gridCell.rowType === 'data') {
          // Format the task cell content
          if (
            gridCell.column.dataField !== 'FullName' &&
            !gridCell.data.Groupname.toUpperCase().includes('SUMMARY')
          ) {
            if (gridCell.value) {
              const content = JSON.parse(gridCell.value);
              pdfCell.content = content?.map((item) => ` ${item.Sb}`);

              // Case only one task, fills the cell with its color
              if (content.length === 1) {
                pdfCell.styles = {
                  ...taskOptions,
                  ...defaultOptions,
                  halign: 'center',
                  fillColor: content[0].Cl,
                  textColor: pickTextColorBasedOnBgColor(
                    content[0].Cl,
                    '#fff',
                    '#000'
                  ),
                };
                return;
              }

              // Case 2 or more tasks, fills with a default color
              pdfCell.styles = {
                ...taskOptions,
                ...defaultOptions,
                halign: 'center',
                fillColor: '#CCC',
              };
              return;
            }

            // No tasks, default format
            pdfCell.styles = {
              ...taskOptions,
              ...defaultOptions,
              halign: 'center',
              fillColor:
                (gridCell.column.caption.toUpperCase().includes('SAT') ||
                  gridCell.column.caption.toUpperCase().includes('SUN')) &&
                '#EEF797',
            };
            return;
          }

          // Format username and full name cells
          if (gridCell.column.dataField === 'FullName') {
            pdfCell.styles = {
              ...defaultOptions,
              fontSize: 6,
              cellMinWidth: 10,
              overflow: 'ellipsize',
              fillColor: gridCell.data.Color,
              textColor: pickTextColorBasedOnBgColor(
                gridCell.data.Color,
                '#fff',
                '#000'
              ),
              fontStyle:
                gridCell.data.FullName === 'zTotal' ? 'bold' : 'normal',
            };
          }

          // Format summary cells totals
          if (
            gridCell.data.Groupname.toUpperCase().includes('SUMMARY') &&
            gridCell.column.dataField !== 'FullName'
          ) {
            pdfCell.styles = {
              ...defaultOptions,
              fontSize: 6,
              fontStyle:
                gridCell.data.FullName === 'zTotal' ? 'bold' : 'normal',
              fillColor:
                gridCell.data.FullName === 'zTotal'
                  ? gridCell.data.Color
                  : (gridCell.column.caption.toUpperCase().includes('SAT') ||
                    gridCell.column.caption.toUpperCase().includes('SUN')) &&
                  '#EEF797',
              halign: 'center',
            };
          }
        }

        // Format header cells (remove week day abreviation and mantain only the day month)
        if (gridCell.rowType === 'header') {
          if (/\d/.test(pdfCell.content)) {
            pdfCell.content = pdfCell.content.replace(/\D+/g, '');
          }
          pdfCell.styles = {
            fontSize: 7,
            fontStyle: 'bold',
            textColor: '#363333',
            halign: 'center',
            valign: 'middle',
            cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
            fillColor:
              (gridCell.column.caption.toUpperCase().includes('SAT') ||
                gridCell.column.caption.toUpperCase().includes('SUN')) &&
              '#EEF797',
          };
        }
      },
    }).then(() => {
      doc.save(
        `${format(currentDate, 'MMM')}-${format(new Date(), 'dd')}-${format(
          currentDate,
          'yyyy'
        )}-${path
          .replaceAll('/', '')
          .replaceAll('schedule', '')
          .replaceAll('-', '')}.pdf`
      );
    });
    if (e) {

      e.cancel = true;
    }
  }

  const dateSelector = useMemo(
    () => (
      <DateBox
        id="dtCurrentDate"
        defaultValue={currentDate}
        type="date"
        format="date"
        width={140}
        displayFormat="monthAndYear"
        // disabled={isLoading}
        calendarOptions={{
          maxZoomLevel: 'year',
          minZoomLevel: 'century',
        }}
        onValueChanged={onMonthChanged}
        height={48}
      />
    ),
    // eslint-disable-next-line
    [isLoading, currentDate]
  );

  const draftModeRadioGroup = useMemo(
    () => (
      <RadioContainer id="radio-draft">
        <RadioGroup
          disabled={isLoading}
          layout="horizontal"
          dataSource={radioOptions}
          itemRender={renderRadioGroupItem}
          defaultValue={radioOptions[draftStatus]}
          value={radioOptions[draftStatus]}
          onValueChanged={handleDraftStatusChange}
        />
      </RadioContainer>
    ),
    // eslint-disable-next-line
    [isLoading, draftStatus]
  );

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  const bgLabels = [
    {
      id: 1,
      title: 'Weekend',
      color: '#EFF897',
    },
    {
      id: 2,
      title: 'Today',
      color: '#9DCBFF',
    },
    {
      id: 3,
      title: 'Holiday',
      color: '#90D052',
    },
  ];

  const handleInvalidateCache = async () => {
    setLoadingInvalidate(true);
    try {
      const res = await API.sendRequest(
        `ContactSchedule/InvalidateCache/${area}/${currentDate.getFullYear()}/${currentDate.getMonth() + 1
        }`,
        'POST'
      );
      if (res.status === 200) {
        setLoadingInvalidate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={loadingInvalidate}
          message="Invalidating cache..."
          closeOnOutsideClick={false}
        />
      }
      {historyPopupVisible && (
        <HistoryPopup
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={changeHistory}
          historyType="json"
          needConvertToJson={false}
        />
      )}
      {holidayPopupVisible && (
        <HolidaysPopup
          visible={holidayPopupVisible}
          closeFunction={() => setHolidayPopupVisible(false)}
          holidaysData={holidayPopupData}
        />
      )}
      {confirmationPopupVisible && (
        <ConfirmationPopup
          visible={confirmationPopupVisible}
          setVisible={setConfirmationPopupVisible}
          setDraftStatus={setDraftStatus}
          draftData={draftData}
          setDraftData={setDraftData}
          saveDraftData={saveDraftData}
        />
      )}
      {pasteConfirmationPopup?.visible && (
        <PasteConfirmationPopup
          visible={pasteConfirmationPopup.visible}
          originUser={pasteConfirmationPopup.originUser}
          destinyUser={pasteConfirmationPopup.destinyUser}
          pasteFunction={pasteConfirmationPopup.pasteFunction}
          cancelFunction={pasteConfirmationPopup.cancelFunction}
        />
      )}
      <DndProvider backend={Backend}>
        <ScheduleClonePopup
          visible={scheduleClonePopupVisible}
          sourceYear={currentDate.getFullYear()}
          sourceMonth={currentDate.getMonth() + 1}
          contacts={contacts}
          area={area}
          onVisibleChange={(visible) => setScheduleClonePopupVisible(visible)}
        />
        <ContactAgendaPopup
          visible={agendaPopupVisible}
          contact={currentContact}
          onVisibleChange={(visible) => setAgendaPopupVisible(visible)}
        />
        <ExchangePopup
          visible={exchangePopupVisible}
          area={area}
          currentDate={currentDate}
          onVisibleChange={(visible) => setExchangePopupVisible(visible)}
        />
        <div className={classes.divGridContainer}>
          <div id="header" className={classes.Header}>
            <BreadCrumb>
              <h3>{ACL.BreadCrumb}</h3>
            </BreadCrumb>
            {areaData.UseTeams && (
              <SelectBox
                items={visualizationOptions}
                onValueChanged={handleChangeVisualization}
                defaultValue={0}
                displayExpr="text"
                valueExpr="id"
                disabled={isLoading}
                height={48}
                width={120}
              />
            )}
            {dateSelector}
            <LoadContainer>
              <LoadIndicator visible={isLoading} />
            </LoadContainer>
            {ACL.Bulk && (
              <>
                <div className={classes.Space} />
                <Button
                  stylingMode="contained"
                  type="normal"
                  onClick={() => setScheduleClonePopupVisible(true)}
                  disabled={isLoading}
                  icon="fas fa-clone"
                  text="Clone"
                  height={48}
                />
              </>
            )}
            {ACL.Export && (
              <>
                <div className={classes.Space} />
                <Button
                  stylingMode="contained"
                  type="normal"
                  onClick={() => setExchangePopupVisible(true)}
                  disabled={isLoading}
                  icon={outlookImg}
                  text="Outlook"
                  height={48}
                  visible={false}
                />
              </>
            )}
            <>
              <div className={classes.Space} />
              <DropDownButton
                text="Export to..."
                icon="fas fa-file-download"
                height={48}
                displayExpr="name"
                keyExpr="id"
                items={saveAsOptions}
                disabled={isLoading}
              />
            </>
            {userLoggedId === '38e417e8-a273-4972-a9e7-2d47c811d1da' && (
              <Button
                stylingMode="contained"
                type="normal"
                onClick={() => handleExportGridToPdf()}
                text="export PDF to njUser"
                height={48}
                id="exportPDF"
              />
            )}
            <>
              <div className={classes.Space} />
              <LegendContainer>
                {/* <LegendLabel>Availability: </LegendLabel> */}
                {periodsList?.map((item, index) => (
                  <LegendItem key={index}>
                    <LegendSquare bg={item.PeriodColor} />
                    <LegendLabel>{item.PeriodCode}</LegendLabel>
                  </LegendItem>
                ))}
              </LegendContainer>
              <LegendContainer>
                {bgLabels
                  .filter((item) => item.id < (holidaysMonth.length ? 4 : 3))
                  .map((item) => (
                    <BgLabelWrapper
                      onClick={() =>
                        item.id === 3 && setHolidayPopupVisible(true)
                      }
                      bg={item.color}
                      key={item.id}
                      button={item.id === 3}
                    >
                      <LegendLabel>{item.title}</LegendLabel>
                    </BgLabelWrapper>
                  ))}
              </LegendContainer>
              {ACL.Admin && (
                <>
                  <div className={classes.Space} />
                  <Button
                    stylingMode="contained"
                    type="normal"
                    onClick={handleInvalidateCache}
                    disabled={loadingInvalidate}
                    icon="refresh"
                    text="Invalidate Cache"
                    height={48}
                    visible
                  />
                </>
              )}
              {ACL.Bulk &&
                monthDiff >= 0 &&
                draftData &&
                draftData.UseDraft &&
                draftModeRadioGroup}
              {ACL.Bulk &&
                draftData &&
                draftData.DraftStatusId > 0 &&
                !isLoading && (
                  <StatusContainer>
                    <UpdateLabel>
                      {`Status Changed: ${format(
                        parseISO(draftData.ChangeDate),
                        "MM/dd/yyyy 'at' HH:mm"
                      )} By ${draftData.ChangedByName}`}
                    </UpdateLabel>
                    {monthDiff >= 0 && draftStatus === 1 && (
                      <WarningLabel>
                        <i className="fas fa-exclamation-circle" />
                        In draft mode the appointments are not visible on Team
                        Relay App.
                      </WarningLabel>
                    )}
                  </StatusContainer>
                )}
            </>
          </div>

          {/* monthDiff >= 0 && draftStatus === 1 && (
            <WarningContainer>
              <WarningLabel>
                <i className="fas fa-exclamation-circle" /> In draft mode the
                tasks are not visible in the mobile devices.
              </WarningLabel>
            </WarningContainer>
          )} */}
          {contacts && contacts?.length > 0 ? (
            <div className={classes.divGridScheduleContainer}>
              <DataGrid
                id="data-grid"
                ref={scheduleGridRef}
                disabled={isLoading}
                height="100%"
                dataSource={contacts}
                keyExpr="ContactId"
                allowColumnReordering={false}
                showColumnHeaders
                showRowLines
                showBorders
                onContentReady={() => handleGridContentReady()}
              >
                <GroupPanel visible={false} />
                <Grouping autoExpandAll />
                <Paging enabled={false} />
                <ColumnFixing enabled />
                <Scrolling useNative />
                <Column
                  dataField="Groupname"
                  caption=""
                  displayFormat=""
                  groupIndex={0}
                  allowEditing={false}
                  allowSorting={false}
                  visible={false}
                  width={0}
                />
                <Column
                  type="buttons"
                  cellRender={copyMonthButtonsRender}
                  fixed
                  fixedPosition="left"
                  width={60}
                />
                <Column
                  dataField="Username"
                  caption=""
                  allowEditing={false}
                  allowSorting={false}
                  cellRender={cellRenderAvatar}
                  width={35}
                  fixed
                  cssClass={classes.cellRenderTd}
                  allowExporting={false}
                />
                <Column
                  allowSorting={false}
                  allowEditing={false}
                  dataField="FullName"
                  caption="Staff"
                  width={180}
                  headerCellRender={renderFullNameHeader}
                  cellRender={cellRenderReadOnly}
                  fixed
                  cssClass={classes.cellRenderTd}
                />
                {daysInMonth?.map((field, fieldIndex) => {
                  const isWeekend = '-Sat-Sun'.includes(
                    field.substr(field.length - 4)
                  );
                  const isToday =
                    now.getDate() === parseInt(field.replace(/\D+/g, '')) &&
                    now.getMonth() + 1 === currentDate.getMonth() + 1 &&
                    now.getFullYear() === currentDate.getFullYear();

                  return (
                    <Column
                      key={fieldIndex}
                      allowSorting={false}
                      width={69}
                      alignment="center"
                      dataField={field}
                      isWeekend={isWeekend}
                      isToday={isToday}
                      headerCellRender={renderDaysHeader}
                      cellRender={cellRender}
                      cssClass={classes.cellRenderTd}
                    />
                  );
                })}
              </DataGrid>
            </div>
          ) : (
            <ProgressStatus
              complementaryMessage="Have groups for this area already been created?"
              timeout={4000}
            />
          )}
        </div>

        {((!cellIsPast && appTypeOverdueCount >= 0) ||
          (cellIsPast && appTypeOverdueCount > 0)) && (
            <Menu
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              onContextMenu={handleContextMenu}
            >
              <MenuItem
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#FFF',
                  zIndex: '10',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  paddingLeft: '14px',
                }}
              >
                <CheckBox
                  value={allowOverrideAppointments}
                  onValueChanged={(e) => setAllowOverrideAppointments(e.value)}
                />
                Override Appointments
              </MenuItem>
              {!cellIsPast && (
                <MenuItem onClick={() => handleMenuSave('CLEAR')} key="0">
                  <>
                    <div
                      className={classes.itemBadge}
                      style={{ backgroundColor: '#FFF' }}
                    />
                    Clear Appointment
                  </>
                </MenuItem>
              )}
              {appointmentTypes &&
                appointmentTypes.map((item, itemId) => {
                  if ((cellIsPast && item.Overdue) || !cellIsPast) {
                    return (
                      <MenuItem
                        onClick={() =>
                          handleMenuSave(
                            item.AppointmentTypeCode,
                            item.Overdue,
                            item.Color
                          )
                        }
                        key={itemId}
                      >
                        <>
                          {item.AvailabilityColor ? (
                            <div
                              className={classes.itemAvailability}
                              style={{ backgroundColor: item.AvailabilityColor }}
                            />
                          ) : (
                            ''
                          )}
                          {item.Color ? (
                            <div
                              className={classes.itemBadge}
                              style={{ backgroundColor: item.Color }}
                            />
                          ) : (
                            ''
                          )}
                          {item.AppointmentTypeCode} -{item.AppointmentTypeDesc}
                        </>
                      </MenuItem>
                    );
                  }
                  return false;
                })}
            </Menu>
          )}

        {ACL.Delete && (
          <PopupCell
            anchorEl={anchorElCell}
            setAnchorEl={setAnchorElCell}
            classes={classes}
            data={dataPopup}
            contacts={contacts}
            setContacts={setContacts}
            currentDate={currentDate}
          />
        )}
      </DndProvider>
    </>
  );
};

export default Schedule;
