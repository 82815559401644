import React, { useEffect, useState } from 'react';
import { Form, List, LoadPanel, Popup } from 'devextreme-react';
import { GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { array, bool, func } from 'prop-types';
import API from '../../../../services/API';
import { ToolbarItem } from 'devextreme-react/popup';
import Avatar from '../../../../components/contacts/Avatar';
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import { GroupSpan } from './styles';

export default function EditGroupPopup({
  isStaff,
  updateContactGrid,
  areas,
  isVisible,
  setIsVisible,
  currentContact,
}) {
  const [groupOptions, setGroupOptions] = useState([]);
  const [teams, setTeams] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [saving, setSaving] = useState(false);
  const [isMultiSchedule, setIsMultiSchedule] = useState(
    () => currentContact.User.MultiSchedule
  );

  const fetchGroups = async () => {
    const { data } = await API.sendRequest('Group/ListNotSystemGroups', 'get');

    await loadTeams(currentContact.User.AreaId);

    let filteredGroups = null;
    if (isMultiSchedule) {
      filteredGroups = data;
    } else {
      filteredGroups = data.filter(
        (group) =>
          group.AreaId === 0 || group.AreaId === currentContact.User.AreaId
      );
    }

    const editedGroups = filteredGroups.map((group) => ({
      GroupId: group.GroupId,
      GroupDesc: `${group.GroupCode} - ${group.GroupName}`,
      ScheduleControlOrder: group.ScheduleControlOrder,
      AreaId: group.AreaId,
      AreaDesc: areas.find((a) => a.AreaId === group.AreaId).AreaDesc,
      disabled: false,
      // disabled: group.GroupId === 8 ? true : false,
    }));

    const orderedGroups = new DataSource({
      store: editedGroups,
      group: 'AreaDesc',
    });

    setGroupOptions(orderedGroups);

    const groups = filteredGroups;
    const list = groups.filter((item) =>
      currentContact.Groups.find((exist) => exist.GroupId === item.GroupId)
    );
    if (
      !list.find((group) => group.GroupId === 8) &&
      isStaff &&
      !currentContact.User.UserId
    ) {
      list.push({ GroupDesc: 'STF - Staff', GroupId: 8 });
    }
    const userGroups = list.map((group) => ({
      GroupId: group.GroupId,
      GroupDesc: `${group.GroupCode} - ${group.GroupName}`,
      ScheduleControlOrder: group.ScheduleControlOrder,
      AreaId: group.AreaId,
      AreaDesc: areas.find((a) => a.AreaId === group.AreaId).AreaDesc,
      disabled: false,
      // disabled: group.GroupId === 8 ? true : false,
    }));

    const groupsByArea = getDistinctAreasId(userGroups).map((areaId) => ({
      key: areas.find((area) => area.AreaId === areaId).AreaDesc,
      items: userGroups.filter((group) => group.AreaId === areaId),
    }));

    setSelectedGroups(groupsByArea);
  };

  useEffect(() => {
    fetchGroups();
  }, [isMultiSchedule]); //eslint-disable-line

  const groupRenderTemplate = (item) => <div>{item.key}</div>;

  const groupItemRenderTemplate = (item) => {
    return (
      <GroupSpan>
        <span>{item.GroupDesc}</span>
        {item.ScheduleControlOrder === 0 && <span>(No Schedule)</span>}
      </GroupSpan>
    );
  };

  const getDistinctAreasId = (groupsArray) => {
    const distinctAreas = groupsArray
      .map((group) => group.AreaId)
      .filter(
        (currentArea, index, areas) => areas.indexOf(currentArea) === index
      );
    return distinctAreas;
  };

  const onSelectedGroupsChange = (args) => {
    if (args.name === 'selectedItems') {
      let currentValueCopy = args.value;
      let currentSelectedItems = [];
      let previousSelectedItems = [];

      currentValueCopy.forEach((opt) =>
        opt.items.forEach((i) => currentSelectedItems.push(i))
      );
      args.previousValue.forEach((opt) =>
        opt.items.forEach((i) => previousSelectedItems.push(i))
      );

      let selectedItem = currentSelectedItems.filter(
        (item) => !previousSelectedItems.includes(item)
      )[0];

      if (selectedItem) {
        let itemsToRemove = [];
        if (selectedItem.ScheduleControlOrder !== 0) {
          // Verify if has items to remove and save them
          currentValueCopy.forEach((opt) => {
            if (opt.key === selectedItem.AreaDesc) {
              const itemToDelete = opt.items.find(
                (group) =>
                  group.GroupId !== selectedItem.GroupId &&
                  group.ScheduleControlOrder !== 0
              );
              if (itemToDelete) {
                itemsToRemove.push(itemToDelete);
              }
            }

            // Remove items from selected items array
            if (itemsToRemove.length > 0) {
              // For each item to be removed get the group key index
              itemsToRemove.forEach((item) => {
                const keyIndex = currentValueCopy.findIndex(
                  (value) => value.key === item.AreaDesc
                );

                // If the item are finded on group array, remove it
                if (
                  currentValueCopy[keyIndex].items.findIndex(
                    (i) => i.GroupId === item.GroupId
                  ) > -1
                ) {
                  currentValueCopy[keyIndex].items.splice(
                    currentValueCopy[keyIndex].items.findIndex(
                      (i) => i.GroupId === item.GroupId
                    ),
                    1
                  );
                }
              });
            }
          });
        }
      }

      setSelectedGroups(currentValueCopy);
    }
  };

  //*************** teams
  const loadTeams = async (areaId) => {
    const area = areas.find((a) => a.AreaId === areaId);
    if (area.UseTeams) {
      const res = await API.sendRequest(`Team/ListByArea/${areaId}`, 'GET');
      if (res.status === 200) {
        setTeams(res.data);
      }
    } else {
      setTeams(null);
    }
  };
  //*************** teams

  const handleSave = async () => {
    setSaving(true);

    const groupsToSave = [];
    selectedGroups.forEach((group) =>
      group.items.forEach((item) => {
        groupsToSave.push(item);
      })
    );

    const userGroups = {
      UserId: currentContact.User.UserId,
      MultiSchedule: isMultiSchedule,
      Groups: groupsToSave.map((group) => {
        return {
          ContactId: currentContact.User.UserId,
          GroupId: group.GroupId,
        };
      }),
    };

    const res = await API.sendRequest('ADUser/SaveGroups', 'POST', userGroups);

    if (res.status === 200) {
      updateContactGrid();
      notify('Changes saved', 'success', 2000);
      setSaving(false);
      setIsVisible(false);
    } else {
      setSaving(false);
      notify(`Error: ${res.message}`, 'error', 2000);
    }
  };

  const buttonsOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        handleSave();
      },
    },
    {
      text: 'Cancel',
      onClick: () => {
        setIsVisible(false);
      },
    },
  ];

  return (
    <Popup visible={isVisible} showTitle={false} width="55%" height={550}>
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={saving}
        message="Saving..."
        closeOnOutsideClick={false}
      />
      <Form
        formData={currentContact}
        readOnly={false}
        showColonAfterLabel
        labelLocation="left"
        colCount={2}
      >
        <GroupItem caption="Personal Data">
          <SimpleItem itemType="simple">
            <Avatar contact={currentContact.User} h={90} w={90} />
          </SimpleItem>
          <SimpleItem
            dataField="User.FirstName"
            editorType="dxTextBox"
            editorOptions={{ readOnly: true }}
          >
            <Label text="First Name" />
          </SimpleItem>
          <SimpleItem
            dataField="User.LastName"
            editorType="dxTextBox"
            editorOptions={{ readOnly: true }}
          >
            <Label text="Last Name" />
          </SimpleItem>
          <SimpleItem
            dataField="User.DisplayName"
            editorType="dxTextBox"
            editorOptions={{ readOnly: true }}
          >
            <Label text="Display Name" />
          </SimpleItem>
          <SimpleItem
            dataField="User.JobTitle"
            editorOptions={{ readOnly: true }}
          >
            <Label text="Job Title" />
          </SimpleItem>
          <SimpleItem
            dataField="User.MultiSchedule"
            editorType="dxCheckBox"
            editorOptions={{
              onValueChanged: () => setIsMultiSchedule(!isMultiSchedule),
              value: isMultiSchedule,
            }}
          >
            <Label text="Multi Schedule" />
          </SimpleItem>
          <SimpleItem
            dataField="User.AreaId"
            editorType="dxSelectBox"
            editorOptions={{
              items: areas,
              displayExpr: 'AreaDesc',
              valueExpr: 'AreaId',
              readOnly: 'true',
            }}
          >
            <Label text="Area" />
          </SimpleItem>
          <SimpleItem
            visible={teams ? true : false}
            dataField="User.TeamId"
            editorType="dxSelectBox"
            editorOptions={{
              readOnly: true,
              items: teams,
              displayExpr: 'TeamName',
              valueExpr: 'TeamId',
            }}
          >
            <Label text="Team" />
          </SimpleItem>
        </GroupItem>
        <GroupItem caption="Groups">
          {groupOptions && (
            <List
              dataSource={groupOptions}
              height={350}
              keyExpr="GroupId"
              displayExpr="GroupDesc"
              showSelectionControls={true}
              selectionMode="multiple"
              selectedItems={selectedGroups}
              onOptionChanged={(args) => onSelectedGroupsChange(args)}
              showScrollbar="always"
              useNativeScrolling
              grouped
              collapsibleGroups
              groupRender={groupRenderTemplate}
              itemRender={groupItemRenderTemplate}
            />
          )}
        </GroupItem>
      </Form>
      {buttonsOptions.map((options, index) => (
        <ToolbarItem
          key={index}
          options={options}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

EditGroupPopup.propTypes = {
  isStaff: bool.isRequired,
  updateContactGrid: func.isRequired,
  isVisible: bool.isRequired,
  setIsVisible: func.isRequired,
  areas: array.isRequired,
};
