import { Button } from 'devextreme-react';
import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  height: 28px;

  i {
    color: ${({ $iconColor }) => $iconColor || '#000'};
  }
`;

export const SelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
`;

export const RequestButton = styled(Button)`
  padding: 0;
`;

export const SeeEmailButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 4px;
  color: #1b5c94;

  cursor: pointer;

  .number {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #d41200;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i {
    color: #1b5c94;
  }
`;
