/* eslint-disable no-unused-expressions */
import HistoryPopup from 'components/popups/HistoryPopup';
import { format, parseISO } from 'date-fns';
import { Button as DxButton, LoadPanel } from 'devextreme-react';
import DataGrid, {
  Button,
  Column,
  FilterRow,
  Lookup,
} from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import { useEmrAccess } from 'pages/EMRAccess/contexts/EmrAccessContext';
import { number, object, shape } from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import API from 'services/API';
import {
  emrCancelRequest,
  emrRevokeRequest,
} from 'services/requests/emrRequestsPost';
import useDocuSignTemplateList from 'services/requests/useDocuSignTemplateList';
import getChangeHistory from 'utils/getChangeHistory';
import DeleteConfirmationPopup from '../DeleteConfirmationPopup';
import EmailView from '../EmailView';
import RequestMethodCell from '../RequestMethodCell';
import SendEmailPopup from '../SendEmailPopup';
import StatusCellRender from '../StatusCellRender';
import EmrAccessEditPopup from './components/EmrAccessEditPopup';
import { CheckBoxSmall, WrapperBulkButton } from './styles';

const RequestsDetail = ({
  data,
  ACL,
  type,
  requestMethodList,
  requestStatusDataList,
}) => {
  const queryClient = useQueryClient();
  const currentRowData = data?.data;
  const [requestChangeHistory, setRequestChangeHistory] = useState(null);
  const [historyPopupVisible, setHistoryPopupVisible] = useState(false);
  const editRequestPopupRef = useRef(null);
  const emailViewRef = useRef(null);
  const sendEmailPopupRef = useRef(null);

  const [deletePopup, setDeletePopup] = useState({
    visible: false,
    emrAccessId: '',
    hospitalGroupName: '',
    exeFunc: () => {},
    question: '',
  });
  const { state, requestsRef, showMissingCredentialsOnly } = useEmrAccess();

  const [autoRefetchGrid, setAutoRefetchGrid] = useState(true);

  const {
    mutate: cancelRequest,
    isSuccess: cancelSuccess,
    isLoading: cancelLoading,
  } = useMutation((requestId) => emrCancelRequest(requestId));

  const {
    mutate: revokeRequest,
    isSuccess: revokeSuccess,
    isLoading: revokeLoading,
  } = useMutation((requestId) => emrRevokeRequest(requestId));

  const [requestList, setRequestList] = useState({
    data: [],
    isLoading: false,
  });

  const [requestsSelected, setRequestsSelected] = useState({
    Users: [],
    Apps: [],
  });

  const [bulkEmailUsers, setBulkEmailUsers] = useState([]);

  async function fetchRequestData() {
    setRequestList((prev) => ({ ...prev, isLoading: true }));
    setRequestsSelected(requestsRef?.current);

    try {
      const { data: requests } =
        type === 'USER'
          ? await API.sendRequest(
              `ADUserEMRAccess/ListByUser/${currentRowData?.UserId}/${state?.showUnassigned}/false`,
              'GET'
            )
          : await API.sendRequest(
              `ADUserEMRAccess/ListByHospital/${currentRowData?.HospitalGroupId}/${state?.showUnassigned}`,
              'GET'
            );
      setRequestList({
        // data: [
        //   { EMRRequestStatusId: 0, ADUserEMRAccessId: '1' },
        //   { EMRRequestStatusId: 0, ADUserEMRAccessId: '2' },
        // ],
        data: requests,
        isLoading: false,
      });
    } catch (error) {
      setRequestList((prev) => ({ ...prev, isLoading: false }));
      console.log(error);
    }
  }

  useEffect(() => {
    if (revokeSuccess || cancelSuccess) {
      fetchRequestData();
    }
    // eslint-disable-next-line
  }, [cancelSuccess, revokeSuccess]);

  const activeAutoRefetch = useCallback(() => {
    if (autoRefetchGrid) {
      fetchRequestData();
    }
    // eslint-disable-next-line
  }, [autoRefetchGrid]);

  useEffect(() => {
    let isSubscribed = true;
    let intervalId;

    if (isSubscribed) {
      fetchRequestData();

      intervalId = setInterval(() => {
        activeAutoRefetch();
      }, 1 * 60 * 1000); // 1 minute
    }
    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [activeAutoRefetch]);

  useEffect(() => {
    if (cancelSuccess || revokeSuccess) {
      queryClient.invalidateQueries(['adUserEmrAccessList']);
      queryClient.invalidateQueries(['allStaffUsers']);
    }
    // eslint-disable-next-line
  }, [cancelSuccess, revokeSuccess]);

  const dateColumnFormatter = ({ value }) => (
    <span>
      {!value || value.startsWith('00')
        ? 'Pending'
        : format(parseISO(value), 'MM/dd/yyyy HH:mm')}
    </span>
  );

  const handleCancelRequest = (e) => {
    console.log(e.row.data);
    setDeletePopup({
      visible: true,
      emrAccessId: e?.row.data.ADUserEMRAccessId,
      hospitalGroupName: e?.row.data.HospitalGroupName,
      employeeName: e?.row.data.DisplayName,
      exeFunc: cancelRequest,
      question: 'Cancel',
    });
  };

  const handleRevokeRequest = (e) => {
    console.log(e.row.data);

    if (e?.row.data.EMRRequestStatusId) {
      setDeletePopup({
        visible: true,
        emrAccessId: e?.row.data.ADUserEMRAccessId,
        hospitalGroupName: e?.row.data.HospitalGroupName,
        employeeName: e?.row.data.DisplayName,
        exeFunc: revokeRequest,
        question: 'Delete',
      });
    }
  };

  const onRowPrepared = ({ rowType, data, rowElement }) => {
    if (rowType === 'data' && data?.EMRRequestStatusId === 3) {
      rowElement.bgColor = '#c5ffc5';
    }
  };

  const handleShowChangeHistory = async (e) => {
    await getChangeHistory(
      'ADUserEMRAccess',
      e?.row?.data?.ADUserEMRAccessId
    ).then((ret) => {
      if (ret) {
        setRequestChangeHistory(ret);
        setHistoryPopupVisible(true);
      }
    });
  };

  useEffect(() => {
    requestsRef.current = requestsSelected;
    // eslint-disable-next-line
  }, [requestsSelected]);

  const { data: docuSignTemplateList } = useDocuSignTemplateList();
  // const ACL = usePermissions();
  const autoRefetchGridRef = useRef(true);

  return (
    <div>
      <WrapperBulkButton
        title={bulkEmailUsers.length < 2 ? 'Select at least 2 users' : ''}
      >
        <DxButton
          text={`Edit and send bulk email ${
            bulkEmailUsers.length > 0 ? `(${bulkEmailUsers.length})` : ''
          }`}
          disabled={bulkEmailUsers.length < 2}
          icon="email"
          visible={
            type === 'APP' && currentRowData?.EMRRequestMethodId === 3
            // &&
            // requestList?.data?.filter((item) => item.EMRRequestStatusId === 0)
            //   .length > 0
          }
          onClick={() =>
            sendEmailPopupRef?.current?.changeVisibility(
              {},
              bulkEmailUsers,
              'bulk'
            )
          }
        />
      </WrapperBulkButton>
      <EmrAccessEditPopup
        forceRefetch={() => fetchRequestData()}
        ref={editRequestPopupRef}
        setAutoRefetchGrid={setAutoRefetchGrid}
      />
      <EmailView ref={emailViewRef} refetch={fetchRequestData} />
      <SendEmailPopup
        ref={sendEmailPopupRef}
        forceRefetch={fetchRequestData}
        clearSelectedUsers={() => setBulkEmailUsers([])}
        autoRefetchGridRef={autoRefetchGridRef}
        isExpirationScreen={false}
      />
      {historyPopupVisible && (
        <HistoryPopup
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={requestChangeHistory}
        />
      )}
      {deletePopup.visible && deletePopup.emrAccessId && (
        <DeleteConfirmationPopup
          visible={deletePopup.visible}
          hospitalGroupName={deletePopup?.hospitalGroupName}
          employeeName={deletePopup?.employeeName}
          onClose={() => {
            setDeletePopup({
              visible: false,
              emrAccessId: '',
              hospitalGroupName: '',
              exeFunc: () => {},
              question: '',
            });
          }}
          question={deletePopup?.question}
          deleteFunc={() => {
            deletePopup?.exeFunc(deletePopup?.emrAccessId);
          }}
        />
      )}
      <LoadPanel
        visible={cancelLoading || revokeLoading}
        message="Deleting..."
      />
      {useMemo(
        () => (
          <DataGrid
            keyExpr={requestList ? 'ADUserEMRAccessId' : ''}
            dataSource={
              showMissingCredentialsOnly
                ? requestList?.data
                    ?.filter((item) => item.EMRRequestMethodId !== 0)
                    ?.filter((item) => item.EMRRequestStatusId === 3)
                : requestList?.data?.filter(
                    (item) => item.EMRRequestMethodId !== 0
                  )
            }
            noDataText={
              requestList?.isLoading
                ? 'Loading Requests...'
                : 'There are no Request(s)'
            }
            loadPanel={{ enabled: requestList?.isLoading }}
            showBorders
            onRowPrepared={onRowPrepared}
            allowColumnResizing
            hoverStateEnabled
            paging={{
              enabled: false,
            }}
            height={state?.showUnassigned ? 500 : 'auto'}
          >
            <FilterRow visible applyFilter="auto" />
            <Column
              visible={state?.showUnassigned}
              width={70}
              cellRender={({ data }) =>
                data.EMRRequestStatusId === 6 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <CheckBoxSmall
                      onValueChanged={(e) => {
                        if (e.value) {
                          setRequestsSelected((prev) => ({
                            Apps: [
                              ...new Set([
                                ...prev?.Apps,
                                data?.HospitalGroupId,
                              ]),
                            ],
                            Users: [...new Set([...prev?.Users, data?.UserId])],
                          }));
                        } else {
                          setRequestsSelected((prev) => ({
                            Apps:
                              prev?.Apps?.length === 1 &&
                              prev?.Users?.length === 1
                                ? []
                                : type === 'USER'
                                ? prev?.Apps.filter(
                                    (item) => item !== data?.HospitalGroupId
                                  )
                                : prev?.Apps,
                            Users:
                              prev?.Apps?.length === 1 &&
                              prev?.Users?.length === 1
                                ? []
                                : type === 'APP'
                                ? prev?.Users.filter(
                                    (item) => item !== data?.UserId
                                  )
                                : prev?.Users,
                          }));
                        }
                      }}
                      defaultValue={
                        type === 'USER'
                          ? requestsSelected?.Apps?.includes(
                              data?.HospitalGroupId
                            )
                          : requestsSelected?.Users?.includes(data?.UserId)
                      }
                    />
                  </div>
                )
              }
            />
            <Column
              caption="Okta Tile"
              dataField="HospitalGroupName"
              visible={type === 'USER'}
            />
            <Column
              caption="Name"
              dataField="DisplayName"
              visible={type === 'APP'}
              width={type === 'APP' && 250}
            />
            <Column
              dataField="EMRRequestStatusId"
              caption="Status"
              type="text"
              cellComponent={StatusCellRender}
              width={type === 'APP' && 300}
            >
              <Lookup
                dataSource={requestStatusDataList}
                displayExpr="EMRRequestStatusName"
                valueExpr="EMRRequestStatusId"
              />
            </Column>

            <Column
              visible={
                type === 'APP' && currentRowData?.EMRRequestMethodId === 3
              }
              width={70}
              cellRender={({ data }) =>
                data?.EMRRequestStatusId === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <CheckBoxSmall
                      onValueChanged={(e) => {
                        if (e.value) {
                          setBulkEmailUsers((prev) => [...prev, data]);
                        } else {
                          setBulkEmailUsers((prev) =>
                            prev.filter(
                              (item) =>
                                data.ADUserEMRAccessId !==
                                item.ADUserEMRAccessId
                            )
                          );
                        }
                      }}
                      defaultValue={bulkEmailUsers
                        ?.map((item) => item.ADUserEMRAccessId)
                        ?.includes(data?.ADUserEMRAccessId)}
                    />
                  </div>
                )
              }
            />
            <Column
              dataField="EMRRequestMethodId"
              caption="Request Method"
              minWidth={150}
              cellComponent={({ data }) => {
                return (
                  !bulkEmailUsers
                    ?.map((i) => i.ADUserEMRAccessId)
                    ?.includes(data?.data?.ADUserEMRAccessId) &&
                  (data?.data?.EMRRequestStatusId === 6 ? (
                    <span>
                      {
                        requestMethodList?.find(
                          (item) =>
                            item.EMRRequestMethodId ===
                            data?.data?.EMRRequestMethodId
                        )?.EMRRequestMethodName
                      }
                    </span>
                  ) : (
                    <RequestMethodCell
                      data={data}
                      emailViewRef={emailViewRef}
                      sendEmailPopupRef={sendEmailPopupRef}
                      forceRefetch={() => fetchRequestData()}
                      docuSignTemplateList={docuSignTemplateList}
                      ACL={ACL}
                    />
                  ))
                );
              }}
            >
              <Lookup
                dataSource={requestMethodList}
                valueExpr="EMRRequestMethodId"
                displayExpr="EMRRequestMethodName"
              />
            </Column>
            <Column
              dataField="RequestDate"
              caption="Request Date"
              cellRender={dateColumnFormatter}
              width={130}
            />
            <Column dataField="ExpiresOn" dataType="date" width={130} />
            <Column
              dataField="ApprovalDate"
              caption="Approval Date"
              cellRender={dateColumnFormatter}
              width={130}
            />
            <Column
              dataField="RequestedByUsername"
              caption="Requested By"
              width={110}
            />
            <Column caption="Actions" type="buttons" width={100}>
              {(ACL?.Admin || ACL?.Update) && (
                <Button
                  icon="fas fa-edit"
                  stylingMode="text"
                  hint="Edit"
                  onClick={(e) => {
                    // eslint-disable-next-line no-unused-expressions
                    editRequestPopupRef?.current?.changeVisibility({
                      ...e.row.data,
                    });
                    setAutoRefetchGrid(false);
                  }}
                  visible={(e) =>
                    e.row.data.EMRRequestStatusId !== 6 &&
                    !requestList?.isLoading
                  }
                />
              )}

              {ACL?.Admin && (
                <Button
                  icon="fas fa-history"
                  hint="Show Change History"
                  onClick={(e) => {
                    handleShowChangeHistory(e);
                  }}
                  visible={(e) => e.row.data.EMRRequestStatusId !== 6}
                />
              )}
              {(ACL?.Admin || ACL?.Update) && (
                <Button
                  icon="fas fa-ban"
                  hint="Cancel"
                  onClick={(e) => {
                    if (
                      !e?.row?.data?.EMREmail &&
                      e?.row?.data?.EMRRequestMethodId === 3
                    ) {
                      notify(
                        'This Okta Tile does not have contacts for each type of email, please configure the contacts on the "Okta Tile" screen!',
                        'warning',
                        8000
                      );
                      return;
                    }
                    handleCancelRequest(e);
                  }}
                  visible={(e) =>
                    e.row.data.EMRRequestStatusId !== 3 &&
                    e.row.data.EMRRequestStatusId !== 4 &&
                    e.row.data.EMRRequestStatusId !== 6
                  }
                />
              )}
              {(ACL?.Admin || ACL?.Delete) && (
                <Button
                  icon="fas fa-trash"
                  hint="Delete"
                  onClick={handleRevokeRequest}
                  visible={(e) =>
                    e.row.data.EMRRequestStatusId === 3 ||
                    e.row.data.EMRRequestStatusId === 4
                  }
                />
              )}
            </Column>
          </DataGrid>
        ), // eslint-disable-next-line
        [requestList?.data, requestList?.isLoading, bulkEmailUsers, ACL]
      )}
    </div>
  );
};

RequestsDetail.propTypes = {
  data: shape({
    data: shape({
      RequestId: number,
    }),
  }).isRequired,
  ACL: object.isRequired,
};

export default RequestsDetail;
