import React, { useState } from 'react';
import { bool, func, object } from 'prop-types';
import notify from 'devextreme/ui/notify';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';
import { LoadPanel } from 'devextreme-react/ui/load-panel';

import API from '../../../../services/API';
import { Container } from './styles';
import Avatar from '../../../../components/contacts/Avatar';

function DeleteExternalContactPopup({
  visible,
  onVisibleChange,
  contact,
  updateContacts,
}) {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);

    const result = await API.sendRequest(
      `Contact/${contact.ContactId}`,
      'DELETE'
    );

    if (result.status !== 200) {
      setDeleting(false);
      notify({ message: `Error: ${result.message}` }, 'error', 5000);
    } else {
      setDeleting(false);
      notify(
        {
          message: `Contact ${contact.FirstName} ${contact.LastName} deleted with success!`,
        },
        'success',
        2000
      );
      updateContacts(contact.ContactId);
      onVisibleChange(false);
    }
  };

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        handleDelete();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        onVisibleChange(false);
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      title={`${contact.FirstName} ${contact.LastName}`}
      onHidden={() => onVisibleChange(false)}
      width={600}
      height={250}
      showCloseButton={false}
      disabled={deleting}
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={deleting}
        message="Deleting..."
        closeOnOutsideClick={false}
      />
      <Container>
        <Box direction="row" width="100%">
          <Item ratio={1}>
            <Avatar contact={contact} h={128} w={128} />
          </Item>
          <Item ratio={2}>
            Deleting this contact will delete all information related to this
            contact. Are you sure?
          </Item>
        </Box>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

DeleteExternalContactPopup.propTypes = {
  visible: bool.isRequired,
  onVisibleChange: func.isRequired,
  contact: object.isRequired,
  updateContacts: func.isRequired,
};

export default DeleteExternalContactPopup;
