import React from 'react';
import { bool, func, object } from 'prop-types';
import { Popup } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/date-box';
import { scheduleCopyGif } from '../../../../../assets';

import { Container, GifContainer, WarningTextContainer } from './styles';

function PasteConfirmationPopup({
  visible,
  originUser,
  destinyUser,
  pasteFunction,
  cancelFunction,
}) {
  const buttonsOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        pasteFunction();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        cancelFunction();
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      title="Warning"
      showCloseButton={false}
      width={1000}
      height={580}
    >
      <Container>
        {originUser && destinyUser && (
          <>
            <GifContainer>
              <img src={scheduleCopyGif} alt="Schedule copy example" />
            </GifContainer>
            <WarningTextContainer>
              <span>
                Do you want to copy the schedule from
                <b>{` [${originUser.FullName}] `}</b>
                to
                <b>{` [${destinyUser.FullName}] `}</b>
                until the end of the month (except past schedules),
                <br />
                replacing all existing schedules?
                <br />
                <br />
                <p>
                  <b>This action cannot be reverted.</b>
                </p>
              </span>
            </WarningTextContainer>
          </>
        )}
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

PasteConfirmationPopup.propTypes = {
  visible: bool,
  originUser: object,
  destinyUser: object,
  pasteFunction: func,
  cancelFunction: func,
};

PasteConfirmationPopup.defaultProps = {
  visible: false,
  originUser: null,
  destinyUser: null,
  pasteFunction: () => {},
  cancelFunction: () => {},
};

export default PasteConfirmationPopup;
