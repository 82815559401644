import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
`;

export const GifContainer = styled.div`
  img {
    border-radius: 4px;
    height: 90%;
  }
`;

export const WarningTextContainer = styled.div`
  margin-top: 20px;
`;
