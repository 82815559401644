import { FileManager } from 'devextreme-react';
import styled from 'styled-components';

export const LoadContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const WrapperBreadCrumb = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const Container = styled.div`
  height: 90vh;
  width: 100vw;
  position: relative;
  padding: 16px;
  max-width: 96%;
  margin: 0 auto;
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  gap: 16px;
  z-index: 150;
  @media (min-width: 576px) {
    position: absolute;
  }
`;

export const DXFileManager = styled(FileManager)`
  .dx-menu-item-text {
    color: #000000;
  }
  .dx-icon {
    color: #000000;
  }
  .dx-filemanager-breadcrumbs {
    background-color: #e6f0fa;
  }
  .dx-filemanager-dirs-panel {
    background-color: #ffffff;
  }
`;

export const WrapperDownloadButton = styled.div`
  height: 50px;
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2%;
`;
