import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 30px;
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #6a6a6a;
  margin-bottom: 20px;

  h3 {
    font-size: 25px;
    font-weight: normal;
  }

  h1 {
    font-size: 35px;
    font-weight: bold;
  }
`;

export const WrapperPlatform = styled.div``;

export const WrapperItemsPlatform = styled.div`
  display: flex;
  padding: 10px 0px;
`;

export const WrapperModules = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  p {
    margin-bottom: 25px;
    color: #6a6a6a;
  }
`;

export const WrapperItems = styled.div`
  display: flex;
  padding: 10px 0px;
`;

export const WrapperItem = styled.button`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 0px solid;
  -webkit-box-shadow: 1px 3px 6px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 3px 6px 5px rgba(0, 0, 0, 0.08);
  margin-right: ${(props) => (props.last ? '0px' : '40px')};
  background-color: ${(props) => (props.itemActive ? '#4F68C2' : '#FFF')};
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.platform ? '70px' : '140px')};
  height: ${(props) => (props.platform ? '70px' : '140px')};
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  i {
    margin-bottom: 8px;
  }

  span {
    color: ${(props) => (props.itemActive ? '#FFF' : '#4F68C2')};
    font-size: ${(props) => (props.platform ? '10px' : '15px')};
  }
`;

export const WrapperSubmodules = styled.div`
  display: flex;
  padding: 10px 0px;
  margin-bottom: 50px;
`;

export const WrapperSubitem = styled.button`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  border: 0px solid;
  -webkit-box-shadow: 1px 3px 6px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 3px 6px 5px rgba(0, 0, 0, 0.08);
  margin-right: ${(props) => (props.last ? '0px' : '35px')};
  background-color: ${(props) => (props.itemActive ? '#4F68C2' : '#FFF')};
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  i {
    margin-bottom: 8px;
  }

  span {
    color: ${(props) => (props.itemActive ? '#FFF' : '#4F68C2')};
    font-size: 12px;
  }
`;

export const WrapperBreadcrumbs = styled.div`
  margin-bottom: 40px;
`;

export const WrapperMedia = styled.div`
  display: flex;
  height: 500px;
  margin-bottom: 50px;
`;

export const WrapperVideo = styled.div`
  width: 60%;
  margin-right: 40px;
`;

export const WrapperTopics = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding: 0px;
  -webkit-box-shadow: 1px 3px 6px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 3px 6px 5px rgba(0, 0, 0, 0.08);

  div:first-child {
    border-bottom: 0.5px solid #ccc;
    width: 100%;
  }

  h3 {
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
  }
`;

export const Topic = styled.div`
  background-color: ${(props) => (props.active ? '#368cc9' : 'transparent')};
  width: 100%;
  padding: 10px 35px;
  transition: background 0.4s;
  color: ${(props) => (props.active ? '#FFF' : '#000')};

  p {
    text-align: left;
  }

  p:first-child {
    font-weight: bold;
  }

  &:hover {
    background-color: #368cc9;
    color: #fff;
    cursor: pointer;
  }
`;

export const WrapperAccordion = styled.div`
  margin-bottom: 60px;
`;

export const WrapperPictures = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  img {
    transition: opacity 0.4s;
    margin-bottom: 45px;
    width: 200px;
    height: 150px;
    margin-right: 20px;
    border-radius: 10px;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

export const WrapperContentAccordion = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
`;

export const GroupDesc = styled.p`
  font-size: 20px;
`;

export const TextContent = styled.p`
  font-size: ${(props) => (props.format === 'subdef' ? '16px' : '14px')};
  font-weight: ${(props) => (props.format === 'subdef' ? 'bold' : 'regular')};
  margin-bottom: ${(props) => (props.format === 'subdef' ? '10px' : '5px')};
  text-align: left;
`;
