import { useQuery } from 'react-query';
import API from '../API';

async function getAllAreas() {
  const { data } = await API.sendRequest('Area', 'GET');

  return data;
}

export default function useAllAreasList() {
  return useQuery('allAreas', getAllAreas, {
    refetchOnWindowFocus: false,
  });
}
