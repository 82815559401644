import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import { WrapperContact, WrapperDataContact, Paragraph } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    display: 'flex',
    alignItems: 'left',
    position: 'absolute',
    zIndex: 5000,
    minWidth: '300px',
    minHeight: '100px',
  },
  cardContentStyle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  typographyStyle: {
    textAlign: 'left',
  },
}));

export default function PopupCard(props) {
  const { contact } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentStyle}>
        <Typography
          className={classes.typographyStyle}
          gutterBottom
          variant="subtitle1"
          component="p"
        >
          {contact.FullName}
        </Typography>
        <Typography
          className={classes.typographyStyle}
          variant="subtitle2"
          color="textSecondary"
          component="p"
        >
          {contact.JobTitle}
        </Typography>
        <Typography
          className={classes.typographyStyle}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {contact.AreaDesc}
        </Typography>
      </CardContent>
    </Card>
  );
}
