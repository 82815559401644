import styled from 'styled-components';

export const ProfilePicture = styled.img`
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray80};
  background: ${({ theme }) => theme.colors.grayscale.gray90};
  object-fit: contain;
`;

export const GroupSpan = styled.div`
  display: flex;
  justify-content: space-between;

  span + span {
    font-style: oblique;
  }
`;
