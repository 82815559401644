import styled from 'styled-components';

export const Container = styled.div`
  list-style: none !important;
`;

export const BoxAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;

  span {
    font-size: 8px;
    font-weight: 500;
    white-space: normal;
  }
`;

export const BoxSubject = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const Subject = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

export const DateTitle = styled.span`
  font-size: 8;
  font-weight: 400;
  white-space: noWrap;
`;
