import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  avatar: {
    display: 'block',
    borderRadius: '50%',
    marginLeft: '2px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  initials: {
    display: 'block',
    width: '100%',
    height: '100%',
    lineHeight: '100%',
    borderRadius: '50%',
    textAlign: 'center',
    color: '#fff',
    fontSize: '100%',
    fontWeight: 'bold',
    marginLeft: '2px',
  },
}));
