import styled from 'styled-components';
import { pin } from '../../../assets';

export const PinMap = styled.div`
  height: 65px;
  width: 46px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  -webkit-mask-image: url('${pin}');
  mask-image: url('${pin}');
  background-color: ${(props) => props.backColor};
`;
