import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #ffffff;
  background-color: #326a95;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  i {
    font-size: 0.7rem;
  }
  span {
    font-size: 0.6rem;
    font-weight: 500;
  }
`;
