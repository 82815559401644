import React, { useRef } from 'react';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import Form, { Label, SimpleItem } from 'devextreme-react/form';
import API from '../../../../services/API';
import notify from 'devextreme/ui/notify';
import { bool, func, object, array } from 'prop-types';

function UserEditPopup({
  visible,
  setVisible,
  updateUsersGrid,
  currentUser,
  menus,
  screens,
}) {
  const formRef = useRef(null);

  const handleSave = () => {
    try {
      if (formRef.current.instance.validate().isValid) {
        API.sendRequest('User/save', 'post', currentUser);
        updateUsersGrid();
        notify('User saved with success', 'success', 2000);
        setVisible(false);
      } else {
        notify('Please check the highlighted fields.', 'error', 2000);
      }
    } catch (e) {
      notify(`${e.message}`, 'error', 2000);
    }
  };

  const buttonsOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        handleSave();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setVisible(false);
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      width={700}
      height={300}
      showCloseButton={false}
      showTitle={!!currentUser.FullName}
      title={currentUser.FullName ? currentUser.FullName : ''}
    >
      <Form id="formEditUser" formData={currentUser} colCount={2} ref={formRef}>
        <SimpleItem
          editorType="dxTextBox"
          dataField="Username"
          editorOptions={{ readOnly: true }}
        >
          <Label text="Username" />
        </SimpleItem>
        <SimpleItem
          editorType="dxSelectBox"
          dataField="InitialMobileScreenId"
          editorOptions={{
            items: screens,
            displayExpr: 'MobileScreenDesc',
            valueExpr: 'MobileScreenId',
          }}
        >
          <Label text="InitialScreen" />
        </SimpleItem>
        <SimpleItem
          editorType="dxTextBox"
          dataField="FullName"
          editorOptions={{ readOnly: true }}
        >
          <Label text="FullName" />
        </SimpleItem>
        <SimpleItem
          editorType="dxSelectBox"
          dataField="InitialMenuId"
          editorOptions={{
            items: menus,
            displayExpr: 'MenuFullCaption',
            valueExpr: 'MenuId',
          }}
        >
          <Label text="InitialMenu" />
        </SimpleItem>
        <SimpleItem
          editorType="dxTextBox"
          dataField="Initials"
          editorOptions={{ readOnly: true }}
        >
          <Label text="Initials" />
        </SimpleItem>
        <SimpleItem
          editorType="dxTextBox"
          dataField="UserTypeDesc"
          editorOptions={{ readOnly: true }}
        >
          <Label text="UserType" />
        </SimpleItem>
      </Form>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

export default UserEditPopup;

UserEditPopup.propTypes = {
  visible: bool.isRequired,
  setVisible: func.isRequired,
  updateUsersGrid: func.isRequired,
  currentUser: object.isRequired,
  menus: array.isRequired,
  screens: array.isRequired,
};

UserEditPopup.defaultProps = {
  visible: false,
  setVisible: () => {},
  updateUsersGrid: () => {},
  currentUser: {},
  menus: [],
  screens: [],
};
