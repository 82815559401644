import { useQuery } from 'react-query';
import API from '../API';

async function getDocuSignTemplate() {
  const { data } = await API.sendRequest(
    'DocuSign/Templates/ListEMRTemplates',
    'GET'
  );

  return data;
}

export default function useGetDocuSignTemplate() {
  return useQuery('docuSignTemplate', getDocuSignTemplate, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
}
