/* eslint-disable no-console */
import {
  compareAsc,
  differenceInDays,
  format,
  parseISO,
  startOfToday,
  startOfTomorrow,
} from 'date-fns';
import Form, {
  // ButtonItem,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import List from 'devextreme-react/list';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { LoadPanel } from 'devextreme-react/ui/load-panel';
import {
  AsyncRule,
  CustomRule,
  EmailRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import { array, bool, func, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import API from '../../services/API';
import ImagesAPI from '../../services/ImagesAPI';
import ImageSelector from '../ImageSelector';
import useStyles, {
  CenterContainer,
  GroupSpan,
  RadioOption,
  StyledLabel,
} from './styles';

// obtém o componentes para uso com o form aberto
let displayName;
const handleDisplayNameInitialized = (e) => {
  displayName = e.component;
};

let firstName;
const handleFirstNameInitialized = (e) => {
  firstName = e.component;
};

let lastName;
const handleLastNameInitialized = (e) => {
  lastName = e.component;
};

let initials;
const handleInitialsInitialized = (e) => {
  initials = e.component;
};

let email;
const handleEmailInitialized = (e) => {
  email = e.component;
};

let logonName;
const handleLogonNameInitialized = (e) => {
  logonName = e.component;
};

let oldLogonName;
const handleOldLogonNameInitialized = (e) => {
  oldLogonName = e.component;
};

let imageChanged = false;

function ContactForm({
  isStaff,
  isTraveler,
  visible,
  updateContactGrid,
  currentContact,
  phoneTypes,
  managers,
  deptos,
  areas,
  formTypeAd,
  isNewUser,
  onVisibleChange,
}) {
  const draftUserName = currentContact?.User?.Username;
  const dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
  const now = startOfToday();
  const tomorrow = startOfTomorrow();

  const classes = useStyles();

  const formRef = useRef(null);
  const effDateRef = useRef(null);
  const retDateRef = useRef(null);
  const firstNameRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [phoneOptions, setPhoneOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [contactImage, setContactImage] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [leaveData, setLeaveData] = useState(null);
  const [leaveId, setLeaveId] = useState(null);
  const [travelerId, setTravelerId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [phonesLabel, setPhonesLabel] = useState(false);
  const [prefPhone, setPrefPhone] = useState(null);
  const [leaving, setLeaving] = useState(false);
  const [disableAdOnLeave, setDisableAdOnLeave] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(now);
  const [minEffDate, setMinEffDate] = useState(null);
  const [returnDate, setReturnDate] = useState(tomorrow);
  const [minRetDate, setMinRetDate] = useState(null);
  const [comments, setComments] = useState('');
  const [staffs, setStaffs] = useState(null);
  const [sdUsers, setSdUsers] = useState([]);
  const [isManager, setIsManager] = useState(
    () => currentContact.User.IsManager
  );
  const [isMultiSchedule, setIsMultiSchedule] = useState(false);
  const [lastAdUserChangeLabel, setLastAdUserChangeLabel] = useState('');
  const [lastGroupChangeLabel, setLastGroupChangeLabel] = useState('');
  const [lastPhonesChangeLabel, setLastPhonesChangeLabel] = useState('');
  const [teams, setTeams] = useState(null);
  const [canEditEmail, setCanEditEmail] = useState(isNewUser);
  const [isActive, setIsActive] = useState(false);
  const lastUsernameTested = useRef();

  useEffect(() => {
    handleUpdateGroupsOptions();
  }, [allGroups]); // eslint-disable-line

  const setBlankLeaveData = () => {
    setLeaving(false);
    setDisableAdOnLeave(false);
    setLeaveData(
      isNewUser
        ? [
            {
              LeaveDate: now,
              ReturnDate: tomorrow,
              Comments: '',
            },
          ]
        : [
            {
              UserId: currentContact.User.UserId,
              LeaveDate: now,
              ReturnDate: tomorrow,
              Comments: '',
            },
          ]
    );
  };

  //* ************* leave
  const loadLeave = async (currentContactId) => {
    const { data } = await API.sendRequest(
      `Leave/ListByUser/${currentContactId.toUpperCase()}`,
      'get'
    );
    return data;
  };
  //* *

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await fetchAllGroups();
      if (formTypeAd) {
        const staffsList = await loadStaff();
        setStaffs(staffsList);

        const sdUsersList = await loadSDUsers();
        setSdUsers(sdUsersList);

        if (!currentContact.User.UserId) {
          setBlankLeaveData();
          setLoading(false);
          return;
        }

        const leave = await loadLeave(currentContact.User.UserId);

        if (leave.length > 0) {
          setLeaveId(leave[0].LeaveId);
          setLeaving(true);
          setDisableAdOnLeave(leave[0].DisableAdOnLeave);
          setEffectiveDate(parseISO(leave[0].LeaveDate));
          setReturnDate(parseISO(leave[0].ReturnDate));
          setComments(leave[0].Comments);
          leave[0].LeaveDate = parseISO(leave[0].LeaveDate);
          leave[0].ReturnDate = parseISO(leave[0].ReturnDate);
          setLeaveData(leave);
        } else {
          setBlankLeaveData();
          setDisableAdOnLeave(isTraveler);
        }

        setMinEffDate(
          leave.length
            ? leave[0].LeaveDate < now
              ? leave[0].LeaveDate
              : now
            : now
        );
        setMinRetDate(
          leave.length
            ? leave[0].ReturnDate <= now
              ? leave[0].ReturnDate
              : tomorrow
            : tomorrow
        );

        if (isTraveler) {
          const { data: traveler } = await API.sendRequest(
            `Traveler/ListByUser/${currentContact.User.UserId.toUpperCase()}`,
            'get'
          );

          if (traveler[0]) {
            traveler[0].StartDate = traveler[0].StartDate
              ? parseISO(traveler[0].StartDate)
              : traveler[0].StartDate;
            traveler[0].EndDate = traveler[0].EndDate
              ? parseISO(traveler[0].EndDate)
              : traveler[0].EndDate;

            setEffectiveDate(traveler[0].StartDate);
            setReturnDate(traveler[0].EndDate);
            setTravelerId(traveler[0].TravelerId);

            setMinEffDate(
              traveler.length
                ? traveler[0].StartDate < now
                  ? traveler[0].StartDate
                  : now
                : now
            );
            setMinRetDate(
              traveler.length
                ? traveler[0].EndDate <= now
                  ? traveler[0].EndDate
                  : tomorrow
                : tomorrow
            );
          }
        }
      }

      if (currentContact && currentContact.User) {
        const lastAdUserChange = await getLastChange('ADUser');
        setLastAdUserChangeLabel(lastAdUserChange);
        const lastGroupChange = await getLastChange('ContactGroup');
        setLastGroupChangeLabel(lastGroupChange);
        const lastPhoneChange = await getLastChange('ContactPhone');
        setLastPhonesChangeLabel(lastPhoneChange);
        setIsMultiSchedule(currentContact.User.MultiSchedule);

        await loadTeams(currentContact.User.AreaId);

        if (currentContact.User.ContactId && !currentContact.User.UserId) {
          currentContact.User.UserId = currentContact.User.ContactId;
        }

        if (currentContact && currentContact.Phones) {
          // currentContact.Phones.map((item) => {
          currentContact.Phones.forEach((item) => {
            item.PhoneNumber = item.PhoneNumber.replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll(' ', '')
              .replaceAll('-', '');
          });
          if (currentContact.Phones.length !== 0) {
            setPhonesLabel(true);
            const pref = currentContact.Phones.findIndex(
              (item) => item.Preferred === true
            );

            if (pref >= 0) {
              setPrefPhone(pref);
            } else {
              const mobile = currentContact.Phones.findIndex(
                (item) => item.PhoneTypeId === 4
              );
              if (mobile >= 0) {
                setPrefPhone(mobile);
              } else {
                const anyType = currentContact.Phones.findIndex(
                  (item) => item.Preferred === false
                );
                if (anyType >= 0) {
                  setPrefPhone(anyType);
                }
              }
            }
          }
        }

        if (!formTypeAd) {
          const newItem = {
            DepartmentId: currentContact.User.DepartmentId,
            DepartmentDesc: currentContact.User.Department,
          };

          deptos.push(newItem);
        }

        getPhoneOptions(currentContact);
      }

      setLoading(false);
      if (isNewUser) {
        firstNameRef.current.focus();
      }
    };

    init();
    // eslint-disable-next-line
  }, []);

  // const handleShowChangeHistory = async (topicDesc) => {
  //   if (topicDesc === 'ADUser') {
  //     setHistoryType('xml');
  //   } else {
  //     setHistoryType('json');
  //   }

  //   await getChangeHistory(topicDesc, currentContact.User.UserId).then(
  //     (ret) => {
  //       if (ret) {
  //         setChangeHistory(ret);
  //         setHistoryPopupVisible(true);
  //       }
  //     }
  //   );
  // };

  const groupRenderTemplate = (item) => <div>{item.key}</div>;

  const groupItemRenderTemplate = (item) => (
    <GroupSpan>
      <span>{item.GroupDesc}</span>
      {item.ScheduleControlOrder === 0 && <span>(No Schedule)</span>}
    </GroupSpan>
  );

  const handleDeptoValidation = ({ value }) =>
    value ? true : !(isNewUser && formTypeAd);

  const handleSave = async () => {
    setSaving(true);
    currentContact.IsAdUser = formTypeAd;

    try {
      if (!currentContact?.User?.UserId) {
        const usernameIsValid = await usernameValidation({
          value: currentContact.User.Username,
        });
        if (!usernameIsValid) {
          throw new Error('Username already exists');
        }
      }

      // preparar telefones para gravação
      if (currentContact && currentContact.Phones) {
        // currentContact.Phones.map((item) => {
        currentContact.Phones.forEach((item) => {
          item.PhoneNumber = getPhone(item.PhoneNumber);
        });
      }

      if (leaving && differenceInDays(returnDate, effectiveDate) <= 0) {
        throw new Error('Please, check the effective and return leave date.');
      }

      if (isTraveler && differenceInDays(returnDate, effectiveDate) <= 0) {
        throw new Error('Please, check the start and end date.');
      }

      if (formRef.current.instance.validate().isValid) {
        if (
          !currentContact.Phones ||
          (currentContact.Phones && currentContact.Phones.length <= 0)
        ) {
          throw new Error('Please, inform at least one phone number.');
        }

        // Set the phone selected as preferred
        currentContact.Phones.forEach((item, ind) => {
          item.Preferred = prefPhone === ind;
        });
        const hasPreferred = currentContact.Phones.findIndex(
          (item) => item.Preferred === true
        );
        if (hasPreferred < 0) {
          throw new Error(
            'Please, select at least one phone number as preferred.'
          );
        }

        if (currentContact.User.DepartmentId < 0) {
          // validar departamento
          currentContact.User.DepartmentId = 0;
        }
        if (password && password !== '') {
          currentContact.User.Password = password;
        }

        // saving groups
        const groupsToSave = [];
        selectedGroups.forEach((group) =>
          group.items.forEach((item) => {
            groupsToSave.push(item);
          })
        );

        currentContact.Groups = groupsToSave.map((group) => ({
          ContactId: currentContact.User.UserId,
          GroupId: group.GroupId,
        }));

        if (formTypeAd) {
          if (
            !currentContact.User.Company ||
            currentContact.User.Company === ''
          ) {
            currentContact.User.Company = 'NJ Sharing Network';
          }
          const depto = deptos.find(
            (item) => item.DepartmentId === currentContact.User.DepartmentId
          );
          if (isNewUser) {
            currentContact.User.ExchangeDB = depto.ExchangeDB;
          }
          currentContact.User.Department = depto.DepartmentDesc;
        }

        currentContact.User.ManagerShareNJ = managers?.find(
          (manager) =>
            manager?.ManagerNJSharing === currentContact?.User?.Manager
        )?.ManagerShareNJ;

        if (leaving) {
          currentContact.User.Leave = true;
        }
        if (disableAdOnLeave) {
          currentContact.User.DisableAdOnLeave = true;
        }

        const resContact = await API.sendRequest(
          'ADUser/SaveComplete',
          'POST',
          currentContact
        );

        if (
          (resContact.status !== 200 && resContact.status !== 204) ||
          !!resContact?.ErrorMsg ||
          resContact?.data?.User.ErrorMsg !== null
        ) {
          throw new Error(
            resContact?.message ||
              resContact?.data?.ErrorMsg ||
              resContact?.data?.User.ErrorMsg ||
              'An error ocurred while saving AD User'
          );
        }

        const savedContact = resContact.data;

        if (imageChanged) {
          // formdata object
          const formData = new FormData();
          // append the values with key, value pair
          formData.append('UserId', savedContact.User.UserId);
          formData.append('Username', savedContact.User.Username);
          formData.append('File', contactImage);

          const resImage = await ImagesAPI.sendRequest(
            contactImage === null
              ? 'ADUser/DeleteProfilePhoto'
              : 'ADUser/SaveProfilePhoto',
            'post',
            formData
          );

          if (resImage.status !== 200) {
            throw new Error(
              resImage?.message ||
                resImage?.ErrorMsg ||
                resImage?.data?.ErrorMsg ||
                `An error ocurred while ${
                  contactImage ? 'saving' : 'deleting'
                } AD User image`
            );
          }
        }

        if (currentContact.IsAdUser) {
          if (isTraveler) {
            const travelerData = {
              TravelerId: travelerId ?? undefined,
              UserId: savedContact?.User?.UserId?.toUpperCase(),
              StartDate: effectiveDate,
              EndDate: returnDate,
            };

            const resTravelerSave = await API.sendRequest(
              'Traveler',
              'POST',
              travelerData
            );

            if (
              (resTravelerSave.status !== 200 &&
                resTravelerSave.status !== 204) ||
              !!resTravelerSave?.data?.ErrorMsg
            ) {
              throw new Error(
                !!resTravelerSave?.data?.ErrorMsg ||
                  `An error ocurred while saving AD User Traveler dates`
              );
            }
          }

          if (leaving) {
            if (
              compareAsc(leaveData[0].LeaveDate, effectiveDate) !== 0 ||
              compareAsc(leaveData[0].ReturnDate, returnDate) !== 0
            ) {
              const copyLeaveData = leaveData;

              if (isNewUser) {
                copyLeaveData[0].UserId = savedContact.User.UserId;
              }
              copyLeaveData[0].DisableAdOnLeave = disableAdOnLeave;
              copyLeaveData[0].LeaveDate = effectiveDate;
              copyLeaveData[0].ReturnDate = returnDate;
              copyLeaveData[0].Comments = comments;
              copyLeaveData[0].IsTraveler = isTraveler;
              const resSaveLeave = await API.sendRequest(
                'Leave',
                'POST',
                copyLeaveData[0]
              );

              if (
                (resSaveLeave.status !== 200 && resSaveLeave.status !== 204) ||
                !!resSaveLeave?.data?.ErrorMsg
              ) {
                throw new Error(
                  resSaveLeave?.data?.ErrorMsg ||
                    `An error ocurred while saving AD User leave`
                );
              }
            }
          } else if (leaveId) {
            const resDeleteLeave = await API.sendRequest(
              `Leave/${leaveId}`,
              'DELETE',
              leaveId
            );

            if (
              (resDeleteLeave.status !== 200 &&
                resDeleteLeave.status !== 204) ||
              !!resDeleteLeave?.data?.ErrorMsg
            ) {
              throw new Error(`An error ocurred while deleting AD User leave`);
            }
          }
        }
        notify(`Contact saved.`, 'success', 4000);
        updateContactGrid();
        onHiding();
      } else {
        setSaving(false);
        notify(`Please check the highlighted fields.`, 'error', 4000);
        formRef.current.instance.validate();
      }
    } catch (e) {
      setSaving(false);
      notify(`${e.message}`, 'error', 4000);
    }
  };

  const getPhone = (value) =>
    value.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');

  const usernameValidation = async (params) => {
    if (params.value === draftUserName && currentContact?.User?.UserId) {
      return true;
    }
    if (params.value?.length > 3) {
      if (params.value !== lastUsernameTested.current?.username) {
        const result = await API.sendRequest(
          `ADUser/UserExist/${params.value}`,
          'get'
        ).then((x) => !x.data);
        lastUsernameTested.current = { username: params.value, result };
        return result;
      }
      return lastUsernameTested.current?.result;
    }
    return false;
  };

  const onHiding = () => {
    imageChanged = false;
    onVisibleChange(false);
  };

  // popup buttons
  const buttonOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        handleSave();
      },
    },
    {
      text: 'Cancel',
      onClick: () => {
        onVisibleChange(false);
      },
    },
  ];

  //* ************** groups
  useEffect(() => {
    handleUpdateGroupsOptions();
  }, [isMultiSchedule]); //eslint-disable-line

  const handleUpdateGroupsOptions = () => {
    let filteredGroups = null;
    if (isMultiSchedule) {
      filteredGroups = allGroups;
    } else {
      filteredGroups = allGroups.filter(
        (group) =>
          group.AreaId === 0 || group.AreaId === currentContact.User.AreaId
      );
    }

    const editedGroups = filteredGroups.map((group) => ({
      GroupId: group.GroupId,
      GroupDesc: `${group.GroupCode} - ${group.GroupName}`,
      ScheduleControlOrder: group.ScheduleControlOrder,
      AreaId: group.AreaId,
      AreaDesc: areas.find((a) => a.AreaId === group.AreaId).AreaDesc,
      disabled: false,
      // disabled: group.GroupId === 8,
    }));

    const orderedGroups = getDistinctAreasId(editedGroups).map((areaId) => ({
      key: areas.find((area) => area.AreaId === areaId).AreaDesc,
      items: editedGroups.filter((group) => group.AreaId === areaId),
    }));

    const groups = filteredGroups;
    const list = groups.filter((item) =>
      currentContact.Groups.find((exist) => exist.GroupId === item.GroupId)
    );
    if (
      !list.find((group) => group.GroupId === 8) &&
      isStaff &&
      !currentContact.User.UserId
    ) {
      list.push({
        GroupCode: 'STF',
        GroupName: 'Staff',
        GroupId: 8,
        AreaId: 0,
      });
    }
    const userGroups = list.map((group) => ({
      GroupId: group.GroupId,
      GroupDesc: `${group.GroupCode} - ${group.GroupName}`,
      ScheduleControlOrder: group.ScheduleControlOrder,
      AreaId: group.AreaId,
      AreaDesc: areas.find((a) => a.AreaId === group.AreaId).AreaDesc,
      disabled: false,
      // disabled: group.GroupId === 8,
    }));

    const groupsByArea = getDistinctAreasId(userGroups).map((areaId) => ({
      key: areas.find((area) => area.AreaId === areaId).AreaDesc,
      items: userGroups.filter((group) => group.AreaId === areaId),
    }));

    const isOptionsDifferent =
      JSON.stringify(groupOptions) !== JSON.stringify(orderedGroups);

    if (isOptionsDifferent) {
      setGroupOptions(orderedGroups);
    }

    const isGroupsSelectedDifferent =
      JSON.stringify(selectedGroups) !== JSON.stringify(groupsByArea);

    if (isGroupsSelectedDifferent) {
      setSelectedGroups(groupsByArea);
    }
  };

  const getDistinctAreasId = (groupsArray) => {
    const distinctAreas = groupsArray
      .map((group) => group.AreaId)
      .filter(
        (currentArea, index, areas) => areas.indexOf(currentArea) === index
      );
    return distinctAreas;
  };

  const loadStaff = async () => {
    const { data } = await API.sendRequest('Staff', 'get');
    return data;
  };

  const loadSDUsers = async () => {
    const { data } = await API.sendRequest('ADUser/SDUserList');
    return data;
  };

  const onSelectedGroupsChange = (args) => {
    let isDifferent = false;
    if (args.name === 'selectedItems') {
      isDifferent =
        JSON.stringify(args.value) !== JSON.stringify(args.previousValue);
    }
    if (args.name === 'selectedItems' && isDifferent) {
      const currentValueCopy = args.value;
      const currentSelectedGroups = [];
      const previousSelectedGroups = [];

      currentValueCopy.forEach((opt) =>
        opt.items.forEach((i) => currentSelectedGroups.push(i))
      );
      args.previousValue.forEach((opt) =>
        opt.items.forEach((i) => previousSelectedGroups.push(i))
      );

      const selectedItem = currentSelectedGroups.filter(
        (item) => !previousSelectedGroups.includes(item)
      )[0];

      if (selectedItem) {
        const itemsToRemove = [];
        if (selectedItem.ScheduleControlOrder !== 0) {
          // Verify if has items to remove and save them
          currentValueCopy.forEach((opt) => {
            if (opt.key === selectedItem.AreaDesc) {
              const itemToDelete = opt.items.find(
                (group) =>
                  group.GroupId !== selectedItem.GroupId &&
                  group.ScheduleControlOrder !== 0
              );
              if (itemToDelete) {
                itemsToRemove.push(itemToDelete);
              }
            }

            // Remove items from selected items array
            if (itemsToRemove.length > 0) {
              // For each item to be removed get the group key index
              itemsToRemove.forEach((item) => {
                const keyIndex = currentValueCopy.findIndex(
                  (value) => value.key === item.AreaDesc
                );

                // If the item are finded on group array, remove it
                if (
                  currentValueCopy[keyIndex].items.findIndex(
                    (i) => i.GroupId === item.GroupId
                  ) > -1
                ) {
                  currentValueCopy[keyIndex].items.splice(
                    currentValueCopy[keyIndex].items.findIndex(
                      (i) => i.GroupId === item.GroupId
                    ),
                    1
                  );
                }
              });
            }
          });
        }
      }
      setSelectedGroups(currentValueCopy);
    }
  };

  const fetchAllGroups = async () => {
    const { data, status } = await API.sendRequest(
      'Group/ListNotSystemGroups',
      'get'
    );
    if (status === 200) {
      setAllGroups(data);
    }
  };
  //* ************** groups

  //* ************** teams

  const loadTeams = async (areaId) => {
    const area = areas.find((a) => a.AreaId === areaId);
    if (area.UseTeams) {
      const res = await API.sendRequest(`Team/ListByArea/${areaId}`, 'GET');
      if (res.status === 200) {
        setTeams(res.data);
      }
    } else {
      setTeams(null);
    }
  };

  //* ************** teams

  //* ************** phones
  const handlePreferredChange = (e) => {
    const value = Number(e.target.value);
    setPrefPhone(value);
    currentContact.Phones.forEach((item, ind) => {
      item.Preferred = value === ind;
    });
  };

  const addPhoneButtonOptions = {
    icon: 'add',
    text: 'Add phone',
    onClick: () => {
      if (!currentContact.Phones) {
        currentContact.Phones = [];
      }
      const phonesOrder = currentContact.Phones.map(
        (phone) => phone.ContactPhoneOrder
      );

      const maxPhoneOrder = phonesOrder.reduce(
        (acc, current) => Math.max(acc, current),
        0
      );

      setPhonesLabel(maxPhoneOrder >= 0 && true);
      setPrefPhone(maxPhoneOrder === 0 ? 0 : prefPhone);

      currentContact.Phones.push({
        ContactId: currentContact.User.UserId,
        PhoneTypeId: 0,
        ContactPhoneOrder: maxPhoneOrder + 1,
        PhoneNumber: '',
        Preferred: maxPhoneOrder === 0,
      });
      getPhoneOptions(currentContact);
    },
  };

  const getPhoneOptions = (user) => {
    const options = [];

    if (user && user.Phones) {
      for (let i = 0; i < user.Phones.length; i++) {
        options.push(generateNewPhoneOptions(user.Phones[i], i));
      }
    }

    setPhoneOptions(options);

    return options;
  };

  const generateNewPhoneOptions = (phone, index) => ({
    maxLength: 50,
    mode: 'Tel',
    mask: '(000) 000-0000',
    // onInitialized: handlePhoneInitialized,

    buttons: [
      {
        name: `trash`,
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'trash',
          onClick: () => {
            currentContact.Phones.splice(index, 1);
            const preferred = currentContact.Phones.findIndex(
              (item) => item.Preferred === true
            );
            if (preferred === -1) {
              const mobile = currentContact.Phones.findIndex(
                (item) => item.PhoneTypeId === 4
              );
              if (mobile >= 0) {
                setPrefPhone(mobile);
              } else {
                const anyType = currentContact.Phones.findIndex(
                  (item) => item.Preferred === false
                );
                if (anyType >= 0) {
                  setPrefPhone(anyType);
                }
              }
            }
            getPhoneOptions(currentContact);
            setPhonesLabel(currentContact.Phones.length !== 0);
          },
        },
      },
    ],
  });
  //* ************** phones

  //* ************** profile photo
  const handleContactImageSelected = (e) => {
    // if (e?.size > 100000) {
    //   notify("Image size can't be bigger than 100kb", 'warning', 4000);
    //   return;
    // }
    setContactImage(e);
    imageChanged = true;
  };

  const handleContactImageDeleted = () => {
    setContactImage(null);
    imageChanged = true;
  };
  //* ************** profile photo

  //* ************** field events
  // mudança no firstname atualiza initials, email e logoname
  const FirstNameValueChanged = (e) => {
    const _ln = lastName.option('value') ? lastName.option('value') : ' ';
    const _dn = `${e.value.trim()} ${_ln}`;
    displayName.option('value', _dn.trim());

    const _ini = _dn
      .split(' ')
      .map((item) => item[0])
      .join('')
      .toUpperCase()
      .padEnd(5);
    initials.option('value', _ini.substring(0, 5).trim());

    // atualiza o logonName e email automaticamente apenas para usuários novos
    if (formTypeAd /* && isNewUser */) {
      if (!email.option('disabled')) {
        const _email = `${e.value
          .padEnd(1)[0]
          .trim()}${_ln.trim()}@njsharingnetwork.org`;
        email.option(
          'value',
          _email
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
          // .toLowerCase()
        );
        if (isNewUser) {
          logonName.option(
            'value',
            `${e.value.padEnd(1)[0].trim()}${_ln.trim()}`.trim().toLowerCase()
          );
          oldLogonName.option(
            'value',
            `${e.value.padEnd(1)[0].trim()}${_ln.trim()}`.trim().toLowerCase()
          );
        }
      }
    }
  };

  // mudança no lastname atualiza initials, email e logoname
  const LastNameValueChanged = (e) => {
    const _fn = firstName.option('value')
      ? firstName.option('value').padEnd(1)
      : ' ';

    const _dn = `${_fn} ${e.value.trim()}`;
    displayName.option('value', _dn);

    const _ini = _dn
      .split(' ')
      .map((item) => item[0])
      .join('')
      .toUpperCase()
      .padEnd(5);
    initials.option('value', _ini.substring(0, 5).trim());

    // atualiza o logonName e email automaticamente apenas para usuários novos
    if (formTypeAd /* && isNewUser */) {
      const _email = `${_fn.padEnd(1)[0].trim()}${e.value
        .trim()
        .replace(' ', '')}@njsharingnetwork.org`;
      email.option(
        'value',
        _email
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim()
        // .toLowerCase()
      );
      if (isNewUser) {
        logonName.option(
          'value',
          `${_fn.padEnd(1)[0].trim()}${e.value.trim().replace(' ', '')}`
            .trim()
            .toLowerCase()
        );
        oldLogonName.option(
          'value',
          `${_fn.padEnd(1)[0].trim()}${e.value.trim().replace(' ', '')}`
            .trim()
            .toLowerCase()
        );
      }
    }
  };

  const handleDeptoChanged = (e) => {
    if (e.selectedItem) {
      currentContact.User.Department = e.selectedItem.DepartmentDesc;
    }
  };

  const handlePasswordChanged = (e) => {
    setPassword(e.value);
  };

  const handleDateChanged = (e, dateType) => {
    const { value } = e;
    const newDate = parseISO(format(value, dateTimeFormat));
    if (dateType === 'eff') {
      setEffectiveDate(newDate);
    }
    if (dateType === 'ret') {
      setReturnDate(newDate);
    }
  };

  const handleCommentsChange = ({ value }) => {
    setComments(value);
  };

  const handleLeaveChanged = ({ value }) => {
    setLeaving(value);
    if (!value) {
      setDisableAdOnLeave(false);
      setIsActive(true);
    }
  };

  const passwordComparison = () => {
    if (
      (!password || password === '') &&
      (!confirmPassword || confirmPassword === '')
    ) {
      return false;
    }
    return password === confirmPassword;
  };

  const dateComparison = () => {
    const fieldRetDate = retDateRef.current.props.editorOptions.value;
    const fieldEffDate = effDateRef.current.props.editorOptions.value;

    if (
      leaveData &&
      leaveData.length > 0 &&
      (compareAsc(leaveData[0].LeaveDate, fieldEffDate) !== 0 ||
        compareAsc(leaveData[0].ReturnDate, fieldRetDate) !== 0) &&
      fieldRetDate > fieldEffDate
    ) {
      return true;
    }

    return (
      differenceInDays(
        parseISO(
          format(retDateRef.current.props.editorOptions.value, dateTimeFormat)
        ),
        parseISO(
          format(effDateRef.current.props.editorOptions.value, dateTimeFormat)
        )
      ) > 0
    );
  };

  //* ************** field events

  const getPrefixField = () => {
    if (formTypeAd) {
      return <SimpleItem itemType="empty" cssClass={classes.EmptyItem} />;
    }
    return (
      <SimpleItem dataField="Prefix">
        <Label text="Prefix" />
      </SimpleItem>
    );
  };

  const getProfilePhotoField = () => {
    if (formTypeAd) {
      return (
        <GroupItem colCount={4}>
          <SimpleItem dataField="User.Username" colSpan={1}>
            <Label text="Profile Photo" location="top" visible={false} />
            <ImageSelector
              contact={currentContact.User}
              onFileSelected={handleContactImageSelected}
              onFileDeleted={handleContactImageDeleted}
            />
            <span
              style={{
                color: '#00000080',
                fontSize: '12px',
                fontWeight: 600,
                whiteSpace: 'nowrap',
              }}
            >
              *Maximum size: 100kb
            </span>
          </SimpleItem>
          {isTraveler ? getTravelerField() : getLeaveField()}
        </GroupItem>
      );
    }
    return <SimpleItem itemType="empty" cssClass={classes.EmptyItem} />;
  };

  const getTravelerField = () => {
    if (formTypeAd) {
      return (
        <GroupItem cssClass={classes.DatesItem} colSpan={3}>
          <SimpleItem itemType="group" colCount={2}>
            <SimpleItem colSpan={2}>
              <b>Traveler Working Period</b>
            </SimpleItem>
            <SimpleItem
              colSpan={1}
              editorType="dxDateBox"
              ref={effDateRef}
              editorOptions={{
                type: 'date',
                defaultValue: effectiveDate,
                value: effectiveDate,
                onValueChanged: (e) => {
                  handleDateChanged(e, 'eff');
                },
                min: minEffDate,
              }}
            >
              <Label text="Start Date" location="top" />
              <CustomRule
                message="The start date must be before the end date."
                validationCallback={dateComparison}
              />
            </SimpleItem>
            <SimpleItem
              name="date-return"
              colSpan={1}
              editorType="dxDateBox"
              ref={retDateRef}
              editorOptions={{
                type: 'date',
                value: returnDate,
                defaultValue: returnDate,
                onValueChanged: (e) => {
                  handleDateChanged(e, 'ret');
                },
                min: minRetDate,
              }}
            >
              <Label text="End Date" location="top" />
              <CustomRule
                message="The end date must be after the start date."
                validationCallback={dateComparison}
              />
            </SimpleItem>
          </SimpleItem>
        </GroupItem>
      );
    }
  };

  const getLeaveField = () => {
    if (formTypeAd) {
      return (
        <GroupItem cssClass={classes.DatesItem} colSpan={3}>
          <SimpleItem itemType="group" colCount={2}>
            <SimpleItem
              colSpan={1}
              editorType="dxCheckBox"
              editorOptions={{
                text: 'Leave',
                defaultValue: leaving,
                value: leaving,
                disabled: isTraveler,
                onValueChanged: handleLeaveChanged,
              }}
            />

            <SimpleItem
              colSpan={1}
              editorType="dxCheckBox"
              visible={leaving}
              editorOptions={{
                text: isTraveler
                  ? 'Enable login during travel time'
                  : 'Disable login during leave time',
                defaultValue: disableAdOnLeave,
                value: disableAdOnLeave,
                onValueChanged: ({ value }) => setDisableAdOnLeave(value),
              }}
            />
            <SimpleItem
              colSpan={1}
              editorType="dxDateBox"
              ref={effDateRef}
              editorOptions={{
                type: 'date',
                defaultValue: effectiveDate,
                value: effectiveDate,
                onValueChanged: (e) => {
                  handleDateChanged(e, 'eff');
                },
                min: minEffDate,
                visible: leaving,
              }}
            >
              <Label text="Effective Date" location="top" visible={leaving} />
              <CustomRule
                message="The effective date must be before the return date."
                validationCallback={dateComparison}
              />
            </SimpleItem>
            <SimpleItem
              name="date-return"
              colSpan={1}
              editorType="dxDateBox"
              ref={retDateRef}
              editorOptions={{
                type: 'date',
                value: returnDate,
                defaultValue: returnDate,
                onValueChanged: (e) => {
                  handleDateChanged(e, 'ret');
                },
                min: minRetDate,
                visible: leaving,
              }}
            >
              <Label text="Return Date" location="top" visible={leaving} />
              <CustomRule
                message="The return date must be after the effective date."
                validationCallback={dateComparison}
              />
            </SimpleItem>
            <SimpleItem
              colSpan={2}
              editorType="dxTextBox"
              editorOptions={{
                placeholder: 'Comments',
                value: comments,
                onValueChanged: handleCommentsChange,
                visible: leaving || isTraveler,
              }}
            />
          </SimpleItem>
        </GroupItem>
      );
    }

    return <SimpleItem itemType="empty" cssClass={classes.EmptyItem} />;
  };

  const getPasswordField = () => {
    if (formTypeAd && isNewUser) {
      return (
        <SimpleItem
          editorType="dxTextBox"
          editorOptions={{
            value: password,
            // mode: 'password',
            showClearButton: true,
            onValueChanged: handlePasswordChanged,
          }}
        >
          <RequiredRule message="The password for a new user is required." />
          <StringLengthRule
            message="Password must have at least 14 characters"
            min={14}
          />
          <Label text="Password" />
        </SimpleItem>
      );
    }
    return <SimpleItem itemType="empty" cssClass={classes.EmptyItem} />;
  };
  const getConfirmPasswordField = () => {
    if (formTypeAd && isNewUser) {
      return (
        <SimpleItem
          editorType="dxTextBox"
          editorOptions={{
            value: confirmPassword,
            onValueChanged: (e) => setConfirmPassword(e.value),
            // mode: 'password',
            showClearButton: true,
          }}
        >
          <CustomRule
            message="Password and Confirm Password do not match"
            reevaluate
            validationCallback={passwordComparison}
          />
          <Label text="Confirm Password" />
        </SimpleItem>
      );
    }
    return <SimpleItem itemType="empty" cssClass={classes.EmptyItem} />;
  };

  const customItemCreating = (args) => {
    if (!args.text) {
      args.customItem = null;
      return;
    }

    const idArray = deptos.map((depto) => depto.DepartmentId);

    // id com valor negativo, significa que não existe na base
    const newItem = {
      DepartmentDesc: args.text,
      DepartmentId: Math.min(...idArray) - 1,
    };

    deptos.push(newItem);

    args.customItem = newItem;
  };

  const getManagerField = () => {
    if (formTypeAd) {
      return (
        <SimpleItem
          dataField="User.Manager"
          editorType="dxSelectBox"
          editorOptions={{
            items: managers,
            searchEnabled: true,
            displayExpr: 'DisplayName',
            valueExpr: 'ManagerNJSharing',
            showClearButton: true,
          }}
          colSpan={1}
        >
          <Label text="Manager" />
        </SimpleItem>
      );
    }
    return <SimpleItem itemType="empty" cssClass={classes.EmptyItem} />;
  };

  const getLogonNameField = () => (
    <GroupItem colCount={2} colSpan={2}>
      <SimpleItem
        dataField="User.Username"
        editorOptions={{
          maxLength: 20,
          // disabled: formTypeAd && !isNewUser,
          onInitialized: handleLogonNameInitialized,
        }}
      >
        <AsyncRule
          message="Username already exists"
          validationCallback={usernameValidation}
        />
        <StringLengthRule
          message="Username max length 20 characters"
          max={20}
        />

        <Label text="Username" />
      </SimpleItem>
      <SimpleItem
        dataField="User.OldUsername"
        editorOptions={{
          maxLength: 20,
          onInitialized: handleOldLogonNameInitialized,
        }}
      >
        <StringLengthRule
          message="Old Username max length 20 characters"
          max={20}
        />
        <Label text="Old Username" />
      </SimpleItem>
    </GroupItem>
  );
  const handleIsManagerChange = (e) => {
    if (!e.value) {
      currentContact.User.UseRank = false;
      currentContact.User.AllowSwitch = false;
    }
    setIsManager(e.value);
  };

  const getIsManagerField = () => {
    if (formTypeAd) {
      return (
        <SimpleItem itemType="group" colCount={1}>
          <SimpleItem itemType="group" colCount={2}>
            {/* <SimpleItem dataField="User.Active" editorType="dxCheckBox">
              <Label text="Active" />
            </SimpleItem> */}
            <SimpleItem dataField="User.IsDirector" editorType="dxCheckBox">
              <Label text="Is Director" />
            </SimpleItem>
          </SimpleItem>
          <SimpleItem itemType="group" colCount={3}>
            <SimpleItem
              dataField="User.IsManager"
              editorType="dxCheckBox"
              editorOptions={{
                onValueChanged: (e) => handleIsManagerChange(e),
              }}
            >
              <Label text="Is Manager" />
            </SimpleItem>
            <SimpleItem
              dataField="User.UseRank"
              editorType="dxCheckBox"
              visible={isManager}
            >
              <Label text="Use Performance" />
            </SimpleItem>
            <SimpleItem
              dataField="User.AllowSwitch"
              editorType="dxCheckBox"
              visible={isManager}
            >
              <Label text="Allow Switch" />
            </SimpleItem>
          </SimpleItem>
        </SimpleItem>
      );
    }
    return <SimpleItem itemType="empty" cssClass={classes.EmptyItem} />;
  };

  const getEmailField = () => (
    <SimpleItem
      colSpan={2}
      dataField="User.Email"
      editorType="dxTextBox"
      editorOptions={{
        maxLength: 200,
        readOnly: !isNewUser && !canEditEmail,
        onInitialized: handleEmailInitialized,
        buttons: [
          {
            name: 'edit',
            location: 'before',
            options: {
              icon: canEditEmail ? 'fas fa-lock' : 'fas fa-pen',
              stylingMode: 'text',
              hint: canEditEmail ? 'Block edit email' : 'Allow edit email',
              onClick: () => setCanEditEmail(!canEditEmail),
              visible: !isNewUser,
              disabled: isNewUser,
            },
          },
        ],
      }}
    >
      <EmailRule message="Email is invalid" />
      <Label text="Email" />
    </SimpleItem>
  );

  // const showLastChangeLabel = (lastChangeLabel) => {
  //   return <span>{lastChangeLabel}</span>;
  // };

  const getLastChange = async (topicDesc) => {
    const lastChange = API.sendRequest(
      `Audit/LastChange/${topicDesc}/${currentContact.User.UserId}`,
      'GET'
    ).then((res) => {
      if (res && res.status === 200 && res.data) {
        return `Last change on ${format(
          parseISO(res.data.ChangeDate),
          "MM/dd/yyyy 'at' HH:mm a"
        )} by ${res.data.Username}`;
      }
      return '';
    });
    return lastChange;
  };

  return (
    <>
      {/* {historyPopupVisible && (
        <HistoryPopup
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={changeHistory}
          historyType={historyType}
        />
      )} */}
      <Popup
        visible={visible && !loading}
        onHidden={onHiding}
        dragEnabled
        closeOnOutsideClick
        showTitle={false}
        height="90%"
      >
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={saving || loading}
          message={saving ? 'Saving...' : 'Loading...'}
          closeOnOutsideClick={false}
        />
        {buttonOptions.map((options, index) => (
          <ToolbarItem
            key={index}
            options={options}
            widget="dxButton"
            toolbar="bottom"
            location="after"
          />
        ))}
        <ScrollView width="100%" height="100%">
          <Form
            id="form"
            formData={currentContact}
            readOnly={false}
            showColonAfterLabel
            labelLocation="left"
            colCount={5}
            ref={formRef}
            style={{
              paddingRight: '10px',
              paddingLeft: '10px',
            }}
            validationGroup="userData"
            // minColWidth={minColWidth}
            // width={width}
          >
            <GroupItem caption="Personal Data" colSpan={3}>
              <GroupItem itemType="group" colCount={2} visible={!isNewUser}>
                <SimpleItem>
                  <span>{lastAdUserChangeLabel}</span>
                </SimpleItem>
                {/* <ButtonItem
                  horizontalAlignment="right"
                  buttonOptions={{
                    visible: canViewHistory,
                    hint: 'Show Change History ',
                    icon: 'fas fa-history',
                    onClick: () => handleShowChangeHistory('ADUser'),
                  }}
                /> */}
              </GroupItem>
              {getProfilePhotoField()}
              {getPrefixField()}
              <GroupItem colCount={3}>
                <SimpleItem
                  dataField="User.Active"
                  editorType="dxCheckBox"
                  editorOptions={{
                    value: currentContact.User.Active || isActive,
                  }}
                >
                  <Label text="Active" />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField="User.FirstName"
                  editorType="dxTextBox"
                  editorOptions={{
                    ref: firstNameRef,
                    valueChangeEvent: 'keyup',
                    onInitialized: handleFirstNameInitialized,
                    onValueChanged: FirstNameValueChanged,
                  }}
                  colSpan={1}
                >
                  <RequiredRule message="First name is required" />
                  <Label text="First Name" />
                </SimpleItem>
                <SimpleItem
                  dataField="User.LastName"
                  editorType="dxTextBox"
                  editorOptions={{
                    valueChangeEvent: 'keyup',
                    onInitialized: handleLastNameInitialized,
                    onValueChanged: LastNameValueChanged,
                  }}
                  colSpan={1}
                >
                  {formTypeAd && (
                    <RequiredRule message="Last name is required" />
                  )}
                  <Label text="Last Name" />
                </SimpleItem>
                <SimpleItem
                  dataField="User.DisplayName"
                  editorType="dxTextBox"
                  editorOptions={{
                    maxLength: 100,
                    onInitialized: handleDisplayNameInitialized,
                  }}
                  colSpan={1}
                >
                  <RequiredRule message="Display name is required" />
                  <Label text="Display Name" />
                </SimpleItem>
                <SimpleItem
                  dataField="User.Initials"
                  editorType="dxTextBox"
                  editorOptions={{
                    maxLength: 5,
                    onInitialized: handleInitialsInitialized,
                  }}
                  colSpan={1}
                >
                  <RequiredRule message="Initials is required" />
                  <Label text="Initials" />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  colSpan={teams ? 1 : 2}
                  dataField="User.AreaId"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: areas,
                    displayExpr: 'AreaDesc',
                    valueExpr: 'AreaId',
                    onValueChanged: ({ value }) => {
                      handleUpdateGroupsOptions();
                      loadTeams(value);
                    },
                  }}
                >
                  <Label text="Area" />
                  <RequiredRule message="Area is required" />
                </SimpleItem>
                <SimpleItem
                  visible={!!teams}
                  dataField="User.TeamId"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: teams,
                    displayExpr: 'TeamName',
                    valueExpr: 'TeamId',
                  }}
                >
                  <Label text="Team" />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField="User.DepartmentId"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: deptos,
                    searchEnabled: true,
                    acceptCustomValue: !formTypeAd,
                    displayExpr: 'DepartmentDesc',
                    valueExpr: 'DepartmentId',
                    onCustomItemCreating: (e) => {
                      customItemCreating(e);
                    },
                    // Atribuir a descrição ao fazer a alteração
                    onSelectionChanged: (e) => {
                      handleDeptoChanged(e);
                    },
                  }}
                  colSpan={1}
                >
                  <CustomRule
                    message="Select a department for a new AD User"
                    validationCallback={handleDeptoValidation}
                  />
                  <Label text="Department" />
                  <RequiredRule message="Department is required" />
                </SimpleItem>
                {getManagerField()}
                <SimpleItem
                  dataField="User.MultiSchedule"
                  editorType="dxCheckBox"
                  editorOptions={{
                    onValueChanged: (e) => {
                      setIsMultiSchedule(e.value);
                    },
                    value: isMultiSchedule,
                  }}
                >
                  <Label text="Multi Schedule" />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem dataField="User.Company">
                  <Label text="Company" />
                </SimpleItem>
                <SimpleItem dataField="User.JobTitle">
                  <Label text="Job Title" />
                </SimpleItem>
              </GroupItem>
              <SimpleItem
                dataField="User.Description"
                editorType="dxTextArea"
                height={90}
              >
                <Label text="Description" />
              </SimpleItem>
              <GroupItem colCount={1}>{getEmailField()}</GroupItem>
              {getLogonNameField()}
              {formTypeAd && (
                <GroupItem colCount={2}>
                  <SimpleItem
                    dataField="User.SDUserId"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: sdUsers,
                      valueExpr: 'SDUserId',
                      displayExpr: 'First_Name',
                      searchEnabled: true,
                      searchMode: 'contains',
                      searchExpr: 'First_Name',
                    }}
                  >
                    <Label text="Service Desk" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="User.StaffId"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: staffs,
                      valueExpr: 'StaffId',
                      displayExpr: 'FullName',
                      searchEnabled: true,
                      searchMode: 'contains',
                      searchExpr: 'FullName',
                    }}
                  >
                    <Label text="POAP" />
                  </SimpleItem>
                </GroupItem>
              )}
              {getPasswordField()}
              {getConfirmPasswordField()}
              {getIsManagerField()}
            </GroupItem>
            <GroupItem colSpan={2}>
              <GroupItem caption="Groups" visible={formTypeAd}>
                <GroupItem itemType="group" colCount={2} visible={!isNewUser}>
                  <SimpleItem>
                    <span>{lastGroupChangeLabel}</span>
                  </SimpleItem>
                  {/* <ButtonItem
                    horizontalAlignment="right"
                    buttonOptions={{
                      visible: canViewHistory,
                      hint: 'Show Change History ',
                      icon: 'fas fa-history',
                      onClick: () => handleShowChangeHistory('ContactGroup'),
                    }}
                  /> */}
                </GroupItem>
                <SimpleItem itemType="simple">
                  {groupOptions && (
                    <List
                      dataSource={groupOptions}
                      height={350}
                      keyExpr="GroupId"
                      displayExpr="GroupDesc"
                      showSelectionControls
                      selectionMode="multiple"
                      selectedItems={selectedGroups}
                      onOptionChanged={(args) => {
                        onSelectedGroupsChange(args);
                      }}
                      showScrollbar="always"
                      useNativeScrolling
                      grouped
                      collapsibleGroups
                      groupRender={groupRenderTemplate}
                      itemRender={groupItemRenderTemplate}
                    />
                  )}
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="Phones" name="phones-container">
                <GroupItem itemType="group" colCount={2} visible={!isNewUser}>
                  <SimpleItem>
                    <span>{lastPhonesChangeLabel}</span>
                  </SimpleItem>
                  {/* <ButtonItem
                    horizontalAlignment="right"
                    buttonOptions={{
                      visible: canViewHistory,
                      hint: 'Show Change History ',
                      icon: 'fas fa-history',
                      onClick: () => handleShowChangeHistory('ContactPhone'),
                    }}
                  /> */}
                </GroupItem>
                <GroupItem name="phones" itemType="group">
                  <GroupItem
                    colCount={7}
                    itemType="group"
                    visible={phonesLabel}
                  >
                    <SimpleItem colSpan={1} itemType="simple">
                      <CenterContainer>
                        <StyledLabel>Preferred</StyledLabel>
                      </CenterContainer>
                    </SimpleItem>
                    <SimpleItem colSpan={3} itemType="simple">
                      <StyledLabel>Type</StyledLabel>
                    </SimpleItem>
                    <SimpleItem colSpan={3} itemType="simple">
                      <StyledLabel>Phone</StyledLabel>
                    </SimpleItem>
                  </GroupItem>
                  {phoneOptions.map((phone, index) => (
                    <GroupItem
                      colCount={7}
                      key={`PhoneItem${index}`}
                      itemType="group"
                    >
                      <SimpleItem colSpan={1} itemType="simple">
                        <CenterContainer key={`PhonePreferred${index}`}>
                          <RadioOption
                            name="preferred"
                            value={index}
                            checked={prefPhone === index}
                            onChange={(e) => handlePreferredChange(e)}
                            inputProps={{ 'aria-label': index }}
                          />
                        </CenterContainer>
                      </SimpleItem>
                      <SimpleItem
                        colSpan={3}
                        key={`PhoneType${index}`}
                        dataField={`Phones[${index}].PhoneTypeId`}
                        editorType="dxSelectBox"
                        editorOptions={{
                          items: phoneTypes,
                          searchEnabled: true,
                          displayExpr: 'PhoneTypeDesc',
                          valueExpr: 'PhoneTypeId',
                        }}
                      >
                        <RequiredRule message="Phone type is required" />
                        <Label text="Type" visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        colSpan={3}
                        key={`Phone${index}`}
                        dataField={`Phones[${index}].PhoneNumber`}
                        editorOptions={phone}
                      >
                        <RequiredRule message="Phone number is required" />
                        <Label text="Phone" visible={false} />
                      </SimpleItem>
                    </GroupItem>
                  ))}
                </GroupItem>
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="left"
                  cssClass="add-phone-button"
                  buttonOptions={addPhoneButtonOptions}
                />
              </GroupItem>
            </GroupItem>
          </Form>
        </ScrollView>
      </Popup>
    </>
  );
}

ContactForm.propTypes = {
  isStaff: bool.isRequired,
  isTraveler: bool,
  // canViewHistory: bool,
  visible: bool.isRequired,
  updateContactGrid: func,
  currentContact: object.isRequired,
  phoneTypes: array.isRequired,
  managers: array.isRequired,
  deptos: array.isRequired,
  areas: array.isRequired,
  formTypeAd: bool.isRequired,
  isNewUser: bool.isRequired,
  onVisibleChange: func.isRequired,
};

ContactForm.defaultProps = {
  isTraveler: false,
  // canViewHistory: false,
  updateContactGrid: () => {},
};

export default ContactForm;
