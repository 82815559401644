import { Button, TextBox } from 'devextreme-react/text-box';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  height: 100%;
  overflow-y: auto;
  padding: 0;
  padding: 0 8px;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 80px);
`;

export const PasswordBox = styled.div`
  width: 35% auto;
  height: 30% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  justify-content: center;
  padding: 24px;
  border: 1px solid #ccc;
  border-radius: 8px;

  .test {
    border: 1px solid red;
  }
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

export const Input = styled(TextBox)`
  width: 100%;
  height: 32px;
  /* border: 0.5px solid gray; */
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

export const ButtonDx = styled(Button)`
  border: 1px solid red;
  width: 40px;
`;
