import React, { useEffect } from 'react';
import usePermissions from '../../hooks/usePermissions';
import { LoadPermissions } from '../../global';
import MapContainer from './MapContainer';

const MapContacts = () => {
  // const [contacts, setContacts] = useState(null);
  // const dataGridRef = useRef();

  useEffect(() => {}, []);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();

  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  return <MapContainer />;
};

export default MapContacts;
