// import { format } from 'date-fns';
import DataGrid, {
  Column,
  FilterRow,
  MasterDetail,
  Selection,
  Sorting,
} from 'devextreme-react/data-grid';
import { useEmrAccess } from 'pages/EMRAccess/contexts/EmrAccessContext';
import { array, object } from 'prop-types';
import React from 'react';
import useEmrRequestMethodList from 'services/requests/useEmrRequestMethodList';
import useEmrRequestStatusList from 'services/requests/useEmrRequestStatusList';
import RequestsDetail from '../RequestsDetail';

const ApplicationsGrid = ({ gridData, ACL }) => {
  const {
    dataGridAppsRef,
    showMissingCredentialsOnly,
    requestsRef,
  } = useEmrAccess();
  const { data: requestMethodList } = useEmrRequestMethodList();
  const { data: requestStatusDataList } = useEmrRequestStatusList();

  const methodIdToName = (id) => {
    const method = requestMethodList?.find(
      (item) => item?.EMRRequestMethodId === id
    );
    return method ? method?.EMRRequestMethodName : id;
  };

  // const dateColumnFormatter = ({ value }) => (
  //   <span>{!value ? 'Not defined' : format(value, 'MM/dd/yyyy')}</span>
  // );

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
    requestsRef.current = {
      Apps: [],
      Users: [],
    };
  }

  return (
    <DataGrid
      dataSource={gridData?.filter((item) => item.EMRRequestMethodId !== 0)}
      keyExpr="HospitalGroupId"
      noDataText="There are no Request(s) in this category"
      height="75vh"
      showBorders
      focusedRowEnabled
      onSelectionChanged={selectionChanged}
      ref={dataGridAppsRef}
    >
      <FilterRow visible applyFilter="auto" />
      <Sorting />
      <Selection mode="single" />
      <Column dataField="HospitalGroupName" caption="Okta Tile" />
      <Column dataField="EMREmail" caption="Email" />
      <Column dataField="EMRPhone" caption="Phone" />
      <Column dataField="EMRRequestUrl" caption="Request Url" />
      <Column dataField="Expiration" caption="Expiration" />
      <Column
        dataField="EMRRequestMethodId"
        caption="Request Method"
        cellRender={(data) => methodIdToName(data.value)}
      />
      {/* <Column dataField="StartDate" cellRender={dateColumnFormatter} /> */}
      <MasterDetail
        enabled={false}
        component={({ data }) => (
          <RequestsDetail
            data={data}
            ACL={ACL}
            type="APP"
            requestStatusDataList={requestStatusDataList}
            requestMethodList={requestMethodList}
          />
        )}
        autoExpandAll={showMissingCredentialsOnly}
        requestMethodList={requestMethodList}
        requestStatusDataList={requestStatusDataList}
      />
    </DataGrid>
  );
};

ApplicationsGrid.propTypes = {
  gridData: array,
  ACL: object.isRequired,
};

ApplicationsGrid.defaultProps = {
  gridData: [],
};

export default ApplicationsGrid;
