import { node } from 'prop-types';
import React from 'react';
import { QueryClientProvider as Provider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import queryClient from '../../services/queryClient';

export default function QueryClientProvider({ children }) {
  return (
    <Provider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      {children}
    </Provider>
  );
}

QueryClientProvider.propTypes = {
  children: node.isRequired,
};
