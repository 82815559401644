import { LoadPanel } from 'devextreme-react';
import { Template } from 'devextreme-react/core/template';
import { Column, DataGrid, Paging } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import React, { useCallback, useState } from 'react';
import HistoryPopup from '../../components/popups/HistoryPopup';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import usePermissions from '../../hooks/usePermissions';
import useAllHospitalGroupsList from '../../services/requests/useAllHospitalGroupsList';
import useEmrRequestMethodList from '../../services/requests/useEmrRequestMethodList';
import { getOktaAppsSyncApps } from '../../services/requests/useOktaApp';
import getChangeHistory from '../../utils/getChangeHistory';
import useStyles from '../styles/styles';
import ConfirmDeleteGroupPopup from './components/ConfirmDeleteGroupPopup';
import HospitalGroupEditPopup from './components/HospitalGroupEditPopup';

function HospitalGroup() {
  const [syncLoading, setSyncLoading] = useState(false);
  const [syncButtonDisabled, setSyncButtonDisabled] = useState(false);
  const [popupHospitalGroupVisible, setPopupHospitalGroupVisible] = useState(
    false
  );
  const [showDeletePopupGroup, setShowDeletePopupGroup] = useState(false);
  const [currentHospitalGroup, setCurrentHospitalGroup] = useState(null);
  const [typePopup, setTypePopup] = useState({
    type: '',
  });
  const [hospitalGroupChangeHistory, setHospitalGroupChangeHistory] = useState(
    null
  );
  const { data: emrRequestMethodList } = useEmrRequestMethodList();

  const [historyPopupVisible, setHistoryPopupVisible] = useState(false);

  const onRowUpdating = (options) => {
    options.newData = { ...options.oldData, ...options.newData };
  };
  const classes = useStyles();

  const {
    data: hospitalGroups,
    refetch: fetchHospitalGroupData,
  } = useAllHospitalGroupsList();

  const hospitalGridRefresh = useCallback(() => {
    fetchHospitalGroupData();
  }, [fetchHospitalGroupData]);

  const handleSyncApps = async () => {
    try {
      setSyncLoading(true);
      setSyncButtonDisabled(true);
      const res = await getOktaAppsSyncApps();
      if (res?.data?.ErrorMsg) {
        setSyncLoading(false);
        setSyncButtonDisabled(false);
        notify('Sync failed. Please try again!', 'error', 4000);
        // notify(res.data.ErrorMsg, 'error', 4000);
        return;
      }
      setSyncLoading(false);
      notify('Synchronization Completed', 'success', 3000);
      hospitalGridRefresh();
      setTimeout(() => {
        setSyncButtonDisabled(false);
      }, 30000);
    } catch (error) {
      setSyncLoading(false);
      setSyncButtonDisabled(false);
      notify('Error to synchronize', 'error', 3000);
    }
  };

  const handlePopupEditGroup = (e) => {
    setCurrentHospitalGroup(e.row.data);
    setTypePopup({ type: 'EDIT' });
    setPopupHospitalGroupVisible(true);
  };

  const handleClosePopup = () => {
    hospitalGridRefresh();
    setPopupHospitalGroupVisible(false);
    setCurrentHospitalGroup(null);
  };

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }
  /** ****************************** */
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'breadCrumb',
    });
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: handleSyncApps,
        disabled: syncButtonDisabled,
        hint: syncButtonDisabled
          ? 'Wait for 1 minute before syncing again!'
          : 'Sync Okta Tiles',
      },
      visible: ACL?.AddNew || ACL?.Admin,
    });
  };

  const handleShowChangeHistory = async (e) => {
    await getChangeHistory('HospitalGroup', e.row.data.HospitalGroupId).then(
      (ret) => {
        if (ret) {
          setHospitalGroupChangeHistory(ret);
          setHistoryPopupVisible(true);
        }
      }
    );
  };

  const methodIdToName = (id) => {
    const method = emrRequestMethodList?.find(
      (item) => item?.EMRRequestMethodId === id
    );
    return method ? method?.EMRRequestMethodName : id;
  };
  return (
    <>
      {popupHospitalGroupVisible && (
        <HospitalGroupEditPopup
          visible={popupHospitalGroupVisible}
          currentHospitalGroup={currentHospitalGroup}
          onClose={handleClosePopup}
          typePopup={typePopup}
        />
      )}
      {historyPopupVisible && (
        <HistoryPopup
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={hospitalGroupChangeHistory}
        />
      )}
      <ConfirmDeleteGroupPopup
        visible={showDeletePopupGroup}
        onVisibleChange={() => setShowDeletePopupGroup(false)}
        currentHospitalGroup={currentHospitalGroup}
        onClose={handleClosePopup}
      />

      <LoadPanel
        visible={syncLoading}
        message="Synchronizing Okta Tiles..."
        shadingColor="rgba(255,255,255,0.4)"
      />

      <div className={classes.divGridContainer}>
        <DataGrid
          elementAttr={{
            id: 'gridContainer',
          }}
          keyExpr="HospitalGroupId"
          dataSource={hospitalGroups}
          allowColumnReordering
          showBorders
          rowAlternationEnabled
          focusedRowEnabled
          onToolbarPreparing={onToolbarPreparing}
          showRowLines
          onRowUpdating={onRowUpdating}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: true }}
          // onToolbarPreparing={onToolbarBreadCrumb}
        >
          <Paging enabled={false} />

          <Column dataField="HospitalGroupName" caption="Okta Tile" />
          <Column dataField="EMREmail" caption="Email" />
          <Column dataField="EMRPhone" caption="Phone" />
          <Column dataField="EMRRequestUrl" caption="Request Url" />
          <Column dataField="Expiration" caption="Expiration" />
          <Column
            dataField="EMRRequestMethodId"
            caption="Request Method"
            cellRender={(data) => methodIdToName(data.value)}
          />
          <Column
            caption="Actions"
            type="buttons"
            width={100}
            buttons={[
              {
                hint: 'Edit Okta Tile',
                icon: 'fas fa-pen',
                onClick: handlePopupEditGroup,
                visible: ACL?.Admin || ACL?.Update,
              },
              {
                hint: 'Show Change History',
                icon: 'fa fa-history',
                onClick: handleShowChangeHistory,
                visible: ACL?.Admin,
              },
            ]}
          />

          <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
        </DataGrid>
      </div>
    </>
  );
}

export default HospitalGroup;
