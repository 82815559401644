import { format, parseISO } from 'date-fns';
import { LoadPanel } from 'devextreme-react';
import { Template } from 'devextreme-react/core/template';
import {
  Column,
  DataGrid,
  Editing,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  SearchPanel,
  Selection,
} from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import TeamRelayOrphansPopup from './Popups/TeamRelayOrphansPopup';
import ContactForm from '../../components/ContactForm';
import Avatar from '../../components/contacts/Avatar';
import AdUserDeletePopup from '../../components/popups/AdUserDeletePopup';
import ContactExportPopup from '../../components/popups/ContactExportPopup';
import { BreadCrumbRender, LoadPermissions } from '../../global';
// import TextBox from 'devextreme-react/text-box';
import usePermissions from '../../hooks/usePermissions';
import API from '../../services/API';
import { handleExportGridToExcel } from '../../utils/exportScheduleHistory';
// import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import ComparePopup from './Popups/ComparePopup';
import DeletedUsersPopup from './Popups/DeletedUsersPopup';
import EditGroupPopup from './Popups/EditGroupPopup';
import ImportFromAdPopup from './Popups/ImportFromAdPopup';

const ADUser = () => {
  const [deptos, setDeptos] = useState(null);
  const [managers, setManagers] = useState(null);
  const [popupImportVisible, setPopupImportVisible] = useState(false);
  const [popupCompareVisible, setPopupCompareVisible] = useState(false);
  const [popupDeletedUsersVisible, setPopupDeletedUsersVisible] = useState(
    false
  );
  // const [
  //   popupTeamRelayOrphansVisible,
  //   setPopupTeamRelayOrphansVisible,
  // ] = useState(false);
  const [adUserDeleteVisible, setAdUserDeleteVisible] = useState(false);
  const [exportPopupVisible, setExportPopupVisible] = useState(false);
  const [popupGroupEditVisible, setPopupGroupEditVisible] = useState(false);
  const [currentAdUser, setCurrentAdUser] = useState(null);
  const [deleteAdUser, setDeleteAdUser] = useState(null);
  const [adEditVisible, setAdEditVisible] = useState(null);
  const [phoneTypes, setPhoneTypes] = useState(null);
  const [areas, setAreas] = useState(null);
  const [adUsers, setAdUsers] = useState(null);
  const [userSchedule, setUserSchedule] = useState(null);
  const [currentUser, setCurrentUser] = useState('');
  const [loading, setLoading] = useState(false);

  const dataGridRef = useRef(null);
  const scheduleGridRef = useRef(null);
  const { pathname } = useLocation();

  const classes = useStyles();

  const getRouteGroup = () => {
    switch (pathname) {
      case '/travelers':
        return 'TRAV';

      case '/onlegalhold':
        return 'OLH';

      case '/others':
        return 'OTHERS';

      case '/onleave':
        return 'LEAVE';

      default:
        return 'Staff';
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    const routeGroup = getRouteGroup();

    API.sendRequest(`ADUser/List/${routeGroup}`, 'get').then(
      ({ status, data }) => {
        if (status === 200 && isSubscribed) {
          setAdUsers(data);
        }
      }
    );
    API.sendRequest(`Department`, 'get').then(({ status, data }) => {
      if (status === 200 && isSubscribed) {
        setDeptos(data);
      }
    });
    API.sendRequest(`ADUser/ListManagers`, 'get').then(({ status, data }) => {
      if (status === 200 && isSubscribed) {
        setManagers(data);
      }
    });
    API.sendRequest(`Area`, 'get').then(({ status, data }) => {
      if (status === 200 && isSubscribed) {
        setAreas(data);
      }
    });
    API.sendRequest(`PhoneType`, 'get').then(({ status, data }) => {
      if (status === 200 && isSubscribed) {
        setPhoneTypes(data);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []); //eslint-disable-line

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You do not have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  const addNewAdUser = () => {
    setCurrentAdUser({
      IsAdUser: true,
      User: {
        // UserId: '00000000-0000-0000-0000-000000000000',
        UserPrincipalName: '',
        Name: '',
        DisplayName: '',
        FirstName: '',
        LastName: '',
        Initials: '',
        Description: '',
        JobTitle: '',
        AreaId: 0,
        DepartmentId: 0,
        Department: '',
        ExchangeDB: '',
        Company: 'NJ Sharing Network',
        Manager: '',
        Email: '',
        StatusPhotoId: 0,
        Username: '',
        Password: '',
        SDUserId: 0,
        StaffId: 0,
        TeamId: null,
        MultiSchedule: false,
        IsManager: false,
        Active: true,
        Synchronize: true,
      },
      Phones: [],
      Groups: [],
    });

    setAdEditVisible(true);
  };

  const updateAdUsersGrid = async () => {
    const routeGroup = getRouteGroup();

    setAdUsers(
      (await API.sendRequest(`ADUser/List/${routeGroup}`, 'get')).data
    );
  };

  const getAdComplete = async (userId) => {
    const { data, status } = await API.sendRequest(
      `ADUser/ListComplete/${userId}`,
      'get'
    );

    if (status !== 200) {
      throw Error('Error getting user data');
    }

    return data;
  };

  const handleEditAdUser = async (e) => {
    try {
      const adUser = await getAdComplete(e.row.data.UserId);
      adUser.IsAdUser = true;
      setCurrentAdUser(adUser);
      setAdEditVisible(true);
    } catch (err) {
      notify(err.message, 'error', 2000);
    }
  };

  const handleDeleteAdUser = async (e) => {
    const adUser = e.row.data;
    adUser.ContactId = adUser.UserId;
    setDeleteAdUser(adUser);
    setAdUserDeleteVisible(true);
  };

  const handleEditGroup = async (e) => {
    const adUser = await getAdComplete(e.row.data.UserId);
    adUser.IsAdUser = true;
    setCurrentAdUser(adUser);
    setPopupGroupEditVisible(true);
  };

  const handleShowUserSchedule = async (e) => {
    setLoading(true);
    setCurrentUser(e.row.data.Username);
    const res = await API.sendRequest(
      `ContactSchedule/ScheduleFromActiveOrRecycle/${e.row.data.UserId}`
    );
    setUserSchedule(
      res.data.map((item) => ({
        ...item,
        StartDate: format(
          parseISO(item.StartDate),
          item.AllDay || item.DayOff ? 'MM-dd-yyyy' : 'MM-dd-yyy HH:mm'
        ),
        EndDate: format(
          parseISO(item.EndDate),
          item.AllDay || item.DayOff ? 'MM-dd-yyyy' : 'MM-dd-yyy HH:mm'
        ),
        CreatedAt: format(parseISO(item.CreatedAt), 'MM-dd-yyy HH:mm'),
        ChangedAt: format(parseISO(item.ChangedAt), 'MM-dd-yyy HH:mm'),
      }))
    );
  };

  function onScheduleGridContentReady() {
    handleExportGridToExcel(scheduleGridRef.current.instance, currentUser);
    setLoading(false);
  }

  const onInitNewRow = async (e) => {
    e.data.Active = true;
    e.data.Synchronize = true;
    e.data.IsManager = false;
  };

  const buttonList = [];

  if (ACL.Update) {
    buttonList.push(
      {
        hint: 'Export schedule history to Excel',
        icon: 'fas fa-calendar-alt',
        onClick: handleShowUserSchedule,
      },
      {
        hint: 'Edit AD User',
        icon: 'fa fa-user',
        onClick: handleEditAdUser,
      }
    );
  }

  if (ACL.Bulk && !ACL.Update) {
    buttonList.push({
      hint: 'Edit Groups',
      icon: 'fa fa-users',
      onClick: handleEditGroup,
    });
  }

  if (ACL.Delete) {
    buttonList.push({
      hint: 'Delete',
      icon: 'trash',
      onClick: handleDeleteAdUser,
    });
  }

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'breadCrumb',
      },
      // {
      //   location: 'center',
      //   widget: 'dxButton',
      //   options: {
      //     height: 36,
      //     icon: 'fas fa-unlink',
      //     text: 'Orphan Users',
      //     hint: 'Team Relay Orphan Users',
      //     onClick: () => {
      //       setPopupTeamRelayOrphansVisible(true);
      //     },
      //   },
      // },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          height: 36,
          icon: 'export',
          text: 'Import from AD',
          hint: 'Import from Active Directory',
          // visible: pathname === '/adusers',
          onClick: () => {
            setPopupImportVisible(true);
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          height: 36,
          icon: 'fieldchooser',
          text: 'Compare',
          hint: 'Compare the user information',
          // visible: pathname === '/adusers',
          visible: false,
          onClick: () => {
            setPopupCompareVisible(true);
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          height: 36,
          icon: 'file',
          text: 'Export Contacts',
          hint: 'Export contacts to PDF or Excel',
          visible: ACL?.Export || false,
          onClick: () => {
            setExportPopupVisible(true);
          },
        },
      }
    );

    if (ACL.AddNew) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: addNewAdUser,
        },
      });
    }

    // if (ACL.Bulk) {
    //   e.toolbarOptions.items.unshift({
    //     location: 'after',
    //     widget: 'dxButton',
    //     options: {
    //       icon: 'far fa-address-book',
    //       hint: 'Active Directory',
    //       //onClick: notificationGeneralClick,
    //     },
    //   });
    // }
  };

  const cellRender = ({ data }) => {
    if (data) {
      return <Avatar contact={data} h={50} w={50} />;
    }

    return <img height="50px" src="assets/blank.jpg" alt="" />;
  };

  const editCellRender = ({ data }) => {
    if (data) {
      return <Avatar contact={data} h={50} w={50} />;
    }

    return <img height="50px" src="assets/blank.jpg" alt="" />;
  };

  return (
    adUsers && (
      <div className={classes.divGridContainer}>
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={loading}
          message="Loading"
          closeOnOutsideClick={false}
        />
        {userSchedule && (
          <DataGrid
            ref={scheduleGridRef}
            dataSource={userSchedule}
            onContentReady={onScheduleGridContentReady}
            visible={false}
          >
            <Column dataField="DisplayName" caption="Name" />
            <Column
              dataField="AppointmentTypeCode"
              caption="Appointment Code"
            />
            <Column
              dataField="AppointmentTypeDesc"
              caption="Appointment Description"
            />
            <Column dataField="StartDate" />
            <Column dataField="EndDate" />
            <Column dataField="AllDay" />
            <Column dataField="DayOff" />
            <Column dataField="CreatedAt" />
            <Column dataField="ChangedAt" />
          </DataGrid>
        )}
        <ContactExportPopup
          isVisible={exportPopupVisible}
          setIsVisible={setExportPopupVisible}
        />
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={adUsers}
          keyExpr="UserId"
          allowColumnReordering
          showBorders
          rowAlternationEnabled
          showRowLines
          focusedRowEnabled
          loadPanel={{ enabled: 'auto' }}
          onToolbarPreparing={onToolbarPreparing}
          // onEditorPreparing={onEditorPreparing}
          onInitNewRow={onInitNewRow}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: true }}
          height="89vh"
        >
          <SearchPanel visible width={240} placeholder="Search..." />

          <Paging defaultPageSize={15} />
          <Pager
            showPageSizeSelector
            allowedPageSizes={[15, 30, 50, 100, 200]}
            showInfo
          />
          <Selection mode="single" />
          <Editing
            useIcons
            mode="popup"
            allowAdding={false}
            allowUpdating={ACL.Update}
            allowDeleting={ACL.Delete}
          />
          <Column
            name="Picture"
            dataField="Username"
            caption=""
            width={70}
            allowSorting={false}
            cellRender={cellRender}
            editCellComponent={editCellRender}
          />
          <Column dataField="FirstName" visible={false}>
            <RequiredRule />
          </Column>
          <Column dataField="LastName" visible={false}>
            <RequiredRule />
          </Column>

          <Column dataField="DisplayName" width={120} />
          <Column dataField="Initials" visible={false} />
          <Column dataField="JobTitle" minWidth={150} />
          <Column dataField="DepartmentId" caption="Department" width={100}>
            <Lookup
              dataSource={deptos}
              valueExpr="DepartmentId"
              displayExpr="DepartmentDesc"
            />
          </Column>
          <Column dataField="Email" minWidth={150} />
          <Column dataField="PhoneNumbers" minWidth={240} cellRender={(data) => {
            if (data.value) {
              const phoneNumbersArray = String(data.value).split(',');
              return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  {phoneNumbersArray.map((phoneNumber, index) => (
                    <div key={index}>{phoneNumber.trim()}</div>
                  ))}
                </div>
              );
            }
          }} />
          <Column alignment="center" caption="Domain: NJSharing.org">
            <Column name="Username" dataField="Username" />
            <Column dataField="OUNJSharing" caption="OU" />
          </Column>
          <Column
            alignment="center"
            caption="Domain: ShareNJ.org"
            visible={ACL?.Admin}
          >
            <Column
              name="OldUsername"
              caption="Username"
              dataField="OldUsername"
            />
            <Column dataField="OUShareNJ" caption="OU" />
          </Column>
          <Column dataField="IsManager" visible={false} caption="Manager" />
          <Column dataField="Groups" maxWidth={60} />
          {/* <Column dataField="Leave" width={80} /> */}
          <Column dataField="Active" width={80} />
          {/* <Column dataField="MultiSchedule" width={80}  /> */}
          <Column caption="Actions" type="buttons" buttons={buttonList} />
          <Column
            dataField="Synchronize"
            visible={false}
            caption="Synchronize as a Staff Contact"
            width={90}
          />

          <Column
            visible={false}
            dataField="CreatedAt"
            dataType="datetime"
            width={140}
            allowEditing={false}
          />

          <Column
            visible={false}
            dataField="ChangedAt"
            dataType="datetime"
            width={140}
            allowEditing={false}
          />
          <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
        </DataGrid>

        {popupGroupEditVisible && (
          <EditGroupPopup
            isStaff={pathname === '/adusers'}
            updateContactGrid={updateAdUsersGrid}
            isVisible={popupGroupEditVisible}
            setIsVisible={setPopupGroupEditVisible}
            currentContact={currentAdUser}
            areas={areas}
          />
        )}

        {popupImportVisible && (
          <ImportFromAdPopup
            popupImportVisible={popupImportVisible}
            setPopupImportVisible={setPopupImportVisible}
            updateAdUsersGrid={updateAdUsersGrid}
          />
        )}
        {popupCompareVisible && (
          <ComparePopup
            popupCompareVisible={popupCompareVisible}
            setPopupCompareVisible={setPopupCompareVisible}
          />
        )}
        {popupDeletedUsersVisible && (
          <DeletedUsersPopup
            visible={popupDeletedUsersVisible}
            setVisible={setPopupDeletedUsersVisible}
          />
        )}
        {/* {popupTeamRelayOrphansVisible && (
        <TeamRelayOrphansPopup
          visible={popupTeamRelayOrphansVisible}
          setVisible={setPopupTeamRelayOrphansVisible}
        />
      )} */}
        {currentAdUser && adEditVisible && (
          <ContactForm
            isStaff={pathname === '/adusers'}
            isTraveler={pathname === '/travelers'}
            canViewHistory={ACL.Update}
            visible={adEditVisible}
            setCurrentContact={setCurrentAdUser}
            currentContact={currentAdUser}
            phoneTypes={phoneTypes}
            managers={managers}
            deptos={deptos}
            areas={areas}
            formTypeAd
            isNewUser={
              currentAdUser &&
              (currentAdUser.User.UserId ===
                '00000000-0000-0000-0000-000000000000' ||
                !currentAdUser.User.UserId)
            }
            onVisibleChange={setAdEditVisible}
            updateContactGrid={updateAdUsersGrid}
          />
        )}
        {deleteAdUser && adUserDeleteVisible && (
          <AdUserDeletePopup
            visible={adUserDeleteVisible}
            adUser={deleteAdUser}
            onVisibleChange={setAdUserDeleteVisible}
            updateAdUsers={updateAdUsersGrid}
          />
        )}
      </div>
    )
  );
};

export default ADUser;
