/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Popup } from 'devextreme-react/popup';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PdfViewer from '../../../FileExplorer/components/PdfViewer';
import {
  Attachments,
  Container,
  Content,
  ContentPopup,
  WrapperAttachments,
  WrapperEmail,
} from './styles';

import { getArchiveIcon } from 'assets/getArchiveIcon';
import { format } from 'date-fns';
import { Button, LoadIndicator, LoadPanel, ScrollView } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { bool, func } from 'prop-types';
import { addCircle, trash } from '../../../../assets';
import API from '../../../../services/API';
import queryClient from '../../../../services/queryClient';
import useGetEMRSetup from '../../../../services/requests/useGetEMRSetup';
import TextEditor from '../../../EMRSetup/components/TextEditor';
import PopupDocViewer from '../PopupDocViewer';

function SendEmailPopup(
  { isExpirationScreen, forceRefetch, clearSelectedUsers, autoRefetchGridRef },
  ref
) {
  const [sendEmailPopup, setSendEmailPopup] = useState({
    visible: false,
    type: 'single',
    currentData: {},
    bulkUserList: [],
    pdfUrl: '',
    attachmentsList: [],
    emailTo: '',
    title: '',
    isLoading: false,
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const { data: getEMRSetup } = useGetEMRSetup({
    enabled: sendEmailPopup.visible,
  });
  const pdfLoaderRef = useRef(null);
  const [currentDate, setCurrentDate] = useState('');
  const [docPopupData, setDocPopupData] = useState({
    visible: false,
    url: '',
  });

  const handleClosePopup = () => {
    setSendEmailPopup({
      visible: false,
      type: 'single',
      currentData: {},
      bulkUserList: [],
      pdfUrl: '',
      attachmentsList: [],
      EMRContactData: {},
      isLoading: false,
      title: '',
    });
    setHtmlContent('');
    setPdfUrl('');
    autoRefetchGridRef.current = true;
    // setAutoRefetchGrid(true);
  };

  useImperativeHandle(
    ref,
    () => ({
      changeVisibility: (data, bulkUsers, emailType) => {
        if (
          (emailType !== 'bulk' && !data?.row?.data?.EMREmail) ||
          (emailType === 'bulk' && !bulkUsers[0]?.EMREmail)
        ) {
          notify(
            'This Okta Tile does not have contacts for each type of email, please configure the contacts on the "Okta Tile" screen!',
            'warning',
            8000
          );
          return;
        }

        autoRefetchGridRef.current = false;
        setSendEmailPopup((prev) => ({
          ...prev,
          title:
            emailType === 'bulk'
              ? `Bulk Email - ${bulkUsers[0]?.HospitalGroupName}`
              : `Email - ${data?.row?.data?.DisplayName} - ${data?.row?.data?.HospitalGroupName}`,
          type: emailType || 'single',
          currentData: data?.row?.data || {
            HospitalGroupName: bulkUsers[0]?.HospitalGroupName,
            HospitalGroupId: bulkUsers[0]?.HospitalGroupId,
          },
          emailTo: data?.row?.data?.EMREmail || bulkUsers[0]?.EMREmail,
          bulkUserList: bulkUsers || [],
          visible: true,
        }));
      },
    }),
    [autoRefetchGridRef]
  );

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const date = new Date();
      const formattedDate = format(date, 'M/d/yyyy h:mm a');
      setCurrentDate(formattedDate);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const initEmail = () => {
    let requestTemplate = '';
    // if (isExpirationScreen) {
    //   requestTemplate = getEMRSetup?.AccessRenewCredentialsEmailBody?.replace(
    //     '{DisplayName}',
    //     sendEmailPopup?.currentData?.DisplayName
    //   )
    //     .replace('{Email}', sendEmailPopup?.currentData?.ADUserEmail)
    //     .replace(
    //       '{HospitalGroupName}',
    //       sendEmailPopup?.currentData?.HospitalGroupName
    //     )
    //     .replace('{Date}', currentDate);
    // } else {

    const templateDraft = isExpirationScreen
      ? getEMRSetup?.AccessRenewCredentialsEmailBody
      : getEMRSetup?.AccessRequestEmailBody;

    if (sendEmailPopup?.type === 'bulk') {
      requestTemplate = templateDraft
        ?.replace('Name:', '')
        ?.replace('Job Title:', '')
        ?.replace('{JobTitle}', '')
        ?.replace('Email:', '')
        ?.replace('{Email}', '')
        ?.replace('Phone Numbers:', '')
        ?.replace('{PhoneNumbers}', '')
        ?.replace(
          '{DisplayName}',
          `<table>
            <tr>
              <th  style='width: 220px; border: 1px solid #000; text-align: center; padding: 8px 12px; font-weight: 700; font-size: 16px;'>Name</th>
              <th  style='width: 220px; border: 1px solid #000; text-align: center; padding: 8px 12px; font-weight: 700; font-size: 16px;'>Job Title</th>
              <th  style='width: 220px; border: 1px solid #000; text-align: center; padding: 8px 12px; font-weight: 700; font-size: 16px;'>Email</th>
              <th  style='width: 220px; border: 1px solid #000; text-align: center; padding: 8px 12px; font-weight: 700; font-size: 16px;'>Phone Numbers</th>
            </tr>${sendEmailPopup?.bulkUserList?.map(
              (item) =>
                `<tr>
                  <td style='width: 220px; border: 1px solid #000; text-align: center; padding: 8px 12px; font-weight: 700; font-size: 16px;'>${item?.DisplayName}</td>
                  <td style='width: 220px; border: 1px solid #000; text-align: center; padding: 8px 12px; font-weight: 700; font-size: 16px;'>${item?.JobTitle}</td>
                  <td style='width: 220px; border: 1px solid #000; text-align: center; padding: 8px 12px; font-weight: 700; font-size: 16px;'>${item?.ADUserEmail}</td>
                  <td style='width: 220px; border: 1px solid #000; text-align: center; padding: 8px 12px; font-weight: 700; font-size: 16px;'>${item?.PhoneNumbers}</td>
                </tr>`
            )}</table>`.replaceAll(',', '')
        )

        .replace(
          '{HospitalGroupName}',
          sendEmailPopup?.currentData?.HospitalGroupName
        )
        .replace('{Date}', currentDate);
    } else {
      requestTemplate = templateDraft
        ?.replace('{DisplayName}', sendEmailPopup?.currentData?.DisplayName)
        ?.replace('{Email}', sendEmailPopup?.currentData?.ADUserEmail)
        ?.replace(
          '{HospitalGroupName}',
          sendEmailPopup?.currentData?.HospitalGroupName
        )
        ?.replace('{Date}', currentDate)
        ?.replace('{JobTitle}', sendEmailPopup?.currentData?.JobTitle)
        ?.replace('{PhoneNumbers}', sendEmailPopup?.currentData?.PhoneNumbers);
    }
    // }
    setHtmlContent(requestTemplate);
  };

  useEffect(() => {
    initEmail();
    // if (sendEmailPopup?.currentData?.HospitalGroupId) {
    //   getHospitalGroupData();
    // }
    // eslint-disable-next-line
  }, [sendEmailPopup?.currentData, sendEmailPopup?.visible]);

  const handleUndoBody = () => {
    initEmail();
  };

  async function handleOpenFile(file) {
    if (file.name.includes('.pdf')) {
      setPdfUrl(URL.createObjectURL(file));
      setOpenPopup(true);
      return;
    }
    if (
      file.name.includes('.jpg') ||
      file.name.includes('.png') ||
      file.name.includes('.gif')
    ) {
      setDocPopupData({ visible: true, url: URL.createObjectURL(file) });
      return;
    }
    notify('You can only preview PDFs and images.', 'warning', 5000);
  }

  const onHidden = () => {
    setOpenPopup(false);
    setPdfUrl('');
  };

  const handleFileUpload = (event) => {
    const { files } = event.target;
    const filesToSave = [...sendEmailPopup?.attachmentsList, ...files];
    const filesSize = filesToSave.reduce(
      (totalSize, file) => totalSize + file.size,
      0
    );

    if (filesSize > 9000000) {
      notify('Maximum file size is 9MB', 'error', 6000);
      return;
    }
    setSendEmailPopup((prev) => ({ ...prev, attachmentsList: filesToSave }));
    // setAttachmentsList(filesToSave);
  };

  const handleAddFile = () => {
    pdfLoaderRef.current.click();
  };

  const handleSendEmail = async () => {
    setSendEmailPopup((prev) => ({ ...prev, isLoading: true }));
    const {
      DisplayName,
      ADUserEmail,
      HospitalGroupName,
      JobTitle,
      PhoneNumbers,
    } = sendEmailPopup?.currentData;

    const requiredDataUser = [
      {
        key: currentDate,
        message: `The date [${currentDate}] must be present in the body of the email`,
      },
      {
        key: DisplayName,
        message: `The name of the Staff [${DisplayName}] must be present in the body of the email`,
      },
      {
        key: ADUserEmail,
        message: `The email of the Staff [${ADUserEmail}] must be present in the body of the email`,
      },
      {
        key: HospitalGroupName,
        message: `The Okta Tile of the Staff [${HospitalGroupName}] must be present in the body of the email`,
      },
      {
        key: JobTitle,
        message: `The email of the Staff [${JobTitle}] must be present in the body of the email`,
      },
      {
        key: PhoneNumbers,
        message: `The Okta Tile of the Staff [${PhoneNumbers}] must be present in the body of the email`,
      },
    ];

    const tableData = [];

    if (sendEmailPopup?.bulkUserList?.length > 0) {
      sendEmailPopup.bulkUserList.forEach((item) => {
        tableData.push({
          key: item?.DisplayName,
          message:
            "You have edited the grid; now you will need to undo the changes using the 'Undo Changes' button.",
        });
        tableData.push({
          key: item?.ADUserEmail,
          message:
            "You have edited the grid; now you will need to undo the changes using the 'Undo Changes' button.",
        });
      });
    }

    const requiredDataUsers = [
      {
        key: currentDate,
        message: `The date [${currentDate}] must be present in the body of the email`,
      },
      {
        key: HospitalGroupName,
        message: `The Okta Tile of the Staff [${HospitalGroupName}] must be present in the body of the email`,
      },
      ...tableData,
    ];

    const missingData = (sendEmailPopup?.type === 'bulk'
      ? requiredDataUsers
      : requiredDataUser
    ).filter(({ key }) => !htmlContent?.includes(key));

    if (missingData.length) {
      missingData.forEach(({ message }) => notify(message, 'error', 6000));
      setSendEmailPopup((prev) => ({ ...prev, isLoading: false }));
      return;
    }

    const emailBody = htmlContent?.replace(currentDate, '{0}{1}');
    // ?.replace(DisplayName, '{DisplayName}')
    // ?.replace(ADUserEmail, '{Email}')
    // ?.replace(HospitalGroupName, '{HospitalGroupName}')
    // ?.replace(
    //   `<table><tr><th>Name</th><th>Email</th></tr>${sendEmailPopup?.bulkUserList?.map(
    //     (item) =>
    //       `<tr><td>${item?.DisplayName}</td><td>${item?.ADUserEmail}</td></tr>`
    //   )}</table>`,
    //   '{DisplayName} - {Email}'
    // );

    const dataToSend = {
      // User: [...sendEmailPopup?.currentData],
      BulkUsers:
        sendEmailPopup?.type === 'bulk'
          ? sendEmailPopup?.bulkUserList?.map((item) => ({
              ADUserEMRAccessId: item?.ADUserEMRAccessId,
              DisplayName: item?.DisplayName,
              EMREmail: item?.ADUserEmail,
            }))
          : [{ ...sendEmailPopup?.currentData }],
      HospitalGroupId: sendEmailPopup?.currentData.HospitalGroupId,
      EmailTo: sendEmailPopup?.emailTo,
      EmailBody: emailBody,
    };

    const formData = new FormData();
    sendEmailPopup.attachmentsList.forEach((file) => {
      formData.append('Attachment', file);
    });
    formData.append(
      'JsonToSend',
      JSON.stringify(
        isExpirationScreen
          ? { ...dataToSend, Subject: 'ACT – Request for Access Renewal' }
          : { ...dataToSend, Subject: 'ACT - Access Request' }
      )
    );

    try {
      const res = await API.sendRequest(
        'ADUserEMRAccess/SendEmailRequest',
        'POST',
        formData
      );

      if (res.status === 200) {
        notify('Email sent successfully', 'success', 6000);
        clearSelectedUsers();
        handleClosePopup();
        queryClient.invalidateQueries([
          'adUserEmrAccessList',
          sendEmailPopup?.currentData?.UserId,
        ]);
        queryClient.invalidateQueries(['allStaffUsers']);
        forceRefetch();
      } else {
        throw new Error(res.message);
      }
    } catch (e) {
      setSendEmailPopup((prev) => ({ ...prev, isLoading: false }));
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  if (sendEmailPopup?.visible) {
    return (
      <>
        {openPopup && (
          <PdfViewer visible={openPopup} pdfUrl={pdfUrl} onHidden={onHidden} />
        )}
        {docPopupData.visible && (
          <PopupDocViewer
            visible={docPopupData.visible}
            url={docPopupData.url}
            onHidden={() => setDocPopupData({ visible: false, url: '' })}
            onlyImage
          />
        )}
        <Popup
          visible={sendEmailPopup?.visible}
          title={sendEmailPopup?.title}
          onHidden={() => handleClosePopup()}
          width="80%"
          maxWidth={1700}
          height="90%"
          showCloseButton
        >
          <LoadPanel
            shadingColor="rgba(255,255,255,0.4)"
            visible={sendEmailPopup?.isLoading}
            message="Sending email..."
            closeOnOutsideClick={false}
          />
          <ContentPopup>
            <ScrollView height="100%">
              <Container>
                <WrapperEmail>
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .txt, .xlsx, .png, .jpg"
                    multiple="multiple"
                    onChange={handleFileUpload}
                    ref={pdfLoaderRef}
                    style={{ display: 'none' }}
                  />

                  <Content>
                    <WrapperAttachments>
                      <div className="title">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.464"
                          height="12.885"
                          viewBox="0 0 12.464 12.885"
                        >
                          <path
                            id="attachment-01-svgrepo-com"
                            d="M14.205,7.634,8.674,13.166A3.221,3.221,0,0,1,4.118,8.61L9.65,3.079a2.147,2.147,0,1,1,3.037,3.037L7.372,11.43A1.074,1.074,0,0,1,5.853,9.912l4.664-4.664"
                            transform="translate(-2.449 -1.95)"
                            fill="none"
                            stroke="#326a95"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                        </svg>
                        <h4>Attachments</h4>
                      </div>
                      <div className="list">
                        {sendEmailPopup?.attachmentsList?.map(
                          (attachment, idx) => (
                            <Attachments key={idx} title={attachment}>
                              <button
                                type="button"
                                className="trash"
                                onClick={() =>
                                  setSendEmailPopup((prev) => ({
                                    ...prev,
                                    attachmentsList: prev?.list?.filter(
                                      (item) => item.name !== attachment.name
                                    ),
                                  }))
                                }
                              >
                                <img
                                  src={trash}
                                  alt="Delete Attachment"
                                  className="trashImg"
                                />
                              </button>
                              {/* <img
                              src={pdfImg}
                              alt="PDF Attachment"
                              onClick={() => handleOpenFile(attachment)}
                            /> */}
                              <div
                                className="doc"
                                onClick={() => handleOpenFile(attachment)}
                              >
                                {getArchiveIcon(attachment.name)}
                              </div>
                              <p onClick={() => handleOpenFile(attachment)}>
                                {attachment.name}
                              </p>
                            </Attachments>
                          )
                        )}
                        <Attachments onClick={handleAddFile} dashed>
                          <img src={addCircle} alt="" />
                          <p>Add Files</p>
                        </Attachments>
                      </div>
                    </WrapperAttachments>
                  </Content>
                  {getEMRSetup?.AccessRequestEmailBody !== undefined ? (
                    <TextEditor
                      data={htmlContent}
                      setHtmlContent={setHtmlContent}
                      width="100%"
                    />
                  ) : (
                    <LoadIndicator
                      id="large-indicator"
                      height={60}
                      width={60}
                    />
                  )}
                </WrapperEmail>
              </Container>
            </ScrollView>
          </ContentPopup>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '42px',
              gap: '8px',
            }}
          >
            {sendEmailPopup?.type === 'bulk' && (
              <span style={{ marginRight: '12px', color: 'red' }}>
                {' '}
                You should not edit the user grid.
              </span>
            )}
            <Button
              type="default"
              stylingMode="contained"
              onClick={handleUndoBody}
              text="Undo Changes"
              icon="fas fa-undo"
            />
            <Button
              type="success"
              stylingMode="contained"
              onClick={handleSendEmail}
              text="Send Email to Hospital"
              icon="fas fa-paper-plane"
            />
            <Button
              stylingMode="contained"
              onClick={handleClosePopup}
              text="Cancel"
            />
          </div>
        </Popup>
      </>
    );
  }
}

export default forwardRef(SendEmailPopup);

SendEmailPopup.propTypes = {
  isExpirationScreen: bool,
  clearSelectedUsers: func,
  setAutoRefetchGrid: func,
  autoRefetchGridRef: { current: bool },
};

SendEmailPopup.defaultProps = {
  isExpirationScreen: false,
  clearSelectedUsers: () => {},
  setAutoRefetchGrid: () => {},
  autoRefetchGridRef: { current: false },
};
