import React from 'react';
import { WrapperEditor } from './styles';
import { func, string } from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'check-editor-custom-tr/build/ckeditor';

function TextEditor({ data, setHtmlContent, width }) {
  function uploadAdapter(loader) {
    return {
      upload: () =>
        // new Promise((resolve, reject) => {
        new Promise((resolve) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append('uploadImg', file);
            resolve(body);
          });
        }),
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader);
  }
  return (
    <WrapperEditor width={width}>
      <CKEditor
        data={data}
        editor={ClassicEditor}
        config={{
          extraPlugins: [uploadPlugin],
        }}
        onChange={(e, editor) => {
          const data = editor.getData();
          setHtmlContent(data);
        }}
      />
    </WrapperEditor>
  );
}

export default TextEditor;

TextEditor.propTypes = {
  data: string,
  setHtmlContent: func.isRequired,
  width: string,
};

TextEditor.defaultProps = {
  data: '',
  width: '70%',
};
