import styled from 'styled-components';
import pickTextColorBasedOnBgColor from '../../../../helpers/pickTextColorBasedOnBgColor';

export const Item = styled.div`
  background: ${(props) => props.bg};
  color: ${(props) => pickTextColorBasedOnBgColor(props.color, '#fff', '#000')};
  border: ${(props) => (props.border ? '2px solid #3f51b5' : '')};
  opacity: ${(props) => props.opacity};

  height: ${(props) =>
    props.itemQty === 1 ? 34 : props.itemQty < 3 ? 17 : 11}px;
  line-height: ${(props) =>
    props.itemQty === 1 ? 34 : props.itemQty < 3 ? 16 : 12}px;
  padding: 0 5px;
  font-size: ${(props) => (props.itemQty > 2 ? 8 : 12)}px;
  user-select: none;
`;

export const ItemEmpty = styled.div`
  border: ${(props) => (props.border ? '2px solid #3f51b5' : '')};
  height: 34px;
  line-height: 34px;
  padding: 0 5px;
`;

export const BoxAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;

  span {
    font-size: 8px;
    font-weight: 500;
    white-space: normal;
  }
`;

export const BoxSubject = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const Subject = styled.span`
  font-size: 12px;
  font-weight: bold;
`;
export const DateTitle = styled.span`
  font-size: 8;
  font-weight: 400;
  white-space: noWrap;
`;
export const BoxSupport = styled.div`
  display: flex;
  flex-direction: row;

  span {
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
  }
`;
export const BoxOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const TextOption = styled.option`
  font-size: 8px;
  font-weight: 500;
  white-space: nowrap;
  padding-left: 10px;
`;

export const SelectCustom = styled.select`
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  background: url('assets/ico-seta-appearance.gif') no-repeat #eeeeee;
  background-position: 98% center;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  padding-left: 10px;
`;
