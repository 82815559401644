import { Button, Popup } from 'devextreme-react';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { ButtonsContainer, Container } from './styles';

function DeleteConfirmationPopup({
  visible,
  onClose,
  deleteFunc,
  hospitalGroupName,
  employeeName,
  question,
}) {
  return (
    <Popup
      showTitle={false}
      visible={visible}
      onHiding={onClose}
      width={400}
      height={150}
    >
      <Container>
        <div className="messageAlert">
          <span>{`${question} request for ${employeeName} in [${hospitalGroupName}]?`}</span>
          <span>Are you sure?</span>
        </div>
        <ButtonsContainer>
          <Button
            text="OK"
            type="default"
            stylingMode="contained"
            onClick={() => {
              deleteFunc();
              onClose();
            }}
          />
          <Button text="Cancel" stylingMode="outlined" onClick={onClose} />
        </ButtonsContainer>
      </Container>
    </Popup>
  );
}

DeleteConfirmationPopup.propTypes = {
  hospitalGroupName: string.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
  deleteFunc: func.isRequired,
  question: string.isRequired,
};

export default DeleteConfirmationPopup;
