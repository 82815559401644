import Box, { Item } from 'devextreme-react/box';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/ui/load-panel';
import notify from 'devextreme/ui/notify';

import { bool, func, object } from 'prop-types';
import React, { useState } from 'react';
import API from '../../../../services/API';

import { Container, Label } from './styles';

function ConfirmDeleteGroupPopup({
  visible,
  onVisibleChange,
  currentHospitalGroup,
  onClose,
}) {
  const [deleting, setDeleting] = useState(false);

  const deleteGroup = async () => {
    const { data: ErrorMsg, status } = await API.sendRequest(
      `HospitalGroup/${currentHospitalGroup?.HospitalGroupId}`,
      'DELETE'
    );
    if (status !== 200) {
      notify(ErrorMsg, 'error', 4000);
      return;
    }
    notify('Okta Tile deleted with success', 'success', 4000);
    onVisibleChange(false);
    onClose();
    setDeleting(false);
  };

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        deleteGroup();
        setDeleting(true);
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        onVisibleChange(false);
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      title={`${currentHospitalGroup?.HospitalGroupName}`}
      onHidden={() => onVisibleChange(false)}
      width={400}
      height={200}
      showCloseButton={false}
      disabled={deleting}
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={deleting}
        message="Deleting..."
        closeOnOutsideClick={false}
      />
      <Container>
        <Box direction="row" height="250px" width="100%">
          <Item ratio={3}>
            <Box direction="col" width="100%" height="100%">
              <Item ratio={1} shrink>
                <Label>Do you want to delete this Okta Tile?</Label>
              </Item>
            </Box>
          </Item>
        </Box>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

ConfirmDeleteGroupPopup.propTypes = {
  visible: bool.isRequired,
  onVisibleChange: func.isRequired,
  currentHospitalGroup: object,
  onClose: func.isRequired,
};

ConfirmDeleteGroupPopup.defaultProps = {
  currentHospitalGroup: {},
};

export default ConfirmDeleteGroupPopup;
