import React from 'react';
import TextEditor from '../TextEditor';

import { LoadIndicator, LoadPanel } from 'devextreme-react';
import { bool, func, object } from 'prop-types';
import { Container } from './styles';

function TerminationTemplate({ emrSetupDraft, setEMRSetupDraft, isLoading }) {
  const requestTemplate = emrSetupDraft?.TerminationEmail;

  return (
    <Container>
      {isLoading && (
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={isLoading}
          message="Saving template..."
          closeOnOutsideClick={false}
        />
      )}
      {emrSetupDraft?.TerminationEmail !== undefined ? (
        <TextEditor
          data={requestTemplate}
          setHtmlContent={(text) =>
            setEMRSetupDraft((prev) => ({
              ...prev,
              TerminationEmail: text,
            }))
          }
          width="100%"
          height="100%"
        />
      ) : (
        <LoadIndicator id="large-indicator" height={40} width={40} />
      )}
    </Container>
  );
}

export default TerminationTemplate;

TerminationTemplate.propTypes = {
  isLoading: bool,
  emrSetupDraft: object,
  setEMRSetupDraft: func,
};

TerminationTemplate.defaultProps = {
  isLoading: false,
  emrSetupDraft: {},
  setEMRSetupDraft: () => {},
};
