import {
  Button,
  CheckBox,
  DataGrid,
  LoadPanel,
  NumberBox,
  Popup,
  SelectBox,
  TagBox,
  TextArea,
} from 'devextreme-react';
import TextBox from 'devextreme-react/text-box';
import notify from 'devextreme/ui/notify';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import API from '../../../../services/API';
import useDocuSignTemplateList from '../../../../services/requests/useDocuSignTemplateList';
import useEmrContactList from '../../../../services/requests/useEmrContactList';
import useEmrRequestMethodList from '../../../../services/requests/useEmrRequestMethodList';
import useGetHospitals from '../../../../services/requests/useGetHospitals';

import PopupConfirm from 'components/popups/PopupConfirm';
import {
  Column,
  Button as DGButton,
  RowDragging,
} from 'devextreme-react/data-grid';
import Validator, {
  PatternRule,
  RequiredRule,
} from 'devextreme-react/validator';
import queryClient from '../../../../services/queryClient';
import AddHospitalContactPopup from '../AddHospitalContactPopup';
import {
  Container,
  Content,
  FieldRow,
  FieldSet,
  HeaderContacts,
  Line,
  WrapperButtons,
  WrapperContacts,
  WrapperContent,
} from './styles';
import { formatPhoneNumber } from 'utils/format';

function HospitalGroupEditPopup({
  visible,
  onClose,
  currentHospitalGroup,
  typePopup,
}) {
  const [hospitalDraft, setHospitalDraft] = useState(null);
  // const [contactData, setContactData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { data: emrRequestMethodList } = useEmrRequestMethodList();
  const {
    data: docuSignTemplateList,
    isLoading: docuSignTemplateLoading,
  } = useDocuSignTemplateList();
  const { data: emrContactList } = useEmrContactList();
  const { data: allHospitalList } = useGetHospitals();
  // const [title, setTitle] = useState('');
  const [contactList, setContactList] = useState([]);
  const [oldContactList, setOldContactList] = useState([]);
  const [contactPopup, setContactPopup] = useState({
    visible: false,
    data: {},
  });
  const [deletePopupConfirm, setDeletePopupConfirm] = useState({
    visible: false,
    message: '',
    data: {},
  });

  useEffect(() => {
    if (currentHospitalGroup?.Hospitals) {
      setHospitalDraft(() => ({
        ...currentHospitalGroup,
        // Expires: currentHospitalGroup.Expires,
        Hospitals: JSON.parse(currentHospitalGroup.Hospitals).map(
          (item) => item.HospitalId
        ),
      }));
    } else {
      setHospitalDraft(() => ({
        ...currentHospitalGroup,
        // Expires: currentHospitalGroup.Expires,
        Hospitals: [],
      }));
    }
    if (
      currentHospitalGroup?.EMRRequestMethodId === 1 &&
      currentHospitalGroup?.DocuSignTemplateId
    ) {
      // const currentTemplate = docuSignTemplateList?.find(
      //   (item) =>
      //     item?.DocuSignTemplateId === currentHospitalGroup?.DocuSignTemplateId
      // );
      // if (
      //   currentHospitalGroup?.ContactId
      // ) {
      //   setContactList([
      //     {
      //       PhoneNumbers: currentHospitalGroup?.EMRPhone,
      //       Email: currentHospitalGroup?.EMREmail,
      //       ContactId: currentHospitalGroup?.ContactId,
      //       FullName: currentHospitalGroup?.EMRName,
      //       IsPrefered: true,
      //     },
      //   ]);
      // } else {
      //   setContactList([
      //     {
      //       PhoneNumbers: currentTemplate?.HospitalEMRAdminPhone,
      //       Email: currentTemplate?.HospitalEMRAdminEmail,
      //       ContactId: currentTemplate?.ContactId,
      //       FullName: currentTemplate?.HospitalEMRAdminName,
      //       IsPrefered: true,
      //     },
      //   ]);
      // }
    }
    // eslint-disable-next-line
  }, [currentHospitalGroup]);

  const fetchContacts = async () => {
    const res = await API.sendRequest(
      currentHospitalGroup?.EMRRequestMethodId === 1
        ? 'Contact/ListEMRContacts'
        : `Contact/ContactHospitalGroupList/${currentHospitalGroup?.HospitalGroupId}`,
      'GET'
    );

    if (currentHospitalGroup?.EMRRequestMethodId === 1) {
      if (currentHospitalGroup?.EMRContactId) {
        const currentContact = res?.data?.find(
          (item) => item?.ContactId === currentHospitalGroup?.EMRContactId
        );
        setContactList([
          {
            ...currentContact,
            FullName: `${currentContact.FirstName} ${currentContact.LastName}`,
            IsPrefered: true,
            toSave: false,
            toDelete: false,
          },
        ]);
      }
      setOldContactList([currentHospitalGroup?.EMRContactId]);
    } else {
      setContactList(
        res?.data?.map((item) => ({
          ...item,
          toSave: false,
          toDelete: false,
          isExistentToSave: false,
          TemporaryId: '',
        }))
      );
      setOldContactList(res?.data?.map((item) => item?.ContactId));
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      fetchContacts();
    }

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleSaveContacts = async () => {
    setIsSaving(true);

    const contactsToDelete = contactList?.filter((item) => item.toDelete);

    const contactsToSave = contactList?.filter((item) => item.toSave);

    // const preferredContact = contactList.find((item) => item.IsPrefered);

    let errorsDeleteResult = [];
    let errorsContactsResult = [];
    let errorsContactHospitalGroupResult = [];

    try {
      if (!!contactsToDelete?.length) {
        await Promise.all(
          contactsToDelete?.map(async (item) => {
            const result = await API.sendRequest(
              `Contact/ContactHospitalGroupDelete/${currentHospitalGroup?.HospitalGroupId}/${item.ContactId}`,
              'DELETE'
            );
            if (result?.data?.ErrorMsg) {
              errorsDeleteResult = [...errorsDeleteResult, result];
            }
          })
        );
      }
      if (hospitalDraft?.EMRRequestMethodId === 1) {
        return;
      }
      if (!!contactsToSave?.length) {
        await Promise.all(
          contactsToSave?.map(async (contact) => {
            const contactToSave = {
              Contact: {
                ContactId: contact.ContactId,
                FirstName: contact.FirstName,
                LastName: contact.LastName,
                Active: true,
                Email: contact.Email,
                IsEMRContact: true,
                IsPrefered: contact.IsPrefered,
              },
              Phones: [
                {
                  ContactId: contact.ContactId,
                  PhoneTypeId: 4,
                  ContactPhoneOrder: 1,
                  PhoneNumber: formatPhoneNumber(contact.PhoneNumbers),
                },
              ],
            };

            if (!contactToSave?.Contact?.ContactId) {
              delete contactToSave?.Contact?.ContactId;
              delete contactToSave?.Phones[0]?.ContactId;
            }

            const resContact = await API.sendRequest(
              'Contact/SaveComplete',
              'POST',
              contactToSave,
              false
            );

            if (resContact?.data?.ErrorMsg) {
              errorsContactsResult = [...errorsContactsResult, resContact.data];
            }

            const resHospitalContact = await API.sendRequest(
              // contact?.ContactId
              oldContactList?.includes(resContact?.data?.ContactId)
                ? 'Contact/ContactHospitalGroupUpdate'
                : 'Contact/ContactHospitalGroupSave',
              'POST',
              {
                ContactId: resContact?.data?.ContactId,
                HospitalGroupId: currentHospitalGroup?.HospitalGroupId,
                IsPrefered: contact?.IsPrefered,
                DisplayOrder: contact?.DisplayOrder,
                IsToNewRequest: contact?.IsToNewRequest,
                IsToReNewRequest: contact?.IsToReNewRequest,
                IsToTerminationRequest: contact?.IsToTerminationRequest,
              },
              false
            );

            if (resHospitalContact?.data?.ErrorMsg) {
              errorsContactHospitalGroupResult = [
                ...errorsContactHospitalGroupResult,
                resContact.data,
              ];
            }
          })
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();

    if (hospitalDraft?.EMRRequestMethodId === 0) {
      notify('Please, select a Request Method', 'error', 5000);
      return;
    }

    if (
      hospitalDraft?.EMRRequestMethodId === 1 &&
      !hospitalDraft?.DocuSignTemplateId
    ) {
      notify('Please, select a DocuSign Template', 'error', 5000);
      return;
    }

    if (
      hospitalDraft?.EMRRequestMethodId === 2 &&
      !hospitalDraft?.EMRRequestUrl
    ) {
      notify('Please, inform the hospital Request Access URL', 'error', 5000);
      return;
    }

    if (
      !contactList?.filter((item) => !item.toDelete).length &&
      hospitalDraft?.EMRRequestMethodId !== 2
    ) {
      notify('Please, add at least one contact!!!!', 'warning', 5000);
      return;
    }

    if (
      hospitalDraft?.EMRRequestMethodId === 3 &&
      (!contactList?.filter((item) => item.IsToNewRequest)?.length ||
        !contactList?.filter((item) => item.IsToReNewRequest)?.length ||
        !contactList?.filter((item) => item.IsToTerminationRequest)?.length)
    ) {
      notify(
        "Each of the values: 'Email New Request', 'Email Renew Request', and 'Email Termination Request' must be assigned to at least one of your contacts!",
        'error',
        7000
      );
      return;
    }

    if (hospitalDraft?.EMRRequestMethodId === 1) {
      if (!!contactList?.filter((item) => item.toDelete).length) {
        await handleSaveContacts();
      }
    } else {
      await handleSaveContacts();
    }

    if (
      JSON.stringify(currentHospitalGroup) === JSON.stringify(hospitalDraft)
    ) {
      onClose();
      return;
    }

    // const savedPreferedContact =

    // const contactToSaveInHospitalGroup =
    //   hospitalDraft?.EMRRequestMethodId === 1
    //     ? {
    //         EMREmail: hospitalDraft?.EMREmail,
    //         EMRContactId: hospitalDraft?.EMRContactId,
    //         EMRPhone: hospitalDraft?.EMRPhone,
    //       }
    //     : {
    //         EMRPhone: savedPreferedContact?.PhoneNumbers,
    //         EMREmail: savedPreferedContact?.Email,
    //         EMRContactId: savedPreferedContact?.ContactId,
    //       };

    setIsSaving(true);
    delete hospitalDraft?.EMRContactId;
    delete hospitalDraft?.EMREmail;
    delete hospitalDraft?.EMRPhone;
    try {
      if (typePopup.type === 'NEW') {
        const res = await API.sendRequest('HospitalGroup', 'post', {
          ...hospitalDraft,
          Hospitals: JSON.stringify(
            hospitalDraft?.Hospitals.map((item) => ({ HospitalId: item }))
          ),
          // ...contactToSaveInHospitalGroup,
        });
        if (res.status !== 200) {
          if (res.data) {
            throw new Error(res.data.ErrorMsg);
          }
          throw new Error(res.message);
        }

        setHospitalDraft(null);
        setIsSaving(true);
        notify('Request Access Configuration Saved', 'success', 5000);
        await queryClient.invalidateQueries('allHospitalGroups');
        onClose();
      }
      if (typePopup.type === 'EDIT') {
        const res = await API.sendRequest(`HospitalGroup`, 'post', {
          ...hospitalDraft,
          // Expires: hospitalDraft.Expires,
          // ExpiresPeriod: hospitalDraft.ExpiresPeriod,
          Hospitals: JSON.stringify(
            hospitalDraft?.Hospitals.map((item) => ({ HospitalId: item }))
          ),
          // ...contactToSaveInHospitalGroup,
        });
        if (res.status !== 200) {
          if (res.data) {
            throw new Error(res.data.ErrorMsg);
          }
          throw new Error(res.message);
        }

        setHospitalDraft(null);
        setIsSaving(true);
        notify('Request Access configuration saved', 'success', 5000);
        // await queryClient.invalidateQueries('allHospitalGroups');
        onClose();
      }
    } catch (e) {
      setIsSaving(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const expiresPeriod = [
    {
      id: 1,
      name: 'Day(s)',
      period: 'D',
    },
    {
      id: 2,
      name: 'Week(s)',
      period: 'W',
    },
    {
      id: 3,
      name: 'Month(s)',
      period: 'M',
    },
    {
      id: 4,
      name: 'Year(s)',
      period: 'Y',
    },
  ];

  useEffect(() => {
    if (hospitalDraft?.EMRContactId) {
      setHospitalDraft((prevState) => ({
        ...prevState,
        EMRContactId:
          hospitalDraft?.EMRContactId || '00000000-0000-0000-0000-000000000000',
        EMREmail: emrContactList?.find(
          (c) => c.ContactId === hospitalDraft?.EMRContactId
        )?.Email,
        EMRPhone: emrContactList
          ?.find((c) => c.ContactId === hospitalDraft?.EMRContactId)
          ?.PhoneNumbers?.split(',')[0],
      }));
    }
    // eslint-disable-next-line
  }, [emrContactList]);

  // useEffect(() => {
  //   if (docuSignTemplateList && hospitalDraft?.EMRRequestMethodId === 1) {
  //     const template = docuSignTemplateList.find(
  //       (item) => item?.DocuSignTemplateId === hospitalDraft?.DocuSignTemplateId
  //     );
  //     setTemplateData(template);
  //   }
  //   // if (hospitalDraft?.EMRRequestMethodId !== 1) {
  //   //   const contact = emrContactList?.find(
  //   //     (c) => c.ContactId === hospitalDraft?.EMRContactId
  //   //   );
  //   //   setContactData(contact);
  //   // }
  // }, [docuSignTemplateList, hospitalDraft, emrContactList]);

  // const handleSelectTemplate = useCallback(() => {
  //   const template = docuSignTemplateList.find(
  //     (item) => item?.DocuSignTemplateId === hospitalDraft?.DocuSignTemplateId
  //   );
  //   setTemplateData(template);
  //   setTitle(template.DocuSignTemplateDesc);
  //   setPopupEditVisible(true);
  // }, [docuSignTemplateList, hospitalDraft]);

  const docuSignSelector = useMemo(
    () => (
      <SelectBox
        name="docuSignTemplate"
        width="100%"
        dataSource={docuSignTemplateList?.filter((item) => item?.ContactId)}
        valueExpr="DocuSignTemplateId"
        displayExpr="DocuSignTemplateDesc"
        value={hospitalDraft?.DocuSignTemplateId}
        showClearButton
        onValueChanged={(e) => {
          const currentTemplate = docuSignTemplateList?.find(
            (item) => item?.DocuSignTemplateId === e.value
          );
          setHospitalDraft((prevState) => ({
            ...prevState,
            EMREmail: currentTemplate?.HospitalEMRAdminEmail,
            EMRContactId: currentTemplate?.ContactId,
            EMRPhone: currentTemplate?.HospitalEMRAdminPhone,
            DocuSignTemplateId: e.value,
          }));
          setContactList((prev) => [
            ...prev,
            {
              PhoneNumbers: currentTemplate?.HospitalEMRAdminPhone,
              Email: currentTemplate?.HospitalEMRAdminEmail,
              ContactId: currentTemplate?.ContactId,
              FullName: currentTemplate?.HospitalEMRAdminName,
              IsPrefered: true,
            },
          ]);
        }}
      >
        {hospitalDraft?.EMRRequestMethodId === 1 && (
          <Validator>
            <RequiredRule message="DocuSign template is required" />
          </Validator>
        )}
        {/* {hospitalDraft?.EMRRequestMethodId === 1 &&
          hospitalDraft?.DocuSignTemplateId && (
            <Button
              width={120}
              text="Edit"
              type="normal"
              onClick={() => handleSelectTemplate()}
            />
          )} */}
      </SelectBox>
    ),
    [hospitalDraft, docuSignTemplateList]
  );

  if (docuSignTemplateLoading) {
    return <LoadPanel visible message="Loading..." />;
  }

  // async function postContactOrdering(data) {
  //   await API.sendRequest(
  //     'Contact/ContactHospitalGroupUpdate',
  //     'post',
  //     data,
  //     false
  //   );
  // }

  const onReorderCategoryList = async (e) => {
    if (contactList) {
      const visibleRows = e.component.getVisibleRows();
      const newContactList = [...contactList];
      const toIndex = newContactList.findIndex(
        (item) => item.DisplayOrder === visibleRows[e.toIndex].data.DisplayOrder
      );
      const fromIndex = newContactList.findIndex(
        (item) => item.DisplayOrder === e.itemData.DisplayOrder
      );

      newContactList.splice(fromIndex, 1);
      newContactList.splice(toIndex, 0, e.itemData);

      const newOrderedList = newContactList.map((item, index) => ({
        ...item,
        DisplayOrder: index + 1,
      }));

      setContactList(
        newOrderedList.map((item) => {
          const oldItem = contactList.find(
            (x) => x.ContactId === item.ContactId
          );
          const isChanged = JSON.stringify(oldItem) !== JSON.stringify(item);
          if (isChanged) {
            return { ...item, toSave: true };
          }
          return item;
        })
      );
    }
  };

  async function handleDeleteContact() {
    if (deletePopupConfirm.data?.IsPrefered) {
      notify('Can not delete principal contact!', 'warning', 5000);
      return;
    }
    setIsSaving(true);

    const arrayToSave = contactList
      ?.map((item) => {
        if (
          !!deletePopupConfirm.data?.TemporaryId &&
          item.TemporaryId === deletePopupConfirm.data?.TemporaryId
        ) {
          return null;
        }
        if (item.ContactId === deletePopupConfirm.data?.ContactId) {
          if (item?.isExistentToSave) {
            return null;
          }
          return { ...item, toDelete: true };
        }
        return item;
      })
      ?.filter((item) => item !== null);

    setContactList(arrayToSave);
    // await API.sendRequest(
    //   `Contact/ContactHospitalGroupDelete/${currentHospitalGroup?.HospitalGroupId}/${deletePopupConfirm?.data?.ContactId}`,
    //   'DELETE'
    // );
    // fetchContacts();
    setIsSaving(false);
    setDeletePopupConfirm({ visible: false, message: '', data: {} });
  }

  return (
    <>
      {/* {popupEditVisible && (
        <EditTemplatePopup
          isVisible={popupEditVisible}
          setIsVisible={() => setPopupEditVisible(false)}
          data={templateData}
          title={title}
          forceRefetch={() => refetchTemplate()}
          isExternalCall
          handleChangeHospitalDraft={setHospitalDraft}
        />
      )} */}

      <AddHospitalContactPopup
        isEmail={hospitalDraft?.EMRRequestMethodId === 3}
        isWebsite={hospitalDraft?.EMRRequestMethodId === 2}
        isVisible={contactPopup.visible}
        onClose={() => setContactPopup({ data: {}, visible: false })}
        currentContact={contactPopup.data}
        currentContactList={contactList
          ?.filter((item) => !item?.toDelete)
          ?.map((item) => item?.ContactId)}
        insertContact={(dataObj) => {
          const data = { ...dataObj, DisplayOrder: contactList?.length + 1 };
          setContactList((prevState) => {
            let state = prevState;
            if (data?.IsPrefered) {
              state = prevState.map((item) => ({
                ...item,
                IsPrefered: false,
              }));
            }
            if (data?.ContactId) {
              return [
                ...state.filter((item) => item.ContactId !== data.ContactId),
                data,
              ];
            }
            if (data.TemporaryId) {
              return [
                ...state.filter(
                  (item) => item.TemporaryId !== data.TemporaryId
                ),
                data,
              ];
            }
            return [...state, data];
          });
        }}
      />

      <PopupConfirm
        visible={deletePopupConfirm.visible}
        message={deletePopupConfirm.message}
        onConfirm={() => handleDeleteContact()}
        onCancel={() =>
          setDeletePopupConfirm({ visible: false, message: '', data: {} })
        }
      />

      <LoadPanel
        visible={isSaving}
        message={isSaving ? 'Saving...' : 'Loading...'}
      />
      <Popup
        title={currentHospitalGroup?.HospitalGroupName}
        visible={visible}
        onHiding={() => {
          onClose();
          setContactList([]);
        }}
        showCloseButton={false}
        height="auto"
        width="95%"
        dragEnabled={false}
      >
        <LoadPanel
          visible={isSaving}
          message={isSaving ? 'Saving...' : 'Loading...'}
          shadingColor="rgba(0,0,0,0.4)"
        />
        <WrapperContent onSubmit={handleSaveChanges}>
          <Content>
            <Container>
              <FieldRow>
                <FieldSet $width="100%">
                  <label htmlFor="HospitalGroupName">Okta Tile</label>
                  <TextBox
                    name="HospitalGroupName"
                    width="100%"
                    readOnly
                    value={hospitalDraft?.HospitalGroupName}
                  />
                </FieldSet>
              </FieldRow>

              <FieldRow>
                <FieldSet $width="100%">
                  <label htmlFor="Hospitals">Hospitals</label>
                  <div className="WrapperTagBox">
                    <TagBox
                      dataSource={allHospitalList}
                      displayExpr="HospitalName"
                      valueExpr="HospitalId"
                      width="100%"
                      id="Hospitals"
                      searchEnabled
                      hideSelectedItems
                      value={hospitalDraft?.Hospitals}
                      onValueChanged={(e) => {
                        setHospitalDraft((prevState) => ({
                          ...prevState,
                          Hospitals: e.value,
                        }));
                      }}
                    >
                      <Validator>
                        <RequiredRule message="Hospitals is required" />
                      </Validator>
                    </TagBox>
                  </div>
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="50%">
                  <label htmlFor="requestMethod">Request Method</label>
                  <SelectBox
                    name="requestMethod"
                    width="100%"
                    dataSource={emrRequestMethodList}
                    valueExpr="EMRRequestMethodId"
                    displayExpr="EMRRequestMethodName"
                    value={hospitalDraft?.EMRRequestMethodId}
                    showClearButton
                    onValueChanged={(e) => {
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        EMREmail: '',
                        EMRContactId: '',
                        EMRPhone: '',
                        DocuSignTemplateId: null,
                        EMRRequestMethodId: e.value || 0,
                      }));
                      if (
                        currentHospitalGroup?.EMRRequestMethodId === e.value
                      ) {
                        fetchContacts();
                      } else {
                        setContactList((pre) => [
                          ...pre.map((item) => ({ ...item, toDelete: true })),
                        ]);
                      }
                    }}
                    isValid={hospitalDraft?.EMRRequestMethodId !== 0}
                  >
                    <Validator>
                      <RequiredRule message="Request method is required" />
                    </Validator>
                  </SelectBox>
                </FieldSet>
                {/* {hospitalDraft?.EMRRequestMethodId !== 1 && (
                <div className="groupEmrContact">
                  <FieldSet $width="100%">
                    <label htmlFor="emrContact">Contact</label>
                    <SelectBox
                      name="emrContact"
                      width="100%"
                      dataSource={emrContactList}
                      showClearButton
                      disabled={!!hospitalDraft?.DocuSignTemplateId}
                      valueExpr="ContactId"
                      displayExpr={(item) =>
                        `${
                          item?.DisplayName ||
                          `${item?.FirstName || ''} ${item?.LastName || ''}`
                        }${item?.Company ? ` | [${item.Company}]` : ''}`
                      }
                      value={hospitalDraft?.EMRContactId}
                      onValueChanged={(e) =>
                        setHospitalDraft((prevState) => ({
                          ...prevState,
                          EMRContactId:
                            e.value || '00000000-0000-0000-0000-000000000000',
                          EMREmail: emrContactList?.find(
                            (c) => c.ContactId === e.value
                          )?.Email,
                          EMRPhone: emrContactList
                            ?.find((c) => c.ContactId === e.value)
                            ?.PhoneNumbers?.split(',')[0],
                        }))
                      }
                    >
                      <Validator>
                        <RequiredRule message="Contact is required" />
                      </Validator>
                    </SelectBox>
                  </FieldSet>
                  <Button
                    icon="edit"
                    hint="Edit Contact"
                    height={35}
                    onClick={() => handleEditContact(contactData)}
                  />
                  <Button
                    icon="plus"
                    hint="Add new contact"
                    height={35}
                    onClick={addNewContact}
                  />
                </div>
              )} */}
                {hospitalDraft?.EMRRequestMethodId === 1 && (
                  <>
                    <FieldSet $width="50%">
                      <label htmlFor="docuSignTemplate">
                        DocuSign Template
                      </label>
                      {docuSignSelector}
                    </FieldSet>
                    {/* <FieldSet $width="33%">
                    <label htmlFor="emrContact">Contact</label>

                    {hospitalDraft?.DocuSignTemplateId ? (
                      <TextBox
                        name="emrContact"
                        width="100%"
                        disabled
                        value={templateData?.HospitalEMRAdminName}
                      />
                    ) : (
                      <SelectBox
                        name="emrContact"
                        width="100%"
                        dataSource={emrContactList}
                        showClearButton
                        disabled={!!hospitalDraft?.DocuSignTemplateId}
                        valueExpr="ContactId"
                        displayExpr={(item) =>
                          `${
                            item?.DisplayName ||
                            `${item?.FirstName || ''} ${item?.LastName || ''}`
                          }${item?.Company ? ` | [${item.Company}]` : ''}`
                        }
                        value={hospitalDraft?.EMRContactId}
                        onValueChanged={(e) =>
                          setHospitalDraft((prevState) => ({
                            ...prevState,
                            EMRContactId:
                              e.value || '00000000-0000-0000-0000-000000000000',
                            EMREmail:
                              prevState?.EMREmail ||
                              emrContactList?.find(
                                (c) => c.ContactId === e.value
                              )?.Email,
                            EMRPhone:
                              prevState?.EMRPhone ||
                              emrContactList
                                ?.find((c) => c.ContactId === e.value)
                                ?.PhoneNumbers?.split(',')[0],
                          }))
                        }
                      />
                    )}
                  </FieldSet> */}
                  </>
                )}
                {/*  <FieldSet $width="33%">
                <label htmlFor="emrEmail">Email</label>
                <TextBox
                  name="emrEmail"
                  width="100%"
                  disabled={!!hospitalDraft?.DocuSignTemplateId}
                  value={
                    hospitalDraft && hospitalDraft?.DocuSignTemplateId
                      ? templateData?.HospitalEMRAdminEmail
                      : hospitalDraft?.EMREmail
                    // : contactData?.Email
                  }
                  readOnly
                />
              </FieldSet>
              <FieldSet $width="33%">
                <label htmlFor="emrEmail">Phone</label>
                <TextBox
                  mask="(000) 000-0000"
                  useMaskedValue
                  disabled={!!hospitalDraft?.DocuSignTemplateId}
                  name="emrPhone"
                  width="100%"
                  value={
                    hospitalDraft?.EMRPhone
                      ? hospitalDraft?.EMRPhone?.replace(/\D/g, '')
                      : hospitalDraft?.DocuSignTemplateId?.replace(/\D/g, '')
                      ? templateData?.HospitalEMRAdminPhone?.replace(/\D/g, '')
                      : contactData?.PhoneNumbers?.split(',')[0]?.replace(
                          /\D/g,
                          ''
                        )
                  }
                  onValueChanged={(e) =>
                    setHospitalDraft((prevState) => ({
                      ...prevState,
                      EMRPhone: e.value,
                    }))
                  }
                >
                  <Validator>
                    <RequiredRule message="Phone is required" />
                  </Validator>
                </TextBox>
              </FieldSet> */}

                {hospitalDraft?.EMRRequestMethodId === 2 && (
                  <FieldSet $width="50%">
                    <label htmlFor="emrRequestUrl">Request Access URL</label>
                    <TextBox
                      name="emrRequestUrl"
                      width="100%"
                      value={hospitalDraft?.EMRRequestUrl}
                      onValueChanged={(e) =>
                        setHospitalDraft((prevState) => ({
                          ...prevState,
                          EMRRequestUrl: e.value,
                        }))
                      }
                    >
                      <Validator>
                        <RequiredRule message="Request Access Url is required" />
                        <PatternRule
                          message="Url do not match to the pattern."
                          pattern={
                            /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/
                          }
                        />
                      </Validator>
                    </TextBox>
                  </FieldSet>
                )}
              </FieldRow>
              <FieldRow>
                <FieldSet $width="100%">
                  <label htmlFor="emrUserObservations">Notes:</label>
                  <TextArea
                    name="emrUserObservations"
                    width="100%"
                    autoResizeEnabled
                    maxHeight={80}
                    value={hospitalDraft?.Notes}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        Notes: e.value,
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="25%">
                  <label htmlFor="passwordExpirationPeriod">Expires in</label>
                  <NumberBox
                    name="passwordExpirationPeriod"
                    min={0}
                    value={hospitalDraft?.Expires}
                    showClearButton
                    onValueChanged={(e) => {
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        Expires: e.value === null ? 0 : e.value,
                      }));
                    }}
                  />
                  {/* <Validator>
                  <RequiredRule message="Expire time is required" />
                 </Validator>
              </NumberBox>  */}
                </FieldSet>
                <FieldSet $width="25%">
                  <label htmlFor="passwordExpirationPeriod">Period</label>
                  <SelectBox
                    name="ExpiresPeriod"
                    items={expiresPeriod}
                    placeholder="Select a period"
                    valueExpr="period"
                    displayExpr="name"
                    value={hospitalDraft?.ExpiresPeriod}
                    showClearButton
                    onValueChanged={(e) => {
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        ExpiresPeriod: e.value,
                      }));
                    }}
                  >
                    <Validator>
                      {hospitalDraft?.Expires && (
                        <RequiredRule message="Expire period is required" />
                      )}
                    </Validator>
                  </SelectBox>
                </FieldSet>
                <FieldSet $width="25%">
                  <label htmlFor="mfaRequired">MFA Required?</label>
                  <CheckBox
                    name="mfaRequired"
                    value={hospitalDraft?.MfaRequired || false}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        MfaRequired: e.value,
                      }))
                    }
                  />
                </FieldSet>
                <FieldSet $width="25%">
                  <label htmlFor="mfaRequired">Show Credentials?</label>
                  <CheckBox
                    name="mfaRequired"
                    value={hospitalDraft?.ShowCredentials || false}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        ShowCredentials: e.value,
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>
              {/* <WrapperButtons>
              <Button width={120} text="OK" type="success" useSubmitBehavior />
              <Button
                width={120}
                text="Cancel"
                type="normal"
                onClick={onClose}
              />
            </WrapperButtons> */}
            </Container>
            <Line />
            <WrapperContacts>
              <HeaderContacts>
                {hospitalDraft?.EMRRequestMethodId === 1 ? (
                  <h3>
                    This contact can be edited on the ACT/DocuSign Template
                    page.
                  </h3>
                ) : (
                  <h3>Contacts</h3>
                )}
                <Button
                  icon="fa fa-plus"
                  onClick={() => setContactPopup({ visible: true, data: {} })}
                  visible={hospitalDraft?.EMRRequestMethodId !== 1}
                />
              </HeaderContacts>
              <DataGrid
                height={'190%'}
                dataSource={contactList
                  ?.filter((item) => !item?.toDelete)
                  ?.sort((a, b) => a.DisplayOrder - b.DisplayOrder)}
                showBorders
                allowColumnResizing
              >
                <RowDragging
                  allowReordering={hospitalDraft?.EMRRequestMethodId !== 1}
                  onReorder={onReorderCategoryList}
                  dropFeedbackMode="push"
                />
                <Column dataField="FullName" />
                <Column dataField="Email" />
                <Column
                  dataField="PhoneNumbers"
                  customizeText={(e) => {
                    if (e?.value) {
                      return e.value.replace(/[a-zA-Z:]+/g, '');
                    }
                    return e.value;
                  }}
                  caption="Phone Number"
                  mask="(000) 000-0000"
                />
                <Column dataField="IsPrefered" caption="Is Principal" />
                <Column
                  dataField="IsToNewRequest"
                  caption="New Request"
                  visible={hospitalDraft?.EMRRequestMethodId === 3}
                />
                <Column
                  dataField="IsToReNewRequest"
                  caption="Re-New Request"
                  visible={hospitalDraft?.EMRRequestMethodId === 3}
                />
                <Column
                  dataField="IsToTerminationRequest"
                  caption="Termination Request"
                  visible={
                    hospitalDraft?.EMRRequestMethodId === 3 ||
                    hospitalDraft?.EMRRequestMethodId === 2
                  }
                />
                <Column
                  caption="Actions"
                  type={'buttons'}
                  visible={hospitalDraft?.EMRRequestMethodId !== 1}
                >
                  <DGButton
                    icon="fa fa-edit"
                    onClick={(e) => {
                      setContactPopup({ visible: true, data: e.row.data });
                    }}
                  />
                  <DGButton
                    icon="fa fa-trash"
                    visible={(e) => !e.row.data?.IsPrefered}
                    onClick={(e) => {
                      setDeletePopupConfirm({
                        visible: true,
                        message: `Are you sure you want to delete [${e.row.data.FullName}]?`,
                        data: e.row.data,
                      });
                    }}
                  />
                </Column>
              </DataGrid>
            </WrapperContacts>
          </Content>
          <WrapperButtons>
            <Button width={120} text="OK" type="success" useSubmitBehavior />
            <Button width={120} text="Cancel" type="normal" onClick={onClose} />
          </WrapperButtons>
        </WrapperContent>
      </Popup>
    </>
  );
}

export default HospitalGroupEditPopup;

HospitalGroupEditPopup.propTypes = {
  visible: bool,
  onClose: func.isRequired,
  currentHospitalGroup: object,
  typePopup: object.isRequired,
};

HospitalGroupEditPopup.defaultProps = {
  visible: false,
  currentHospitalGroup: object.isRequired,
};
