import Form, { GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { LoadPanel } from 'devextreme-react/ui/load-panel';
import { EmailRule, RequiredRule } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import { array, bool, func, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import API from '../../../../services/API';

const EditPrivateContactPopup = ({
  phoneTypes,
  isVisible,
  setIsVisible,
  updateContactGrid,
  currentContact,
}) => {
  const [phoneOptions, setPhoneOptions] = useState([]);

  const [saving, setSaving] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (currentContact && currentContact.Phones) {
      currentContact.Phones.forEach((item) => {
        item.PhoneNumber = item.PhoneNumber.replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '');
      });
    }
    getPhoneOptions(currentContact);
  }, []); //eslint-disable-line

  const bottomToolbarButtons = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        handleSaveOnClick();
      },
      // disabled: importButtonDisabled,
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setIsVisible(false);
      },
    },
  ];

  const addPhoneButtonOptions = {
    icon: 'add',
    text: 'Add Phone',
    onClick: () => {
      if (!currentContact.Phones) {
        currentContact.Phones = [];
      }
      const phonesOrder = currentContact.Phones.map(
        (phone) => phone.ContactPhoneOrder
      );

      const maxPhoneOrder = phonesOrder.reduce(
        (acc, current) => Math.max(acc, current),
        0
      );

      currentContact.Phones.push({
        ContactId: currentContact.PrivateContact.PrivateContactId,
        PhoneTypeId: null,
        ContactPhoneOrder: maxPhoneOrder + 1,
        PhoneNumber: '',
        // eslint-disable-next-line no-unneeded-ternary
        Preferred: maxPhoneOrder === 0 ? true : false,
      });
      getPhoneOptions(currentContact);
    },
  };

  const getPhoneOptions = (contact) => {
    const options = [];

    if (contact && contact.Phones) {
      for (let i = 0; i < contact.Phones.length; i++) {
        options.push(generateNewPhoneOptions(contact.Phones[i], i));
      }
    }

    setPhoneOptions(options);

    return options;
  };

  const generateNewPhoneOptions = (phone, index) => ({
    maxLength: 50,
    mode: 'Tel',
    mask: '(000) 000-0000',

    buttons: [
      {
        name: `trash`,
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'trash',
          onClick: () => {
            currentContact.Phones.splice(index, 1);
            getPhoneOptions(currentContact);
          },
        },
      },
    ],
  });

  async function handleSaveOnClick() {
    setSaving(true);

    try {
      if (formRef.current.instance.validate().isValid) {
        if (
          !currentContact.Phones ||
          (currentContact.Phones && currentContact.Phones.length <= 0)
        ) {
          throw new Error('Please, inform at least one phone number.');
        }

        const res = await API.sendRequest(
          'PrivateContact/SaveComplete',
          'POST',
          currentContact
        ).finally(() => { });

        if (res?.data?.ErrorMsg || res?.status !== 200) {
          notify(res.data.ErrorMsg || res.message, 'error', 2000);
          setSaving(false);
          return;
        }
        updateContactGrid();
        notify('Contact saved', 'success', 2000);
        setIsVisible(false);
        setSaving(false);
      } else {
        setSaving(false);
        notify(`Please check the highlighted fields.`, 'error', 2000);
      }
    } catch (e) {
      setSaving(false);
      notify(`Error: ${e.message}`, 'error', 2000);
    }
  }

  return (
    <Popup
      visible={isVisible}
      dragEnabled
      showCloseButton={false}
      showTitle={!!currentContact.PrivateContact.PrivateContactId}
      title={
        currentContact && currentContact.PrivateContact.DisplayName
          ? currentContact.PrivateContact.DisplayName
          : `${currentContact.PrivateContact.FirstName} ${currentContact.PrivateContact.LastName}`
      }
      height={550}
    >
      {bottomToolbarButtons.map((buttonOptions, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOptions }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={saving}
        message="Saving..."
        closeOnOutsideClick={false}
      />
      <ScrollView width="100%" height="100%">
        <Form
          id="form"
          formData={currentContact}
          showColonAfterLabel
          labelLocation="left"
          colCount={2}
          ref={formRef}
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
          }}
        >
          <GroupItem caption="Information">
            <SimpleItem
              dataField="PrivateContact.Prefix"
              editorType="dxTextBox"
            >
              <Label text="Prefix" />
            </SimpleItem>
            <SimpleItem
              dataField="PrivateContact.FirstName"
              editorType="dxTextBox"
            >
              <RequiredRule />
              <Label text="First Name" />
            </SimpleItem>
            <SimpleItem
              dataField="PrivateContact.LastName"
              editorType="dxTextBox"
            >
              <RequiredRule />
              <Label text="Last Name" />
            </SimpleItem>
            <SimpleItem
              dataField="PrivateContact.Company"
              editorType="dxTextBox"
            >
              <Label text="Company" />
            </SimpleItem>

            <SimpleItem
              dataField="PrivateContact.JobTitle"
              editorType="dxTextBox"
            >
              <Label text="Job Title" />
            </SimpleItem>
            <SimpleItem
              dataField="PrivateContact.Department"
              editorType="dxTextBox"
            >
              <Label text="Department" />
            </SimpleItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="PrivateContact.Active"
                editorType="dxCheckBox"
              >
                <Label text="Active" />
              </SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem caption="Contacts">
            <SimpleItem dataField="PrivateContact.Email" editorType="dxTextBox">
              <RequiredRule />
              <EmailRule message="Email is not valid, please enter a valid email with @ and ." />
              <Label text="Email" />
            </SimpleItem>
            <GroupItem colCount={1} caption="Phones">
              {phoneOptions.map((phone, index) => (
                <GroupItem
                  colCount={2}
                  key={`PhoneItem${index}`}
                  itemType="group"
                >
                  <SimpleItem
                    key={`PhoneType${index}`}
                    dataField={`Phones[${index}].PhoneTypeId`}
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: phoneTypes,
                      searchEnabled: true,
                      displayExpr: 'PhoneTypeDesc',
                      valueExpr: 'PhoneTypeId',
                    }}
                  >
                    <RequiredRule />
                    <Label text="Type" visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    key={`Phone${index}`}
                    dataField={`Phones[${index}].PhoneNumber`}
                    editorOptions={phone}
                  >
                    <RequiredRule />
                    <Label text="Phone" visible={false} />
                  </SimpleItem>
                </GroupItem>
              ))}
              <SimpleItem
                itemType="button"
                horizontalAlignment="left"
                cssClass="add-phone-button"
                buttonOptions={addPhoneButtonOptions}
              />
            </GroupItem>
          </GroupItem>
        </Form>
      </ScrollView>
    </Popup>
  );
};

export default EditPrivateContactPopup;

EditPrivateContactPopup.propTypes = {
  phoneTypes: array,
  isVisible: bool.isRequired,
  updateContactGrid: func.isRequired,
  setIsVisible: func.isRequired,
  currentContact: object.isRequired,
};

EditPrivateContactPopup.defaultProps = {
  phoneTypes: [],
};
