import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import { array, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import API from '../../services/API';
import CustomCheckbox from '../Checkbox';
import Avatar from '../contacts/Avatar';

import 'react-perfect-scrollbar/dist/css/styles.css';

import { Button } from 'devextreme-react';
import {
  BoxAssignment,
  BoxCenter,
  BoxSearch,
  Container,
  PopupStyled,
  SearchInput,
  StyledMenuItem,
  WrapperButtons,
  WrapperInput,
  useStyles,
} from './styles';

export default function UsersPopup({
  anchorEl,
  handleClose,
  users,
  setUsers,
  setErrorSnack,
  handleClickSnack,
  title,
  roleId,
}) {
  const classes = useStyles();
  const [usersApi, setUsersApi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [term, setTerm] = useState('');

  useEffect(() => {
    async function loadUsers() {
      const { data } = await API.sendRequest('User', 'get');

      const results = data.map((d) => {
        const existItem = users.filter(
          (it) => it.UserId.toUpperCase() === d.UserId.toUpperCase()
        );
        if (existItem && existItem.length > 0) {
          d.check = true;
        } else {
          d.check = false;
        }
        return d;
      });

      setUsersApi(results);
      setIsLoading(false);
    }

    loadUsers(); // eslint-disable-next-line
  }, []);

  const handleChangeCheckbox = (event) => {
    const { id, checked } = event.target;

    setUsersApi((prevState) =>
      prevState.map((prev) => {
        if (prev.ContactId === id) {
          prev.check = checked;
        }
        return prev;
      })
    );
  };

  const handleClickCheckbox = (id, checked) => {
    setUsersApi((prevState) =>
      prevState.map((prev) => {
        if (prev.UserId === id) {
          prev.check = !checked;
        }
        return prev;
      })
    );
  };

  const handleSave = async () => {
    setIsLoading(true);

    const usersSave = usersApi.filter((person) => person.check);
    const usersSaveId = usersSave.map((p) => ({ UserId: p.UserId }));

    // SAVE
    let data;
    if (usersSaveId.length > 0) {
      const response = await API.sendRequest('UserRole/SavePopup', 'post', {
        RoleId: roleId,
        Users: [...usersSaveId],
      });
      data = response.data;
    } else {
      const response = await API.sendRequest('UserRole/SavePopup', 'post', {
        RoleId: roleId,
        Users: [],
      });
      data = response.data;
    }

    if (data) {
      setUsers(usersSave);

      setIsLoading(false);
      handleClose();
      handleClickSnack();
    } else {
      setIsLoading(false);
      setErrorSnack(true);
      handleClickSnack();
    }
  };

  const filterUsers = usersApi.filter(
    (user) =>
      user.FullName.toLowerCase().includes(term.toLowerCase()) ||
      user.Username.toLowerCase().includes(term.toLowerCase())
  );

  return (
    <PopupStyled
      title={title}
      visible={Boolean(anchorEl)}
      onHiding={handleClose}
      dragEnabled
      showCloseButton
      width={400}
      height={400}
      shading={false}
    >
      <Container>
        <BoxSearch>
          {!isLoading && (
            <WrapperInput>
              <SearchIcon />
              <SearchInput
                placeholder="Search…"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
            </WrapperInput>
          )}
        </BoxSearch>
        <BoxAssignment>
          {!isLoading ? (
            <PerfectScrollbar>
              {filterUsers &&
                filterUsers.map((listItem) => (
                  <StyledMenuItem
                    key={listItem.UserId}
                    onClick={() =>
                      handleClickCheckbox(listItem.UserId, listItem.check)
                    }
                  >
                    <ListItemIcon>
                      <CustomCheckbox
                        css={{ marginLeft: 0, marginRight: 0 }}
                        id={listItem.UserId}
                        value={listItem.check}
                        handleChange={handleChangeCheckbox}
                      />
                    </ListItemIcon>
                    <ListItemAvatar>
                      <Avatar
                        contact={listItem}
                        h={30}
                        w={30}
                        style={{ marginLeft: 0, marginRight: 0 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={listItem.FullName || listItem.Username}
                      className={classes.textList}
                    />
                  </StyledMenuItem>
                ))}
            </PerfectScrollbar>
          ) : (
            <BoxCenter>
              <CircularProgress />
            </BoxCenter>
          )}
        </BoxAssignment>

        <WrapperButtons>
          <Button
            disabled={isLoading}
            type="default"
            onClick={handleSave}
            text="OK"
          />
          <Button disabled={isLoading} text="Cancel" onClick={handleClose} />
        </WrapperButtons>
      </Container>
    </PopupStyled>
  );
}

UsersPopup.propTypes = {
  anchorEl: object.isRequired,
  handleClose: func.isRequired,
  setUsers: func.isRequired,
  setErrorSnack: func.isRequired,
  handleClickSnack: func.isRequired,
  users: array.isRequired,
  title: string.isRequired,
  roleId: number.isRequired,
};
