import React, { useState, useEffect } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Popup, My, At, Offset, Position } from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
// import Form, { GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import TextBox, { Button as TextBoxButton } from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import { Button } from 'devextreme-react/button';
import CardPerson from '../../../components/CardPerson';
import CircularProgress from '@material-ui/core/CircularProgress';
import PerfectScrollbar from 'react-perfect-scrollbar';
import API from '../../../services/API';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { BoxCenter, BoxAssignment, useStyles } from './styles';

export default function SearchPopup(props) {
  const {
    isPopupVisible,
    onHiding,
    contacts,
    setContacts,
    onContactClick,
  } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [filteredTypes, setFilteredTypes] = useState([]);
  const [areas, setAreas] = useState([]);
  const [comboTypesDisabled, setComboTypesDisabled] = useState(true);

  const [area, setArea] = useState(0);
  const [type, setType] = useState('NULL');
  const [term, setTerm] = useState('NULL');
  const [categories, setCategories] = useState(3);
  const [only, setShowOnly] = useState(false);

  useEffect(() => {
    async function init() {
      const areas = await API.sendRequest('Area/ScheduleOnly', 'get');
      setAreas(areas.data);

      const types = await API.sendRequest('AppointmentType', 'get');
      setAppointmentTypes(types.data);
      setFilteredTypes(types.data);

      setIsLoading(false);
    }

    init();
  }, []);

  const onTextValueChanged = (e) => {
    setTerm(e.value.trim().length === 0 ? 'NULL' : e.value);
  };

  const onAreaValueChanged = (e) => {
    setArea(e.value);
    setType('null');

    setComboTypesDisabled(e.value === 0);

    setFilteredTypes(
      appointmentTypes.filter(
        (item) => e.value !== 0 && item.AreaId === e.value && item.ShowInToday
      )
    );
  };

  const onTypeValueChanged = (e) => {
    setType(e.value);
  };

  const onCheckHospValueChanged = (e) => {
    const addRemoveHospitals = e.value ? 2 : -2;
    setCategories(categories + addRemoveHospitals);
  };

  const onCheckPersValueChanged = (e) => {
    const addRemovePersons = e.value ? 1 : -1;
    setCategories(categories + addRemovePersons);
  };

  const onCheckOnlineValueChanged = (e) => {
    setShowOnly(e.value);
  };

  const getRandom = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const onClick = async (e) => {
    const result = await API.sendRequest(
      `Contact/FilterMap/${term}/${area}/${type}/${categories}`,
      'get'
    );

    //randomizar as coordenadas (TEMPORARIO)
    // result.data.map((item) => {
    result.data.forEach((item) => {
      item.Online = false;
      if (item.AreaDesc !== 'Hospitals') {
        item.Coords =
          (parseFloat(40.698061) + getRandom(1, 500) / 1000).toString() +
          ', ' +
          (parseFloat(-74.410133) + getRandom(1, 500) / 1000).toString();
        item.Online = getRandom(1, 10) % 2 === 0;
      }
    });

    const contactsTmp = result.data.filter(
      (contact) =>
        ((categories & 2) === 2 && contact.AreaDesc === 'Hospitals') ||
        ((categories & 1) === 1 &&
          contact.AreaDesc !== 'Hospitals' &&
          (!only || contact.Online === only))
    );

    setContacts(contactsTmp);
  };

  const contentRender = () => {
    return (
      <div>
        {!isLoading && (
          <div>
            <div className="dx-field">
              <TextBox
                placeholder="Search..."
                showClearButton={true}
                valueChangeEvent="keyup"
                onValueChanged={onTextValueChanged}
              >
                <TextBoxButton
                  name="search"
                  location="before"
                  options={{
                    icon: 'search',
                    disabled: true,
                    stylingMode: 'text',
                  }}
                />
              </TextBox>
            </div>
            <div className="dx-field">
              <div className={classes.left}>
                <CheckBox
                  defaultValue={true}
                  text="Show persons"
                  onValueChanged={onCheckPersValueChanged}
                />
              </div>
              <div className={classes.right}>
                <CheckBox
                  defaultValue={true}
                  text="Show hospitals"
                  onValueChanged={onCheckHospValueChanged}
                />
              </div>
            </div>
            {(categories & 1) === 1 && (
              <>
                <div className="dx-field">
                  <SelectBox
                    items={areas}
                    displayExpr="AreaDesc"
                    valueExpr="AreaId"
                    defaultValue={areas[0].AreaId}
                    onValueChanged={onAreaValueChanged}
                  />
                </div>
                <div className="dx-field">
                  <SelectBox
                    items={filteredTypes}
                    placeholder="Select Appointment Type"
                    disabled={comboTypesDisabled}
                    displayExpr="AppointmentTypeDesc"
                    valueExpr="AppointmentTypeCode"
                    onValueChanged={onTypeValueChanged}
                  />
                </div>
              </>
            )}
            <div className="dx-field">
              {(categories & 1) === 1 && (
                <div className={classes.left}>
                  <CheckBox
                    defaultValue={false}
                    text="Online only"
                    onValueChanged={onCheckOnlineValueChanged}
                  />
                </div>
              )}
              <div className={classes.right}>
                <Button
                  width={100}
                  maxWidth={100}
                  text="Apply"
                  type="default"
                  stylingMode="contained"
                  onClick={onClick}
                />
              </div>
            </div>
            <BoxAssignment>
              <PerfectScrollbar>
                <>
                  {contacts &&
                    contacts.map((item) => (
                      <CardPerson
                        key={'cp_' + item.ContactId}
                        data={item}
                        width={240}
                        height={70}
                        columnDirection={false} // aqui vc pode definir de o primeiro nome fica em cima ou na mesma linha do sobrenome
                        avatarW={40}
                        avatarH={40}
                        handleOnlineClick={(e) => onContactClick(e, item)}
                      />
                    ))}
                </>
              </PerfectScrollbar>
            </BoxAssignment>
          </div>
        )}
        {isLoading && (
          <BoxCenter>
            <CircularProgress />
          </BoxCenter>
        )}
      </div>
    );
  };

  return (
    <Popup
      shading={false}
      visible={isPopupVisible}
      onHiding={onHiding}
      dragEnabled={true}
      height={500}
      width={300}
      contentRender={contentRender}
      title="Filter"
    >
      <Position>
        <My x="right" y="bottom" />
        <At x="right" y="bottom" />
        <Offset x="-10" y="-10" />
      </Position>
    </Popup>
  );
}
