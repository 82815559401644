import React, { useState, useEffect, useRef } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import {
  DataGrid,
  Column,
  SearchPanel,
  Selection,
  Editing,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { func, bool } from 'prop-types';
import API from '../../../../services/API';

import CompareDetails from './CompareDetails';

import { ContentContainer, GridContainer } from '../styles';

export default function ComparePopup({
  popupCompareVisible,
  setPopupCompareVisible,
}) {
  const [compareUsers, setCompareUsers] = useState([]);
  const [userIdSelected, setUserIdSelected] = useState('');
  const [userNameSelected, setUserNameSelected] = useState('');
  const [cleanButtonVisible, setCleanButtonVisible] = useState(false);
  const [compareButtonDisabled, setCompareButtonDisabled] = useState(true);
  const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const gridCompareUsersRef = useRef(null);
  const bottomToolbarButtons = [
    {
      text: 'Clear Selection',
      type: 'normal',
      onClick: () => {
        handleCleanButtonClick();
      },
      visible: cleanButtonVisible,
    },
    {
      text: 'Compare',
      type: 'success',
      hint: 'Click to compare the user data',
      onClick: () => {
        setDetailsPopupVisible(true);
      },
      disabled: compareButtonDisabled,
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setPopupCompareVisible(false);
      },
    },
  ];

  useEffect(() => {
    if (userIdSelected) {
      setCompareButtonDisabled(false);
      setCleanButtonVisible(true);
    } else {
      setCompareButtonDisabled(true);
      setCleanButtonVisible(false);
    }
  }, [userIdSelected]);

  const onPopupShowing = async () => {
    setLoading(true);
    if (compareUsers.length === 0) {
      const { data } = await API.sendRequest(`ADUser/ListCompare`, 'get');
      setCompareUsers(data);
    } else {
      gridCompareUsersRef.current &&
        gridCompareUsersRef.current.instance.refresh();
    }
    setUserIdSelected('');
    gridCompareUsersRef.current &&
      gridCompareUsersRef.current.instance.clearSelection();

    setLoading(false);
  };

  const handleCleanButtonClick = () => {
    gridCompareUsersRef.current.instance.clearSelection();
    setUserIdSelected('');
  };

  const onHidden = () => {
    setPopupCompareVisible(false);
  };

  const handleSqlSelectionChanged = (e) => {
    let userSelected = e.component.getSelectedRowsData();
    if (userSelected.length !== 0) setUserIdSelected(userSelected[0].UserId);
    if (userSelected.length !== 0)
      setUserNameSelected(
        `${userSelected[0].FirstName} ${userSelected[0].LastName}`
      );
  };

  const onCompareDataSaved = () => {
    let gridCompare = gridCompareUsersRef.current.instance;
    let rowIndex = gridCompare.getRowIndexByKey(
      gridCompare.getSelectedRowKeys()[0]
    );
    gridCompare.deleteRow(rowIndex);
    gridCompare.clearSelection();
    setUserIdSelected('');
    setDetailsPopupVisible(false);
  };

  const toolbarInfoRender = (source, totalCount) => {
    return (
      <div className="toolBarInformation">
        <strong>{source}:</strong> {totalCount} records
      </div>
    );
  };

  const onTrToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'totalTrRecordsCount',
    });
  };

  return (
    <>
      {userIdSelected && detailsPopupVisible && (
        <CompareDetails
          detailsPopupVisible={detailsPopupVisible}
          setDetailsPopupVisible={setDetailsPopupVisible}
          UserId={userIdSelected}
          UserName={userNameSelected}
          onDataChanged={onCompareDataSaved}
        />
      )}
      <Popup
        onHidden={onHidden}
        visible={popupCompareVisible}
        title="Compare"
        width={'90%'}
        height={'80%'}
        onShowing={onPopupShowing}
      >
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={loading}
          message="Loading"
          closeOnOutsideClick={false}
        />
        <ContentContainer>
          <GridContainer>
            {compareUsers.length > 0 && (
              <DataGrid
                id="gridCompareUsers"
                ref={gridCompareUsersRef}
                keyExpr="UserId"
                onToolbarPreparing={onTrToolbarPreparing}
                dataSource={compareUsers}
                showBorders
                rowAlternationEnabled
                showRowLines
                showColumnLines
                height={'100%'}
                width={'100%'}
                onSelectionChanged={handleSqlSelectionChanged}
              >
                <Editing
                  texts={{
                    confirmDeleteMessage: '',
                  }}
                />
                <SearchPanel visible={true} />
                <Selection mode="single" />
                <Column
                  dataField={'FirstName'}
                  caption={'First Name'}
                  width={100}
                />
                <Column
                  dataField={'LastName'}
                  caption={'Last Name'}
                  width={100}
                />
                <Column
                  dataField={'Username'}
                  caption={'User Name'}
                  width={100}
                />
                <Column
                  dataField={'Department'}
                  caption={'Department'}
                  width={300}
                />
                <Column
                  dataField={'JobTitle'}
                  caption={'Job Title'}
                  width={300}
                />
                <Column dataField={'OU'} caption={'OU'} width={300} />
                <Template
                  name="totalTrRecordsCount"
                  render={() =>
                    toolbarInfoRender(
                      'Users with conflicting data',
                      compareUsers.length
                    )
                  }
                />
              </DataGrid>
            )}
          </GridContainer>
        </ContentContainer>
        {bottomToolbarButtons.map((buttonOptions, index) => (
          <ToolbarItem
            key={index}
            options={{ stylingMode: 'contained', ...buttonOptions }}
            widget="dxButton"
            toolbar="bottom"
            location="after"
          />
        ))}
      </Popup>
    </>
  );
}

ComparePopup.propTypes = {
  popupCompareVisible: bool,
  setPopupCompareVisible: func,
  updateCompare: func,
};

ComparePopup.defaultProps = {
  popupCompareVisible: false,
  setPopupCompareVisible: () => {},
  updateCompare: () => {},
};
