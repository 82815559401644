import React from 'react';
import { HistoryContainer, TableRow } from './styles';
import { object } from 'prop-types';

function JSONHistory({ data }) {
  const objAfter = JSON.parse(data.data.XMLAfter);
  const objBefore = JSON.parse(data.data.XMLBefore);

  const after = objAfter?.Phones.map((item) => item);
  const phoneNumberAfter = after?.map((item) => item.PhoneNumber);
  const phoneTypeIdAfter = after?.map((item) => item.PhoneTypeId);
  const auditAfter = {
    ...objAfter,
    PhoneNumber: String(phoneNumberAfter),
    PhoneTypeId: String(phoneTypeIdAfter),
  };
  const newAuditAfter = { ...auditAfter, Phones: undefined };
  delete newAuditAfter.Phones;
  const before = objBefore?.Phones.map((item) => item);
  const phoneNumberBefore = before?.map((item) => item.PhoneNumber);
  const phoneTypeIdBefore = before?.map((item) => item.PhoneTypeId);
  const auditBefore = {
    ...objBefore,
    PhoneNumber: String(phoneNumberBefore),
    PhoneTypeId: String(phoneTypeIdBefore),
  };

  const auditObj = { ...newAuditAfter, ...auditBefore };

  const newAuditObj = { ...auditObj, Phones: undefined };
  delete newAuditObj.Phones;

  const Update = () => {
    if (newAuditObj?.Contact || newAuditObj?.PrivateContact) {
      return Object.entries(
        newAuditObj?.Contact || newAuditObj?.PrivateContact
      ).map(([key]) => (
        <TableRow
          key={key}
          hasalteration={
            auditBefore.Contact?.[key] ||
            auditBefore.PrivateContact?.[key] !== auditAfter.Contact?.[key] ||
            auditAfter.PrivateContact?.[key]
          }
        >
          <td>
            <b>{key}</b>
          </td>
          <td>
            {auditBefore.Contact?.[key] || auditBefore.PrivateContact?.[key]}
          </td>
          <td>
            {auditAfter.Contact?.[key] || auditAfter.PrivateContact?.[key]}
          </td>
        </TableRow>
      ));
    }
    return Object.entries(newAuditObj).map(([key]) => (
      <TableRow
        key={key}
        hasalteration={auditBefore?.[key] !== auditAfter?.[key]}
      >
        <td>
          <b>{key}</b>
        </td>
        <td>{auditBefore?.[key]}</td>
        <td>{auditAfter?.[key]}</td>
      </TableRow>
    ));
  };

  const Insert = () => {
    try {
      return Object.entries(newAuditAfter).map(([key, value]) => (
        <TableRow key={key}>
          <td>
            <b>{key}</b>
          </td>
          <td>{value}</td>
        </TableRow>
      ));
    } catch (error) {
      return <div>teste</div>;
    }
  };

  return (
    <div>
      <HistoryContainer>
        {data.data.ActionDesc === 'Update' ? (
          <table cellSpacing="0">
            <thead>
              <tr>
                <th> &nbsp;</th>
                <th>Before</th>
                <th>After</th>
              </tr>
            </thead>
            <tbody>
              <Update />
            </tbody>
          </table>
        ) : (
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>Record created</th>
              </tr>
            </thead>
            <tbody>
              <Insert />
            </tbody>
          </table>
        )}
      </HistoryContainer>
    </div>
  );
}

JSONHistory.propTypes = {
  data: object.isRequired,
};

export default JSONHistory;
