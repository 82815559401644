import React, { useState } from 'react';
import { string } from 'prop-types';
import { iconConfig } from '../../../../assets';

function AppLogo({ url }) {
  const [image, setImage] = useState(url);
  return (
    <img
      src={image}
      alt="Logo"
      onError={() => setImage(iconConfig)}
      style={{ maxWidth: '100px', height: '30px' }}
    />
  );
}

export default AppLogo;

AppLogo.propTypes = {
  url: string,
};

AppLogo.defaultProps = {
  url: '',
};
