import React from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';

import { Column, DataGrid, Paging, Editing } from 'devextreme-react/data-grid';
import useStyles from '../styles/styles';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

const phoneTypes = new CustomStore({
  key: 'PhoneTypeId',
  load: async () => {
    const { data } = await API.sendRequest(`PhoneType`, 'get');
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`PhoneType`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`PhoneType`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`PhoneType/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const PhoneType = () => {
  const classes = useStyles();

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={phoneTypes}
        // keyExpr={'PhoneTypeId'}
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        onRowUpdating={onRowUpdating}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />
        <Editing
          useIcons
          mode="form"
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        />
        <Column dataField="PhoneTypeDesc" caption="Phone Type" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default PhoneType;
