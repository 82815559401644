import styled from 'styled-components';

export const Container = styled.div`
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Section = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;

  button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: #161616;
`;

export const TopTitleGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border: 1px solid #0063a5;
    color: #0063a5;
    padding: 6px;
    border-radius: 4px;

    :hover {
      background-color: #0063a515;
      transition: 0.4s;
    }
  }
`;

export const DatagridContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CellContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 2px;
  gap: 8px;
`;

export const ColorStatusIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor};
`;
