import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

export const CustomTableCell = styled(TableCell)`
  background-color: ${(props) => (props.holiday
    ? props.isActive
      ? '#f7f7f7'
      : '#CCC'
    : props.isActive
      ? '#f7f7f7'
      : '#FFF')};
  border: 0.5px solid #ccc !important;
  min-width: 110px;
`;
