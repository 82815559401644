import { bool, string, func } from 'prop-types';
import React from 'react';
import { PopupDx } from './styles';

function PdfViewer({ visible, pdfUrl, onHidden }) {
  return (
    <PopupDx
      title="PDF Viewer"
      visible={visible}
      onHidden={() => onHidden()}
      clo
      minHeight="90%"
      minWidth="75%"
      on
      resizeEnabled
    >
      <object
        data={pdfUrl}
        type="application/pdf"
        width="100%"
        height="100%"
        aria-label="PDF Viewer"
      />
    </PopupDx>
  );
}

export default PdfViewer;

PdfViewer.propTypes = {
  visible: bool.isRequired,
  onHidden: func.isRequired,
  pdfUrl: string.isRequired,
};
