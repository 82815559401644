import styled from 'styled-components';

export const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 50%;
  z-index: 1;
`;

export const ProgressLabel = styled.span`
  margin-top: 10px;
  font-size: 20px;
`;

export const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;
