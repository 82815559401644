import styled from 'styled-components';

export const WrapperContent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const Line = styled.div`
  height: 100%;
  width: 1px;
  background-color: #ddd;
`;

export const WrapperContacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 40%;
`;

export const HeaderContacts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  height: 100%;
  width: 60%;
  overflow-y: auto;
  padding: 0;
  padding: 0 8px;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const FieldRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;

  .groupEmrContact {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
  }
`;

export const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: ${({ $width }) => $width};
  height: 100%;

  border: none;

  .WrapperTagBox {
    width: 100%;
    max-height: 120px;
    overflow: auto;
  }
  .groupSelectBox {
    display: flex;
    gap: 12px;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  height: ${({ $height }) => $height || 'content-fit'} !important;
  padding-top: 10px;

  & + div {
    border-top: 1px solid #ddd;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;

  width: 100%;

  span {
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const EnableEMRConfig = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;
