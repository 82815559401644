import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Popup } from 'devextreme-react';
import React from 'react';
import styled from 'styled-components';

export const PopupStyled = styled(Popup)`
  .dx-popup-content {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// export const Header = styled.div`
//   height: 60px;
//   width: 100%;
//   background-color: ${(props) => props.color};
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   cursor: move;
// `;

// export const SubHeader = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding: 5px;
// `;

// export const Text = styled.span`
//   margin-left: 15px;
//   color: ${(props) => props.color};
//   font-weight: ${(props) => props.weight};
//   font-size: ${(props) => props.size};
//   text-transform: ${(props) => props.upper && 'uppercase'};
// `;

export const BoxSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const BoxCenter = styled.div`
  height: 230px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxAssignment = styled.div`
  height: 250px;
  width: 100%;
  overflow: hidden;
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 8px;
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  padding: 8px;
  margin: 8px;
  width: 100%;
`;

export const SearchInput = styled.input`
  border: none;

  font-size: 1.05rem;
  height: 24px;
`;

export const ButtonCustom = styled(Button)`
  padding: 7px 14px;
  width: 70px;
  margin: 10px;

  &:hover {
    background-color: ${(props) =>
      props.bg === '#FFF' ? 'rgba(0, 0, 0, 0.08)' : '#c8c8c8'};
  }
`;

export const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    border: '1px solid #707070',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    margin: 15,
    zIndex: 1350,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    zIndex: 1351,
    // // height: 10,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // // transition: theme.transitions.create('width'),
    width: '100%',
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },
  buttonClose: {
    marginRight: 5,
  },
  iconClose: {
    color: '#FFF',
  },
  textList: {
    fontSize: 10,
    fontWeight: 500,
  },
}));

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: -50,
    // width: 300,
    // cursor: 'move',

    '& .MuiList-padding': {
      padding: 0,
    },
  },
})((props) => (
  <Menu
    elevation={8}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: '#EBEBEB',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);
