import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const WrapperStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    margin-right: 10px;
  }
`;

export const WrapperDateCaptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const WrapperDateBox = styled.div``;

export const WrapperCaptions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WrapperCaptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;

  i {
    margin-right: 10px;
  }
`;

export const WrapperAppointmentCell = styled.div`
  background-color: ${(props) => props.backColor};
  color: ${(props) => props.foreColor};
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
`;

export default makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  cellRenderTd: {
    padding: '0 !important',
    lineHeight: '34px',
  },
}));
