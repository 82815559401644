import React from 'react';
import styled from 'styled-components';

export const LoadPermissions = styled.div`
  margin: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #3f51b5;
`;

export const BreadCrumb = styled.div`
  margin: 10px;
  h3 {
    font-weight: 500;
    font-size: 20px;
  }
`;

export const BreadCrumbRender = (ACL) => {
  return (
    <BreadCrumb>
      <h3>{ACL.BreadCrumb}</h3>
    </BreadCrumb>
  );
};

export const onToolbarBreadCrumb = (e) => {
  e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'breadCrumb',
  });
};
