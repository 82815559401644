import { useQuery } from 'react-query';
import API from '../API';

async function getDocuSignTemplateList() {
  const { data } = await API.sendRequest(
    'DocuSign/Templates/ListEMRTemplates',
    'GET'
  );

  return data;
}

export default function useDocuSignTemplateList() {
  return useQuery('docuSignTemplateList', getDocuSignTemplateList, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
}
