import React from 'react';
import { func } from 'prop-types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDrop } from 'react-dnd';
import { Container } from './styles';

export default function Garbage({ handleDrop }) {
  const [{ isOver /*, canDrop*/ }, drop] = useDrop({
    accept: 'CELL',
    // canDrop: handleCanDrop,
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Container ref={drop} isActive={isOver}>
      <DeleteOutlineIcon fontSize="large" />
    </Container>
  );
}

Garbage.propTypes = {
  handleDrop: func.isRequired,
};
