import styled from 'styled-components';
import { Scheduler } from 'devextreme-react';

export const PopupContent = styled.div`
  width: 100%;
  height: 100%;
`;
export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 12px;
`;
export const SchedulerStyled = styled(Scheduler)`
  .dx-item-content {
    max-height: 20px;
    background-color: #00000000;
    padding: 0;
  }
  .dx-scheduler-appointment-content {
    max-height: 20px;
    background-color: #00000000;
    padding: 0;
  }
  .dx-scheduler-appointment {
    max-height: 20px;
    background-color: #00000000;
    padding: 0;
  }
  .dx-scheduler-appointment-horizontal {
    max-height: 20px;
    background-color: #00000000;
    padding: 0;
  }
  .dx-scheduler-navigator {
    display: none;
  }
`;
export const AppointmentCell = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  max-height: 20px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
`;

export const AppointmentTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;

  gap: 6px;

  .content {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .color-indicator {
    background-color: ${({ bgColor }) => bgColor};
    height: 12px;
    width: 12px;
    border-radius: 6px;
  }
  .description {
    color: #c0c0c0;
    font-size: 0.9rem;
  }
`;
