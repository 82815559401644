import { DataGrid, LoadPanel, TagBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import usePermissions from 'hooks/usePermissions';
import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react';
import API from 'services/API';
import {
  Container,
  FilterGroup,
  GridContainer,
  Title,
  WrapperContent,
} from './styles';

function ExportStaff() {
  const [contactsExported, setContactsExported] = useState(null);
  const [contactsToShow, setContactsToShow] = useState(null);
  const [areas, setAreas] = useState(null);
  const [deptos, setDeptos] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedDeptos, setSelectedDeptos] = useState([]);

  const [contactTypeFilter, setContactTypeFilter] = useState(0);
  const [loading, setLoading] = useState(true);

  const contactExportGridRef = useRef(null);
  const ACL = usePermissions();

  useEffect(() => {
    let isSubscribed = true;

    API.sendRequest(`Department`, 'get').then(({ status, data }) => {
      if (status === 200 && isSubscribed) {
        setDeptos(data);
      }
    });

    API.sendRequest(`Area`, 'get').then(({ status, data }) => {
      if (status === 200 && isSubscribed) {
        setAreas(data);
      }
    });

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    let contacts = contactsExported;

    if (selectedAreas?.length) {
      contacts = contacts.filter((contact) =>
        selectedAreas.includes(contact?.Area)
      );
    }
    if (selectedDeptos?.length) {
      contacts = contacts.filter((contact) =>
        selectedDeptos.includes(contact?.Department)
      );
    }

    setContactsToShow(contacts);
    setLoading(false);
    // eslint-disable-next-line
  }, [selectedAreas?.length, selectedDeptos?.length, contactsExported]);

  const radioOptions = [
    { value: '0', text: `Staff Only`, term: 'STAFF', archiveName: 'StaffOnly' },
    { value: '1', text: `Managers`, term: 'MNG', archiveName: 'Managers' },
  ];

  async function loadContactExports() {
    const res = await API.sendRequest(
      `Contact/Export/${radioOptions.find((item) => Number(item.value) === contactTypeFilter)
        ?.term
      }`,
      'get'
    );

    return res.data;
  }

  useEffect(() => {
    setLoading(true);
    let isSubscribed = true;

    loadContactExports().then((contacts) => {
      if (isSubscribed) {
        setContactsExported(contacts);
        setLoading(false);
      }
    });

    return () => {
      isSubscribed = false;
      setLoading(false);
    };
    // eslint-disable-next-line
  }, [contactTypeFilter]);

  function handleExportGridToExcel(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      contactTypeFilter ? 'Staff Contacts' : 'All Contacts'
    );
    const dataGrid = contactExportGridRef.current.instance;

    exportDataGridToExcel({
      component: dataGrid,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'PhoneNumbers' && gridCell.value) {
            const content = gridCell.value
              .replaceAll('Business ', '')
              .replaceAll(',', ' \n');

            excelCell._value.model.value = content;
            excelCell._column.width = 40;
            excelCell.alignment = { wrapText: true };
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${radioOptions.find(
            (item) => Number(item.value) === contactTypeFilter
          )?.archiveName
          }.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  function handleExportGridToPdf(e) {
    const dataGrid = contactExportGridRef.current.instance;
    const doc = new jsPDF('landscape');
    // const doc = new jsPDF(
    //   dataGrid.getVisibleColumns().length > 8 ? 'landscape' : 'portrait'
    // );

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
      autoTableOptions: {
        margin: { left: 1, right: 1, top: 5, bottom: 5 },
        rowPageBreak: 'avoid',
      },
      customizeCell: ({ gridCell, pdfCell }) => {
        pdfCell.styles = {
          fontSize: 8,
          cellPadding: 1.5,
          overflow: 'ellipsize',
        };

        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'PhoneNumbers' && gridCell.value) {
            const content = gridCell.value
              .replaceAll('Business ', '')
              .replaceAll(',', '\n');
            pdfCell.content = content;
          }
        }
        if (gridCell.rowType === 'header') {
          pdfCell.styles = {
            fontSize: 9,
            fontStyle: 'bold',
            overflow: 'ellipsize',
          };
        }
      },
    }).then(() => {
      doc.save(
        `${radioOptions.find((item) => Number(item.value) === contactTypeFilter)
          ?.archiveName
        }.pdf`
      );
    });
    e.cancel = true;
  }

  const handleRadioChanged = ({ value }) => {
    setContactTypeFilter(Number(value.value));
  };

  const handleSelectAreas = ({ value }) => {
    setSelectedAreas(value);
  };

  const handleSelectDeptos = ({ value }) => {
    setSelectedDeptos(value);
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxRadioGroup',
        options: {
          dataSource: radioOptions,
          defaultValue: radioOptions[contactTypeFilter],
          onValueChanged: handleRadioChanged,
          value: radioOptions[contactTypeFilter],
          layout: 'horizontal',
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'xlsxfile',
          text: 'Export to Excel',
          onClick: handleExportGridToExcel,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'exportpdf',
          text: 'Export to PDF',
          onClick: handleExportGridToPdf,
        },
      }
    );
  };

  return (
    <Container>
      <Title>{ACL?.BreadCrumb}</Title>
      <WrapperContent>
        <FilterGroup>
          <span>Filter by Areas:</span>
          <TagBox
            placeholder="Select Areas..."
            dataSource={areas}
            valueExpr="AreaDesc"
            displayExpr="AreaDesc"
            searchEnabled={true}
            value={selectedAreas}
            onValueChanged={handleSelectAreas}
            width={400}
            multiline={false}
            maxDisplayedTags={7}
          />
          <span>Filter by Departments:</span>
          <TagBox
            placeholder="Select Departments..."
            dataSource={deptos}
            valueExpr="DepartmentDesc"
            displayExpr="DepartmentDesc"
            searchEnabled={true}
            value={selectedDeptos}
            onValueChanged={handleSelectDeptos}
            width={400}
            multiline={false}
            maxDisplayedTags={7}
          />
        </FilterGroup>
        <GridContainer>
          <DataGrid
            ref={contactExportGridRef}
            showRowLines
            dataSource={contactsToShow}
            allowColumnReordering
            allowColumnResizing
            showBorders
            rowAlternationEnabled
            onToolbarPreparing={onToolbarPreparing}
            width="100%"
            height="100%"
            loadPanel={{ enabled: false }}
          >
            <Column dataField="Email" caption="Email Address" />
            <Column dataField="FirstName" caption="First Name" />
            <Column dataField="LastName" caption="Last Name" />
            <Column dataField="PhoneNumbers" caption='Phone' minWidth={240} cellRender={(data) => {
              if (data.value) {
                const phoneNumbersArray = String(data.value).split(',');
                return (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {phoneNumbersArray.map((phoneNumber, index) => (
                      <div key={index}>{phoneNumber.trim()}</div>
                    ))}
                  </div>
                );
              }
            }} />
            <Column dataField="Area" caption="Area" />
            <Column dataField="Department" caption="Department" />
            <Column dataField="JobTitle" caption="Job Title" />
          </DataGrid>
          <div className="count">Contacts: {contactsToShow?.length}</div>
        </GridContainer>
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={loading}
          message="Loading..."
          closeOnOutsideClick={false}
        />
      </WrapperContent>
    </Container>
  );
}

export default ExportStaff;
