import { Popover } from 'devextreme-react';
import { array, bool, string } from 'prop-types';
import React from 'react';
import pickTextColorBasedOnBgColor from '../../../helpers/pickTextColorBasedOnBgColor';
import { AppCard, AppCode, AppType, Container } from './styles';

const animationConfig = {
  show: {
    type: 'pop',
    from: {
      scale: 0,
    },
    to: {
      scale: 1,
    },
  },
  hide: {
    type: 'fade',
    from: 1,
    to: 0,
  },
};

function AppPopover({ target, visible, data }) {
  return (
    <Popover
      target={`#${target}`}
      position="top"
      width={375}
      visible={visible}
      animation={animationConfig}
    >
      <Container>
        {data?.map((app, index) => (
          <AppCard key={`card_${index}`}>
            <AppType>
              <AppCode
                bgColor={app.Color}
                textColor={pickTextColorBasedOnBgColor(
                  app.Color,
                  '#ffffff',
                  '#333333'
                )}
              >
                {app?.Subject}
              </AppCode>
              <div>{app.AppointmentTypeDesc}</div>
            </AppType>
          </AppCard>
        ))}
      </Container>
    </Popover>
  );
}

AppPopover.propTypes = {
  target: string.isRequired,
  visible: bool.isRequired,
  data: array.isRequired,
};

export default AppPopover;
