import { LoadIndicator, ScrollView } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import PdfViewer from 'pages/FileExplorer/components/PdfViewer';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import API from '../../../../services/API';
import PopupDocViewer from '../PopupDocViewer';
import EmailBox from './components/EmailBox';
import { Container } from './styles';

function EmailView({ refetch }, ref) {
  const [isLoading, setIsLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [indexSelected, setIndexSelected] = useState(null);
  const [docPopupData, setDocPopupData] = useState({
    visible: false,
    url: '',
    isLocalFile: false,
  });

  async function fetchEmailList(id, loading) {
    if (loading) {
      setIsLoading(true);
    }
    if (id) {
      const { data: response } = await API.sendRequest(
        `ExchangeEmail/ListEmailByADUserEMRAccessId/${currentData?.ADUserEMRAccessId}`,
        'get'
      );

      const emails = [];

      await Promise.all(
        response?.map(async (email) => {
          const { data: response } = await API.sendRequest(
            `ExchangeEmail/GetExchangeFile/${email?.FileName.replace(
              '.eml',
              '.HTML'
            )}`,
            'get'
          );

          emails.push({ ...email, emailDetails: response });
        })
      );

      const emailsFiltered = emails
        ?.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((t) => t['emailDetails'] === obj['emailDetails'])
        )
        ?.sort((a, b) => b?.Sequence - a?.Sequence);

      if (JSON.stringify(emailList) !== JSON.stringify(response)) {
        // setEmailList(response);
        setEmailList(emailsFiltered);
      }
    }
    if (loading) {
      setIsLoading(false);
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      changeVisibility: (data) => {
        setCurrentData(data.row.data);
        setVisible((old) => !old);
      },
      setData: (data) => setCurrentData(data),
    }),
    []
  );

  const activeAutoRefetch = useCallback(() => {
    fetchEmailList(currentData?.UserId, false);
    // eslint-disable-next-line
  }, [currentData?.UserId]);

  useEffect(() => {
    let isSubscribed = true;
    let intervalId;

    if (isSubscribed) {
      fetchEmailList(currentData?.UserId, true);

      intervalId = setInterval(() => {
        activeAutoRefetch();
      }, 1 * 60 * 1000); // 1 minute
    }
    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [activeAutoRefetch, visible]);

  function handleOpenFile(params, isLocal) {
    if (isLocal) {
      if (params?.name?.includes('.pdf')) {
        setPdfUrl(URL.createObjectURL(params));
        setOpenPopup(true);
        return;
      }
      if (
        params?.name?.includes('.jpg') ||
        params?.name?.includes('.png') ||
        params?.name?.includes('.gif')
      ) {
        setDocPopupData({
          visible: true,
          url: URL.createObjectURL(params),
          isLocalFile: true,
        });
        return;
      }
      notify('You can only preview PDFs and images.', 'warning', 5000);
      return;
    }

    if (params?.includes('.pdf')) {
      setPdfUrl(params);
      setOpenPopup(true);
      return;
    }
    if (
      params?.includes('.doc') ||
      params?.includes('.docx') ||
      params?.includes('.xlsx') ||
      params?.includes('.jpg') ||
      params?.includes('.png') ||
      params?.includes('.gif') ||
      params?.includes('.txt')
    ) {
      setDocPopupData({ visible: true, url: params, isLocalFile: false });
      return;
    }
    notify('To view this file you need to download it.', 'warning', 5000);
  }

  const onHidden = () => {
    setOpenPopup(false);
    setPdfUrl('');
  };

  if (visible) {
    return (
      <>
        {openPopup && (
          <PdfViewer visible={openPopup} pdfUrl={pdfUrl} onHidden={onHidden} />
        )}
        {docPopupData.visible && (
          <PopupDocViewer
            visible={docPopupData.visible}
            url={docPopupData.url}
            onlyImage={docPopupData.isLocalFile}
            onHidden={() =>
              setDocPopupData({ visible: false, url: '', isLocalFile: false })
            }
          />
        )}
        <Popup
          visible={visible}
          title={`Email - ${currentData.DisplayName} - ${currentData.HospitalGroupName}`}
          onHidden={() => {
            setVisible(false);
            setIndexSelected(null);
            setEmailList([]);
            onHidden();
            refetch();
          }}
          width={1200}
          height="90%"
          showCloseButton
        >
          <ScrollView>
            <Container>
              {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <LoadIndicator visible />
                </div>
              )}
              {!isLoading && emailList?.length === 0 && (
                <h3 style={{ textAlign: 'center' }}>No emails to display</h3>
              )}
              {!isLoading &&
                emailList
                  // ?.filter((item) => item.FileName.includes('.HTML'))
                  ?.map((item, index) => (
                    <EmailBox
                      key={index}
                      emailData={item}
                      currentData={currentData}
                      isSelected={item.Sequence === indexSelected}
                      onHeaderClick={() =>
                        setIndexSelected(
                          item.Sequence === indexSelected ? null : item.Sequence
                        )
                      }
                      handleOpenFile={(url, isLocal) =>
                        handleOpenFile(url, isLocal)
                      }
                      fetchEmailList={fetchEmailList}
                      refetch={refetch}
                    />
                  ))}
            </Container>
          </ScrollView>
        </Popup>
      </>
    );
  }
}

export default forwardRef(EmailView);
