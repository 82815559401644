import { useQuery } from 'react-query';
import API from '../API';

export async function getTrainings() {
  const { data } = await API.sendRequest('Training/List', 'GET');

  return data;
}

export default function useGetTrainings() {
  return useQuery('trainings', getTrainings, {
    refetchOnWindowFocus: false,
  });
}

async function getUserTrainings() {
  const { data } = await API.sendRequest('UserTraining/ListUsers', 'GET');

  return data;
}

export function useGetUserTrainings() {
  return useQuery('userTrainings', getUserTrainings, {
    refetchOnWindowFocus: false,
  });
}
