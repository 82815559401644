import { CheckBox } from 'devextreme-react';
import styled from 'styled-components';

export const CheckBoxSmall = styled(CheckBox)`
  .dx-checkbox-icon {
    scale: 0.78;
  }
`;

export const WrapperBulkButton = styled.div`
  margin-left: 550px;
  padding-bottom: 8px;
`;
