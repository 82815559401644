import { DataGrid } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import API from 'services/API';

import { Container, Title } from './styles';
import { Column } from 'devextreme-react/data-grid';
import DetailPopup from './components/DetailPopup';

function LogErrors() {
  const [logErrors, setLogErrors] = useState([]);
  const [requestDataDetail, setRequestDataDetail] = useState(null);
  const [showDetailPopup, setShowDetailPopup] = useState(false);

  const handleLogErrors = async () => {
    const { data } = await API.sendRequest('LogErrors/List', 'get');
    setLogErrors(data);
  };

  useEffect(() => {
    handleLogErrors();
  }, []);

  const handleShowDetailPopup = (e) => {
    setShowDetailPopup(true);
    setRequestDataDetail(e?.row?.data);
  };

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Title>Log Errors</Title>
      </div>
      <DataGrid
        elementAttr={{
          id: 'gridContainer',
        }}
        keyExpr="Id"
        dataSource={logErrors}
        showBorders
        allowColumnResizing
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
      >
        <Column dataField="UserName" />
        <Column dataField="Id" />
        <Column
          dataField="ErrorMessage"
          cellRender={({ data }) => {
            return data && String(data?.ErrorMessage).includes('{"') ? (
              <div>
                {!JSON.parse(data?.ErrorMessage)?.message
                  ? `${JSON.parse(data?.ErrorMessage)?.title} - ${
                      JSON.parse(data?.ErrorMessage)?.status
                    }`
                  : JSON.parse(data?.ErrorMessage)?.message}
              </div>
            ) : (
              <div>{`${data?.ErrorMessage}`}</div>
            );
          }}
        />
        <Column dataField="StatusCode" width="120px" alignment="left" />
        <Column dataField="Method" width="120px" />
        <Column
          caption="Created At"
          dataField="CreatedDate"
          dataType="datetime"
        />
        <Column dataField="PageRoute" />
        <Column
          caption="Actions"
          type="buttons"
          width={100}
          buttons={[
            {
              hint: 'View',
              icon: 'fa-solid fa-arrow-up-right-from-square',
              onClick: (e) => handleShowDetailPopup(e),
            },
          ]}
        />
      </DataGrid>
      {showDetailPopup && (
        <DetailPopup
          visible={showDetailPopup}
          onClose={() => setShowDetailPopup(false)}
          data={requestDataDetail}
        />
      )}
    </Container>
  );
}

export default LogErrors;
