import axios from 'axios';
import Button from 'devextreme-react/button';
import { FileSelectionItem, Toolbar } from 'devextreme-react/file-manager';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import config from '../../config';
import { BreadCrumbRender } from '../../global';
import usePermissions from '../../hooks/usePermissions';
import API from '../../services/API';
import PdfViewer from './components/PdfViewer';
import {
  Container,
  DXFileManager,
  LoadContainer,
  WrapperBreadCrumb,
  WrapperHeader,
} from './styles';

function FileExplorer() {
  const [filesDirectory, setFilesDirectory] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [itemsSelected, setItemsSelected] = useState([]);

  useEffect(() => {
    async function getFiles() {
      const { data: response } = await API.sendRequest(
        'ContactSchedule/ListDirectories',
        'get'
      );
      setFilesDirectory(response);
    }
    getFiles();
  }, []);

  function handleOpenFile(params) {
    setPdfUrl(params?.file?.dataItem?.url);
    setOpenPopup(true);
  }

  const onHidden = () => {
    setOpenPopup(false);
    setPdfUrl('');
  };

  async function downloadFile(fileName) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const configuration = {
      method: 'get',
      url: `${config.apiUrl}/ContactSchedule/GetPdf/${fileName}`,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    };
    const response = await axios(configuration);
    const blobFile = new Blob([response?.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blobFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const handleDownload = useCallback(async () => {
    await Promise.all(
      itemsSelected.map(async (item) => {
        await downloadFile(item.dataItem.name);
      })
    );
  }, [itemsSelected]);

  const ACL = usePermissions();

  // while (ACL === undefined) {
  //   return <LoadPermissions>Loading permissions</LoadPermissions>;
  // }
  // if (!ACL.Admin) {
  //   return (
  //     <LoadPermissions>You don't have access to this page.</LoadPermissions>
  //   );
  // }

  return filesDirectory.length ? (
    <Container>
      {openPopup && (
        <PdfViewer visible={openPopup} pdfUrl={pdfUrl} onHidden={onHidden} />
      )}
      <WrapperHeader>
        <WrapperBreadCrumb>
          {ACL?.BreadCrumb && BreadCrumbRender(ACL)}
        </WrapperBreadCrumb>
        {itemsSelected.length > 0 && (
          <Button
            icon="download"
            onClick={() => handleDownload(itemsSelected[0].dataItem.name)}
            hint="Download"
            height={36}
            text="Download"
          />
        )}
      </WrapperHeader>
      <DXFileManager
        height="90%"
        currentPath="Widescreen"
        fileSystemProvider={filesDirectory}
        onSelectedFileOpened={(props) => handleOpenFile(props)}
        onSelectionChanged={(itemSelected) => {
          if (itemSelected.selectedItems.length) {
            const items = itemSelected.selectedItems.filter(
              (item) => !item.isDirectory
            );
            setItemsSelected(items);
          } else {
            setItemsSelected([]);
          }
        }}
      >
        <Toolbar>
          <FileSelectionItem name="separator" />
          <FileSelectionItem name="delete" />
        </Toolbar>
      </DXFileManager>
    </Container>
  ) : (
    <LoadContainer>
      <LoadIndicator visible={filesDirectory.length === 0} />
    </LoadContainer>
  );
}

export default FileExplorer;
