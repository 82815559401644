import styled from 'styled-components';

export const BoxLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const BoxCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  input {
    margin-right: 15px;
  }
  label {
    white-space: pre-wrap;
  }
`;

export const WrapperTreeList = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
`;
