import { node } from 'prop-types';
import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

const EmrAccessContext = createContext();

function reducer(state, action) {
  const draft = { ...state };
  switch (action.type) {
    case 'SET_SHOW_UNASSIGNED':
      // draft.showUnassigned = !draft.showUnassigned;
      return { ...draft, showUnassigned: !draft.showUnassigned };

    case 'SET_CURRENT_SELECTED_APPS':
      // draft.currentSelectedApps = action.payload;
      return { ...draft, currentSelectedApps: action.payload };

    case 'SET_CURRENT_SELECTED_USERS':
      // draft.currentSelectedUsers = action.payload;
      return { ...draft, currentSelectedUsers: action.payload };

    default:
      break;
  }
}

const initialState = {
  showUnassigned: false,
  currentSelectedApps: [],
  currentSelectedUsers: [],
};

function EmrAccessProvider({ children }) {
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState([]);
  const [batchRequests, setBatchRequests] = useState([]);
  const [currentTab, setCurrentTab] = useState({ selectedIndex: 0 });
  const [showMissingCredentialsOnly, setShowMissingCredentialsOnly] = useState(
    false
  );
  const dataGridDetailTabRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showUnassignedTest, setShowUnassignedTest] = useState(false);

  const dataGridEmployeesRef = useRef([]);
  const dataGridAppsRef = useRef([]);
  const requestsRef = useRef({
    Users: [],
    Apps: [],
  });

  const emrAccessContextValues = useMemo(
    () => ({
      currentTab,
      setCurrentTab,
      currentSelectedUsers,
      setCurrentSelectedUsers,
      batchRequests,
      setBatchRequests,
      showMissingCredentialsOnly,
      setShowMissingCredentialsOnly,
      dataGridDetailTabRef,
      state,
      dispatch,
      showUnassignedTest,
      setShowUnassignedTest,
      dataGridEmployeesRef,
      dataGridAppsRef,
      requestsRef,
    }),
    // eslint-disable-next-line
    [
      currentSelectedUsers,
      batchRequests,
      currentTab,
      showMissingCredentialsOnly,
      state,
    ]
  );

  return (
    <EmrAccessContext.Provider value={emrAccessContextValues}>
      {children}
    </EmrAccessContext.Provider>
  );
}

EmrAccessProvider.propTypes = {
  children: node.isRequired,
};

const useEmrAccess = () => useContext(EmrAccessContext);

export { EmrAccessProvider, useEmrAccess };
