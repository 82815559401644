import {
  Button,
  LoadPanel,
  Popup,
  SelectBox,
  TextBox,
  Validator,
} from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import API from '../../../../services/API';
import { formatPhone } from '../../../../utils/format';
import {
  Container,
  FieldRow,
  FieldSet,
  GroupContainer,
  WrapperButtons,
} from './styles';

export default function HospitalEditPopup({
  visible,
  onClose,
  currentHospital,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [hospitalDraft, setHospitalDraft] = useState(null);
  const [hospitalGroup, setHospitalGroup] = useState(null);

  useEffect(() => {
    if (currentHospital) {
      setHospitalDraft({ ...currentHospital });
    }
  }, [currentHospital]);

  useEffect(() => {
    let isSubscribed = true;
    API.sendRequest('HospitalGroup', 'get').then(({ data }) => {
      if (isSubscribed) {
        setHospitalGroup(data);
      }
    });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleSaveChanges = async (e) => {
    e.preventDefault();

    if (JSON.stringify(currentHospital) === JSON.stringify(hospitalDraft)) {
      onClose();
      return;
    }

    setIsSaving(true);
    try {
      const res = await API.sendRequest('Hospital', 'post', hospitalDraft);

      if (res.status !== 200) {
        throw new Error(res.message);
      }

      setHospitalDraft(null);
      setIsSaving(false);
      notify('Hospital edited successfully', 'success', 5000);
      onClose();
    } catch (e) {
      setIsSaving(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  return (
    <>
      <LoadPanel
        visible={isSaving}
        message={isSaving ? 'Saving...' : 'Loading...'}
      />
      <Popup
        title={`${hospitalDraft?.HospitalCode} - ${hospitalDraft?.HospitalName}`}
        visible={visible}
        onHiding={onClose}
        width={700}
        height={520}
        dragEnabled={false}
        showCloseButton={false}
      >
        <form style={{ height: '100%' }} onSubmit={handleSaveChanges}>
          <Container>
            <GroupContainer>
              <FieldRow>
                <FieldSet $width="49%">
                  <label htmlFor="hospitalName">Okta Tile</label>
                  <SelectBox
                    name="hospitalGroup"
                    width="100%"
                    dataSource={hospitalGroup}
                    valueExpr="HospitalGroupId"
                    displayExpr="HospitalGroupName"
                    searchEnabled
                    searchExpr="HospitalGroupName"
                    // readOnly
                    value={hospitalDraft?.HospitalGroupId}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        HospitalGroupId:
                          e.value === null
                            ? '00000000-0000-0000-0000-000000000000'
                            : e.value,
                      }))
                    }
                    showClearButton
                  />
                </FieldSet>
                <FieldSet $width="49%">
                  <label htmlFor="hospitalName">Name</label>
                  <TextBox
                    name="hospitalName"
                    width="100%"
                    value={hospitalDraft?.HospitalName}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        HospitalName: e.value,
                      }))
                    }
                  >
                    <Validator>
                      <RequiredRule message="Name is required" />
                    </Validator>
                  </TextBox>
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="49%">
                  <label htmlFor="hospitalCode">Code</label>
                  <TextBox
                    name="hospitalCode"
                    width="100%"
                    value={hospitalDraft?.HospitalCode}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        HospitalCode: e.value,
                      }))
                    }
                  >
                    <Validator>
                      <RequiredRule message="Hospital Code is required" />
                    </Validator>
                  </TextBox>
                </FieldSet>
                <FieldSet $width="49%">
                  <label htmlFor="phoneNumber">Phone</label>
                  <TextBox
                    name="phoneNumber"
                    mask="000-000-0000"
                    maxLength={12}
                    width="100%"
                    value={hospitalDraft?.PhoneNumber}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        PhoneNumber: formatPhone(e.value),
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="32%">
                  <label htmlFor="addLine1">Address Line 1</label>
                  <TextBox
                    name="addLine1"
                    width="100%"
                    readOnly
                    value={hospitalDraft?.Line1}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        Line1: e.value,
                      }))
                    }
                  />
                </FieldSet>
                <FieldSet $width="32%">
                  <label htmlFor="addLine2">Line 2</label>
                  <TextBox
                    name="addLine2"
                    width="100%"
                    readOnly
                    value={hospitalDraft?.Line2}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        Line2: e.value,
                      }))
                    }
                  />
                </FieldSet>
                <FieldSet $width="32%">
                  <label htmlFor="neighborhood">Neighborhood</label>
                  <TextBox
                    name="neighborhood"
                    width="100%"
                    readOnly
                    value={hospitalDraft?.Neighbourhood}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        Neighbourhood: e.value,
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="32%">
                  <label htmlFor="city">City</label>
                  <TextBox
                    name="city"
                    width="100%"
                    readOnly
                    value={hospitalDraft?.City}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        City: e.value,
                      }))
                    }
                  />
                </FieldSet>
                <FieldSet $width="32%">
                  <label htmlFor="state">State</label>
                  <TextBox
                    name="state"
                    width="100%"
                    readOnly
                    value={hospitalDraft?.State}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        State: e.value,
                      }))
                    }
                  />
                </FieldSet>
                <FieldSet $width="32%">
                  <label htmlFor="zipCode">Zip Code</label>
                  <TextBox
                    name="zipCode"
                    width="100%"
                    readOnly
                    value={hospitalDraft?.ZipCode}
                    onValueChanged={(e) =>
                      setHospitalDraft((prevState) => ({
                        ...prevState,
                        ZipCode: e.value,
                      }))
                    }
                  />
                </FieldSet>
              </FieldRow>
              <FieldRow>
                <FieldSet $width="100%">
                  <label htmlFor="zipCode">Full Address</label>
                  <TextBox
                    name="zipCode"
                    width="100%"
                    readOnly
                    value={hospitalDraft?.FullAddress}
                  />
                </FieldSet>
              </FieldRow>
            </GroupContainer>
          </Container>
          <WrapperButtons>
            <Button text="OK" type="success" useSubmitBehavior />
            <Button text="Cancel" type="normal" onClick={() => onClose()} />
          </WrapperButtons>
        </form>
      </Popup>
    </>
  );
}

HospitalEditPopup.propTypes = {
  visible: bool,
  onClose: func.isRequired,
  currentHospital: object.isRequired,
};

HospitalEditPopup.defaultProps = {
  visible: false,
};
