import ContactPopup from '../../components/ContactPopup';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { array, number } from 'prop-types';
import React, { useMemo, useState } from 'react';
import Avatar from '../../components/contacts/Avatar';
import pickTextColorBasedOnBgColor from '../../helpers/pickTextColorBasedOnBgColor';
import AppPopover from './AppPopover';
import useStyles, { AppCode, AppContainer, AvatarWrapper } from './styles';

function WorkDayGrid({ workingDay, dataSource }) {
  const [popoverVisibleId, setPopoverVisibleId] = useState('');
  const [popupContact, setPopupContact] = useState({
    visible: false,
    data: {},
  });
  const classes = useStyles();
  const workingDayName = useMemo(() => {
    switch (workingDay) {
      case 0:
        return 'Yesterday';
      case 1:
        return 'Today';
      case 2:
        return 'Tomorrow';

      default:
        return 'Today';
    }
  }, [workingDay]);

  function avatarCellRender({ data }) {
    if (data) {
      return (
        <AvatarWrapper>
          <Avatar contact={data} h={30} w={30} />
        </AvatarWrapper>
      );
    }

    return <img height="50px" src="assets/blank.jpg" alt="" />;
  }

  function appointmentBadgeCellRender({ data }) {
    const appObj = JSON.parse(data[workingDayName]);
    return (
      <AppContainer
        onMouseEnter={() =>
          setPopoverVisibleId(`cell_${data?.UserId}_${workingDayName}`)
        }
        onMouseLeave={() => setPopoverVisibleId('')}
        id={`cell_${data?.UserId}_${workingDayName}`}
      >
        {appObj?.map((app, index, array) => {
          if (index > 2) {
            return false;
          }
          if (index === 2) {
            return (
              <AppCode
                key={`${index}`}
                textColor={() =>
                  pickTextColorBasedOnBgColor('#0a00f0', '#fff', '#333333')
                }
                bgColor="#0a00f0"
                appQty={array?.length}
              >
                {`+${array.length - 2} more`}
              </AppCode>
            );
          }
          return (
            <AppCode
              key={`${index}`}
              textColor={() =>
                pickTextColorBasedOnBgColor(app?.Color, '#fff', '#333333')
              }
              bgColor={app?.Color}
              appQty={appObj?.length}
            >
              {app?.Subject}
            </AppCode>
          );
        })}
        <AppPopover
          target={`cell_${data?.UserId}_${workingDayName}`}
          visible={
            popoverVisibleId === `cell_${data?.UserId}_${workingDayName}`
          }
          data={appObj}
        />
      </AppContainer>
    );
  }

  function appointmentDescCellRender({ data }) {
    const appObj = JSON.parse(data[workingDayName]);
    return appObj?.map(
      (app, index, array) =>
        `${app?.AppointmentTypeDesc}${index === array?.length - 1 ? '' : ' / '}`
    );
  }

  function phoneCellRender({ data }) {
    const phonesObj = JSON.parse(data?.Phones);

    if (!phonesObj?.length) {
      return '';
    }

    return (
      phonesObj?.find((phone) => phone?.Preferred)?.PhoneNumber ??
      phonesObj[0]?.PhoneNumber
    );
  }

  const handleRowClick = ({ data }) => {
    const contactsData = {
      ...data,
      Phones: JSON.parse(data.Phones),
    };
    setPopupContact({
      visible: true,
      data: contactsData,
    });
  };

  return (
    <>
      {popupContact.data && popupContact.visible && (
        <ContactPopup
          visible={popupContact.visible}
          data={popupContact.data}
          phones={popupContact.phoneList}
          onClose={() => setPopupContact({ visible: false, data: null })}
        />
      )}
      <DataGrid
        dataSource={dataSource}
        height="100%"
        showBorders
        showColumnLines
        showRowLines
        searchPanel={{ visible: true }}
        filterBuilder={{ visible: true }}
        filterRow={{ visible: true }}
        onRowClick={handleRowClick}
      >
        <Column
          caption=""
          dataField="Username"
          width={50}
          allowSorting={false}
          cellRender={avatarCellRender}
        />
        <Column caption="Name" dataField="FullName" width="15%" />
        <Column dataField="Email" width="15%" />
        <Column
          caption="Phone Number"
          cellRender={phoneCellRender}
          width="10%"
        />
        <Column dataField="JobTitle" width="20%" />
        <Column alignment="center" caption="Appointments">
          <Column
            name={`${workingDayName}_code`}
            caption=""
            dataField={workingDayName}
            cellRender={appointmentBadgeCellRender}
            width={80}
            cssClass={classes.cellRenderTd}
          />
          <Column
            name={`${workingDayName}_desc`}
            caption="Description"
            dataField={workingDayName}
            cellRender={appointmentDescCellRender}
          />
          <Column dataField="SearchField" visible={false} />
        </Column>
      </DataGrid>
    </>
  );
}

WorkDayGrid.propTypes = {
  workingDay: number.isRequired,
  dataSource: array.isRequired,
};

export default WorkDayGrid;
