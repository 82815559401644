import { DateBox } from 'devextreme-react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  height: 100%;
  width: 100%;
`;

export const WrapperGrids = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
  gap: 6px;

  @media (max-height: 700px) {
    flex-direction: row;
    gap: 7px;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 50%;
  max-height: 50%;

  @media (max-height: 700px) {
    height: 100%;
    max-height: 100%;
    width: 50%;
  }
`;

export const Grid = styled.div`
  width: 100%;
  height: calc(100% - 24px);
`;

export const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  gap: 5px;
  width: 100%;
`;

export const DateBoxStyled = styled(DateBox)`
  border: none !important;
  background-color: transparent !important;

  .dx-texteditor-input {
    padding: 0 0 0 6px !important;
  }
`;
