export default {
  failure: '#cc0000',
  success: '#357a38',
  transparent: 'transparent',
  opacity: 'rgba(0,0,0,0.7)',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#337ab7',
  grayscale: {
    gray20: '#808080',
    gray25: '#707070',
    gray30: '#2F2623',
    gray70: '#F3F3F3',
    gray80: '#ccc',
    gray90: '#eee',
    gray100: '#f7f7f7',
  },
  primary: {
    main: '#E50000',
  },
  secondary: {
    main: '#9D0911',
  },
  third: {
    main: '#9c2919',
  },
  fourth: {
    main: '#ffc10d',
    secondary: '#577590',
  },
  fifth: {
    main: '#7159c1',
  },
  green: {
    primary: '#43AA8B',
  },
  tags: {
    success: '#D3FACD',
    open: '#61E483',
    close: ' #ED3237',
    maxDiscount: '#FFDDC6',
    medDiscount: '#ED8F32',
    minDiscount: '#FCD76E',
  },
};
