import React, { useEffect, useState } from 'react';
import { element, func, arrayOf, string, number } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Draggable from 'react-draggable';
import CustomCheckbox from '../Checkbox';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '../contacts/Avatar';
import API from '../../services/API';

import 'react-perfect-scrollbar/dist/css/styles.css';

import {
  Header,
  SubHeader,
  Text,
  BoxSearch,
  BoxCenter,
  BoxAssignment,
  WrapperButtons,
  ButtonCustom,
  StyledMenu,
  StyledMenuItem,
  useStyles,
} from './styles';

export default function GroupsPopup({
  anchorEl,
  handleClose,
  groups,
  setGroups,
  setErrorSnack,
  handleClickSnack,
  title,
  reportId,
}) {
  const classes = useStyles();
  const [groupsApi, setGroupsApi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [term, setTerm] = useState('');

  const [position, setPosition] = useState(0);

  useEffect(() => {
    async function loadGroups() {
      const { data } = await API.sendRequest('Group', 'get');

      const results = data.map((d) => {
        const existItem = groups.filter((it) => it.GroupId === d.GroupId);
        if (existItem && existItem.length > 0) {
          d.check = true;
        } else {
          d.check = false;
        }
        d.GroupDesc = `${d.GroupCode} - ${d.GroupName}`;
        return d;
      });

      setGroupsApi(results);
      setIsLoading(false);
    }

    loadGroups(); // eslint-disable-next-line
  }, []);

  const handleChangeCheckbox = (event) => {
    const { id, checked } = event.target;

    setGroupsApi((prevState) =>
      prevState.map((prev) => {
        if (prev.ContactId === id) {
          prev.check = checked;
        }
        return prev;
      })
    );
  };

  const handleClickCheckbox = (id, checked) => {
    setGroupsApi((prevState) =>
      prevState.map((prev) => {
        if (prev.GroupId === id) {
          prev.check = !checked;
        }
        return prev;
      })
    );
  };

  const handleSave = async () => {
    setIsLoading(true);

    const groupsSave = groupsApi.filter((group) => group.check);
    const groupsSaveId = groupsSave.map((p) => ({ GroupId: p.GroupId }));

    // SAVE
    let data;
    if (groupsSaveId.length > 0) {
      const response = await API.sendRequest('Report/SaveGroups', 'post', {
        ReportId: reportId,
        Groups: [...groupsSaveId],
      });
      data = response.data;
    } else {
      const response = await API.sendRequest('Report/SaveGroups', 'post', {
        ReportId: reportId,
        Groups: [],
      });
      data = response.data;
    }

    if (data) {
      setGroups(groupsSave);

      setIsLoading(false);
      handleClose();
      handleClickSnack();
    } else {
      setIsLoading(false);
      setErrorSnack(true);
      handleClickSnack();
    }
  };

  const onStart = () => {
    setPosition(position + 1);
  };

  const onStop = () => {
    setPosition(position - 1);
  };

  const filterGroups = groupsApi.filter((group) =>
    group.GroupDesc.toLowerCase().includes(term.toLowerCase())
  );
  const dragHandlers = { onStart, onStop };

  return (
    <Draggable {...dragHandlers} handle="strong">
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <strong>
          <Header color="#D5D5D5">
            <SubHeader>
              <Text color="#000" weight={300} size="12px" />
              <Text color="#000" weight="bold" size="16px" upper>
                {title}
              </Text>
            </SubHeader>
            <IconButton
              className={classes.buttonClose}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" className={classes.iconClose} />
            </IconButton>
          </Header>
        </strong>
        <BoxSearch>
          {!isLoading && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                // value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
            </div>
          )}
        </BoxSearch>
        <BoxAssignment>
          {!isLoading ? (
            <PerfectScrollbar>
              {filterGroups &&
                filterGroups.map((listItem) => (
                  <StyledMenuItem
                    key={listItem.GroupId}
                    onClick={() =>
                      handleClickCheckbox(listItem.GroupId, listItem.check)
                    }
                  >
                    <ListItemIcon>
                      <CustomCheckbox
                        id={listItem.GroupId}
                        value={listItem.check}
                        handleChange={handleChangeCheckbox}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={listItem.GroupDesc}
                      className={classes.textList}
                    />
                  </StyledMenuItem>
                ))}
            </PerfectScrollbar>
          ) : (
            <BoxCenter>
              <CircularProgress />
            </BoxCenter>
          )}
        </BoxAssignment>

        <WrapperButtons>
          <ButtonCustom bg="#707070" color="#FFF" onClick={handleSave}>
            OK
          </ButtonCustom>
          <ButtonCustom bg="#FFF" color="#707070" onClick={handleClose}>
            Cancel
          </ButtonCustom>
        </WrapperButtons>
      </StyledMenu>
    </Draggable>
  );
}

GroupsPopup.propTypes = {
  anchorEl: element.isRequired,
  handleClose: func.isRequired,
  setGroups: func.isRequired,
  setErrorSnack: func.isRequired,
  handleClickSnack: func.isRequired,
  groups: arrayOf.isRequired,
  title: string.isRequired,
  groupId: number.isRequired,
};
