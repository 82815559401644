import React, { useState, useEffect } from 'react';
import API from '../../../services/API';
import CustomStore from 'devextreme/data/custom_store';

import {
  Column,
  DataGrid,
  Paging,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';
import useStyles from '../../styles/styles';
import usePermissions from '../../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../../global';
import { Template } from 'devextreme-react/core/template';

const values = new CustomStore({
  key: 'ValueId',
  load: async () => {
    const { data } = await API.sendRequest(`Value/ListWeb`, 'get');
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`Value`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`Value`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`Value/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const Value = () => {
  const classes = useStyles();
  const [areas, setAreas] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    let isSubscribed = true;

    API.sendRequest('ValueCategory', 'GET', false).then((res) => {
      if (res.status === 200 && isSubscribed) {
        setCategories(res.data);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);
  useEffect(() => {
    let isSubscribed = true;

    API.sendRequest('Area', 'GET', false).then((res) => {
      if (res.status === 200 && isSubscribed) {
        setAreas(res.data);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <div />;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  ACL.BreadCrumb = 'Performance / Values';
  /** ****************************** */

  function getCategoryDisplayExpr(item) {
    if (areas) {
      const areaDesc = areas.find((a) => a.AreaId === item.AreaId).AreaDesc;
      return item ? `${item.ValueCategoryDesc} (${areaDesc})` : '';
    }
    return `${item.ValueCategoryDesc}`;
  }

  return (
    <div className={classes.divSmallGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={values}
        eyExpr="ValueId"
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        onRowUpdating={onRowUpdating}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />
        <Editing
          useIcons
          mode="form"
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        />

        <Column dataField="ValueDesc" caption="Name" />
        <Column dataField="Description" />
        <Column dataField="ValueCategoryId" caption="Category">
          <Lookup
            dataSource={categories}
            displayExpr={getCategoryDisplayExpr}
            valueExpr="ValueCategoryId"
          />
        </Column>
        <Column dataField="ValueOrder" caption="Value Order" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default Value;
