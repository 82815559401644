import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  padding: 16px 32px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 28px;
  padding-left: 8px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 36px);
  background-color: white;
  border: 1px solid #cccccc60;
  padding: 0 12px;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 90px);
  width: 100%;

  .count {
    text-align: left;
    padding: 8px 0;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 8px 0;
  width: 100%;
  background-color: white;
`;
