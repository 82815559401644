import Box, { Item } from 'devextreme-react/box';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/ui/load-panel';
import notify from 'devextreme/ui/notify';
import { bool, func, object } from 'prop-types';
import React, { useState } from 'react';
import API from '../../../services/API';
import Avatar from '../../contacts/Avatar';
import { Container, Label } from './styles';

function AdUserDeletePopup({
  visible,
  onVisibleChange,
  adUser,
  updateAdUsers,
}) {
  const [deleting, setDeleting] = useState(false);
  // const [typeDel, setTypeDel] = useState('2');
  // const [comments, setComments] = useState('');

  // useEffect(() => {
  //   if (typeDel === '2') {
  //     setComments('');
  //   }
  // }, [typeDel]);

  const handleDelete = async () => {
    // if (typeDel === '1' && comments === '') {
    //   notify({ message: 'Please enter a comment.' }, 'error', 5000);
    //   return;
    // }
    // setDeleting(true);

    // const adUserDelete = {
    //   UserId: adUser.ContactId,
    //   DeletionType: Number(typeDel),
    //   Comments: comments,
    // };

    const result = await API.sendRequest(
      `ADUser/Delete/${adUser.ContactId}`,
      'POST'
    );

    if (result.status !== 200) {
      setDeleting(false);
      notify({ message: `Error: ${result.message}` }, 'error', 5000);
    } else {
      if (result?.data?.ErrorMsg) {
        notify(
          {
            message: result?.data?.ErrorMsg,
          },
          'error',
          7000
        );
        return;
      }
      setDeleting(false);
      notify(
        {
          message: `AD User ${adUser.FirstName} ${adUser.LastName} deleted with success!`,
        },
        'success',
        2000
      );
      updateAdUsers(adUser.ContactId);
      onVisibleChange(false);
    }
  };

  // const handleDelTypeChange = ({ value }) => {
  //   setTypeDel(value.value);
  // };

  // const renderRadioGroupItem = (itemData) => {
  //   return (
  //     <>
  //       <RadioLabel>
  //         <span>{itemData.text}</span>
  //       </RadioLabel>
  //     </>
  //   );
  // };

  // const radioOptions = [
  //   { value: '1', text: 'Move it to the OU [Disabled User Accounts]' },
  //   { value: '2', text: `Keep it active on the OU [${adUser.OU}]` },
  // ];

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        handleDelete();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        onVisibleChange(false);
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      title={`${adUser.FirstName} ${adUser.LastName}`}
      onHidden={() => onVisibleChange(false)}
      width={600}
      height={250}
      showCloseButton={false}
      disabled={deleting}
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={deleting}
        message="Deleting..."
        closeOnOutsideClick={false}
      />
      <Container>
        <Box direction="row" height="250px" width="100%">
          <Item ratio={1}>
            <Avatar contact={adUser} h={128} w={128} />
          </Item>
          <Item ratio={3}>
            <Box direction="col" width="100%" height="100%">
              <Item ratio={1} shrink>
                <Label>
                  Do you want to delete this user from Team Relay and Disable it
                  in Active Directory?
                </Label>
                {/* <Label marginTop={'10px'}>
                  Additionally in Active Directory:
                </Label> */}
              </Item>
              {/* <Item ratio={2}>
                <OptionsItem>
                  <RadioGroup
                    dataSource={radioOptions}
                    defaultValue={radioOptions[Number(typeDel) - 1]}
                    value={radioOptions[Number(typeDel) - 1]}
                    onValueChanged={handleDelTypeChange}
                    itemRender={renderRadioGroupItem}
                  />
                  {typeDel === '1' && (
                    <>
                      <Label marginTop={'10px'}>Comments: </Label>
                      <TextBox
                        value={comments}
                        onValueChanged={({ value }) => setComments(value)}
                      />
                    </>
                  )}
                </OptionsItem>
              </Item> */}
            </Box>
          </Item>
        </Box>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

AdUserDeletePopup.propTypes = {
  visible: bool.isRequired,
  onVisibleChange: func.isRequired,
  adUser: object.isRequired,
  updateAdUsers: func.isRequired,
};

export default AdUserDeletePopup;
