import { LoadPanel } from 'devextreme-react';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { func } from 'prop-types';
import { bool, object } from 'prop-types';
import React, { useState } from 'react';
import API from '../../../../services/API';

function RestoreConfirmationPopup({
  userData,
  visible,
  handleClose,
  updateRecycleList,
}) {
  const [restoring, setRestoring] = useState(false);

  async function handleRestoreUser() {
    setRestoring(true);
    try {
      await API.sendRequest(`ADUser/Restore/${userData.UserId}`, 'POST');
      updateRecycleList();
      notify('User restored with success', 'success', 3000);
      handleClose();
    } catch (error) {
      console.log(error); //eslint-disable-line
      notify('An error ocurred trying restore the user', 'error', 3000);
    } finally {
      setRestoring(false);
    }
  }

  return (
    <Popup
      visible={visible}
      onHiding={handleClose}
      width={350}
      height={150}
      showTitle={false}
      showCloseButton={false}
      closeOnOutsideClick
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={restoring}
        message="Restoring..."
        closeOnOutsideClick={false}
      />
      <div style={{ textAlign: 'center' }}>
        {`The user [${userData.FirstName}] will be restored. Are you sure?`}
      </div>
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{ text: 'OK', onClick: handleRestoreUser, type: 'success' }}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{ text: 'Cancel', onClick: handleClose }}
      />
    </Popup>
  );
}

RestoreConfirmationPopup.propTypes = {
  userData: object.isRequired,
  visible: bool.isRequired,
  handleClose: func.isRequired,
  updateRecycleList: func.isRequired,
};

export default RestoreConfirmationPopup;
