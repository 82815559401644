import styled from 'styled-components';

export const NoChangesWarning = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const DataGridContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const HistoryContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fafafa;

  table {
    width: 100%;
    font-size: 0.9rem;
    border-collapse: separate;
    border-spacing: 0 0.75rem;

    th,
    td {
      padding: 0.75rem 0.5rem;
      color: #000;
    }

    td.fieldName {
      font-weight: bold;
    }

    th {
      color: #000;
      text-transform: uppercase;
      text-align: left;
    }

    td {
      border-top: solid 2px #eee;
      border-bottom: solid 2px #eee;
    }

    tr td:first-child {
      border-left: solid 2px #eee;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    tr td:last-child {
      border-right: solid 2px #eee;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
`;
