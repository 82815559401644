import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateBox } from 'devextreme-react';
import DataGrid, {
  Selection,
  Column,
  Grouping,
  GroupPanel,
  Paging,
} from 'devextreme-react/data-grid';

import useStyles from './styles.js';

import {
  WrapperStatus,
  WrapperDateBox,
  WrapperDateCaptions,
  WrapperCaptions,
  WrapperCaptionItem,
  WrapperAppointmentCell,
} from './styles';

import pickTextColorBasedOnBgColor from '../../helpers/pickTextColorBasedOnBgColor';
import API from '../../services/API';

let dataGrid;

const ExchangePopup = (props) => {
  const { visible, onVisibleChange, area, currentDate } = props;
  // const [error, setError] = useState('');
  const [status, setStatus] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [exchangeDate, setExchangeDate] = useState(currentDate);
  const [counter, setCounter] = useState(0);
  const [selectedKeys, setSelectedRowKeys] = useState([]);
  const classes = useStyles();

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleExchange = async () => {
    const schedules = selectedKeys.map((item) => ({ ContactScheduleId: item }));

    // const response =
    await API.sendRequest(
      `ContactSchedule/ChangeStatus/0/1`,
      'post',
      schedules,
      false
    );

    setCounter(counter + 1);
    dataGrid.instance.clearSelection();

    // setError('');
  };

  const onMonthChanged = (e) => {
    const newDate = new Date(e.value);
    setExchangeDate(newDate);
  };

  useEffect(() => {
    async function init() {
      const response = await API.sendRequest(
        `ContactSchedule/ListExchange/${area}/${exchangeDate.getFullYear()}/${
          exchangeDate.getMonth() + 1
        }`,
        'get',
        {},
        false
      );

      if (response.status === 200) {
        setSchedules(response.data);
      }

      const status = await API.sendRequest('ScheduleStatus', 'get');
      setStatus(status.data);
    }

    init(); //eslint-disable-next-line
  }, [exchangeDate, counter]);

  const cellRenderIcon = (source) => {
    if (source.data) {
      return (
        <>
          <WrapperStatus>
            <i className={source.data.ScheduleStatusIcon}></i>
            <p>{source.data.ScheduleStatusDesc}</p>
          </WrapperStatus>
        </>
      );
    }
    return <img height="34px" src="assets/blank.jpg" alt="" />;
  };

  const cellRenderAppointment = (source) => {
    if (source.data) {
      return (
        <WrapperAppointmentCell
          foreColor={pickTextColorBasedOnBgColor(
            source.data.Color,
            '#FFF',
            '#000'
          )}
          backColor={source.data.Color}
        >
          <p>{source.data.Subject}</p>
        </WrapperAppointmentCell>
      );
    }
    return <img height="34px" src="assets/blank.jpg" alt="" />;
  };

  return (
    <Dialog
      open={visible}
      onClose={() => onVisibleChange(false)}
      aria-labelledby="form-dialog-title"
      height={800}
      maxWidth="lg"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="form-dialog-title">
        SEND APPOINTMENTS TO EXCHANGE
      </DialogTitle>
      <DialogContent>
        <WrapperDateCaptions>
          <WrapperDateBox>
            <DateBox
              id="dtCurrentDate"
              defaultValue={exchangeDate}
              type="date"
              format="date"
              width="200px"
              displayFormat="monthAndYear"
              // maxZoomLevel="year"
              // minZoomLevel="century"
              calendarOptions={{
                maxZoomLevel: 'year',
                minZoomLevel: 'century',
              }}
              onValueChanged={onMonthChanged}
            />
          </WrapperDateBox>
          <WrapperCaptions>
            {status &&
              status.map((st, stIndex) => {
                return (
                  <WrapperCaptionItem key={stIndex}>
                    <i className={`${st.ScheduleStatusIcon}`}></i>
                    <p>{st.ScheduleStatusDesc}</p>
                  </WrapperCaptionItem>
                );
              })}
          </WrapperCaptions>
        </WrapperDateCaptions>

        <div className={classes.divGridContainer}>
          <DataGrid
            id={'gridContainer'}
            dataSource={schedules}
            keyExpr={'ContactScheduleId'}
            onSelectionChanged={onSelectionChanged}
            ref={(ref) => (dataGrid = ref)}
            allowColumnReordering={true}
            showBorders={true}
            rowAlternationEnabled={true}
            showRowLines={true}
            headerFilter={{ visible: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
          >
            <GroupPanel visible={true} />
            <Grouping autoExpandAll={true} />
            <Selection mode={'multiple'} />
            <Paging enabled={false} />
            <Column dataField={'FullName'} caption={'Name'} groupIndex={0} />
            <Column dataField={'Email'} />
            <Column
              dataField={'ScheduleStatusDesc'}
              caption={'Status'}
              width={150}
              cellRender={cellRenderIcon}
            />
            <Column
              dataField={'Subject'}
              caption={'Appointment'}
              cellRender={cellRenderAppointment}
              cssClass={classes.cellRenderTd}
              width={100}
            />
            <Column dataField={'Description'} />
            <Column dataField={'StartDate'} dataType="date" width={100} />
            <Column dataField={'EndDate'} dataType="date" width={100} />
            <Column dataField={'Duration'} />
          </DataGrid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleExchange} color="primary">
          Send
        </Button>
        <Button onClick={() => onVisibleChange(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExchangePopup;
