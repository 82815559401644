import React, { useEffect, useState } from 'react';
import { HistoryContainer, TableRow } from './styles';
import { object } from 'prop-types';

function XMLHistoryDetail({ data }) {
  const [beforeData, setBeforeData] = useState([]);
  const [afterData, setAfterData] = useState([]);
  const auditObj = { ...beforeData, ...afterData };

  useEffect(() => {
    if (data) {
      setAfterData(convertXMLToJson(data.data.XMLAfter));

      setBeforeData(convertXMLToJson(data.data.XMLBefore));
    }
  }, [data]);

  function convertXMLToJson(xml) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');
    const obj = {};
    if (xmlDoc.children.length > 0) {
      for (let i = 0; i < xmlDoc.children[0].attributes.length; i++) {
        const attribute = xmlDoc.children[0].attributes[i];
        obj[attribute.name] = attribute.value;
      }
    }
    const jsonString = JSON.stringify(obj);
    const jsonObject = JSON.parse(jsonString);
    return jsonObject;
  }

  return (
    <div>
      <HistoryContainer>
        {data.data.ActionDesc === 'Update' ? (
          <table cellSpacing="0">
            <thead>
              <tr>
                <th> &nbsp;</th>
                <th>Before</th>
                <th>After</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(auditObj).map(([key]) => (
                <TableRow
                  key={key}
                  hasalteration={beforeData?.[key] !== afterData?.[key]}
                >
                  <td>
                    <b>{key}</b>
                  </td>
                  <td>{beforeData?.[key]}</td>
                  <td>{afterData?.[key]}</td>
                </TableRow>
              ))}
            </tbody>
          </table>
        ) : (
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>Record created</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(auditObj).map(([key, value]) => (
                <TableRow key={key}>
                  <td>
                    <b>{key}</b>
                  </td>
                  <td>{value}</td>
                </TableRow>
              ))}
            </tbody>
          </table>
        )}
      </HistoryContainer>
    </div>
  );
}

XMLHistoryDetail.propTypes = {
  data: object.isRequired,
};

export default XMLHistoryDetail;
