import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 3px 0 0;
  border-top: ${(props) => (props.border ? '2px solid #3f51b5' : '')};
  border-right: ${(props) => (props.border ? '2px solid #3f51b5' : '')};
  border-bottom: ${(props) => (props.border ? '2px solid #3f51b5' : '')};
  border-left: ${({ periodColor, isSummary }) =>
    periodColor && !isSummary ? `8px solid ${periodColor}` : ''};
  background-color: ${(props) =>
    props.isToday
      ? '#9dcbff'
      : props.isWeekend
      ? '#eef797'
      : props.isHoliday
      ? '#90D052'
      : '#FFF'};
  background: ${({ isLocked, isSummary }) =>
    isLocked &&
    !isSummary &&
    'repeating-linear-gradient(45deg, #00000010, #00000010 10px, #ffffff20 10px, #ffffff20 20px)'};
  cursor: ${(props) => (props.pointer ? 'no-drop' : 'default')};
  display: ${(props) => (props.isSummary ? 'block' : 'flex')};
  flex-direction: ${(props) => (props.isSummary ? 'row' : 'column-reverse')};
  text-align: center;
`;
