import styled from 'styled-components';

export const Content = styled.div`
  max-height: 220px;
  min-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  align-items: flex-start;
  justify-content: ${({ reverse }) => (reverse ? 'flex-end' : 'flex-start')};
  gap: 10px;
  width: 100%;
  height: ${({ $height }) => $height || 'content-fit'} !important;
  padding-top: 10px;

  & + div {
    border-top: 1px solid #ddd;
  }
`;

export const FieldRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
`;

export const FieldSet = styled.fieldset`
  border: unset;
  background: unset;
  display: flex;
  gap: 15px;
  width: 100%;

  .label {
    width: 110px;
  }
`;
