import React, { useState, useEffect } from 'react';
//import { useSelector, useDispatch } from 'react-redux';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
import config from 'devextreme/core/config';
import appConfig from '../../../config';
import GoogleMapReact from 'google-map-react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import SearchPopup from '../SearchPopup';
import Marker from '../Marker';
import isInside from 'point-in-polygon';

// import Chat from '../../../components/popups/Chat';
// import { newChat } from '../../../store/modules/chat/actions';

export default function MapContainer(props) {
  const [contacts, setContacts] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [activeMarkers, setActiveMarkers] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [drawMode, setDrawMode] = useState(false);
  const [drawingManager, setDrawing] = useState(null);
  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);

  // const dataChat = useSelector(({ chat }) => chat.chatData);
  // const dispatch = useDispatch();

  const defaultProps = {
    center: {
      lat: 40.698061,
      lng: -74.410133,
    },
    zoom: 14,
  };

  const polygonOptions = {
    fillColor: '#455A64',
    fillOpacity: 0.3,
    strokeColor: '#455A64',
    strokeWeight: 3,
    clickable: true,
    editable: true,
    zIndex: 1,
  };

  useEffect(() => {
    const fitBounds = (list) => {
      if (window.google && window.google.maps && map) {
        const bounds = new window.google.maps.LatLngBounds();

        if (list && list.length > 0) {
          list.forEach((marker) => {
            bounds.extend(
              new window.google.maps.LatLng(marker.props.lat, marker.props.lng)
            );
          });

          map.fitBounds(bounds);
        }
      }
    };

    if (contacts) {
      const list = contacts
        .filter(
          (item) =>
            (item.Online && item.Coords) || item.AreaDesc === 'Hospitals'
        )
        .map((item) => {
          return (
            <Marker
              contact={item}
              map={map}
              key={item.ContactId}
              lat={parseFloat(item.Coords.split(',')[0].trim())}
              lng={parseFloat(item.Coords.split(',')[1].trim())}
            />
          );
        });

      //no primeiro momento, todos os markers ativos corresponde a todos os contatos
      setMarkers(list);
      setActiveMarkers(list);
      fitBounds(list);
    } //eslint-disable-next-line
  }, [contacts]);

  useEffect(() => {
    if (drawMode) {
      if (drawingManager) {
        drawingManager.setDrawingMode(
          window.google.maps.drawing.OverlayType.POLYGON
        );
        drawingManager.setMap(map);
      }

      drawPolyline();
    } else {
      if (drawingManager) {
        drawingManager.setDrawingMode(null);
        drawingManager.setMap(null);
      }
      deletePolygon(polygon, null);
    } //eslint-disable-next-line
  }, [drawMode]);

  useEffect(() => {
    config({
      floatingActionButtonConfig: {
        direction: 'auto',
        zIndex: 3000,
        position: {
          my: 'right top',
          at: 'right top',
          offset: '-62 82',
        },
      },
    });

    repaintFloatingActionButton();
  }, []);

  const deletePolygon = (polyline, mode) => {
    if (polyline) {
      polyline.setMap(null);
      setActiveMarkers(markers);
    }

    if (drawingManager && mode !== null) {
      drawingManager.setDrawingMode(
        mode ? window.google.maps.drawing.OverlayType.POLYGON : null
      );
      drawingManager.setMap(mode ? map : null);
    }
  };

  const drawPolyline = () => {
    //======================================================
    // Event listeners after Polygon closed
    //======================================================
    window.google.maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      function (polyline) {
        drawingManager.setDrawingMode(null);

        setPolygon(polyline);
        let _resizablePolygon = polyline.getPath();

        //======================================================
        // Delete Polygon on click
        //======================================================
        window.google.maps.event.addListener(polyline, 'click', (e) => {
          deletePolygon(polyline, drawMode);
        });

        //======================================================
        // Filtering function
        //======================================================
        const filterMarkers = () => {
          let polygon = [];

          _resizablePolygon.forEach((coord) => {
            polygon.push([coord.lat(), coord.lng()]);
          });

          const insideMarkers = markers.filter((marker) => {
            if (isInside([marker.props.lat, marker.props.lng], polygon)) {
              return marker;
            }
            //! Falta o return caso não entre no if
            return null;
          });

          setActiveMarkers(insideMarkers);
        };

        filterMarkers();

        //======================================================
        // Resize polygon
        //======================================================
        window.google.maps.event.addListener(
          _resizablePolygon,
          'set_at',
          function (edge) {
            _resizablePolygon = polyline.getPath();
            filterMarkers();
          }
        );

        window.google.maps.event.addListener(
          _resizablePolygon,
          'insert_at',
          function (edge) {
            _resizablePolygon = polyline.getPath();
            filterMarkers();
          }
        );
      }
    );
  };

  // const fitBounds = (list) => {
  //   if (window.google && window.google.maps && map) {
  //     const bounds = new window.google.maps.LatLngBounds();

  //     if (list && list.length > 0) {
  //       list.forEach((marker) => {
  //         bounds.extend(
  //           new window.google.maps.LatLng(marker.props.lat, marker.props.lng)
  //         );
  //       });

  //       map.fitBounds(bounds);
  //     }
  //   }
  // };

  function onContactClick(e, item) {
    //dispatch(newChat(item));
  }

  const toggleDraw = () => {
    setDrawMode(!drawMode);
  };

  const onHiding = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleApiLoaded = (g) => {
    const map = g.map;
    setMap(map);

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: polygonOptions,
    });

    setDrawing(drawingManager);
  };

  const style = {
    width: '100%',
    height: 'calc(100vh - 100px)',
  };

  return (
    <div id="targetMapContainer">
      <div style={style}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: appConfig.googleAPIKey,
            libraries: ['drawing'].join(','),
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
          libraries={['drawing', 'visualization']}
        >
          {activeMarkers &&
            activeMarkers.map((item) => {
              return item;
            })}
        </GoogleMapReact>
      </div>
      <SearchPopup
        onHiding={onHiding}
        isPopupVisible={isPopupVisible}
        contacts={contacts}
        setContacts={setContacts}
        onContactClick={onContactClick}
      />
      <SpeedDialAction
        icon="add"
        label="Show Filter"
        index={1}
        visible={!isPopupVisible}
        onClick={onHiding}
      />
      <SpeedDialAction
        icon="edit"
        label="Enable Drawing"
        index={2}
        visible={!drawMode && contacts && contacts.length > 0}
        onClick={toggleDraw}
      />
      <SpeedDialAction
        icon="edit"
        label="Disable Drawing"
        index={2}
        visible={drawMode}
        onClick={toggleDraw}
      />
      {/* {contacts && (
          <>
            {dataChat.length > 0 &&
              dataChat.map((item) => (
                <Chat
                  key={item.personData.ContactId}
                  active={item.personData.isActive}
                  data={item.personData}
                  position={item.personData.position}
                  minimized={item.personData.minimized}
                />
              ))}
          </>
        )} */}
    </div>
  );
}
