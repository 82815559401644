import { format } from 'date-fns';
import DataGrid, { Column, Lookup, Paging } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import TagBox from 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { emrSaveRequests } from '../../../../services/requests/emrRequestsPost';
import useAllADUserEmrAccess from '../../../../services/requests/useAllADUserEmrAccess';
import useAllHospitalsGroupsList from '../../../../services/requests/useAllHospitalsGroupsList';
import useEmrRequestMethodList from '../../../../services/requests/useEmrRequestMethodList';
import useGetTrainings from '../../../../services/requests/useTrainings';
import { saveUserTrainings } from '../../../../services/requests/useUserTrainings';
import { useEmrAccess } from '../../contexts/EmrAccessContext';
import {
  Container,
  DateBoxStyled,
  FieldSet,
  Grid,
  GridContainer,
  WrapperGrids,
} from './styles';

const NewRequestPopup = ({ refetch, isHospitalGroupView }, ref) => {
  const [hospitalGroupList, setHospitalGroupList] = useState(null);
  const [trainingList, setTrainingList] = useState(null);
  const [sendEMRRequestsIsLoading, setSendEMRRequestsIsLoading] = useState(
    false
  );
  // const [sendUserTrainingIsLoading, setSendUserTrainingIsLoading] = useState(
  //   false
  // );
  // const queryClient = useQueryClient();
  const { data: allUsersList } = useAllADUserEmrAccess();
  const { data: emrRequestMethodList } = useEmrRequestMethodList();
  const { currentSelectedUsers, setCurrentSelectedUsers } = useEmrAccess();
  const hospitalGroupDataGridRef = useRef();
  const trainingDataGridRef = useRef();

  const { data: trainingData } = useGetTrainings();
  const { data: hospitalsGroupData } = useAllHospitalsGroupsList();

  const { requestsRef, dataGridAppsRef, dataGridEmployeesRef } = useEmrAccess();

  const [popupVisible, setPopupVisible] = useState(false);

  // function init() {
  //   setPopupVisible(true);
  //   const hospitalGroupDataGrid = hospitalGroupDataGridRef.current?.instance;
  //   setCurrentSelectedUsers([]);
  //   hospitalGroupDataGrid.clearSelection();

  //   console.log('isHospitalGroupView', isHospitalGroupView);

  //   if (isHospitalGroupView) {
  //     console.log('isHospitalGroupView true');
  //     const requestsUsers = requestsRef?.current?.Users?.length
  //       ? requestsRef?.current?.Users
  //       : [];
  //     setCurrentSelectedUsers(requestsUsers);

  //     const gridApps = dataGridAppsRef?.current?.instance?.getSelectedRowsData()
  //       .length
  //       ? dataGridAppsRef?.current?.instance
  //           ?.getSelectedRowsData()
  //           ?.map((item) => item.HospitalGroupId)
  //       : [];

  //     console.log('gridApps', gridApps);
  //     hospitalGroupDataGrid.selectRows(gridApps, true);
  //   } else {
  //     console.log('Users');
  //     const gridUsers = dataGridEmployeesRef?.current?.instance?.getSelectedRowsData()
  //       .length
  //       ? dataGridEmployeesRef?.current?.instance
  //           ?.getSelectedRowsData()
  //           ?.map((item) => item.UserId)
  //       : [];
  //     console.log('gridUsers', gridUsers);
  //     setCurrentSelectedUsers(gridUsers);

  //     const requestsApps = requestsRef?.current?.Apps?.length
  //       ? requestsRef?.current?.Apps
  //       : [];

  //     console.log('requestsApps', requestsApps);
  //     hospitalGroupDataGrid.selectRows(requestsApps, true);
  //   }
  // }

  function init() {
    setPopupVisible(true);
    const hospitalGroupDataGrid = hospitalGroupDataGridRef.current?.instance;

    if (!isHospitalGroupView) {
      setCurrentSelectedUsers([]);
    } else {
      hospitalGroupDataGrid.clearSelection();
    }

    const gridUsers = dataGridEmployeesRef?.current?.instance?.getSelectedRowsData()
      .length
      ? dataGridEmployeesRef?.current?.instance
          ?.getSelectedRowsData()
          ?.map((item) => item.UserId)
      : [];
    const requestsUsers = requestsRef?.current?.Users?.length
      ? requestsRef?.current?.Users
      : [];
    const usersSelected = [...new Set([...gridUsers, ...requestsUsers])];

    setCurrentSelectedUsers(usersSelected);

    const gridApps = dataGridAppsRef?.current?.instance?.getSelectedRowsData()
      .length
      ? dataGridAppsRef?.current?.instance
          ?.getSelectedRowsData()
          ?.map((item) => item.HospitalGroupId)
      : [];

    const requestsApps = requestsRef?.current?.Apps?.length
      ? requestsRef?.current?.Apps
      : [];
    const appsSelected = [...new Set([...gridApps, ...requestsApps])];

    hospitalGroupDataGrid.selectRows([...appsSelected], false);
  }

  useImperativeHandle(
    ref,
    () => ({
      handleShowPopup: () => init(),
    }),
    // eslint-disable-next-line
    [popupVisible]
  );

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      const hospitalsGroup = hospitalsGroupData
        ?.concat({
          HospitalGroupId: '00000000-0000-0000-0000-000000000000',
          HospitalGroupName: 'undefined',
        })
        ?.map((item) => ({
          ...item,
          isGroupSelected: false,
        }));
      setHospitalGroupList(hospitalsGroup);

      const trainingsToSave = [];

      if (trainingData?.length) {
        trainingData
          .filter((item) => item.Active)
          .forEach((training) => {
            if (training?.HospitalGroups?.length) {
              training.HospitalGroups.forEach((item) => {
                trainingsToSave.push({
                  ...training,
                  TemporaryId: training.TrainingId + item.HospitalGroupId,
                  HospitalGroupId: item.HospitalGroupId,
                  HospitalGroupName: item.HospitalGroupName,
                  ScheduledDate: '',
                  Show: false,
                  isSelected: false,
                });
              });
            } else {
              trainingsToSave.push({
                ...training,
                TemporaryId: training.TrainingId + '',
                HospitalGroupId: '',
                HospitalGroupName: '',
                ScheduledDate: '',
                Show: true,
                isSelected: false,
              });
            }
          });
      }

      setTrainingList(trainingsToSave);
    }

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, [hospitalsGroupData, trainingData]);

  const selectedUsers = allUsersList?.length
    ? [...allUsersList]?.filter((user) =>
        currentSelectedUsers.includes(user.UserId)
      )
    : [];

  const onClose = () => {
    setTrainingList((prev) =>
      prev.map((item) => ({ ...item, isSelected: false }))
    );
    setPopupVisible(false);
  };

  const handleSaveNewRequests = async () => {
    setSendEMRRequestsIsLoading(true);

    let existentRequest = '';

    const selectedHospitalsGroups = hospitalGroupDataGridRef.current.instance.getSelectedRowsData();

    const trainingsSelected = trainingList?.filter((item) => item.isSelected);
    if (
      selectedHospitalsGroups &&
      selectedHospitalsGroups.find(
        (item) => item.EMRContactId === null && item?.EMRRequestMethodId !== 2
      )
    ) {
      const selectedWithoutContact = selectedHospitalsGroups
        ?.filter((item) => item?.EMRContactId === null)
        .map((hospital) => hospital.HospitalGroupName);
      // console.log(
      //   selectedHospitalsGroups.find((h) => h.EMRRequestMethodId !== 2)
      // );
      setSendEMRRequestsIsLoading(false);
      return notify(
        `The following Okta tiles do not have a configured contact - [ ${selectedWithoutContact} ]`,
        'warning',
        4000
      );
    }
    if (!selectedHospitalsGroups.length) {
      notify('Select a Okta Tile', 'error', 4000);
      setSendEMRRequestsIsLoading(false);
      return;
    }
    if (trainingsSelected.length) {
      const error = trainingsSelected.filter((item) => !item.ScheduledDate);
      if (error.length) {
        notify(
          'You need to choose a Scheduled Date for each Training selected',
          'error',
          6000
        );
        setSendEMRRequestsIsLoading(false);
        return;
      }
    }

    const emrRequestsToSave = [];
    const trainingsToSave = [];

    selectedUsers.forEach((selectedUser) => {
      selectedHospitalsGroups.forEach((hospitalGroup) => {
        emrRequestsToSave.push({
          UserId: selectedUser.UserId,
          HospitalGroupId: hospitalGroup.HospitalGroupId,
          RequestDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
          AutoSend: hospitalGroup.AutoSend,
          EMRRequestMethodId: hospitalGroup.EMRRequestMethodId,
        });
      });

      trainingsSelected.forEach((training) => {
        if (training.HospitalGroupId) {
          trainingsToSave.push({
            UserId: selectedUser.UserId,
            TrainingId: training.TrainingId,
            ScheduledDate: training.ScheduledDate,
            HospitalGroupId: training.HospitalGroupId,
          });
        } else {
          trainingsToSave.push({
            UserId: selectedUser.UserId,
            TrainingId: training.TrainingId,
            ScheduledDate: training.ScheduledDate,
          });
        }
      });
    });

    try {
      const resRequest = await emrSaveRequests(emrRequestsToSave);
      if (resRequest?.data?.ErrorMsg) {
        if (resRequest.status !== 200) {
          throw new Error(resRequest?.data?.ErrorMsg);
        }

        if (
          resRequest?.data?.ErrorMsg.includes('The request already exist') ||
          resRequest?.data?.ErrorMsg.includes('All requests already exist')
        ) {
          notify(resRequest?.data?.ErrorMsg, 'warning', 4000);
          setSendEMRRequestsIsLoading(false);
          return;
        }
        existentRequest = resRequest?.data?.ErrorMsg.toLowerCase();
      }

      if (trainingsToSave.length) {
        // setSendEMRRequestsIsLoading(false);
        // setSendUserTrainingIsLoading(true);

        const resTraining = await saveUserTrainings(
          trainingsToSave.filter(
            (obj, index, self) =>
              index ===
              self.findIndex(
                (el) =>
                  el['TrainingId'] === obj['TrainingId'] &&
                  el['UserId'] === obj['UserId'] &&
                  el['HospitalGroupId'] === obj['HospitalGroupId']
              )
          )
        );

        let trainingError = '';

        if (resTraining?.status !== 200) {
          throw new Error(resRequest?.message);
        }

        if (resTraining?.data[0]?.ErrorMsg) {
          trainingError = existentRequest
            ? `${existentRequest} and ${resTraining?.data[0]?.ErrorMsg}`
            : resTraining?.data[0]?.ErrorMsg;
        } else {
          trainingError = existentRequest;
        }

        notify(
          `Requests generated with success. ${
            trainingError ? `But, ${trainingError?.toLowerCase()}` : ''
          }`,
          'success',
          8000
        );
        setCurrentSelectedUsers([]);
        setSendEMRRequestsIsLoading(false);
        onClose();
      } else {
        notify(
          `Requests generated with success. ${
            existentRequest ? `But, ${existentRequest}` : ''
          }`,
          'success',
          8000
        );
        setCurrentSelectedUsers([]);
        setSendEMRRequestsIsLoading(false);
        onClose();
      }
      refetch();
    } catch (e) {
      setSendEMRRequestsIsLoading(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const handleTagBoxChange = (e) => {
    setCurrentSelectedUsers(e.value);
  };

  useEffect(() => {
    trainingDataGridRef.current.instance.refresh();
  }, [trainingList]);

  const handleSelectGroup = ({
    currentSelectedRowKeys,
    currentDeselectedRowKeys,
  }) => {
    const updatedTrainingList = trainingList.map((training) => {
      if (currentSelectedRowKeys.includes(training.HospitalGroupId)) {
        return { ...training, isSelected: true, Show: true };
      }
      if (currentDeselectedRowKeys.includes(training.HospitalGroupId)) {
        return {
          ...training,
          isSelected: false,
          ScheduledDate: '',
          Show: false,
        };
      }
      return training;
    });
    setTrainingList(updatedTrainingList);

    // const updatedTrainingList = trainingList.map((training) => {
    //   let isIncludesSelected = false;
    //   let isIncludesDeselected = false;
    //   training.HospitalGroups.forEach((item) => {
    //     if (currentSelectedRowKeys.includes(item.HospitalGroupId)) {
    //       isIncludesSelected = true;
    //     }
    //   });

    //   if (isIncludesSelected) {
    //     return { ...training, isSelected: true, Show: true };
    //   }

    //   training.HospitalGroups.forEach((item) => {
    //     if (currentDeselectedRowKeys.includes(item.HospitalGroupId)) {
    //       isIncludesDeselected = true;
    //     }
    //   });
    //   if (isIncludesDeselected) {
    //     return {
    //       ...training,
    //       isSelected: false,
    //       ScheduledDate: '',
    //       Show: false,
    //     };
    //   }
    //   return training;
    // });
    // setTrainingList(updatedTrainingList);
  };

  const handleSelectTrainings = ({
    currentSelectedRowKeys,
    currentDeselectedRowKeys,
  }) => {
    const newTrainingList = trainingList.map((training) => {
      if (currentSelectedRowKeys.includes(training.TemporaryId)) {
        return { ...training, isSelected: true, ScheduledDate: new Date() };
        // return { ...training, isSelected: true };
      }
      if (currentDeselectedRowKeys.includes(training.TemporaryId)) {
        return { ...training, isSelected: false, ScheduledDate: '' };
      }
      return training;
    });
    setTrainingList(newTrainingList);
  };

  const inputDateCell = (eRow) => {
    const setDate = (eDate) => {
      if (eDate.value) {
        setTrainingList((prev) =>
          prev.map((item) =>
            item.TemporaryId === eRow.data.TemporaryId
              ? { ...item, ScheduledDate: eDate.value }
              : item
          )
        );
      }
    };
    return (
      <DateBoxStyled
        height={28}
        value={eRow?.data?.ScheduledDate}
        min={new Date()}
        visible={eRow?.data?.isSelected}
        placeholder="00/00/0000"
        useMaskBehavior
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
        deferRendering
        onValueChanged={(e) => setDate(e)}
        pickerType="calendar"
      />
    );
  };

  const buttonOptions = [
    {
      type: 'default',
      text: 'Generate Requests',
      onClick: handleSaveNewRequests,
      disabled: !currentSelectedUsers.length,
    },
    {
      type: 'normal',
      text: 'Cancel',
      onClick: onClose,
    },
  ];

  return (
    <Popup
      visible={popupVisible}
      onHiding={() => onClose()}
      title="New Request"
      disabled={sendEMRRequestsIsLoading}
      height="90%"
      width="95%"
      maxWidth={1400}
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={sendEMRRequestsIsLoading}
        message="Generating requests..."
        closeOnOutsideClick={false}
      />
      {/* <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={sendUserTrainingIsLoading}
        message="Creating User Trainings..."
        closeOnOutsideClick={false}
      /> */}
      <Container>
        <FieldSet>
          <label title="New Request(s) for Employee:">
            New Requests for Staff:
          </label>
          <TagBox
            width="100%"
            dataSource={allUsersList}
            searchEnabled
            searchExpr="DisplayName"
            value={currentSelectedUsers}
            onValueChanged={handleTagBoxChange}
            valueExpr="UserId"
            displayExpr="DisplayName"
            multiline={false}
            maxDisplayedTags={7}
          />
        </FieldSet>

        <WrapperGrids>
          <GridContainer $width="40%">
            <label>Okta Tiles:</label>

            <Grid>
              <DataGrid
                // disabled={!currentSelectedUsers?.length}
                ref={hospitalGroupDataGridRef}
                keyExpr="HospitalGroupId"
                dataSource={hospitalGroupList
                  ?.filter((item) => item.HospitalGroupName !== 'undefined')
                  ?.filter((item) => item.EMRRequestMethodId !== 0)}
                height="100%"
                showBorders
                selection={{ mode: 'multiple', showCheckBoxesMode: 'always' }}
                filterRow={{ visible: true, applyFilter: 'auto' }}
                sorting={{ mode: 'multiple' }}
                rowAlternationEnabled
                editing={{
                  mode: 'cell',
                  allowUpdating: ({ row }) =>
                    row.data.EMRRequestMethodId === 1 ||
                    row.data.EMRRequestMethodId === 3,
                }}
                onSelectionChanged={handleSelectGroup}
              >
                <Paging enabled={false} />
                <Column
                  allowEditing={false}
                  dataField="HospitalGroupName"
                  caption="Okta Tile"
                />
                <Column
                  allowEditing={false}
                  dataField="EMRRequestMethodId"
                  caption="Request Method"
                >
                  <Lookup
                    dataSource={emrRequestMethodList}
                    valueExpr="EMRRequestMethodId"
                    displayExpr="EMRRequestMethodName"
                  />
                </Column>
                {/* <Column
              dataField="AutoSend"
              caption="Auto Send Request"
              allowEditing
            /> */}
              </DataGrid>
            </Grid>
          </GridContainer>

          <GridContainer $width="60%">
            <label>Trainings:</label>

            <Grid>
              <DataGrid
                ref={trainingDataGridRef}
                dataSource={trainingList?.filter((item) => item.Show)}
                height="100%"
                showBorders
                selection={{
                  mode: 'multiple',
                  showCheckBoxesMode: 'always',
                }}
                rowAlternationEnabled
                filterRow={{ visible: true, applyFilter: 'auto' }}
                sorting={{ mode: 'multiple' }}
                onSelectionChanged={handleSelectTrainings}
                keyExpr="TemporaryId"
                selectedRowKeys={trainingList
                  ?.filter((item) => item.isSelected)
                  ?.map((item) => item.TemporaryId)}
              >
                <Paging enabled={false} />
                <Column dataField="TrainingDesc" caption="Training Name" />
                <Column
                  dataField="IsPortal"
                  caption="Portal"
                  allowEditing={false}
                  width={80}
                />
                <Column dataField="HospitalGroupName" caption="Okta Tile" />
                {/* <Column
                  // dataField="HospitalGroupName"
                  caption="Okta Tile"
                  cellRender={(data) => {
                    return data.data.HospitalGroups.map((item) => (
                      <div key={item.HospitalGroupId}>
                        {item.HospitalGroupName}
                      </div>
                    ));
                  }}
                /> */}
                <Column
                  dataField="ScheduledDate"
                  caption="Scheduled Date"
                  cellRender={inputDateCell}
                />
              </DataGrid>
            </Grid>
          </GridContainer>
        </WrapperGrids>
      </Container>
      {buttonOptions.map((options, index) => (
        <ToolbarItem
          key={index}
          options={options}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
};

export default forwardRef(NewRequestPopup);
