import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import TextBox from 'devextreme-react/text-box';
import Button from '@material-ui/core/Button';
import List from 'devextreme-react/list';
import useStyles, { NJGrid } from './styles.js';
import clone from '../../../helpers/clone';
//import PersonInfo from './personInfo';

let appointmentTypesForm = [];

export default function ScheduleForm(props) {
  const {
    saveAppointments,
    setPopupVisible,
    selectedDate,
    typeChanged,
    appointmentTypes,
    contacts,
  } = props;
  const [selectedId, setSelectedId] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const classes = useStyles();

  const GroupTemplate = (item) => {
    var subject = appointmentTypes.find((t) => t.id === item.key).text;

    return <div>{subject}</div>;
  };

  // carrega o tagbox com os ids existentes
  const handleAppointmentTypeValueChanged = (event) => {
    if (event.name !== 'selectedItem') return;

    if (!event.value) return;

    if (selectedId !== event.value.id) {
      var items = [];

      if (event.value.id !== -1) {
        var type = typeChanged.find((t) => t.key === event.value.id);

        items = contacts.filter(
          (c) =>
            type.items.filter((t) => t.ContactId === c.ContactId).length > 0
        );
      }

      setSelectedId(event.value.id);
      setSelectedContacts(items);
    }
  };

  // atualiza o objeto com os ids selecionados
  const handleContactValueChanged = (event) => {
    if (event.name !== 'selectedItems') {
      return;
    }

    var type = typeChanged.find((t) => t.key === selectedId);

    if (!type) return;

    var items = contacts
      .filter((c) =>
        event.value
          .map((ci) => {
            return ci.ContactId;
          })
          .includes(c.ContactId)
      )
      .map((c) => {
        return {
          ...c,
          AppointmentTypeId: selectedId,
        };
      });

    type.items = items;
    setSelectedContacts(event.value);
  };

  const handleItemDeleting = (event) => {
    var type = typeChanged.find((t) => t.key === event.itemData.key);

    if (!type) return;

    var index = type.items.findIndex(
      (item) => item.ContactId === event.itemData.ContactId
    );
    type.items.splice(index, 1);
  };

  useEffect(() => {
    var item = { id: -1, text: 'All' };
    appointmentTypesForm = clone(appointmentTypes);
    appointmentTypesForm.splice(0, 0, item);

    setSelectedId(-1); //eslint-disable-next-line
  }, []);

  return (
    <div>
      <NJGrid container spacing={2}>
        <Grid item xs={6}>
          <TextBox
            readOnly={true}
            hoverStateEnabled={false}
            value={'Date ' + format(new Date(selectedDate), 'MM/dd/yyyy')}
          />
          <List
            className={classes.padding}
            dataSource={appointmentTypesForm}
            height={parseInt(window.innerHeight * 0.8) - 180}
            displayExpr="text"
            valueExpr="id"
            showSelectionControls={false}
            selectionMode="single"
            selectedItems={[
              appointmentTypesForm.find((a) => a.id === selectedId),
            ]}
            onOptionChanged={handleAppointmentTypeValueChanged}
          ></List>
        </Grid>
        <Grid item xs={6}>
          <TextBox readOnly={true} hoverStateEnabled={false} value="Contacts" />
          <List
            className={classes.padding}
            dataSource={
              selectedId === -1
                ? typeChanged.filter((t) => t.items.length > 0)
                : contacts
            }
            height={parseInt(window.innerHeight * 0.8) - 200}
            displayExpr="FullName"
            valueExpr="ContactId"
            onOptionChanged={handleContactValueChanged}
            grouped={selectedId === -1}
            collapsibleGroups={false}
            allowItemDeleting={selectedId === -1}
            showSelectionControls={selectedId !== -1}
            selectionMode="multiple"
            selectedItems={selectedContacts}
            itemDeleteMode={'static'}
            groupRender={GroupTemplate}
            onItemDeleting={handleItemDeleting}
            //itemRender={PersonInfo}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="flex-start"
            justify="flex-end"
            direction="row"
          >
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                saveAppointments();
                setSelectedId(-1);
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                setPopupVisible(false);
                setSelectedId(-1);
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </NJGrid>
    </div>
  );
}
