import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config';
import Common from './Common';

export default class ImagesAPI {
  static async sendRequest(
    uri,
    method,
    data,
    throwErrors = true,
    customOptions = {}
  ) {
    let response;
    try {
      const options = {
        method,
        data,
      };

      if (Cookies.get('token')) {
        if (!Common.checkToken()) {
          throw new Error('Your session has expired.');
        }

        options.headers = {
          authorization: `Bearer ${Cookies.get('token')}`,
        };
      }

      if (method === 'post') {
        const postConfig = { 'Content-Type': 'multipart/form-data' };
        options.headers = { ...options.headers, ...postConfig };
      }

      if (customOptions.headers) {
        options.headers = { ...options.headers, ...customOptions.headers };
      }

      response = (await axios.request(`${config.imagesUrl}/${uri}`, options))
        .data;

      if (
        response.ErrorMsg !== undefined &&
        response.ErrorMsg !== null &&
        response.ErrorMsg !== ''
      ) {
        if (throwErrors) {
          throw new Error(response.ErrorMsg);
        }

        return {
          data: response ? response.data : {},
          message: response.ErrorMsg,
          status: 500,
        };
      }

      // Return OK
      return { data: response, status: 200 };
    } catch (e) {
      let message = 'Error on ImagesAPI';

      if (e.response) {
        if (e.response.data) {
          if (e.response.data.errors) {
            message = Object.values(e.response.data.errors)[0];
          }
          if (e.response.data.message) {
            message = e.response.data.message;
          }
          if (e.response.data.ErrorMsg) {
            message = e.response.data.ErrorMsg;
          }
        }
      } else {
        if (e.message) {
          message = e.message;
        }
      }

      if (throwErrors) {
        throw new Error(message);
      }

      return {
        data: e.response ? e.response.data : {},
        message,
        status: 500,
      };
    }
  }
}
