import { string } from 'prop-types';
import React from 'react';
import { AvatarContainer, Container } from './styles';

function ProviderAvatar({ providerCode }) {
  return (
    <Container>
      <AvatarContainer>
        <i className="fas fa-hospital" />
        <span>{providerCode}</span>
      </AvatarContainer>
    </Container>
  );
}

ProviderAvatar.propTypes = {
  providerCode: string.isRequired,
};

export default ProviderAvatar;
