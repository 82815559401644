import React from 'react';

export function getArchiveIcon(url) {
  if (url.includes('.pdf')) {
    return (
      <svg
        id="pdf-svgrepo-com"
        xmlns="http://www.w3.org/2000/svg"
        width="163.216"
        height="186.822"
        viewBox="0 0 163.216 186.822"
      >
        <path
          id="Caminho_73"
          data-name="Caminho 73"
          d="M107.658,0A11.7,11.7,0,0,0,96,11.676V175.146a11.7,11.7,0,0,0,11.658,11.676H224.241A11.7,11.7,0,0,0,235.9,175.146V46.706L189.266,0Z"
          transform="translate(-72.684)"
          fill="#e2e5e7"
        />
        <path
          id="Caminho_74"
          data-name="Caminho 74"
          d="M363.658,46.633h34.975L352,0V34.975A11.693,11.693,0,0,0,363.658,46.633Z"
          transform="translate(-235.417)"
          fill="#b0b7bd"
        />
        <path
          id="Caminho_75"
          data-name="Caminho 75"
          d="M418.975,162.975,384,128h34.975Z"
          transform="translate(-255.759 -81.278)"
          fill="#cad1d8"
        />
        <path
          id="Caminho_76"
          data-name="Caminho 76"
          d="M171.9,304.12a5.846,5.846,0,0,1-5.829,5.829H37.829A5.846,5.846,0,0,1,32,304.12V245.829A5.846,5.846,0,0,1,37.829,240H166.07a5.846,5.846,0,0,1,5.829,5.829Z"
          transform="translate(-32 -152.345)"
          fill="#f15642"
        />
        <g
          id="Grupo_180"
          data-name="Grupo 180"
          transform="translate(25.409 107.429)"
        >
          <path
            id="Caminho_77"
            data-name="Caminho 77"
            d="M101.744,297.538a3.207,3.207,0,0,1,3.165-3.218h10.766c6.062,0,11.518,4.057,11.518,11.833,0,7.368-5.456,11.472-11.518,11.472h-7.782v6.156a2.926,2.926,0,0,1-2.984,3.212,3.14,3.14,0,0,1-3.165-3.212Zm6.15,2.652V311.8h7.782a5.81,5.81,0,0,0,0-11.611Z"
            transform="translate(-101.744 -294.32)"
            fill="#fff"
          />
          <path
            id="Caminho_78"
            data-name="Caminho 78"
            d="M191.042,327.724c-1.539,0-3.218-.839-3.218-2.885V298.363a3.17,3.17,0,0,1,3.218-2.891h10.673c21.3,0,20.833,32.252.42,32.252Zm2.938-26.563v20.88h7.735c12.585,0,13.145-20.88,0-20.88Z"
            transform="translate(-156.463 -295.052)"
            fill="#fff"
          />
          <path
            id="Caminho_79"
            data-name="Caminho 79"
            d="M293.233,301.524v7.409h11.886a3.6,3.6,0,0,1,3.358,3.305c0,1.539-1.679,2.8-3.358,2.8H293.233v9.787a2.761,2.761,0,0,1-2.792,2.885c-2.052,0-3.305-1.253-3.305-2.885V298.347c0-1.673,1.259-2.891,3.305-2.891H306.8c2.052,0,3.264,1.218,3.264,2.891a3.22,3.22,0,0,1-3.264,3.171h-13.57Z"
            transform="translate(-219.594 -295.042)"
            fill="#fff"
          />
        </g>
        <path
          id="Caminho_80"
          data-name="Caminho 80"
          d="M206.753,421.829H96v5.829H206.753a5.846,5.846,0,0,0,5.829-5.829V416A5.846,5.846,0,0,1,206.753,421.829Z"
          transform="translate(-72.684 -264.153)"
          fill="#cad1d8"
        />
      </svg>
    );
  }
  if (url.includes('.doc')) {
    return (
      <svg
        id="doc-svgrepo-com"
        xmlns="http://www.w3.org/2000/svg"
        width="163.216"
        height="186.822"
        viewBox="0 0 163.216 186.822"
      >
        <path
          id="Caminho_65"
          data-name="Caminho 65"
          d="M107.658,0A11.7,11.7,0,0,0,96,11.676V175.146a11.7,11.7,0,0,0,11.658,11.676H224.241A11.7,11.7,0,0,0,235.9,175.146V46.706L189.266,0Z"
          transform="translate(-72.684)"
          fill="#e2e5e7"
        />
        <path
          id="Caminho_66"
          data-name="Caminho 66"
          d="M363.658,46.633h34.975L352,0V34.975A11.693,11.693,0,0,0,363.658,46.633Z"
          transform="translate(-235.417)"
          fill="#b0b7bd"
        />
        <path
          id="Caminho_67"
          data-name="Caminho 67"
          d="M418.975,162.975,384,128h34.975Z"
          transform="translate(-255.759 -81.278)"
          fill="#cad1d8"
        />
        <path
          id="Caminho_68"
          data-name="Caminho 68"
          d="M171.9,304.12a5.846,5.846,0,0,1-5.829,5.829H37.829A5.846,5.846,0,0,1,32,304.12V245.829A5.846,5.846,0,0,1,37.829,240H166.07a5.846,5.846,0,0,1,5.829,5.829Z"
          transform="translate(-32 -152.345)"
          fill="#50bee8"
        />
        <g
          id="Grupo_179"
          data-name="Grupo 179"
          transform="translate(18.851 106.683)"
        >
          <path
            id="Caminho_69"
            data-name="Caminho 69"
            d="M86.962,327.724a2.921,2.921,0,0,1-3.218-2.891v-26.47a3.166,3.166,0,0,1,3.218-2.891H97.635c21.3,0,20.827,32.252.414,32.252ZM89.9,301.155v20.88h7.735c12.585,0,13.139-20.88,0-20.88Z"
            transform="translate(-83.744 -294.306)"
            fill="#fff"
          />
          <path
            id="Caminho_70"
            data-name="Caminho 70"
            d="M197.335,326.157c-8.621.373-17.575-5.363-17.575-16.782,0-11.466,8.948-17.1,17.575-17.1,8.155.414,16.683,6.056,16.683,17.1S205.49,326.157,197.335,326.157Zm-.513-27.822a11.05,11.05,0,0,0,0,22.1c5.363,0,11.093-3.731,11.093-11.052C207.915,302.012,202.185,298.334,196.822,298.334Z"
            transform="translate(-144.779 -292.272)"
            fill="#fff"
          />
          <path
            id="Caminho_71"
            data-name="Caminho 71"
            d="M288.848,309.9c0-8.994,5.643-16.73,16.363-16.73a15.231,15.231,0,0,1,10.673,4.15,3.022,3.022,0,1,1-4.15,4.378,8.044,8.044,0,0,0-6.523-2.559c-7.176,0-10.621,5.083-10.621,10.767,0,5.782,3.4,11.093,10.621,11.093a10.886,10.886,0,0,0,7.275-2.985,3.129,3.129,0,0,1,4.284.56,3.215,3.215,0,0,1-.513,4.378c-3.264,3.037-7.176,3.643-11.052,3.643C293.931,326.592,288.848,318.892,288.848,309.9Z"
            transform="translate(-214.124 -292.842)"
            fill="#fff"
          />
        </g>
        <path
          id="Caminho_72"
          data-name="Caminho 72"
          d="M206.753,421.829H96v5.829H206.753a5.846,5.846,0,0,0,5.829-5.829V416A5.846,5.846,0,0,1,206.753,421.829Z"
          transform="translate(-72.684 -264.153)"
          fill="#cad1d8"
        />
      </svg>
    );
  }
  if (url.includes('.xls')) {
    return (
      <svg
        id="xls-svgrepo-com"
        xmlns="http://www.w3.org/2000/svg"
        width="163.216"
        height="186.822"
        viewBox="0 0 163.216 186.822"
      >
        <path
          id="Caminho_81"
          data-name="Caminho 81"
          d="M107.658,0A11.7,11.7,0,0,0,96,11.676V175.146a11.7,11.7,0,0,0,11.658,11.676H224.241A11.7,11.7,0,0,0,235.9,175.146V46.706L189.266,0Z"
          transform="translate(-72.684)"
          fill="#e2e5e7"
        />
        <path
          id="Caminho_82"
          data-name="Caminho 82"
          d="M363.658,46.633h34.975L352,0V34.975A11.693,11.693,0,0,0,363.658,46.633Z"
          transform="translate(-235.417)"
          fill="#b0b7bd"
        />
        <path
          id="Caminho_83"
          data-name="Caminho 83"
          d="M418.975,162.975,384,128h34.975Z"
          transform="translate(-255.759 -81.278)"
          fill="#cad1d8"
        />
        <path
          id="Caminho_84"
          data-name="Caminho 84"
          d="M171.9,304.12a5.846,5.846,0,0,1-5.829,5.829H37.829A5.846,5.846,0,0,1,32,304.12V245.829A5.846,5.846,0,0,1,37.829,240H166.07a5.846,5.846,0,0,1,5.829,5.829Z"
          transform="translate(-32 -152.345)"
          fill="#84bd5a"
        />
        <g
          id="Grupo_181"
          data-name="Grupo 181"
          transform="translate(26.678 106.368)"
        >
          <path
            id="Caminho_85"
            data-name="Caminho 85"
            d="M119.474,306.391l8.108-10.16c2.425-3.171,7.135.88,4.477,3.911-2.792,3.311-5.73,6.9-8.528,10.58l9.507,11.839c2.565,3.5-2.559,6.849-5.077,3.4l-8.58-11L110.946,326.2c-2.378,3.4-7.648-.42-4.99-3.591l9.367-11.886c-2.938-3.684-5.782-7.269-8.621-10.58-2.932-3.5,2.518-7.082,4.663-3.812Z"
            transform="translate(-105.226 -293.718)"
            fill="#fff"
          />
          <path
            id="Caminho_86"
            data-name="Caminho 86"
            d="M197.36,298.189a2.907,2.907,0,0,1,2.938-2.845,2.842,2.842,0,0,1,2.751,2.845V321.54h12.678c4.564,0,4.663,6.1,0,6.1H200.3a2.815,2.815,0,0,1-2.938-2.839V298.189Z"
            transform="translate(-163.794 -293.91)"
            fill="#fff"
          />
          <path
            id="Caminho_87"
            data-name="Caminho 87"
            d="M271.088,299.883c1.073-9.047,14.724-10.673,21.16-5.73,3.171,2.559-.187,6.616-2.985,4.564-3.451-2.186-11.279-3.212-12.259,1.626-1.259,7.648,19.015,3.27,18.688,15.669-.326,11.839-17.476,12.113-23.911,6.8a3.445,3.445,0,0,1-.653-4.57c1.212-1.206,2.559-1.626,4.15-.32,3.818,2.605,13.658,4.564,14.351-2.058C289.03,308.976,269.642,313.121,271.088,299.883Z"
            transform="translate(-210.317 -291.407)"
            fill="#fff"
          />
        </g>
        <path
          id="Caminho_88"
          data-name="Caminho 88"
          d="M206.753,421.829H96v5.829H206.753a5.846,5.846,0,0,0,5.829-5.829V416A5.846,5.846,0,0,1,206.753,421.829Z"
          transform="translate(-72.684 -264.153)"
          fill="#cad1d8"
        />
      </svg>
    );
  }

  if (url.includes('.png')) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="163.681"
        height="187.064"
        viewBox="0 0 163.681 187.064"
      >
        <g id="png-svgrepo-com" transform="translate(-32)">
          <path
            id="Caminho_97"
            data-name="Caminho 97"
            d="M107.691,0A11.726,11.726,0,0,0,96,11.691V175.372a11.726,11.726,0,0,0,11.691,11.691H224.606A11.726,11.726,0,0,0,236.3,175.372V46.766L189.532,0Z"
            transform="translate(-40.617)"
            fill="#e2e5e7"
          />
          <path
            id="Caminho_98"
            data-name="Caminho 98"
            d="M363.691,46.766h35.074L352,0V35.074A11.726,11.726,0,0,0,363.691,46.766Z"
            transform="translate(-203.085)"
            fill="#b0b7bd"
          />
          <path
            id="Caminho_99"
            data-name="Caminho 99"
            d="M419.074,163.074,384,128h35.074Z"
            transform="translate(-223.394 -81.234)"
            fill="#cad1d8"
          />
          <path
            id="Caminho_100"
            data-name="Caminho 100"
            d="M172.3,304.3a5.863,5.863,0,0,1-5.846,5.846H37.846A5.863,5.863,0,0,1,32,304.3V245.846A5.863,5.863,0,0,1,37.846,240H166.452a5.863,5.863,0,0,1,5.846,5.846Z"
            transform="translate(0 -152.314)"
            fill="#a066aa"
          />
          <g
            id="Grupo_183"
            data-name="Grupo 183"
            transform="translate(54.22 107.118)"
          >
            <path
              id="Caminho_101"
              data-name="Caminho 101"
              d="M92.816,297.537A3.217,3.217,0,0,1,95.99,294.3h10.8c6.074,0,11.545,4.069,11.545,11.873,0,7.389-5.472,11.5-11.545,11.5H98.977v6.173a2.929,2.929,0,0,1-2.987,3.227,3.151,3.151,0,0,1-3.174-3.227Zm6.161,2.66v11.639h7.81a5.823,5.823,0,0,0,0-11.639Z"
              transform="translate(-92.816 -293.895)"
              fill="#fff"
            />
            <path
              id="Caminho_102"
              data-name="Caminho 102"
              d="M178.966,298c0-1.689.374-3.32,2.806-3.32,1.678,0,2.058.421,3.315,1.631l15.468,19.355V297.537A3.29,3.29,0,0,1,203.5,294.3a3.56,3.56,0,0,1,3.315,3.233v26.312a3.121,3.121,0,0,1-2.479,3.227,4.7,4.7,0,0,1-3.782-1.631l-15.468-19.636v18.046a2.9,2.9,0,0,1-2.946,3.227,3.077,3.077,0,0,1-3.18-3.227V298Z"
              transform="translate(-147.486 -293.895)"
              fill="#fff"
            />
            <path
              id="Caminho_103"
              data-name="Caminho 103"
              d="M302.314,322.769a17.74,17.74,0,0,1-11.5,3.928c-9.821,0-16.783-5.612-16.783-16.736,0-9.441,7.342-16.777,17.2-16.777a15.306,15.306,0,0,1,10.657,4.115c2.853,2.8-1.263,6.962-4.063,4.49a9.851,9.851,0,0,0-6.594-2.946,11.138,11.138,0,0,0-11.119,11.119c0,6.921,4.577,11.124,10.7,11.124a11.893,11.893,0,0,0,7.009-2.1v-5.741h-7.009c-4.162,0-3.741-5.711,0-5.711h9.347c1.73,0,3.315,1.315,3.315,2.765v9.955A3.775,3.775,0,0,1,302.314,322.769Z"
              transform="translate(-207.823 -293.184)"
              fill="#fff"
            />
          </g>
          <path
            id="Caminho_104"
            data-name="Caminho 104"
            d="M207.069,421.846H96v5.846H207.069a5.863,5.863,0,0,0,5.846-5.846V416A5.863,5.863,0,0,1,207.069,421.846Z"
            transform="translate(-40.617 -264.011)"
            fill="#cad1d8"
          />
        </g>
      </svg>
    );
  }
  if (url.includes('.jpg')) {
    return (
      <svg
        id="jpg-svgrepo-com"
        xmlns="http://www.w3.org/2000/svg"
        width="163.216"
        height="186.822"
        viewBox="0 0 163.216 186.822"
      >
        <path
          id="Caminho_89"
          data-name="Caminho 89"
          d="M107.658,0A11.7,11.7,0,0,0,96,11.676V175.146a11.7,11.7,0,0,0,11.658,11.676H224.241A11.7,11.7,0,0,0,235.9,175.146V46.706L189.266,0Z"
          transform="translate(-72.684)"
          fill="#e2e5e7"
        />
        <path
          id="Caminho_90"
          data-name="Caminho 90"
          d="M363.658,46.633h34.975L352,0V34.975A11.693,11.693,0,0,0,363.658,46.633Z"
          transform="translate(-235.417)"
          fill="#b0b7bd"
        />
        <path
          id="Caminho_91"
          data-name="Caminho 91"
          d="M418.975,162.975,384,128h34.975Z"
          transform="translate(-255.759 -81.278)"
          fill="#cad1d8"
        />
        <path
          id="Caminho_92"
          data-name="Caminho 92"
          d="M171.9,304.12a5.846,5.846,0,0,1-5.829,5.829H37.829A5.846,5.846,0,0,1,32,304.12V245.829A5.846,5.846,0,0,1,37.829,240H166.07a5.846,5.846,0,0,1,5.829,5.829Z"
          transform="translate(-32 -152.345)"
          fill="#50bee8"
        />
        <g
          id="Grupo_182"
          data-name="Grupo 182"
          transform="translate(23.824 107.022)"
        >
          <path
            id="Caminho_93"
            data-name="Caminho 93"
            d="M113.633,298.026c0-3.917,6.156-3.917,6.156,0v18.408c0,7.321-3.5,11.752-11.559,11.752-3.964,0-7.269-1.078-10.16-4.8-2.378-2.845,2.1-6.943,4.523-3.964a6.935,6.935,0,0,0,6.1,2.891c2.606-.093,4.9-1.265,4.943-5.876V298.026Z"
            transform="translate(-97.394 -294.4)"
            fill="#fff"
          />
          <path
            id="Caminho_94"
            data-name="Caminho 94"
            d="M181.344,297.538a3.208,3.208,0,0,1,3.171-3.218h10.766c6.062,0,11.518,4.057,11.518,11.833,0,7.368-5.456,11.472-11.518,11.472H187.5v6.156a2.926,2.926,0,0,1-2.985,3.212,3.145,3.145,0,0,1-3.171-3.212V297.538Zm6.156,2.652V311.8h7.782a5.81,5.81,0,0,0,0-11.611Z"
            transform="translate(-150.759 -293.912)"
            fill="#fff"
          />
          <path
            id="Caminho_95"
            data-name="Caminho 95"
            d="M293.369,322.7a17.658,17.658,0,0,1-11.466,3.917c-9.793,0-16.735-5.59-16.735-16.689,0-9.408,7.321-16.724,17.149-16.724a15.258,15.258,0,0,1,10.627,4.1c2.839,2.792-1.259,6.942-4.051,4.477a9.85,9.85,0,0,0-6.575-2.938,11.106,11.106,0,0,0-11.087,11.087c0,6.9,4.564,11.1,10.673,11.1a11.9,11.9,0,0,0,6.989-2.1v-5.73H281.9c-4.15,0-3.73-5.689,0-5.689h9.321c1.725,0,3.305,1.306,3.305,2.751v9.927A3.753,3.753,0,0,1,293.369,322.7Z"
            transform="translate(-204.044 -293.2)"
            fill="#fff"
          />
        </g>
        <path
          id="Caminho_96"
          data-name="Caminho 96"
          d="M206.753,421.829H96v5.829H206.753a5.846,5.846,0,0,0,5.829-5.829V416A5.846,5.846,0,0,1,206.753,421.829Z"
          transform="translate(-72.684 -264.153)"
          fill="#cad1d8"
        />
      </svg>
    );
  }

  return (
    <svg
      id="jpg-svgrepo-com"
      xmlns="http://www.w3.org/2000/svg"
      width="139.899"
      height="186.822"
      viewBox="0 0 139.899 186.822"
    >
      <path
        id="Caminho_89"
        data-name="Caminho 89"
        d="M107.658,0A11.7,11.7,0,0,0,96,11.676V175.146a11.7,11.7,0,0,0,11.658,11.676H224.241A11.7,11.7,0,0,0,235.9,175.146V46.706L189.266,0Z"
        transform="translate(-96)"
        fill="#e2e5e7"
      />
      <path
        id="Caminho_90"
        data-name="Caminho 90"
        d="M363.658,46.633h34.975L352,0V34.975A11.693,11.693,0,0,0,363.658,46.633Z"
        transform="translate(-258.734)"
        fill="#b0b7bd"
      />
      <path
        id="Caminho_91"
        data-name="Caminho 91"
        d="M418.975,162.975,384,128h34.975Z"
        transform="translate(-279.076 -81.278)"
        fill="#cad1d8"
      />
    </svg>
  );
}
