/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import {
  format,
  isPast as checkIsPast,
  isToday as checkIsToday,
  parseISO,
} from 'date-fns';

import clone from '../../../../helpers/clone';
import { returnTwoDigits } from '../../../../helpers/twoDigits';
import CellContent from '../CellContent';
import notify from 'devextreme/ui/notify';
import { Container } from './styles';
import CellTotal from '../CellTotal';
import generateUUID from 'helpers/uuid';

let tasks;

const Cell = (props) => {
  const {
    source,
    currentDate,
    save,
    contacts,
    handleCellClick,
    setContacts,
    ACL,
    availabilityList,
    periodsList,
    holidaysMonth,
  } = props;



  const [canDrop, setCanDrop] = useState(false);
  const [periodColor, setPeriodColor] = useState('#D8D8D8');

  const yearMonth = format(currentDate, 'yyyy-MM');
  const day = source.column.dataField ? source.column.dataField : '';

  const now = new Date();

  const isWeekend = '-Sat-Sun'.includes(day.substr(day.length - 4));

  const cellDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    parseInt(day.replace(/\D+/g, ''))
  );

  const currentDateIso = cellDate.toISOString().slice(0, -14);
  const isHoliday = holidaysMonth.includes(String(currentDateIso));

  const isToday = checkIsToday(cellDate);
  const isPast = checkIsPast(cellDate);


  // const isLOA = source.displayValue !== null && JSON.parse(source.displayValue)[0]?.Sb === "LOA";


  const cellId = `${yearMonth}-${day}#${source.data?.ContactId}`;

  useEffect(() => {
    if (day) {
      const formattedDate = format(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          returnTwoDigits(day.substr(0, day.indexOf('-')))
        ),
        'yyyy-MM-dd'
      );

      const availability = availabilityList.find(
        (item) =>
          item.UserId === source.data?.ContactId &&
          parseISO(formattedDate) >= parseISO(item.StartDate) &&
          parseISO(formattedDate) <= parseISO(item.EndDate)
      );

      if (availability) {
        const period = periodsList.find(
          (item) => item.PeriodId === availability.PeriodId
        );
        setPeriodColor(period.PeriodColor);
      }
    }
  }, []); //eslint-disable-line

  const handleDrop = (cellProps) => {
    if (!ACL.Update) {
      return;
    }



    // formatSelectedCell begin
    let item = {};

    if (cellProps.source.value) {
      const itemArray = JSON.parse(cellProps.source.value);

      item = Array.isArray(itemArray)
        ? itemArray.find((op) => op.Id === cellProps.id)
        : itemArray;
    }

    item.day = cellProps.source.column.dataField
      ? cellProps.source.column.dataField
      : '';
    item.contact = cellProps.source.data;
    // formatSelectedCell end

    let saveItem = item;
    const oldContactId = saveItem.contact.ContactId;
    const oldDay = cellProps.source.column.dataField
      ? cellProps.source.column.dataField
      : '';

    saveItem.contact = source.data;
    saveItem.ContactScheduleId = cellProps.id;
    saveItem.Subject = 'MOVING';

    // Clone to avoid multiples renders
    const contactsCopy = clone(contacts);

    const contactIndex = contacts.findIndex(
      (contact) => contact.ContactId === saveItem.contact.ContactId
    );

    const oldContactIndex = contacts.findIndex(
      (contact) => contact.ContactId === oldContactId
    );

    let itemsDay = JSON.parse(contactsCopy[contactIndex][day]) || [];
    const itemsOld = JSON.parse(contactsCopy[oldContactIndex][oldDay]) || [];

    saveItem = save(saveItem, day.split('-')[0]);

    const itemMinified = {
      Ad: saveItem.Ad ?? saveItem.AllDay,
      Sb: saveItem.Sb,
      Id: saveItem.ContactScheduleId,
      Ci: saveItem.ContactId,
      Cl: saveItem.Cl,
      Od: saveItem.Od,
      Sd: saveItem.Sd ?? saveItem.StartDate,
      Ed: saveItem.Ed ?? saveItem.EndDate,
      Sw: saveItem.Sw,
    };

    // eslint-disable-next-line no-unused-expressions
    Array.isArray(itemsDay)
      ? itemsDay.push(itemMinified)
      : (itemsDay = [itemsDay, itemMinified]);

    const oldArray =
      itemsOld.length > 0
        ? JSON.stringify(
          itemsOld.filter((itemOld) => itemOld.Id !== itemMinified.Id)
        )
        : null;

    contactsCopy[contactIndex][day] = JSON.stringify(itemsDay);
    contactsCopy[oldContactIndex][oldDay] = oldArray;
    setContacts(contactsCopy);
  };

  const handleCanDrop = (cellCanDrop) => {
    if (!ACL.Update) {
      return false;
    }

    const cellValue = JSON.parse(cellCanDrop.source.value) || [];
    const isPast =
      ((now.getDate() >
        parseInt(cellCanDrop.source.column.dataField.replace(/\D+/g, '')) ||
        now.getDate() >
        parseInt(source.column.dataField.replace(/\D+/g, ''))) &&
        now.getMonth() + 1 === currentDate.getMonth() + 1 &&
        now.getFullYear() === currentDate.getFullYear()) ||
      (now.getMonth() + 1 > currentDate.getMonth() + 1 &&
        now.getFullYear() === currentDate.getFullYear()) ||
      now.getFullYear() > currentDate.getFullYear();

    const contactsCopy = clone(contacts);
    const contactIndexDest = contacts.findIndex(
      (contact) => contact.ContactId === source.key
    );

    const itemsDest = JSON.parse(contactsCopy[contactIndexDest][day]) || [];

    let item = {};

    item = Array.isArray(cellValue)
      ? cellValue.find(
        (cell) => cell.Id === cellCanDrop.id || cell.Sb === cellCanDrop.Sb
      )
      : cellValue;

    if (!item.Od && isPast) {
      notify(
        {
          message:
            item.Sb !== ''
              ? `This appointment [ ${item.Sb} ] cannot be set late.`
              : 'This appointment cannot be set late.',
          height: 55,
          width: 400,
        },
        'error',
        3000
      );
      return false;
    }

    if (itemsDest.length > 0) {
      const dayUpdate = itemsDest.find(
        (itemDay) => itemDay.Id === item.Id || itemDay.Sb === item.Sb
      );

      setCanDrop(!dayUpdate);
      return !dayUpdate;
    }
    setCanDrop(true);
    return true;
  };

  const [{ isOver }, drop] = useDrop({
    accept: 'CELL',
    canDrop: handleCanDrop,
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  tasks = [];
  if (source.value) {
    tasks = JSON.parse(source.value) || [];
  }

  if (!Array.isArray(tasks)) {
    tasks = [tasks];
  }

  const isSummary = source.data?.isSummary;


  return (
    <div>
      <Container
        ref={drop}
        border={isOver}
        isSummary={isSummary}
        isWeekend={isWeekend}
        isToday={isToday}
        isHoliday={isHoliday}
        isLocked={source?.data?.locked ?? (!isToday && isPast)}
        pointer={isOver && !canDrop}
        onClick={(e) => handleCellClick(e, source)}
        periodColor={periodColor}
      >
        {!isSummary &&
          tasks &&
          tasks
            .filter((item, index) => index < 3)
            .map((task, index) => (
              <CellContent
                key={`task.Id_${index}`}
                cellId={cellId}
                item={task}
                itemQty={tasks.length}
                index={index}
                tasks={tasks}
                {...props}
              />
            ))}

        {(!isSummary && !tasks) ||
          (!isSummary && tasks.length === 0 && (
            <CellContent key={cellId} cellId={cellId} itemQty={0} {...props} />
          ))}
        {isSummary && (
          <CellTotal key={generateUUID()} source={source} {...props} />
        )}
      </Container>
    </div>
  );
};
export default Cell;
