import { DataGrid } from 'devextreme-react';
import { Column, MasterDetail } from 'devextreme-react/data-grid';
import { array } from 'prop-types';
import React from 'react';
import EmployeeDetailGrid from './EmployeeDetailGrid';

function EmployeeGrid({ data }) {
  return (
    <DataGrid
      dataSource={data}
      keyExpr="UserId"
      showBorders
      selection={{
        mode: 'single',
      }}
      focusedRowEnabled
      allowColumnReordering
      rowAlternationEnabled
      showRowLines
      headerFilter={{ visible: true }}
      filterPanel={{ visible: true }}
      filterRow={{ visible: true }}
    >
      <MasterDetail
        enabled
        component={({ data }) => <EmployeeDetailGrid data={data?.row?.data} />}
      />
      <Column dataField="DisplayName" caption="Name" />
      <Column dataField="JobTitle" caption="Job Title" />
      <Column dataField="Department" />
      <Column dataField="Email" caption="Email" />
    </DataGrid>
  );
}

export default EmployeeGrid;

EmployeeGrid.propTypes = {
  data: array,
};

EmployeeGrid.defaultProps = {
  data: [],
};
