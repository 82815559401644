import * as Sentry from '@sentry/browser';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
// import { PersistGate } from 'redux-persist/integration/react';
// import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import 'video-react/dist/video-react.css';
import './App.css';
import QueryClientProvider from './components/QueryClientProvider';
import Routes from './components/route/Routes';
import AppBarDrawer from './components/template/AppBarDrawer';
import config from './config';
// import oneSignalConnect from './utils/oneSignalConnect';
import './config/ReactotronConfig';
// import { store, persistor } from './store';

import { theme } from './themes';

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
  });
}

// Force HTTPS
const httpDomains = ['localhost'];
/** TESTE */

if (
  window.location.protocol !== 'https:' &&
  httpDomains.includes(window.location.hostname) === false
) {
  window.location.href = `https:${window.location.href.substring(
    window.location.protocol.length
  )}`;
}

const App = () => (
  // useEffect(() => {
  //   oneSignalConnect();
  // }, []);

  <div className="App">
    {/* <Provider store={store}>
      <PersistGate persistor={persistor}> */}
    <Router>
      <QueryClientProvider>
        <AppBarDrawer />
        <ThemeProvider theme={theme}>
          <div className="AppContainer">
            <Routes />
          </div>
        </ThemeProvider>
      </QueryClientProvider>
    </Router>
    {/* </PersistGate>
    </Provider> */}
  </div>
);
export default App;
