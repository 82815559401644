import HistoryPopup from 'components/popups/HistoryPopup';
import { addDays, format } from 'date-fns';
import { Button, DateBox, LoadIndicator, Validator } from 'devextreme-react';
import DataGrid, {
  Column,
  MasterDetail,
  Selection,
} from 'devextreme-react/data-grid';
import { CustomRule, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import usePermissions from 'hooks/usePermissions';
import DeleteConfirmationPopup from 'pages/EMRAccess/components/DeleteConfirmationPopup';
import EmailView from 'pages/EMRAccess/components/EmailView';
import EmrAccessEditPopup from 'pages/EMRAccess/components/RequestsDetail/components/EmrAccessEditPopup';
import SendEmailPopup from 'pages/EMRAccess/components/SendEmailPopup';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import API from 'services/API';
import useAllHospitalGroupsList from 'services/requests/useAllHospitalGroupsList';
import useEmrRequestMethodList from 'services/requests/useEmrRequestMethodList';
import useEmrRequestStatusList from 'services/requests/useEmrRequestStatusList';
import getChangeHistory from 'utils/getChangeHistory';
import ExpirationDetail from './ExpirationDetail';
import {
  Container,
  FilterGroup,
  GridContainer,
  Line,
  Title,
  WrapperContent,
} from './styles';

function ACTExpirationSearch() {
  const [dateData, setDateData] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });
  const [oldDateData, setOldDateData] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });
  const editRequestPopupRef = useRef(null);

  const [popupHistory, setPopupHistory] = useState({
    visible: false,
    data: {},
  });
  const [deletePopup, setDeletePopup] = useState({
    visible: false,
    emrAccessId: '',
    hospitalGroupName: '',
    question: '',
  });

  const { data: applicationList } = useAllHospitalGroupsList(false);

  const ACL = usePermissions();
  const { data: requestMethodList } = useEmrRequestMethodList();
  const { data: requestStatusDataList } = useEmrRequestStatusList();

  const autoRefetchGridRef = useRef(true);

  const emailViewRef = useRef(null);
  const sendEmailPopupRef = useRef(null);
  const detailsRef = useRef();

  const methodIdToName = (id) => {
    const method = requestMethodList?.find(
      (item) => item?.EMRRequestMethodId === id
    );
    return method ? method?.EMRRequestMethodName : id;
  };

  const [expirationList, setExpirationList] = useState({
    data: [],
    isLoading: true,
  });

  async function fetchRequestData() {
    try {
      const dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";

      const { data: requests } = await API.sendRequest(
        'ADUserEMRAccess/ListExpires',
        'POST',
        {
          StartDate: format(dateData.startDate, dateFormat),
          EndDate: format(dateData.endDate, dateFormat),
        }
      );
      const newExpirationList = applicationList
        ?.map((item) => ({
          ...item,
          requestList: requests?.filter((expItem) => {
            return expItem.HospitalGroupId === item.HospitalGroupId;
          }),
        }))
        ?.filter((item) => item?.requestList?.length > 0);

      if (
        JSON.stringify(expirationList?.data) !==
        JSON.stringify(newExpirationList)
      ) {
        setExpirationList({
          data: newExpirationList,
          isLoading: false,
        });
      }
    } catch (error) {
      setExpirationList((prev) => ({ ...prev, isLoading: false }));
      console.log(error);
    }
  }

  const activeAutoRefetch = useCallback(() => {
    if (autoRefetchGridRef.current) {
      fetchRequestData();
    }
    // eslint-disable-next-line
  }, [
    autoRefetchGridRef.current,
    dateData,
    applicationList,
    expirationList.data,
  ]);

  function refetch() {
    fetchRequestData();
  }

  useEffect(() => {
    let isSubscribed = true;
    let intervalId;

    if (isSubscribed) {
      fetchRequestData();

      intervalId = setInterval(() => {
        activeAutoRefetch();
      }, 1 * 60 * 1000); // 1 minute
    }
    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [activeAutoRefetch]);

  const loadRequests = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setOldDateData(dateData);
    refetch();
  };

  const handleShowChangeHistory = async (e) => {
    await getChangeHistory(
      'ADUserEMRAccess',
      e?.row?.data?.ADUserEMRAccessId
    ).then((ret) => {
      if (ret) {
        setPopupHistory({
          visible: true,
          data: ret,
        });
      }
    });
  };

  const handleDeleteRequest = (e) => {
    if (e?.row.data.EMRRequestStatusId) {
      setDeletePopup({
        visible: true,
        emrAccessId: e?.row.data.ADUserEMRAccessId,
        hospitalGroupName: e?.row.data.HospitalGroupName,
        question: 'Delete',
      });
    }
  };

  async function revokeRequest(requestId) {
    if (requestId) {
      const res = await API.sendRequest(
        `ADUserEMRAccess/CancelRequest/${requestId}`,
        'POST'
      );

      if (res?.data?.ErrorMsg) {
        notify(res?.data?.ErrorMsg, 'error', 5000);
        return;
      }
      refetch();
      notify('Request canceled successfully', 'success', 5000);
    }
  }

  return (
    <>
      <SendEmailPopup
        ref={sendEmailPopupRef}
        clearSelectedUsers={() => detailsRef?.current?.clearUsers()}
        isExpirationScreen
        forceRefetch={refetch}
        autoRefetchGridRef={autoRefetchGridRef}
      />
      <EmrAccessEditPopup
        forceRefetch={() => refetch()}
        ref={editRequestPopupRef}
      />
      <EmailView ref={emailViewRef} refetch={refetch} />

      {popupHistory?.visible && (
        <HistoryPopup
          isVisible={popupHistory?.visible}
          setIsVisible={(visible) =>
            setPopupHistory({
              visible: visible,
              data: {},
            })
          }
          changeHistory={popupHistory?.data}
        />
      )}
      {deletePopup.visible && deletePopup.emrAccessId && (
        <DeleteConfirmationPopup
          visible={deletePopup.visible}
          hospitalGroupName={deletePopup?.hospitalGroupName}
          onClose={() => {
            setDeletePopup({
              visible: false,
              emrAccessId: '',
              hospitalGroupName: '',
              question: '',
            });
          }}
          question={deletePopup?.question}
          deleteFunc={() => revokeRequest(deletePopup?.emrAccessId)}
        />
      )}

      <Container>
        <Title>{ACL?.BreadCrumb}</Title>
        <WrapperContent>
          <form onSubmit={(e) => loadRequests(e)} style={{ width: '100%' }}>
            <FilterGroup>
              <span>Filter by date:</span>
              <DateBox
                value={dateData.startDate}
                onValueChanged={(e) => {
                  setDateData((prev) => ({ ...prev, startDate: e.value }));
                }}
              >
                <Validator>
                  <RequiredRule message="This field is required" />
                </Validator>
              </DateBox>
              <span>-</span>
              <DateBox
                value={dateData.endDate}
                onValueChanged={(e) => {
                  setDateData((prev) => ({ ...prev, endDate: e.value }));
                }}
                isValid={dateData?.startDate <= dateData?.endDate}
              >
                <Validator>
                  <RequiredRule message="This field is required" />
                  <CustomRule
                    message="End date should be greater than or equal to start date"
                    validationCallback={(e) => {
                      return dateData.startDate <= e.value;
                    }}
                  />
                </Validator>
              </DateBox>
              <Button
                icon="search"
                text="Search"
                type="default"
                useSubmitBehavior
                disabled={
                  dateData.startDate === oldDateData.startDate &&
                  dateData.endDate === oldDateData.endDate
                }
              />

              {expirationList?.isLoading && <LoadIndicator visible />}
            </FilterGroup>
          </form>
          <Line />
          <GridContainer>
            <DataGrid
              dataSource={expirationList?.data}
              noDataText={
                expirationList?.isLoading
                  ? 'Loading Requests...'
                  : 'There are no Request(s)'
              }
              keyExpr="HospitalGroupId"
              height="75vh"
              showBorders
              focusedRowEnabled
            >
              <Selection mode="single" />
              <Column dataField="HospitalGroupName" caption="Okta Tile" />
              <Column dataField="EMREmail" caption="Email" />
              <Column dataField="EMRPhone" caption="Phone" />
              <Column dataField="EMRRequestUrl" caption="Request Url" />
              <Column dataField="Expiration" caption="Expiration" />
              <Column
                dataField="EMRRequestMethodId"
                caption="Request Method"
                cellRender={(data) => methodIdToName(data.value)}
              />
              <MasterDetail
                enabled={true}
                component={({ data }) => (
                  <ExpirationDetail
                    ref={detailsRef}
                    ACL={ACL}
                    data={data?.data}
                    handleDeleteRequest={handleDeleteRequest}
                    handleShowChangeHistory={handleShowChangeHistory}
                    requestMethodList={requestMethodList}
                    requestStatusDataList={requestStatusDataList}
                    editRequestPopupRef={editRequestPopupRef}
                    emailViewRef={emailViewRef}
                    sendEmailPopupRef={sendEmailPopupRef}
                    loading={expirationList?.isLoading}
                  />
                )}
                autoExpandAll
                requestMethodList={requestMethodList}
                requestStatusDataList={requestStatusDataList}
              />
            </DataGrid>
          </GridContainer>
        </WrapperContent>
      </Container>
    </>
  );
}

export default ACTExpirationSearch;
