import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Scheduler /* , { Resource } */ from 'devextreme-react/scheduler';
import API from '../../../services/API';

import useStyles from './styles.js';

const views = ['week', 'month'];
// const groups = ['ContactId'];
const currentDate = new Date();

const ContactAgendaPopup = (props) => {
  const { visible, onVisibleChange, contact } = props;
  const [schedules, setSchedules] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (contact) {
      setSchedules([]);

      async function init() {
        const response = await API.sendRequest(
          `ContactSchedule/${contact.ContactId}`,
          'get',
          {},
          false
        );

        if (response.status === 200) {
          setSchedules(
            response.data.map((schedule) => ({
              text: schedule.Subject,
              startDate: schedule.StartDate,
              endDate: schedule.EndDate,
              allDay: schedule.AllDay,
              color: schedule.Color,
            }))
          );
        }
      }

      init();
    }
  }, [contact]);

  // const onAppointmentAdded = e => {};
  // const onAppointmentUpdated = e => {};
  // const onAppointmentDeleted = e => {};

  return (
    <Dialog
      open={visible}
      onClose={() => onVisibleChange(false)}
      aria-labelledby="form-dialog-title"
      height={800}
      maxWidth="lg"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="form-dialog-title">
        {contact && `${contact.FirstName} ${contact.LastName}`}
      </DialogTitle>
      <DialogContent>
        <Scheduler
          dataSource={schedules}
          // dataCellComponent={DataCell}
          // resourceCellComponent={ResourceCell}
          // groups={['Color']}
          editing={{
            allowAdding: false,
            allowUpdating: false,
            allowDeleting: false,
            allowResizing: false,
            allowDragging: false,
          }}
          // onAppointmentAdded={onAppointmentAdded}
          // onAppointmentUpdated={onAppointmentUpdated}
          // onAppointmentDeleted={onAppointmentDeleted}
          views={views}
          showAllDayPanel
          firstDayOfWeek={1}
          defaultCurrentView="month"
          defaultCurrentDate={currentDate}
          height={550}
          startDayHour={7}
        >
          {/* <Resource
            label={'Contact'}
            fieldExpr={'ContactId'}
            dataSource={contact}
            type={'Color'}
            allowMultiple={false}
          /> */}
        </Scheduler>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleSave} color="primary">
          Save
        </Button> */}
        <Button onClick={() => onVisibleChange(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactAgendaPopup;
