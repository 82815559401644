/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
import Typography from '@material-ui/core/Typography';
import { Tooltip } from 'devextreme-react/tooltip';
import React, { useState } from 'react';
import useGetImages from '../../services/requests/useGetImages';
import useStyles from './Avatar.css.js';

const Avatar = (props) => {
  const { contact, h, w, onClick, style, useTooltip = true } = props;
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  const { data: getUserImage } = useGetImages(
    contact.StatusPhotoId > 0 ? contact.Username : null
  );

  const avatarStyle = {
    backgroundColor: `${
      contact.Color
        ? contact.Color.toUpperCase().substr(0, 4) === '#FFF'
          ? '#C0C0C0'
          : contact.Color
        : '#C0C0C0'
    }`,
    backgroundImage:
      contact.Username && contact.StatusPhotoId > 0
        ? `url('data:image/png;base64,${
            getUserImage === undefined ? '' : getUserImage
          }`
        : '',
    lineHeight: `${h}px`,
    height: `${h}px`,
    width: `${w}px`,
    ...style,
  };

  const showInitials = (contact) => {
    if ((contact && contact.FirstName) || (contact && contact.Initials)) {
      return contact.Initials
        ? contact.Initials.length > 2
          ? `${contact.Initials.substring(0, 2)}`
          : contact.Initials
        : contact.LastName
        ? `${
            contact.FirstName.substring(0, 1).toUpperCase() +
            contact.LastName.substring(0, 1).toUpperCase()
          }`
        : `${contact.FirstName.substring(0, 1).toUpperCase()}`;
    }

    return '';
  };

  return (
    <>
      <Typography
        id={`contact-avatar-${
          contact.ContactId ? contact.ContactId : contact.UserId
        }`}
        className={
          contact.StatusPhotoId > 0 ? classes.avatar : classes.initials
        }
        style={avatarStyle}
        onClick={onClick}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {contact.StatusPhotoId > 0 ? '' : showInitials(contact)}
      </Typography>
      {useTooltip && (
        <Tooltip
          target={`#contact-avatar-${
            contact.ContactId ? contact.ContactId : contact.UserId
          }`}
          visible={showTooltip}
          closeOnOutsideClick={false}
        >
          <div>
            {contact.FirstName && contact.LastName
              ? `${contact.Prefix ? contact.Prefix : ''} ${
                  contact.FirstName ? contact.FirstName : ''
                } ${contact.LastName ? contact.LastName : ''}`
              : `${contact.FullName}`}
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default Avatar;
