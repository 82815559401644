import React from 'react';
import { ItemIcon, SelectBoxItemContainer } from './styles';

const SelectBoxItemRender = (data) => (
  <SelectBoxItemContainer>
    <ItemIcon
      className={data?.EMRRequestStatusIcon}
      $iconColor={data?.EMRRequestStatusColor}
    />
    {data?.EMRRequestStatusName}
  </SelectBoxItemContainer>
);

export default SelectBoxItemRender;
