import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;
`;

export const AccordionItemHeader = styled.h5`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  span:first-child {
    color: #326a95;
  }
`;

export const AccordionItemBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  overflow: auto;
  span {
    white-space: pre-line;
  }
`;
