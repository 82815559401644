import { bool, func, object } from 'prop-types';
import React from 'react';

import { LoadIndicator, LoadPanel } from 'devextreme-react';
import TextEditor from '../TextEditor';
import { Container } from './styles';

function NewTrainingTemplate({ emrSetupDraft, setEMRSetupDraft, isLoading }) {
  const requestTemplate = emrSetupDraft?.NewTrainingEmailBody;
  return (
    <Container>
      {isLoading && (
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={isLoading}
          message="Saving template..."
          closeOnOutsideClick={false}
        />
      )}
      {emrSetupDraft?.NewTrainingEmailBody !== undefined ? (
        <TextEditor
          data={requestTemplate}
          setHtmlContent={(text) =>
            setEMRSetupDraft((prev) => ({
              ...prev,
              NewTrainingEmailBody: text,
            }))
          }
          width="100%"
          height="100%"
        />
      ) : (
        <LoadIndicator id="large-indicator" height={40} width={40} />
      )}
    </Container>
  );
}

export default NewTrainingTemplate;

NewTrainingTemplate.propTypes = {
  isLoading: bool,
  emrSetupDraft: object,
  setEMRSetupDraft: func,
};

NewTrainingTemplate.defaultProps = {
  isLoading: false,
  emrSetupDraft: {},
  setEMRSetupDraft: () => {},
};
