import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 270px;
  align-items: center;
  gap: 12px;
`;

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 64px;
  height: 32px;
  border-radius: 100px;
  background-color: #cecece;
  position: relative;
  transition: background-color 0.2s;
`;

export const SwitchButton = styled.span`
  content: '';
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2px;
  left: 2px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  transition: 0.2s;
  background: #337ab7;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  color: #fff;
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`;

export const LabelDescription = styled.span`
  font-size: 16px;
`;
