import DataGrid, { Button, Column, Paging } from 'devextreme-react/data-grid';
import React, { useEffect, useMemo, useState } from 'react';

import { IconButton } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { array, object, shape } from 'prop-types';
import API from '../../../../services/API';
import PopupUserTraining from '../popups/PopupUserTraining';
import PopupUserTrainingDelete from '../popups/PopupUserTrainingDelete';
import {
  Container,
  DatagridContainer,
  Section,
  SectionHeader,
  Title,
} from './styles';

function TrainingGroupsGrid({ data, trainings, ACL }) {
  const [popupUserTrainingVisible, setPopupUserTrainingVisible] = useState(
    false
  );
  const [popupDeleteVisible, setPopupDeleteVisible] = useState(false);
  const [currentUserTraining, setCurrentUserTraining] = useState(null);
  const [userTrainingList, setUserTrainingList] = useState(null);
  const userTrainingData = data?.data;

  async function fetchUserTrainingData() {
    if (userTrainingData?.UserId) {
      const { data: userTrainingDataResponse } = await API.sendRequest(
        `UserTraining/ListByUser/${userTrainingData?.UserId}`,
        'GET'
      );
      setUserTrainingList(userTrainingDataResponse);
    }
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      fetchUserTrainingData();
    }

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, []);

  function handleOpenPopupUserTraining({ data, type }) {
    setCurrentUserTraining({ data, type });
    setPopupUserTrainingVisible(true);
  }

  function handleClosePopupUserTraining() {
    setPopupUserTrainingVisible(false);
    setCurrentUserTraining(null);
  }
  function handleOpenPopupDelete(data) {
    setCurrentUserTraining(data);
    setPopupDeleteVisible(true);
  }

  function handleClosePopupDelete() {
    setPopupDeleteVisible(false);
    setCurrentUserTraining(null);
  }

  return (
    <>
      {popupUserTrainingVisible && (
        <PopupUserTraining
          currentUserTraining={currentUserTraining}
          visible={popupUserTrainingVisible}
          refetch={() => fetchUserTrainingData()}
          onClose={() => handleClosePopupUserTraining()}
          trainingId={userTrainingData.TrainingId}
          currentUserId={userTrainingData.UserId}
          trainings={trainings}
        />
      )}
      {popupDeleteVisible && (
        <PopupUserTrainingDelete
          currentUserTraining={currentUserTraining}
          visible={popupDeleteVisible}
          refetch={() => fetchUserTrainingData()}
          onClose={() => handleClosePopupDelete()}
        />
      )}
      <Container>
        <Section>
          <SectionHeader>
            <Title>Trainings</Title>
            {(ACL?.Update || ACL?.Admin) && (
              <IconButton
                aria-label="plus"
                size="small"
                onClick={() =>
                  handleOpenPopupUserTraining({
                    data: null,
                    type: 'NEW',
                  })
                }
              >
                <AddBoxOutlinedIcon />
              </IconButton>
            )}
          </SectionHeader>
          <DatagridContainer>
            {useMemo(
              () => (
                <DataGrid
                  height={300}
                  dataSource={userTrainingList}
                  keyExpr="UserTrainingId"
                  showBorders
                  selection={{
                    mode: 'single',
                  }}
                  hoverStateEnabled
                >
                  <Paging enabled={false} />
                  <Column dataField="TrainingDesc" caption="Name" />
                  <Column
                    caption="Okta Tile"
                    cellRender={(data) => (
                      <div>
                        <div>
                          {
                            data.data.HospitalGroups?.find(
                              (item) =>
                                item?.HospitalGroupId ===
                                data.data?.HospitalGroupId
                            )?.HospitalGroupName
                          }
                        </div>
                      </div>
                    )}
                  />
                  <Column dataField="IsPortal" caption="Portal" width={100} />
                  <Column dataField="ScheduledDate" dataType="date" />
                  <Column dataField="CompletedDate" dataType="date" />
                  {(ACL?.Update || ACL?.Delete || ACL?.Admin) && (
                    <Column type="buttons" caption="Actions">
                      {(ACL?.Update || ACL?.Admin) && (
                        <Button
                          icon="edit"
                          text="Edit"
                          onClick={(e) =>
                            handleOpenPopupUserTraining({
                              data: e?.row?.data,
                              type: 'EDIT',
                            })
                          }
                        />
                      )}
                      {(ACL?.Delete || ACL?.Admin) && (
                        <Button
                          icon="trash"
                          text="Delete"
                          onClick={(e) => handleOpenPopupDelete(e?.row?.data)}
                        />
                      )}
                    </Column>
                  )}
                </DataGrid>
              ),
              [userTrainingList, ACL]
            )}
          </DatagridContainer>
        </Section>
      </Container>
    </>
  );
}

TrainingGroupsGrid.propTypes = {
  data: shape({
    data: shape({}),
  }).isRequired,
  trainings: array,
  ACL: object.isRequired,
};

TrainingGroupsGrid.defaultProps = {
  trainings: [],
};

export default TrainingGroupsGrid;
