import React, { useState, useEffect } from 'react';
import { Column, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import GroupsPopup from '../GroupsPopup';

import API from '../../services/API';
import {
  Container,
  Content,
  BoxSubtitle,
  Subtitle,
  ColumnDivider,
  DataGridCustom,
} from './styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ReportDetails({ data }) {
  const [groups, setGroups] = useState([]);
  const [reportAnchorEl, setReportAnchorEl] = useState(null);
  const [openSnack, setOpenSnack] = useState(null);
  const [errorSnack, setErrorSnack] = useState(false);

  useEffect(() => {
    async function loadDataGroups() {
      const response = await API.sendRequest(
        `Report/listgroups/${data.key}`,
        'get'
      );

      setGroups(response.data);
    }
    loadDataGroups(); //eslint-disable-next-line
  }, []);

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const onDeleteClick = async (item) => {
    const response = await API.sendRequest(
      `Report/GroupDelete/${data.key}/${item.row.data.GroupId}`,
      'DELETE'
    );

    if (response) {
      const groupsRest = groups.filter(
        (r) => r.GroupId !== item.row.data.GroupId
      );

      setGroups(groupsRest);
    } else {
      setErrorSnack(true);
      setOpenSnack(true);
    }
  };

  const handleGroupOpen = (event) => {
    event.preventDefault();

    setReportAnchorEl(event.currentTarget);
  };

  const handleGroupClose = () => {
    setReportAnchorEl(null);
  };

  return (
    <Container>
      <Content>
        <ColumnDivider>
          <BoxSubtitle>
            <Subtitle>GROUPS</Subtitle>
            <IconButton
              aria-label="plus"
              size="small"
              onClick={handleGroupOpen}
            >
              <AddBoxOutlinedIcon />
            </IconButton>
          </BoxSubtitle>
          <DataGridCustom
            dataSource={groups}
            showBorders
            showRowLines
            hoverStateEnabled
          >
            <Scrolling mode="virtual" />
            <HeaderFilter visible allowSearch />
            <Column dataField="GroupCode" />
            <Column dataField="GroupName" />
            <Column
              caption="Actions"
              type="buttons"
              width={100}
              buttons={[
                {
                  hint: 'Delete',
                  icon: 'trash',
                  onClick: onDeleteClick,
                },
              ]}
            />
          </DataGridCustom>
        </ColumnDivider>
      </Content>

      {reportAnchorEl && (
        <GroupsPopup
          anchorEl={reportAnchorEl}
          handleClose={handleGroupClose}
          title="Select Group"
          groups={groups}
          setGroups={setGroups}
          reportId={data.key}
          setErrorSnack={setErrorSnack}
          handleClickSnack={handleClickSnack}
        />
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        {errorSnack ? (
          <Alert onClose={handleCloseSnack} severity="error">
            Error during operation. Please try again!
          </Alert>
        ) : (
          <Alert onClose={handleCloseSnack} severity="success">
            Report add success!
          </Alert>
        )}
      </Snackbar>
    </Container>
  );
}
