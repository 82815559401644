import { Template } from 'devextreme-react/core/template';
import {
  Column,
  DataGrid,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
  Selection,
} from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import usePermissions from '../../hooks/usePermissions';
import API from '../../services/API';
import useStyles from '../styles/styles';
import CasePopup from './CasePopup';
import ProviderAvatar from './ProviderAvatar';

const onToolbarPreparing = (e) => {
  e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'breadCrumb',
  });
};

const genderList = [
  {
    value: 'M',
    display: 'Male',
  },
  {
    value: 'F',
    display: 'Female',
  },
];

function ageCellRender({ data }) {
  return data?.Age ? `${data?.Age} ${data?.AgeUnit}`.trim() : '';
}

function providerAvatarRender({ data }) {
  return <ProviderAvatar providerCode={data?.ProviderCode} />;
}

function ActiveCases() {
  const ACL = usePermissions();
  const classes = useStyles();
  const [activeCasesData, setActiveCasesData] = useState([]);
  const [currentCasePopup, setCurrentCasePopup] = useState({
    referralId: '',
    visible: false,
  });
  useEffect(() => {
    fetchData();
  }, []);

  const actionButtons = [
    {
      icon: 'fas fa-file-medical',
      onClick: ({ row }) => {
        setCurrentCasePopup({
          referralId: row?.data?.ReferralID,
          visible: true,
        });
      },
    },
  ];

  async function fetchData() {
    const { data } = await API.sendRequest('ActiveCases/false', 'get');
    setActiveCasesData(data);
  }

  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You do not have access to this page</LoadPermissions>
    );
  }

  return (
    <div className={classes.divGridContainer}>
      {currentCasePopup.visible && (
        <CasePopup
          referralId={currentCasePopup.referralId}
          visible={currentCasePopup.visible}
          onHidden={() => {
            setCurrentCasePopup({ referralId: '', visible: false });
          }}
        />
      )}
      <DataGrid
        id="gridContainer"
        dataSource={activeCasesData}
        keyExpr="ReferralID"
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        showRowLines
        focusedRowEnabled
        loadPanel={{ enabled: 'auto' }}
        onToolbarPreparing={onToolbarPreparing}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        height="89vh"
      >
        <SearchPanel visible width={240} placeholder="Search..." />

        <Paging defaultPageSize={15} />
        <Pager
          showPageSizeSelector
          allowedPageSizes={[15, 30, 50, 100, 200]}
          showInfo
        />
        <Selection mode="single" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
        <Column
          cellRender={providerAvatarRender}
          width={85}
          dataField="ProviderCode"
          caption="Provider"
        />
        <Column dataField="OPOID" caption="OPO #" width={95} />
        <Column dataField="Fullname" caption="Name" />
        <Column dataField="ReferralTakenBy" caption="Referred by" />
        <Column dataField="Gender" width={80}>
          <Lookup
            dataSource={genderList}
            valueExpr="value"
            displayExpr="display"
          />
        </Column>
        <Column dataField="Age" cellRender={ageCellRender} width={65} />
        <Column dataField="TypeOfCall" caption="Transplant Type" />
        <Column dataField="Phone" />
        <Column dataField="ReferralDateTime" dataType="datetime" />
        <Column type="buttons" caption="Detail" buttons={actionButtons} />
      </DataGrid>
    </div>
  );
}

export default ActiveCases;
