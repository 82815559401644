import styled from 'styled-components';

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  box-sizing: border-box;
  height: calc(100% - 35px);
  width: 100%;

  .count {
    padding: 8px 0;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 8px 0;
  width: 100%;
`;
