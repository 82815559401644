import { useQuery } from 'react-query';
import API from '../API';

async function getImages(Username) {
  const response = await API.sendRequest(
    `Images/UserBase64/${Username}`,
    'GET'
  );

  return response.data;
}
export default function useGetImages(Username) {
  const enabled = !!Username;
  return useQuery(['getImages', Username], () => getImages(Username), {
    refetchOnWindowFocus: false,
    enabled,
  });
}
