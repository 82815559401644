import { RadioGroup } from 'devextreme-react';
import Form, {
  ButtonItem,
  EmptyItem,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/ui/load-panel';
import { EmailRule, RequiredRule } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import { array, bool, func, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useEmrContactList from 'services/requests/useEmrContactList';
import generateGuid from 'utils/uuiGenerator';

const AddHospitalContactPopup = ({
  isEmail,
  isWebsite,
  isVisible,
  onClose,
  currentContact,
  currentContactList,
  insertContact,
}) => {
  const [saving, setSaving] = useState(false);
  const [isExistent, setIsExistent] = useState(false);
  const { data: emrContactList } = useEmrContactList();
  const formRef = useRef(null);

  const contactMock = {
    ContactId: '',
    FirstName: '',
    LastName: '',
    Active: true,
    Email: '',
    // Phones: [{ PhoneNumber: '' }],
    PhoneNumbers: '',
    IsEMRContact: true,
    IsPrefered: !currentContactList?.length ? true : false,
    IsToNewRequest: false,
    IsToReNewRequest: false,
    IsToTerminationRequest: false,
  };

  const [contactDraft, setContactDraft] = useState(contactMock);

  const contactsToShow = emrContactList
    ?.map((item) => {
      if (currentContactList.includes(item?.ContactId)) {
        return null; // Retorna null em vez de undefined
      }
      return {
        ...item,
        FullName: `${item?.FirstName} ${item?.LastName}`,
      };
    })
    .filter((item) => item !== null);

  useEffect(() => {
    if (currentContact?.ContactId || currentContact?.TemporaryId) {
      setContactDraft({
        ...currentContact,
        // IsPrefered: !currentContactList?.length
        //   ? true
        //   : currentContact?.IsPrefered,
        // Phones: [
        //   {

        PhoneNumbers: currentContact?.PhoneNumbers?.replace(/[^0-9]/g, ''),
        //   },
        // ],
      });
    } else {
      setContactDraft(contactMock);
    }
  }, [currentContact]); //eslint-disable-line

  useEffect(() => {
    if (currentContact && currentContact?.Phones) {
      currentContact.Phones.forEach((item) => {
        item.PhoneNumber = item.PhoneNumber?.replaceAll('(', '')
          ?.replaceAll(')', '')
          ?.replaceAll(' ', '')
          ?.replaceAll('-', '');
      });
    }
  }, []); //eslint-disable-line

  const bottomToolbarButtons = [
    {
      text: 'OK',
      type: 'success',
      // useSubmitBehavior: true,
      onClick: () => {
        handleSaveOnClick();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        onClose();
      },
    },
  ];

  const onPopupClose = () => {
    setSaving(false);
    setIsExistent(false);
    setContactDraft(contactMock);
    formRef.current.instance.resetValues();
    onClose();
  };

  async function handleSaveOnClick(e) {
    e.preventDefault();

    if (
      isEmail &&
      !contactDraft?.IsToNewRequest &&
      !contactDraft?.IsToReNewRequest &&
      !contactDraft?.IsToTerminationRequest
    ) {
      notify(
        'To save this contact you must check at least one of the following options: "Email New Request", "Email Re-New Request" and "Email Termination Request".',
        'error',
        6000
      );
      return;
    }

    if (JSON.stringify(contactDraft) !== JSON.stringify(currentContact)) {
      insertContact({
        ...contactDraft,
        FullName: `${contactDraft.FirstName} ${contactDraft.LastName}`,
        TemporaryId: contactDraft?.ContactId
          ? ''
          : contactDraft?.TemporaryId
          ? contactDraft?.TemporaryId
          : generateGuid(),
        PhoneNumbers: contactDraft?.PhoneNumbers?.replace(/[^0-9]/g, ''),
        isExistentToSave: isExistent,
        toDelete: false,
        toSave: true,
      });
    }

    // const contactToSave = {
    //   Contact: {
    //     ContactId: contactDraft.ContactId,
    //     FirstName: contactDraft.FirstName,
    //     LastName: contactDraft.LastName,
    //     Active: true,
    //     Email: contactDraft.Email,
    //     IsEMRContact: true,
    //     IsPrefered: contactDraft.IsPrefered,
    //   },
    //   Phones: [
    //     {
    //       ContactId: contactDraft.ContactId,
    //       PhoneTypeId: 1,
    //       ContactPhoneOrder: 1,
    //       PhoneNumber: contactDraft.Phones[0].PhoneNumber,
    //     },
    //   ],
    // };

    // if (!contactToSave?.Contact?.ContactId) {
    //   delete contactToSave?.Contact?.ContactId;
    //   delete contactToSave?.Phones[0]?.ContactId;
    // }

    // try {
    //   if (formRef.current.instance.validate().isValid) {
    //     const resContact = await API.sendRequest(
    //       'Contact/SaveComplete',
    //       'POST',
    //       contactToSave,
    //       false
    //     );

    //     if (resContact?.data?.ErrorMsg) {
    //       throw new Error(resContact?.data?.ErrorMsg);
    //     }

    //     const resHospitalContact = await API.sendRequest(
    //       currentContact?.ContactId
    //         ? 'Contact/ContactHospitalGroupUpdate'
    //         : 'Contact/ContactHospitalGroupSave',
    //       'POST',
    //       {
    //         ContactId: resContact?.data?.ContactId,
    //         HospitalGroupId: currentHospitalGroupId,
    //         IsPrefered: contactDraft?.IsPrefered,
    //         DisplayOrder: 1,
    //       },
    //       false
    //     );

    //     if (resHospitalContact?.ErrorMsg) {
    //       throw new Error(resHospitalContact?.ErrorMsg);
    //     }

    // updateData();
    // notify('Contact saved', 'success', 2000);
    // setSaving(false);
    onPopupClose();
    formRef.current.instance.resetValues();
    //   } else {
    //     setSaving(false);
    //     notify(`Please check the highlighted fields.`, 'error', 2000);
    //   }
    // } catch (e) {
    //   setSaving(false);
    //   notify(`Error: ${e.message}`, 'error', 2000);
    // }
  }

  const mask = { mask: '(000) 000-0000', useMaskedValue: true };
  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      showCloseButton={false}
      onHiding={onPopupClose}
      title="Okta Tile Contact"
      width={400}
      height="auto"
    >
      <form
        style={{ width: '100%', height: '100%' }}
        onSubmit={handleSaveOnClick}
      >
        {bottomToolbarButtons?.map((buttonOptions, index) => (
          <ToolbarItem
            key={index}
            options={{ stylingMode: 'contained', ...buttonOptions }}
            widget="dxButton"
            toolbar="bottom"
            location="after"
          />
        ))}
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={saving}
          message="Saving..."
          closeOnOutsideClick={false}
        />
        <RadioGroup
          dataSource={[
            { Text: 'New Contact', value: false },
            { Text: 'Existing Contact', value: true },
          ]}
          displayExpr="Text"
          valueExpr="value"
          layout="horizontal"
          visible={!currentContact?.ContactId && !currentContact?.TemporaryId}
          value={isExistent}
          onValueChanged={({ value }) => {
            setIsExistent(value);
            setContactDraft(contactMock);
          }}
        />
        <br />
        <Form
          id="form"
          formData={contactDraft}
          showColonAfterLabel
          labelLocation="left"
          ref={formRef}
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
          }}
        >
          {!isExistent && (
            <SimpleItem dataField="FirstName" editorType="dxTextBox">
              <RequiredRule message="First Name is required" />
              <Label text="First Name" />
            </SimpleItem>
          )}
          {!isExistent && (
            <SimpleItem dataField="LastName" editorType="dxTextBox">
              <RequiredRule message="Last Name is required" />
              <Label text="Last Name" />
            </SimpleItem>
          )}
          {isExistent && (
            <SimpleItem
              editorOptions={{
                dataSource: contactsToShow,
                displayExpr: 'FullName',
                valueExpr: 'ContactId',
                onValueChanged: ({ value }) => {
                  const currentSelected = contactsToShow?.find(
                    (c) => c?.ContactId === value
                  );
                  setContactDraft({
                    ...currentSelected,
                    IsPrefered: !currentContactList?.length,
                    PhoneNumbers: currentSelected?.PhoneNumbers?.replace(
                      /[^0-9]/g
                    ),
                    Phones: [
                      {
                        PhoneNumber: currentSelected?.PhoneNumbers?.replace(
                          /[^0-9]/g,
                          ''
                        ),
                      },
                    ],
                  });
                },
              }}
              dataField="ContactId"
              editorType="dxSelectBox"
            >
              <RequiredRule message="Contact is required" />
              <Label text="Name" />
            </SimpleItem>
          )}
          <SimpleItem dataField="Email" editorType="dxTextBox">
            <EmailRule message="Email is invalid" />
            <RequiredRule message="Email is required" />
            <Label text="Email" />
          </SimpleItem>
          <SimpleItem
            name="phone"
            dataField="PhoneNumbers"
            editorType="dxTextBox"
            editorOptions={mask}
          >
            <RequiredRule message="Phone is required" />
            <Label text="Phone" />
          </SimpleItem>
          {!currentContact?.IsPrefered && (
            <SimpleItem
              editorOptions={{
                disabled: !currentContactList?.length,
              }}
              dataField="IsPrefered"
              editorType="dxCheckBox"
            >
              <Label text="Is Principal" />
            </SimpleItem>
          )}
          {isEmail && (
            <SimpleItem dataField="IsToNewRequest" editorType="dxCheckBox">
              <Label text="Email New Request" />
            </SimpleItem>
          )}
          {isEmail && (
            <SimpleItem dataField="IsToReNewRequest" editorType="dxCheckBox">
              <Label text="Email Re-New Request" />
            </SimpleItem>
          )}
          {isEmail || isWebsite ? (
            <SimpleItem
              dataField="IsToTerminationRequest"
              editorType="dxCheckBox"
            >
              <Label text="Email Termination Request" />
            </SimpleItem>
          ) : null}
          <GroupItem>
            <EmptyItem />
          </GroupItem>
          <GroupItem colCount={3}>
            <EmptyItem />
            <ButtonItem
              buttonOptions={{
                text: 'OK',
                type: 'success',
                useSubmitBehavior: true,
              }}
            />
            <ButtonItem
              buttonOptions={{
                text: 'Cancel',
                type: 'normal',
                onClick: () => {
                  onPopupClose();
                },
              }}
            />
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
};

export default AddHospitalContactPopup;

AddHospitalContactPopup.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  currentContact: object,
  insertContact: func,
  currentContactList: array.isRequired,
  isEmail: bool,
  isWebsite: bool,
};
AddHospitalContactPopup.defaultProps = {
  currentContact: {},
  insertContact: () => {},
  isEmail: false,
  isWebsite: false,
};
