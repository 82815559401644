import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
`;

export const WrapperEmail = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;

  .ck.ck-editor__editable_inline {
    height: 200px;
    border: 1px solid red;
  }
`;

export const WrapperHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  gap: 8px;
  border-radius: 12px;
  :hover {
    background-color: #dddddd50;
    cursor: pointer;
  }
`;

export const WrapperImage = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 75px;
    height: 75px;
  }
`;

export const WrapperInfos = styled.div`
  width: calc(100% - 68px);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;

  div {
    i {
      color: #337ab7;
      font-size: 0.85rem;
    }

    p {
      color: #717171;
    }
  }
`;

export const EmailTitle = styled.h3`
  font-weight: ${({ isRead }) => (isRead ? 'normal' : 'bold')};
`;

export const Content = styled.div`
  width: fit-content;
  max-width: 1000px;
  width: 800px;
  @media (max-width: 1000px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 12px;
`;

export const WrapperReply = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 12px;
  flex-direction: column;
  transition: 0.7s;

  /* border: 1px solid red; */
`;

export const WrapperReplyButton = styled.div`
  width: 100%;
  height: fit-content;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
  padding: 5px 0;
`;

export const WrapperReplyContent = styled.div`
  width: 100%;
  height: ${({ hasHeight }) => (hasHeight ? 'fit-content' : '0px')};
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: 0.7s;
  border-radius: 6px;
  overflow: hidden;
`;

export const ReplyContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  background-color: #f1f1f140;
  border: 1px solid #dddddd;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  /* position: relative; */
`;

export const WrapperReplyButtons = styled.div`
  width: calc(100% - 16px);
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: absolute;
  bottom: 8px; */
`;

export const WrapperAttachments = styled.div`
  border: 1px solid #dddddd;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;

  gap: 8px;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .list {
    display: flex;
    gap: 8px;
    max-width: 100%;
    overflow-x: auto;
  }
`;

export const Attachments = styled.div`
  .doc {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 2px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
  p {
    width: 100%;
    word-break: break-all;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
  }
  padding: 8px;
  height: 140px;
  /* min-height:; */
  min-width: 100px;
  max-width: 100px;
  border: 1px ${({ dashed }) => (dashed ? 'dashed #eeeeee' : 'solid #dddddd')};
  border-radius: 6px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  position: relative;
  gap: 4px;

  img {
    width: 40px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    gap: 8px;
  }

  .trash {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4px;
    top: 4px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    transition: 0.5s;
    border: none;
    z-index: 2;

    :hover {
      cursor: pointer;
      background-color: #a1a1a140;
    }
    .trashImg {
      width: 13px !important;
      height: 14px !important;
      opacity: 0.6;
      :hover {
        opacity: 1;
      }
    }
  }
  button {
    border: none;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 24px;

    padding: 6px 8px;
    text-decoration: none;

    span {
      font-weight: 600;
      font-size: 0.8rem;
      color: #000;
    }

    :hover {
      cursor: pointer;
      background-color: #eee;
    }
  }
`;

export const WrapperEmailContent = styled.div``;
