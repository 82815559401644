import Box, { Item } from 'devextreme-react/box';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/ui/load-panel';

import { bool, func, object } from 'prop-types';
import React, { useState } from 'react';

import { Container, Label } from './styles';

function OktaUserDeletePopup({
  visible,
  onVisibleChange,
  emrAccessData,
  // deleteOktaUserFn,
}) {
  const [deleting, setDeleting] = useState(false);

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        // deleteOktaUserFn();
        setDeleting(true);
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        onVisibleChange(false);
      },
    },
  ];

  return (
    <Popup
      visible={visible}
      title={`${emrAccessData?.DisplayName}`}
      onHidden={() => onVisibleChange(false)}
      width={400}
      height={200}
      showCloseButton={false}
      disabled={deleting}
    >
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={deleting}
        message="Deleting..."
        closeOnOutsideClick={false}
      />
      <Container>
        <Box direction="row" height="250px" width="100%">
          <Item ratio={3}>
            <Box direction="col" width="100%" height="100%">
              <Item ratio={1} shrink>
                <Label>
                  Do you want to delete this user from Okta Application?
                </Label>
              </Item>
            </Box>
          </Item>
        </Box>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
}

OktaUserDeletePopup.propTypes = {
  visible: bool.isRequired,
  onVisibleChange: func.isRequired,
  emrAccessData: object,
  // deleteOktaUserFn: func.isRequired,
};

OktaUserDeletePopup.defaultProps = {
  emrAccessData: {},
};

export default OktaUserDeletePopup;
