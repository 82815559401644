import React from 'react';
// import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import Avatar from '../contacts/Avatar';

// import { delUser } from '../../store/modules/userLogged/actions';
// import { closeAllChat } from '../../store/modules/chat/actions';

import {
  Container,
  BoxTop,
  FullName,
  BoxBottom,
  BoxField,
  BoxLabel,
  LabelField,
  IconField,
  WrapperSignout,
  WrapperFooter,
} from './styles';
import API from '../../services/API';

const Profile = ({ user, handlePopoverClose }) => {
  // const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = async () => {
    try {
      const oneSignalId = Cookies.get('oneSignalId');
      if (oneSignalId) {
        await API.sendRequest(
          `Device/oneSignalDelete/${oneSignalId}`,
          'DELETE'
        );
      }
      Cookies.remove('token');
      Cookies.remove('refreshtoken');
      Cookies.remove('oneSignalId');
      handlePopoverClose();
      // dispatch(delUser());
      // dispatch(closeAllChat());
      history.push('/');
    } catch (e) {
      console.log('error: ', e);
    }
  };

  return (
    <Container>
      <BoxTop>
        {user && <Avatar contact={user} h={98} w={98} />}
        <FullName>{user && user.Username}</FullName>
      </BoxTop>

      <BoxBottom>
        <BoxField>
          <BoxLabel>
            <IconField src="assets/icon-department.png" alt="department" />
            <LabelField>Department</LabelField>
          </BoxLabel>
          <TextField
            id="field-department"
            value={user ? user.Department : ''}
            disabled
          />
        </BoxField>

        <BoxField>
          <BoxLabel>
            <IconField src="assets/icon-jobtitle.png" alt="jobtitle" />
            <LabelField>Job Title</LabelField>
          </BoxLabel>
          <TextField
            id="field-jobtitle"
            value={user ? user.JobTitle : ''}
            disabled
          />
        </BoxField>
      </BoxBottom>
      <WrapperFooter>
        <WrapperSignout>
          <a onClick={handleLogout} href="/#">
            <p>Sign Out</p>
            <i className="fas fa-power-off" />
          </a>
        </WrapperSignout>
      </WrapperFooter>
    </Container>
  );
};

export default Profile;
