import Popup from 'devextreme-react/popup';
import DataGrid, { Column, Lookup, Grouping } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import React, { useState } from 'react';
import { bool, func } from 'prop-types';

import { ButtonsContainer, Container } from './styles';
import notify from 'devextreme/ui/notify';
import { LoadPanel } from 'devextreme-react';
import { useEmrAccess } from '../../contexts/EmrAccessContext';
import useEmrRequestMethodList from '../../../../services/requests/useEmrRequestMethodList';
import useAllADUserEmrAccess from '../../../../services/requests/useAllADUserEmrAccess';
import API from 'services/API';

const BatchRequestsPopup = ({ visible, onClose, refetchGrid }) => {
  const { batchRequests, setBatchRequests } = useEmrAccess();
  const { data: adUserEmrData } = useAllADUserEmrAccess();
  const { data: requestMethodList } = useEmrRequestMethodList();
  // const queryClient = useQueryClient();
  const [isSendingLoad, setSendingLoad] = useState(false);

  // const {
  //   mutate: sendRequests,
  //   isLoading: sendRequestsIsLoading,
  //   isSuccess: sendRequestsIsSuccess,
  //   isError: sendRequestsIsError,
  //   error: sendRequestsErrorData,
  // } = useMutation((requests) => emrSendRequests(requests), {
  //   onSettled: ({ data: requests }) => {
  //     let userIdsToInvalidate = [];
  //     requests.forEach((request) => {
  //       userIdsToInvalidate.push(request.UserId);
  //     });
  //     userIdsToInvalidate = [...new Set([...userIdsToInvalidate])]; // remove duplicates
  //     userIdsToInvalidate.forEach((userId) => {
  //       queryClient.invalidateQueries(['adUserEmrAccessList', userId]);
  //     });
  //     queryClient.invalidateQueries('allStaffUsers');
  //   },
  // });

  // console.log('sendRequestsIsLoading', sendRequestsIsLoading);

  // useEffect(() => {
  //   if (sendRequestsIsSuccess) {
  //     notify('Requests sent with success', 'success', 4000);
  //     setBatchRequests([]);
  //     onClose();
  //     return;
  //   }

  //   if (sendRequestsIsError) {
  //     notify(
  //       sendRequestsErrorData
  //         ? `Error updating requests: ${sendRequestsErrorData}`
  //         : 'Error updating requests',
  //       'error',
  //       4000
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [sendRequestsIsLoading, sendRequestsIsSuccess, sendRequestsIsError]);

  const handleRemoveEMRAccess = ({ row }) => {
    setBatchRequests((prevState) =>
      prevState.filter(
        (req) => req.ADUserEMRAccessId !== row.data.ADUserEMRAccessId
      )
    );
  };

  const handleSendRequests = async () => {
    setSendingLoad(true);

    try {
      const { data: response } = await API.sendRequest(
        'ADUserEMRAccess/Send',
        'POST',
        batchRequests
      );

      const checkErrorsResult = response?.find(
        (item) => item.ErrorMsg !== null
      );
      if (checkErrorsResult) {
        notify(checkErrorsResult.ErrorMsg, 'error', 9000);
        return;
      }

      notify('Requests sent with success', 'success', 4000);
      refetchGrid();
      setBatchRequests([]);
      setSendingLoad(false);
      onClose();
    } catch (error) {
      notify('Error to send requests, please try again', 'error', 4000);
    }

    setSendingLoad(false);
  };

  return (
    <Popup
      title={`Confirm Request${
        batchRequests?.length > 1 ? 's' : ''
      } Submission`}
      visible={visible}
      onHiding={onClose}
      width={600}
      height="auto"
    >
      <Container>
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={isSendingLoad}
          message="Sending requests..."
          closeOnOutsideClick={false}
        />
        <DataGrid
          dataSource={batchRequests}
          showBorders
          showColumnLines
          showRowLines
          rowAlternationEnabled
          height={400}
          keyExpr="ADUserEMRAccessId"
        >
          <Grouping autoExpandAll />
          <Column dataField="UserId" caption="Name">
            <Lookup
              dataSource={adUserEmrData}
              valueExpr="UserId"
              displayExpr="DisplayName"
            />
          </Column>
          <Column dataField="HospitalName" groupIndex={0} />
          <Column dataField="EMRRequestMethodId" caption="Request Method">
            <Lookup
              dataSource={requestMethodList}
              valueExpr="EMRRequestMethodId"
              displayExpr="EMRRequestMethodName"
            />
          </Column>
          <Column
            type="buttons"
            buttons={[{ icon: 'trash', onClick: handleRemoveEMRAccess }]}
          />
        </DataGrid>
        <ButtonsContainer>
          <Button
            text={`Submit Queued Request${
              batchRequests?.length > 1 ? 's' : ''
            }`}
            disabled={!batchRequests?.length}
            onClick={handleSendRequests}
            icon="fas fa-paper-plane"
            type="default"
          />
          <Button text="Cancel" onClick={onClose} />
        </ButtonsContainer>
      </Container>
    </Popup>
  );
};

BatchRequestsPopup.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  refetchGrid: func.isRequired,
};

export default BatchRequestsPopup;
