import React, { useEffect, useState } from 'react';
import API from '../../services/API';
import notify from 'devextreme/ui/notify';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { Container } from './styles';

function EMRCredentialsLoading() {
  const { push } = useHistory();
  const [loadDetails, setLoadDetails] = useState('Loading token info');

  useEffect(() => {
    async function getCredentials() {
      const currentUrl = window.location.href;
      const getIndexToken = currentUrl.search('token=');
      const tokenValue = currentUrl.substring(
        getIndexToken + 6,
        currentUrl.length
      );
      try {
        setLoadDetails('Loading decrypting token');
        const respDecryptLink = await API.sendRequest(
          'EMRSetup/DecryptLink',
          'POST',
          {
            Link: tokenValue,
          }
        );
        if (respDecryptLink.status !== 200) {
          setLoadDetails(`${respDecryptLink.data.ErrorMsg}`);
          return;
        }
        Cookies.remove('token');
        Cookies.remove('refreshtoken');
        Cookies.set('token', respDecryptLink.data.Token);
        Cookies.set('refreshtoken', respDecryptLink.data.RefreshToken);
        setLoadDetails('Loading Request info');
        const { data: respEMRAccess, status } = await API.sendRequest(
          `ADUserEMRAccess/${respDecryptLink.data.ADUserEMRAccessId}`,
          'GET'
        );
        if (status !== 200) {
          setLoadDetails(`${respDecryptLink.data.ErrorMsg}`);
          return;
        }
        push({ pathname: 'emr-access', state: { link: respEMRAccess } });
        setLoadDetails('token info');
      } catch (error) {
        notify('Error when trying to get information', 'error', 3000);
      }
    }
    getCredentials();
  }, [push]);

  return (
    <Container>
      <span>{`${loadDetails}`}</span>
    </Container>
  );
}

export default EMRCredentialsLoading;
