import styled from 'styled-components';

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  /* height: ${({ $height }) => $height || 'fit-content'} !important; */
  height: fit-content;
  padding-top: 10px;
  & + div {
    border-top: 1px solid #ddd;
  }
`;

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  height: ${({ height }) => height || 'fit-content'};
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
`;

export const FieldSet = styled.fieldset`
  border: unset;
  background: unset;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  height: fit-content;

  .label {
    width: 110px;
  }
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 12px;
  /* margin-bottom: 12px; */
  /* height: 48px; */
`;
