import OktaUserDeletePopup from 'components/popups/OktaUserDeletePopup';
import { CheckBox, DateBox, ScrollView, Validator } from 'devextreme-react';
import Button from 'devextreme-react/button';
import LoadPanel from 'devextreme-react/load-panel';
import Popup from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import { CompareRule, RequiredRule } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';

import { format, parseISO } from 'date-fns';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import API from 'services/API';
import { emrUpdateRequestStatus } from 'services/requests/emrRequestsPost';
import useEmrRequestStatusList from 'services/requests/useEmrRequestStatusList';
import SelectBoxItemRender from './SelectBoxItemRender';
import { ButtonsRow, Container, FieldSet, FormContainer } from './styles';

function EmrAccessEditPopup(
  { forceRefetch, terminationPage, setAutoRefetchGrid = () => {} },
  ref
) {
  const [emrAccessDraft, setEmrAccessDraft] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [showDeletePopupOktaUser, setShowDeletePopupOktaUser] = useState(false);
  const [isRenewCredentials, setIsRenewCredentials] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { data: requestStatusDataList } = useEmrRequestStatusList();

  const [visible, setVisible] = useState(false);
  const [emrAccess, setCurrentData] = useState(null);
  const [errorMsg, setErrorMsg] = useState({
    visible: false,
    message: '',
  });

  const [enablePassword, setEnablePassword] = useState(true);

  const [statusController, setStatusController] = useState({
    statusList: [],
    editEnabled: false,
  });

  const returnStatus = (requestData) => {
    if (terminationPage) {
      // Page: act-access-termination (ACT Termination Page)
      if ([3]?.includes(requestData?.EMRRequestMethodId)) {
        if ([2, 4, 5]?.includes(requestData?.EMRRequestStatusId)) {
          setStatusController({
            statusList: requestStatusDataList?.filter((item) =>
              [2, 4, 5].includes(item?.EMRRequestStatusId)
            ),
            editEnabled: false,
          });
        }
        if ([7]?.includes(requestData?.EMRRequestStatusId)) {
          setStatusController({
            statusList: requestStatusDataList?.filter((item) =>
              [7, 8].includes(item?.EMRRequestStatusId)
            ),
            editEnabled: true,
          });
        }
        if ([8]?.includes(requestData?.EMRRequestStatusId)) {
          setStatusController({
            statusList: requestStatusDataList?.filter((item) =>
              [8].includes(item?.EMRRequestStatusId)
            ),
            editEnabled: false,
          });
        }
      } else {
        // Requests Method: Others
        setStatusController({
          statusList: requestStatusDataList?.filter((item) =>
            [requestData?.EMRRequestStatusId, 8]?.includes(
              item?.EMRRequestStatusId
            )
          ),
          editEnabled: true,
        });
      }
      return;
    }

    if (requestData?.EMRRequestStatusId === 0) {
      // Requests with status: Pending Submission
      if ([2, 4]?.includes(requestData?.EMRRequestMethodId)) {
        // Requests Method: Phone or Website
        setStatusController({
          statusList: requestStatusDataList?.filter((item) =>
            [0, 2, 4].includes(item?.EMRRequestStatusId)
          ),
          editEnabled: true,
        });
      }
      if ([1, 3]?.includes(requestData?.EMRRequestMethodId)) {
        // Requests Method: DocuSign or Email
        setStatusController({
          statusList: requestStatusDataList?.filter((item) =>
            [0].includes(item?.EMRRequestStatusId)
          ),
          editEnabled: false,
        });
      }
    }

    if (requestData?.EMRRequestStatusId === 1) {
      // Status: Submitted to Staff (DocuSign Only)
      setStatusController({
        statusList: requestStatusDataList?.filter((item) =>
          [1].includes(item?.EMRRequestStatusId)
        ),
        editEnabled: false,
      });
    }

    if (requestData?.EMRRequestStatusId === 2) {
      // Requests with status: Submitted to Hospital
      if ([2, 4]?.includes(requestData?.EMRRequestMethodId)) {
        // Requests Method: Phone or Website
        setStatusController({
          statusList: requestStatusDataList?.filter((item) =>
            [2, 4].includes(item?.EMRRequestStatusId)
          ),
          editEnabled: true,
        });
      }
      if ([1, 3]?.includes(requestData?.EMRRequestMethodId)) {
        // Requests Method: DocuSign or Email
        setStatusController({
          statusList: requestStatusDataList?.filter((item) =>
            [2].includes(item?.EMRRequestStatusId)
          ),
          editEnabled: false,
        });
      }
    }

    if (requestData?.EMRRequestStatusId === 3) {
      // Status: Approved - Missing credentials (DocuSign)
      setStatusController({
        statusList: requestStatusDataList?.filter((item) =>
          [3, 4].includes(item?.EMRRequestStatusId)
        ),
        editEnabled: true,
      });
    }

    if (requestData?.EMRRequestStatusId === 4) {
      // Requests with status: Approved - Completed
      setStatusController({
        statusList: requestStatusDataList?.filter((item) =>
          [4].includes(item?.EMRRequestStatusId)
        ),
        editEnabled: false,
      });
    }

    if (requestData?.EMRRequestStatusId === 5) {
      // Requests with status: Returned from Hospital
      setStatusController({
        statusList: requestStatusDataList?.filter((item) =>
          [5, 4].includes(item?.EMRRequestStatusId)
        ),
        editEnabled: true,
      });
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      changeVisibility: (data) => {
        setCurrentData(data);
        setVisible((old) => !old);
        if (data.ErrorMsg) {
          setErrorMsg({
            visible: true,
            message:
              data.ErrorMsg === 'The input data is not a complete block.'
                ? 'The password could not be displayed due to a decoding error. Please renew the password.'
                : data.ErrorMsg,
          });
          setTimeout(() => setErrorMsg({ visible: false, message: '' }), 12000);
        }
      },
      setData: (data) => setCurrentData(data),
    }),
    []
  );

  const returnView = (status) => !!(status === 3 || status === 4);

  useEffect(() => {
    setEmrAccessDraft(
      returnView(emrAccess?.EMRRequestStatusId)
        ? { ...emrAccess, RepeatPassword: emrAccess?.Password }
        : emrAccess
    );
    returnStatus(emrAccess);

    // eslint-disable-next-line
  }, [emrAccess]);

  // const getUserFromOkta = async () => {
  //   const { data: userData } = await API.sendRequest(
  //     `ADUser/ListComplete/${emrAccessDraft?.UserId}`,
  //     'get'
  //   );
  // const { data: userOkta } = await API.sendRequest(
  //   `Okta/GetUserByEmail/${userData.User.Email}`,
  //   'get'
  // );
  //   return { userData };
  // };

  const handleConfirm = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    const dataToSend = { ...emrAccessDraft };
    delete dataToSend.RepeatPassword;
    let message = '';

    if (
      JSON.stringify(dataToSend) === JSON.stringify(emrAccess) &&
      !isRenewCredentials
    ) {
      setVisible(false);
      setShowPassword(false);
      setShowRepeatPassword(false);
      setEnablePassword(true);
      setAutoRefetchGrid(true);
      setIsUpdating(false);
      setIsRenewCredentials(false);
      return;
    }

    if (
      emrAccess?.EMRRequestStatusId !== emrAccessDraft?.EMRRequestStatusId ||
      JSON.stringify(dataToSend) !== JSON.stringify(emrAccess)
    ) {
      if (
        emrAccessDraft?.EMRRequestStatusId === 3 &&
        (!emrAccessDraft?.Username || !emrAccessDraft?.Password)
      ) {
        notify(
          'Inform user credentials before approve the access',
          'error',
          4000
        );
        setIsUpdating(false);
        return;
      }
      if (
        emrAccess?.EMRRequestStatusId !== 3 ||
        (emrAccess?.EMRRequestStatusId === 3 &&
          emrAccess?.Notes !== emrAccessDraft?.Notes)
      ) {
        const isTerminatedData = {
          ...dataToSend,
          TerminationCompleted: parseISO(
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
          ),
        };
        const res = await emrUpdateRequestStatus(
          terminationPage && emrAccessDraft?.EMRRequestStatusId === 8
            ? isTerminatedData
            : dataToSend
        );
        if (res?.data?.ErrorMsg) {
          message = res?.data?.ErrorMsg;
        }
      }
    }

    if (
      (isRenewCredentials && emrAccess?.EMRRequestStatusId === 4) ||
      emrAccessDraft?.EMRRequestStatusId === 4
    ) {
      try {
        const res = await API.sendRequest(
          enablePassword
            ? 'ADUserEMRAccess/UpdatePassword'
            : 'ADUserEMRAccess/UpdatePasswordRenew',
          'post',
          enablePassword
            ? {
                ADUserEMRAccessId: emrAccess?.ADUserEMRAccessId,
                Username: emrAccessDraft?.Username,
                Password: emrAccessDraft?.Password,
              }
            : {
                ADUserEMRAccessId: emrAccess?.ADUserEMRAccessId,
                Username: '',
                Password: '',
              }
        );

        if (res?.data?.ErrorMsg) {
          if (message) {
            message = `${message} ${res?.data?.ErrorMsg}`;
          }
        }
      } catch (err) {
        notify(`Error: ${err}`, 'error', 5000);
        setIsUpdating(false);
        return;
      }
    }
    if (message) {
      notify(
        message === 'Unable to send notification, target not found'
          ? 'Saved, but ' + message?.toLowerCase()
          : message,
        message === 'Unable to send notification, target not found'
          ? 'warning'
          : 'error',
        5000
      );
    } else notify('Request updated successfully', 'success', 5000);

    setVisible(false);
    setShowPassword(false);
    setShowRepeatPassword(false);
    setEnablePassword(true);
    setIsUpdating(false);
    setIsRenewCredentials(false);
    forceRefetch();
  };

  if (visible) {
    return (
      <Popup
        onHiding={() => {
          setVisible(false);
          setShowPassword(false);
          setShowRepeatPassword(false);
          setEnablePassword(true);
          setAutoRefetchGrid(true);
          setIsRenewCredentials(false);
        }}
        onHidden={() => {
          setShowPassword(false);
          setShowRepeatPassword(false);
          setEnablePassword(true);
          setAutoRefetchGrid(true);
          setIsRenewCredentials(false);
        }}
        onInitialized={() => {
          // setAutoRefetchGrid(false);
        }}
        visible={visible}
        title="Edit Request"
        width={750}
        maxHeight="95%"
        height={710}
      >
        <OktaUserDeletePopup
          visible={showDeletePopupOktaUser}
          emrAccessData={emrAccess}
          onVisibleChange={setShowDeletePopupOktaUser}
        />
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={isUpdating}
          message="Updating request..."
          closeOnOutsideClick={false}
        />
        <form onSubmit={handleConfirm} style={{ height: '100%' }}>
          <Container>
            <ScrollView>
              <FormContainer>
                <FieldSet width="calc(50% - 7px)">
                  <label>Name:</label>
                  <TextBox
                    width="100%"
                    value={emrAccessDraft?.DisplayName || ''}
                    readOnly
                  />
                </FieldSet>
                <FieldSet width="calc(50% - 7px)">
                  <label>Okta Tile:</label>
                  <TextBox
                    width="100%"
                    value={emrAccessDraft?.HospitalGroupName}
                    readOnly
                  />
                </FieldSet>
                <FieldSet width="calc(50% - 7px)">
                  <label>Status:</label>
                  <SelectBox
                    disabled={!statusController?.editEnabled}
                    dataSource={statusController?.statusList}
                    valueExpr="EMRRequestStatusId"
                    displayExpr="EMRRequestStatusName"
                    value={emrAccessDraft?.EMRRequestStatusId}
                    onValueChanged={(e) => {
                      setEmrAccessDraft((prevState) => ({
                        ...prevState,
                        EMRRequestStatusId: e.value,
                      }));
                    }}
                    itemRender={SelectBoxItemRender}
                    width="100%"
                  />
                  {/* <SelectBox
                    disabled={
                      (emrAccess?.EMRRequestStatusId === 2 &&
                        emrAccess?.EMRRequestMethodId !== 4 &&
                        emrAccess?.EMRRequestMethodId !== 2) ||
                      emrAccess?.EMRRequestStatusId === 4
                      // || emrAccess?.EMRRequestStatusId === 3
                      //  || emrAccess?.EMRRequestMethodId === 1
                    }
                    dataSource={
                      emrAccess?.EMRRequestMethodId === 3
                        ? requestStatusDataList?.filter(
                            (status) => status.EMRRequestStatusId !== 3
                          )
                        : requestStatusDataList
                    }
                    valueExpr="EMRRequestStatusId"
                    displayExpr="EMRRequestStatusName"
                    value={emrAccessDraft?.EMRRequestStatusId}
                    onValueChanged={(e) => {
                      setEmrAccessDraft((prevState) => ({
                        ...prevState,
                        EMRRequestStatusId: e.value,
                      }));
                    }}
                    itemRender={SelectBoxItemRender}
                    width="100%"
                  /> */}
                </FieldSet>
                <FieldSet width="calc(50% - 7px)">
                  <label>Expiration On:</label>
                  <DateBox
                    width="100%"
                    value={emrAccessDraft?.ExpiresOn}
                    readOnly
                  />
                </FieldSet>
                <FieldSet>
                  <label>Notes:</label>
                  <TextArea
                    width="100%"
                    height={120}
                    value={emrAccessDraft?.Notes}
                    onValueChanged={(e) => {
                      setEmrAccessDraft((prevState) => ({
                        ...prevState,
                        Notes: e.value,
                      }));
                    }}
                    readOnly={emrAccess.EMRRequestStatusId === 4}
                  />
                </FieldSet>
                {!terminationPage && emrAccess?.EMRRequestStatusId === 4 && (
                  <FieldSet align="center" flexDirection="row">
                    <CheckBox
                      value={isRenewCredentials}
                      onValueChanged={(e) => setIsRenewCredentials(e.value)}
                    />
                    <label>Renew Credentials</label>
                  </FieldSet>
                )}
                {((emrAccess?.EMRRequestStatusId === 4 && isRenewCredentials) ||
                  (emrAccess?.EMRRequestMethodId === 3 &&
                    emrAccess?.EMRRequestStatusId === 5 &&
                    emrAccessDraft?.EMRRequestStatusId === 4) ||
                  (emrAccess?.EMRRequestMethodId !== 3 &&
                    emrAccessDraft?.EMRRequestStatusId === 4)) &&
                  !terminationPage && (
                    <FieldSet align="center" flexDirection="row">
                      <CheckBox
                        value={!enablePassword}
                        onValueChanged={(e) => setEnablePassword(!e.value)}
                      />
                      <label>
                        {emrAccess?.EMRRequestStatusId === 4
                          ? 'Renew without Credentials'
                          : 'Approve without Credentials'}
                      </label>
                    </FieldSet>
                  )}
                {!terminationPage &&
                  enablePassword &&
                  returnView(emrAccessDraft?.EMRRequestStatusId) && (
                    <FieldSet width="50%">
                      <label>Username:</label>
                      <TextBox
                        width="100%"
                        value={emrAccessDraft?.Username}
                        onValueChanged={(e) => {
                          setEmrAccessDraft((prevState) => ({
                            ...prevState,
                            Username: e.value,
                          }));
                        }}
                        readOnly={
                          !isRenewCredentials &&
                          emrAccess?.EMRRequestStatusId === 4
                        }
                      >
                        <Validator>
                          {isRenewCredentials ||
                            (returnView(emrAccessDraft?.EMRRequestStatusId) &&
                              !(
                                emrAccess?.EMRRequestStatusId === 4 &&
                                !emrAccessDraft?.Username
                              ) && (
                                <RequiredRule message="Username is required" />
                              ))}
                        </Validator>
                      </TextBox>
                    </FieldSet>
                  )}
                {!terminationPage &&
                  enablePassword &&
                  returnView(emrAccessDraft?.EMRRequestStatusId) && (
                    <FieldSet width="50%">
                      <label>Password:</label>
                      <div className="flex">
                        <TextBox
                          width={302}
                          value={emrAccessDraft?.Password}
                          mode={showPassword ? 'text' : 'password'}
                          onValueChanged={(e) => {
                            setEmrAccessDraft((prevState) => ({
                              ...prevState,
                              Password: e.value,
                            }));
                          }}
                          readOnly={
                            !isRenewCredentials &&
                            emrAccess?.EMRRequestStatusId === 4
                            // &&
                            // returnView(emrAccessDraft?.EMRRequestStatusId)
                          }
                        >
                          <Validator>
                            {isRenewCredentials ||
                              (returnView(emrAccessDraft?.EMRRequestStatusId) &&
                                !(
                                  emrAccess?.EMRRequestStatusId === 4 &&
                                  !emrAccessDraft?.Password
                                ) && (
                                  <RequiredRule message="Password is required" />
                                ))}
                          </Validator>
                        </TextBox>
                        <Button
                          icon={
                            showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                          }
                          stylingMode="outlined"
                          onClick={() => {
                            setShowPassword((prevState) => !prevState);
                          }}
                        />
                      </div>
                    </FieldSet>
                  )}
                {!terminationPage &&
                  enablePassword &&
                  returnView(emrAccessDraft?.EMRRequestStatusId) && (
                    <FieldSet width="50%">
                      <label>Repeat Password:</label>
                      <div className="flex">
                        <TextBox
                          width={302}
                          value={emrAccessDraft?.RepeatPassword}
                          mode={showRepeatPassword ? 'text' : 'password'}
                          inputAttrs={{ autoComplete: 'off' }}
                          onValueChanged={(e) =>
                            setEmrAccessDraft((prevState) => ({
                              ...prevState,
                              RepeatPassword: e.value,
                            }))
                          }
                          readOnly={
                            !isRenewCredentials &&
                            emrAccess?.EMRRequestStatusId === 4

                            // isRenewCredentials &&
                            // returnView(emrAccessDraft?.EMRRequestStatusId)
                          }
                        >
                          {/* <Validator>
                      {isRenewCredentials ||
                        (returnView(emrAccessDraft?.EMRRequestStatusId) && (
                          <RequiredRule message="Password is required" />
                        ))}
                    </Validator> */}

                          <Validator>
                            {isRenewCredentials ||
                              (returnView(emrAccessDraft?.EMRRequestStatusId) &&
                                !(
                                  emrAccess?.EMRRequestStatusId === 4 &&
                                  !emrAccessDraft?.RepeatPassword
                                ) && (
                                  <RequiredRule message="Repeat Password is required" />
                                ))}
                            {isRenewCredentials ||
                              (returnView(
                                emrAccessDraft?.EMRRequestStatusId
                              ) && (
                                <CompareRule
                                  message="Passwords do not match"
                                  comparisonTarget={() =>
                                    emrAccessDraft?.Password
                                  }
                                />
                              ))}
                          </Validator>
                        </TextBox>
                        <Button
                          icon={
                            showRepeatPassword
                              ? 'fas fa-eye-slash'
                              : 'fas fa-eye'
                          }
                          // stylingMode="outlined"
                          onClick={() => {
                            setShowRepeatPassword((prevState) => !prevState);
                          }}
                        />
                      </div>
                    </FieldSet>
                  )}
                {errorMsg.visible && (
                  <span style={{ color: 'red' }}>{errorMsg.message}</span>
                )}
              </FormContainer>
            </ScrollView>

            <ButtonsRow>
              {/* {emrAccess?.EMRRequestStatusId === 3 && (
            <Button
              text="Remove Okta User"
              type="danger"
              width={160}
              onClick={() => setShowDeletePopupOktaUser(true)}
              />
            )} */}
              <Button text="OK" type="default" width={100} useSubmitBehavior />
              <Button
                text="Cancel"
                type="normal"
                width={100}
                onClick={() => {
                  setVisible(false);
                  setShowPassword(false);
                  setShowRepeatPassword(false);
                  setEnablePassword(true);
                  setAutoRefetchGrid(true);
                }}
              />
            </ButtonsRow>
          </Container>
        </form>
      </Popup>
    );
  }
}

export default forwardRef(EmrAccessEditPopup);
