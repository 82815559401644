import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export default makeStyles(() => ({
  colorCellRender: {
    height: '32px',
    padding: '0 5px',
    lineHeight: '32px',
  },
  colorCellRenderTd: {
    padding: '0 !important',
  },
  divGridContainer: {
    margin: 'auto',
    width: '95%',
    height: '77vh',
    textAlign: 'center',
  },
  Header: {
    display: 'flex',
  },
  cellRenderTd: {
    padding: '0 !important',
  },
}));

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
`;

export const AppCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  font-weight: 500;
  height: ${({ appQty }) => (appQty === 1 ? 45 : appQty === 2 ? 22.5 : 15)}px;
  width: 100%;
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  :hover {
    cursor: pointer;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
