import React from 'react';

import { Button, NumberBox, SelectBox } from 'devextreme-react';
import { bool, func, object, string } from 'prop-types';
import { WrapperButton, WrapperContent, WrapperScheduleBox } from './styles';

function ScheduleItem({ data, setAlertList, isEditing, title }) {
  return (
    <WrapperScheduleBox>
      <WrapperContent>
        {isEditing && <h5 style={{ marginTop: '2px' }}>⋮⋮</h5>}
        <label>{`${title} Alert`}</label>
        <label>/</label>
        {/* <label>Time Before:</label> */}
        <NumberBox
          showSpinButtons
          readOnly={!isEditing}
          width={80}
          min={1}
          onValueChanged={(e) => {
            setAlertList((prev) =>
              prev.map((item) => {
                if (
                  item.ACTExpirationScheduleId === data?.ACTExpirationScheduleId
                ) {
                  return {
                    ...item,
                    TimeBefore: e.value,
                  };
                }
                return item;
              })
            );
          }}
          value={data?.TimeBefore}
        />
        <SelectBox
          dataSource={[
            {
              TimeType: 1,
              TimeDesc: data?.TimeBefore === 1 ? 'Day Before' : 'Days Before',
            },
            {
              TimeType: 2,
              TimeDesc: data?.TimeBefore === 1 ? 'Week Before' : 'Weeks Before',
            },
          ]}
          width={140}
          readOnly={!isEditing}
          onValueChanged={(e) => {
            setAlertList((prev) =>
              prev.map((item) => {
                if (
                  item.ACTExpirationScheduleId === data?.ACTExpirationScheduleId
                ) {
                  return {
                    ...item,
                    TimeType: e.value,
                  };
                }
                return item;
              })
            );
          }}
          displayExpr="TimeDesc"
          valueExpr="TimeType"
          value={data?.TimeType}
        />
      </WrapperContent>
      <WrapperButton>
        <Button
          icon="trash"
          visible={isEditing}
          type="danger"
          hint="Delete"
          onClick={() =>
            setAlertList((prev) =>
              prev?.filter(
                (item) =>
                  item?.ACTExpirationScheduleId !==
                  data?.ACTExpirationScheduleId
              )
            )
          }
        />
      </WrapperButton>
    </WrapperScheduleBox>
  );
}

export default ScheduleItem;

ScheduleItem.propsTypes = {
  data: object,
  setAlertList: func,
  isEditing: bool,
  title: string,
};

ScheduleItem.defaultProps = {
  data: {},
  setAlertList: () => {},
  isEditing: false,
  title: '',
};
