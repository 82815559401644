import { format } from 'date-fns';
import { useQuery } from 'react-query';
import API from '../API';

async function getAllADUserEmrAccess() {
  // ListAllFromSQL
  const { data } = await API.sendRequest('ADUserEMRAccess/UserList', 'GET');

  return data;
}

export default function useAllADUserEmrAccess(enableRefetch) {
  return useQuery('allADUserEmrAccess', getAllADUserEmrAccess, {
    // refetchInterval: enableRefetch ? 1 * 60 * 1000 : false, // 5 minutes
    refetchOnWindowFocus: false,
  });
}

async function getADUsersDeactivation() {
  const { data } = await API.sendRequest(
    'ADUserEMRAccess/DeactivationList',
    'GET'
  );
  return data;
}

export function useADUserDeactivation() {
  return useQuery('ADUserDeactivation', getADUsersDeactivation, {
    refetchOnWindowFocus: false,
  });
}

export async function getListExpiresADUserEmrAccess(dateData) {
  const dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";

  const { data } = await API.sendRequest(
    'ADUserEMRAccess/ListExpires',
    'POST',
    {
      StartDate: format(dateData.startDate, dateFormat),
      EndDate: format(dateData.endDate, dateFormat),
    }
  );

  return data;
}

export function useGetListExpiresADUserEmrAccess(dateData) {
  return useQuery('listExpiresADUserEmrAccess', () =>
    getListExpiresADUserEmrAccess(dateData)
  );
}
