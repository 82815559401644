import React, { useState } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import {
  MasterDetail,
  Column,
  DataGrid,
  Selection,
  Paging,
  Editing,
} from 'devextreme-react/data-grid';
import usePermissions from '../../hooks/usePermissions';
import DetailTemplate from '../../components/RoleDetails';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

// import SyncGroupsADPopup from '../components/popups/SyncGroupsADPopup';
import RoleDeletePopup from '../../components/popups/RoleDeletePopup';
import RolePermissionsPopup from '../../components/popups/RolePermissionsPopup';
import Message from '../../components/message/Message';

import useStyles from '../styles/styles';

const getRoles = () => {
  const m_Roles = new CustomStore({
    key: 'RoleId',
    load: async () => {
      const { data } = await API.sendRequest(`Role`, 'get');
      return data;
    },
    insert: async (data) => {
      await API.sendRequest(`Role`, 'post', data);
    },
    update: async (id, data) => {
      await API.sendRequest(`Role`, 'post', data);
    },
    remove: async (id) => {
      await API.sendRequest(`Role/${id}`, 'delete');
    },
  });

  return m_Roles;
};

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const Role = () => {
  // const [syncPopupVisible, setSyncPopupVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [permissionsVisible, setPermissionsVisible] = useState(false);
  const [roles, setRoles] = useState(getRoles());
  const [currentRole, setCurrentRole] = useState(null);
  const [error, setError] = useState('');

  const classes = useStyles();

  const handleMessageClose = () => {
    setError('');
  };

  // const setSyncGroupsVisible = (visible) => {
  // setSyncPopupVisible(visible);
  //   setRoles(getRoles());
  // };

  const showPermissions = (e) => {
    setCurrentRole(e.row.data);
    setPermissionsVisible(true);
  };

  const onDeleteClick = (e) => {
    if (e.row.data.RoleId < 3) {
      setError('This is a System Role. Cannot be deleted');
      return;
    }

    setCurrentRole(e.row.data);
    setDeleteVisible(true);
  };

  const afterDelete = (refresh) => {
    setDeleteVisible(false);
    if (refresh) {
      setRoles(getRoles());
    }
  };

  // const contentReady = (e) => {
  //   if (!e.component.getSelectedRowKeys().length) {
  //     e.component.selectRowsByIndexes(0);
  //   }
  // };

  const selectionChanged = (e) => {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  };

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (!ACL.Admin) {
    return (
      <LoadPermissions>
        You don't have access to this page. For Administrators Only
      </LoadPermissions>
    );
  }
  /** ****************************** */

  return (
    <div className={classes.divGridContainer}>
      <Message
        message={error}
        variant="error"
        open={!!error}
        handleClose={handleMessageClose}
      />
      {/* <div id={'header'} className={classes.Header}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => setSyncPopupVisible(true)}
        >
          Sync from AD Groups
        </Button>
      </div>
       <SyncGroupsADPopup
        visible={syncPopupVisible}
        onVisibleChange={(visible) => setSyncGroupsVisible(visible)}
      /> */}
      {currentRole && (
        <RolePermissionsPopup
          visible={permissionsVisible}
          role={currentRole}
          setPermissionsVisible={setPermissionsVisible}
        />
      )}

      <RoleDeletePopup
        visible={deleteVisible}
        role={currentRole}
        afterDelete={afterDelete}
      />

      <DataGrid
        id="gridContainer"
        dataSource={roles}
        // keyExpr="RoleId"
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        onSelectionChanged={selectionChanged}
        // onContentReady={contentReady}
        onRowUpdating={onRowUpdating}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />
        <Selection mode="single" />
        <Editing
          useIcons
          mode="form"
          allowAdding
          allowUpdating
          allowDeleting={false}
        />
        <Column dataField="RoleDesc" caption="Role" />
        <Column
          caption="Actions"
          type="buttons"
          width={100}
          buttons={[
            {
              hint: 'Permissions',
              icon: 'fas fa-user-cog',
              onClick: showPermissions,
            },
            {
              hint: 'Remove',
              icon: 'trash',
              onClick: onDeleteClick,
            },
          ]}
        />
        <MasterDetail enabled component={DetailTemplate} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default Role;
