import React, { useState, useEffect, useRef } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import {
  Column,
  DataGrid,
  Paging,
  Lookup,
  Editing,
  SearchPanel,
  Pager,
  // GroupPanel,
  // Grouping,
  Selection,
  RequiredRule,
  AsyncRule,
  // Validator,
  // PatternRule,
} from 'devextreme-react/data-grid';

import TextBox from 'devextreme-react/text-box';

import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  // onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

const ADUsers = new CustomStore({
  key: 'LogonName',
  load: async () => {
    const data = (await API.sendRequest(`ADUser/ListAllFromAD`, 'get')).data;
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`ADUser/SaveToAD`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`ADUser/SaveToAD`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`ADUser/${id}`, 'delete');
  },
});

const ADUserFromAD = () => {
  const [deptos, setDeptos] = useState(null);
  const [managers, setManagers] = useState(null);

  const dataGridRef = useRef();

  const classes = useStyles();

  useEffect(() => {
    async function init() {
      setDeptos((await API.sendRequest(`Department`, 'get')).data);
      setManagers((await API.sendRequest(`ADUser/ListManagers`, 'get')).data);
    }

    init();
  }, []);

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /*********************************/

  // obtém o componentes para uso com o form aberto
  let displayName;
  const handleDisplayNameInitialized = (e) => {
    displayName = e.component;
  };

  let firstName;
  const handleFirstNameInitialized = (e) => {
    firstName = e.component;
  };

  let lastName;
  const handleLastNameInitialized = (e) => {
    lastName = e.component;
  };

  let initials;
  const handleInitialsInitialized = (e) => {
    initials = e.component;
  };

  let email;
  const handleEmailInitialized = (e) => {
    email = e.component;
  };

  let logonName;
  const handleLogonNameInitialized = (e) => {
    logonName = e.component;
  };

  //remove os caracteres não numericos dos phones, para o formulário
  const handlePhoneInitialized = (e) => {
    var item = e.component;
    item.option(
      'value',
      item.option('value').replace('(', '').replace(')', '').replace(' ', '')
    );
  };

  //mudança no firstname atualiza initials, email e logoname
  const FirstNameValueChanged = (cell, e) => {
    var _ln = lastName.option('value') ? lastName.option('value') : ' ';
    var _dn = `${e.value.trim()} ${_ln}`;
    displayName.option('value', _dn.trim());

    var _ini = _dn
      .split(' ')
      .map((item) => {
        return item[0];
      })
      .join('')
      .toUpperCase()
      .padEnd(5);
    initials.option('value', _ini.substring(0, 5).trim());

    if (!email.option('disabled')) {
      var _email = `${e.value
        .padEnd(1)[0]
        .trim()}${_ln.trim()}@njsharingnetwork.org`;
      email.option('value', _email.trim().toLowerCase());

      var _logon = `${e.value.trim()}${_ln[0]}`;
      logonName.option('value', _logon.trim().toLowerCase());
    }

    cell.setValue(e.value);
  };

  //mudança no lastname atualiza initials, email e logoname
  const LastNameValueChanged = (cell, e) => {
    var _fn = firstName.option('value')
      ? firstName.option('value').padEnd(1)
      : ' ';
    var _dn = `${_fn} ${e.value.trim()}`;
    displayName.option('value', _dn);

    var _ini = _dn
      .split(' ')
      .map((item) => {
        return item[0];
      })
      .join('')
      .toUpperCase()
      .padEnd(5);
    initials.option('value', _ini.substring(0, 5).trim());

    if (!email.option('disabled')) {
      var _email = `${_fn[0].trim()}${e.value
        .trim()
        .replace(' ', '')}@njsharingnetwork.org`;
      email.option('value', _email.toLowerCase());

      var _logon = `${_fn}${e.value.padEnd(1)[0].trim()}`;
      logonName.option('value', _logon.trim().toLowerCase());
    }

    cell.setValue(e.value);
  };

  const textBoxCellRender = (cell) => {
    return (
      <TextBox
        maxLength={100}
        defaultValue={cell.value}
        valueChangeEvent="keyup"
        onInitialized={
          cell.item.dataField === 'FirstName'
            ? handleFirstNameInitialized
            : handleLastNameInitialized
        }
        onValueChanged={(e) =>
          cell.item.dataField === 'FirstName'
            ? FirstNameValueChanged(cell, e)
            : LastNameValueChanged(cell, e)
        }
      />
    );
  };

  const asyncValidation = (params) => {
    return API.sendRequest(`ADUser/UserExist/${params.value}`, 'get').then(
      (x) => !x.data
    );
  };

  const validatePhoneNumbers = (data) => {
    if (data.BusinessPhone && data.BusinessPhone.trim().length > 0) {
      data.BusinessPhone = getPhone(data.BusinessPhone);
    }

    if (data.Fax && data.Fax.trim().length > 0) {
      data.Fax = getPhone(data.Fax);
    }

    if (data.HomePhone && data.HomePhone.trim().length > 0) {
      data.HomePhone = getPhone(data.HomePhone);
    }

    if (data.Mobile && data.Mobile.trim().length > 0) {
      data.Mobile = getPhone(data.Mobile);
    }

    if (data.Pager && data.Pager.trim().length > 0) {
      data.Pager = getPhone(data.Pager);
    }

    return data;
  };

  const getPhone = (value) => {
    return value.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
  };

  const onEditorPreparing = (e) => {
    if (
      e.parentType === 'dataRow' &&
      (e.dataField === 'Email' || e.dataField === 'LogonName')
    ) {
      e.editorOptions.disabled = !e.row.isNewRow;
    }

    if (
      e.parentType === 'dataRow' &&
      e.dataField === 'Active' &&
      e.row.isNewRow
    ) {
      e.editorOptions.value = true;
    }

    if (
      e.parentType === 'dataRow' &&
      e.dataField === 'Synchronize' &&
      e.row.isNewRow
    ) {
      e.editorOptions.value = true;
    }

    if (
      e.parentType === 'dataRow' &&
      e.dataField === 'IsManager' &&
      e.row.isNewRow
    ) {
      e.editorOptions.value = false;
    }
  };

  const onRowUpdating = (options) => {
    options.newData = { ...options.oldData, ...options.newData };
    options.newData.Department = deptos.find(
      (item) => item.DepartmentId === options.newData.DepartmentId
    ).DepartmentDesc;
    options.newData.Company = 'NJ Sharing Network';
    options.newData = validatePhoneNumbers(options.newData);
  };

  const onRowInserting = (options) => {
    options.data.Company = 'NJ Sharing Network';
    options.data.ExchangeDB = deptos.find(
      (item) => item.DepartmentId === options.data.DepartmentId
    ).ExchangeDB;
    options.data.Department = deptos.find(
      (item) => item.DepartmentId === options.data.DepartmentId
    ).DepartmentDesc;
    options.data = validatePhoneNumbers(options.data);
  };

  const onRowValidating = async (e) => {
    if (e.newData.LogonName) {
      var data = (
        await API.sendRequest(`ADUser/UserExist/${e.newData.LogonName}`, 'get')
      ).data;
      e.errorText = 'Logon Name already exists';
      e.isValid = !data;
    }
  };

  const onInitNewRow = async (e) => {
    e.data.Active = true;
    e.data.Synchronize = true;
    e.data.IsManager = false;
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'breadCrumb',
    });

    // if (ACL.Bulk) {
    //   e.toolbarOptions.items.unshift({
    //     location: 'after',
    //     widget: 'dxButton',
    //     options: {
    //       icon: 'far fa-address-book',
    //       hint: 'Active Directory',
    //       //onClick: notificationGeneralClick,
    //     },
    //   });
    // }
  };

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={ADUsers}
        keyExpr="UserId"
        allowColumnReordering={true}
        showBorders={true}
        rowAlternationEnabled={true}
        showRowLines={true}
        focusedRowEnabled
        onRowInserting={onRowInserting}
        onRowUpdating={onRowUpdating}
        onRowValidating={onRowValidating}
        onToolbarPreparing={onToolbarPreparing}
        onEditorPreparing={onEditorPreparing}
        onInitNewRow={onInitNewRow}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        ref={dataGridRef}
      >
        <SearchPanel visible width={240} placeholder="Search..." />

        <Paging defaultPageSize={150} />
        <Pager
          showPageSizeSelector
          allowedPageSizes={[100, 150, 200]}
          showInfo
        />
        <Selection mode="single" />
        <Editing
          mode="popup"
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        ></Editing>

        <Column
          dataField="FirstName"
          editCellRender={textBoxCellRender}
          visible={false}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="LastName"
          editCellRender={textBoxCellRender}
          visible={false}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="DisplayName"
          width={120}
          editorOptions={{
            maxLength: 100,
            onInitialized: handleDisplayNameInitialized,
          }}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="Initials"
          visible={false}
          editorOptions={{
            maxLength: 5,
            onInitialized: handleInitialsInitialized,
          }}
        />
        <Column
          dataField="Description"
          width={250}
          editorOptions={{
            maxLength: 100,
          }}
        />
        <Column
          dataField="JobTitle"
          visible={false}
          editorOptions={{
            maxLength: 200,
          }}
        >
          <RequiredRule />
        </Column>
        <Column dataField="DepartmentId" width={150} caption="Department">
          <RequiredRule />
          <Lookup
            allowClearing
            dataSource={deptos}
            displayExpr="DepartmentDesc"
            valueExpr="DepartmentId"
          />
        </Column>
        <Column dataField="Manager" caption="Manager">
          <Lookup
            allowClearing
            dataSource={managers}
            displayExpr="DisplayName"
            valueExpr="ManagerName"
          />
        </Column>
        <Column
          dataField="BusinessPhone"
          visible={false}
          editorOptions={{
            maxLength: 50,
            mode: 'Tel',
            mask: '(000) 000-0000',
            onInitialized: handlePhoneInitialized,
          }}
        />
        <Column
          dataField="HomePhone"
          visible={false}
          editorOptions={{
            maxLength: 50,
            mode: 'Tel',
            mask: '(000) 000-0000',
            onInitialized: handlePhoneInitialized,
          }}
        />
        <Column
          dataField="Mobile"
          width={120}
          editorOptions={{
            maxLength: 50,
            mode: 'Tel',
            mask: '(000) 000-0000',
            onInitialized: handlePhoneInitialized,
          }}
        />
        <Column
          dataField="Fax"
          visible={false}
          width={120}
          editorOptions={{
            maxLength: 50,
            mode: 'Tel',
            mask: '(000) 000-0000',
            onInitialized: handlePhoneInitialized,
          }}
        />
        <Column
          dataField="Pager"
          visible={false}
          width={120}
          editorOptions={{
            maxLength: 50,
            mask: '(000) 000-0000',
            onInitialized: handlePhoneInitialized,
          }}
        ></Column>
        <Column
          dataField="IPPhone"
          visible={false}
          editorOptions={{
            maxLength: 50,
          }}
        />
        <Column
          dataField="Email"
          editorOptions={{
            maxLength: 200,
            onInitialized: handleEmailInitialized,
          }}
        />
        <Column
          dataField="LogonName"
          width={100}
          editorOptions={{
            maxLength: 50,
            onInitialized: handleLogonNameInitialized,
          }}
        >
          <AsyncRule
            message="Logon Name already exists"
            validationCallback={asyncValidation}
          />
        </Column>
        <Column dataField="IsManager" caption="Manager" width={100} />
        <Column dataField="Active" width={90} />
        <Column
          dataField="Synchronize"
          caption="Synchronize as a Staff Contact"
          width={90}
        />

        <Column
          dataField="CreatedAt"
          dataType="datetime"
          width={140}
          allowEditing={false}
        />

        <Column
          dataField="ChangedAt"
          dataType="datetime"
          width={140}
          allowEditing={false}
        />

        {/* <Column dataField="ADStatusId" caption="Status">
          <Lookup
            dataSource={statusAD}
            displayExpr="ADStatusDesc"
            valueExpr="ADStatusId"
          />
        </Column> */}
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default ADUserFromAD;
