import Button from 'devextreme-react/button';
import React, { useState } from 'react';
import Switch from '../../components/Switch';
import useStyles from '../styles/styles';
import {
  DataGridContainer,
  Header,
  HeaderButtons,
  Title,
  TitleWrapper,
} from './styles';
import { LoadPermissions } from '../../global';
import ApplicationGrid from './components/ApplicationGrid';
import EmployeeGrid from './components/EmployeeGrid';
import PopupApplication from './components/popups/PopupAplication';
import PopupDelete from './components/popups/PopupDelete';
import {
  deleteOktaApp,
  deleteOktaAppMember,
  useGetOktaApps,
} from '../../services/requests/useOktaApp';
import { useOktaApp } from './context/OktaAppContext';
import useGetADUserListFromSQL from '../../services/requests/useGetADUserListFromSQL';
import notify from 'devextreme/ui/notify';
import queryClient from '../../services/queryClient';
import PopupAddAppMember from './components/popups/PopupAddAppMember';
// import PopupAddMembers from './components/popups/PopupAddMembers';

const OktaApplications = () => {
  const classes = useStyles();
  const [isApplicationView, setIsApplicationView] = useState(true);
  const { data: oktaAppList } = useGetOktaApps();
  const { data: adUserList } = useGetADUserListFromSQL();

  const {
    popupDeleteRef,
    // popupAddMembersRef,
    popupApplicationRef,
    // popupAddAppsRef,
    popupAddAppMemberRef,
    ACL,
  } = useOktaApp();

  /** *******************************
   * Access Control
   ******************************** */
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }
  /** ****************************** */

  function handleChangeTab() {
    setIsApplicationView(!isApplicationView);
  }

  const handleConfirmDel = async ({ data: confirmData }) => {
    if (confirmData.Type === 'MEMBER') {
      try {
        const res = await deleteOktaAppMember(
          confirmData?.OktaAppId,
          confirmData?.OktaApplicationId,
          confirmData?.UserId
        );
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        notify('Member removed successfully', 'success', 5000);
        confirmData.refetch();
      } catch (e) {
        notify(`Error: ${e.message}`, 'error', 5000);
      }
    } else {
      try {
        const res = await deleteOktaApp(confirmData?.OktaApplicationId);
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        queryClient.invalidateQueries(['oktaAppList']);
        notify('Okta Application removed successfully', 'success', 5000);
      } catch (e) {
        notify(`Error: ${e.message}`, 'error', 5000);
      }
    }
    // eslint-disable-next-line no-unused-expressions
    popupDeleteRef?.current?.setPopupData({ visible: false, data: null });
  };

  return (
    <div className={classes.divGridContainer}>
      <PopupApplication
        ref={popupApplicationRef}
        onClose={() =>
          popupApplicationRef?.current?.setPopupData({
            visible: false,
            data: null,
          })
        }
      />
      <PopupAddAppMember
        ref={popupAddAppMemberRef}
        onClose={() =>
          popupAddAppMemberRef?.current?.setPopupData({
            visible: false,
            data: null,
            refetch: () => {},
          })
        }
        adUserList={adUserList}
        oktaAppList={oktaAppList?.data}
      />
      {/* <PopupAddMembers
        ref={popupAddMembersRef}
        onClose={() =>
          popupAddMembersRef?.current?.setPopupData({
            visible: false,
            data: null,
          })
        }
      /> */}
      <PopupDelete
        ref={popupDeleteRef}
        onClose={() =>
          popupDeleteRef?.current?.setPopupData({
            visible: false,
            data: null,
          })
        }
        onConfirm={handleConfirmDel}
      />
      <Header>
        <TitleWrapper>
          <Title>Okta/Applications</Title>
          <Switch
            id="switch-view"
            toggled={!isApplicationView}
            onChange={() => handleChangeTab()}
            iconOn="fas fa-users"
            iconOff="fa fa-th-large"
            title={
              isApplicationView
                ? 'Switch to Employees'
                : 'Switch to Applications'
            }
            description={isApplicationView ? 'By Applications' : 'By Employees'}
          />
        </TitleWrapper>
        <HeaderButtons>
          {isApplicationView && (ACL?.AddNew || ACL?.Admin) && (
            <Button
              icon="plus"
              text="New Okta Application"
              type="default"
              onClick={() =>
                popupApplicationRef?.current?.setPopupData({
                  visible: true,
                  data: null,
                })
              }
              width={200}
            />
          )}
        </HeaderButtons>
      </Header>
      <DataGridContainer visible={isApplicationView}>
        <ApplicationGrid data={oktaAppList?.data} />
      </DataGridContainer>
      <DataGridContainer visible={!isApplicationView}>
        <EmployeeGrid data={adUserList} />
      </DataGridContainer>
    </div>
  );
};

export default OktaApplications;
