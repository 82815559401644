import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Content,
  Step,
  WrapperInput,
  TextLabel,
  SideImage,
  LogoWrapper,
  AppName,
  Logo,
  StepDetails,
  WarningInfo,
} from './styles';
import { Button, LoadIndicator, LoadPanel, TextBox } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import API from 'services/API';
import { TRIconSquareLogo } from 'assets/login/TRIconSquareLogo';

function AccountDeletion() {
  const [activeStep, setActiveStep] = useState(1);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const history = useHistory();

  const handleSendEmail = async () => {
    setIsLoading(true);
    if (email === '') {
      setIsLoading(false);
      notify('Email is required', 'error', 5000);
      return;
    }

    try {
      const emailToSend = await API.sendRequest(
        `UserExternal/CheckEmail/${email}`,
        'GET'
      );

      if (emailToSend.status === 200) {
        setIsLoading(false);
        setActiveStep(2);
        notify('Email sent', 'success', 5000);
      }

      if (emailToSend.status !== 200) {
        setIsLoading(false);
        throw new Error(emailToSend.data.ErrorMsg);
      }
    } catch (error) {
      setIsLoading(false);
      notify(error.message, 'error', 5000);
    }
  };
  const handleSendCode = async () => {
    setDeleting(true);
    if (code === '') {
      setDeleting(false);
      notify('Code is required', 'error', 5000);
      return;
    }

    try {
      const codeToSend = await API.sendRequest(
        `UserExternal/CheckCode/${email}/${code}`,
        'GET'
      );

      if (codeToSend.status === 200) {
        setDeleting(false);
        setActiveStep(2);
        setCode('');
        setEmail('');
        notify(
          'All contact data has been deleted successfully',
          'success',
          5000
        );
        history.push('/login');
      }

      if (codeToSend.status !== 200) {
        setDeleting(false);
        throw new Error(codeToSend.data.ErrorMsg);
      }
    } catch (error) {
      setDeleting(false);
      notify(error.message, 'error', 5000);
    }
  };
  return (
    <Container>
      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={deleting}
        message="Deleting Contact..."
        closeOnOutsideClick={false}
      />
      <Content>
        {activeStep === 1 && (
          <>
            <SideImage>
              <LogoWrapper>
                <Logo src="assets/warning.png" />
                <h2>Account Deletion</h2>
              </LogoWrapper>
              <StepDetails bgActive={activeStep} bdActive={activeStep}>
                <span>STEP 1</span>
                <span>
                  Enter the email address associated with your account. After
                  entering it and clicking the 'Delete my account' button, we
                  will send a verification code to your email.
                </span>
              </StepDetails>
              <StepDetails>
                <span>STEP 2</span>
                <span>
                  Please enter the code you received in your registration email
                  into the designated code field. After confirming that the code
                  is correct, we will proceed to delete* your user data.
                </span>
              </StepDetails>
              <WarningInfo>
                * All data will be permanently deleted, including: Name , Phone
                , number , Email , Password. <br />
                We do not retain data for any specified period; it will be
                permanently removed.
              </WarningInfo>
            </SideImage>
            <Step>
              <WrapperInput>
                <LogoWrapper>
                  <TRIconSquareLogo />
                  <AppName>
                    <span>Team Relay</span>
                    <label>by NJ Sharing Network</label>
                  </AppName>
                </LogoWrapper>
                <TextLabel>
                  <span>STEP 1</span>
                  <span>
                    We will send you a verification code on your email.
                  </span>
                </TextLabel>
                <TextBox
                  placeholder="Email"
                  width="100%"
                  value={email}
                  stylingMode="outlined"
                  onValueChanged={(e) => setEmail(e.value)}
                />
                <Button
                  onClick={handleSendEmail}
                  type="default"
                  width="100%"
                  height={40}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {isLoading && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginLeft: '-8px',
                        }}
                      >
                        <LoadIndicator height={20} />
                      </div>
                    )}
                    <span>Delete my account</span>
                  </div>
                </Button>
              </WrapperInput>
            </Step>
          </>
        )}
        {activeStep === 2 && (
          <>
            <SideImage>
              <LogoWrapper>
                <Logo src="assets/warning.png" />
                <h2>Account Deletion</h2>
              </LogoWrapper>
              <StepDetails>
                <span>STEP 1</span>
                <span>
                  Enter the email address associated with your account. After
                  entering it and clicking the 'Delete my account' button, we
                  will send a verification code to your email.
                </span>
              </StepDetails>
              <StepDetails bgActive={activeStep} bdActive={activeStep}>
                <span>STEP 2</span>
                <span>
                  Please enter the code you received in your registration email
                  into the designated code field. After confirming that the code
                  is correct, we will proceed to delete* your user data.
                </span>
              </StepDetails>
              <WarningInfo>
                * All data will be permanently deleted, including: Name , Phone
                , number , Email , Password. <br />
                We do not retain data for any specified period; it will be
                permanently removed.
              </WarningInfo>
            </SideImage>
            <Step>
              <WrapperInput>
                <LogoWrapper>
                  <TRIconSquareLogo />
                  <AppName>
                    <span>Team Relay</span>
                    <label>by NJ Sharing Network</label>
                  </AppName>
                </LogoWrapper>
                <TextLabel>
                  <span>STEP 2</span>
                  <span>
                    We sent a code to {`${email}`}
                    <br />
                    Please enter received code.
                  </span>
                </TextLabel>
                <div className="inputWrapper">
                  <TextBox
                    placeholder="0-0-0-0-0-0"
                    width={100}
                    stylingMode="outlined"
                    value={code}
                    maxLength={6}
                    onValueChanged={(e) => setCode(e.value)}
                    mask="0 0 0 0 0 0"
                    onFocusIn={() => setCode('')}
                  />
                </div>

                <Button
                  onClick={handleSendCode}
                  text="Confirm account deletion"
                  type="danger"
                  width="100%"
                  height={40}
                />
              </WrapperInput>
            </Step>
          </>
        )}
      </Content>
    </Container>
  );
}

export default AccountDeletion;
