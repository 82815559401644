import React from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import { Column, DataGrid, Paging } from 'devextreme-react/data-grid';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

const countries = new CustomStore({
  key: 'CountryId',
  load: async () => {
    const data = (await API.sendRequest(`Country`, 'get')).data;
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`Country`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`Country`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`Country/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const Country = () => {
  const classes = useStyles();

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /*********************************/

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id={'gridContainer'}
        dataSource={countries}
        //keyExpr={'CountryId'}
        allowColumnReordering={true}
        showBorders={true}
        rowAlternationEnabled={true}
        focusedRowEnabled={true}
        showRowLines={true}
        onRowUpdating={onRowUpdating}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />
        {/* <Editing
          mode={'form'}
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        /> */}

        <Column dataField={'CountryCode'} caption={'Code'} />
        <Column dataField={'CountryName'} caption={'Name'} />
        <Column dataField={'DDICode'} caption={'DDI'} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default Country;
