import { bool, func, object } from 'prop-types';
import React from 'react';

import { Container } from './styles';
import TextEditor from '../TextEditor';
import { LoadIndicator, LoadPanel } from 'devextreme-react';

function RenewCredentials({ emrSetupDraft, setEMRSetupDraft, isLoading }) {

  const requestTemplate = emrSetupDraft?.AccessRenewCredentialsEmailBody
    ;

  return (
    <Container>
      {isLoading && (
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={isLoading}
          message="Saving template..."
          closeOnOutsideClick={false}
        />
      )}
      {emrSetupDraft?.AccessRenewCredentialsEmailBody
        !== undefined ? (
        <TextEditor
          data={requestTemplate}
          setHtmlContent={(text) =>
            setEMRSetupDraft((prev) => ({
              ...prev,
              AccessRenewCredentialsEmailBody
                : text,
            }))
          }
          width="100%"
          height="100%"
        />
      ) : (
        <LoadIndicator id="large-indicator" height={40} width={40} />
      )}
    </Container>
  )
}

export default RenewCredentials;

RenewCredentials.propTypes = {
  isLoading: bool,
  emrSetupDraft: object,
  setEMRSetupDraft: func,
};

RenewCredentials.defaultProps = {
  isLoading: false,
  emrSetupDraft: {},
  setEMRSetupDraft: () => { },
};
