import React from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

import {
  Column,
  DataGrid,
  Paging,
  // Editing,
  Lookup,
  GroupPanel,
  Grouping,
} from 'devextreme-react/data-grid';

const states = new CustomStore({
  key: 'StateId',
  load: async () => {
    const data = (await API.sendRequest(`State`, 'get')).data;
    return data;
  },
});

const counties = new CustomStore({
  key: 'CountyId',
  load: async () => {
    const data = (await API.sendRequest(`County`, 'get')).data;
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`County`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`County`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`County/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const setStateValue = (rowData, value) => {
  //rowData.CityID = null;
  //this.defaultSetCellValue(rowData, value);
};

const County = () => {
  const classes = useStyles();

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /*********************************/

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id={'gridContainer'}
        dataSource={counties}
        //keyExpr={'CountyId'}
        allowColumnReordering={true}
        showBorders={true}
        rowAlternationEnabled={true}
        focusedRowEnabled={true}
        showRowLines={true}
        onRowUpdating={onRowUpdating}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <Paging enabled={false} />
        {/* <Editing
          mode={'form'}
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        /> */}

        <Column dataField={'CountyName'} caption={'Name'} />
        <Column
          dataField="StateId"
          caption="State"
          setCellValue={setStateValue}
          groupIndex={0}
        >
          <Lookup
            dataSource={states}
            displayExpr="StateName"
            valueExpr="StateId"
          />
        </Column>
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default County;
