import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import {
  Column,
  DataGrid,
  Paging,
  Lookup,
  Editing,
} from 'devextreme-react/data-grid';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

const externalUsers = new CustomStore({
  key: 'UserId',
  load: async () => {
    const data = (await API.sendRequest(`UserExternal`, 'get')).data;
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`UserExternal`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`UserExternal`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`UserExternal/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const ExternalUser = () => {
  const [interestTypes, setInterestTypes] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    async function init() {
      setInterestTypes((await API.sendRequest(`InterestType`, 'get')).data);
    }

    init();
  }, []);

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (!ACL.Admin) {
    return (
      <LoadPermissions>
        You don't have access to this page. For Administrators Only
      </LoadPermissions>
    );
  }
  /*********************************/

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id={'gridContainer'}
        dataSource={externalUsers}
        //keyExpr={'UserId'}
        allowColumnReordering={true}
        showBorders={true}
        onRowUpdating={onRowUpdating}
        rowAlternationEnabled={true}
        showRowLines={true}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />
        <Editing useIcons mode={'form'} allowDeleting={ACL.Delete} />

        <Column dataField={'LastName'} />
        <Column dataField={'FirstName'} />
        <Column dataField={'Email'} />
        <Column dataField={'Mobile'} />
        <Column dataField={'ValidationDate'} dataType="datetime" />
        <Column dataField={'InterestTypeId'} caption={'Interest'}>
          <Lookup
            dataSource={interestTypes}
            displayExpr="InterestTypeDesc"
            valueExpr="InterestTypeId"
          />
        </Column>
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default ExternalUser;
