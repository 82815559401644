import { DataGrid } from 'devextreme-react';
import { Button, Column, MasterDetail } from 'devextreme-react/data-grid';
import React from 'react';
import { array } from 'prop-types';

import AppDetailGrid from './AppDetailGrid';
import { useOktaApp } from '../../context/OktaAppContext';
import AppLogo from '../AppLogo';
import returnPhoto from '../../../../utils/returnPhoto';

function ApplicationGrid({ data }) {
  const { popupDeleteRef, popupApplicationRef } = useOktaApp();

  function renderLogo(dataCell) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <AppLogo url={returnPhoto(dataCell?.data?.OktaAppLogo)} />
      </div>
    );
  }

  return (
    <>
      <DataGrid
        dataSource={data}
        showBorders
        selection={{
          mode: 'single',
        }}
        allowColumnReordering
        rowAlternationEnabled
        keyExpr="OktaAppId"
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
      >
        <MasterDetail
          enabled
          component={({ data }) => <AppDetailGrid data={data?.row?.data} />}
        />
        <Column
          dataField="OktaAppLogo"
          caption="Logo"
          cellRender={(data) => renderLogo(data)}
          width={110}
        />
        <Column dataField="OktaAppLabel" caption="Name" />
        <Column dataField="OktaAppUrl" caption="Login Url" />
        <Column type="buttons" caption="Actions">
          <Button
            icon="link"
            onClick={(e) => window.open(e.row?.data?.OktaAppUrl, '_blank')}
          />
          <Button
            icon="edit"
            onClick={(e) =>
              popupApplicationRef?.current?.setPopupData({
                visible: true,
                data: e.row?.data,
              })
            }
          />
          <Button
            icon="trash"
            onClick={(e) =>
              popupDeleteRef?.current?.setPopupData({
                visible: true,
                data: {
                  OktaApplicationId: e?.row?.data?.OktaApplicationId,
                  Type: 'APP',
                  Message: `Do you want to delete application [${e.row?.data?.OktaAppLabel}]?`,
                },
              })
            }
          />
        </Column>
      </DataGrid>
    </>
  );
}

export default ApplicationGrid;

ApplicationGrid.propTypes = {
  data: array,
};

ApplicationGrid.defaultProps = {
  data: [],
};
