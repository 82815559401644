import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import usePermissions from '../../hooks/usePermissions';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import { Template } from 'devextreme-react/core/template';

import AppointmentTypeEditPopup from './Popups/AppointmentTypeEditPopup';
import AppointmentTypeDeletePopup from './Popups/AppointmentTypeDeletePopup';

import {
  Column,
  DataGrid,
  Paging,
  Lookup,
  SearchPanel,
  Pager,
} from 'devextreme-react/data-grid';
import pickTextColorBasedOnBgColor from '../../helpers/pickTextColorBasedOnBgColor';
import useStyles from '../styles/styles';
import HistoryPopup from '../../components/popups/HistoryPopup';
import getChangeHistory from '../../utils/getChangeHistory';

const AppointmentType = () => {
  const [areas, setAreas] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const [currentAppType, setCurrentAppType] = useState(null);
  const [deleteAppType, setDeleteAppType] = useState(null);
  const [editPopupVisible, setEditPopupVisible] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [historyPopupVisible, setHistoryPopupVisible] = useState(false);
  const [appTypeChangeHistory, setAppTypeChangeHistory] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    async function init() {
      setAreas((await API.sendRequest(`Area/ScheduleOnly`, 'get')).data);
      setAppointments((await API.sendRequest(`AppointmentType`, 'get')).data);
    }
    init();
  }, []);

  const colorCellRender = (data) => (
    <div
      className={classes.colorCellRender}
      style={{
        backgroundColor: data.value,
        color: pickTextColorBasedOnBgColor(data.value, '#fff', '#000'),
      }}
    >
      {data.value}
    </div>
  );

  // Work around to avoid use dxDateBox
  const hours = [
    { text: '12:00 AM', value: '00:00:00' },
    { text: '12:30 AM', value: '00:30:00' },
    { text: '1:00 AM', value: '01:00:00' },
    { text: '1:30 AM', value: '01:30:00' },
    { text: '2:00 AM', value: '02:00:00' },
    { text: '2:30 AM', value: '02:30:00' },
    { text: '3:00 AM', value: '03:00:00' },
    { text: '3:30 AM', value: '03:30:00' },
    { text: '4:00 AM', value: '04:00:00' },
    { text: '4:30 AM', value: '04:30:00' },
    { text: '5:00 AM', value: '05:00:00' },
    { text: '5:30 AM', value: '05:30:00' },
    { text: '6:00 AM', value: '06:00:00' },
    { text: '6:30 AM', value: '06:30:00' },
    { text: '7:00 AM', value: '07:00:00' },
    { text: '7:30 AM', value: '07:30:00' },
    { text: '8:00 AM', value: '08:00:00' },
    { text: '8:30 AM', value: '08:30:00' },
    { text: '9:00 AM', value: '09:00:00' },
    { text: '9:30 AM', value: '09:30:00' },
    { text: '10:00 AM', value: '10:00:00' },
    { text: '10:30 AM', value: '10:30:00' },
    { text: '11:00 AM', value: '11:00:00' },
    { text: '11:30 AM', value: '11:30:00' },
    { text: '12:00 PM', value: '12:00:00' },
    { text: '12:30 PM', value: '12:30:00' },
    { text: '1:00 PM', value: '13:00:00' },
    { text: '1:30 PM', value: '13:30:00' },
    { text: '2:00 PM', value: '14:00:00' },
    { text: '2:30 PM', value: '14:30:00' },
    { text: '3:00 PM', value: '15:00:00' },
    { text: '3:30 PM', value: '15:30:00' },
    { text: '4:00 PM', value: '16:00:00' },
    { text: '4:30 PM', value: '16:30:00' },
    { text: '5:00 PM', value: '17:00:00' },
    { text: '5:30 PM', value: '17:30:00' },
    { text: '6:00 PM', value: '18:00:00' },
    { text: '6:30 PM', value: '18:30:00' },
    { text: '7:00 PM', value: '19:00:00' },
    { text: '7:30 PM', value: '19:30:00' },
    { text: '8:00 PM', value: '20:00:00' },
    { text: '8:30 PM', value: '20:30:00' },
    { text: '9:00 PM', value: '21:00:00' },
    { text: '9:30 PM', value: '21:30:00' },
    { text: '10:00 PM', value: '22:00:00' },
    { text: '10:30 PM', value: '22:30:00' },
    { text: '11:00 PM', value: '23:00:00' },
    { text: '11:30 PM', value: '23:30:00' },
  ];

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  const addNewAppType = () => {
    setCurrentAppType({
      AppointmentTypeCode: '',
      AppointmentTypeDesc: '',
      Color: '',
      AreaId: 0,
      ShowInToday: true,
      AllDay: true,
      StartTime: '',
      EndTime: '',
      DayOff: false,
      Summary: false,
    });
    setEditPopupVisible(true);
  };

  const updateGrid = async () => {
    setAppointments((await API.sendRequest(`AppointmentType`, 'get')).data);
  };

  const handleDeleteAppType = ({ row }) => {
    setDeleteAppType(row.data);
    setDeletePopupVisible(true);
  };

  const editAppType = async (e) => {
    const { data } = await API.sendRequest(
      `AppointmentType/ListById/${e.row.data.AppointmentTypeId}`,
      'get'
    );
    setCurrentAppType(data);
    setEditPopupVisible(true);
  };

  const handleShowChangeHistory = async (e) => {
    await getChangeHistory(
      'AppointmentType',
      e.row.data.AppointmentTypeId
    ).then((ret) => {
      if (ret) {
        setAppTypeChangeHistory(ret);
        setHistoryPopupVisible(true);
      }
    });
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'breadCrumb',
    });

    if (ACL.AddNew) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: addNewAppType,
        },
      });
    }
  };

  const buttonsList = [];

  if (ACL.Update) {
    buttonsList.push(
      {
        hint: 'Show Change History',
        icon: 'fa fa-history',
        onClick: handleShowChangeHistory,
        visible: ACL?.Admin,
      },
      {
        hint: 'Edit Appointment Type',
        icon: 'edit',
        onClick: editAppType,
      }
    );
  }

  if (ACL.Delete) {
    buttonsList.push({
      hint: 'Delete Appointment Type',
      icon: 'trash',
      onClick: handleDeleteAppType,
    });
  }
  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={appointments}
        keyExpr="AppointmentTypeId"
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarPreparing}
        width="100%"
      >
        <SearchPanel visible width={240} placeholder="Search..." />

        <Paging defaultPageSize={20} />
        <Pager
          showPageSizeSelector
          allowedPageSizes={[20, 40, 60, 100, 200]}
          showInfo
        />

        <Column dataField="AppointmentTypeCode" caption="Code" width={90} />
        <Column dataField="AppointmentTypeDesc" caption="Description" />
        {/* <Column
          dataField="AppointmentTypeDesc"
          caption="Description"
          width={150}
          hidingPriority={0}
        /> */}
        <Column
          dataField="Color"
          width={85}
          cellRender={colorCellRender}
        // cssClass={classes.colorCellRenderTd}
        />
        <Column dataField="AreaId" width={140} caption="Area">
          <Lookup
            dataSource={areas}
            displayExpr="AreaDesc"
            valueExpr="AreaId"
          />
        </Column>
        <Column
          dataField="ShowInToday"
          width={140}
          caption="Show in [Home/Working]"
          hidingPriority={0}
        />
        <Column
          dataField="Overdue"
          width={100}
          caption="Past Appt."
          hidingPriority={0}
        />
        <Column dataField="AllDay" width={100} hidingPriority={0} />
        <Column id="StartTime" dataField="StartTime" width={100}>
          <Lookup dataSource={hours} displayExpr="text" valueExpr="value" />
        </Column>
        <Column id="EndTime" dataField="EndTime" width={100}>
          <Lookup dataSource={hours} displayExpr="text" valueExpr="value" />
        </Column>

        <Column dataField="DayOff" width={100} hidingPriority={0} />
        <Column dataField="Summary" width={120} hidingPriority={0} />
        <Column caption="Actions" type="buttons" buttons={buttonsList} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>

      {historyPopupVisible && (
        <HistoryPopup
          isVisible={historyPopupVisible}
          setIsVisible={setHistoryPopupVisible}
          changeHistory={appTypeChangeHistory}
        />
      )}

      {currentAppType && (
        <AppointmentTypeEditPopup
          currentAppType={currentAppType}
          areas={areas}
          updateGrid={updateGrid}
          setVisible={setEditPopupVisible}
          visible={editPopupVisible}
        />
      )}
      {deleteAppType && (
        <AppointmentTypeDeletePopup
          currentAppType={deleteAppType}
          updateGrid={updateGrid}
          setVisible={setDeletePopupVisible}
          visible={deletePopupVisible}
        />
      )}
    </div>
  );
};

export default AppointmentType;
