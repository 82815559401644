import Form, {
  ButtonItem,
  EmptyItem,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/ui/load-panel';
import { EmailRule, RequiredRule } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import API from '../../../../services/API';
import queryClient from '../../../../services/queryClient';
import { formatPhoneNumber } from '../../../../utils/format';

const AddEMRContactPopup = ({
  isVisible,
  setIsVisible,
  currentContact,
  updateData,
}) => {
  const [saving, setSaving] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (currentContact && currentContact?.Phones) {
      currentContact.Phones.forEach((item) => {
        item.PhoneNumber = item.PhoneNumber?.replace(/[^0-9]/g, '');
      });
    }
  }, []); //eslint-disable-line

  const bottomToolbarButtons = [
    {
      text: 'OK',
      type: 'success',
      // useSubmitBehavior: true,
      onClick: () => {
        handleSaveOnClick();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setIsVisible(false);
      },
    },
  ];

  async function handleSaveOnClick(e) {
    e.preventDefault();
    const phoneNumber = formRef.current.instance.option().formData;

    if (phoneNumber.Phones[0].PhoneNumber.length < 10) {
      return;
    }

    try {
      if (formRef.current.instance.validate().isValid) {
        setSaving(true);
        const dataToSend = {
          ...currentContact,
          Contact: {
            ...currentContact.Contact,
            Active: true,
            IsEMRContact: true,
          },

          Phones: [
            {
              ContactId: phoneNumber.Contact.ContactId,
              PhoneTypeId: 1,
              ContactPhoneOrder: 1,
              PhoneNumber: formatPhoneNumber(phoneNumber.Phones[0].PhoneNumber),
            },
          ],
        };
        const res = await API.sendRequest(
          'Contact/SaveComplete',
          'POST',
          dataToSend,
          true
        );
        if (res.status === 200) {
          queryClient.invalidateQueries('emrContactsList');
          notify('Contact saved', 'success', 2000);
          updateData(dataToSend, !!currentContact?.Contact?.ContactId);
          setIsVisible(false);
          setSaving(false);
        }
      } else {
        setSaving(false);
        notify(`Please check the highlighted fields.`, 'error', 2000);
      }
    } catch (e) {
      setSaving(false);
      notify(`Error: ${e.message}`, 'error', 2000);
    }
  }

  const mask = { mask: '(000) 000-0000', useMaskedValue: true };
  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      showCloseButton={false}
      showTitle={!!currentContact.ContactId}
      title={
        currentContact && currentContact.DisplayName
          ? currentContact.DisplayName
          : `${currentContact.FirstName} ${currentContact.LastName}`
      }
      width={400}
      height="auto"
    >
      <form
        style={{ width: '100%', height: '100%' }}
        onSubmit={handleSaveOnClick}
      >
        {bottomToolbarButtons?.map((buttonOptions, index) => (
          <ToolbarItem
            key={index}
            options={{ stylingMode: 'contained', ...buttonOptions }}
            widget="dxButton"
            toolbar="bottom"
            location="after"
          />
        ))}
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={saving}
          message="Saving..."
          closeOnOutsideClick={false}
        />
        <Form
          id="form"
          formData={currentContact}
          showColonAfterLabel
          labelLocation="left"
          // colCount={2}
          ref={formRef}
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
          }}
        >
          <GroupItem caption="Contact">
            <SimpleItem dataField="Contact.FirstName" editorType="dxTextBox">
              <RequiredRule message="First Name is required" />
              <Label text="First Name" />
            </SimpleItem>
            <SimpleItem dataField="Contact.LastName" editorType="dxTextBox">
              <RequiredRule message="Last Name is required" />
              <Label text="Last Name" />
            </SimpleItem>
            <SimpleItem dataField="Contact.Email" editorType="dxTextBox">
              <EmailRule message="Email is invalid" />
              <RequiredRule message="Email is required" />
              <Label text="Email" />
            </SimpleItem>
            <SimpleItem
              name="phone"
              dataField="Phones[0].PhoneNumber"
              editorType="dxTextBox"
              editorOptions={mask}
            >
              <RequiredRule message="Phone is required" />
              <Label text="Phone" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={3}>
            <EmptyItem />
            <ButtonItem
              buttonOptions={{
                text: 'OK',
                type: 'success',
                useSubmitBehavior: true,
              }}
            />
            <ButtonItem
              buttonOptions={{
                text: 'Cancel',
                type: 'normal',
                onClick: () => {
                  setIsVisible(false);
                },
              }}
            />
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
};

export default AddEMRContactPopup;

AddEMRContactPopup.propTypes = {
  isVisible: bool.isRequired,
  setIsVisible: func.isRequired,
  currentContact: object,
  updateData: func,
};
AddEMRContactPopup.defaultProps = {
  currentContact: {},
  updateData: () => {},
};
