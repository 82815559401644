import { useQuery } from 'react-query';
import API from '../API';

async function getDistListByUser() {
  const { data } = await API.sendRequest('ADUser/GetDistListByUser', 'GET');
  if (data) {
    const dataFormatted = data?.map((item) => ({
      ...item,
      DistributionLists: JSON.parse(item.DistributionLists),
    }));
    return dataFormatted;
  }
  return data;
}

export default function useGetDistListsByUser() {
  return useQuery('distListByUser', getDistListByUser, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}
