export const faList = [
  { Name: 'fas fa-book' },
  { Name: 'fas fa-book-medical' },
  { Name: 'fas fa-book-open' },
  { Name: 'fas fa-book-reader' },
  { Name: 'fas fa-laptop' },
  { Name: 'fas fa-laptop-code' },
  { Name: 'fas fa-laptop-medical' },
  { Name: 'fas fa-fingerprint' },
  { Name: 'fas fa-users' },
  { Name: 'fas fa-users-cog' },
  { Name: 'fas fa-building' },
  { Name: 'fas fa-briefcase' },
  { Name: 'fas fa-briefcase-medical' },
  { Name: 'fas fa-sort-down' },
  { Name: 'fas fa-sort-numeric-down' },
  { Name: 'fas fa-sort-numeric-down-alt' },
  { Name: 'fas fa-sort-numeric-up' },
  { Name: 'fas fa-sort-numeric-up-alt' },
  { Name: 'fas fa-sort-up' },
  { Name: 'fas fa-user-tie' },
  { Name: 'fas fa-hand-point-down' },
  { Name: 'fas fa-hand-point-left' },
  { Name: 'fas fa-hand-point-right' },
  { Name: 'fas fa-hand-point-up' },
  { Name: 'fas fa-code' },
  { Name: 'fas fa-code-branch' },
  { Name: 'fas fa-user-nurse' },
  { Name: 'fas fa-cog' },
  { Name: 'fas fa-cogs' },
  { Name: 'fas fa-wrench' },
  { Name: 'fas fa-screwdriver' },
];
