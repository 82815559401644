import { bool, func, string } from 'prop-types';
import React from 'react';
import {
  Container,
  SwitchButton,
  SwitchInput,
  SwitchLabel,
  LabelDescription,
} from './styles';

const Switch = ({
  iconOn,
  iconOff,
  textOn,
  textOff,
  toggled,
  onChange,
  id,
  title,
  description,
}) => (
  <Container>
    <SwitchInput
      id={id}
      type="checkbox"
      checked={toggled}
      onChange={onChange}
    />
    <SwitchLabel htmlFor={id}>
      <SwitchButton title={title}>
        {iconOn && iconOff ? (
          <i className={toggled ? iconOn : iconOff} />
        ) : toggled ? (
          textOn
        ) : (
          textOff
        )}
      </SwitchButton>
    </SwitchLabel>
    <LabelDescription>{description}</LabelDescription>
  </Container>
);

Switch.propTypes = {
  iconOn: string,
  iconOff: string,
  textOn: string,
  textOff: string,
  toggled: bool,
  onChange: func.isRequired,
  id: string.isRequired,
  title: string,
  description: string,
};

Switch.defaultProps = {
  iconOn: '',
  iconOff: '',
  textOn: '',
  textOff: '',
  toggled: false,
  title: '',
  description: '',
};

export default Switch;
