import React, { useState, useEffect } from 'react';
import { Column, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import RolesPopup from '../RolesPopup';

import API from '../../services/API';
import {
  Container,
  Content,
  BoxSubtitle,
  Subtitle,
  ColumnDivider,
  DataGridCustom,
} from './styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UserDetails({ data }) {
  const [roles, setRoles] = useState([]);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [openSnack, setOpenSnack] = useState(null);
  const [errorSnack, setErrorSnack] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    loadDataRoles().then((roles) => {
      if (isSubscribed) {
        setRoles(roles);
      }
    });

    return () => (isSubscribed = false);
    //eslint-disable-next-line
  }, []);

  async function loadDataRoles() {
    const res = await API.sendRequest(`UserRole/listroles/${data.key}`, 'get');

    return res.data;
  }

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const onDeleteClick = async (item) => {
    const response = await API.sendRequest('UserRole', 'DELETE', {
      UserId: data.key,
      RoleId: item.row.data.RoleId,
    });

    if (response) {
      const rolesRest = roles.filter((r) => r.RoleId !== item.row.data.RoleId);

      setRoles(rolesRest);
    } else {
      setErrorSnack(true);
      setOpenSnack(true);
    }
  };

  const handleRoleOpen = (event) => {
    event.preventDefault();

    setUserAnchorEl(event.currentTarget);
  };

  const handleRoleClose = () => {
    setUserAnchorEl(null);
  };

  return (
    <Container>
      <Content>
        <ColumnDivider>
          <BoxSubtitle>
            <Subtitle>ROLES</Subtitle>
            <IconButton aria-label="plus" size="small" onClick={handleRoleOpen}>
              <AddBoxOutlinedIcon />
            </IconButton>
          </BoxSubtitle>
          <DataGridCustom
            dataSource={roles}
            showBorders
            showRowLines
            hoverStateEnabled
          >
            <Scrolling mode="virtual" />
            <HeaderFilter visible allowSearch />
            <Column dataField="RoleDesc" />
            <Column
              caption="Actions"
              type="buttons"
              width={100}
              buttons={[
                {
                  hint: 'Delete',
                  icon: 'trash',
                  onClick: onDeleteClick,
                },
              ]}
            />
          </DataGridCustom>
        </ColumnDivider>
      </Content>

      {userAnchorEl && (
        <RolesPopup
          anchorEl={userAnchorEl}
          handleClose={handleRoleClose}
          title="Select Role"
          roles={roles}
          setRoles={setRoles}
          userId={data.key}
          setErrorSnack={setErrorSnack}
          handleClickSnack={handleClickSnack}
        />
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        {errorSnack ? (
          <Alert onClose={handleCloseSnack} severity="error">
            Error during operation. Please try again!
          </Alert>
        ) : (
          <Alert onClose={handleCloseSnack} severity="success">
            User add success!
          </Alert>
        )}
      </Snackbar>
    </Container>
  );
}
