import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

// import { Container } from './styles';

export default function CustomCheckbox({ id, value, handleChange }) {
  return (
    <Checkbox
      id={id}
      checked={value}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'primary checkbox1' }}
    />
  );
}
