import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';

import { TreeList, Editing, Column, Lookup } from 'devextreme-react/tree-list';

import usePermissions from '../../hooks/usePermissions';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import { Template } from 'devextreme-react/core/template';

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const Menu = () => {
  const [menus, setMenus] = useState(null);
  const [rootMenu, setRootMenu] = useState(null);
  const [menuRootId, setMenuRootId] = useState(1);
  const classes = useStyles();

  const rootMenuList = [
    {
      value: 1,
      text: 'Web Admin',
    },
    {
      value: 2,
      text: 'Mobile',
    },
  ];

  useEffect(() => {
    async function init() {
      setRootMenu(
        (await API.sendRequest(`Menu/Root/${menuRootId}`, 'get')).data
      );

      const menusTmp = new CustomStore({
        key: 'MenuId',
        load: async () => {
          const { data } = await API.sendRequest(
            `Menu/List/${menuRootId}`,
            'get'
          );
          return data;
        },
        insert: async (data) => {
          if (data?.ParentMenuId === 0) {
            data.ParentMenuId = null;
          }
          await API.sendRequest(`Menu`, 'post', data);
        },
        update: async (id, data) => {
          await API.sendRequest(`Menu`, 'post', data);
        },
        remove: async (id) => {
          await API.sendRequest(`Menu/${id}`, 'delete');
        },
      });

      setMenus(menusTmp);
    }

    init();
  }, [menuRootId]);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (!ACL.Admin) {
    return (
      <LoadPermissions>
        You don't have access to this page. For Administrators Only.
      </LoadPermissions>
    );
  }
  /** ****************************** */
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'breadCrumb',
      },
      {
        location: 'before',
        widget: 'dxSelectBox',
        options: {
          width: 200,
          items: rootMenuList,
          displayExpr: 'text',
          valueExpr: 'value',
          value: menuRootId,
          onValueChanged: menuRootChanged,
        },
      }
    );
  };

  const menuRootChanged = (e) => {
    setMenuRootId(e.value);
  };

  return (
    <div className={classes.divGridContainer}>
      <TreeList
        id="gridContainer"
        dataSource={menus}
        keyExpr="MenuId"
        parentIdExpr="ParentMenuId"
        allowColumnReordering
        showBorders
        onRowUpdating={onRowUpdating}
        rowAlternationEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          useIcons
          mode="form"
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        />

        <Column visible={false} dataField="ParentMenuId" caption="Parent Menu">
          <Lookup
            dataSource={rootMenu}
            displayExpr="MenuCaption"
            valueExpr="MenuId"
          />
        </Column>

        <Column dataField="MenuCaption" caption="Caption" />
        <Column dataField="MenuFullCaption" caption="BreadCrumb" />
        <Column dataField="MenuOrder" />
        <Column dataField="Url" />
        <Column dataField="Icon" />
        <Column dataField="MenuRootId">
          <Lookup
            dataSource={rootMenuList}
            displayExpr="text"
            valueExpr="value"
          />
        </Column>
        <Column dataField="Active" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </TreeList>
    </div>
  );
};

export default Menu;
