import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  background: #ffffff;
  padding: 10px;

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;

  .button-save > .dx-button-content {
    padding: 0 0 0 12px;
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }

  .button-indicator {
    position: absolute;
    left: 10px;
    color: #ffffff;
  }
  .button-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const WrapperField = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
`;
