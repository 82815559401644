import API from '../API';

export async function emrSaveTemplates(emrSaveTemplate) {
  const data = await API.sendRequest(
    'DocuSign/Templates/Save',
    'POST',
    emrSaveTemplate
  );
  return data;
}
