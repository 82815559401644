import React, { useState, useEffect } from 'react';

import { Container, DeleteButton } from './styles';
import config from '../../config';
import noPhoto from '../../assets/profile.png';
import iconConfig from '../../assets/config.jpg';
import Cookies from 'js-cookie';
import { any, bool, func, object } from 'prop-types';
import notify from 'devextreme/ui/notify';
import returnPhoto from '../../utils/returnPhoto';

export default function ImageSelector({
  contact,
  previewImg,
  onFileSelected,
  onFileDeleted,
  rounded,
  hasLimit,
  showDeleteButton,
}) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const token = Cookies.get('token');

  useEffect(() => {
    if (contact && contact.StatusPhotoId > 0) {
      setPreview(`${config.picUrl + contact.Username}?access_token=${token}`);
    }
    // eslint-disable-next-line
  }, []);

  function handleChange(e) {
    if (hasLimit) {
      if (e.target.files[0]?.size > 999999) {
        notify('The image size cannot exceed 1MB', 'error', 4000);
        return;
      }
    }
    if (e.target && e.target.files.length) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
      if (onFileSelected) {
        onFileSelected(e.target.files[0]);
      }
    }
  }

  const handleDelete = () => {
    if (onFileDeleted) {
      onFileDeleted();
    }
    if (contact) {
      contact.StatusPhotoId = 0;
    }
    setPreview(null);
    setFile(null);
  };

  return (
    <Container rounded={rounded}>
      <label htmlFor="image">
        <img
          className="contact-image-thumb-img"
          src={
            preview ||
            (rounded ? noPhoto : returnPhoto(previewImg) || iconConfig)
          }
          alt=""
          onError={() => !rounded && setPreview(iconConfig)}
        />
        <input
          type="file"
          id="image"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
        />
      </label>
      <DeleteButton
        icon="trash"
        hint="Delete profile photo"
        onClick={handleDelete}
        visible={showDeleteButton}
      />
      {hasLimit && <span>Maximum size: 1MB</span>}
    </Container>
  );
}

ImageSelector.propTypes = {
  contact: object,
  onFileSelected: func.isRequired,
  onFileDeleted: func.isRequired,
  rounded: bool,
  previewImg: any,
  hasLimit: bool,
  showDeleteButton: bool,
};

ImageSelector.defaultProps = {
  contact: {},
  rounded: true,
  previewImg: null,
  hasLimit: false,
  showDeleteButton: true,
};
