import React from 'react';

// import { Container } from './styles';

function AttachmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.464"
      height="12.885"
      viewBox="0 0 12.464 12.885"
    >
      <path
        id="attachment-01-svgrepo-com"
        d="M14.205,7.634,8.674,13.166A3.221,3.221,0,0,1,4.118,8.61L9.65,3.079a2.147,2.147,0,1,1,3.037,3.037L7.372,11.43A1.074,1.074,0,0,1,5.853,9.912l4.664-4.664"
        transform="translate(-2.449 -1.95)"
        fill="none"
        stroke="#326a95"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  );
}

export default AttachmentIcon;
