import React, { useState, useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import MenuPopup from './MenuPopup';
import DeleteMenuPopup from './DeleteMenuPopup';
import notify from 'devextreme/ui/notify';
import API from '../../../../services/API';
import { format, parseISO } from 'date-fns';

export default function AreaEditPopup({ visible, setVisible, currentArea }) {
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [useInSchedule, setUseInSchedule] = useState(false);
  const [canAddMenu, setCanAddMenu] = useState(false);
  const [menuPopup, setMenuPopup] = useState(false);
  const [deleteMenuPopup, setDeleteMenuPopup] = useState(false);
  const [lastChangeLabel, setLastChangeLabel] = useState('');

  useEffect(() => {
    setScheduleVisible(!!currentArea.AreaId);
    setUseInSchedule(currentArea.UseInSchedule);

    setCanAddMenu(!currentArea.ScheduleMenuId);

    if (currentArea && currentArea.AreaId) {
      API.sendRequest(
        `Audit/LastChange/Area/${currentArea.AreaId}`,
        'GET'
      ).then((res) => {
        if (res && res.status === 200 && res.data) {
          setLastChangeLabel(
            `Last change on ${format(
              parseISO(res.data.ChangeDate),
              "MM/dd/yyyy 'at' HH:mm a"
            )} by ${res.data.Username}`
          );
        }
      });
    }
  }, [currentArea]);

  const buttonsOptions = [
    {
      text: 'OK',
      type: 'success',
      onClick: () => {
        handleSave();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        setVisible(false);
      },
    },
  ];

  const periods = [
    {
      periodId: 1,
      periodDesc: 'Monthly',
    },
    {
      periodId: 2,
      periodDesc: 'Weekly',
    },
  ];

  const handleSave = async () => {
    if (currentArea.AreaId) {
      if (
        currentArea.UseInSchedule === true &&
        (currentArea.ScheduleMenuId === null ||
          currentArea.ScheduleMenuId === '')
      ) {
        notify(
          'Please, click in Add Schedule Menu to create a new menu for this area.',
          'error',
          4000
        );
        return;
      }
    }

    const res = await API.sendRequest('Area', 'post', currentArea);

    if (res.status === 200) {
      notify('Area saved.', 'success', 2000);
      setVisible(false);
      return;
    }
    notify(res.message, 'error', 2000);
  };

  const handleOnShowing = async () => {
    setScheduleVisible(!!currentArea.AreaId);
    setUseInSchedule(currentArea.UseInSchedule);

    if (!currentArea.ScheduleMenuId) {
      setCanAddMenu(true);
    }
  };

  const handleUseScheduleChanged = (e) => {
    setUseInSchedule(e.value);
  };

  const showLastChangeLabel = () => <span>{lastChangeLabel}</span>;

  return (
    <>
      {menuPopup && (
        <MenuPopup
          visible={menuPopup}
          setVisible={setMenuPopup}
          currentArea={currentArea}
        />
      )}
      {deleteMenuPopup && (
        <DeleteMenuPopup
          visible={deleteMenuPopup}
          setVisible={setDeleteMenuPopup}
          currentArea={currentArea}
        />
      )}
      <Popup
        onShowing={handleOnShowing}
        visible={visible}
        onHiding={() => setVisible(false)}
        width={700}
        height={580}
        showCloseButton={false}
        title={currentArea.AreaDesc}
      >
        <Form id="formEditArea" formData={currentArea}>
          <GroupItem caption="Area Data" name="area-data-container">
            <SimpleItem dataField="AreaDesc" editorType="dxTextBox">
              <Label text="Description" />
            </SimpleItem>
            <SimpleItem dataField="DistributionList" editorType="dxTextBox">
              <Label text="Distribution List" />
            </SimpleItem>
            <SimpleItem dataField="SummaryBefore" editorType="dxTextBox">
              <Label text="Summary Before" />
            </SimpleItem>
          </GroupItem>
          <GroupItem
            caption="Schedule Options"
            name="schedule-opt-container"
            visible={scheduleVisible}
            colCount={useInSchedule && !canAddMenu ? 2 : 1}
          >
            <SimpleItem
              dataField="UseInSchedule"
              editorType="dxCheckBox"
              colSpan={1}
              editorOptions={{
                onValueChanged: handleUseScheduleChanged,
                disabled: !canAddMenu,
              }}
            >
              <Label text="Use in Schedule" />
            </SimpleItem>
            <SimpleItem
              dataField="UseDraft"
              editorType="dxCheckBox"
              colSpan={!canAddMenu ? 2 : 1}
              visible={useInSchedule}
            >
              <Label text="Use Draft" />
            </SimpleItem>
            <SimpleItem
              dataField="UseTeams"
              editorType="dxCheckBox"
              colSpan={1}
              visible={useInSchedule}
            >
              <Label text="Use Teams" />
            </SimpleItem>

            <SimpleItem
              dataField="AvailabilityPeriod"
              editorType="dxSelectBox"
              editorOptions={{
                items: periods,
                valueExpr: 'periodId',
                displayExpr: 'periodDesc',
                width: 190,
              }}
              colSpan={1}
              visible={useInSchedule}
            >
              <Label text="Availability" />
            </SimpleItem>

            <SimpleItem
              dataField="HideAppointmentsToOtherAreas"
              editorType="dxCheckBox"
              colSpan={1}
            >
              <Label text="Hide Appointments To Others Areas" />
            </SimpleItem>

            <SimpleItem
              itemType={useInSchedule && canAddMenu ? 'button' : 'emptyItem'}
              colSpan={1}
              horizontalAlignment="center"
              buttonOptions={{
                icon: 'add',
                text: 'Add Schedule Menu',
                onClick: () => setMenuPopup(true),
              }}
              visible={useInSchedule && canAddMenu}
            />
            <SimpleItem />
            <SimpleItem
              itemType="button"
              colSpan={1}
              horizontalAlignment="center"
              buttonOptions={{
                icon: 'clear',
                text: 'Delete Schedule Menu',
                onClick: () => setDeleteMenuPopup(true),
              }}
              visible={useInSchedule && !canAddMenu}
            />
            <SimpleItem
              itemType="button"
              colSpan={1}
              horizontalAlignment="center"
              buttonOptions={{
                icon: 'edit',
                text: 'Change Icon Schedule Menu',
                onClick: () => setMenuPopup(true),
              }}
              visible={useInSchedule && !canAddMenu}
            />
          </GroupItem>
        </Form>
        <ToolbarItem
          toolbar="bottom"
          location="before"
          render={showLastChangeLabel}
        />
        {buttonsOptions.map((buttonOpt, index) => (
          <ToolbarItem
            key={index}
            options={{ stylingMode: 'contained', ...buttonOpt }}
            widget="dxButton"
            toolbar="bottom"
            location="after"
          />
        ))}
      </Popup>
    </>
  );
}

AreaEditPopup.propTypes = {
  visible: bool.isRequired,
  setVisible: func.isRequired,
  currentArea: object.isRequired,
};
