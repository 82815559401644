import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const InputRadio = styled.input`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

export const Header = styled.div`
  display: flex;
  font-weight: bold;
  padding-left: 20%;
  margin-bottom: 10px;
`;

export const Card = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;

  border: 1px solid;
  border-color: ${(props) => (props.diff ? '#ff9587' : '#dddddd')};
  background: ${(props) => props.diff && '#ff9587'};
  border-radius: 4px;
`;

export const Fields = styled.div`
  display: flex;
  justify-content: left;
  text-align: left;
  padding: 10px 0px;
  width: 80%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  display: flex;
  width: 50%;
  font-weight: normal;
  text-align: left;
  align-items: center;
`;

export const TitleLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 50%;
  text-align: left;
`;

export const FieldLabel = styled.div`
  font-weight: bold;
  width: 20%;
  text-align: left;
  margin-left: 5px;
`;
