import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const Container = styled.div`
  padding: 10px;
  margin-top: 5px;
`;

export const WrapperHeadCell = styled.div`
  color: rgb(151, 151, 151);

  p {
    color: #000;
    font-weight: bold;
  }
`;

export const CustomTableRow = styled(TableRow)`
  height: 120px;
`;

export const CustomGroupNameCell = styled(TableCell)`
  background-color: ${(props) => props.schedulecolor};
  color: ${(props) => props.color};
  font-weight: 600;
  width: 140px;
`;

export const RowDateGarbage = styled.div`
  display: flex;
  align-items: center;
`;
