import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  width: 50vw;
  height: 90vh;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;

  h3 {
    font-weight: 500;
    font-size: 20px;
  }
`;

export const LoadPermissions = styled.div`
  margin: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #3f51b5;
`;

export const ListsContainer = styled.div`
  display: flex;
  gap: 50px;
  height: 89%;
  width: 100%;
  margin-top: 10px;

  text-align: left;
`;

export const TeamsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 50%;
  height: 100%;

  overflow-y: auto;
`;

export const TeamListLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid #dddddd;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor};
  height: 42px;
  span {
    font-weight: 600;
    color: ${(props) => (props.bgColor ? props.textColor : '#2d6da3')};
  }
  div {
    display: flex;
    gap: 5px;
  }
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2.5px;

  margin: 1.5px 0;

  border: 1px solid #dddddd;
  border-radius: 4px;
`;

export const NoTeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
