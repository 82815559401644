import React, { useRef, useState } from 'react';
import DataGrid, {
  Column,
  MasterDetail,
  Button,
  Selection,
} from 'devextreme-react/data-grid';
import { Button as HeaderButton } from 'devextreme-react/button';
import useStyles from '../styles/styles';
import { Template } from 'devextreme-react/core/template';

import usePermissions from '../../hooks/usePermissions';
import Switch from '../../components/Switch';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import TrainingUsersGrid from './components/TrainingUsersGrid';
import TrainingGroupsGrid from './components/TrainingGroupsGrid';
import useGetTrainings, {
  useGetUserTrainings,
} from '../../services/requests/useTrainings';
import PopupTraining from './components/popups/PopupTraining';
import PopupTrainingDelete from './components/popups/PopupTrainingDelete';

import { Header, WrapperLeft, Title } from './styles';
import PopupUserTraining from './components/popups/PopupUserTraining';

function Training() {
  const popupTraining = useRef(null);
  const popupTrainingDelete = useRef(null);
  const [isUserTrainingView, setIsUserTrainingView] = useState(false);
  const classes = useStyles();
  const { data: trainingData, refetch: refetchTraining } = useGetTrainings();
  const { data: trainings } = useGetTrainings();
  const {
    data: userTrainingData,
    refetch: refetchUserTraining,
  } = useGetUserTrainings();
  const [popupUserTrainingVisible, setPopupUserTrainingVisible] = useState(
    false
  );

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }
  /** ****************************** */

  function handleOpenPopupTraining(data, type) {
    popupTraining.current.setPopupData({ data, type });
    popupTraining.current.popupControl();
  }
  function handleClosePopupTraining() {
    // popupTraining.current.setPopupData({ data: null, type: '' });
    popupTraining.current.popupControl();
  }
  function handleOpenPopupDelete(data) {
    popupTrainingDelete.current.setPopupData(data);
    popupTrainingDelete.current.popupControl();
  }
  function handleClosePopupDelete() {
    // popupTrainingDelete.current.setPopupData(null);
    popupTrainingDelete.current.popupControl();
  }

  const handleSwitch = () => {
    refetchTraining();
    refetchUserTraining();
    setIsUserTrainingView(!isUserTrainingView);
  };

  return (
    <div className={classes.divGridContainer}>
      <PopupTraining
        refetch={() => refetchTraining()}
        onClose={() => handleClosePopupTraining()}
        ref={popupTraining}
      />
      <PopupTrainingDelete
        ref={popupTrainingDelete}
        refetch={() => refetchTraining()}
        onClose={() => handleClosePopupDelete()}
      />
      {popupUserTrainingVisible && (
        <PopupUserTraining
          currentUserTraining={{ type: 'NEW' }}
          visible={popupUserTrainingVisible}
          refetch={refetchUserTraining}
          onClose={() => setPopupUserTrainingVisible(false)}
          trainings={trainingData}
        />
      )}
      <Header>
        <WrapperLeft>
          <Title>{ACL?.BreadCrumb}</Title>
          <Switch
            id="switch-view"
            toggled={isUserTrainingView}
            onChange={handleSwitch}
            iconOn="fas fa-book"
            iconOff="fas fa-users"
            title={
              isUserTrainingView
                ? 'Switch to Employees View'
                : 'Switch to Training View'
            }
            description={
              isUserTrainingView ? 'By Training View' : 'By Employees'
            }
          />
        </WrapperLeft>
        {(ACL?.AddNew || ACL?.Admin) && (
          <HeaderButton
            icon="plus"
            onClick={() =>
              isUserTrainingView
                ? handleOpenPopupTraining(null, 'NEW')
                : setPopupUserTrainingVisible(true)
            }
          />
        )}
      </Header>

      {/* <WrapperDataGrid visible={!isUserTrainingView}> */}

      <DataGrid
        elementAttr={{
          id: 'gridContainer',
        }}
        visible={!isUserTrainingView}
        keyExpr="UserId"
        dataSource={userTrainingData}
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        // onToolbarPreparing={onToolbarPreparing}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
      >
        <MasterDetail
          enabled
          component={({ data }) => (
            <TrainingGroupsGrid
              ACL={ACL}
              data={data}
              trainings={trainingData}
            />
          )}
        />
        <Selection mode="single" />
        <Column dataField="DisplayName" caption="Employee name" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
      {/* </WrapperDataGrid>
      <WrapperDataGrid visible={isUserTrainingView}> */}
      <DataGrid
        elementAttr={{
          id: 'gridContainer',
        }}
        visible={isUserTrainingView}
        keyExpr="TrainingId"
        dataSource={trainings}
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        // onToolbarPreparing={onToolbarPreparing}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
      >
        <MasterDetail
          enabled
          component={({ data }) => (
            <TrainingUsersGrid ACL={ACL} data={data} trainings={trainingData} />
          )}
        />
        <Selection mode="single" />
        <Column dataField="TrainingDesc" caption="Training Name" />
        <Column
          // dataField="HospitalGroupName"
          caption="Okta Tile"
          cellRender={(data) => (
            <div>
              {data.data.HospitalGroups?.map((item, index) => {
                return <div key={index}>{item.HospitalGroupName}</div>;
              })}
            </div>
          )}
        />
        <Column dataField="Url" />
        <Column dataField="IsGlobal" caption="Global" width={120} />
        <Column dataField="IsPortal" caption="Portal" width={120} />
        <Column dataField="Active" width={120} />
        {(ACL?.Admin || ACL?.Delete || ACL?.Update) && (
          <Column type="buttons" caption="Actions">
            {(ACL?.Admin || ACL?.Update) && (
              <Button
                icon="edit"
                text="Edit"
                onClick={(e) => handleOpenPopupTraining(e.row.data, 'EDIT')}
              />
            )}
            {(ACL?.Admin || ACL?.Delete) && (
              <Button
                icon="trash"
                text="Delete"
                onClick={(e) => handleOpenPopupDelete(e.row.data)}
              />
            )}
          </Column>
        )}
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
      {/* </WrapperDataGrid> */}
    </div>
  );
}

export default Training;
