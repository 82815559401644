import { Popup } from 'devextreme-react/popup';
import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Button, SelectBox, TextBox } from 'devextreme-react';
import LoadPanel from 'devextreme-react/load-panel';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import API from '../../../../services/API';
import queryClient from '../../../../services/queryClient';
import { emrSaveTemplates } from '../../../../services/requests/emrSaveTemplate';
import useEmrContactList from '../../../../services/requests/useEmrContactList';
import AddEMRContactPopup from '../AddEMRContactPopup';
import { Container, Row, WrapperButtons, WrapperField } from './styles';

const EditTemplatePopup = ({
  isVisible,
  setIsVisible,
  title,
  data,
  forceRefetch,
  isExternalCall = false,
  handleChangeHospitalDraft = () => {},
}) => {
  const [templateDraft, setTemplateDraft] = useState({ ...data });
  const [isSavingData, setIsSavingData] = useState(false);
  const [contactData, setContactData] = useState({});
  const [currentContact, setCurrentContact] = useState({});
  const [popupEditContact, setPopupEditContact] = useState(false);
  const { data: emrContactList } = useEmrContactList();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const contact = emrContactList?.find(
        (item) => item?.ContactId === templateDraft?.ContactId
      );
      setContactData(contact);
    }
    return () => {
      isMounted = false;
    };
  }, [emrContactList, templateDraft]);

  const loadData = async (uri) => {
    const res = await API.sendRequest(uri, 'GET');

    return res.data;
  };

  const handleEditContact = async (contactData) => {
    loadData(`Contact/ListComplete/${contactData?.ContactId}`).then(
      (contactComplete) => {
        setCurrentContact({
          ...contactComplete,
          Phones: contactComplete?.Phones?.map((item) => {
            return { PhoneNumber: item?.PhoneNumber?.replace(/[^0-9]/g, '') };
          }),
        });
        setPopupEditContact(true);
      }
    );
  };
  const handleTextChange = (e) => {
    setTemplateDraft((prevState) => ({
      ...prevState,
      [e.element.id]: e.value,
    }));
  };

  const handleSelectChange = (e) => {
    const dataToSave = emrContactList?.find(
      (item) => item?.ContactId === e?.value
    );

    setTemplateDraft((prevState) => ({
      ...prevState,
      ContactId: dataToSave?.ContactId,
      DocuSignTemplateDesc: prevState.DocuSignTemplateDesc,
      DocuSignTemplateId: prevState.DocuSignTemplateId,
      HospitalEMRAdminName: `${dataToSave?.FirstName || ''} ${
        dataToSave?.LastName || ''
      }`,
      HospitalEMRAdminEmail: dataToSave?.Email,
      HospitalEMRAdminPhone: dataToSave?.PhoneNumbers?.split(',')[0],
    }));
  };

  const handleSaveEditTemplate = async (e) => {
    e.preventDefault();
    setIsSavingData(true);

    try {
      const dataToSend = {
        DocuSignTemplateId: templateDraft.DocuSignTemplateId,
        DocuSignTemplateDesc: templateDraft.DocuSignTemplateDesc,
        ContactId: templateDraft.ContactId,
        HospitalEMRAdminName: templateDraft.HospitalEMRAdminName,
        HospitalEMRAdminEmail: templateDraft.HospitalEMRAdminEmail,
        HospitalEMRAdminPhone: templateDraft.HospitalEMRAdminPhone,
      };

      const res = await emrSaveTemplates(dataToSend);

      if (res.status !== 200) {
        if (res.data) {
          throw new Error(res.data.ErrorMsg);
        }
        throw new Error(res.message);
      }
      notify('Contact saved', 'success', 5000);
      await queryClient.invalidateQueries('docuSignTemplate');
      setIsSavingData(false);
      // forceRefetch();
      // setIsVisible(false);
    } catch (e) {
      notify(`Error: ${e.message}`, 'error', 5000);
      setIsVisible(false);
      setIsSavingData(false);
      forceRefetch();
      setTemplateDraft({});
    }
  };

  const handleSaveEditTemplateOnClick = async (e) => {
    if (isExternalCall) {
      await handleSaveEditTemplate(e);
      // await queryClient.invalidateQueries('docuSignTemplateList');
      handleChangeHospitalDraft((prevState) => ({
        ...prevState,
        EMREmail: templateDraft.HospitalEMRAdminEmail,
        EMRContactId: templateDraft?.ContactId,
        EMRPhone: templateDraft?.HospitalEMRAdminPhone,
        DocuSignTemplateId: templateDraft.DocuSignTemplateId,
      }));
    } else {
      await handleSaveEditTemplate(e);
    }
    await queryClient.invalidateQueries('docuSignTemplateList');
    setTemplateDraft({});
    setIsVisible(false);
  };

  const addNewContact = () => {
    setCurrentContact({
      Contact: {
        Prefix: '',
        DisplayName: '',
        FirstName: '',
        LastName: '',
        Initials: '',
        Company: '',
        AreaId: 0,
        DepartmentId: 0,
        Department: '',
        JobTitle: '',
        Description: '',
        Active: true,
        OU: '',
        Today: '',
        Username: '',
        Email: '',
        BadgeColor: '',
        PhoneNumbers: '',
        Categories: '',
      },
      Phones: [],
      Category: [],
    });

    setPopupEditContact(true);
  };

  const updateData = (data, isUpdate) => {
    if (isUpdate) {
      setTemplateDraft((prevState) => ({
        ...prevState,
        HospitalEMRAdminPhone: data?.Phones[0]?.PhoneNumber,
        HospitalEMRAdminEmail: data?.Contact?.Email,
      }));
    }
    forceRefetch();
  };

  const TextBoxGroup = useMemo(
    () => (
      <>
        <WrapperField>
          <span>Email:</span>
          <TextBox
            readOnly
            width="100%"
            id="HospitalEMRAdminEmail"
            value={templateDraft?.HospitalEMRAdminEmail}
          />
        </WrapperField>
        <WrapperField>
          <span> Phone:</span>
          <TextBox
            readOnly
            width="100%"
            id="HospitalEMRAdminPhone"
            value={templateDraft?.HospitalEMRAdminPhone?.replace(/\D/g, '')}
            mask="(000) 000-0000"
          />
        </WrapperField>
      </>
    ),
    [templateDraft]
  );

  return (
    <Popup
      title={title}
      visible={isVisible}
      dragEnabled={false}
      showCloseButton={false}
      width={750}
      height={480}
    >
      <AddEMRContactPopup
        isVisible={popupEditContact}
        setIsVisible={setPopupEditContact}
        currentContact={currentContact}
        updateData={updateData}
      />

      <LoadPanel
        shadingColor="rgba(255,255,255,0.4)"
        visible={isSavingData}
        message="Saving Contact..."
        closeOnOutsideClick={false}
      />

      <Container onSubmit={handleSaveEditTemplateOnClick}>
        <WrapperField>
          <span>Template Name:</span>
          <TextBox
            width="100%"
            id="DocuSignTemplateDesc"
            value={templateDraft.DocuSignTemplateDesc}
            onValueChanged={handleTextChange}
          />
        </WrapperField>
        <fieldset>
          <legend>EMR</legend>
          <Row>
            <WrapperField>
              <span>Contact:</span>
              <SelectBox
                id="ContactId"
                width="100%"
                dataSource={emrContactList}
                value={templateDraft.ContactId}
                valueExpr="ContactId"
                displayExpr={(item) =>
                  `${
                    item?.DisplayName ||
                    `${item?.FirstName || ''} ${item?.LastName || ''}`
                  }${item?.Company ? ` | [${item.Company}]` : ''}`
                }
                onValueChanged={(e) => handleSelectChange(e)}
              >
                <Validator>
                  <RequiredRule message="Contact is required" />
                </Validator>
              </SelectBox>
            </WrapperField>
            <Button
              icon="edit"
              hint="Edit Contact"
              height={35}
              onClick={() => handleEditContact(contactData)}
            />
            <Button
              icon="plus"
              hint="Add new contact"
              height={35}
              onClick={addNewContact}
            />
          </Row>
          {TextBoxGroup}
        </fieldset>
        <WrapperButtons>
          <Button width={120} text="OK" type="success" useSubmitBehavior />
          <Button
            width={120}
            text="Cancel"
            type="normal"
            onClick={() => {
              setIsVisible(false);
            }}
          />
        </WrapperButtons>
      </Container>
    </Popup>
  );
};

export default EditTemplatePopup;

EditTemplatePopup.propTypes = {
  data: object.isRequired,
  isVisible: bool.isRequired,
  setIsVisible: func.isRequired,
  title: string.isRequired,
  forceRefetch: func.isRequired,
  isExternalCall: bool.isRequired,
  handleChangeHospitalDraft: func.isRequired,
};
