import { format } from 'date-fns';
import notify from 'devextreme/ui/notify';
import API from '../API';

export async function emrSaveRequests(newEmrRequests) {
  const data = await API.sendRequest(
    'ADUserEMRAccess/SaveRequests',
    'POST',
    newEmrRequests
  );
  // if (status !== 200) {
  //   throw new Error(`Error on api status ${status}`);
  // }
  // // const checkError = data.find((item) => item.ErrorMsg);
  // if (data.ErrorMsg) {
  //   throw new Error(data.ErrorMsg);
  // }
  return data;
}

export async function emrUpdateRequestStatus(emrUpdateData) {
  return API.sendRequest('ADUserEMRAccess/UpdateStatus', 'POST', {
    ...emrUpdateData,
    ApprovalDate:
      emrUpdateData.EMRRequestStatusId === 3
        ? format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
        : null,
  });
}

export async function emrCancelRequest(requestId) {
  return API.sendRequest(`ADUserEMRAccess/CancelRequest/${requestId}`, 'POST');
}

export async function emrRevokeRequest(requestId) {
  return API.sendRequest(`ADUserEMRAccess/RevokeAccess/${requestId}`, 'POST');
}

export async function emrSendRequests(emrRequests) {
  const { data: response } = await API.sendRequest(
    'ADUserEMRAccess/Send',
    'POST',
    emrRequests
  );

  const checkErrorsResult = response.find((item) => item.ErrorMsg !== null);

  if (checkErrorsResult) {
    notify(checkErrorsResult.ErrorMsg, 'error', 9000);
  }
  return response;
}
