import React from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import TimeZoneDetailTemplate from '../../components/template/TimeZoneDetailTemplate';
import useStyles from '../styles/styles';
import {
  Column,
  DataGrid,
  Paging,
  MasterDetail,
} from 'devextreme-react/data-grid';

import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

const timezones = new CustomStore({
  key: 'TimeZoneId',
  load: async () => {
    const { data } = await API.sendRequest(`TimeZone`, 'get');
    return data;
  },
});

const TimeZone = () => {
  const classes = useStyles();

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        dataSource={timezones}
        // keyExpr={'TimeZoneId'}
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled />

        <Column dataField="DisplayName" caption="DisplayName" width={500} />
        <Column dataField="StandardName" caption="StandardName" width={500} />
        <Column dataField="HasDST" />
        <Column dataField="UTCOffset" />
        <MasterDetail enabled component={TimeZoneDetailTemplate} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default TimeZone;
