import React, { useEffect, useState } from 'react';
import { element, func, shape, arrayOf } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Draggable from 'react-draggable';
import CustomCheckbox from '../Checkbox';
import Avatar from '../../../../components/contacts/Avatar';
import API from '../../../../services/API';
import clone from '../../../../helpers/clone';

import 'react-perfect-scrollbar/dist/css/styles.css';

import {
  Header,
  SubHeader,
  Text,
  BoxSearch,
  BoxCenter,
  BoxAssignment,
  WrapperButtons,
  ButtonCustom,
  StyledMenu,
  StyledMenuItem,
  useStyles,
} from './styles';

export default function CustomizedMenus({
  anchorEl,
  handleClose,
  cell,
  contactSchedule,
  setContactSchedule,
  setErrorSnack,
  handleClickSnack,
}) {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [term, setTerm] = useState('');

  const [position, setPosition] = useState(0);

  useEffect(() => {
    async function loadContacts() {
      const { data } = await API.sendRequest(
        'ContactSchedule/ListContacts/4',
        'get'
      );

      const contactsCopy = clone(contactSchedule);

      const itemsDay =
        JSON.parse(
          contactsCopy[cell.row.ScheduleControlOrder - 1][cell.item.columnName]
        ) || [];

      const results = data.map((d) => {
        const existItem = itemsDay.filter(
          (it) => it.ContactId.toUpperCase() === d.ContactId.toUpperCase()
        );
        if (existItem && existItem.length > 0) {
          d.check = true;
        } else {
          d.check = false;
        }
        return d;
      });

      setContacts(results);
      setIsLoading(false);
    }

    loadContacts(); // eslint-disable-next-line
  }, []);

  const handleChangeCheckbox = (event) => {
    const { id, checked } = event.target;

    setContacts((prevState) =>
      prevState.map((prev) => {
        if (prev.ContactId === id) {
          prev.check = checked;
        }
        return prev;
      })
    );
  };

  const handleClickCheckbox = (id, checked) => {
    setContacts((prevState) =>
      prevState.map((prev) => {
        if (prev.ContactId === id) {
          prev.check = !checked;
        }
        return prev;
      })
    );
  };

  const handleSave = async () => {
    setIsLoading(true);
    const contactsCopy = clone(contactSchedule);

    const contactsSave = contacts.filter((person) => person.check);
    const contactsSaveId = contactsSave.map((p) => ({
      ContactId: p.ContactId,
    }));

    // SAVE
    let data;
    if (contactsSaveId.length > 0) {
      const response = await API.sendRequest(
        'ContactSchedule/SavePopup',
        'post',
        {
          StartDate: cell.item.columnDate,
          Subject: cell.row.GroupCode,
          Contacts: [...contactsSaveId],
        }
      );

      data = response.data;

      contactsSave.forEach((contact) => {
        const contactFound = data.find(
          (d) => d.ContactId === contact.ContactId
        );
        contact.ContactScheduleId = contactFound.ContactScheduleId;
      });
    } else {
      const response = await API.sendRequest(
        'ContactSchedule/SavePopup',
        'post',
        {
          StartDate: cell.item.columnDate,
          Subject: cell.row.GroupCode,
          Contacts: [],
        }
      );
      data = response.data;
    }

    if (data) {
      contactsCopy[cell.row.ScheduleControlOrder - 1][cell.item.columnName] =
        JSON.stringify(contactsSave);

      setContactSchedule(contactsCopy);

      setIsLoading(false);
      handleClose();
      handleClickSnack();
    } else {
      setIsLoading(false);
      setErrorSnack(true);
      handleClickSnack();
    }
  };

  const onStart = () => {
    setPosition(position + 1);
  };

  const onStop = () => {
    setPosition(position - 1);
  };

  const filterContacts = contacts.filter((contact) =>
    contact.FullName.toLowerCase().includes(term.toLowerCase())
  );
  const dragHandlers = { onStart, onStop };

  return (
    <Draggable {...dragHandlers} handle="strong">
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <strong>
          <Header color={cell.row.Color}>
            <SubHeader>
              <Text
                color={cell.row.Color === '#C92828' ? '#FFF' : '#000'}
                weight={300}
                size="12px"
              >
                {cell.item.columnName}
              </Text>
              <Text
                color={cell.row.Color === '#C92828' ? '#FFF' : '#000'}
                weight="bold"
                size="16px"
                upper
              >
                {cell.row.GroupName}
              </Text>
            </SubHeader>
            <IconButton
              className={classes.buttonClose}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" className={classes.iconClose} />
            </IconButton>
          </Header>
        </strong>
        <BoxSearch>
          {!isLoading && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                // value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
            </div>
          )}
        </BoxSearch>
        <BoxAssignment>
          {!isLoading ? (
            <PerfectScrollbar>
              {filterContacts &&
                filterContacts.map((listItem) => (
                  <StyledMenuItem
                    key={listItem.ContactId}
                    onClick={() =>
                      handleClickCheckbox(listItem.ContactId, listItem.check)
                    }
                  >
                    <ListItemIcon>
                      <CustomCheckbox
                        id={listItem.ContactId}
                        value={listItem.check}
                        handleChange={handleChangeCheckbox}
                      />
                    </ListItemIcon>
                    <ListItemAvatar>
                      <Avatar
                        contact={listItem}
                        h={30}
                        w={30}
                        style={{ marginLeft: 0, marginRight: 0 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={listItem.FullName}
                      className={classes.textList}
                    />
                  </StyledMenuItem>
                ))}
            </PerfectScrollbar>
          ) : (
            <BoxCenter>
              <CircularProgress />
            </BoxCenter>
          )}
        </BoxAssignment>

        <WrapperButtons>
          <ButtonCustom bg="#707070" color="#FFF" onClick={handleSave}>
            OK
          </ButtonCustom>
          <ButtonCustom bg="#FFF" color="#707070" onClick={handleClose}>
            Cancel
          </ButtonCustom>
        </WrapperButtons>
      </StyledMenu>
    </Draggable>
  );
}

CustomizedMenus.propTypes = {
  anchorEl: element.isRequired,
  handleClose: func.isRequired,
  cell: shape.isRequired,
  setContactSchedule: func.isRequired,
  setErrorSnack: func.isRequired,
  handleClickSnack: func.isRequired,
  contactSchedule: arrayOf.isRequired,
};
