import { format, parseISO } from 'date-fns';
import { CheckBox, Popup, ScrollView, TagBox, TextBox } from 'devextreme-react';
import Form, { GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import TabPanel, { Item as Tab } from 'devextreme-react/tab-panel';
import notify from 'devextreme/ui/notify';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import pickTextColorBasedOnBgColor from '../../helpers/pickTextColorBasedOnBgColor';
import API from '../../services/API';
import Avatar from '../contacts/Avatar';
import {
  AppointmentCell,
  AppointmentTooltip,
  Content,
  PopupContent,
  SchedulerStyled,
} from './styles';

function ContactPopup({ visible, data, onClose }) {
  const [scheduleData, setScheduleData] = useState([]);
  const [groupUserList, setGroupUserList] = useState([]);
  const [contactDetails, setContactDetails] = useState({});

  async function fetchContactGroups() {
    try {
      const { data: userGroups } = await API.sendRequest(
        `ContactGroup/${data?.UserId}`
      );
      if (userGroups.length === 0) {
        return;
      }

      const { data: groupList } = await API.sendRequest(`Group`);
      const { data: contactData } = await API.sendRequest(
        `ADUser/ListComplete/${data.UserId}`
      );
      setContactDetails(contactData?.User);
      const userGroupsList = userGroups?.map((group) =>
        groupList.find((item) => item?.GroupId === group?.GroupId)
      );
      setGroupUserList(
        userGroupsList?.map((item) => `${item?.GroupCode} - ${item?.GroupName}`)
      );
    } catch (e) {
      notify('Error loading Groups data', 'error');
    } finally {
      // setIsLoading(false);
    }
  }

  useEffect(() => {
    if (data && visible) {
      fetchContactGroups();
    }
    if (data?.Schedule) {
      const schedule = JSON.parse(data?.Schedule);
      const arrayFormated = schedule.map((item, index) => ({
        ...item,
        text: item?.Subject,
        employeeID: `employee_${index}`,
        startDate: new Date(item?.StartDate),
        endDate: new Date(item?.EndDate),
      }));
      setScheduleData(arrayFormated);
    }

    // eslint-disable-next-line
  }, [data]);

  const DataCell = ({ data }) => (
    <div className="dx-template-wrapper">
      <div className="day-cell">{data.text}</div>
    </div>
  );

  const appointmentRender = ({ appointmentData }) => (
    <AppointmentCell
      bgColor={appointmentData?.Color}
      color={pickTextColorBasedOnBgColor(
        appointmentData?.Color,
        '#FFFFFF',
        '#000000'
      )}
    >
      {appointmentData?.Subject}
    </AppointmentCell>
  );

  const appointmentTooltipRender = ({ appointmentData }) => (
    <AppointmentTooltip bgColor={appointmentData?.Color}>
      <div className="content">
        <div className="color-indicator" />
        <h4>{appointmentData?.Subject}</h4>
      </div>
      <div className="description">
        {appointmentData?.AllDay
          ? 'All Day'
          : `${format(
              parseISO(appointmentData?.StartDate),
              'MM/dd/yyyy HH:mm a'
            )} - ${format(
              parseISO(appointmentData?.EndDate),
              'MM/dd/yyyy HH:mm a'
            )}`}
      </div>
    </AppointmentTooltip>
  );
  function onPopupClose() {
    onClose();
    setScheduleData([]);
    setGroupUserList([]);
    setContactDetails({});
  }

  return (
    <Popup
      visible={visible}
      title={data?.FullName}
      onHiding={() => onPopupClose()}
      width="96%"
      height="96%"
      maxHeight={675}
      maxWidth={1000}
    >
      <PopupContent>
        <TabPanel
          hoverStateEnabled={false}
          activeStateEnabled={false}
          focusStateEnabled={false}
          height="92.5%"
        >
          <Tab title="Personal Data">
            <Content>
              <ScrollView>
                <Form colCount={1} id="Contact" formData={data} height="100%">
                  <GroupItem colCount={2}>
                    <SimpleItem>
                      <Avatar
                        contact={data}
                        h={100}
                        w={100}
                        useTooltip={false}
                      />
                    </SimpleItem>
                    <GroupItem colCount={1}>
                      <SimpleItem>
                        <CheckBox
                          value={contactDetails?.IsDirector || false}
                          text="Is Director"
                          readOnly
                        />
                      </SimpleItem>
                      <SimpleItem>
                        <CheckBox
                          value={contactDetails?.IsManager || false}
                          text="Is Manager"
                          readOnly
                        />
                      </SimpleItem>
                    </GroupItem>
                    <SimpleItem
                      dataField="FullName"
                      editorType="dxTextBox"
                      editorOptions={{ readOnly: true }}
                    >
                      <Label text="Full Name" alignment="left" location="top" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="Email"
                      editorType="dxTextBox"
                      editorOptions={{ readOnly: true }}
                    >
                      <Label text="Email" alignment="left" location="top" />
                    </SimpleItem>
                    {/* <SimpleItem
                      dataField="Company"
                      editorType="dxTextBox"
                      editorOptions={{ readOnly: true }}
                    >
                      <Label text="Company" alignment="left" location="top" />
                    </SimpleItem> */}
                    <SimpleItem
                      dataField="JobTitle"
                      editorType="dxTextBox"
                      editorOptions={{ readOnly: true }}
                    >
                      <Label text="Job Title" alignment="left" location="top" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="DepartmentDesc"
                      editorType="dxTextBox"
                      editorOptions={{ readOnly: true }}
                    >
                      <Label
                        text="Department"
                        alignment="left"
                        location="top"
                      />
                    </SimpleItem>
                    {data?.Phones?.map((obj, idx) => (
                      <SimpleItem key={idx}>
                        <Label
                          text={`${obj?.PhoneTypeDesc}${
                            obj?.Preferred ? ' (Preferred)' : ''
                          }`}
                          alignment="left"
                          location="top"
                        />
                        <TextBox value={obj?.PhoneNumber} readOnly />
                      </SimpleItem>
                    ))}
                    <SimpleItem visible={groupUserList.length !== 0}>
                      <Label text="Groups" alignment="left" location="top" />
                      <TagBox
                        value={groupUserList}
                        readOnly
                        placeholder="Loading..."
                      />
                    </SimpleItem>
                    <SimpleItem
                      visible={contactDetails?.Description !== undefined}
                    >
                      <Label
                        text="Description"
                        alignment="left"
                        location="top"
                      />
                      <TextBox value={contactDetails?.Description} readOnly />
                    </SimpleItem>
                    <SimpleItem visible={contactDetails?.Company !== undefined}>
                      <Label text="Company" alignment="left" location="top" />
                      <TextBox value={contactDetails?.Company} readOnly />
                    </SimpleItem>
                  </GroupItem>
                </Form>
              </ScrollView>
            </Content>
          </Tab>
          <Tab title={`${format(new Date(), 'MMMM')} Schedule`}>
            {/* <ScrollView> */}
            <SchedulerStyled
              dataSource={scheduleData}
              dataCellComponent={DataCell}
              views={['month', 'week']}
              defaultCurrentView="month"
              defaultCurrentDate={new Date()}
              height="100%"
              showAllDayPanel
              appointmentRender={appointmentRender}
              appointmentTooltipRender={appointmentTooltipRender}
              tool
              editing={false}
            />
            {/* </ScrollView> */}
          </Tab>
        </TabPanel>
      </PopupContent>
    </Popup>
  );
}

export default ContactPopup;

ContactPopup.propTypes = {
  visible: bool.isRequired,
  data: object.isRequired,
  onClose: func.isRequired,
};
