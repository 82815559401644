import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

import {
  Column,
  DataGrid,
  Paging,
  Pager,
  // Editing,
  Lookup,
  GroupPanel,
  Grouping,
} from 'devextreme-react/data-grid';

const cities = new CustomStore({
  key: 'CityId',
  load: async () => {
    const data = (await API.sendRequest(`City`, 'get')).data;
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`City`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`City`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`City/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const setStateValue = (rowData, value) => {
  //rowData.CityID = null;
  //this.defaultSetCellValue(rowData, value);
};

const setCountyValue = (rowData, value) => {
  //rowData.CityID = null;
  //this.defaultSetCellValue(rowData, value);
};

const City = () => {
  const [states, setStates] = useState(null);
  const [counties, setCounties] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    async function init() {
      setStates((await API.sendRequest(`State`, 'get')).data);
      setCounties((await API.sendRequest(`County`, 'get')).data);
    }

    init();
  }, []);

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /*********************************/

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id={'gridContainer'}
        dataSource={cities}
        //keyExpr={'CityId'}
        allowColumnReordering={true}
        showBorders={true}
        rowAlternationEnabled={true}
        focusedRowEnabled={true}
        showRowLines={true}
        onRowUpdating={onRowUpdating}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 15, 20, 50]}
          showInfo={true}
        />
        {/* <Editing
          mode={'form'}
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        /> */}

        <Column dataField={'CityName'} caption={'Name'} />
        <Column
          dataField="StateId"
          caption="State"
          setCellValue={setStateValue}
          groupIndex={0}
        >
          <Lookup
            dataSource={states}
            displayExpr="StateName"
            valueExpr="StateId"
          />
        </Column>
        <Column
          dataField="CountyId"
          caption="County"
          setCellValue={setCountyValue}
          groupIndex={1}
        >
          <Lookup
            dataSource={counties}
            displayExpr="CountyName"
            valueExpr="CountyId"
          />
        </Column>
        <Column dataField={'ZipCode'} caption={'ZipCode'} />
        <Column dataField={'Latitude'} caption={'Latitude'} />
        <Column dataField={'Longitude'} caption={'Longitude'} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default City;
