import styled from 'styled-components';

export const Header = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;

export const WrapperLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
`;
