import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import notify from 'devextreme/ui/notify';

export function handleExportGridToExcel(dataGrid, username) {
  if (dataGrid.totalCount() === 0) {
    notify(`There is no schedule history for this user`, 'error', 3000);
    return;
  }

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Schedule history');

  exportDataGridToExcel({
    component: dataGrid,
    worksheet,
    customizeCell: ({ gridCell, excelCell }) => {
      excelCell._column.width = 25;
      const { dataField } = gridCell.column;
      if (
        dataField === 'StartDate' ||
        dataField === 'EndDate' ||
        dataField === 'CreatedAt' ||
        dataField === 'ChangedAt'
      ) {
        excelCell._column.width = 18;
      }
      if (dataField === 'AllDay' || dataField === 'DayOff') {
        excelCell._column.width = 13;
      }
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `schedule_history_${username}.xlsx`
      );
    });
  });
}
