import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { string, number } from 'prop-types';

import { ProgressContainer, ProgressLabel, Message } from './styles';

export default function ProgressStatus({ complementaryMessage, timeout }) {
  const [messageVisible, setMessageVisible] = useState(false);

  useEffect(() => {
    if (!complementaryMessage) {
      setMessageVisible(false);
      return;
    }

    let timeCount = setTimeout(() => {
      setMessageVisible(true);
    }, timeout);

    return () => {
      clearTimeout(timeCount);
    };
  }, [complementaryMessage, timeout]);

  return (
    <ProgressContainer>
      <CircularProgress size={'4rem'} />
      <ProgressLabel>Loading...</ProgressLabel>
      <Message visible={messageVisible}>
        <ProgressLabel>{complementaryMessage}</ProgressLabel>
      </Message>
    </ProgressContainer>
  );
}

ProgressStatus.propTypes = {
  complementaryMessage: string,
  timeout: number,
};
