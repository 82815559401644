import React, { useState, useEffect, useRef } from 'react';
import API from '../../services/API';
import useStyles from '../styles/styles';

import {
  Column,
  DataGrid,
  Selection,
  Paging,
  Lookup,
  MasterDetail,
  SearchPanel,
  Pager,
} from 'devextreme-react/data-grid';

import usePermissions from '../../hooks/usePermissions';
import DetailTemplate from '../../components/UserDetails';
import { BreadCrumbRender, LoadPermissions } from '../../global';
import { Template } from 'devextreme-react/core/template';
import UserEditPopup from './Popups/UserEditPopup';
// import UserDeletePopup from './Popups/UserDeletePopup';
// import CustomStore from 'devextreme/data/custom_store';

// const users = new CustomStore({
//   key: 'UserId',
//   load: async () => {
//     const data = (await API.sendRequest(`User`, 'get')).data;
//     console.log('Users: ', data);
//     return data;
//   },
//   insert: async (data) => {
//     console.log('User to save: ', data);
//     await API.sendRequest(`User/save`, 'post', data);
//   },
//   update: async (id, data) => {
//     console.log('User to save: ', data);
//     await API.sendRequest(`User/save`, 'post', data);
//   },
//   remove: async (id) => {
//     await API.sendRequest(`User/${id}`, 'delete');
//   },
// });

// const onRowUpdating = (options) => {
//   options.newData = { ...options.oldData, ...options.newData };
// };

const User = () => {
  const [users, setUsers] = useState(null);
  const [menus, setMenus] = useState(null);
  const [screens, setScreens] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [editPopupVisible, setEditPopupVisible] = useState(false);
  // const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const classes = useStyles();
  const dataGridRef = useRef(null);

  useEffect(() => {
    async function init() {
      setMenus((await API.sendRequest(`Menu/ChildrenOnly/1`, 'get')).data);
      setScreens((await API.sendRequest('MobileScreen', 'get')).data);
    }
    updateUsersGrid();
    init();
  }, []);

  const updateUsersGrid = async () => {
    setUsers((await API.sendRequest(`User`, 'get')).data);
    if (dataGridRef.current.instance) {
      dataGridRef.current.instance.refresh();
    }
  };

  const contentReady = (e) => {
    if (!e.component.getSelectedRowKeys().length) {
      e.component.selectRowsByIndexes(0);
    }
  };

  const selectionChanged = (e) => {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  };

  const handleEditUser = async ({ row }) => {
    const { data } = await API.sendRequest(`User/${row.data.UserId}`, 'get');
    if (
      data.InitialMobileScreenId === null ||
      data.InitialMobileScreenId === 0
    ) {
      data.InitialMobileScreenId = 1;
    }
    setCurrentUser(data);
    setEditPopupVisible(true);
  };

  // const handleDeleteUser = ({ row }) => {
  //   setCurrentUser(row.data);
  //   setDeletePopupVisible(true);
  // };

  // const addNewUser = () => {
  //   setCurrentUser({
  //     Username: '',
  //     FullName: '',
  //     Initials: null,
  //     InitialMenuId: '',
  //     InitialMobileScreenId: 1,
  //     UserType: null,
  //   });
  //   setEditPopupVisible(true);
  // };

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (!ACL.Admin) {
    return (
      <LoadPermissions>
        You don't have access to this page. For Administrators Only
      </LoadPermissions>
    );
  }
  /** ****************************** */

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'breadCrumb',
    });

    // if (ACL.AddNew) {
    //   e.toolbarOptions.items.unshift({
    //     location: 'after',
    //     widget: 'dxButton',
    //     options: {
    //       icon: 'add',
    //       onClick: addNewUser,
    //     },
    //   });
    // }
  };

  const buttonList = [];

  if (ACL.Update) {
    buttonList.push({
      hint: 'Edit User',
      icon: 'edit',
      onClick: handleEditUser,
    });
  }

  // if (ACL.Delete) {
  //   buttonList.push({
  //     hint: 'Delete User',
  //     icon: 'trash',
  //     onClick: handleDeleteUser,
  //   });
  // }

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id="gridContainer"
        ref={dataGridRef}
        dataSource={users}
        keyExpr="UserId"
        allowColumnReordering
        showBorders
        onSelectionChanged={selectionChanged}
        onContentReady={contentReady}
        // onRowUpdating={onRowUpdating}
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <SearchPanel visible width={240} placeholder="Search..." />
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector allowedPageSizes={[20, 40, 60, 100, 200]} />
        <Selection mode="single" />

        <Column dataField="Username" />
        <Column dataField="FullName" caption="FullName" allowEditing={false} />
        <Column dataField="Initials" allowEditing={false} />
        <Column dataField="InitialMenuId" width={150} caption="Initial Menu">
          <Lookup
            dataSource={menus}
            displayExpr="MenuFullCaption"
            valueExpr="MenuId"
          />
        </Column>
        <Column dataField="InitialScreen" />
        <Column
          dataField="UserTypeDesc"
          allowEditing={false}
          caption="UserType"
        />
        <Column caption="Actions" type="buttons" buttons={buttonList} />
        <MasterDetail enabled component={DetailTemplate} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
      {currentUser && (
        <UserEditPopup
          updateUsersGrid={updateUsersGrid}
          currentUser={currentUser}
          visible={editPopupVisible}
          setVisible={setEditPopupVisible}
          menus={menus}
          screens={screens}
        />
      )}
      {/* {currentUser && (
        <UserDeletePopup
          currentUser={currentUser}
          setVisible={setDeletePopupVisible}
          visible={deletePopupVisible}
          updateGrid={updateUsersGrid}
        />
      )} */}
    </div>
  );
};

export default User;
