import { Button, Popup, ScrollView } from 'devextreme-react';
import React, { useState } from 'react';

import {
  ButtonDx,
  Container,
  Content,
  Input,
  PasswordBox,
  WrapperButtons,
} from './styles';
import { bool, func, object } from 'prop-types';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import API from 'services/API';

function DetailPopup({ visible, onClose, data }) {
  const logId = data?.Id;
  const [showPassword, setShowPassword] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [decryptKey, setDecryptKey] = useState({});
  const passwordButtonOptions = {
    icon: showPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash',
    type: 'default',
    stylingMode: 'text',
    location: 'after',
    onClick: () => {
      setShowPassword(!showPassword);
    },
    hoverStateEnabled: false,
    activeStateEnabled: false,
    focusStateEnabled: false,
    hint: showPassword ? 'Esconder senha' : 'Mostrar senha',
  };
  const handleTextChange = (e) => {
    setDecryptKey((prevState) => ({
      ...prevState,
      [e.element.id]: e.value,
    }));
  };

  const handleDecryptRequestData = async () => {
    const res = await API.sendRequest(
      `LogErrors/RequestData/${decryptKey.password}/${logId}`,
      'get'
    );

    if (res.status === 200) {
      setDecryptKey('');
      setDetailData(res);
    }
  };

  return (
    <Popup
      visible={visible}
      onHiding={onClose}
      showCloseButton
      title="Request Data Detail"
    >
      <Container>
        <Content>
          {Object.keys(detailData).length === 0 ? (
            <PasswordBox>
              <span>Type password to decrypt</span>

              <Input
                id="password"
                onValueChanged={handleTextChange}
                mode={!showPassword ? 'password' : 'text'}
              >
                <ButtonDx
                  name="password"
                  location="after"
                  options={passwordButtonOptions}
                />
              </Input>
              <Button
                text="Ok"
                width="100%"
                type="default"
                onClick={handleDecryptRequestData}
              />
            </PasswordBox>
          ) : (
            <ScrollView width="100%">
              <JsonView
                data={detailData}
                shouldExpandNode={allExpanded}
                style={defaultStyles}
              />
            </ScrollView>
          )}
        </Content>
        <WrapperButtons>
          <Button width={120} text="Cancel" type="normal" onClick={onClose} />
        </WrapperButtons>
      </Container>
    </Popup>
  );
}

export default DetailPopup;

DetailPopup.propTypes = {
  visible: bool,
  onClose: func,
  data: object,
};

DetailPopup.defaultProps = {
  visible: false,
  onClose: () => {},
  data: {},
};
