import React, { useEffect, useState, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { DateBox } from 'devextreme-react';
import pickTextColorBasedOnBgColor from '../../../helpers/pickTextColorBasedOnBgColor';
import ContactAgendaPopup from '../../../components/popups/ContactAgendaPopup';
import ItemDrag from '../components/ItemDrag';
import ItemDrop from '../components/ItemDrop';
import AssignmentPopup from '../components/AssignmentPopup';
import Garbage from '../components/Garbage';

import clone from '../../../helpers/clone';
import API from '../../../services/API';
import usePermissions from '../../../hooks/usePermissions';
import { BreadCrumb, LoadPermissions } from '../../../global';

import {
  Container,
  WrapperHeadCell,
  CustomGroupNameCell,
  CustomTableRow,
  RowDateGarbage,
} from './styles';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: '98%',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// TODO:
const area = 4;

function getInitialDate(area) {
  const itemStorage = `lab_report_v1_${area}`;
  const now = localStorage.getItem(itemStorage)
    ? new Date(JSON.parse(localStorage.getItem(itemStorage)))
    : new Date();
  localStorage.setItem(itemStorage, JSON.stringify(now));
  return now;
}

export default function Report() {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(getInitialDate(area));
  const [contactSchedule, setContactSchedule] = useState([]);
  const [headerSchedule, setHeaderSchedule] = useState([]);
  const [treatmentColumnName, setTreatmentColumnName] = useState([]);
  const [agendaPopupVisible, setAgendaPopupVisible] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [assignmentAnchorEl, setAssignmentAnchorEl] = useState(null);
  const [assignmentItem, setAssignmentItem] = useState(null);
  const [openSnack, setOpenSnack] = useState(null);
  const [errorSnack, setErrorSnack] = useState(false);

  useEffect(() => {
    setContactSchedule([]);
    setHeaderSchedule([]);
    setTreatmentColumnName([]);

    const year = currentDate.getFullYear();
    const quarter = getQuarter(currentDate);

    async function loadDataContactSchedule() {
      const response = await API.sendRequest(
        `ContactSchedule/${area}/${year}/${quarter}/false`,
        'get'
      );

      setContactSchedule(response.data);
    }

    async function loadDataHeaderContactSchedule() {
      const response = await API.sendRequest(
        `ContactSchedule/${area}/${year}/${quarter}/true`,
        'get'
      );

      const dataTreatment = response.data.map((data) => ({
        columnName: data.ColumnName,
        columnDate: data.DateField,
      }));

      const columnName = dataTreatment.filter((data, index) => index > 3);

      // const newColumnName = columnName.map((data) => data.columnName);
      setHeaderSchedule(columnName);
      setTreatmentColumnName(columnName);
    }

    loadDataContactSchedule();
    loadDataHeaderContactSchedule();
  }, [currentDate]);

  const onMonthChanged = (e) => {
    const newDate = new Date(e.value);
    setCurrentDate(newDate);
    const itemStorage = `lab_report_v1_${area}`;
    localStorage.setItem(itemStorage, JSON.stringify(newDate));
  };

  function getQuarter(date) {
    const month = date.getMonth() + 1;
    return Math.ceil(month / 3);
  }

  function handleAvatarClick(contact) {
    setCurrentContact(contact);
    // setAgendaPopupVisible(true);
  }

  function treatmentHolidayText(holidayStr) {
    let searchTerm;
    let convertStr;
    searchTerm = holidayStr.indexOf('(');
    convertStr = holidayStr.slice(0, searchTerm);
    return convertStr;
  }

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleGarbageDrop = useCallback(
    (itemDrop) => {
      // REMOVE API
      API.sendRequest(`ContactSchedule/${itemDrop.id}`, 'DELETE', itemDrop.id);
    },
    []
    // [contactSchedule],
  );

  const handleDrop = useCallback(
    (index, cell, itemDrop) => {
      const contactsCopy = clone(contactSchedule);

      const itemsDay = JSON.parse(contactsCopy[index][cell.columnName]) || [];

      const date = cell.columnDate;
      let subject = '';
      if (index === 0) {
        subject = 'SOC';
      } else if (index === 1) {
        subject = 'DAY';
      } else if (index === 2) {
        subject = 'EVENING';
      } else {
        subject = 'NIGHT';
      }

      // MOVING
      API.sendRequest(
        `ContactSchedule/Move/${itemDrop.data.ContactScheduleId}/${date}/${subject}`,
        'post'
      );

      itemsDay.push(itemDrop.data);
      contactsCopy[index][cell.columnName] = JSON.stringify(itemsDay);

      setContactSchedule(contactsCopy);
    },
    [contactSchedule]
  );

  const handleCanDrop = useCallback(
    (index, cell, itemDrop) => {
      const contactsCopy = clone(contactSchedule);

      const itemsDay = JSON.parse(contactsCopy[index][cell.columnName]) || [];

      const verifyItems = itemsDay.filter(
        (item) =>
          item.ContactScheduleId === itemDrop.data.ContactScheduleId ||
          item.ContactId === itemDrop.data.ContactId
      );

      if (verifyItems.length > 0) {
        return false;
      }

      return true;
    },
    [contactSchedule]
  );

  const handleAssignmentOpen = (event, item, row) => {
    event.preventDefault();
    if (!ACL.AddNew && !ACL.Update) {
      return;
    }

    setAssignmentAnchorEl(event.currentTarget);
    setAssignmentItem({ item, row });
  };

  const handleAssignmentClose = () => {
    setAssignmentAnchorEl(null);
  };

  const handleDeleteItem = (item) => {
    const contactsCopy = clone(contactSchedule);

    const itemsOld =
      JSON.parse(contactsCopy[item.row.ScheduleControlOrder - 1][item.cell]) ||
      [];
    const restItems = itemsOld.filter(
      (rest) => rest.ContactScheduleId !== item.id
    );

    contactsCopy[item.row.ScheduleControlOrder - 1][item.cell] = JSON.stringify(
      restItems
    );
    setContactSchedule(contactsCopy);
  };

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /** ****************************** */

  return (
    <DndProvider backend={Backend}>
      <Container>
        <ContactAgendaPopup
          visible={agendaPopupVisible}
          contact={currentContact}
          onVisibleChange={(visible) => setAgendaPopupVisible(visible)}
        />
        <RowDateGarbage>
          <BreadCrumb>
            <h3>{ACL.BreadCrumb}</h3>
          </BreadCrumb>
          <DateBox
            id="dtCurrentDate"
            defaultValue={currentDate}
            type="date"
            format="date"
            width="200px"
            displayFormat="y QQQ"
            // maxZoomLevel="year"
            // minZoomLevel="century"
            calendarOptions={{ maxZoomLevel: 'year', minZoomLevel: 'century' }}
            onValueChanged={onMonthChanged}
          />
          {ACL.Delete && <Garbage handleDrop={handleGarbageDrop} />}
        </RowDateGarbage>
        <TableContainer component={Paper} hover>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                {headerSchedule.map((data) => (
                  <TableCell align="center">
                    <WrapperHeadCell>
                      <p>
                        {data.columnName.includes('(')
                          ? treatmentHolidayText(data.columnName)
                          : data.columnName}
                      </p>
                      {data.columnName.includes('(') &&
                        data.columnName.slice(7)}
                    </WrapperHeadCell>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contactSchedule.map((row, indexRow) => (
                <CustomTableRow key={row.ScheduleControlOrder}>
                  <CustomGroupNameCell
                    component="th"
                    scope="row"
                    schedulecolor={row.Color}
                    color={pickTextColorBasedOnBgColor(
                      row.Color,
                      '#FFF',
                      '#000'
                    )}
                  >
                    {row.GroupName && row.GroupName.toUpperCase()}
                  </CustomGroupNameCell>
                  {treatmentColumnName.map((item) => (
                    <ItemDrop
                      item={item}
                      handleDrop={(itemDrop) =>
                        handleDrop(indexRow, item, itemDrop)
                      }
                      handleCanDrop={(itemDrop) =>
                        handleCanDrop(indexRow, item, itemDrop)
                      }
                      row={row}
                      handleAssignmentOpen={handleAssignmentOpen}
                    >
                      {row[item.columnName] !== null &&
                        JSON.parse(row[item.columnName]).map((data) => (
                          <ItemDrag
                            key={data.ContactScheduleId}
                            data={data}
                            acl={ACL}
                            handleAvatarClick={handleAvatarClick}
                            row={row}
                            cell={item.columnName}
                            handleDelete={(id) => handleDeleteItem(id)}
                          />
                        ))}
                    </ItemDrop>
                  ))}
                </CustomTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      {assignmentAnchorEl && (
        <AssignmentPopup
          anchorEl={assignmentAnchorEl}
          handleClose={handleAssignmentClose}
          cell={assignmentItem}
          contactSchedule={contactSchedule}
          setContactSchedule={setContactSchedule}
          setErrorSnack={setErrorSnack}
          handleClickSnack={handleClickSnack}
        />
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        {errorSnack ? (
          <Alert onClose={handleCloseSnack} severity="error">
            Error during assignment. Please try again!
          </Alert>
        ) : (
          <Alert onClose={handleCloseSnack} severity="success">
            Assignment success!
          </Alert>
        )}
      </Snackbar>
    </DndProvider>
  );
}
