import { CheckBox } from 'devextreme-react';
import Button from 'devextreme-react/button';
import React, { useEffect, useRef, useState } from 'react';
import Switch from '../../components/Switch';
import { LoadPermissions } from '../../global';
import usePermissions from '../../hooks/usePermissions';
import useAllADUserEmrAccess from '../../services/requests/useAllADUserEmrAccess';
import useStyles from '../styles/styles';
import BatchRequestsPopup from './components/BatchRequestsPopup';
import NewRequestPopup from './components/NewRequestPopup';
import { useEmrAccess } from './contexts/EmrAccessContext';
import {
  DataGridContainer,
  Header,
  HeaderButtons,
  Title,
  TitleWrapper,
} from './styles';

import useAllHospitalGroupsList from 'services/requests/useAllHospitalGroupsList';
import ApplicationsGrid from './components/ApplicationsGrid';
import EmployeesGrid from './components/EmployeesGrid';

const EMRAccess = () => {
  // const { state } = useLocation();
  const classes = useStyles();
  const [
    showMissingCredentialsFilter,
    setShowMissingCredentialsFilter,
  ] = useState(false);
  // const [currentEmrAccess, setCurrentEmrAccess] = useState(null);
  // const [editPopupVisible, setEditPopupVisible] = useState(false);

  const [popupBatchRequestsVisible, setPopupBatchRequestsVisible] = useState(
    false
  );
  const {
    currentTab,
    showMissingCredentialsOnly,
    setShowMissingCredentialsOnly,
    state: stateReducer,
    dispatch,
    requestsRef,
    dataGridEmployeesRef,
    dataGridAppsRef,
  } = useEmrAccess();
  const [isHospitalGroupView, setIsHospitalGroupView] = useState(false);

  const {
    data: adUserEmrData,
    refetch: forceUpdateUsers,
    isFetching: isFetchingUsers,
  } = useAllADUserEmrAccess(!isHospitalGroupView);

  const {
    data: applicationList,
    refetch: forceUpdateApps,
    isFetching: isFetchingApps,
  } = useAllHospitalGroupsList(isHospitalGroupView);

  const isLoading = !!(isFetchingApps || isFetchingUsers);

  const popupNewRequestRef = useRef(null);

  const handleUpdate = async () => {
    forceUpdateUsers();
    forceUpdateApps();
  };

  useEffect(() => {
    if (
      (!isHospitalGroupView && currentTab.selectedIndex === 0) ||
      isHospitalGroupView
    ) {
      setShowMissingCredentialsFilter(true);
    } else {
      setShowMissingCredentialsFilter(false);
      setShowMissingCredentialsOnly(false);
    }
  }, [currentTab, isHospitalGroupView, setShowMissingCredentialsOnly]);

  // useEffect(() => {
  //   if (state && state.link) {
  //     setCurrentEmrAccess(state.link);
  //     setEditPopupVisible(true);
  //   }
  // }, [state]);

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }

  /*********************************/

  function handleChangeView() {
    setIsHospitalGroupView((old) => !old);
    requestsRef.current = {
      Apps: [],
      Users: [],
    };
    const dxEmployee = dataGridEmployeesRef?.current?.instance;
    const dxApplication = dataGridAppsRef?.current?.instance;

    dxEmployee.clearSelection();
    dxApplication.clearSelection();
  }

  return (
    <div className={classes.divGridContainer}>
      {/* {currentEmrAccess && (
        <EmrAccessEditPopup
          visible={editPopupVisible}
          onClose={() => {
            setCurrentEmrAccess(null);
            setEditPopupVisible(false);
          }}
          emrAccess={state.link}
          forceRefetch={() => {}}
        />
      )} */}
      <NewRequestPopup
        ref={popupNewRequestRef}
        refetch={() => handleUpdate()}
        isHospitalGroupView={isHospitalGroupView}
      />
      {popupBatchRequestsVisible && (
        <BatchRequestsPopup
          visible={popupBatchRequestsVisible}
          onClose={() => {
            setPopupBatchRequestsVisible(false);
          }}
          refetchGrid={() => handleUpdate()}
        />
      )}
      <Header>
        <TitleWrapper>
          <Title>{ACL?.BreadCrumb}</Title>
          <Switch
            id="switch-view"
            toggled={isHospitalGroupView}
            onChange={() => handleChangeView()}
            iconOn="fas fa-hospital"
            iconOff="fas fa-users"
            title={
              !isHospitalGroupView
                ? 'Switch to Okta Tiles'
                : 'Switch to Employees'
            }
            description={isHospitalGroupView ? 'By Okta Tiles' : 'By Employees'}
          />
          <CheckBox
            text="Show Missing Credentials Only"
            onValueChanged={(e) => setShowMissingCredentialsOnly(e.value)}
            value={showMissingCredentialsOnly}
            visible={showMissingCredentialsFilter}
          />
          <div />
          <CheckBox
            text="Show Unassigned"
            onValueChanged={(e) =>
              dispatch({ type: 'SET_SHOW_UNASSIGNED', value: e.value })
            }
            value={stateReducer.showUnassigned}
          />
        </TitleWrapper>
        <HeaderButtons>
          <Button
            icon={isLoading ? 'clock' : 'refresh'}
            onClick={handleUpdate}
            hint="Refresh data"
            disabled={isLoading}
          />

          {/* <Button
            icon="fas fa-eraser"
            text={`Clear requests ${
              batchRequests.length ? `(${batchRequests.length})` : ''
            }`}
            onClick={() => setBatchRequests([])}
            disabled={!batchRequests.length}
            width={180}
          /> */}
          {/* {isHospitalGroupView && (ACL?.Update || ACL?.Admin) && (
            <Button
              icon="fas fa-envelopes-bulk"
              text={`Queued request${batchRequests?.length > 1 ? 's' : ''} ${
                batchRequests.length ? `(${batchRequests.length})` : ''
              }`}
              onClick={() => {
                setPopupBatchRequestsVisible(true);
              }}
              disabled={!batchRequests.length}
              width={200}
            />
          )} */}
          {(ACL?.AddNew || ACL?.Admin) && (
            <Button
              icon="plus"
              text={'New Request'}
              // text={`New Request ${
              //   currentSelectedUsers.length
              //     ? `(${currentSelectedUsers.length})`
              //     : ''
              // }`}
              type="default"
              onClick={() => popupNewRequestRef?.current?.handleShowPopup()}
              // disabled={!currentSelectedUsers.length}
              width={180}
            />
          )}
        </HeaderButtons>
      </Header>
      <DataGridContainer visible={isHospitalGroupView}>
        <ApplicationsGrid
          ACL={ACL}
          gridData={applicationList}
          showMissingCredentialsOnly={showMissingCredentialsOnly}
        />
      </DataGridContainer>
      <DataGridContainer visible={!isHospitalGroupView}>
        <EmployeesGrid
          ACL={ACL}
          gridData={adUserEmrData}
          showMissingCredentialsOnly={showMissingCredentialsOnly}
        />
      </DataGridContainer>
    </div>
  );
};

export default EMRAccess;
