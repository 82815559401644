import React, { useEffect, useState } from 'react';
import { LoadPanel } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import API from '../../services/API';
import ContactsGrid from './ContactsGrid';
import { Container } from './styles';

function QueryContact() {
  async function fetchContactsWCI() {
    setIsLoading(true);
    try {
      const { data: contactData } = await API.sendRequest(`ContactWCI/ListWeb`);
      setContactList(contactData);
    } catch (e) {
      notify('Error loading Contacts data', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    fetchContactsWCI();
  }, [refetch]);

  return (
    <Container>
      <LoadPanel visible={isLoading} />
      <ContactsGrid dataSource={contactList} setRefetch={setRefetch} />
    </Container>
  );
}

export default QueryContact;
