import { ButtonItem } from 'devextreme-react/form';
import Scheduler from 'devextreme-react/scheduler';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  margin-top: 5px;
`;

export const RowDateGarbage = styled.div`
  display: flex;
  align-items: center;
`;

export const NJScheduler = styled(Scheduler)`
  height: ${window.innerHeight - 150}px !important;

  .dx-scheduler-header {
    height: auto;
  }

  .dx-scheduler-header > * {
    visibility: hidden;
  }

  .dx-scheduler-work-space {
    padding-top: 0px;
    margin-top: -56px;
  }
`;

export const RButtonItem = styled(ButtonItem)`
  flex: none !important;
`;
