import { bool, func, string } from 'prop-types';
import React from 'react';
import { PopupDx } from './styles';

function PopupDocViewer({ visible, url, onHidden, onlyImage }) {
  return (
    <PopupDx
      title="Document Viewer"
      visible={visible}
      onHidden={() => {
        onHidden();
      }}
      minHeight="90%"
      minWidth="75%"
      resizeEnabled
    >
      {url.includes('.jpg') ||
      url.includes('.png') ||
      url.includes('.gif') ||
      onlyImage ? (
        <img
          src={url}
          alt="file"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      ) : (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
          // src={'https://docs.google.com/viewer?url=' + url + '&embedded=true'}
          title="file"
          width="100%"
          height="100%"
          style={{ border: 0 }}
        ></iframe>
      )}
    </PopupDx>
  );
}

export default PopupDocViewer;

PopupDocViewer.propTypes = {
  visible: bool.isRequired,
  onHidden: func.isRequired,
  url: string.isRequired,
  onlyImage: bool,
};

PopupDocViewer.defaultProps = {
  onlyImage: false,
};
