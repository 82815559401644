import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import API from '../../../services/API';
import CustomStore from 'devextreme/data/custom_store';

class TimeZoneDetailTemplate extends React.Component {
  render() {
    let { TimeZoneId } = this.props.data.data;
    let tzLocal = new CustomStore({
      key: 'TimeZoneLocalId',
      load: async () => {
        return (await API.sendRequest(`TimeZoneLocal/${TimeZoneId}`, 'get'))
          .data;
      },
    });

    return (
      <React.Fragment>
        <DataGrid
          dataSource={tzLocal}
          showBorders={true}
          columnAutoWidth={true}
        >
          <Column dataField="TimeZoneLocalDesc" caption="TimeZone Local" />
        </DataGrid>
      </React.Fragment>
    );
  }
}

export default TimeZoneDetailTemplate;
