import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { func } from 'prop-types';
import notify from 'devextreme/ui/notify';
import { Container, LabelContainer, Text } from './styles';
import API from '../../../../../services/API';

const PopupTrainingDelete = forwardRef(({ onClose, refetch }, ref) => {
  const [deleting, setDeleting] = useState(false);
  const [currentTraining, setPopupData] = useState(null);
  const [visiblePopup, setVisiblePopUp] = useState(false);

  useImperativeHandle(ref, () => ({
    popupControl: () => setVisiblePopUp((prev) => !prev),
    setPopupData: (data) => setPopupData(data),
  }));

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await API.sendRequest(
        `Training/Delete/${currentTraining.TrainingId}`,
        'delete'
      );

      if (res.status !== 200) {
        throw new Error(res.data.message);
      }

      refetch();
      setDeleting(false);
      onClose();
      notify('Training deleted.', 'success', 2000);
    } catch (e) {
      setDeleting(false);
      notify(`Error: ${e.message}`, 'error', 5000);
    }
  };

  const buttonsOptions = [
    {
      text: 'Delete',
      type: 'success',
      onClick: () => {
        handleDelete();
      },
    },
    {
      text: 'Cancel',
      type: 'normal',
      onClick: () => {
        onClose();
      },
    },
  ];

  return (
    <Popup
      visible={visiblePopup}
      onHiding={onclose}
      width={500}
      height={250}
      showCloseButton={false}
      title="Training - Delete"
      disabled={deleting}
    >
      <Container>
        <LabelContainer>
          <Text bold>
            {currentTraining?.TrainingDesc
              ? `The [${currentTraining.TrainingDesc}] will be deleted. This action cannot be undone.`
              : `The Training will be deleted. This action cannot be undone.`}
          </Text>
          <Text>Are you sure?</Text>
        </LabelContainer>
      </Container>
      {buttonsOptions.map((buttonOpt, index) => (
        <ToolbarItem
          key={index}
          options={{ stylingMode: 'contained', ...buttonOpt }}
          widget="dxButton"
          toolbar="bottom"
          location="after"
        />
      ))}
    </Popup>
  );
});

export default PopupTrainingDelete;

PopupTrainingDelete.propTypes = {
  onClose: func.isRequired,
  refetch: func.isRequired,
};

PopupTrainingDelete.defaultProps = {
  // visible: false,
};
