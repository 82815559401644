import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import { Button, Column } from 'devextreme-react/data-grid';
import { TitleGrid } from '../../styles';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { useOktaApp } from '../../context/OktaAppContext';
import { object } from 'prop-types';
import API from '../../../../services/API';
import AppLogo from '../AppLogo';

function EmployeeDetailGrid({ data }) {
  const { popupDeleteRef, popupAddAppMemberRef, ACL } = useOktaApp();
  const [oktaApps, setOktaApps] = useState([]);

  async function fetchOktaApps() {
    const { data: oktaAppsData } = await API.sendRequest(
      `OktaApp/ListByUser/${data?.UserId}`,
      'GET'
    );
    setOktaApps(oktaAppsData);
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && data?.UserId && oktaApps.length === 0) {
      fetchOktaApps();
    }
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, []);

  function renderLogo(dataCell) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <AppLogo url={dataCell?.data?.OktaAppLogo} />
        {/* <img
          src={dataCell?.data?.OktaAppLogo ?? iconConfig}
          alt="Logo"
          style={{ maxWidth: '100px', height: '30px' }}
        /> */}
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          paddingBottom: '8px',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <TitleGrid>Applications</TitleGrid>
        {(ACL?.Update || ACL?.Admin) && (
          <IconButton
            aria-label="plus"
            size="small"
            onClick={() =>
              popupAddAppMemberRef?.current?.setPopupData({
                visible: true,
                data,
                type: 'MEMBER',
                refetch: fetchOktaApps,
              })
            }
          >
            <AddBoxOutlinedIcon />
          </IconButton>
        )}
      </div>
      {useMemo(
        () => (
          <DataGrid
            focusedRowEnabled
            keyExpr="OktaAppId"
            dataSource={oktaApps}
            showBorders
            rowAlternationEnabled
          >
            <Column
              dataField="OktaAppLogo"
              caption="Logo"
              cellRender={(data) => renderLogo(data)}
              width={110}
            />
            <Column dataField="OktaAppLabel" caption="Name" />
            <Column dataField="OktaAppUrl" caption="Login Url" />
            {(ACL?.Update || ACL?.Admin) && (
              <Column type="buttons" caption="Actions">
                <Button
                  icon="edit"
                  onClick={(e) =>
                    popupAddAppMemberRef?.current?.setPopupData({
                      visible: true,
                      data: { ...e?.row?.data, ...data },
                      type: 'EDIT',
                      refetch: fetchOktaApps,
                    })
                  }
                />
                <Button
                  icon="trash"
                  onClick={(e) =>
                    popupDeleteRef?.current?.setPopupData({
                      visible: true,
                      data: {
                        UserId: data?.UserId,
                        OktaAppId: e?.row?.data?.OktaAppId,
                        OktaApplicationId: e?.row?.data?.OktaApplicationId,
                        Type: 'MEMBER',
                        Message: `Do you want to delete application [${e?.row?.data?.OktaAppLabel}] from member [${data?.DisplayName}]?`,
                        refetch: fetchOktaApps,
                      },
                    })
                  }
                />
              </Column>
            )}
          </DataGrid>
        ),
        // eslint-disable-next-line
        [oktaApps]
      )}
    </>
  );
}

export default EmployeeDetailGrid;

EmployeeDetailGrid.propTypes = {
  data: object.isRequired,
};
