import React from 'react';
import API from '../../services/API';
import CustomStore from 'devextreme/data/custom_store';
import useStyles from '../styles/styles';
import { Column, DataGrid, Paging, Editing } from 'devextreme-react/data-grid';
import usePermissions from '../../hooks/usePermissions';
import {
  BreadCrumbRender,
  onToolbarBreadCrumb,
  LoadPermissions,
} from '../../global';
import { Template } from 'devextreme-react/core/template';

const events = new CustomStore({
  key: 'EventId',
  load: async () => {
    const data = (await API.sendRequest(`Event`, 'get')).data;
    return data;
  },
  insert: async (data) => {
    await API.sendRequest(`Event`, 'post', data);
  },
  update: async (id, data) => {
    await API.sendRequest(`Event`, 'post', data);
  },
  remove: async (id) => {
    await API.sendRequest(`Event/${id}`, 'delete');
  },
});

const onRowUpdating = (options) => {
  options.newData = { ...options.oldData, ...options.newData };
};

const Event = () => {
  const classes = useStyles();

  /*********************************
   * Access Control
   *********************************/
  const ACL = usePermissions();
  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>You don't have access to this page</LoadPermissions>
    );
  }
  /*********************************/

  return (
    <div className={classes.divGridContainer}>
      <DataGrid
        id={'gridContainer'}
        dataSource={events}
        // keyExpr={'EventId'}
        allowColumnReordering={true}
        showBorders={true}
        rowAlternationEnabled={true}
        showRowLines={true}
        onRowUpdating={onRowUpdating}
        onToolbarPreparing={onToolbarBreadCrumb}
      >
        <Paging enabled={false} />
        <Editing
          useIcons
          mode={'form'}
          allowAdding={ACL.AddNew}
          allowUpdating={ACL.Update}
          allowDeleting={ACL.Delete}
        />

        <Column dataField={'EventDate'} dataType="date" width={100} />
        <Column dataField={'IsMonthly'} width={80} />
        <Column dataField={'Title'} />
        <Column dataField={'Subtitle'} />
        <Column dataField={'Url'} />
        <Column dataField={'EventDesc'} editorType={'dxTextArea'} />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
};

export default Event;
